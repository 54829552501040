
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const UpdateAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.updateRowHandler(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-save"></i> Update </button>
       
        </div>
    );
};

export default UpdateAction;
/*
export default class UpdateAction extends Component {
	updateItem = (e) => {
		console.log("Insi",e.data);
		console.log(this.props.actionCall+" , "+this.props.actionMethod);
		var actionCall=this.props.actionCall;
		var actionMethod=this.props.actionMethod;
		var fdata = {
	    		rownode:e.data
	    }
	    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				console.log(response.data)
				this.props.ashow=true;
				this.props.atype='danger';
				this.props.atitle="test";
				//this.props.updatemessage='test';
				//let deletedRow = this.props.node.data;
		        //e.gridApi.updateRowData({ remove: [deletedRow] })
				
			}
		)
		.catch(function(error){
			console.log(error);
		});
		
		
	}
    render() {
        return (
            <button onClick={() => this.updateItem(this.props.node)} className="custom-btn label label-success"><i className="icofont icofont-save"></i> Update </button>
        );
    }
}

function resp(message){
	//console.log("message", message)
	if(message.nModified == 0)
	{
		document.querySelector(".updatemessage").classList.remove("show-n");
		document.querySelector(".updatemessage").classList.remove("fade");
		document.querySelector(".updatemessage").classList.add("show-m");
		document.querySelector(".updatemessage").innerHTML="No Changes made";
		document.querySelector(".errmessage").classList.add("show-n");
	}
	
	else if(message.nModified == 1)
	{
		document.querySelector(".updatemessage").classList.remove("show-n");
		document.querySelector(".updatemessage").classList.add("show-m");
		document.querySelector(".updatemessage").classList.remove("fade");
		document.querySelector(".updatemessage").innerHTML="Successfully updated";
		document.querySelector(".errmessage").classList.add("show-n");
	}
	else
	{
		document.querySelector(".errmessage").classList.remove("show-n");
		document.querySelector(".updatemessage").classList.add("show-n");
		document.querySelector(".updatemessage").classList.remove("show-m");
		document.querySelector(".errmessage").classList.add("show-m");
		document.querySelector(".errmessage").classList.remove("fade");
		document.querySelector(".errmessage").innerHTML=message;
	}
	//console.log("Doc",document.querySelector(".updatemessage").html);
	setTimeout(function(){
		document.querySelector(".errmessage").classList.add("fade");
		document.querySelector(".updatemessage").classList.add("fade");
		document.querySelector(".errmessage").classList.add("show-n");
		document.querySelector(".updatemessage").classList.add("show-n");
		document.querySelector(".updatemessage").innerHTML="";
		document.querySelector(".errmessage").innerHTML='';
	},5000);
}

*/