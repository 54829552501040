
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentLoadAttach = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowUserLoadAttachmentComments(props);
    };

    return (
        <div className="map-icon-component">
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="History"><i className="icofont icofont-speech-comments f25"></i></button>
       
        </div>
    );
};

export default CommentLoadAttach;
