import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM ,getHyphenYYYYMMDDHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class LoadArrivals extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            oldTruckNo:"",
            truckNumList:"",
            loadshow:'show-n',
            overly : "show-n",
            truckLocation:"",
            replaced_date_time:"",
            transhipment_done_by:"",
            route_details:"",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: [],
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"",
            eventGridAction: "gridAction",
            showonlytransport:'show-m',
            reason:'',
            frameworkComponents : {
                ArrivalMap : ArrivalMap,
            },
            maptruckno:"",
            sliderRouteTranslate:"",
            mapinfo:"",
            routeTruck:	"",
            rownode:"",
            consigner:[{"value":"all", "label":"All"}],
            startDate : "",
            endDate : "",
            exitWithoutLoads : [],
            totalRecords : "",
            inside_vicinity:[],
            outside_vicinity : [],
            no_gps_data_total : [],
            no_gps_data : [],
            inside_plant_vicinty:[],
            outside_plant_vicinity :[],
            gps_outside_vicinity :[],
            tolls : [],
            rowClassRules : {
                "bgColorDangerLight" : function(params){
                    if(params.data.left_without_load == 1)
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            sliderTruckNo : "",
        };
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.getData = this.getData.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            overly : "show-m",
            loadshow : "show-m"
        })
        var reqparams = {
            "consigner_code" : ["all"],
            "startdate" : "",
            "enddate" : "" ,

        }
        
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        this.getData(reqparams);
    }
    
    async getData(params)
    {
        console.log(params);
        this.setState({
            exitWithoutLoads : []
        })
        await redirectURL.post("/consignments/getLoadArrivals",params).then((response)=>{
            console.log(response.data);
            var records = response.data;
            var no_gps_present = []
            try{
                var exitWithoutLoads = records.filter(function(e){
                    return e.left_without_load  == 1;
                });
                var no_gps_data_total = records.filter(function(e){
                    return e.gps_present == 0;
                });
                var no_gps_data = records.filter(function(e){
                    return e.gps_present == 0 && e.load_no != undefined;
                });
                var inside_vicinity = records.filter(function(e){
                    return  e.gps_present == 1 && e.entered_plant_area == 1 && e.left_without_load == undefined;
                })
                var gps_outside_vicinity = records.filter(function(e){
                    if((e.gps_present == 1 && e.entered_plant_area == 0) || (e.left_without_load  == 1 && e.gps_present == 1))
                    {
                        return e;
                    } 
                    
                })
                var outside_vicinity = records.filter(function(e){
                    return e.load_no != undefined && e.gps_present == 1 && e.entered_plant_area == 0;
                })
                var inside_plant_vicinty = records.filter(function(e){
                    return e.gps_present == 1 && e.entered_plant_area == 1 && e.load_no == undefined; 
                });
                var outside_plant_vicinity = records.filter(function(e){
                    return e.gps_present == 1 && e.entered_plant_area == 0 && e.load_no != undefined;
                });
                console.log(inside_plant_vicinty);
            }catch(e){
                var exitWithoutLoads = [];
            }
            
            this.setState({
                rowData : response.data,
                totalRecords : records,
                loadshow : "show-n",
                overly : "show-n",
                exitWithoutLoads : exitWithoutLoads,
                no_gps_data : no_gps_data,
                no_gps_data_total : no_gps_data_total,
                inside_vicinity : inside_vicinity,
                outside_vicinity : outside_vicinity,
                inside_plant_vicinty : inside_plant_vicinty,
                outside_plant_vicinity : outside_plant_vicinity,
                gps_outside_vicinity : gps_outside_vicinity
            })
            
        }).catch((e)=>{
            console.log(e.message);
        })
    }

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    onCloseRouteDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
			overly:'show-n'
		});
    }
    onClickCounterShowData(params){
		console.log(" params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		
		if(params == "exitloads")
		{
			this.gridApi.setRowData(this.state.exitWithoutLoads);
			this.gridApi.onFilterChanged();
        }
        if(params == "all")
        {
            this.gridApi.setRowData(this.state.totalRecords);
			this.gridApi.onFilterChanged();
        }
        if(params == "gps_present")
        {
            this.gridApi.setRowData(this.state.gps_present);
			this.gridApi.onFilterChanged();
        }
        if(params == "entered_plant_vicinity")
        {
            this.gridApi.setRowData(this.state.inside_plant_vicinty);
			this.gridApi.onFilterChanged();
        }
        if(params == "outside_plant_vicinity")
        {
            this.gridApi.setRowData(this.state.outside_plant_vicinity);
			this.gridApi.onFilterChanged();
        }
        
        if(params == "inside_vicinity")
        {
            this.gridApi.setRowData(this.state.inside_vicinity);
			this.gridApi.onFilterChanged();
        }
        if(params == "outside_vicinity")
        {
            this.gridApi.setRowData(this.state.outside_vicinity);
			this.gridApi.onFilterChanged();
        }
        if(params == "no_gps_data")
        {
            this.gridApi.setRowData(this.state.no_gps_data);
			this.gridApi.onFilterChanged();
        }
        if(params == "no_gps_data_total")
        {
            this.gridApi.setRowData(this.state.no_gps_data_total);
			this.gridApi.onFilterChanged();
        }
        if(params == "total_exceptions")
        {
            var total_exceptions = this.state.no_gps_data_total.concat(this.state.outside_vicinity);
            this.gridApi.setRowData(total_exceptions);
			this.gridApi.onFilterChanged();
        }
        if(params == "gps_outside_vicinity")
        {
            this.gridApi.setRowData(this.state.gps_outside_vicinity);
            this.gridApi.onFilterChanged();
        }
        
    }
    onShowRouteDiv = (e) => {
        //console.log("E ",e)
        this.setState({
            overly : "show-m",
            loadshow : "show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var sdate = getHyphenYYYYMMDDHHMMSS(e.arrival_date);
        var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        var parameters = {
            first_instance : sdate,
            recent_instance : edate,
            truck_no : e.truck_no,
            
        }
        if(e.truck_no != "" && e.truck_no != null)
        {
            //console.log(parameters);
            redirectURL.post("/reports/nightdriveroutes",parameters)
            .then((response) => {
                console.log("Map Routes ", response.data)
                var records = response.data;
                this.setState({
				    loadshow:'show-m'
                });
                try{
                    if(records.coords.length == 0)
                    {
                        //console.log("records", records.coords);
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n',
                            overly : "show-n"
                        });
                    }
                    else
                    {
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Consignment : "+e.consignment_code,
                                sliderTruckNo : e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
                                loadshow:'show-n',
                                overly:'show-n',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls
                            });
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            this.renderMap();
                        }
                    }
                }catch(e){
                    if(records.message == "no data found")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n',
                            overly : "show-n"
                        });

                    }else
                    {
                        console.log(e);
                    }
                }
                
               
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
        else{
            this.setState({
				show:true,
				basicTitle:"No data available",
                basicType:"danger",
                overly:'show-n',
                loadshow:'show-n'
			});
        }
        
		
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		// if(this.state.dept_code == 'SNDG')
		// {
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		// }


		// if(this.state.dept_code == 'LOG-PRT')
		// {
		// 	var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
		// 	// An array of values 
		// 	var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
		// 	// Object created 
		// 	var obj = {}; 
			
		// 	// Using loop to insert key 
		// 	// value in Object 
		// 	for(var i = 0; i < keys.length; i++){ 
		// 		consigners.push({"code":keys[i],"value":values[i]}); 
		// 	} 
		// }


		// if(this.state.dept_code == 'LOG-TNP')
		// {
		// 	var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
		// 	// An array of values 
		// 	var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
		// 	// Object created 
		// 	var obj = {}; 
			
		// 	// Using loop to insert key 
		// 	// value in Object 
		// 	for(var i = 0; i < keys.length; i++){ 
		// 		consigners.push({"code":keys[i],"value":values[i]}); 
		// 	} 
		// }

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
    }

    changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onSubmitFilter = async () => {
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        var consigners = []

        if(this.state.consigner == null || this.state.consigner == "")
        {
            consigners.push("all")
        }
        else
        {
            var clist= this.state.consigner;
            clist.map((item) => {
                consigners.push(item.value)
            })
        }
        //console.log("selected consigners ", consigners)
        
        if(this.state.startDate == "NaN-NaN-NaN")
        {
            var sdate =""
        }
        else
        {
            sdate = this.state.startDate
        }
        if(this.state.endDate == "NaN-NaN-NaN")
        {
            var edate =""
        }
        else
        {
            edate = this.state.endDate
        }

        var requestparams = {
			startdate:sdate,
            enddate:edate,
            consigner_code:consigners,
        }
        var defStartDate = moment.parseZone(new Date("2021-02-01 00:00:00")).utcOffset("+05:30")._d;
        
        if(defStartDate > moment.parseZone(new Date(sdate)).utcOffset("+05:30")._d || defStartDate > moment.parseZone(new Date(edate)).utcOffset("+05:30")._d )
        {
            this.setState({
                basicTitle: "Start Date must not be less than February.",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })
        }
        else
        {
            this.getData(requestparams)

        }
    }



	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Load Arrivals';
		
		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    render(){
        const columnwithDefs = [
            {
                headerName : "",
                field : "_id",
                cellRenderer : "ArrivalMap",
                width : 60
            },
            {
                headerName : "",
                field : "_id",
                cellRenderer : function(params){
                    if(params.data.gps_present == 1)
                    {
                        return "<i class='icofont icofont-social-google-map txt-grey-light f20'></icofont>"
                    }
                    if(params.data.gps_present == 0)
                    {
                        return "<i class='icofont icofont-social-google-map txt-danger-dark f20'></icofont>"
                    }
                },
                width : 60
            },
            {
                headerName : "Truck No",
                field : "truck_no",
                filter : true,
                resizable :true,
                width:120,
            },
            {
                headerName : "Truck Type",
                field : "truck_type",
                filter : true,
                resizable :true,
                width:110,
            },
            {
                headerName : "Transporter Code",
                field : "transporter_code",
                filter : true,
                resizable :true,
                width:130,
            },

            {
                headerName : "Transporter Name",
                field : "transporter_name",
                filter : true,
                resizable :true,
                width:130,
            },
            {
                headerName : "Consigner Code",
                field : "consigner_code",
                filter : true,
                resizable :true,
                width:130,
            },
            {
                headerName : "Plant Name",
                field : "plant_name",
                filter : true,
                resizable :true,
                width:120,
            },
            {
                headerName : "Arrival Entry On",
                field : "arrival_date",
                resizable :true,
                width:150,
                valueGetter : function(params)
                {
                    if(params.data.arrival_date != "" && params.data.arrival_date != undefined && params.data.arrival_date != null)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.arrival_date)
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: "Distance From Plant At Entry (KM)",
                field: "distance_from_plant_at_entry",
                filter: true,
                resizable: true,

                valueGetter : function(params)
                {
                    if(params.data.distance_from_plant_at_entry != "" && params.data.distance_from_plant_at_entry != null && params.data.distance_from_plant_at_entry != undefined)
                    {
                        if(params.data.distance_from_plant_at_entry > 0)
                        {
                            return Math.round(params.data.distance_from_plant_at_entry);
                        }
                        
                    }
                    else
                    {
                        return params.data.distance_from_plant_at_entry;
                    }
                }
            },
            
            {
                headerName : "Current Distance From Plant (KM)",
                field : "distance_from_plant",
                filter : true,
                resizable :true,
                width:150,
                valueGetter : function(params)
                {
                    if(params.data.distance_from_plant != "" && params.data.distance_from_plant != null && params.data.distance_from_plant != undefined)
                    {
                        if(params.data.distance_from_plant > 0)
                        {
                            return Math.round(params.data.distance_from_plant);
                        }
                        
                    }
                    else
                    {
                        return params.data.distance_from_plant;
                    }
                }
            },
            {
                headerName : "Distance Updated On",
                field : "distance_updated_on",
                resizable :true,
                width:150,
                valueGetter : function(params)
                {
                    if(params.data.distance_updated_on != "" && params.data.distance_updated_on != undefined && params.data.distance_updated_on != null)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.distance_updated_on)
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: "Within Plant vicinity",
                field: "entered_plant_area",
                filter: true,
                resizable: true,
                width:120,
                valueGetter : function(params)
                {
                    
                        if(params.data.entered_plant_area == 1)
                        {
                            return "Yes";
                        }
                        if(params.data.entered_plant_area == 0){
                            return "No";
                        }
                    
                    // else
                    // {
                    //     console.log("params.data.entered_plant_area",params.data.entered_plant_area)
                    //     return params.data.entered_plant_area;
                    // }
                }
            },
            {
                headerName : "Load No",
                field : "load_no",
                width:120,
                filter : true,
                resizable : true
            },
            {
                headerName : "Load Attachment Date",
                field : "load_attachment_date",
                resizable :true,
                width:150,
                valueGetter : function(params)
                {
                    if(params.data.load_attachment_date != "" && params.data.load_attachment_date != undefined && params.data.load_attachment_date != null)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.load_attachment_date)
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             var cellDate = new Date(cellValue);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             var filteredDate = new Date(filterValue);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             // console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // },
            },
            {
                headerName: "Present Route",
                field: "present_route",
                filter: true,
                resizable: true,
                width:110
            },
            {
                headerName: "Left Without Load",
                field: "left_without_load",
                filter: true,
                resizable: true,
                width:110,
                valueGetter : function(params)
                {
                    if(params.data.left_without_load == 1)
                    {
                        return "Yes";
                    }
                    if(params.data.left_without_load == 0){
                        return "No";
                    }
                }
            }

        ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <div className="form-group col-xl-4 col-lg-4">
                        <label>Select Consigner: </label>
                        <Select 
                            placeholder={"All"} 
                            closeMenuOnSelect={false} 
                            value={this.state.consigner} 
                            isMulti="true" 
                            className="border-radius-0" 
                            onChange={this.changeConsignerArrayHandler.bind(this)} 
                            style={{borderRadius:"0px"}} 
                            options={this.selectConsignerOptionsItems()} 
                            required 
                        />
                                    
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>From Date</label>
                        <Datetime 
                            value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ 
                                placeholder: 'From', 
                                name: 'startDate', 
                                autoComplete:'off' 
                            }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} 
                        />
                    </div>
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>To Date</label>
                        <Datetime 
                        value={this.state.endDate} 
                        disableCloseOnClickOutside={false}
                            closeOnSelect={true} 
                            inputProps={{ 
                                placeholder: 'To', 
                                name: 'endDate', 
                                autoComplete:'off'
                        }} 
                            dateFormat="YYYY-MM-DD" 
                            onChange={this.handlerEndDateTime.bind(this)} />
                            
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onSubmitFilter.bind(this)}>Submit</button>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                        <LoadArrivalCounters 
                            context={this} 
                            exitWithoutLoads = {this.state.exitWithoutLoads.length}
                            totalRecords = {this.state.totalRecords.length}
                            // gps_present = {this.state.gps_present.length}
                            inside_plant_vicinty = {this.state.inside_plant_vicinty.length}
                            no_gps_data = {this.state.no_gps_data.length}
                            no_gps_data_total = {this.state.no_gps_data_total.length}
                            inside_vicinity = {this.state.inside_vicinity.length}
                            outside_vicinity = {this.state.outside_vicinity.length}
                            outside_plant_vicinity = {this.state.outside_plant_vicinity.length}
                            gps_outside_vicinity = {this.state.gps_outside_vicinity.length}
                        />
                    </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Load Arrivals</span>
                                </h5>

                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>

                            </div>	
                            <div className="card-body pt-15px" >
                                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        // stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        // rowSelection={this.state.rowSelection}
                                        // suppressRowClickSelection={true}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>`
                    </div>
                </div>
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Truck Number : {(this.state.sliderTruckNo)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
                             {(this.state.mapinfo != "")?
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                
                                <DrawMap context={this} mapFor={"loadattach"} tolls={this.state.tolls}  truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} />
								
                               
								
                                <div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != "")?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											
										
										</div>
									</div>
								</div>

							</div> :""}
						</div>
						
					</div>
				:""	
				}
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}