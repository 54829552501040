

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getHyphenDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import download from 'js-file-download';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class chaAirCargoListComponent extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        plants:[],
        plant_name:"",
        cellPlants:[],
        pending:"activet",
        closure:"",
        invoiceData:[],
        pageType:1,
        ccSlider : "", 
        jobNo : "",
        ccData : [],
        cc_docs : "",
        //components : {datepicker:getDatePicker()}
    }
    this.downloadRingiFile = this.downloadRingiFile.bind(this);
    this.changeDocFileHandler = this.changeDocFileHandler.bind(this);
    this.uploadCCFileFormHandler = this.uploadCCFileFormHandler.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
}

onLoadPageData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var supplier_code = localStorage.getItem("supplier_code");
    var params = {
        "cha_code" : supplier_code
    }
    redirectURL.post("/consignments/getChaSupplerAirCargos",params)    
    .then((response) => {
        var records = response.data;
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}

onLoadClosureData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    });
    
    var supplier_code = localStorage.getItem("supplier_code");
    var params = {
        "cha_code" : supplier_code
    }    
    redirectURL.post("/consignments/getChaSupplerAirCargosClosures",params)    
    .then((response) => {
        var records = response.data;
        console.log("records ", records)
        this.setState({
            rowData : records,
            overly:"show-n",
            loadshow:"show-n",
        })
    })
    .catch(function(e){
        console.log("Error ",e)
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    invoiceData:[],
    ccData:[],
    ccSlider:"",
    jobNo:"",
    cc_docs:""

    });
    
}

onClickTab(pageType){
    if(pageType == "pending")
    {
        var pending = "activet";
        var closure = "";
        var pageType = 1;
        this.onLoadPageData();
    }
    else if(pageType == "closure")
    {
        var pending = "";
        var closure = "activet";
        var pageType = 2;
        this.onLoadClosureData();
    }
    this.setState({
        pending:pending,
        closure:closure,
        pageType:pageType
    })
}

viewDetails(e){
    if(e.colDef.field == "invoiceData")
    {
        var invoiceData = JSON.parse(e.data.invoiceData);
        var sliderTitle = e.data.job_no+" - Pre alert documents";
        this.setState({
            overly :"show-m",
            invoiceData:invoiceData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle
        })
    }
    if(e.colDef.field == "other_docs")
    {
        var ringiFile = e.data.other_docs
        if(ringiFile !='')
        {
            var rngParam = {
                ringiFile : ringiFile,
            }
            redirectURL.post("/consignments/downloadRingiFile",rngParam).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, ringiFile)
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    if(e.colDef.field == "ccData")
    {
        var ccData = [];
        if(e.data.ccData !=undefined && e.data.ccData !='')
        {
            ccData = JSON.parse(e.data.ccData);
        }
        var sliderTitle = e.data.job_no+" - Custom clearance documents";
        this.setState({
            overly :"show-m",
            ccData:ccData,
            bulkslide : "slider-translate-40p",
            sliderTitle : sliderTitle,
            ccSlider : 1,
        })
        var pageType = this.state.pageType;
        if(pageType == 1)
        {
            this.setState({
                jobNo : e.data.job_no,
            })
        }
    }
}

downloadRingiFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        this.setState({
            loadshow:"show-m"
        });
        redirectURL.post("/consignments/downloadRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
            this.setState({
                loadshow:"show-n"
            });
        }).catch(function(error){
            console.log(error);
            this.setState({
                loadshow:"show-n"
            });
        })
    }
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        cc_docs : e.target.files[0]
    });
}
uploadCCFileFormHandler(event){
    event.preventDefault();
    var jobNo = this.state.jobNo;
    if(jobNo !=undefined && jobNo !='')
    {
        if(this.state.cc_docs !=undefined && this.state.cc_docs !='')
        {
            let saveData = new FormData();
            saveData.append("cc_docs", this.state.cc_docs);
            saveData.append("job_no", this.state.jobNo);
            saveData.append("username", localStorage.getItem("username"));
            redirectURL.post("/consignments/uploadChaCCDocuments",saveData,{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then((response)=>{
                var rowData = this.state.rowData;
                var ccDetails = [];
                var filteredData = rowData.filter(rec => (rec.job_no == jobNo));
                if(filteredData[0].ccData !=undefined && filteredData[0].ccData !='')
                {
                    var ccData = JSON.parse(filteredData[0].ccData);
                    for(var r=0;r<ccData.length;r++)
                    {
                        ccDetails.push({
                            "custom_clearance_file" : ccData[r].custom_clearance_file
                        });
                    }
                }
                ccDetails.push({
                    "custom_clearance_file" : this.state.cc_docs.name
                });
                $("#other_docs").val("");
                this.setState({
                    basicTitle:"Success",
                    basicType : "success",
                    show : true,
                    ccData:ccDetails,
                    cc_docs:"",
                });
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                basicTitle:"File should not be empty",
                basicType : "warning",
                show : true
            });
        }
    }
    else
    {
        this.setState({
            basicTitle:"Something Went wrong, Please try again",
            basicType : "warning",
            show : true
        });
    }
}

  render() {
    var pageType = this.state.pageType;
    console.log(pageType,"pageType");
    if(pageType == 2)
    {
        var editHide = true;
    }
    else
    {
        var editHide = false;
    }
    console.log(editHide,"editHide")
    var supplier_code = localStorage.getItem("supplier_code");
    console.log(supplier_code,"supplier_code")
    var columnwithDefs = [    
        {
            field:'_id',
            headerName:'Edit',
            colId:"_id",
            //pinned:"left",
            width: 72,
            hide: editHide,
            cellRenderer:function(params){
                let basestring = params.data._id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/editchaaircargoform/'+encryptedstring+'/1" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                if(pageType == 1)
                {
                    return htmloption;
                }
                else
                {
                    return "N/A";
                }
            }            
        }, 
        {
            headerName: "BOE Documents",
            field: "ccData",
            width:150,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Add/View</button>';
                return htmloption;
            }
        },    
        {
            headerName: "Custom Duty Paid",
            field: "custom_duty_paid",
            width:150,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                if(supplier_code !=undefined && supplier_code !="undefined" && supplier_code !='')
                {
                    if(params.data.custom_duty_paid !=undefined)
                    {
                        return params.data.custom_duty_paid;
                    }
                }
                else
                {
                    var htmloption = '<button class="btn btn-info" style="padding-top:0px">Mark As Paid</button>';
                    if(params.data.custom_duty_paid == undefined || params.data.custom_duty_paid =='' || params.data.custom_duty_paid == 'no')
                    {
                        if(pageType == 1)
                        {
                            //return htmloption;
                            return params.data.custom_duty_paid;
                        }
                        else
                        {
                            return params.data.custom_duty_paid;
                        }
                    }
                    else
                    {
                        return params.data.custom_duty_paid;
                    }
                }
            }     
        },
        {
            headerName: "Job No",
            field: "job_no",
            width:160,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA IGI Airport",
            field: "eta_igi_airport",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.eta_igi_airport_date !='' && params.data.eta_igi_airport_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.eta_igi_airport_date);
                    }
                }
                catch(e){
                }
            }           
        },
        {
            headerName: "ATA IGI Airport",
            field: "ata_igi_airport",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.ata_igi_airport_date !='' && params.data.ata_igi_airport_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ata_igi_airport_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Free Trade Agreement",
            field: "free_trade",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "R&D benefit Certificate",
            field: "benefit_certificate",
            width:180,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Pre alert documents",
            field: "invoiceData",
            width:130,
            filter: true,
            resizable: true,
            cellRenderer:function(params){
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                return htmloption;
            }         
        },
        {
            headerName: "Docs",
            field: "other_docs",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "IGM Date",
            field: "igm_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.igm_date !='' && params.data.igm_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.igm_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Inward Date",
            field: "inward_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.inward_date !='' && params.data.inward_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.inward_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Segregation Date",
            field: "segregation_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.segregation_date !='' && params.data.segregation_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.segregation_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "BOE No",
            field: "boe_no",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BOE Date",
            field: "boe_date",
            width:120,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                try{
                    if(params.data.boe_date !='' && params.data.boe_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.boe_date);
                    }
                }
                catch(e){
                }
            }            
        },
        {
            headerName: "Status",
            field: "status",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Exam Date",
            field: "exam_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.exam_date !='' && params.data.exam_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.exam_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "OTP Date",
            field: "otp_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.otp_date !='' && params.data.otp_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.otp_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.ooc_date !='' && params.data.ooc_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.ooc_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Exchange Rate",
            field: "exchange_rate",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Assessable Value",
            field: "assessable_value",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BCD",
            field: "bcd",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "SWS",
            field: "sws",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "IGST",
            field: "igst",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Total Custom Duty",
            field: "total_custom_duty",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "LPC",
            field: "lpc",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Interest",
            field: "interest",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "MEIS",
            field: "meis",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Duty payable",
            field: "duty_payable",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Reason For Delay",
            field: "lpc_reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC No 1",
            field: "bc_no_1",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC-1 Date",
            field: "bc_1_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.bc_1_date !='' && params.data.bc_1_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.bc_1_date);
                    }
                }
                catch(e){
                }
            }          
        },
        
        {
            headerName: "BC No 2",
            field: "bc_no_2",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "BC-2 Date",
            field: "bc_2_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.bc_2_date !='' && params.data.bc_2_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.bc_2_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "TSP charges",
            field: "tsp_charges",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges",
            field: "demmurage_charges",
            width:120,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Charges Amount",
            field: "demmurage_charges_amount",
            width:150,
            filter: true,
            resizable: true,          
        },
        {
            headerName: "Demmurage Start Date",
            field: "demmurage_start_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_start_date !='' && params.data.demmurage_start_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_start_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Demmurage End Date",
            field: "demmurage_end_date",
            width:120,
            filter: true,
            resizable: true, 
            valueGetter: function(params){
                try{
                    if(params.data.demmurage_end_date !='' && params.data.demmurage_end_date !=undefined)
                    {
                        return getHyphenDDMMYYYY(params.data.demmurage_end_date);
                    }
                }
                catch(e){
                }
            }          
        },
        {
            headerName: "Reason For Delay",
            field: "reason_for_delay",
            width:120,
            filter: true,
            resizable: true,          
        },
    ]
    return (
      <div class="container-fluid">
          
          <h5>
              <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>CHA Updates </span>
          </h5>
          {/* <button className="btn btn-success" onClick={this.addNewRow.bind(this)}>Add New Holiday</button> */}
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        <div className="f16" style={{marginBottom:"10px"}}>
            <ul className="d-tabs">
                <li onClick={this.onClickTab.bind(this,"pending")} className={"lttabs "+(this.state.pending)}>
                    <button type="button" className="btn">Pending</button>
                </li>

                <li onClick={this.onClickTab.bind(this,"closure")} className={"lttabs "+(this.state.closure)}>
                    <button type="button" className="btn">Closure</button>
                </li>
            </ul>
        </div>
        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
            <AgGridReact
                modules={this.state.modules}
                columnDefs={columnwithDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                enableCharts={false}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                onGridReady={this.onGridReady}
                onGridState={this.onGridState}
                frameworkComponents={this.state.frameworkComponents}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                stopEditingWhenGridLosesFocus= {true}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                gridOptions={{
                    context: { componentParent: this }
                }}
                // components={this.state.components}
                enableRangeSelection= {true}
                onCellClicked={this.viewDetails.bind(this)}
                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                />
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">
                {this.state.sliderTitle}
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                {(this.state.invoiceData.length > 0) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.invoiceData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.invoice_file)}>{e.invoice_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                : ""}
                {(this.state.ccSlider == 1) ?                         
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table table-bordered tptinfo">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ccData.map((e, index) => (
                                        <tr>
                                            <td style={{cursor:"pointer"}} onClick={() => this.downloadRingiFile(e.custom_clearance_file)}>{e.custom_clearance_file}</td>
                                        </tr>
                                            ))}
                                    </tbody>
                                </table>
                                {(this.state.jobNo != "") ?
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadCCFileFormHandler} style={{marginTop:"2%"}}>
                                    <div className="form-group">
                                        <label className="">Upload New Document</label>
                                        <input type="file" name="other_docs" id="other_docs" className="form-control" onChange={this.changeDocFileHandler} required/>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                : ""} 
                            </div>
                        </div>
                    </div>
                : ""}
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};