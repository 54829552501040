
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const RouteMap = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onShowRouteMap(props.data);
    };

    return (
        <div>
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f20"></i> </button>
       
        </div>
    );
};

export default RouteMap;
