import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

// import Grid from '../layouts/transporterComponent';
import Modal from 'react-responsive-modal';
import EditItem from "./editaction";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageTransporters extends Component {

	constructor(props){
		super(props);
		this.state={
			modules: AllModules,
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData:[],
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			pagetitle:"",
			usermanualmodal:false,
			sliderAddTransporter:"",
			transporter_name:"",
			transporter_code:"",
			rowid:"",
			utransporter_name:"",
			utransporter_code:"",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				EditItem:EditItem,
			},

			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			sliderEditTransporter:""
			
		}
		this.onLoadTransporters = this.onLoadTransporters.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/manage/sndtransporters":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/manage/prttransporters":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptransporters":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctransporters":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/	
		 this.onLoadTransporters();
	};
	onLoadTransporters(){
		var params = {}
		if(localStorage.getItem("pc")!= undefined && localStorage.getItem("pc")!= "undefined" && localStorage.getItem("pc")!= null &&localStorage.getItem("pc")!= ""){
			params.plant_code = localStorage.getItem("pc")
		}
		redirectURL.post('/transporters/list',params)    
		.then((response) => {
		  var records = JSON.parse(JSON.stringify(response)).data;
		  
		  if (this.state.departmentCode) {
			  records = records.filter(rec => rec.dept_code === this.state.departmentCode);
			}
		  //   console.log("/transporters/list, records = ", records);
		  this.setState({
			  rowData:records,
			  loadshow:"show-n",
			  overly:"show-n"
		  });
		})
		.catch(function (error) {
		  console.log(error);
		  });
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderAddTransporter:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			sliderEditTransporter:""
		});
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
		
    }
	onCLickAddTransporter(){
		this.setState({
			sliderAddTransporter:'slider-translate',
			overly:"show-m"
		});
	}
	changeHandler(event){
		var name = event.target.name;
		var value = event.target.value;
		this.setState({[name]:value});
	}
	onClickEditForm(rowdata){
		var row = rowdata.data;
		this.setState({
			utransporter_code:row.transporter_code,
			utransporter_name:row.transporter_name,
			rowid:row._id,
			sliderEditTransporter:'slider-translate',
			overly:"show-m"
		})
	}
	saveTransporterItem(event){
		event.preventDefault();
		var transporter_name = this.state.transporter_name;
		var transporter_code = this.state.transporter_code;
		var params = {
			transporter_code:transporter_code,
			transporter_name:transporter_name
		}
		if(transporter_name != "" && transporter_code != "")
		{
			redirectURL.post("/transporters/addtransporter",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully added transporter",
						basicType:"success",
						transporter_name:"",
						transporter_code:"",
						sliderAddTransporter:"",
						overly:"show-n"
					})
					this.onLoadTransporters();
				}
				else{
					this.setState({
						show:true,
						basicTitle:"Failed to add transporter",
						basicType:"danger"
					})
				}
				
			})
		}
		
	}
	updateTransporterItem(event){
		event.preventDefault();
		var transporter_name = this.state.utransporter_name;
		var transporter_code = this.state.utransporter_code;
		var rowid = this.state.rowid;
		var params = {
			transporter_code:transporter_code,
			transporter_name:transporter_name,
			rowid:rowid
		}
		if(transporter_name != "" && transporter_code != "")
		{
			redirectURL.post("/transporters/updatetransporterdata",params)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicTitle:"Successfully update transporter",
						basicType:"success",
						utransporter_name:"",
						utransporter_code:"",
						sliderEditTransporter:"",
						overly:"show-n"
					})
					this.onLoadTransporters();
				}
				else{
					this.setState({
						show:true,
						basicTitle:"Failed to add transporter",
						basicType:"danger"
					})
				}
				
			})
		}
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
	  	        {
	  	          headerName: "Transporter Code",
	  	          field: "transporter_code",
	  	          width: 300,
                  editable:false,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Transporter Name",
                  field: "transporter_name",
				  width: 300,
				  editable : false,
                  filter: "agSetColumnFilter"	    	          
				},
				
		        {
		            headerName: "Status",
		            field: "is_active",
		            width: 90,
					filter: false,
		            //singleClickEdit:true,
		            editable: false,
		            cellRenderer: function(params){
		            	if(params.value == 1 || params.value == 'Active')
	            		{
		            		return 'Active';
	            		}
		            	else
	            		{
		            		return 'Inactive';
	            		}
		            },
		            cellEditor: 'agRichSelectCellEditor',
		            cellEditorParams: {
		                cellRenderer: 'genderCellRenderer',
		                values: ['Active', 'Inactive']
		            }
		        },
		        {
		          headerName: "Action",
		          field: "_id",
		          width: 100,
				  filter: false,
		          cellRenderer:'EditItem',
		          editable:false
		        }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Transporters </span>
									
									<button type="button" onClick={this.onCLickAddTransporter.bind(this)} className="btn btn-warning pull-right">Add Transporter</button>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
								<div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										rowSelection={this.state.rowSelection}
										enableCharts={false}
										//  paginationAutoPageSize={true}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										enableRangeSelection={true}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										deleteRowHandler={this.deleteRowHandler}
										// onSelectionChanged={this.onSelectionChanged.bind(this)}
										frameworkComponents={this.state.frameworkComponents}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										//updateRowHandler={this.updateRowHandler}
										//onCellEditingStopped={this.updateCellData.bind(this)}
									/>
								</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Transporter</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists details regarding to all the transporters, its AMNS code and currently actively taking consignments or not.
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								For PRT department there is additional column present which is known as child codes which consists of subcodes of transporters through which they pick the consignments from various point of Origin
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
						</div>
					</div>
				</Modal>

				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r "+(this.state.sliderAddTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Transporter
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveTransporterItem.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code</label>
							    <input type="text" placeholder="Enter Transporter Code" onChange={this.changeHandler.bind(this)} value={this.state.transporter_code} id="transporter_code" name="transporter_code" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Name</label>
							    <input type="text" placeholder="Enter Transporter Name" onChange={this.changeHandler.bind(this)} value={this.state.transporter_name} id="transporter_name" name="transporter_name" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderEditTransporter)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Edit Transporter
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateTransporterItem.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code</label>
							    <input type="text" placeholder="Enter Transporter Code" onChange={this.changeHandler.bind(this)} value={this.state.utransporter_code} id="utransporter_code" name="utransporter_code" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Name</label>
							    <input type="text" placeholder="Enter Transporter Name" onChange={this.changeHandler.bind(this)} value={this.state.utransporter_name} id="utransporter_name" name="utransporter_name" className="form-control" required />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>
            </div>
              	
		);
	}
}