import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
var redirectURL = require('../redirectURL');

export default class LspWiseTrucksWithGPSChartComponent extends Component {

    constructor(props) {
		super(props);
		this.state = {
			lspaxis:'',
			lspyaxis:'',
			inlineFilters:'',
			filter_from_date:'',
			filter_to_date:'',
		}
    }
	componentDidMount(){
		const layoutdata={
            token:localStorage.getItem('token'),
            dept_code:this.props.deptcode
        }
		redirectURL.post('/overview/lspWiseTruckChart', layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
    		  .then((response) => {
    			 
    		   var records = JSON.parse(JSON.stringify(response)).data;
    		  // console.log(records);
    		   var lxaxis=[];
    		   var lseriesdata=[];
    		   for(var rec in records)
    		   {
    			  // console.log(records[rec]);
    			   lxaxis.push(records[rec]._id);
    			   lseriesdata.push(parseInt(records[rec].count));
    		   }
    		   this.setState({
    			   lspaxis:lxaxis,
    			   lspyaxis:lseriesdata
    		   });
    		    
    		  })
    		  .catch(function (error) {
    		    console.log(error);
    		  });
        	    

	}
	onShowInlineFilter = (i) => {
		//console.log("i " ,i)
		this.setState({
			inlineFilters:"fcslide-translate-40p",
		});	
	}
	onCloseiInlineFilter(){
		this.setState({
			inlineFilters:""
		});	
	}
    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
	      	     },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
	      		};
        
        return ( 
			<div className={"card"} >
			<div className="card-header">
				<h5 className="grid-h5">{this.props.componentTitle}
					<span className="pull-right">
						<ul className="actions-list">
							{/*<li>
								<a href="#" onClick={this.onShowInlineFilter.bind(this)} title="Filter">
									<i class="fa fa-filter"></i>
								</a>
							</li>
							*/}
	
							<li>
								<a className="grid-mx" href="" title="Maximize">
									<i class="fa fa-external-link"></i>
								</a>
							</li>
							
						</ul>
					</span>
				</h5>
			</div> 
			<div className="card-body" style={{padding:"0px"}}>
				<div className="dashboard-chart-container sales-overview-chart posrel">
					<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
					<div className={"fcslide-r "+(this.state.inlineFilters)}>
						<div className="fcslide-r-title">
							<h4>
								<i class="fa fa-external-link"></i> Filter
								<span className="float-right fclose" onClick={this.onCloseiInlineFilter.bind(this)}>x</span>
							</h4>
						</div>
					</div> 
				</div>
			</div>
			
		</div> 
			);
		}
}












