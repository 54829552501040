import React, {Component} from 'react';
import Countdown from 'react-countdown-now';
import { Redirect } from 'react-router';
var redirectURL = require('../redirectURL');

class ComingSoon extends Component {


    constructor(props) {
        super(props);
        this.state = {style:{}}
    };

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        console.log("this.props.match.path ", this.props)
        console.log(this.props.location.pathname+this.props.location.search)
        var urlpath = this.props.location.pathname+this.props.location.search;
        redirectURL.get(urlpath)
		  .then((response) => {
            var records = response.data;
            
		  })
		  .catch(function (error) {
		    console.log(error);
			});
	
    }

    render() {
        return <Redirect to="/" push={true} />
        let style = this.state.style;

        const Completionist = () => <span>You are good to go!</span>;

        const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <Completionist />;
            } else {
                // Render a countdown
                return <div className="countdown">
                    
                </div>;
            }
        };

        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var coundown = new Date(year , month, day+10).getTime();



        return (
            <div>
                <div className='loader-wrapper' style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                 {/*Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="comingsoon auth-bg-video">
                            <video poster={require('../../assets/images/auth-bg.jpg')} className="bgvideo-comingsoon" id="bgvid"  autoPlay muted loop>
                                <source src={require('../../assets/video/auth-bg.mp4')} type="video/mp4"/>
                            </video>
                            <div className="comingsoon-inner text-center">
                                <img src={require('../../assets/icons/logo.png')} alt=""/>
                                    <h5>Your account has been activated successfully. Please <a href="/" style={{color:"#FF0000"}}>click here</a> to login</h5>

                                <Countdown date={coundown} renderer={renderer}/>

                            </div>
                        </div>
                    </div>
                </div>
                {/*page-wrapper Ends*/}
            </div>
        )
    }
}


export default ComingSoon;
