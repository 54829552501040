import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import CSVFileValidator from 'csv-file-validator';
import RailInventoryLoadingYard from "./railinventoryloadingyard";
import RailInventoryDestinationYard from "./railinventorydestinationyard";
import RailInventoryTransLoadingYard from "./railinventorytransloadingyard";
import RailInventoryTransDestinationYard from "./railinventorytransdestinationyard";

$.datetimepicker.setLocale('en');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
//var rowdefs = []
export default class RailInventory extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "Rail Inventory",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderBulkForceClose:"",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
      	    },
      	    rowData: [],
			maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				RailInventoryLoadingYard:RailInventoryLoadingYard,
				RailInventoryDestinationYard:RailInventoryDestinationYard,
				RailInventoryTransLoadingYard:RailInventoryTransLoadingYard,
				RailInventoryTransDestinationYard:RailInventoryTransDestinationYard
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.gate_in_time != ''",
				//"green":"data.status >= 3"

			},
			rowDatagrid:[],
			screenpage:'',
			pagetitle:'Rail Inventory',
			open:false,
            truckslist:[],
            truckcategories:[],
            truckcategory:{"value":"","label":""},
            truck:"",
            slidertranslate:'',
            trucktype:{"value":"","label":""},
            warehouse:"",
            tentitivetime:"",
			showTrucksList : "show-n",
            filteredData : [],
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            rowid:"",
            trucktypeother:"show-n",
            othertrucktype:"",
            bulkslide:"",
            file:"",
            csvcontent:'',
            categories:[],
            trucktypes:[],
            transporters:[],
            columns:[],
			rowdefs:[],
			allrowdata:[],
			columnDefs:[],
			headerHeight:80,
			groupHeaderHeight:30
		}
		
		this.onLoadRailInventoryData = this.onLoadRailInventoryData.bind(this);
		this.onClickDestinationYard = this.onClickDestinationYard.bind(this);
		this.onClickLoadingYard = this.onClickLoadingYard.bind(this);
		this.createColumns = this.createColumns.bind(this);
		this.onClickTransDestinationYard = this.onClickTransDestinationYard.bind(this);
		this.onClickTransLoadingYard = this.onClickTransLoadingYard.bind(this);
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        this.onLoadRailInventoryData();

    };
    onLoadRailInventoryData(){
        var parameters = {

        }
        redirectURL.post("/consignments/railinventorydata", parameters)
        .then(async (response) => {
          // console.log("response ", response.data)
			var rowdata = response.data.records;
			if(rowdata.length > 0)
			{
				rowdata = rowdata.sort(GetSortDescOrder("loadcount"));            
			}
            await this.setState({
				//rowData:rowdata,
				allrowdata:rowdata
            })
			await this.createColumns()
        })
    }
    createColumns(){
		var allrowdata = this.state.allrowdata;
		var cols = []
		var rows=[]
		cols.push({
			headerName:"Yard Name",
			field:"yard"
		},
		{
			headerName:"Awaiting Rake Loading",
			field:"loadcount",
			width:110,
			cellRendererSelector:function(params){
				var rendComponent = {
					component: 'RailInventoryLoadingYard'
				};
				return rendComponent
			
			}
			
		},
		{
			headerName:"Awaiting Dealer Delivery",
			field:"destcount",
			width:110,
			cellRendererSelector:function(params){
				var rendComponent = {
					component: 'RailInventoryDestinationYard'
				};
				return rendComponent
			
			}
			
		})
		var alltransporters = []
		if(allrowdata.length > 0)
		{
			allrowdata.map((item) => {
				var rws = {}
				//console.log("Item ", item)
				rws._id = item._id;
				rws.yard = item.yard;
				rws.loadcount=item.loadcount;
				rws.destcount=item.destcount;
				
				var transs = groupBy(item.loadrecords, rdata => rdata.transporter_name);
				transs.forEach((trans,key) => {
					alltransporters.push(key)
					// console.log("tranporter ", key)
					// console.log("load trans ", trans)
					var keydata = key+"_loadcount"
					rws[keydata] = trans.length;
				})
				var transsdest = groupBy(item.destrecords, rdata => rdata.transporter_name);
				transsdest.forEach((transd,keyd) => {
					alltransporters.push(keyd)
					var keydatad = keyd+"_destcount"
					rws[keydatad] = transd.length;
					// console.log("tranporter ", key)
					// console.log("dest trans ", trans)
				})
				rows.push(rws);
			})
			
		}
		let uniquetransporters = alltransporters.filter((item, i, ar) => ar.indexOf(item) === i);

		//console.log("alltransporters ", uniquetransporters)
		var classes = ["cellstylegridG", "cellstylegridY", "bgColorDangerLight", "cellstylegridM", "cellstylegridSk"]
		var applyclass = classes[Math.floor(Math.random() * classes.length)]
		console.log("applyclass ", applyclass)
		if(uniquetransporters.length > 0)
		{
			uniquetransporters.map((item, index) => {
				var aclass = classes[index]
				var def = {
					headerName:item,
					headerClass:[aclass],
					children:[
						{
							headerName:"Awaiting Rake Loading",
							headerClass:[aclass],
							width:140,
							cellClass:[aclass],
							field:item+"_loadcount",
							cellRendererSelector:function(params){
								var rendComponent = {
									component: 'RailInventoryTransLoadingYard'
								};
								return rendComponent
							
							}
						},
						{
							headerName:"Awaiting Dealer Delivery",
							headerClass:[aclass],
							width:110,
							cellClass:[aclass],
							field:item+"_destcount",
							cellRendererSelector:function(params){
								var rendComponent = {
									component: 'RailInventoryTransDestinationYard'
								};
								return rendComponent
							
							}
						}
					]
					
				}
				cols.push(def);
			})
		}
		// console.log("cols ", cols)
		// console.log("rows ", rows)
		this.setState({
			columnDefs:cols,
			rowData:rows
		})
		
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
    }
    onCloseRouteDiv(){
        this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            slidertranslate:'',
            bulkslide:"",
            showTrucksList : "show-n",
            truck:{"value":"","label":""},
            truckcategory:{"value":"","label":""},
            warehouse:{"value":"","label":""},
            trucktype:{"value":"","label":""},
            rowid:""
			
		});
		
	}
  
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        //await window.location.reload();
	}
    
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
	
	onClickLoadingYard(params)
	{
		this.setState({
			loadshow:"show-m"
		})
		//console.log("Loading ",params)
		var parameters = {
			loadid : params.data._id,
			clickable:"loading"
		}
		redirectURL.post("/consignments/railinventorygrid", parameters)
		.then((response) => {
			console.log("grid data ", response.data)
			
			this.setState({
				slidertranslate:"slider-translate-60p",
				overly:"show-m",
				loadshow:"show-n",
				rowDatagrid:response.data.records
			})
		})

	}
	onClickDestinationYard(params){

		this.setState({
			loadshow:"show-m"
		})
		//console.log("Destination ",params)
		var parameters = {
			destid : params.data._id,
			clickable:"destination"
		}
		redirectURL.post("/consignments/railinventorygrid", parameters)
		.then((response) => {
			//console.log("grid data ", response.data)
			
			this.setState({
				slidertranslate:"slider-translate-60p",
				overly:"show-m",
				loadshow:"show-n",
				rowDatagrid:response.data.records
			})
		})
	}

	onClickTransDestinationYard(params){
		//console.log("Destination Trans ", params)
		var tranp = params.colDef.field;
		var trans = tranp.split("_");
		var transporter = trans[0];
		//var clickable = trans[1];
		var rowid = params.data._id;
		var parameters = {
			destid : rowid,
			clickable:"destination",
			transporter:transporter
		}
		redirectURL.post("/consignments/railinventorytransportergrid", parameters)
		.then((response) => {
			//console.log("grid data ", response.data)
			
			this.setState({
				slidertranslate:"slider-translate-60p",
				overly:"show-m",
				loadshow:"show-n",
				rowDatagrid:response.data.records
			})
		})

	}
	onClickTransLoadingYard(params){
		var tranp = params.colDef.field;
		var trans = tranp.split("_");
		var transporter = trans[0];
		//var clickable = trans[1];
		var rowid = params.data._id;
		var parameters = {
			loadid : rowid,
			clickable:"loading",
			transporter:transporter
		}
		redirectURL.post("/consignments/railinventorytransportergrid", parameters)
		.then((response) => {
			//console.log("grid data ", response.data)
			
			this.setState({
				slidertranslate:"slider-translate-60p",
				overly:"show-m",
				loadshow:"show-n",
				rowDatagrid:response.data.records
			})
		})
	}

	render(){
		const { open } = this.state;
        var columnDefs = [
            {
                headerName:"Yard Name",
                field:"yard"
            },
            {
                headerName:"Awaiting Rake Loading",
				field:"loadcount",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'RailInventoryLoadingYard'
					};
					return rendComponent
				
				}
                
            },
            {
                headerName:"Awaiting Dealer Delivery",
				field:"destcount",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'RailInventoryDestinationYard'
					};
					return rendComponent
				
				}
                
            }
		]
		
		var gridcols = [
			
			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Vin No",
				field: "vin",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName:"Loading Yard Reached On ",
				field:"truck_loading_yard_reached_on",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
					if(params.data.truck_loading_yard_reached_on != '' && typeof params.data.truck_loading_yard_reached_on != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.truck_loading_yard_reached_on);
					} 
					else{
						return '';
					}
				}
				
			},
			{
				headerName:"Destination Yard Reached On ",
				field:"rake_delivery_point_reached_on",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					if(params.data.rake_delivery_point_reached_on != '' && typeof params.data.rake_delivery_point_reached_on != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.rake_delivery_point_reached_on);
					} 
					else{
						return '';
					}
				}
				
			},
			{
				headerName: "Aging",
				field: "aging",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Status",
				field: "status",
				width: 180,            
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					//console.log("rake no",params.data.rake_no);
					//console.log("GPS ", params.data.gps_data_available);
					if(params.data.status == 2)
					{
						return "In Transit to Loading Yard"
					}						
					else if(params.data.status == 3 && params.data.rake_no != undefined)
					{
						return "Rake Assigned"
					}
					else if(params.data.status == 3 && params.data.rake_no == undefined)
					{
						return "At Loading Yard"
					}
					else if(params.data.status == 4)
					{
						return "In Transit To Destination Yard"
					}	
					
					else if(params.data.status == 5)
					{
						return "At Destination Yard"
					}					
					else if(params.data.status == 6)
					{
						return "Force Closed"
					}					
					else if(params.data.status == 7)
					{
						return "In Transit To Destination Dealer"
					}					
					else if(params.data.status == 8)
					{
						return "Near Destination"
					}				
					else if(params.data.status == 9)
					{
						return "Reached Destination"
					}				
					else if(params.data.status == 10)
					{
						return "Left Destination"
					}
					else{
						return ""
					}						 
				} 	          
			},  

			{
				headerName: "Expected ETA",
				field: "eta_rail_final_destination",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true,
				valueGetter:function(params){
					try{
						return getDDMMMYYYYHHMMDefault(params.data.eta_rail_final_destination)
					}
					catch(e){
						return "";
					}
				}
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Revised ETA",
				field: "revised_eta_rail_final_destination",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true,
				valueGetter:function(params){
					try{
						return getDDMMMYYYYHHMMDefault(params.data.revised_eta_rail_final_destination)
					}
					catch(e){
						return "";
					}
				},
				cellClass:function(params){
					let hourDifference = 0;
					let revisedDateOnlyMoment = 0
					let expectedDateOnlyMoment  = 0
					if (params.data.revised_eta_rail_final_destination && params.data.eta_rail_final_destination) {
						revisedDateOnlyMoment  = moment(params.data.revised_eta_rail_final_destination);
						expectedDateOnlyMoment  = moment(params.data.eta_rail_final_destination);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if(revisedDateOnlyMoment > expectedDateOnlyMoment)
					{
						return 'bgColorDangerMedium'
					}

					if(revisedDateOnlyMoment < expectedDateOnlyMoment)
					{
						return 'bgColorSuccessMedium'
					}
					else{
						return ''
					}
				}
				//cellRenderer: "agGroupCellRenderer"
			},
	

			{
				headerName:"Cluster",
				field:"cluster",
				width:100,
				filter:"agSetColumnFilter",
				resizable: true,
				
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
				headerName: "Transshipment",
				field: "is_transshipment",
				width: 100,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					if(params.data.is_transshipment == 1)
					{
						return "Yes";
					}
					else{
						return "No";
					}
				},
				resizable: true
			},
			{
				headerName: "Old Truck No",
				field: "old_truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				resizable: true
			},
			{
			headerName: "Transporter Name",
			field: "transporter_name",
			
			width: 150,
			filter: "agSetColumnFilter",
			resizable: true	          
			}, 
			
			{
				headerName:"Last City/Town",
				field:"truck",
				width:150,
				valueGetter:function(params){
					//console.log(params.data.truck);
					if (params.data.hasOwnProperty('truck'))
					{
						if (params.data.truck.length > 0)
						{
						return params.data.truck[0].area;
						}
					}
					
				},
				filter:"agSetColumnFilter",
				resizable: true
			},    
			{
				headerName:"Last State",
				field:"truck",
				width:120,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					//console.log(params.data.truck);
					if (params.data.hasOwnProperty('truck'))
					{
						if (params.data.truck.length > 0)
						{
						return params.data.truck[0].state;
						}
					}
					
				}
			},             
			
			{
				headerName: "Last Packet Time",
				field: "truck",
				valueGetter:function(params){
					//console.log(params.data.transporter);
					if (params.data.hasOwnProperty('truck'))
					{
						if (params.data.truck.length > 0)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
						}
					}
						
				},
				
				width: 150,
				//filter: "agDateColumnFilter",
				resizable: true	          
			},
			{
				headerName: "GPS Provider",
				field: "truck",
				width: 150,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					//console.log(params.data.transporter);
					if (params.data.hasOwnProperty('truck'))
					{
						if (params.data.truck.length > 0)
						{
						return params.data.truck[0].actual_lspuser;
						}
					}
					
				},	          
			},    
			{
				headerName: "Invoice Date",
				field: "invoice_time",
				width: 150,
				//filter: "agDateColumnFilter",
				resizable: true,
				
				valueGetter:function(params){
					if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
					{
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					}
					else{
						return "NA";
					}
				},	          
			},             
			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer:''	,resizable: true    	          
			},               
			{
			headerName: "Consignee Code",
			field: "consignee_code",
			width: 150,                 
			filter: "agSetColumnFilter",
			resizable: true	    	          
			},  
						
			{
				headerName: "Dist. From Destination (km)",
				field: "distance_from_dealer_location",
				width: 150,                 
				filter: "agSetColumnFilter",
				resizable: true	,
				valueGetter:function(params){
					//console.log("params ", params.data);
					if(typeof params.data.distance_from_dealer_location == 'undefined')
					{

					}
					else
					{
						return Math.round(params.data.distance_from_dealer_location);
					}
					
				},   	          
			},  
			
			{
				headerName: "Consignee Name",
				field: "consignee_name",
				width: 150,           
				filter: "agSetColumnFilter",
				resizable: true	    	          
			},               
			{
				headerName: "Consignee City",
				field: "consignee_city",
				width: 150,           
				filter: "agSetColumnFilter",
				resizable: true	    	          
			},     
			
			{
				headerName: "Gate in Time",
				field: "gate_in_time",
				width: 180,                
				
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
				},
				resizable: true	    	          
			},
			{
				headerName: "GPS Gate in Time",
				field: "inside_fence",
				width: 180,                 
				
				
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
				},
				resizable: true 
			},				
			{
				headerName: "Gate out Time",
				field: "gate_out_time",
				width: 180,                 
				
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
				},
				resizable: true    
			},
			{
				headerName: "GPS Gate out Time",
				field: "outside_fence",
				
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
				},
				resizable: true    
			},
				
			{
				headerName:"ETA Loading Yard ",
				field:"eta_first_mile",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
					if(params.data.eta_first_mile != '' && typeof params.data.eta_first_mile != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.eta_first_mile);
					} 
					else{
						return '';
					}
				}
				
			},
			

	
			{
				headerName:"Rake No",
				field:"rake_no",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				
			},	

			{
				headerName:"FNR No",
				field:"rr_number",
				widht:100,
				filter:"agSetColumnFilter",
				//
				resizable: true,
				// valueGetter:function(params)
				// {
				// 	console.log("rr_number ",params.data)
				// 	return params.data.rr_number;
				// }
			},  
			{
				headerName:"Last City/Town",
				field:"rake_no",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					if (params.data.hasOwnProperty('raketruck'))
					{
						if (params.data.raketruck.length > 0)
						{
						return params.data.raketruck[0].area;
						}
					}
				} 
				
			},	
			{
				headerName:"Last State",
				field:"rake_no",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					if (params.data.hasOwnProperty('raketruck'))
					{
						if (params.data.raketruck.length > 0)
						{
						return params.data.raketruck[0].state;
						}
					}
						
				},
				
			},
			{
				headerName:"Last Packet Time",
				field:"rake_no",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					//console.log(params.data.transporter);
					if (params.data.hasOwnProperty('raketruck'))
					{
						if (params.data.raketruck.length > 0)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.raketruck[0].timestamp);
						}
					}
						
				},
				
			},
			{
				headerName:"Loading Yard",
				field:"rake_loading_point_name",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				
			},	
			{
				headerName:"Destination Yard",
				field:"rake_delivery_point_name",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				
			},	
			{
				headerName:"Load Dispatch Date/Time ",
				field:"expected_rake_dispatch_date",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					if(params.data.expected_rake_dispatch_date != '' && typeof params.data.expected_rake_dispatch_date != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.expected_rake_dispatch_date);
					} 
					else{
						return '';
					}
				}
				
			},	

			{
				headerName:"ETA Destination Yard ",
				field:"eta_rail_middle_mile",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
					if(params.data.eta_rail_middle_mile != '' && typeof params.data.eta_rail_middle_mile != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.eta_rail_middle_mile);
					} 
					else{
						return '';
					}
				}
				
			},
				
				
			{
				headerName:"Local Movement(Yes/No)",
				field:"local_movement",
				width:200,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					if(params.data.local_movement == true){
						return "Yes";
					}
					else
					{
						return "No";
					}
					
				}
			},
				
			{
				headerName:"Last Mile Dispatch Date ",
				field:"last_mile_dispatch_date",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					if(params.data.last_mile_dispatch_date != '' && typeof params.data.last_mile_dispatch_date != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.last_mile_dispatch_date);
					} 
					else{
						return '';
					}
				}
				
			},		
			{
				headerName:"Last Mile Truck No ",
				field:"last_mile_truck_no",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter : function(params)
				{
					//console.log(params.data.last_mile_truck_no);
					return params.data.last_mile_truck_no
				},
				
			},
			{
				headerName:"Last Packet Time",
				field:"timestamp",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter : function(params)
				{
					try{
						if(params.data.lastmiletruck.length > 0)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.lastmiletruck[0].timestamp);
						}
						else
						{
							return "";
						}
					}catch(e){}							
					
				},
				
			},
			{
				headerName:"Last Area/City",
				field:"area",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter : function(params)
				{
					try{
						//console.log(params.data.lastmiletruck)
						if(params.data.lastmiletruck.length > 0)
						{
							return params.data.lastmiletruck[0].area;
						}
					}catch(e){}	
				},
			},
			{
				headerName:"Last State",
				field:"state",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter : function(params)
				{
					try{
						if(params.data.lastmiletruck.length > 0)
						{
							return params.data.lastmiletruck[0].state;
						}
					}catch(e){}
				},
			},			

			{
				headerName:"ETA Dealer Reached On ",
				field:"eta_rail_last_mile",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					//console.log("truck_loading_yard_reached_on ", params.data.truck_loading_yard_reached_on)
					if(params.data.eta_rail_last_mile != '' && typeof params.data.eta_rail_last_mile != 'undefined')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.eta_rail_last_mile);
					} 
					else{
						return '';
					}
				}
				
			},
			{
				headerName:"Last Mile Dealer Reached On ",
				field:"recent_dealer_reported",
				width:140,
				filter:"agSetColumnFilter",
				resizable: true, 
				valueGetter:function(params){
					try{
						if(params.data.recent_dealer_reported != '' && typeof params.data.recent_dealer_reported != 'undefined')
						{
							return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
						} 
						else{
							return '';
						}
					}
					catch(e){
						return '';
					}
					
					
				}
				
			},
		

			{
				headerName:"TVP Gate In",
				field:"tvp_gate_in",
				width:160,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.tvp_gate_in != '1970-01-01 00:00:00.000Z' && params.data.tvp_gate_in != '')
						{
							return getDDMMMYYYYHHMMDefault(params.data.tvp_gate_in);
						}
						else
						{
							return '';
						}
						
					}
					catch(e){
						return "";
					}
				}
			},

			{
				headerName:"POD Received",
				field:"pod_received",
				width:100,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.pod_received == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
					catch(e){
						return "No";
					}
				}
			},
			
			{
				headerName:"POD Date",
				field:"trip_actual_delivery_date",
				width:100,
				filter:"agSetColumnFilter",
				resizable: true,
				valueGetter : function(params)
				{
					try{
						if(params.data.trip_actual_delivery_date != '')
						{
							return getDDMMMYYYYHHMMDefault(params.data.trip_actual_delivery_date);
						}
						else
						{
							return "";
						}
					}catch(e){
						return "";
					}
					
				}
			},
		
			
			{
				headerName:"Trip Type",
				field:"trip_type",
				widht:100,
				filter:"agSetColumnFilter",
				resizable: true
			},           
			
							
			{
				headerName: "TPT Delivered",
				field: "tpt_confirm_delivery",
				width: 120,                 
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.tpt_confirm_delivery == 1)
						{
							return "Yes";
						}
						else{
							return "No";
						}
					}
					catch(e){
						return "No";
					}
				}   	          
			},   			
			{
				headerName: "TPT Delivered On",
				field: "tpt_delivery_date",
				width: 120,                 
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.tpt_delivery_date != "")
						{
							return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
						}
						else{
							return "No";
						}
					}
					catch(e){
						return "No";
					}
				}   	          
			},  			
			{
				headerName: "TPT Delivered Reason",
				field: "tpt_delivery_reason",
				width: 200,                 
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.tpt_delivery_reason != "")
						{
							return params.data.tpt_delivery_reason;
						}
						else{
							return "";
						}
					}
					catch(e){
						return "";
					}
				}   	          
			}, 
			 
			{
				headerName:"Vehicle Mode",
				field:"vehicle_mode",
				widht:100,
				filter:"agSetColumnFilter",
				resizable: true
			},  			
			{
				headerName: "Items",
				field: "items",
				width: 150,                 
				filter: "agSetColumnFilter",
				cellRenderer:'' ,resizable: true   	          
			}
	   
		]
		return(
			<div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                
                    {/* <div className="col-xl-12 col-lg-12">
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
                                        <Datetime
                                        value={this.state.startDate} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										onChange={this.handlerStartDateTime.bind(this)} />
										
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>
                                        <Datetime 
                                        value={this.state.endDate} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										onChange={this.handlerEndDateTime.bind(this)} />
										
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.onClickFilterData.bind(this)}>Submit</button>
									</div>
									
								</div>
							</div>
							
						 </div>
					</div> */}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-train-line cus-i"></i> <span>Stockyard Inventory</span>
                                    
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                           
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
											headerHeight={this.state.headerHeight}
											groupHeaderHeight={this.state.groupHeaderHeight}
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
							Stockyard Inventory Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12">
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={gridcols}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowDatagrid}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={false}
									rowClassRules={this.state.rowClassRules}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									rowSelection={this.state.rowSelection}
									suppressRowClickSelection={true}
									onPaginationChanged={this.resetPaginationSelection}
									
								/>
								
								
							</div> 
						
					
                        </div>
                    </div>
                </div>
            
            </div>
    
              	
		);
	}
}



function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
