
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
const ViewDownloadPayments = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //props.context.componentParent.onShowRakeLegs(props);
    };
    var donwloads = []
    try{
        if(props.data.sec_uploadfiles != undefined)
        {
            if(props.data.sec_uploadfiles.length > 0)
            {
                props.data.sec_uploadfiles.map((item)=> {
                    //var fileCheck =  fileExists(item.path)
                    //console.log(fileCheck,item.path)
                    item.fileCheck = true;
                    donwloads.push(item)
                })
            }
        }
        else{
            donwloads=[];
        }
    
    }
    catch(e){
        donwloads=[];
    }
    
    const download = (downloadFile) => {
        if(downloadFile !='')
        {
            var rngParam = {
                downloadFile : downloadFile,
            }
            redirectURL.post("/consignments/downloadSecondaryFile",rngParam,{
                responseType: 'blob'
            }).then((response)=>{
                console.log("dowlad response ",response)
                download(response.data, downloadFile)
            }).catch(function(error){
                console.log(error);
            })
        }
      };

    //console.log(" donwloads ", donwloads)
    return (
        <div>
           {(donwloads.length > 0)?
            (donwloads.map(file =>
                ((file.fileCheck == true || file.fileCheck == false) ?
                <a
                    href="javascript:;"
                    target="_blank"  
                    onClick={e => download(file.name)} 
                >{file.name} &nbsp;
                </a>
                :
                <a
                    //onClick={e => download(file.path, file.name)}
                >{file.name} &nbsp;
                </a>
                )
            ))
            :""}
            
           
        </div>
    );
};
function fileExists(url) {
    if(url){
        var req = new XMLHttpRequest();
        req.open('HEAD', url, false);
        req.send();
        return req.status==200;
    } else {
        return false;
    }
}
export default ViewDownloadPayments;
