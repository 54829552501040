import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import LoadTimeInDaysReport from "./detentionLeadTimeReport"
var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');

var redirectURL = require('../redirectURL');
var moment = require('moment');

class DispatchVehicleCountDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicType: "",
            basicTitle: "",
            loadshow: "show-n",
            overly: "show-n",
            dispatch_vehicle_data: [],

        };
    }

    componentDidMount() {
        this.fetchDayWiseDispatch();
    }
    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }


    fetchDayWiseDispatch = async () => {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            })
            const dayWiseDispatchSdate = moment().subtract(1, 'month');
            const startDate = moment(dayWiseDispatchSdate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

            const parameters = {
                from_date: startDate,
                to_date: endDate
            };

            const [dispatchVehicleCount, countResponse] = await Promise.all([
                redirectURL.post("/consignments/dispatchVehicleCountDashboard", parameters),
                // redirectURL.post("/consignments/daywiseDispatchCount", parameters)
            ]);

            const dispatchVehicleCountDashboard = dispatchVehicleCount.data || [];
            console.log(dispatchVehicleCountDashboard, 'dispatchVehicleCountDashboard')

            const format_data_dispatch = {
                date: 'Total',
                day_wise: {
                    MY: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                    NMA: {
                        fence_grop: ''
                        , hrs_0_8: 0,
                        hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                    DSC: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0
                        ,
                        total_hrs: 0
                    },
                    CRM: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                    plate_mill: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                    pipe_mill: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                    total: {
                        fence_grop: ''
                        , hrs_0_8: 0
                        , hrs_8_16: 0,
                        hrs_16_24: 0,
                        total_hrs: 0
                    },
                }
            }


            const data_dispatch = dispatchVehicleCountDashboard.map(eachItem => {
                const formattedData = {
                    date: eachItem.date,
                    day_wise: {

                    }
                };

                eachItem.day_wise.forEach(item => {
                    const { fence_group, hrs_0_8, hrs_8_16, hrs_16_24 } = item;

                    format_data_dispatch.day_wise[fence_group].hrs_0_8 += hrs_0_8

                    format_data_dispatch.day_wise[fence_group].hrs_8_16 += hrs_8_16

                    format_data_dispatch.day_wise[fence_group].hrs_16_24 += hrs_16_24

                    var total_hrs = hrs_0_8 + hrs_8_16 + hrs_16_24

                    format_data_dispatch.day_wise[fence_group].total_hrs += total_hrs
                    formattedData.day_wise[fence_group] = { ...item, total_hrs };
                });

                return formattedData;
            });


            if (data_dispatch.length > 0) {
                data_dispatch.push(format_data_dispatch)
            }

            console.log(format_data_dispatch, "formattedData");

            this.setState({
                dispatch_vehicle_data: data_dispatch,
                loadshow: "show-n",
                overly: "show-n",
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
// 



    render() {

        const dispatch_vehicle_data_arr = this.state.dispatch_vehicle_data
        const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '10px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '8px',
            textAlign: 'center',
            textTransform: 'uppercase',
        };

        return (
            <>
                {/* <div className="card">
                    <div className='card-body'> */}
                        

                        <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
                            <div className="card-header" style={{ textAlign: 'center' }}>
                                <h5>
                                    DISPATCH VEHICLE COUNT DASHBOARD
                                </h5>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: "scroll" }}>
                                <div style={{ width: '100%', marginRight: '14px' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', }}></th>

                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', }}>M.Yard</th>

                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '5px', border: '1px solid #000', }}>NMA</th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>DSC</th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>CRM</th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Plate Mill</th>

                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Pipe Mill</th>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Total</th>

                                            </tr>
                                            <tr>
                                                <th style={tableHeaderStyle}>Date</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>0-8 Hrs</th>
                                                <th style={tableHeaderStyle}>8-16 Hrs</th>
                                                <th style={tableHeaderStyle}>16-24 Hrs</th>
                                                <th style={tableHeaderStyle}>Total</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dispatch_vehicle_data_arr.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{(row.date)}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.MY.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.MY.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.MY.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.MY.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.DSC.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.DSC.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.DSC.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.DSC.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.CRM.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.CRM.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.CRM.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.CRM.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.plate_mill.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.plate_mill.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.pipe_mill.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.pipe_mill.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.NMA.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.NMA.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.NMA.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.NMA.total_hrs}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.total.hrs_0_8}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.total.hrs_8_16}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.total.hrs_16_24}</td>
                                                    <td style={tableCellStyle}>{row.day_wise.total.total_hrs}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {dispatch_vehicle_data_arr.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                            </div>

                        </div>
                    {/* </div> */}


                {/* </div> */}
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </>
        );
    }
}

export default DispatchVehicleCountDashboard;

