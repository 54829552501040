import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ForceCloseCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            nogpslifetime:0,
            deptcode:this.props.deptcode,
            triptype:this.props.triptype,
            active_trucks:0,
            loading_delay:0,
            unloading_delay:0,
            enroute_stoppage:0,
            hidecol:true,
            deliveryconfirm:0,
            probableAccident : 0
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{
        if(newprops.deptcode != this.state.deptcode)
        {
        //console.log("Conters ",newprops.deptcode)
            if(newprops.triptype && newprops.triptype != 0)
            {
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode,
                    trip_type:newprops.triptype
                }
            }
            else{
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode
                }   
            }
            
            /*Venkatesh Code*/
            var transitdelaysarr=[];
            var gpsdatanaarr=[];
            var overspeedtrucksarr=[];
            var nightdrivearr=[];
            var enroutearr=[];
            var loadingarr=[];
            var unloadingarr=[];
            //console.log("newprops.gridData ", newprops.gridData)
            if(newprops.gridData.length > 0)
            {
               
                /*New Code for Counters*/
                var counter = newprops.countersjson;
                console.log("counter ",counter)
                var overspdcnt = 0;
                var nightdrvcnt = 0;
                var transdly = 0;
                var npgs = 0;
                var nogpslifetime=0;
                var lodelay= 0;
                var unlodelay = 0;
                var enrote=0;
                var deliveryconfirm = 0;
                var probableAccident = 0;
                if(counter.overspeeding.length > 0)
                {
                    var overspdcnt = counter.overspeeding[0].overspeeding_exception_count;
                }
                try{
                    if(counter.overspeeding.length > 0)
                    {
                        var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    }
                }catch(e){}
                if(counter.night_drive.length > 0)
                {
                    var nightdrvcnt = counter.night_drive[0].nightdriving_exception_count;
                }

                if(counter.transit_delay.length > 0)
                {
                    var transdly = counter.transit_delay[0].transit_delay_count;
                }
                

                if(counter.deliverconfirmtpt.length > 0)
                {
                    var deliveryconfirm = counter.deliverconfirmtpt[0].tpt_confirm_delivery_count;
                }
                if(counter.no_gps.length > 0)
                {
                    var npgs = counter.no_gps[0].no_gps_data_count;
                }
                //console.log("newprops.hide ",newprops.hide)
               // if(newprops.hide == true)
               // {
                    if(counter.nogpslifetime.length > 0)
                    {
                        var nogpslifetime = counter.nogpslifetime[0].no_gps_data_lifetime_count;
                    }
               // }
                if(counter.loading_delay.length > 0)
                {
                    var lodelay = counter.loading_delay[0].loading_delay_exception_count;
                }

                if(counter.unloading_delay.length > 0)
                {
                    var unlodelay = counter.unloading_delay[0].unloading_delay_exception_count;
                }

                if(counter.enroute_stoppage.length > 0)
                {
                    var enrote = counter.enroute_stoppage[0].enroute_exception_count;
                }
                //console.log("nogpslifetime ",nogpslifetime)
                await this.setState({
                    active_consignments:newprops.gridData.length,
                    overspeeding:overspdcnt,
                    night_driving:nightdrvcnt,
                    transit_delay:transdly,
                    gps_data_available:npgs,
                    nogpslifetime:nogpslifetime,
                    loading_delay:lodelay,
                    unloading_delay:unlodelay,
                    enroute_stoppage:enrote,
                    deliveryconfirm : deliveryconfirm
                });
            }
            else{
                await this.setState({
                    active_consignments:0,
                    overspeeding:0,
                    night_driving:0,
                    transit_delay:0,
                    deliveryconfirm:0,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    gps_data_available:0,
                    nogpslifetime:0,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay:0,
                    unloading_delay:0,
                    enroute_stoppage:0,
                }); 
            }
            this.setState({
                hidecol:newprops.hide
            });
        }
        

    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '')?this.state.active_consignments:0}/></span></h4>
                                    
                                </div>
                                {/* <div className="col">
                                    <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Active Trucks</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>
                                    
                                </div> */}
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div>

                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"tpt_confirm_delivery")}>
                                    <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Delivery Confirmed By Transporter</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.deliveryconfirm != '')?this.state.deliveryconfirm:0}/></span></h4>
                                    
                                </div>
                                {(this.props.deptcode == "SNDG")?
                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"probable_accident")}>
                                        <span className="f13"><i className="icofont icofont-barricade f22 txt-danger"></i><br />Probable Accident</span>
                                        <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.probableAccident != '')?this.state.probableAccident:0}/></span></h4>                                   
                                    </div>
                                :""}

                                {/* <div className="col cursorPointer">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                    <h4 className="txt-secondary f40">
                                        
                                        <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                            
                                            <CountUp className="txt-danger" end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                            <CountUp  className="txt-secondary" end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                        </span>  

                                    </h4>
                                    
                                </div>
                                
                                
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                   
                                </div>
                                {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.loading_delay != '')?this.state.loading_delay:0}/></span></h4>
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT' || (this.props.unloadshow == true))?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                    <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                </div>
                                }
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '')?this.state.enroute_stoppage:0}/></span></h4>                                   
                                </div> */}
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}






