
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const Check = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props ", props);
        props.agGridReact.props.gridOptions.context.componentParent.onShowTransitRouteMap(props.data);
    };

    return (
        <div>
			<input type="checkbox" onClick={handleClick} className="custom-btn label label-success" />
       
        </div>
    );
};

export default Check;
