
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const InvalidTicketAction = (props) => {
    
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.agGridReact.props.gridOptions.context.componentParent.onClickInvalidTicket(props);
    };

    return (
        <div>
			<button title="Invalid Ticket" onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-minus-circle f20"></i> </button>
       
        </div>
    );
};

export default InvalidTicketAction;
