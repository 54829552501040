
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const LoadGpsAction = (props) => {
    
    // const handleRouteClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onShow(props);
    // };


    return (
        <div className="map-icon-component">
            {(props.data.gps_present == 1)?
                 <button className="custom-btn f22 label label-success" 
                 title="GPS Available" ><i className={"icofont icofont-social-google-map txt-grey-light"}></i> &nbsp;</button>
                 
            :
            <button className="custom-btn f22 label label-success" 
            title="GPS Available" ><i className={"icofont icofont-social-google-map txt-danger-dark"}></i> &nbsp;</button>
           
           }
        </div>
    );
};

export default LoadGpsAction;
