
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const LoadMapAction = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowRouteDiv(props.data);
    };
    var truck_type = props.data.truck_type;
    var disable = 0;
    if(truck_type == "ROAD")
    {
        disable = 1;
    }
    return (
        <div className="map-icon-component">
            {(disable == 0)?
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25"></i> </button>
            :<button className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f25" style={{color:"grey"}}></i> </button>}
        </div>
    );
};

export default LoadMapAction;
