import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';

import Returntripmap from "./returntripmap";
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class ReturnTrips extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true
			},
			rowData: [],
            //headerHeight: 60,
            //rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
                returntripmap:Returntripmap
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Return Trips",
            originaldata:"",
            orignalresponse:"",
            mapinfo:"",
            sliderRouteTranslate:"",
            open:false,
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            geofences:[],
            boundaries:[],
            samedist:false
        };
        this.onLoadPageData = this.onLoadPageData.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
       
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
       this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        this.setState({
            loadshow:'show-m'
        })
        var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
		var edate = moment.parseZone().format('YYYY-MM-DD');
    //    console.log("sdate ", sdate)
    //    console.log("edate ", edate)
       this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsDate:sdate,
            defaulteDate:edate,
       })
       var parameters = {
            startdate:sdate,
            enddate:edate,
            samedist:this.state.samedist
        }
       this.onLoadPageData(parameters)
       redirectURL.post("/consignments/returntripsgeofences")
        .then((response)=> {
            //console.log("response ", response.data)
            var records = response.data;
            var recordsarr = []
            var boundaries = []
            if(records.length > 0)
            {
                records.map((item) => {
                    recordsarr.push({
                        lat:item.centroid_lat,
                        lng:item.centroid_lng,
                        name:item.geofence_name
                    })
                    boundaries.push(JSON.parse(item.coordinates))
                })
            }
            this.setState({
                geofences:recordsarr,
                boundaries:boundaries
            })
        })
	}
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    
    onLoadPageData(parameters)
    {
        redirectURL.post("/consignments/returntrips",parameters)
        .then((response)=> {
            //console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                rowData:records,
                loadshow:'show-n',
                overly:'show-n'
            })
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:''
		});
		
    }
    

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickGetData(){
        this.setState({
            loadshow:'show-m'
        })
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            var sDate = this.state.defaultsDate;
        }
        else{
            var sDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
        {
            var eDate = this.state.defaulteDate;
        }
        else{
            var eDate = this.state.endDate;
        }
        if(eDate == undefined || eDate == "")
        {
            var etdate = this.state.defaulteDate;
        }
        else{
            var etdate = eDate
        }
        if(sDate == undefined || sDate == "")
        {
            var stdate = this.state.defaultsDate;
        }
        else{
            var stdate = sDate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
            samedist:this.state.samedist
        }
        //console.log("parameters ",parameters)
       this.onLoadPageData(parameters)
    }

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onShowRouteDiv(params){
        this.setState({
            loadshow:'show-m',
            overly:'show-m'
        })
        //console.log("params ", params)
        var parameters = {
            truck_no:params.truck_no,
            from_date:getHyphenYYYYMMDDHHMMSS(params.return_start_time),
            to_date:getHyphenYYYYMMDDHHMMSS(params.return_end_time)
        }
        redirectURL.post("/consignments/returntripmap",parameters)
        .then((response) => {
            console.log("routes ", response.data.data)
            this.setState({
                sliderRouteTranslate:"slider-translate-60p",
                mapinfo:JSON.parse(response.data.data),
                loadshow:'show-n',
			    overly:'show-m'
            })
        })
    }
    onClickSameDist(){
        this.setState({
            samedist:!this.state.samedist
        })
    }
    render(){
       const columnDefs = [
            {
                headerName:"",
                field:"_id",
                width:50,
                cellRenderer:'returntripmap',
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:160
            },
            {
                headerName:"Previous Gateout Time",
                field:"prev_gate_out_time",
                width:180,
                valueGetter:function(params){
                        if(params.data.prev_gate_out_time != '' && params.data.prev_gate_out_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.prev_gate_out_time);
                        }
                        else{
                            return "NA";
                        }
                }
            },
            {
                headerName:"Previous Consignments",
                field:"consignment_codes",
                width:160
            },
            {
                headerName:"Next Trip Consignments",
                field:"next_trip_consignment_codes",
                width:160
            },
            {
                headerName:"Consigner Code",
                field:"consigner_code",
                width:160
            },
            {
                headerName:"Consignee City",
                field:"consignee_city",
                width:160
            },
            
            {
                headerName:"Return Start Time",
                field:"return_start_time",
                width:180,
                valueGetter:function(params){
                        if(params.data.return_start_time != '' && params.data.return_start_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.return_start_time);
                        }
                        else{
                            return "NA";
                        }
                }
            },
            {
                headerName:"Return End Time",
                field:"return_end_time",
                width:180,
                valueGetter:function(params){
                        if(params.data.return_end_time != '' && params.data.return_end_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.return_end_time);
                        }
                        else{
                            return "NA";
                        }
                }
            },

            {
                headerName:"Expected Distance",
                field:"expected_distance",
                width:140,
                valueGetter:function(params){
                    try{
                        //console.log("params ",params)
                        if(params.data.expected_distance != "undefined" && params.data.expected_distance != undefined)
                        {
                            return params.data.expected_distance+" kms"
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },

            {
                headerName:"Total Distance",
                field:"total_distance_without_google",
                width:140,
                valueGetter:function(params){
                    try{
                        
                        if(params.data.total_distance_without_google != "undefined" && params.data.total_distance_without_google != undefined)
                        {
                            return params.data.total_distance_without_google+" kms"
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },

            {
                headerName:"Total Distance (Google)",
                field:"total_distance_with_google",
                width:140,
                valueGetter:function(params){
                    try{
                        
                        if(params.data.total_distance_with_google != "undefined" && params.data.total_distance_with_google != undefined)
                        {
                            return params.data.total_distance_with_google+" kms"
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Route",
                field:"route_id",
                width:160
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:160
            },
            {
                headerName:"Is Transshipment",
                field:"is_transshipment",
                width:160,
                valueGetter:function(params){
                    if(parseInt(params.data.is_transshipment) == 1)
                    {
                        return "Yes";
                    }
                    else
                    {
                        return "No";
                    }
                }
            },
            {
                headerName:"Transshipment Truck Type",
                field:"transshipment_truck_type",
                width:160
            },

            {
                headerName:"Next Trip Consigner Code",
                field:"next_trip_consigner_code",
                width:140
            },
            {
                headerName:"Next Trip Cluster",
                field:"next_trip_cluster",
                width:120
            },
            {
                headerName:"Next Trip Route ID",
                field:"next_trip_route_id",
                width:140
            },
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                        <form className="row">
                            <div className="row col-xl-12 col-lg-12">

                                <div className="col-xl-3 col-lg-3 form-group">
                                    <span className="mt-30p float-left">
                                        <input type="checkbox" name="samedest" checked={this.state.samedist} id="samedest" onClick={this.onClickSameDist.bind(this)} />
                                        &nbsp;Ignore Same Destination
                                    </span>
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>From Date</label>
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                    {/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

                                    {/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

                                </div>

                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>To Date</label>
                                    {/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <button type="button" className="btn btn-success mt-30p" onClick={this.onClickGetData.bind(this)}>Submit</button>
                                </div>
                            
                            </div> 
                        </form>

                    </div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pagetitle}</span>
				       			</h5>
				   			</div>
				   			<div className="card-body row">

								<div className="row col-xl-12 col-lg-12">
								   
                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                frameworkComponents={this.state.frameworkComponents}
                                               
                                            />
                                        </div>
                                  
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

                 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Map Route
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this}
                                    mapFor={"returntrips"}
                                    boundaries={this.state.boundaries}
                                    mapinfo={this.state.mapinfo}
                                    consignercords={this.state.geofences}
                                />
								
							</div>
						</div>
						
					</div>
				:""	
				}

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
            </div>
              
        );
    }
}
