import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault } from '../common/utils';

import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import LoadColumnChart from "./loadplantcolumnchart";
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class LoadPlanningToDashboard extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:100,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            dept_code:"",
            pagetitle:"",
            overly :"show-n",
            startmonth:{"value":"","label":"Start Month"},
            endmonth:{"value":"","label":"End Month"},
            startyear:{"value":"","label":"Start Year"},
            endyear:{"value":"","label":"End Year"},
            financialyear:{"value":"","label":"Select"},
            invoiceseries:[],
            datatab:"show-m",
            charttab:"show-n",
            viewtype:{"value":"",lable:"View Type"},
            financialyears:[],
            allrecords8hrs:[],
            allrecords:[],
            totalvins:0,
            totalvins8hrs:0
        };
        this.onLoadData = this.onLoadData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        this.setState({
            //overly : "show-m",
            loadshow:'show-n',
        })

        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="Load Planning To Dashboard "
        var dept_code = "SNDG";
        var israil=0;
        var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
        'September', 'October', 'November', 'December'] 
        var startmonth = moment.parseZone().subtract(2, 'months').format("MM")
        // console.log("startmonth ", parseInt(startmonth))
        var smonthtext = mopts[parseInt(startmonth)-1]
        var endmonth = moment.parseZone().format("MM");
        var emonthtext = mopts[parseInt(endmonth)-1]
        var year = moment.parseZone().format("YYYY");
        this.setState({            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle,
            startyear:{value:year,label:year},
            startmonth:{value:startmonth,label:smonthtext},
            endmonth:{value:endmonth,label:emonthtext},
            viewtype:{value:"invoice",label:"Invoice to Retrieval"}
            
        });
        var parameters= {
            startyear:parseInt(year),
            startmonth:parseInt(startmonth),
            endmonth:parseInt(endmonth),
            viewtype:{value:"invoice",label:"Invoice to Retrieval"}
        }
        this.onLoadData(parameters) 
        
        redirectURL.post("consignments/loadplanfys",parameters)
        .then((response) => {
            console.log("resposne ", response.data)
            var fys = response.data.records
            var financialyears = []
            if(fys.length > 0)
            {
                fys.map((item) => {
                    financialyears.push(item)
                })
            }
            this.setState({
                financialyears:financialyears
            })
        })

    }
    
    onLoadData(parameters){
        redirectURL.post("consignments/loadplandashboardparams",parameters)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records;
            var allrecords = response.data.allrecords;
            var allrecords8hrs = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(parameters.viewtype == "invoice")
                    {
                        if(item.ret_to_inv_duration_hrs >= 8)
                        {
                            allrecords8hrs.push(item)
                        }
                    }
                    else
                    {
                        if(item.gate_out_to_ret_duration_hrs >= 8)
                        {
                            allrecords8hrs.push(item)
                        }
                    }
                })
            }
            this.setState({
                rowData:records,
                allrecords:allrecords,
                totalvins:allrecords.length,
                totalvins8hrs:allrecords8hrs.length,
                allrecords8hrs:allrecords8hrs,
                loadshow:'show-n',
            })
            
        })
    
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }

    
	changeStartMonthHandler = startmonth =>{
        this.setState(
	      { startmonth },
	      () => console.log(`Month Option selected:`, this.state.startmonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectStartMonths(){
        var items = []
        items.push({value:'',label:'Month'});
        items.push({value:'01',label:'Jan'});
        items.push({value:'02',label:'Feb'});
        items.push({value:'03',label:'Mar'});
        items.push({value:'04',label:'Apr'});
        items.push({value:'05',label:'May'});
        items.push({value:'06',label:'Jun'});
        items.push({value:'07',label:'Jul'});
        items.push({value:'08',label:'Aug'});
        items.push({value:'09',label:'Sep'});
        items.push({value:'10',label:'Oct'});
        items.push({value:'11',label:'Nov'});
        items.push({value:'12',label:'Dec'}); 
		return items
    }
    
    
	changeEndMonthHandler = endmonth =>{
        this.setState(
	      { endmonth },
	      () => console.log(`Month Option selected:`, this.state.endmonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectEndMonths(){
        var items = []
        items.push({value:'',label:'Month'});
        items.push({value:'01',label:'Jan'});
        items.push({value:'02',label:'Feb'});
        items.push({value:'03',label:'Mar'});
        items.push({value:'04',label:'Apr'});
        items.push({value:'05',label:'May'});
        items.push({value:'06',label:'Jun'});
        items.push({value:'07',label:'Jul'});
        items.push({value:'08',label:'Aug'});
        items.push({value:'09',label:'Sep'});
        items.push({value:'10',label:'Oct'});
        items.push({value:'11',label:'Nov'});
        items.push({value:'12',label:'Dec'}); 
		return items
    }
        
	changeStartYearHandler = startyear =>{
        this.setState(
	      { startyear },
	      () => console.log(`Year Option selected:`, this.state.startyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectStartYear(){
        var items = []
        var cyear = moment.parseZone().format("YYYY");
        items.push({value:'',label:'Start Year'});
        for(var y = 2021;y<=cyear;y++)
        {
            items.push({value:y,label:y});
        } 
		return items
    }
    
	changeEndYearHandler = endyear =>{
        this.setState(
	      { endyear },
	      () => console.log(`Year Option selected:`, this.state.endyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectEndYear(){
        var items = []
        var cyear = moment.parseZone().format("YYYY");
        items.push({value:'',label:'Start Year'});
        for(var y = 2021;y<=cyear;y++)
        {
            items.push({value:y,label:y});
        }
		return items
    }
	changeFinancialYearHandler = financialyear =>{
        this.setState(
	      { financialyear },
	      () => console.log(`Financial Option selected:`, this.state.financialyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectFinancialYears(){
        var items = []
        items.push({value:'',label:'Select'});
        var fys = this.state.financialyears;
        if(fys.length > 0)
        {
            fys.map((item) => {
                items.push({value:item,label:item});
            })
        }
        
		return items
    }
     
	changeViewTypeHandler = viewtype =>{
        this.setState(
	      { viewtype },
	      () => console.log(`Viewtype Option selected:`, this.state.viewtype)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    onClickFilterData(){
        this.setState({
            //overly : "show-m",
            loadshow:'show-m',
        })
        var startmonth = this.state.startmonth
        var endmonth = this.state.endmonth
        var startyear = this.state.startyear
        var viewtype = this.state.viewtype
        if(startmonth.value != "" && endmonth.value != "" && startyear.value != "" && viewtype.value != "")
        {
            var parameters = {
                startmonth:parseInt(startmonth.value),
                endmonth:parseInt(endmonth.value),
                startyear:parseInt(startyear.value),
                viewtype:viewtype.value,
            }
            this.onLoadData(parameters)
            // redirectURL.post("consignments/loadplandashboardparams",parameters)
            // .then((response) => {
            //     console.log("response ", response.data)
            //     var records = response.data.records;
                
            //     this.setState({
            //         rowData:records
            //     })
                
            // })
        }
        else{
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"All * fields are mandatory"
            })
        }
        
    }
    onClickFilterFinancialYearData(){
        this.setState({
            loadshow:'show-m',
        })
        var viewtype = this.state.viewtype
        var financialyear = this.state.financialyear
        if(financialyear.value != "" && viewtype.value != "")
        {
            var parameters = {
                financialyear:financialyear.value,
                viewtype:viewtype.value
            }
            redirectURL.post("consignments/loadplandashboardfy",parameters)
            .then((response) => {
                // console.log("response ", response.data)
                var records = response.data.records;
                var allrecords = response.data.allrecords;
                var allrecords8hrs = []
                if(records.length > 0)
                {
                    records.map((item) => {
                        if(parameters.viewtype == "invoice")
                        {
                            if(item.ret_to_inv_duration_hrs >= 8)
                            {
                                allrecords8hrs.push(item)
                            }
                        }
                        else
                        {
                            if(item.gate_out_to_ret_duration_hrs >= 8)
                            {
                                allrecords8hrs.push(item)
                            }
                        }
                    })
                }
                this.setState({
                    rowData:records,
                    allrecords:allrecords,
                    totalvins:allrecords.length,
                    totalvins8hrs:allrecords8hrs.length,
                    allrecords8hrs:allrecords8hrs,
                    loadshow:'show-n',
                })
                
            })
        }
        else{
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"All * fields are mandatory",
                loadshow:'show-n',
            })
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    onClickCounter(clickable){
        if(clickable == "all")
        {
            this.setState({
                rowData:this.state.allrecords
            })
        }
        if(clickable == "totalvins8hrs")
        {
            this.setState({
                rowData:this.state.allrecords8hrs
            })
        }
    }
    render(){
        var viewtype = this.state.viewtype;
        console.log("viewtype ", viewtype)
        if(viewtype.value == "invoice")
        {
            var hideinv = false;
            var hideret = true;
        }
        else
        {
            var hideinv = true;
            var hideret = false;
        }
       var columnwithDefs = [
            {
               headerName:"Vin No",
               field:"vin",
               width:140
            },
            {
                headerName:"Plant Name",
                field:"plant_name",
                width:140
            },
            {
                headerName:"Trip Type",
                field:"trip_type",
                width:140
            },
            {
                headerName:"Month",
                field:"month",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.month != "")
                        {
                            var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
                            'September', 'October', 'November', 'December'] 
                            return mopts[parseInt(params.data.month)-1];
                        }
                    }
                    catch(e){

                    }
                }
            },
            {
                headerName:"Year",
                field:"year",
                width:140
            },
            {
                headerName:"Financial Year",
                field:"financial_year",
                width:140
            },
            {
                headerName:"Invoice Date",
                field:"invoice_date",
                hide:hideinv,
                width:140,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.invoice_date);
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Retrival Date",
                field:"retrival_date",
                width:140,
                // hide:hideret,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.retrival_date);
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Gateout Time",
                field:"gate_out_time",
                width:140,
                hide:hideret,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.gate_out_time);
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName:"Invoice to Retrieval Duration (Hrs)",
                field:"ret_to_inv_duration_hrs",
                hide:hideinv,
                width:140
            },
            {
                headerName:"Retrieval to Gateout Duration (Hrs)",
                field:"gate_out_to_ret_duration_hrs",
                width:140,
                hide:hideret
            }
        ]
        return(
            <div class="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="row">
                            <div className="row col-sm-12 col-xl-12  col-lg-12">
                                <form method="POST" className="col-xl-8 col-lg-8"> 
                                                                        
                                <div className="row card-body">                                       
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <lable> Start Month *: </lable>
                                        <Select placeholder={"Start Month"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.startmonth} 
                                        className="border-radius-0" 
                                        onChange={this.changeStartMonthHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectStartMonths()} 
                                        required />                                        
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <lable>End Month *:</lable>
                                        <Select placeholder={"End Month"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.endmonth} 
                                        className="border-radius-0" 
                                        onChange={this.changeEndMonthHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectEndMonths()} 
                                        required />                                        
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <lable> Year *:</lable>
                                        <Select placeholder={"Start Year"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.startyear} 
                                        className="border-radius-0" 
                                        onChange={this.changeStartYearHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectStartYear()} 
                                        required />                                        
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <lable>View Type *:</lable>
                                        <Select placeholder={"View Type"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.viewtype} 
                                        className="border-radius-0" 
                                        onChange={this.changeViewTypeHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[
                                            {
                                                value:"invoice",
                                                label:"Invoice to Retrieval"
                                            },
                                            {
                                                value:"retrieval",
                                                label:"Retrieval to Exit"
                                            }
                                        ]} 
                                        required />                                        
                                    </div>
                                       
                                    <div className="form-group col-xl-4 col-lg-4">                                        
                                        <button 
                                        type="button" 
                                        className="btn btn-warning mt-22p"
                                        onClick={this.onClickFilterData.bind(this)}
                                        >Submit</button>
                                    
                                    </div>
                                </div>
                                </form>
                                <form method="POST" className="col-xl-4 col-lg-4" style={{borderLeft:"1px solid #ccc"}}>               
                                <div className="row card-body">                                          
                                    <div className="form-group col-xl-5 col-lg-5">
                                    
                                        <lable>Financial Year *:</lable>
                                        <Select placeholder={"Select "} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.financialyear} 
                                        className="border-radius-0" 
                                        onChange={this.changeFinancialYearHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectFinancialYears()} 
                                        required />
                                    
                                    </div>
                                    <div className="form-group col-xl-7 col-lg-7">
                                        <lable>View Type *:</lable>
                                        <Select placeholder={"View Type"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.viewtype} 
                                        className="border-radius-0" 
                                        onChange={this.changeViewTypeHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={[
                                            {
                                                value:"invoice",
                                                label:"Invoice to Retrieval"
                                            },
                                            {
                                                value:"retrieval",
                                                label:"Retrieval to Exit"
                                            }
                                        ]} 
                                        required />                                        
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">                                        
                                        <button 
                                        type="button" 
                                        className="btn btn-warning mt-22p"
                                        onClick={this.onClickFilterFinancialYearData.bind(this)}
                                        >Submit</button>
                                    
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Vins<br /><br /></span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalvins != '')?this.state.totalvins:0}/></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"totalvins8hrs")}>
                                            <span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br /> Total Vin ( &gt;= 8 Hrs)<br /></span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.totalvins8hrs != '')?this.state.totalvins8hrs:0}/></span></h4>
                                            
                                        </div>
                                       
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                            </h5>
                        </div>
                        <div className="row card-body">
                            <div className="row col-sm-12 col-xl-12  col-lg-12">
                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                            
                            </div>
                            
                        </div>
                    </div>

                </div> 
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

        

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
function arrayMax(arr) {
return arr.reduce(function (p, v) {
    return ( p > v ? p : v );
});
}
