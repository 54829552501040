
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ShowTPTEtaLegs = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowETALegs(props);
    };

    return (
        <div>
          	<span onClick={handleOverspeedClick} className={"f25 "} title="View Legs" >
                  <i className="icofont icofont-site-map f25"></i>
              </span>
          
        </div>
    );
};

export default ShowTPTEtaLegs;
