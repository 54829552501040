import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import $ from "jquery";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Map from './mapLocationComponent';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
}from 'react-accessible-accordion';



export default class AlloyBarForceClose extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
			rowData: null,
			animateRows: true,
            reasonforceclose : "",
            msil : "",
            selectedReason : "",
            displayReasonField : "show-n"

        };
       // console.log("Contxt", this.props.context);
       // this.showRelatedData()
       
    } 
    componentDidMount(){
        loadDateTimeScript();
        console.log("sidebar");
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({reasonforceclose:value});
    }
    setMSIL = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        // console.log(value);
        this.setState({msil:value});
    }
    handleSubmit(){
        var valuesArr = [this.state.reasonforceclose,this.state.msil]
        this.props.context.handleInput(valuesArr);
    }

    render(){
    
        return ( 
                <div className="col-xl-12 co-xl-12">
                     <div className="form-group col-xl-12 col-lg-12">
                        <label>MSIL Gate in</label>
                        <input type="text" placeholder="MSIL Gate In" onBlur={this.setMSIL.bind(this)} className="datetimepicker_date form-control" required />  
                    </div>
                    <div className="form-group col-xl-12 col-lg-12">
                        <label className="col-xl-12 col-lg-12">Reason for closure</label>
                        <textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)}  rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
                    </div>
                    <div className="form-group col-xl-12 col-lg-12">
                        <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
                    </div>
                </div>
			);
		}
}
function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y',
        onShow:false,
        timepicker : false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
