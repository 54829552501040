import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class LoadAttachmentCounters extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            records:[],
            allRowDatacount:0,
            totalLoads:0,
            retrivalyettostart:0,
            retrivalinprogress:0,
    		retrivalcomplete:0,
            gatein:0,
            invoiced:0,
            totalloadscars:0,
            yettostartcars:0,
            inprogresscars:0,
    		invoicedcars:0,
            completedcars:0,
            gateincars:0,
            pendinginvoicecars:0,
            pendinginvoice:0,
            screen:'',
            pendingtptcnt:0,
            pendingplantcnt:0,
            gateout:0,
            gateoutcars:0
    	}
    
    }
    
    componentWillReceiveProps(newprops){
        //console.log("Newporps ", newprops)
       this.setState({
            totalLoads:newprops.totalLoads,
            retrivalyettostart:newprops.retrivalyettostart,
            retrivalinprogress:newprops.retrivalinprogress,
            retrivalcomplete:newprops.retrivalcomplete,
            gatein:newprops.gatein,
            invoiced:newprops.invoiced,
            pendinginvoice:newprops.pendinginvoice,
            totalloadscars:newprops.totalloadscars,
            yettostartcars:newprops.yettostartcars,
            inprogresscars:newprops.inprogresscars,
    		invoicedcars:newprops.invoicedcars,
            completedcars:newprops.completedcars,
            pendinginvoicecars:newprops.pendinginvoicecars,
            gateincars:newprops.gateincars,
            screen:newprops.screen,
            pendingtptcnt:newprops.pendingtptcnt,
            pendingplantcnt:newprops.pendingplantcnt,
            gateout:newprops.gateout,
            gateoutcars:newprops.gateoutcars
       })

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        var hideRetrievals = 0;
        var cardCol = "col-xl-2";
        if(this.state.screen == 'exitloads')
        {
            var gtcol = 'col-xl-6';
            var clcol = 'col-xl-4';
            cardCol = "col-xl-4"
            hideRetrievals = 1;
        }
        else{
            var gtcol = 'col-xl-12';
            var clcol = 'col-xl-2';
        }
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
               
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className={"col cirlce-d cpointer p-5px "+cardCol} onClick={this.onClickCounter.bind(this,0)}>
                                         
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">Active Loads</span>
                                        </div>
                                        <div className="row card-body p-10px">
                                            <div className="col col-xl-12 col-lg-12" onClick={this.onClickCounter.bind(this,0)}> 
                                                <span className="f12"><i className="icofont icofont-settings cus-i txt-info f24"></i><br /><br /></span>
                                                <h4 className="txt-info f26"><span className="counter"><CountUp end={(this.state.totalLoads != '')?this.state.totalLoads:0}/></span></h4>
                                            
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.totalloadscars != '')?this.state.totalloadscars:0}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                                {(hideRetrievals == 0)?
                                    <div className="col col-xl-4 cpointer p-5px">
                                        <div className="card n-p-0">
                                            <div className="card-header">
                                                <span className="f12">Retrivals</span>
                                            </div>
                                            <div className="row card-body p-10px">

                                                <div className="col col-xl-4 col-lg-4 cpointer" onClick={this.onClickCounter.bind(this,1)}>
                                                    <span className="f12"><i className="icon-time f22 txt-primary-dark"></i><br /> Pending  </span>
                                                    <h4 className="txt-primary f26"><span className="counter">
                                                        <CountUp end= {(this.state.retrivalyettostart)?this.state.retrivalyettostart:0}/>
                                                        </span></h4>
                                                    <div className=" f12">
                                                        <div className="counter txt-success-medium">
                                                        Cars: &nbsp;
                                                            <CountUp end={(this.state.yettostartcars != '')?this.state.yettostartcars:0}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col col-xl-4 col-lg-4 cpointer" onClick={this.onClickCounter.bind(this,2)}>
                                                    <span className="f12"><i className="icofont icofont-settings-alt f25 txt-secondary-dark"></i><br />  In Progress</span>
                                                    <h4 className="txt-secondary f26"><span className="counter">
                                                        <CountUp end= {(this.state.retrivalinprogress)?this.state.retrivalinprogress:0}/>
                                                        </span></h4>
                                                    <div className=" f12">
                                                        <div className="counter txt-success-medium">
                                                        Cars: &nbsp;
                                                            <CountUp end={(this.state.inprogresscars != '')?this.state.inprogresscars:0}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col col-xl-4 col-lg-4" onClick={this.onClickCounter.bind(this,3)}>  
                                                    <span className="f12"><i className="icofont icofont-checked f24 txt-pink-light"></i><br />  Completed </span>
                                                    <h4 className="txt-pink-medium f26"><span className="counter"><CountUp end={(this.state.retrivalcomplete)?this.state.retrivalcomplete:0}/></span></h4>
                                                
                                                    <div className=" f12">
                                                        <div className="counter txt-success-medium">
                                                        Cars: &nbsp;
                                                            <CountUp end={(this.state.completedcars != '')?this.state.completedcars:0}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                :""}
                                
                                <div className={"col cpointer p-5px "+(clcol)}>
                                    <div className="card n-p-0">
                                        <div className="card-header">
                                            <span className="f12">Invoice</span>
                                        </div>
                                        <div className="row card-body p-10px">    
                                            
                                            <div className="col col-xl-6 col-lg-6 cpointer" onClick={this.onClickCounter.bind(this,6)}>
                                                <span className="f12"><i className="icofont icofont-sand-clock f22 txt-secondary"></i><br /> Pending </span>
                                                <h4 className="txt-secondary f26"><span className="counter">
                                                    <CountUp end= {(this.state.pendinginvoice)?this.state.pendinginvoice:0}/>
                                                    </span></h4>
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.pendinginvoicecars != '')?this.state.pendinginvoicecars:0}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-xl-6 col-lg-6" onClick={this.onClickCounter.bind(this,5)}>  
                                                <span className="f12"><i className="icofont icofont-check-circled f22 txt-primary-dark"></i><br /> Completed </span>
                                                <h4 className="txt-primary f26"><span className="counter">
                                                    <CountUp end= {(this.state.invoiced)?this.state.invoiced:0}/>
                                                    </span></h4>
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.invoicedcars != '')?this.state.invoicedcars:0}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className={"col cpointer p-5px "+(clcol)}>
                                    <div className="card">
                                        <div className="card-header">
                                            <span className="f12">Loading</span>
                                        </div>
                                        <div className="row card-body p-10px">  
                                            <div className={"col cpointer "+(gtcol)} onClick={this.onClickCounter.bind(this,4)}>
                                                <span className="f12"><i className="icofont icofont-login f22 txt-info-medium"></i><br />Gate In </span>
                                                <h4 className="txt-info-dark f26"><span className="counter"><CountUp end={(this.state.gatein)?this.state.gatein:0}/></span></h4>
                                            
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.gateincars != '')?this.state.gateincars:0}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {(this.state.screen == 'exitloads')?
                                            <div className="col col-xl-6 cpointer" onClick={this.onClickCounter.bind(this,7)}>
                                                <span className="f12"><i className="icofont icofont-logout f22 txt-info-medium"></i><br />Gate Out </span>
                                                <h4 className="txt-info-dark f26"><span className="counter"><CountUp end={(this.state.gateout)?this.state.gateout:0}/></span></h4>
                                            
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.gateoutcars != '')?this.state.gateoutcars:0}/>
                                                    </div>
                                                </div>
                                            </div>
                                            :""}
                                            
                                        </div>
                                    </div>
                                </div>
                                {(this.state.screen == 'loads')?
                                <div className="col col-xl-2 cpointer c-pd">
                                    <div className="card">
                                        <div className="card-header">&nbsp;</div>
                                        <div className="row card-body p-10px">  
                                        
                                        {(typeof localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == undefined )
                                            ?
                                            (this.state.screen == 'loads')?
                                            <div className="col col-xl-12 cpointer" onClick={this.onClickCounter.bind(this,8)}>
                                                <span className="f12"><i className="icofont icofont-clock-time f22 txt-danger"></i><br />Pending Acknowledgement </span>
                                                <h4 className="txt-danger f26"><span className="counter"><CountUp end={(this.state.pendingplantcnt)?this.state.pendingplantcnt:0}/></span></h4>
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            :""
                                            :
                                            (this.state.screen == 'loads')?
                                            <div className="col col-xl-12 cpointer" onClick={this.onClickCounter.bind(this,9)}>
                                                <span className="f12"><i className="icofont icofont-clock-time f22 txt-danger"></i><br />Pending Acknowledgement</span>
                                                <h4 className="txt-danger f26"><span className="counter"><CountUp end={(this.state.pendingtptcnt)?this.state.pendingtptcnt:0}/></span></h4>
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                     &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            :""
                                        }
                                        </div>
                                    </div>
                                </div>
                                :""}
                            </div>
                        </div>
                       
                    </div>
                
           
            </div>
        );
    }
}






