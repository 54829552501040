import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
    getHyphenYYYYMMDD, 
    getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import TransitLineChart from './transitdashboardlinechart';
import ColumnGroupGPS from './columngroupgps';
import ColumnGPSGroupChart from './columngpsgroupchart';
import LineGPSDashboard from './linegpsdashboard';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

var moment = require('moment');
var allmonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
       
export default class GPSDashboard extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot : true,
                // enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            rowDataExcluded:[],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'',
            departmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-n',
            showgpschart:'show-m',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            tripclosureallsummary:[],
            tripclosureprimarysummary:[],
            tripclosuresecondsummary:[],
            tripcategories:[],
            tripfcclosureallsummary:[],
            tripfcclosureprimarysummary:[],
            tripfcclosuresecondsummary:[],
            tripfccategories:[],
            monthlydataaall:[],
            monthlyprimarydata:[],
            monthlysecondarydata:[],
            shownormalprimary:'show-n',
            shownormalall:'show-m',
            shownormalsecond:'show-n',
            showfcprimary:'show-n',
            showfcsecond:'show-n',
            showfcall:'show-m',
            pchecked:false,
            schecked:false,
            achecked:true,
            afcchecked:false,
            sfcchecked:false,
            afcchecked:true,
            monthlyfcprimarydata:[],
            monthlyfcsecondarydata:[],
            monthlyfcalldata:[],
            dept_code:'',
            selectstartmonth:'',
            selectendmonth:'',
            selectstartyear:'',
            selectendyear:'',
            monthlywisegpsprovider:[],
            selectgps:{"value":"", "label":""},
            gpsproviderlist:[],
            monthlywisegpspacket:[],
            gpsdata:[],
            transmonthlywisegpsprovider:[],
            gpsprovider:'',
            gpstransportdata:[],
            transmonthlywisegpspacketprovider:[],
            onlymonth:'',
            selectmonth:'',
            monthlydata:[],
            monthlypacketwisedata:[],
            selectgpstype:{"value":"0","label":"All"},
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"GPS Dashboard",
            eventGridAction: "gridAction",
            
        }
    }

    componentWillReceiveProps(newprops){
        //console.log("newprops.dept_code ", newprops.dept_code)

		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var month = moment.parseZone().format("M Y")
        var onlymonth = moment.parseZone().format("M")
        var onlyyear = moment.parseZone().format("Y")

        var currentmonth = moment.parseZone(new Date()).format("MMM")
        if(newprops.dept_code != this.state.dept_code)
        {
            
            //console.log("currentmonth ", currentmonth)
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
                transporter:{"value": "all", "label":'All'},
                selectstartmonth:{"value": "5", "label":'May'},
                selectendmonth:{"value": onlymonth, "label":currentmonth},
                selectstartyear:{"value": 2020, "label":2020},
                selectendyear:{"value": onlyyear, "label":onlyyear}
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            if(newprops.dept_code == 'all')
            {
                var dept_code="SNDG";
            
            }
            else{
                var dept_code=newprops.dept_code;
            
            }
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            // var preqparams = {
            //     dept_code:dept_code
            // }
            this.setState({
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                locations:[],
                month:month,
                onlymonth:onlymonth,
                selectmonth:{"value":onlymonth,"label":currentmonth},
                transporters:[]
            })
            var preqparams = {
                dept_code:dept_code,
                startMonth:5,
                endMonth:onlymonth,
                startYear:2020,
                endYear:onlyyear,
                gpstype:this.state.selectgpstype
            }
            

            var paramtrs = {
                dept_code:dept_code,
                month:onlymonth,
                gpstype:this.state.selectgpstype
            }
            this.onShowMonthWiseData(preqparams);

            this.onShowTransporterwiseData(paramtrs);
        }
        
    }
    
    onClickGetLoadingData(event){
        
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }


    monthoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
       var months = allmonths;
        if(months.length > 0){
            months.forEach((item,key) =>{
                items.push({value:(key+1),label:item}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }

    yearoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
       items.push({"label": 2020,"value": 2020})
       var onlyyear = moment.parseZone().format("Y")
       for(var i=2021; i<= onlyyear; i++)
       {
           items.push({"label": i, "value": i})
       }
        console.log(items);
        return items;
    }
    gpsprovidersoptions(){
        var items=[];
        var gpsproviders = this.state.gpsproviderlist;
        if(gpsproviders.length > 0){
            gpsproviders.forEach((item,key) =>{
                items.push({value:item,label:item}); 
            });
        }
        return items;
    }

    gpstripoptions(){
        var items = [];
        items.push({value:"0","label":"All"})
        items.push({value:"1","label":"On Trip"})
        return items;
    }

    /*Month wise*/
    onSelectStartMonth = async (selectstartmonth) => {
        //console.log("Month ", selectmonth)
        await this.setState(
			{ selectstartmonth },
			() => console.log(`Month Option selected:`, this.state.selectstartmonth)
		  );
		  
        // await this.onShowMonthWiseData();
        // await this.onShowTransporterwiseData();
        
    }


    onSelectEndMonth = async (selectendmonth) => {
        //console.log("Month ", selectmonth)
        await this.setState(
			{ selectendmonth },
			() => console.log(`Month Option selected:`, this.state.selectendmonth)
		  );
		  
        // await this.onShowMonthWiseData();
        // await this.onShowTransporterwiseData();
        
    }


    onSelectStartYear = async (selectstartyear) => {
        //console.log("Month ", selectmonth)
        await this.setState(
			{ selectstartyear },
			() => console.log(`Year Option selected:`, this.state.selectstartyear)
		  );
		  
        // await this.onShowMonthWiseData();
        // await this.onShowTransporterwiseData();
        
    }


    onSelectEndYear = async (selectendyear) => {
        //console.log("Month ", selectendyear)
        await this.setState(
			{ selectendyear },
			() => console.log(`Year Option selected:`, this.state.selectendyear)
		  );
		  
        // await this.onShowMonthWiseData();
        // await this.onShowTransporterwiseData();
        
    }
    onClickGPSData = async() =>{

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Filters",
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var preqparams = {
            dept_code:this.state.dept_code,
            startMonth:this.state.selectstartmonth.value,
            endMonth:this.state.selectendmonth.value,
            startYear:this.state.selectstartyear.value,
            endYear:this.state.selectendyear.value,
            gpstype:this.state.selectgpstype
        }
        console.log(preqparams)
        // await redirectURL.post("/dashboard/gpsdashboard", preqparams)
        // .then((response) => {
        //     console.log("Response ", response.data)
        //     var records = JSON.parse(response.data.mon_lsp_wise_data);
        //     this.setState({
        //         gpsdata:records
        //     })
        // })
        // .catch(function(e){
        //     console.log("Error ", e)
        // })
        await this.onShowMonthWiseData(preqparams);
        //await this.onShowTransporterwiseData();
    }
    onShowMonthWiseData(parameters){
        this.setState({
            monthlywisegpsprovider:[],
            monthlywisegpspacket:[]
        })
        
        redirectURL.post("/dashboard/gpsdashboard",parameters)
        .then((response) => {
            
            var records = JSON.parse(response.data.mon_lsp_wise_data);
            var monthlydatagroup = groupBy(records, rdata => rdata.report_month+" "+rdata.report_year);
            //console.log("monthlydatagroup ", monthlydatagroup)
            var categories = []
            var pcategories = []
            var monthlywisegpsprovider=[]
            var monthlywisegpspacketreceive=[]
            var columndata = []

            var prcolumndata = []
            var gpsarr=[]
            var sgps = ''
            monthlydatagroup.forEach((item,key ) => {
                
                // if(key == month)
                //{
                    var less3=[];
                    var threefive=[];
                    var fiveten=[];
                    var ten15=[];
                    var fiteen60=[];
                    var greater60=[];

                    var prless3=[];
                    var prthreefive=[];
                    var prfiveten=[];
                    var prten15=[];
                    var prfiteen60=[];
                    var prgreater60=[];
                    item.map((itm, ky) => {
                        //console.log("itm ", itm)
                        if(ky == 0)
                        {
                            sgps = itm.gps_provider
                        }
                          
                        if(this.state.dept_code == 'SNDG')
                        {
                            if(itm.gps_provider == 'TRIMBLE' || itm.gps_provider == 'EFKON')
                            {

                                gpsarr.push(itm.gps_provider) 
                                if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                    itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                    itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                    itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                    itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                    itm.timestamp_freq_greater_than_60_mins_per > 0)
                                    {
                                        categories.push({
                                            name:itm.gps_provider
                                        })
                                    }
                                    
                                if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                {
                                    less3.push(itm.timestamp_freq_less_than_3_mins_per*100)
                                }
                                else
                                {
                                    less3.push(0)
                                }
                                    
                                if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                {
                                    threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per*100)
                                }
                                else
                                {
                                    threefive.push(0)
                                }
                                
                                if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                {
                                    fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per*100)
                                }
                                else
                                {
                                    fiveten.push(0)
                                }

                                if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                {
                                    ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per*100)
                                }
                                else
                                {
                                    ten15.push(0)
                                }

                                if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                {
                                    fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per*100)
                                }
                                else
                                {
                                    fiteen60.push(0)
                                }

                                if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                {
                                    greater60.push(itm.timestamp_freq_greater_than_60_mins_per*100)
                                }
                                else
                                {
                                    greater60.push(0)
                                }


                                if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                    itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                {
                                    pcategories.push({
                                        name:itm.gps_provider
                                    })
                                }
                                
                                

                                if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                {
                                    prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                                }
                                else
                                {
                                    prless3.push(0)
                                }
                                
                                if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                {
                                    prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                                }
                                else
                                {
                                    prthreefive.push(0)
                                }

                                if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                {
                                    prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                                }
                                else
                                {
                                    prfiveten.push(0)
                                }

                                if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                {
                                    prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                                }
                                else
                                {
                                    prten15.push(0)
                                }

                                if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                {
                                    prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                                }
                                else
                                {
                                    prfiteen60.push(0)
                                }
                                
                                if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                {
                                    prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                                }
                                else
                                {
                                    prgreater60.push(0)
                                }
                            }
                        }      
                        else{

                            gpsarr.push(itm.gps_provider) 
                            if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                itm.timestamp_freq_greater_than_60_mins_per > 0)
                                {
                                    categories.push({
                                        name:itm.gps_provider
                                    })
                                }
                                
                            if(itm.timestamp_freq_less_than_3_mins_per > 0)
                            {
                                less3.push(itm.timestamp_freq_less_than_3_mins_per*100)
                            
                            }
                            else{
                                less3.push(0)
                            }
                                
                            if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                            {
                                threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per*100)
                            }
                            else{
                                threefive.push(0)
                            }

                            if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                            {
                                fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per*100)
                            }
                            else{
                                fiveten.push(0)
                            }

                            if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                            {
                                ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per*100)
                            }
                            else{
                                ten15.push(0)
                            }

                            if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                            {
                                fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per*100)
                            }
                            else{
                                fiteen60.push(0)
                            }

                            if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                            {
                                greater60.push(itm.timestamp_freq_greater_than_60_mins_per*100)
                            }
                            else{
                                greater60.push(0)
                            }

                            

                            if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                itm.packet_receival_freq_greater_than_60_mins_per > 0)
                            {
                                pcategories.push({
                                    name:itm.gps_provider
                                })
                            }
                            
                            

                            if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                            {
                                prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                            }
                            else
                            {
                                prless3.push(0)
                            }

                            if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                            {
                                prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                            }
                            else
                            {
                                prthreefive.push(0)
                            }
                            

                            if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                            {
                                prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                            }
                            else
                            {
                                prfiveten.push(0)
                            }
                            
                            if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                            {
                                prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                            }
                            else
                            {
                                prten15.push(0)
                            }
                            
                            if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                            {
                                prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                            }
                            else
                            {
                                prfiteen60.push(0)
                            }
                            
                            
                            if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                            {
                                prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                            }
                            else
                            {
                                prgreater60.push(0)
                            }
                            
                        }
                        
                            
                    })
                    columndata.push({
                        name:'< 3 mins',
                        data:less3,
                        stack:key
                    },{
                        name: '3 - 5 mins',
                        data:threefive,
                        stack:key
                    },{
                        name: '5 - 10 mins',
                        data: fiveten,
                        stack:key
                    },{
                        name: '10 - 15 mins',
                        data: ten15,
                        stack:key
                    },{
                        name: '15 - 60 mins',
                        data: fiteen60,
                        stack:key
                    },{
                        name: '> 60 mins',
                        data: greater60,
                        stack:key
                        
                    })


                    prcolumndata.push({
                        name:'< 3 mins',
                        data:prless3,
                        stack:key
                    },{
                        name: '3 - 5 mins',
                        data:prthreefive,
                        stack:key
                    },{
                        name: '5 - 10 mins',
                        data: prfiveten,
                        stack:key
                    },{
                        name: '10 - 15 mins',
                        data: prten15,
                        stack:key
                    },{
                        name: '15 - 60 mins',
                        data: prfiteen60,
                        stack:key
                    },{
                        name: '> 60 mins',
                        data: prgreater60,
                        stack:key
                        
                    })
                //}
            })

            let newArray = []; 
        
            // Declare an empty object 
            let uniqueObject = {}; 
            var objTitle;
            // Loop for the array elements 
            for (let i in categories) { 
    
                // Extract the title 
                objTitle = categories[i]['name']; 
    
                // Use the title as the index 
                uniqueObject[objTitle] = categories[i]; 
            } 
            
            // Loop to push unique object into array 
            for (var a in uniqueObject) { 
                newArray.push(uniqueObject[a]); 
            } 
            var ncats = []
            newArray.map((it) => {
                ncats.push(it.name)
            })

            let packnewArray = []; 
        
            //console.log("pcategories ", pcategories)
            // Declare an empty object 
            let packuniqueObject = {}; 
            var packobjTitle;
            // Loop for the array elements 
            for (let p in pcategories) { 
    
                // Extract the title 
                packobjTitle = pcategories[p]['name']; 
                //console.log("packobjTitle ", packobjTitle)
                // Use the title as the index 
                packuniqueObject[packobjTitle] = pcategories[p]; 
            } 
            
            // Loop to push unique object into array 
            for (var a in packuniqueObject) { 
                packnewArray.push(packuniqueObject[a]); 
            } 
            var paccats = []
            packnewArray.map((it) => {
                paccats.push(it.name)
            })

           // console.log('ncats ',ncats)

            monthlywisegpsprovider.push({
                series:columndata,
                categories:ncats
            })


            //console.log("monthlywisegpsprovider ",monthlywisegpsprovider)
            
            monthlywisegpspacketreceive.push({
                series:prcolumndata,
                categories:paccats
            })
            if(this.state.selectgps.value == ""){
                var selgps = {"value":gpsarr[0],"label":gpsarr[0]}
            }
            else{
                var selgps = this.state.selectgps
            }
            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                selectgps:selgps,
                gpsproviderlist:gpsarr,
                monthlywisegpsprovider:monthlywisegpsprovider,
                monthlywisegpspacket:monthlywisegpspacketreceive,
                gpsdata:records
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })

        

    }
    

    onSelectTransporterMonth = async (selectmonth) => {
        //console.log("Month ", selectmonth)
        await this.setState(
			{ selectmonth },
			() => console.log(`Month Option selected:`, this.state.selectmonth)
		  );
		  
        // await this.onShowMonthWiseData();
        // await this.onShowTransporterwiseData();
        
    }

    onSelectGPS = async (selectgps) =>{
        await this.setState(
			{ selectgps },
			() => console.log(`Location Option selected:`, this.state.selectgps)
		  );
		  var preqparams = {
                dept_code:this.state.dept_code,
                month:this.state.selectmonth.value,
                gpstype:this.state.selectgpstype
            }
          //await this.onShowTransporterwiseData(preqparams)
    }
    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    onClickTransporterGPSData = async() =>{
        var preqparams = {
            dept_code:this.state.dept_code,
            month:this.state.selectmonth.value,
            gpsprovider:this.state.gpsprovider,
            gpstype:this.state.selectgpstype
        }
        await this.onShowTransporterwiseData(preqparams);
    }
    onShowTransporterwiseData(parameters)
    {
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "FIlters",
			}
			googleAnalytics.logEvent(eventOptions);
		}   
        this.setState({
            transmonthlywisegpsprovider:[],
            transmonthlywisegpspacketprovider:[],
            monthlydata:[],
            monthlypacketwisedata:[]
        })
        /*Transportwise */
        redirectURL.post("/dashboard/gpspacketdashboard", parameters)
        .then((response) => {
            //console.log("Packet Response ", response.data)
            var recordsarr = response.data
            if(this.state.dept_code == "SNDG")
            {
                var records = recordsarr.filter((item) => {
                    //console.log("filter ", item)
                    if(item.gps_provider == "TRIMBLE" || item.gps_provider == "EFKON")
                    {
                        return item;
                    }
                })
            }
            else{
                var records = recordsarr;
            }
           //console.log("rrr ", records)
            var monthlydatagroup = groupBy(records, rdata => rdata.report_month+" "+rdata.report_year);
             console.log('monthlydatagroup ', monthlydatagroup)
            var mcategories = []
            var packcategories = []
            var transmonthlywisegpsprovider=[]
            
            var transmonthlywisegpspacketprovider = []
            var transcats = []
            
            var columndata = []

            var prcolumndata = []
            var gpsarr=[]
            var sgps = ''
            var sn = 0;
           //console.log("this.state.selectmonth.value ", this.state.selectmonth.value)
            monthlydatagroup.forEach((item,key ) => {
               // console.log("key ", key)
                var sp = key.split(" ")
                //console.log(sp)
                var less3=[];
                var threefive=[];
                var fiveten=[];
                var ten15=[];
                var fiteen60=[];
                var greater60=[];
    
    
                var prless3=[];
                var prthreefive=[];
                var prfiveten=[];
                var prten15=[];
                var prfiteen60=[];
                var prgreater60=[];
                if(sp[0] == this.state.selectmonth.value)
                {
                     
                    var monthlydataarr = groupBy(item, rdata => rdata.gps_provider);
                    //console.log("monthlydataarr ", monthlydataarr)
                    
                    var samplearr=[]
                    var datafreqarr=[]
                    //console.log("this.state.selectgps.value ", this.state.selectgps.value)
                    monthlydataarr.forEach((m, mk) => {
                        //console.log("mk ",mk)
                        if(this.state.selectgps.value == "")
                        {
                            if(sn == 0)
                            {
                                sgps = mk
                            }
                        }
                        else{
                            sgps = this.state.selectgps.value
                        }
                        
                       //console.log("sgps ", sgps)
                       sn=sn+1
                       gpsarr.push(mk)
                        if(mk == sgps)
                        {
                           //console.log("m ", m)
                            
                            m.map((itm, ky) => {
                                
                                if(this.state.dept_code == 'SNDG')
                                {
                                    
                                    if(itm.gps_provider == 'TRIMBLE' || itm.gps_provider == 'EFKON')
                                    {
                                        if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                            itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                            itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                            itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                            itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                            itm.timestamp_freq_greater_than_60_mins_per > 0)
                                            {
                                                mcategories.push({
                                                    name:itm.gps_provider
                                                })
                                            }
                                            samplearr.push([(itm.timestamp_freq_less_than_3_mins_per*100),
                                                (itm.timestamp_freq_btw_3_to_5_mins_per*100),
                                                (itm.timestamp_freq_btw_5_to_10_mins_per*100),
                                                (itm.timestamp_freq_btw_10_to_15_mins_per*100),
                                                (itm.timestamp_freq_btw_15_to_60_mins_per*100),
                                                (itm.timestamp_freq_greater_than_60_mins_per*100)]);


                                            datafreqarr.push([(itm.packet_receival_freq_less_than_3_mins_per*100),
                                                (itm.packet_receival_freq_btw_3_to_5_mins_per*100),
                                                (itm.packet_receival_freq_btw_5_to_10_mins_per*100),
                                                (itm.packet_receival_freq_btw_10_to_15_mins_per*100),
                                                (itm.packet_receival_freq_btw_15_to_60_mins_per*100),
                                                (itm.packet_receival_freq_greater_than_60_mins_per*100)]);
                                                

                                        
                                    }
                                }
                                else{
                                    //gpsarr.push(itm.gps_provider)
                                    if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                        itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                        itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                        itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                        itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                        itm.timestamp_freq_greater_than_60_mins_per > 0)
                                        {
                                            mcategories.push({
                                                name:itm.gps_provider
                                            })
                                        }
                                        
                                        
                                        samplearr.push([(itm.timestamp_freq_less_than_3_mins_per*100),
                                            (itm.timestamp_freq_btw_3_to_5_mins_per*100),
                                            (itm.timestamp_freq_btw_5_to_10_mins_per*100),
                                            (itm.timestamp_freq_btw_10_to_15_mins_per*100),
                                            (itm.timestamp_freq_btw_15_to_60_mins_per*100),
                                            (itm.timestamp_freq_greater_than_60_mins_per*100)]);


                                        datafreqarr.push([(itm.packet_receival_freq_less_than_3_mins_per*100),
                                            (itm.packet_receival_freq_btw_3_to_5_mins_per*100),
                                            (itm.packet_receival_freq_btw_5_to_10_mins_per*100),
                                            (itm.packet_receival_freq_btw_10_to_15_mins_per*100),
                                            (itm.packet_receival_freq_btw_15_to_60_mins_per*100),
                                            (itm.packet_receival_freq_greater_than_60_mins_per*100)]);
                                            
                                   
                                }
                                
    
                            })
                        }
                        
                    })

                    columndata.push({
                        name:"Sample Frequency",
                        data:samplearr[0]
                    },{
                        name:"Data Frequency",
                        data:datafreqarr[0]
                    })
                    
                }
            })
            
            //console.log("mcategories ", mcategories)
            let newArray = []; 
        
            // Declare an empty object 
            let uniqueObject = {}; 
            var objTitle;
            // Loop for the array elements 
            for (let i in mcategories) { 
    
                // Extract the title 
                objTitle = mcategories[i]['name']; 
    
                // Use the title as the index 
                uniqueObject[objTitle] = mcategories[i]; 
            } 
            
            // Loop to push unique object into array 
            for (var a in uniqueObject) { 
                newArray.push(uniqueObject[a]); 
            } 
            var mcats = []
            newArray.map((it) => {
                mcats.push(it.name)
            })

            let packnewArray = []; 
        
            //console.log("packcategories ", packcategories)
            // Declare an empty object 
            let packuniqueObject = {}; 
            var packobjTitle;
            // Loop for the array elements 
            for (let p in packcategories) { 
    
                // Extract the title 
                packobjTitle = packcategories[p]['name']; 
                //console.log("packobjTitle ", packobjTitle)
                // Use the title as the index 
                packuniqueObject[packobjTitle] = packcategories[p]; 
            } 
            
            // Loop to push unique object into array 
            for (var a in packuniqueObject) { 
                packnewArray.push(packuniqueObject[a]); 
            } 
            var packcats = []
            packnewArray.map((it) => {
                packcats.push(it.name)
            })
            
           //console.log("columndata ",columndata)
            transmonthlywisegpsprovider.push({
                series:columndata,
                categories:["<3 mins", "3 - 5 mins", "5 - 10 mins", "10 - 15 mins", "15 - 60 mins", ">60 mins"]
            })
            
            transmonthlywisegpspacketprovider.push({
                series:prcolumndata,
                categories:packcats
            })
            //console.log("transmonthlywisegpsprovider ",transmonthlywisegpsprovider)


            var gmonthlydataarr=[]
            var gmonthlypacketdataarr=[]

            /*Line Chart*/
            var linecolumndata = []
            var linepacketcolumndata=[]
            var lineless3=[];
            var linethreefive=[];
            var linefiveten=[];
            var lineten15=[];
            var linefiteen60=[];
            var linegreater60=[];

            var plineless3=[];
            var plinethreefive=[];
            var plinefiveten=[];
            var plineten15=[];
            var plinefiteen60=[];
            var plinegreater60=[];
             var monthcategories=[]
            
            //console.log("Line monthlydatagroup ", monthlydatagroup)
            monthlydatagroup.forEach((item,key ) => {
                 //console.log("key ", this.state.selectmonth.value)
                 var sp = key.split(" ")
                 //console.log("sp ", sp)
                 
     
                 if(parseInt(sp[0]) >= 5 || parseInt(sp[0]) <= this.state.selectmonth.value)
                 {
                    monthcategories.push(allmonths[sp[0]-1]+" "+sp[1])
                     var gmonthlydataarr = groupBy(item, rdata => rdata.gps_provider);
                 //console.log("monthlydataarr ", gmonthlydataarr)
                     
                     var samplearr=[]
                     var datafreqarr=[]
                     //console.log("this.state.selectgps.value ", this.state.selectgps.value)
                     gmonthlydataarr.forEach((m, mk) => {
                         //console.log("mk ",mk)
                         if(this.state.selectgps.value == "")
                         {
                             if(sn == 0)
                             {
                                 sgps = mk
                             }
                         }
                         else{
                             sgps = this.state.selectgps.value
                         }
                         
                        //console.log("sgps ", sgps)
                        sn=sn+1
                        //console.log("mk ", mk)
                         if(mk == sgps)
                         {
                            //console.log("m ", m)
                             
                             m.map((itm, ky) => {
                                 
                                 if(this.state.dept_code == 'SNDG')
                                 {
                                     
                                     if(itm.gps_provider == 'TRIMBLE' || itm.gps_provider == 'EFKON')
                                     {
                                        if(parseInt(sp[0]) >= 5 || sp[0] <= itm.report_month)
                                        {
                                            ///console.log("itm.gps_provider ", itm.gps_provider)
                                            if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                            {
                                                lineless3.push((itm.timestamp_freq_less_than_3_mins_per*100))
                                            }
                                            else{
                                                lineless3.push(0)
                                            }
                                            
                                            if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                            {
                                                linethreefive.push((itm.timestamp_freq_btw_3_to_5_mins_per*100))
                                            }
                                            else{
                                                linethreefive.push(0)
                                            }
            
                                            if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                            {
                                                linefiveten.push((itm.timestamp_freq_btw_5_to_10_mins_per*100))
                                            }
                                            else{
                                                linefiveten.push(0)
                                            }

                                            if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                            {
                                                lineten15.push((itm.timestamp_freq_btw_10_to_15_mins_per*100))
                                            }
                                            else{
                                                lineten15.push(0)
                                            }
            
                                            if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                            {
                                                linefiteen60.push((itm.timestamp_freq_btw_15_to_60_mins_per*100))
                                            }
                                            else{
                                                linefiteen60.push(0)
                                            }
                                            
                                            if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                            {
                                                linegreater60.push((itm.timestamp_freq_greater_than_60_mins_per*100))
                                            }
                                            else{
                                                linegreater60.push(0)
                                            }
    
                                            
                                        
                                            if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                            {
                                                plineless3.push((itm.packet_receival_freq_less_than_3_mins_per*100))
                                            }
                                            else{
                                                plineless3.push(0)
                                            }
                                            
                                            if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                            {
                                                plinethreefive.push((itm.packet_receival_freq_btw_3_to_5_mins_per*100))
                                            }
                                            else{
                                                plinethreefive.push(0)
                                            }
            
                                            if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                            {
                                                plinefiveten.push((itm.packet_receival_freq_btw_5_to_10_mins_per*100))
                                            }
                                            else{
                                                plinefiveten.push(0)
                                            }

                                            if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                            {
                                                plineten15.push((itm.packet_receival_freq_btw_10_to_15_mins_per*100))
                                            }
                                            else{
                                                plineten15.push(0)
                                            }
            
                                            if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                            {
                                                plinefiteen60.push((itm.packet_receival_freq_btw_15_to_60_mins_per*100))
                                            }
                                            else{
                                                plinefiteen60.push(0)
                                            }
                                            
                                            if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                            {
                                                plinegreater60.push((itm.packet_receival_freq_greater_than_60_mins_per*100))
                                            }
                                            else{
                                                plinegreater60.push(0)
                                            }
                                        }


                                     }
                                 }
                                 else{
                                    if(parseInt(sp[0]) >= 5 || sp[0] <= itm.report_month)
                                    {
                                         if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                         {
                                            lineless3.push(itm.timestamp_freq_less_than_3_mins_per*100)
                                         
                                         }
                                         
                                           
                                         if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                         {
                                            linethreefive.push(itm.timestamp_freq_btw_3_to_5_mins_per*100)
                                         }
                                         
         
                                         if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                         {
                                            linefiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per*100)
                                         }
                                         
                                         if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                         {
                                            lineten15.push(itm.timestamp_freq_btw_10_to_15_mins_per*100)
                                         }
                                        
         
                                         if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                         {
                                            linefiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per*100)
                                         }
                                        
         
                                         if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                         {
                                            linegreater60.push(itm.timestamp_freq_greater_than_60_mins_per*100)
                                         }




                                         if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                         {
                                             plineless3.push((itm.packet_receival_freq_less_than_3_mins_per*100))
                                         }
                                         else{
                                             plineless3.push(0)
                                         }
                                         
                                         if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                         {
                                             plinethreefive.push((itm.packet_receival_freq_btw_3_to_5_mins_per*100))
                                         }
                                         else{
                                             plinethreefive.push(0)
                                         }
         
                                         if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                         {
                                             plinefiveten.push((itm.packet_receival_freq_btw_5_to_10_mins_per*100))
                                         }
                                         else{
                                             plinefiveten.push(0)
                                         }
 
                                         if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                         {
                                             plineten15.push((itm.packet_receival_freq_btw_10_to_15_mins_per*100))
                                         }
                                         else{
                                             plineten15.push(0)
                                         }
         
                                         if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                         {
                                             plinefiteen60.push((itm.packet_receival_freq_btw_15_to_60_mins_per*100))
                                         }
                                         else{
                                             plinefiteen60.push(0)
                                         }
                                         
                                         if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                         {
                                             plinegreater60.push((itm.packet_receival_freq_greater_than_60_mins_per*100))
                                         }
                                         else{
                                             plinegreater60.push(0)
                                         }
                                    }
                                    
                                 }
                                 
     
                             })
                         }
                         
                     })
 
 
                     //console.log("packcategories ", packcategories)
                     
                
                     
                }
                 
             })
             //console.log('lineless3 ',lineless3)
             linecolumndata.push({
                    name:'< 3 mins',
                    data:lineless3
                },{
                    name: '3 - 5 mins',
                    data:linethreefive
                },{
                    name: '5 - 10 mins',
                    data: linefiveten
                },{
                    name: '10 - 15 mins',
                    data: lineten15
                },{
                    name: '15 - 60 mins',
                    data: linefiteen60
                },{
                    name: '> 60 mins',
                    data: linegreater60
                    
                })

            linepacketcolumndata.push({
                    name:'< 3 mins',
                    data:plineless3
                },{
                    name: '3 - 5 mins',
                    data:plinethreefive
                },{
                    name: '5 - 10 mins',
                    data: plinefiveten
                },{
                    name: '10 - 15 mins',
                    data: plineten15
                },{
                    name: '15 - 60 mins',
                    data: plinefiteen60
                },{
                    name: '> 60 mins',
                    data: plinegreater60
                    
                })
            gmonthlydataarr.push({
                categories:monthcategories,
                lineseries:linecolumndata
            })

            gmonthlypacketdataarr.push({
                categories:monthcategories,
                lineseries:linepacketcolumndata
            })
            //monthlypacketwisedata
            //console.log("gmonthlydataarr ", gmonthlydataarr)
            //console.log("gmonthlypacketdataarr ", gmonthlypacketdataarr)
            if(this.state.selectgps.value == ""){
                var selgps = {"value":gpsarr[0],"label":gpsarr[0]}
            }
            else{
                var selgps = this.state.selectgps
            }


            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                transmonthlywisegpsprovider:transmonthlywisegpsprovider,
                transmonthlywisegpspacketprovider:transmonthlywisegpspacketprovider,
                gpsprovider:sgps,
                gpsproviderlist:gpsarr,
                gpstransportdata:records,
                selectgps:selgps,
                monthlydata:gmonthlydataarr,
                monthlypacketwisedata:gmonthlypacketdataarr
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })

    }
   
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n'
		});
		
    }
    

    onChangeChartView(params){
        
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Change Chart View",
			}
			googleAnalytics.logEvent(eventOptions);
        }
        
        if(params == 'sample')
        {
            this.setState({
                gpsloadcheck:false,
                loadcheck:true,
                showgpschart:'show-m',
                showtranschart:'show-n'
            })
        }
        if(params == 'datapacket')
        {
            this.setState({
                gpsloadcheck:true,
                loadcheck:false,
                showgpschart:'show-n',
                showtranschart:'show-m'
            })
        }
    }
    onChangeOnTripOption= async (selectgpstype) => {
        //console.log("Month ", selectmonth)
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Trip Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        });
        await this.setState(
            { selectgpstype },
            () => console.log(`Type Option selected:`, this.state.selectgpstype)
        );

        var preqparams = {
            dept_code:this.state.dept_code,
            startMonth:this.state.selectstartmonth.value,
            endMonth:this.state.selectendmonth.value,
            startYear:this.state.selectstartyear.value,
            endYear:this.state.selectstartyear.value,
            gpstype:selectgpstype
        }
        

        var paramtrs = {
            dept_code:this.state.dept_code,
            month:this.state.selectmonth.value,
            gpstype:selectgpstype
        }

        await this.onShowMonthWiseData(preqparams);

        await this.onShowTransporterwiseData(paramtrs);
    }

    render(){
        return ( 
        		<div className="row pt-20px tab-brd">
                    <div className="col-xl-2 col-lg-2" style={{margin:"0 auto 20px auto"}}>
                        Select View Type: <Select placeholder={"Select"} value={this.state.selectgpstype} className="border-radius-0" onChange={this.onChangeOnTripOption.bind(this)} style={{borderRadius:"0px"}} options={this.gpstripoptions()} required />
                    </div>
                    <div className="col-xl-12 col-lg-12" style={{margin:"0 auto"}}>
                        &nbsp;<br />
                    </div>
                    <div className="row col-xl-12 col-lg-12">
                        <div className="col-xl-3"></div>
                        <div className="col-xl-3 row">
                            <div className="col-xl-6">
                            Start Month : <Select placeholder={"Month"} value={this.state.selectstartmonth} className="border-radius-0" onChange={this.onSelectStartMonth.bind(this)} style={{borderRadius:"0px"}} options={this.monthoptions()} required />
                            </div>
                            
                            <div className="col-xl-6"><br />
                                <Select placeholder={"Year"} value={this.state.selectstartyear} className="border-radius-0" onChange={this.onSelectStartYear.bind(this)} style={{borderRadius:"0px"}} options={this.yearoptions()} required />
                            </div>
                        </div>

                        <div className="col-xl-1"></div>
                        <div className="col-xl-3 row">
                            <div className="col-xl-6">
                                End Month : <Select placeholder={"Month"} value={this.state.selectendmonth} className="border-radius-0" onChange={this.onSelectEndMonth.bind(this)} style={{borderRadius:"0px"}} options={this.monthoptions()} required />
                            </div>
                            <div className="col-xl-6"><br />
                                <Select placeholder={"Year"} value={this.state.selectendyear} className="border-radius-0" onChange={this.onSelectEndYear.bind(this)} style={{borderRadius:"0px"}} options={this.yearoptions()} required />
                            </div>
                        </div>
                        <div className="col-xl-2 row"><br />
                            <button type="button" className="btn btn-success btn-data"  onClick={this.onClickGPSData.bind(this)} >Get Data</button>
                        </div>
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                            
                            <div className={"col-xl-12 col-lg-12 "+(this.state.shownormalall)}>
                            {this.state.monthlywisegpsprovider.length > 0?
                
                                this.state.monthlywisegpsprovider.map(item => 
                                    <ColumnGroupGPS
                                        title={"GPS Provider Wise Sample Frequency Analysis "}
                                        subtitle={"Time duration between two GPS Samples"}
                                        context={this}
                                        series={item.series}
                                        //drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        //dept_code={this.state.dept_code}
                                    />    
                                    )
                                :
                                <ColumnGroupGPS
                                        title={"GPS Provider Wise Sample Frequency Analysis "}
                                        subtitle={"Time duration between two GPS Samples"}
                                        context={this}
                                        series={[]}
                                        //drildown = {item.chartdrildown}
                                        categories={[]}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        //dept_code={this.state.dept_code}
                                    />
                                    }
                            </div>
                            <div className={"col-xl-12 col-lg-12 "}>
                                {this.state.monthlywisegpspacket.length > 0?
                
                                this.state.monthlywisegpspacket.map(item => 
                                    <ColumnGroupGPS
                                        title={"GPS Provider Wise Data Frequency Analysis"}
                                        subtitle={"Time duration between receipt of two GPS Packets"}
                                        context={this}
                                        series={item.series}
                                        //drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        dept_code={this.state.dept_code}
                                    />    
                                    )
                                :""}
                            </div>
                            

                            <div className={"col-xl-12 col-lg-12 row "}>
                                <div className="col-xl-4"></div>
                                <div className="col-xl-2">
                                    Select Month : <Select placeholder={"Month"} value={this.state.selectmonth} className="border-radius-0" onChange={this.onSelectTransporterMonth.bind(this)} style={{borderRadius:"0px"}} options={this.monthoptions()} required />
                                </div>
                                <div className="col-xl-3">
                                    Choose GPS Provider:<Select placeholder={"GPS Provider"} value={this.state.selectgps} className="border-radius-0" onChange={this.onSelectGPS.bind(this)} style={{borderRadius:"0px"}} options={this.gpsprovidersoptions()} required />
                                </div>
                                <div className="col-xl-3 row"><br />
                                    <button type="button" className="btn btn-success btn-data"  onClick={this.onClickTransporterGPSData.bind(this)} >Get Data</button>
                                </div>

                                <div className="col-xl-12">
                                    {this.state.transmonthlywisegpsprovider.length > 0?
                    
                                    this.state.transmonthlywisegpsprovider.map(item => 
                                        <ColumnGPSGroupChart
                                            title={"GPS Data Analysis "}
                                            subtitle={"Comparision between Sample frequency vs Data frequency"}
                                            context={this}
                                            series={item.series}
                                            //drildown = {item.chartdrildown}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Percentages"}
                                            dept_code={this.state.dept_code}
                                        />    
                                        )
                                    :""}
                                </div>
                            </div>
                        
                        
                            <div className={"col-xl-12 col-lg-12 mt-40p optchrt"}>
                                <input type="radio" value="sample" checked={this.state.loadcheck} onChange={this.onChangeChartView.bind(this, "sample")} /> Sample Frequency
                                &nbsp;&nbsp;<input type="radio" value="datapacket" checked={this.state.gpsloadcheck} onChange={this.onChangeChartView.bind(this, "datapacket")} /> Data Frequency
                            </div>

                            <div className={"col-xl-12 col-lg-12 "+(this.state.showgpschart)}>
                                    
                                {this.state.monthlydata.length > 0?
                            
                                    this.state.monthlydata.map(item => 
                                    <LineGPSDashboard
                                        title={'Monthly Sample Frequency Analysis'}
                                        subtitle={"Monthly Avg. time duration between two GPS Samples"}
                                        context={this}
                                        series={item.lineseries}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        pointpostfix={""}
                                        dept_code={this.state.dept_code}
                                    />
                                    )
                                :""}
                            </div>
                                
                           
                      
                            <div className={"col-xl-12 col-lg-12 "+(this.state.showtranschart)}>
                                            
                                {this.state.monthlypacketwisedata.length > 0?
                                    
                                    this.state.monthlypacketwisedata.map(item => 
                                    <LineGPSDashboard
                                        title={'Monthly Data Frequency Analysis'}
                                        subtitle={"Monthly Avg. time duration between receipt of two GPS Packets"}
                                        context={this}
                                        series={item.lineseries}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        pointpostfix={""}
                                        dept_code={this.state.dept_code}
                                    />
                                                
                                )
                                :""} 
    
                            </div>
                     
                        </div>
                        
                        
                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    
                    </div>
                   
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}





