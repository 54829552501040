import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import ComponentIndex from '../../common/componentIndex';
import AppendComponentIndex from '../../common/appendComponents';
//import MyGridComponent from '../../common/dockmenu';
import { WidthProvider, Responsive } from "react-grid-layout";
import GridLayout from './grid-layout';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
var redirectURL = require('../../redirectURL');

//const userid="5df882472d59296a95b79a91";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};
const layoutdata={
		token:localStorage.getItem('token'),
}
var usrlayouts=[];
var startdate;
var enddate;
var globalFilterParams={};
export default class Overview extends Component {
  static defaultProps = {
	    className: "layout",
	    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
	    rowHeight: 100,
	    onLayoutChange:function(){}
	  };

	  constructor(props) {
	    super(props);
	    this.myRef = React.createRef();
	    this.conRef = React.createRef();
	    this.state = {
			showdata : this.displayData,
			filter_from_date:'',
			filter_to_date:'',
			filternow:'no',
			filter_dept_code:'',
			filtersparams:'',
			alldepts:[],
			userid:localStorage.getItem('userid'),
			sliderTranslate:'',
			fsliderTranslate:'',
			vwTranslate:'',
	    	transportchartTitles:[],
	    	transportchartSeries:[],
	    	chartTitle:'',
	    	truckscnt:'',
	    	notrans:'',
	    	trucktoday:'',
	    	trucklocations:'',
	    	lspaxis:[],
	    	lspyaxis:[],
	    	deptaxis:[],
			deptyaxis:[],
			inlineFilters:'',
			filerKey:'',
	    	wd:'100%',
	    	ht:'100%',
	     //mylayout:[],
	      items: [].map(function(i, key, list) {
	        return {
	          i: i.toString(),
	          x: i * 2,
	          y: 0,
	          w: 2,
	          h: 2,
			  t:'',
			  s:1,
	          //dp:'grid',
	          static:false,
	          add: i === (list.length - 1)
	        };
		  }),
		  viewports:[].map(function(i, key, list) {
	        return {
				i: i.toString(),
				x: i * 2,
				y: 0,
				w: 2,
				h: (2/2),
				add: i === (list.length - 1)
			  };
		  }),
	      newCounter: 0,
	      value: '',
	      suggestions: [],
	      open: false,
	      chartdata:[64,21,33,42,52,34,39,73,24,121,53,15,43,25,75,46,55,33,120,221,74,99,43,44,56],
	      
	      columnwithDefs: [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
			  
			},
			{
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
				
		  },
			{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
			{
				headerName: "GPS Provider (as per master)",
				field: "lsp_name",
				width: 200,
					
		  },
			{
			headerName: "Actual GPS Provider",
			field: "actual_lspuser",
			width: 200,
				
		  },
			{
			headerName: "Last Packet Time",
			field: "timestamp",
			width: 200,
				
		  },
		  {
			headerName: "Data Received On",
			field: "last_gps_date",
			width: 200
		  }
			 
			 
		  ],
		columnwithoutDefs: [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 200,
			  
			},
			{
			headerName: "LSP Name",
			field: "transporter_name",
			width: 200,
				
		  },
			{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
			{
			headerName: "GPS Provider Name (as per master)",
			field: "lsp_name",
			width: 200,
				
		  },
		  {
			headerName: "Actual LSP Provider",
			field: "actual_lspuser",
			width: 200,
				
		  },
		  {
			headerName: "Last Packet Time",
			field: "last_gps_date",
			width: 200,
				
		  }
			 
		  ],
	  	    rowData:'',
	        rowwithoutData:''
	    };

	    this.onBreakpointChange = this.onBreakpointChange.bind(this);
	    this.onDragStop = this.onDragStop.bind(this);
	    this.onResizeItem = this.onResizeItem.bind(this);
	    this.onUserAction=this.onUserAction.bind(this);
	    this.onUserRemoveComponent=this.onUserRemoveComponent.bind(this);
	    this.onResizeStart = this.onResizeStart.bind(this);
	    this.onGlobalAddItem = this.onGlobalAddItem.bind(this);
	    this.onComponentRend = this.onComponentRend.bind(this);
	    this.onMaximizeItem = this.onMaximizeItem.bind(this);
	    this.onPinItem = this.onPinItem.bind(this);
	    this.onUserPinComponent = this.onUserPinComponent.bind(this);
	    this.onUnPinItem = this.onUnPinItem.bind(this);
	    this.onUserUnPinComponent = this.onUserUnPinComponent.bind(this);
	    this.onFilterItem = this.onFilterItem.bind(this);
		this.onUserNewAddGridAction=this.onUserNewAddGridAction.bind(this);
	   
	  }

	
	  componentDidMount(){
		// console.log("items ", localStorage.getItem('token'));
		 const layoutdata={
			userid:this.state.userid,
		}
		//Get Unique items from array
		//var myArray = ['a', 1, 'a', 2, '1'];

		//let uniquearr = [...new Set(myArray)];
		//console.log("uniquearr ", uniquearr); 
		redirectURL.post('/overview/departments', layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
  		  .then((response) => {
			var records = JSON.parse(JSON.stringify(response)).data;
			var optss={};
		   records.map((item, key) => {
			  var optValue = item.dept_code;
			optss[optValue] = item.dept_name
		   });
		   this.setState({
			alldepts:records
		   });
		});
		
		//console.log("vh", layoutdata);
		redirectURL.post('/layouts/userComponents', layoutdata)
		.then((response) => {
			//console.log("data",response.data.length);
			if(response.data.length > 0)
			{

			//}
				var rec=JSON.parse(JSON.stringify(response.data));
			//console.log("User ",rec[0].layout);
			//if(response.data != '0' && response.data != 0)
			//{	
				//console.log("Layout ",JSON.parse(rec[0].layout));
				try{
					var layouts = JSON.parse(rec[0].layout);
					// layouts.map(item => {
					// 	//this.createElement(item);
					// 	this.setState({
					// 		items:this.state.items.concat(item)
					// 	});
					
					// });
				}
				catch(e){
					console.log(e)
				}
			}
			else
			{
				usrlayouts=[
								{i: "counters_counters_Counter_Counter_1",x: 0,y: 0,w: 12,h: 2,static:false,t:'Counter',s:1},
								{i: "transporterwisetruckswithgpschart_chart_TransporterChart_Transporter wise trucks with GPS Data_1",x: 0,y: 2,w: 12,h: 5,static:false,t:'Transporter wise trucks with GPS Data',s:1},
								{i: "transporterwisetruckswithoutgpschart_chart_TransporterChart_Transporter wise trucks without GPS Data_1",x: 0,y: 7,w: 12,h: 5,static:false,t:'Transporter wise trucks without GPS Data',s:1},
								{i: "lspwisetruckswithgps_chart_LSPChart_LSP wise trucks with GPS Data_1",x: 0,y: 12,w: 7,h: 5,static:false,t:'LSP wise trucks with GPS Data',s:1},
								//{i: "lspwisetruckswithoutgps_chart_LSPChart_LSP wise trucks without GPS Data_0",x: 0,y: 12,w: 5,h: 5,static:false,t:'LSP wise trucks without GPS Data',s:0},
								{i: "departmentwisetruckswithgps_chart_DepartmentChart_Department wise trucks with GPS Data_1",x: 5,y: 12,w: 5,h: 5,static:false,t:'Department wise trucks with GPS Data',s:1},
								{i: "truckswithgps_grid_TruckWithGPSChart_Trucks with GPS Data_1",x: 0,y: 17,w: 6,h: 6,static:true,t:'Trucks with GPS Data',s:1},
								{i: "truckswithoutgps_grid_TruckWithOutGPSChart_Trucks with out GPS Data_1",x: 6,y: 17,w: 6,h: 6,static:true,t:'Trucks with out GPS Data',s:1}
							];
				this.onUserAction(usrlayouts);
				usrlayouts.map((item) =>{
					//this.createElement(item);
					this.setState({
						items:this.state.items.concat(item)
					});
				});
				

			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	  }
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	startdate = d.getFullYear()+"-"+d.getMonth() +1+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	enddate = d.getFullYear()+"-"+d.getMonth() +1+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
	}
	
	formHandler = (event) => {
		//console.log("Form", event.target);
    	//event.preventDefault();
		var fromDate = document.getElementById('filter_from_date').value;
		var toDate = document.getElementById('filter_to_date').value;
		var deptCode = document.getElementById('filter_dept_code').value;
		var filtersparams = {
			filter_from_date:fromDate,
			filter_from_date:fromDate,
			filter_to_date:toDate,
			filter_dept_code:deptCode,
			filternow:"yes",
		} 
		this.setState({
			filtersparams:filtersparams,
			filter_from_date:fromDate,
			filter_to_date:toDate,
			filter_dept_code:deptCode,
			//filternow:"yes",
			//fsliderTranslate:''
		});
	}
	
	onReset(){
		console.log(this.state.items);
		this.setState({ items: {} });
	}
  createElement(el) {
		//console.log("createElement is : ",el.i);
	    var i = '';
	    try{	    	
	    	var type = el.i;	    	
	    }
	    catch(e){	    	
	    } 
	    i = el.i;
	    var componentTitle='';
	    var spt=el.i;
	    var myvalue=spt.split("_");
	    let dContent = [myvalue[0]];
	    //console.log("Content",dContent)
	    var columnaxis='';
	    var columnyaxis='';
	    var tottrucks='';
	    var truckgps='';
	    var notruckgps='';
	    var rowData='';
	    var columnwithDefs="";
	    const layoutdata={
			token:localStorage.getItem('token'),
		}
		componentTitle=el.t;
		var shwlayout='show-m';
		if(el.s == 0)
		{
			var shwlayout='show-n';
		}
		else{
			var shwlayout='show-m';
		}
		//console.log("componentTitle", componentTitle);
		Object.assign(el, { static: true })
	    try{
	    	 return (
		      <div key={i} data-grid={el} className={"grid-layout-item row "} >
			      <div className="col-xl-12 col-lg-12">
		             {
		            	 (componentTitle.toLowerCase() == 'counter')?
            			 <div className={"col-xl-12 col-lg-12 "+(shwlayout)} style={{padding:"0"}}>
			             
							          {dContent.map((componentId) => {
							        	 // console.log("componentId", componentId);
							              let MyComponent = ComponentIndex[componentId];
							              return (<MyComponent key={i} popnow={"0"} info={i} setFilterNow={this.state.filternow} componentTitle={componentTitle} fromDate={this.state.filter_from_date} toDate={this.state.filter_to_date} context={this} deptcode={this.state.filter_dept_code} />);
							            })}
						          
					      </div>
					      :
				    	  <div className={"col-xl-12 col-lg-12 "+(shwlayout)} style={{padding:"0"}}>
			              
										{dContent.map((componentId) => {
											let MyComponent = ComponentIndex[componentId];
											return (<MyComponent key={i} popnow={"0"} info={i} filtersparams={this.state.filtersparams} setFilterNow={this.state.filternow} componentTitle={componentTitle} fromDate={this.state.filter_from_date} toDate={this.state.filter_to_date} context={this} deptcode={this.state.filter_dept_code} />);
										})}
									
					      </div>
		             }
			    </div>
		    </div>
		    );
        }
        catch(e){
        	 return (
    		      <div key={i} data-grid={el} className={"grid-layout-item row"} >
    		        <div className={"col-md-12 col-lg-12"}>
    		        	<div className={"grid-div-title row"}>
    		        		{el.t}
    		        	</div>
    		         

        		        <span
        		          className={"remove close-bx"}
        		          onClick={this.onRemoveItem.bind(this, i)}
        		        >
        		          a
        		        </span>
    		        <span
    		          className={"remove close-bx"}
    		          onClick={this.onRemoveItem.bind(this, i)}
    		        >
    		          x
    		        </span>
    		          </div>
    		      </div>
    		    );
        }
	   
	  }
	  
	  onComponentRend = (e) => {
		  var type = e; // example variable - will change from user input
		  console.log(ComponentIndex)
          const ComponentToRender = ComponentIndex[type];
          return <ComponentToRender/>;
	  }
	  

	  onBreakpointChange(breakpoint, cols) {
	    this.setState({
	      breakpoint: breakpoint,
	      cols: cols
	    });
	  }

	  onLayoutChange(layout) {
		//  console.log("onChnage "+layout)
	    this.props.onLayoutChange(layout);
	    this.setState({ items: layout });
	  }


  onDragStop(e,l,c){
		
		//console.log("Drag e: ",e);
		//console.log("Drag l: ",l);
		//console.log("Drag c: ",c);
		var allitems=[];
		var data = e;
		
		for(var n=0;n<data.length;n++)
		{
			var title=data[n].i.toUpperCase().split("_");
			
			allitems[n]=data[n];
			allitems[n]['t']=title[3];
			allitems[n]['s']=title[4];
			//allitems[n]['dp']=data[n].dp;
		}
	    
	    this.setState({ items: allitems });
	    this.onUserAction(allitems)
	    
	  }
  onResizeStart(e){
		  for (let t1 = 0; t1 <= 100; t1 += 10) {
			  setTimeout(() => {
		  	    window.dispatchEvent(new Event('resize'))
		  	  }, t1)
		  	}
	  }
  onResizeItem(e,l, a){
		  console.log("herere", e)
		
		var allitems=[];
		var data = e;
		for(var n=0;n<data.length;n++)
		{
			var title=data[n].i.toUpperCase().split("_");
			
			allitems[n]=data[n];
			allitems[n]['t']=title[3];
			allitems[n]['s']=title[4];
			//allitems[n]['dp']=data[n].dp;
		}
	    
	    console.log(allitems);
		this.setState({ items: allitems});
		this.onUserAction(allitems)
	    this.forceUpdate();
	    for (let t1 = 0; t1 <= 100; t1 += 10) {
    	  setTimeout(() => {
    	    window.dispatchEvent(new Event('resize'))
    	  }, t1)
		}
			   
	  }
  onWidthChange = (e) => {
	  //console.log("Width Change is",e)
	  return e;
  }
  onGlobalAddItem = (e) => {
		   console.log(e.target);
		  console.log(e.target.id, e.target.name,e.target.className)
		 
		  this.setState({
	      items: this.state.items.concat({
	        i: e.target.id+"_"+e.target.className,
	        x: (this.state.items.length * 2) % (this.state.cols || 12),
	        //x:0,
	        y: Infinity, // puts it at the bottom
	        w: 4,
	        h: 4,
	        static:false,
	        t:e.target.name,
	        //dp:e.target.className
	      }),
	      newCounter: this.state.newCounter + 1
	    });
		
		  var newarry = [];
		  for(var nmi in this.state.items)
		  {
			  newarry.push(this.state.items[nmi]);
		  }
		  let itme={
			        i: e.target.id+"_"+e.target.className,
			        x: (this.state.items.length * 2) % (this.state.cols || 12),
			        //x:0,
			        y: 0, // puts it at the bottom
			        w: 4,
			        h: 4,
			        static:false,
			        t:e.target.name,
			        //dp:e.target.className
			      };
		  newarry.push(itme);
		 //console.log("Array Checking ",newarry);
	    //this.onUserAction(this.state.items)
		this.onUserNewAddGridAction(newarry)
  }

  onUserNewAddGridAction=(e) => {
	  const layoutdata={
				userid:this.state.userid,
				layout: JSON.stringify(e),
				//newlayout:JSON.stringify(e)
		}
	  //console.log(layoutdata);
	  redirectURL.post('/layouts/saveLayout', layoutdata,{
			headers:{
				'Content-Type':'application/json'
			}
		})
	  .then(function (response) {
		
	    console.log(response);
	  })
	  .catch(function (error) {
	    console.log(error);
	  });
	  
  }
  onUserAction=(e) => {
	  const layoutdata={
				userid:localStorage.getItem('userid'),
				layout: JSON.stringify(e)
		}
	 // console.log("ldata ",JSON.stringify(e));
	  
	  redirectURL.post('/layouts/saveNewLayout', layoutdata,{
			headers:{
				'Content-Type':'application/json'
			}
		})
		  .then(function (response) {
			
		    //console.log(response);
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	  
  }

  onRemoveItem(i) {
	console.log("removing", i);
	this.setState({ items: _.reject(this.state.items, { i: i }) });
	var allitems=[];
	for ( var item in this.state.items ) {
		if ( this.state.items[item].i === i) {
			allitems.push(this.state.items[item]);
		}
		else{
			
		}
	}
	console.log("allitems", allitems);
	this.onUserRemoveComponent(allitems[0]);
	
  }
  onUserRemoveComponent=(e) => {
	  console.log("User Remvie", e);
	  const ldata={
				userid:this.state.userid,
				layout: e.i
		}
		console.log("user remove", ldata);
	  redirectURL.post('/layouts/remove', ldata)
		  .then( (response) => {
			
			console.log(response);
			window.location.reload();
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
  }
  
 
  refActions = (e) => {
	  alert("test")
	  console.log("here"+e.target)
  }

  onChange = (event, { newValue }) => {
	    this.setState({
	      value: newValue
	    });
	  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      //suggestions: getSuggestions(value)
    });
  };

   onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  appendComponentHere =(e) =>{
	  
	  console.log(e,"hereme");
	  this.onGlobalAddItem(e);
	  this.setState({
	      suggestions: [],
	      value:''
	    });
  }
  onMaximizeItem = (e) => {
	  console.log(e);
  }
  onPinItem = (i) => {
	  console.log("Pin", i);
	  var allitems=[];
    for ( var item in this.state.items ) {
    	if ( this.state.items[item].i === i) {
        	allitems.push(this.state.items[item]);
        }
        else{
        	
        }
    }
    
    this.onUserPinComponent(allitems[0]);
    this.setState({
        items: this.state.items.map(el => el.i === i ? Object.assign(el, { static: true }) : el)
      });
    console.log(this.state.items)
  }
  onUserPinComponent=(e) => {
	  const layoutdata={
				userid:this.state.userid,
				layout: e.i
		}
	  console.log(layoutdata);
	  
	  redirectURL.post('/layouts/pin', layoutdata)
		  .then( (response) => {
			
		    console.log(response);
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
    this.forceUpdate();
    window.location.reload();
  }
  
  onUnPinItem = (i) => {
	  console.log("unPin", i);
	 var allitems=[];
    for ( var item in this.state.items ) {
       if ( this.state.items[item].i === i) {
        	allitems.push(this.state.items[item]);
        }
        else{
        	
        }
    }
    
    console.log(i);
    this.onUserUnPinComponent(allitems[0]);
    this.setState({
        items: this.state.items.map(el => el.i === i ? Object.assign(el, { static: false }) : el)
      });
    console.log(this.state.items)
  }
  onUserUnPinComponent=(e) => {
	  const layoutdata={
				userid:this.state.userid,
				layout: e.i
		}
	  console.log(layoutdata);
	  
	  redirectURL.post('/layouts/unpin', layoutdata)
		  .then( (response) => {
			
		    console.log(response);
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
    this.forceUpdate();
    window.location.reload();
  }
  
  onFilterItem = (e) => {
	  console.log(e);
	  var typeis = e.split("_");
	  if(typeis[0] == 'chart')
	  {
		  this.setState({
			  chartdata:[164,211,133,421,152,134,319,173,124,421,153,151,143,251,175,146,155,133,120,221,174,199,143,144,156]
		  });
	  }
	  //console.log(this.state.chartdata)
  }
  
  onPopItem = (e) =>{
	  console.log("Pop",e)
	 let cpm = e.split("_");
	  var dataprop='';
	    if(cpm[1] == 'chart')
	  	{
		    dataprop = this.state.chartdata;
	  	}
	  let ontn= [cpm[0]].map((componentId) => {
          let MyComponent = ComponentIndex[componentId];
          return (<MyComponent key={e} info={e} popnow={"1"} filtersparams={this.state.filtersparams} setFilterNow={this.state.filternow} componentTitle={cpm[3]} fromDate={this.state.filter_from_date} toDate={this.state.filter_to_date} context={this} deptcode={this.state.filter_dept_code} />);
        })
       
	  this.setState({
		  popContent:ontn
	  
	  });
	  this.onOpenModal();
  
  }
  onOpenModal = () => {
       this.setState({ open: true });
    };
   
    onCloseModal = () => {
      this.setState({ open: false });
	};
	
	/*Truck Sidebar Component Starts*/
	async onShowSidebarAquireComponent(e){
		this.setState({
			sliderTranslate:""
		});
		//console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = AppendComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						truckInfo:truckrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

				/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						consignments:consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}

		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = AppendComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = AppendComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
	}

	onApplyData(e){
		this.setState({
			sliderTranslate:"slider-translate",
			showdata:e
		});
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	}

	createElementViewport(el,k) {
		//console.log("View port ",el)
		
		///console.log("View port k ",k)
		const removeStyle = {
		  position: "absolute",
		  right: "2px",
		  top: 0,
		  cursor: "pointer"
		};
		Object.assign(el, { static: false })
		//const k = el.add ? "+" : k;
		return (
		  <div key={k} data-grid={el}>
			
			  <span className="text">{k}</span>
			
		  </div>
		);
	  }
	
	onShowViewport(){
		this.setState({
			vwTranslate:"vwTranslate",
			viewports:this.state.items
		});
	}

	onCloseViewport(){
		this.setState({
			vwTranslate:"",
		});
	}
	onShowGlobalFilter(e){
		this.setState({
			fsliderTranslate:"slider-translate"
		});
	}

	onCloseGlobalFilter(e){
		this.setState({
			fsliderTranslate:""
		});
	}
	onRefreshPage(){
		window.location.reload();
	}
	onDropdownDepartments(){
		let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
	}
	handleDepartmentDropDown(e){
		this.setState({ 
			//filter_dept_code: e.target.value,
			//filternow:"yes" 
		});
	}
	refreshFilter(){
		window.location.reload();
	}
  render() {
	  //console.log("View Ports : ", this.state.viewports);
	  const { value, suggestions } = this.state;
	  const inputProps = {
		      placeholder: 'Search here...',
		      value,
		      onChange: this.onChange,
		      className:'form-control',
		      style:{padding:'0.6em 0.375rem',height:'35px'}
		    };
	  const renderSuggestion = suggestion => (
			  <div id={suggestion.component_tag} name={suggestion.component_name} className={suggestion.grid_type} onClick={(e) => { e.stopPropagation(); this.appendComponentHere(e); } }>
			    {suggestion.component_name}
			  </div>
			);
	  
	  const modalStyles  = {
              width:'1200px !important',
      		}
      const { open } = this.state;
	    return (
	      <div className={"row"} style={{paddingBottom:'10%'}}>
			<div className="col-xl-6 col-lg-6">
				
			</div>
			  <div className="col-xl-6 col-lg-6 float-right">
			  	<span className="float-right ly" title="Change Layout" onClick={this.onShowViewport.bind(this)}><i className="icofont icofont-layout"></i></span>
			  {/*}	<span className="float-right ly" style={{background:"none"}} title="Filter" onClick={this.onShowGlobalFilter.bind(this)}><i className="icofont icofont-filter"></i></span>
			  */}
			  </div>
				  {
				 //console.log("Itemss", this.state.items) 
				  /*
	      		<div className={"col-sm-8 col-md-8 col-lg-8"}>
			      <MyGridComponent onGlobalAddItem={this.onGlobalAddItem} layouts = {this.state.items} />
		    		
		      	</div>
	   
	      		*/}
	      		 
				<div className="col-xl-12 col-lg-12">
					<iframe frameborder="0" width="1250" height="3000" src="https://3.108.113.108:8443/open-view/7310/9633f5252cfb446dd26a412a4e0325e8"></iframe>
				</div>   
	      		<div className={"col-sm-12 col-md-12 col-lg-12"}>
			        {/* <ResponsiveReactGridLayout
			        className={"col-md-12 col-lg-12"}
			        layouts={this.state.items}
			        onLayoutChange={this.onLayoutChange}
			          onBreakpointChange={this.onBreakpointChange}
			          //onDragStop = {this.onDragStop}
			          onResizeStart = {this.onResizeStart}
			          onResizeStop = {this.onResizeItem}
			          onWidthChange = {this.onWidthChange}
			        useCSSTransforms={true}
			          {...this.props}
			        >
			         {_.map(this.state.items, el => this.createElement(el))}
			         
			        </ResponsiveReactGridLayout> */}
		        </div>
		        <Modal open={open} onClose={this.onCloseModal} >
	                <div className={"row"} style={{ height: '200vh',padding:'5px 20px', position: 'fixed',top:'10px', left: '10px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-12 col-lg-12 col-md-12"} style={{padding:'20px', background:'#fff'}}>
	                		<div style={{position:'absolute',top:'5px',right:'10px'}}>
	                			<a href={"#"} style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../../assets/icons/close.png")} style={{width:'14px'}} />
	                				 
	                			</a>
	                		</div>
	                		{this.state.popContent}
	                	</div>
	                </div>
	           </Modal>
			   <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	{this.state.showdata}
				</div>
			   <div className={"slide-r "+(this.state.fsliderTranslate)} >
			   		<div className="fslide-r-title">
						<h4>
							<i class="icofont icofont-filter"></i> Filter
							<span className="float-right fclose" style={{marginRight:"5px"}} onClick={this.onCloseGlobalFilter.bind(this)}>x</span>
						</h4>
					</div>
					<div className="fcslide-r-body">
						<form className="row theme-form col-xl-12 col-lg-12">
							<div className="form-group col-xl-12 col-lg-12">
								<label for="formDate">From Date</label>
								<Datetime inputProps={{ placeholder: 'yyyy-mm-dd HH:mm:ss',name:'filter_from_date', id:'filter_from_date',  autoComplete:'off', class:'fc-input form-control' }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerStartDateTime.bind(this)} />
							</div>
							<div className="form-group col-xl-12 col-lg-12">
								<label for="formDate">To Date</label>
								<Datetime inputProps={{ placeholder: 'yyyy-mm-dd HH:mm:ss',name:'filter_to_date', id:'filter_to_date', autoComplete:'off', class:'fc-input form-control' }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} />
							</div>
							<div className="form-group col-xl-12 col-lg-12">
				  				<label for="dept_code">Department</label>
								  <select name="filter_dept_code" id="filter_dept_code" className="form-control" onChange={this.handleDepartmentDropDown.bind(this)}>
									  <option value="">Select Department</option>
									  {this.onDropdownDepartments()}
								  </select>
							</div>
							
							<div className="form-group col-xl-12 col-lg-12">
								<button type="button" onClick={this.formHandler} className="btn btn-success">Submit</button>
								<button type="button" onClick={this.refreshFilter} className="btn btn-default">Reset</button>
							</div>
						</form>
					</div>
				</div>
				
				<div className={"viewport-r "+(this.state.vwTranslate)} >
					<h4>Change Grid Layout</h4>
                
					<div className="col-xl-12 col-lg-12">

						<GridLayout contextProps={this} gridlayouts={this.state.viewports} />
					</div>
					 
				</div>	
	      </div>
	    );
	  }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } 
      catch (e) {
      /*Ignore*/
      }
    }
    return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function refActions()
{
	alert("helloo")
}


                                
                                
                                
                                
                                
                                