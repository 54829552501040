
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ShowTruckConsignments = (props) => {
    
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.context.componentParent.onClickShowTruckLegs(props);
    };

    return (
        <div>
            <button 
                title="Show Consignments" 
                onClick={handleClick}
                className="custom-btn label label-success">

                <i className="icofont icofont-sub-listing f16"></i>
            </button>
       
        </div>
    );
};

export default ShowTruckConsignments;
