
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ActionItem = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props ", props.data);
        props.context.componentParent.onCellClicked(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-eye"></i> View Map </button>
       
        </div>
    );
};

export default ActionItem;
