import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import RailFinancialColumnLineChart from "./railfinancialcolumlinechart";
import RailFinancialStackDrilldownChart from "./railfinancialstackeddrilldownchart";
import RailFinancialColumnDrillChart from "./railfinancialcolumndrillchart";
import RailFinancialBreakColumnDrillChart from "./railfinancialbreakcolumndrillchart";
import RailFinancialColumnGroupLineChart from "./railfinancialcolumgrouplinechart";
import RailFinancialStackChart from "./railfinancialstackchart";
import RailFinancialDelayMonthCompenseChart from "./railfinancialdelaymonthcompenschart";
import RailFinancialDelayCompenseChart from "./railfinancialdelaycompenschart";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class RailAnaytics extends Component{
        constructor(props){
        super(props);
        this.state = {
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            dept_code:"",
            pageTitle:"Rail",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            open:false,
            financialyear:'',
            finyear:'',
            raildispatchdata:[],
            raildispatchdataavg:[],
            raildispatchthird:[],
            raildispatchfourth:[],
            raildispatchfifth:[],
            raildispatchsixth:[],
            raildispatchseventh:[],
            raildispatcheigth:[],
            sourceoption:[],
            clusters:"",
            transporters:[],
            clusterdropdown:[],
            transdropdown:[],
            sourcedropdown:[],
            olderfyplot:[],
            showThirdChart: "show-m",
            showThirdGrid: "show-n",
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true

            },              
            rowSelection:'multiple',
                rowModelhighchartoptionsType: 'enterprise',
                rowGroupPanelShow: 'always',
                overlayNoRowsTemplate: "",
                frameworkComponents: {
            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    },
                ]
            },
            thirdChartGridData: [],
            sourceoption2: {}
        }

        this.monthoptions = this.monthoptions.bind(this);
        this.onLoadShowData = this.onLoadShowData.bind(this);
        this.onLoadsecond = this.onLoadsecond.bind(this);
        this.onLoadThrid = this.onLoadThrid.bind(this);
        this.onLoadFourth = this.onLoadFourth.bind(this);
        this.clusteroptions = this.clusteroptions.bind(this);
        this.transportersoptions = this.transportersoptions.bind(this);
        this.onLoadFifth = this.onLoadFifth.bind(this);
        this.onLoadSixth = this.onLoadSixth.bind(this);
        this.onLoadSeventh = this.onLoadSeventh.bind(this);
        this.onLoadEighth = this.onLoadEighth.bind(this);
        this.onClickShowFYData = this.onClickShowFYData.bind(this);
        this.onLoadOlderFys = this.onLoadOlderFys.bind(this);
        this.onClickSourceData = this.onClickSourceData.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                //console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async () => {
        var sources = [
            {"value":"F", label:"Haryana"},
            {"value":"G", label:"Gujarat"},
            // {"value":"M", label:"Manesar"},
            // {"value":"I", label:"Silguri"},
           // {"value":"T", label:"Gurgoan"},
         ]
         //console.log(":sources ", sources);
         this.setState({
            sourcedropdown:sources,
            sourceoption:sources[0],
            sourceoption2 : sources[0]
         })
         
        var mm = moment.parseZone().subtract(1, 'months').format("MM")
        var yyyy = moment.parseZone().add(1, 'year').format("YYYY")
        // //console.log("yyyy ", yyyy)
        var q1 = moment().quarter(moment().quarter()).startOf('quarter');
        var q2 = moment().quarter(moment().quarter()).endOf('quarter');
        // //console.log("Q1 ",q1)
        // //console.log("Q2 ",q2)

        const obj = {};

        /* 0-Jan, 1-Feb, 2-Mar */
        if (moment().month() <= 2) { 
            obj.quarter1 = { start: moment().month(3).startOf('month').add('years', -1), end: moment().month(5).endOf('month').add('years', -1) };
            obj.quarter2 = { start: moment().month(6).startOf('month').add('years', -1), end: moment().month(8).endOf('month').add('years', -1) };
            obj.quarter3 = { start: moment().month(9).startOf('month').add('years', -1), end: moment().month(11).endOf('month').add('years', -1) };
            obj.quarter4 = { start: moment().month(0).startOf('month'), end: moment().month(2).endOf('month') };    
        } else {
            obj.quarter1 = { start: moment().month(3).startOf('month'), end: moment().month(5).endOf('month') };
            obj.quarter2 = { start: moment().month(6).startOf('month'), end: moment().month(8).endOf('month') };
            obj.quarter3 = { start: moment().month(9).startOf('month'), end: moment().month(11).endOf('month') };
            obj.quarter4 = { start: moment().month(0).startOf('month').add('years', 1), end: moment().month(2).endOf('month').add('years', 1) };    
        }
        var start = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("MMM-YYYY")
        var end = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("MMM-YYYY")
        var startyy = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("YYYY")
        var endyy = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("YYYY")
        var startmm = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("YY")
        var endmm = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("YY")
       
        var financialyear = start+" - "+end;
        var finyear = startmm+" - "+endmm;

        var defaultmm = {value: startyy+","+endyy, label: "Apr-"+startyy+" - Mar-"+endyy}
        
        this.setState({
            financialyear : financialyear,
            finyear:finyear,
            month:defaultmm
        });

        await redirectURL.post("/consignments/sndinvoiceslist")
       .then((response) => {
           //console.log("REs ", response.data)
           var records = response.data;
           var clusters = records.clusters;
           var allclusters = []
           if(clusters.length > 0)
           {
                clusters.map((im) => {
                    if(im != '' && im != null)
                    {
                        allclusters.push(im)
                    }        
                })
           }
           //console.log("allclusters ", allclusters)
           var transporters = records.transporters;
           //console.log("transporters[0] ", transporters[0].transporter_code)
           this.setState({
                transdropdown:transporters,
                clusterdropdown:allclusters,
                clusters:{value:allclusters[0],label:allclusters[0]},
                transporters:[{value:"ALL", label:"All"}]
           })
       })

        
        //console.log("financialyear ", financialyear)
        var parameters = {
            financialyear:financialyear
        }
        var yearparams = {
            fy:[startyy, endyy]
        }
        var curenty = moment.parseZone().format("YYYY")
        var olderfy = []
        for(var y = 2015; y<curenty; y++)
        {
            //console.log("y ", y)
            var strt = moment.parseZone(new Date(y+"-01-01")).add(1, 'years').format("YYYY");
            //console.log("strt ", strt)
            var first = y
            var end = strt
            var fy = first+","+end;
            var labelfy = "Apr-"+first+" - Mar-"+end;
            olderfy.push(labelfy)
        }
        var pm = {
            olderfy:olderfy
        }
        await this.onLoadOlderFys(pm, parameters);
        //await this.onLoadShowData(parameters);
        await this.onLoadsecond();
        await this.onLoadThrid(yearparams);
        await this.onLoadFourth(yearparams);
        await this.onLoadFifth(yearparams);
       // this.onLoadSixth(yearparams);
       await this.onLoadSeventh(yearparams);
       await this.onLoadEighth(yearparams);
       // const daysInMonth = moment(startDate).daysInMonth();
       
    }

    onLoadOlderFys(prmt, parameters){
        redirectURL.post("/consignments/oldfinancialyeardata", prmt)
        .then(async (response) => {
          console.log("older list ",response.data)
            var records = response.data
            var oldfy = groupBy(records, rdata => rdata.financial_year);
            console.log("oldfy ", oldfy)
            var oldyears = []
            try{
                oldfy.forEach((item,key) => {
                    var sk = key.split(" - ")
                    var keysp1 = moment.parseZone(new Date(sk[0])).format("YY")
                    var keysp2 = moment.parseZone(new Date(sk[1])).format("YY")
                    var combkey = "FY "+keysp1+"-"+keysp2
                    var ofyact = []
                    var oldact = 0
                    var oldplan = 0
                    if(item.length > 0)
                    {
                        item.map((itm) => {
                            oldact=parseInt(oldact)+parseInt(itm.actual)
                            if(itm.planned != null && itm.planned != NaN)
                            {
                                oldplan=parseInt(oldplan)+parseInt(itm.planned)
                            }
                            else{
                                oldplan=parseInt(oldplan)+parseInt(0)
                            }
                            
                        })
                    }
                    ofyact = {month:combkey,actual:oldact,planned:oldplan}
                    oldyears.push(ofyact)
                })
                //console.log("oldyears ", oldyears)
                // this.setState({
                //     olderfyplot:oldyears
                // })
                console.log(parameters, "parameters")
                await redirectURL.post("/consignments/financialyeardata", parameters)
                .then((response) => {
                 console.log("new list ",response.data)
                    var records = response.data
                    var olderfyplot = oldyears;
                    console.log(records, "fydata")
                    // if(records.length > 0)
                    // {
                        records.sort(GetSortOrder("rowid"));
                        var categories = []
                        var fycategories = []
                        var raildispatcharr = []
                        var raildispatchactualseries = []
                        var raildispatchplanseries = []
        
                       
                        var yyyy = moment.parseZone().format("YYYY")
                        var mm = moment.parseZone().format("MMM")
                        olderfyplot.forEach((item,key) => {
                            // console.log("Key ", key)
                            // console.log("item ", item)
                            categories.push(item.month)
                            raildispatchactualseries.push(item.actual)
                            raildispatchplanseries.push(item.planned)
                        })
                        if(records.length > 0)
                        {
                            // if(olderfyplot.length > 0)
                            // {
                                
                            //}
                            // console.log("categories ", categories)
                            // console.log("raildispatchactualseries ", raildispatchactualseries)
                            // console.log("raildispatchplanseries ", raildispatchplanseries)
                            
                            var actl = 0;
                            var actlarr = 0;
                            records.map((item) => {
                                var aa = item.month.split("-")
                                //console.log("aa ", aa)
                                var mmmm = moment.parseZone(new Date(item.month)).format("MMM-YY")
                                //console.log("mmmm ",mmmm)
                                categories.push(mmmm)
                                var m = item.month.split("-");
                                var t = (parseInt(yyyy)+1) +"=="+ (parseInt(m[1])+1)
                                //console.log('taa ',t)
                                console.log(yyyy, m[1],"-", (parseInt(yyyy)+1), (parseInt(m[1])))
                                // if(yyyy === m[1] || (parseInt(yyyy)+1) === (parseInt(m[1])))
                                // {
                                    var mmt =  moment.parseZone(new Date(mm+"-"+yyyy)).format("MM")
                                    var mmty =  moment.parseZone(new Date(mm+"-"+yyyy)).format("YYYYMM")
            
                                    var smmt =  moment.parseZone(new Date(item.month)).format("MM")
                                    var smmty =  moment.parseZone(new Date(item.month)).format("YYYYMM")
                                    // console.log("mmt ", mmty)
                                    // console.log("smmt ", smmty)
                                    var tt = parseInt(smmty)+"==="+parseInt(mmty);
                                    //console.log("TT ", tt)
                                    // if(parseInt(smmty) <= parseInt(mmty))
                                    // {
                                        actl = parseInt(actl)+parseInt(item.planned)
                                        //raildispatchactualseries.push(actl)
                                        raildispatchplanseries.push(actl)
                                    // }
                                    // else
                                    // {
                                    //     var ddd = yyyy +"==="+ m[1]+" || "+yyyy+" === "+(parseInt(m[1])+1);
                                    //     //console.log("ddd ", ddd)
                                    //     // if(yyyy == m[1] && yyyy < (parseInt(m[1])+1))
                                    //     // {
                                    //       //  console.log("s1")
                                    //         raildispatchplanseries.push(0)
                                    //     // }
                                    //     // else
                                    //     // {
                                    //     //     console.log("s2")
                                    //     //     raildispatchplanseries.push(parseInt(item.planned))
                                    //     // }
                                    // }
                                // }
                                // else{
                                //     raildispatchplanseries.push(parseInt(item.planned))
                                // }

                                // if(yyyy === m[1] || (parseInt(yyyy)+1) === (parseInt(m[1])))
                                // {
                                    var mmt =  moment.parseZone(new Date(mm+"-"+yyyy)).format("MM")
                                    var mmty =  moment.parseZone(new Date(mm+"-"+yyyy)).format("YYYYMM")
            
                                    var smmt =  moment.parseZone(new Date(item.month)).format("MM")
                                    var smmty =  moment.parseZone(new Date(item.month)).format("YYYYMM")
                                    
                                    if(parseInt(smmty) <= parseInt(mmty))
                                    {
                                        actlarr = parseInt(actlarr)+parseInt(item.actual)
                                        //raildispatchactualseries.push(actl)
                                        raildispatchactualseries.push(actlarr)
                                        //raildispatchactualseries.push(parseInt(item.actual))
                                    }
                                    else{
                                        raildispatchactualseries.push(0)
                                    }
                                // }
                                // else{
                                //     raildispatchactualseries.push(parseInt(item.actual))
                                // }
                            })
                           
                        }
                        var series = [
                            {
                                name: 'Cummulative Plan',
                                type: 'column',
                                data: raildispatchplanseries,
                                dataLabels: {
                                    enabled: true
                                }
                            },{
                                name: 'Cummulative Actual',
                                type: 'spline',
                               // yAxis: 1,
                                data: raildispatchactualseries,
                                dataLabels: {
                                    enabled: true
                                }
                            }
                            
                        ]
            
                        raildispatcharr.push({
                            series : series,
                            categories : categories
                        })
                        console.log("raildispatcharr ", raildispatcharr)
                        this.setState({
                            raildispatchdata:raildispatcharr
                        })
                    // }
                    // else{
                    //     this.setState({
                    //         raildispatchdata:[{message:"no data"}]
                    //     })
                    // }
                    
                })
            
            }catch(e){

            }
              
            
        })
    }
    onLoadShowData(parameters){
        redirectURL.post("/consignments/financialyeardata", parameters)
        .then((response) => {
          console.log("firstchartddata",response.data)
            var records = response.data
            var olderfyplot = this.state.olderfyplot;
            if(records.length > 0)
            {
                records.sort(GetSortOrder("rowid"));
                var categories = []
                var fycategories = []
                var raildispatcharr = []
                var raildispatchactualseries = []
                var raildispatchplanseries = []

               
                var yyyy = moment.parseZone().format("YYYY")
                var mm = moment.parseZone().format("MMM")
                
                if(records.length > 0)
                {
                    if(olderfyplot.length > 0)
                    {
                        olderfyplot.forEach((item,key) => {
                            categories.push(key)
                            raildispatchactualseries.push(item.actual)
                            raildispatchplanseries.push(item.planned)
                        })
                    }
                    // console.log("categories ", categories)
                    // console.log("raildispatchactualseries ", raildispatchactualseries)
                    // console.log("raildispatchplanseries ", raildispatchplanseries)
                    
                    var actl = 0;
                    var actlarr = 0;
                    records.map((item) => {
                        categories.push(item.month)
                        var m = item.month.split("-");
                        var t = yyyy +"=="+ m[1]
                        //console.log('t ',t)
                        if(yyyy === m[1])
                        {
                            var mmt =  moment.parseZone(new Date(mm+"-"+yyyy)).format("MM")
    
                            var smmt =  moment.parseZone(new Date(item.month)).format("MM")
                           console.log("mmt ", mmt)
                           console.log("smmt ", smmt)
                            if(smmt<mmt)
                            {
                                actl = parseInt(actl)+parseInt(item.planned)
                                //raildispatchactualseries.push(actl)
                                raildispatchplanseries.push(actl)
                            }
                            else
                            {
                                if(yyyy === m[1])
                                {
                                    raildispatchplanseries.push(0)
                                }
                                else
                                {
                                    raildispatchplanseries.push(parseInt(item.planned))
                                }
                            }
                        }
                        else{
                            raildispatchplanseries.push(parseInt(item.planned))
                        }
                        if(yyyy === m[1])
                        {
                            if(smmt<mmt)
                            {
                                actlarr = parseInt(actlarr)+parseInt(item.actual)
                                //raildispatchactualseries.push(actl)
                                raildispatchactualseries.push(actlarr)
                                //raildispatchactualseries.push(parseInt(item.actual))
                            }
                            else{
                                raildispatchactualseries.push(0)
                            }
                        }
                        else{
                            raildispatchactualseries.push(parseInt(item.actual))
                        }
                    })
                   
                }
                var series = [
                    {
                        name: 'Cummulative Actual',
                        type: 'line',
                        yAxis: 1,
                        data: raildispatchactualseries
                    },
                    {
                        name: 'Cummulative Plan',
                        type: 'column',
                        data: raildispatchplanseries
                    }
                ]
    
                raildispatcharr.push({
                    series : series,
                    categories : categories
                })
                console.log("raildispatcharr ", raildispatcharr)
                this.setState({
                    raildispatchdata: raildispatcharr
                })
            }
            else{
                this.setState({
                    raildispatchdata:[{message:"no data"}]
                })
            }
            
        })
    }
    
    onLoadsecond = async () => {
        
        await redirectURL.post("/consignments/railsecondchartdata")
        .then((response) => {
            //console.log("list ",response.data)
            var records = response.data
            records = records.filter(function(e){
                return e.month != moment.parseZone(new Date()).utcOffset("+05:30").format("MMM-Y")
            })
            records.sort(GetSortOrder("rowid"));
            //console.log("records ", records)
            var xcats = []
            var dilldown = []
            var raildispatcharr = []
            let resultarr = []
            var chartseries= [];
            let result = {}
            var exportavg= []
            var domesticavg = []
            // if(records.length > 0)
            // {

            //     result = records.reduce((result, current) => {
            //         ////console.log("current ", result)
            //         if(!result[current.financial_year]){
            //             result[current.financial_year] = { item_export: 0, item_domestic: 0, item_cnt:0 };
            //         }
            //         //result[current.financial_year].item_month += current.month;
            //         result[current.financial_year].item_export += parseInt(current.export);
            //         result[current.financial_year].item_domestic += parseInt(current.domestic);
            //         result[current.financial_year].item_cnt += 1;
            //         return result;
            //     }, {})
                
            //     //console.log("result ", result);
            // }
            // resultarr=result;
            // var grouped = _.mapValues(_.groupBy(records, 'financial_year'),
            //               clist => clist.map(record => _.omit(record, 'financial_year')));
            
            // var rr = Object.entries(resultarr).forEach(item => {
            //    // //console.log("Ite ", item[1])
            //     xcats.push("Monthly Avg. "+item[0])
            //     var drilid = item[0].replace(/[^\w\s]/g, '')
            //     var id = drilid.replace(/ /g,"")
            //     exportavg.push({
            //         name:"Monthly Avg. "+item[0],
            //         y:(Math.round(parseInt(item[1].item_export)/parseInt(item[1].item_cnt))),
            //         drilldown:true
            //     });

            //     domesticavg.push({
            //         name:"Monthly Avg. "+item[0],
            //         y:(Math.round(parseInt(item[1].item_domestic)/parseInt(item[1].item_cnt))),
            //         drilldown:true
            //     });
                
            // })


            var stacserieex=[]
            var stacseriesdm=[]
            var drilldata =[]
            var stackgrp= []
            if(records.length > 0)
            {

                var currentm= moment.parseZone().format("MM")
                var currenty= moment.parseZone().format("YYYY")

                
               
                var stackarr = groupBy(records, rdata => rdata.financial_year);
                //console.log("stackarr ", stackarr)
               
                var diff =  12
                stackarr.forEach((item,key) => {
                    var exdata= []
                    var dmdata= []
                    var totexdmdata= []
                    var sk = key.split(" - ")
                    var keysp1 = moment.parseZone(new Date(sk[0])).format("YY")
                    var keysp2 = moment.parseZone(new Date(sk[1])).format("YY")
                    var combkey = keysp1+"-"+keysp2

                    xcats.push(combkey);
                    //console.log("keysp ", keysp)
                    var exportcount = 0
                    var domesticcount = 0
                    var no_of_months_included = 0
                    //console.log("item ", item)
                    if(item.length > 0)
                    {   
                        var fy = item[0].financial_year;
                        var fys = fy.split(" ")
                        // console.log("fys[0]",fys[0])
                        // console.log("fys[2]",fys[2])
    
                        var sp = fys[0].split("-")
                        if(currenty == sp[1])
                        {
                            var s= moment.parseZone(new Date(fys[0])).format("YYYY-MM")
                            var s1= moment.parseZone().format("YYYY-MM")
            
                            diff = moment(s1).diff(moment(s), 'months', true)
                            
                        }
                        else
                        {
                            var s= moment.parseZone(new Date(fys[0])).format("YYYY-MM")
                            var s1= moment.parseZone(new Date(fys[1])).format("YYYY-MM")
            
                            diff = 12
                            
                        }
    
                       // console.log("diff ", diff)
                        item.map((itm) => {
                            //console.log("export ", itm.export)
                            exdata.push([itm.month,parseInt(itm.export)])
                            dmdata.push([itm.month,parseInt(itm.domestic)])
                            var mmmm = moment.parseZone(new Date(itm.month)).format("MMM-YY")
                          
                            totexdmdata.push([mmmm,(parseInt(itm.export)+parseInt(itm.domestic))])
                            // if(itm.export != 0 && itm.domestic != 0)
                            // {
                                exportcount = (parseInt(exportcount)+parseInt(itm.export))
                                domesticcount = (parseInt(domesticcount)+parseInt(itm.domestic))
                                // no_of_months_included = no_of_months_included + 1
                            // }
                            
                            
                        })
                    }
                    // console.log(exportcount+domesticcount,"stackarr",diff-1,Math.round((exportcount+domesticcount)/(diff - 1)))
                    // var exportavg = Math.round(exportcount/diff - 2);
                    // var domesticavg = Math.round(domesticcount/diff - 2);
                    var totalexdom = Math.round((exportcount+domesticcount)/(diff - 1))
                    //console.log("exportavg ", exportavg)
                    // if(exportavg !== NaN && exportavg !== "NaN")
                    // {
                    //     var exavg = exportavg
                    // }
                    // else{
                    //     var exavg = 0
                    // }

                    if(totalexdom !== NaN && totalexdom !== "NaN")
                    {
                        var exavg = totalexdom
                    }
                    else{
                        var exavg = 0
                    }

                  //  stackgrp.push()
                    stacserieex.push({
                        name:"Monthly Avg. "+combkey,
                        y: exavg,
                        drilldown:"Ex"+combkey
                    })
                    // stacseriesdm.push(
                    // {
                    //     name:"Monthly Avg. "+combkey,
                    //     y:Math.round(domesticcount/diff),
                    //     drilldown:"Dm"+combkey
                    // })

                    drilldata.push({
                        name:"E"+combkey,
                        id:"Ex"+combkey,
                        data:totexdmdata
                    }
                    // ,
                    // {
                    //     name:"D"+combkey,
                    //     id:"Dm"+combkey,
                    //     data:dmdata
                    // }
                    )

                })
            }
            // console.log("stacserieex ", stacserieex)
            // console.log("stacseries ", stacseriesdm)
            chartseries = [
                {
                    name:"Exports&Domestic",
                    data:stacserieex
                }
                // ,
                // {
                //     name:"Domestic",
                //     data:stacseriesdm
                // }
            ]

            ////console.log("grouped ", grouped)
            // var drilldata =[]
            // var dcats = []
            // var rr = Object.entries(grouped).forEach(item => {
            // ////console.log("Ite ", item[1])
            //   var dt = item[1];
            //   var dridt = []
            //   var dridt1 = []
            //   var mont = []
            //   dt.map((t) => {
            //     mont.push(t.month)
            //     dridt.push([t.month, parseInt(t.export)])
            //     dridt1.push([t.month, parseInt(t.domestic)])
            //   })
            //   dcats=mont;
            // var drilid = item[0].replace(/[^\w\s]/g, '')
            // var id = drilid.replace(/ /g,"")
            //     drilldata.push({
            //         id:"Ex"+id,
            //         name:item[0],
            //         data:dridt
            //     });
            //     drilldata.push({
            //         id:"Dm"+id,
            //         name:item[0],
            //         data:dridt1
            //     });
            // })
            var drilldownseries = {
                series:drilldata
            }
            raildispatcharr.push({
                series : chartseries,
                categories : xcats,
                drilldown : drilldata,
                dcategories:[]
            })
            //console.log("second ", raildispatcharr)
            this.setState({
                raildispatchdataavg:raildispatcharr
            })
        })
    }

    onLoadThrid(parameters){
        if(this.state.sourceoption.value == "F")
        {
            var sourceop = ["F", "M"]
        }
        else{
            var sourceop = [this.state.sourceoption.value]
        }
        parameters.source = sourceop;
        redirectURL.post("/consignments/thirdchartdata", parameters)
        .then((response) => {
            try{
                var records = response.data;
                var cluster_wise_data = JSON.parse(records.cluster_wise_data);
                console.log(cluster_wise_data, "thirdcharrt")

                var month_wise_data = JSON.parse(records.month_wise_data)
                month_wise_data = groupBy(month_wise_data, rdata => rdata.cluster);
                cluster_wise_data = groupBy(cluster_wise_data, rdata => rdata.fy);
                // //console.log("cluster_wise_data ", cluster_wise_data)

                var transporter_wise_data = JSON.parse(records.transporter_wise_data);
                //console.log("transporter_wise_datatransporter_wise_data ", transporter_wise_data)
                transporter_wise_data = groupBy(transporter_wise_data, rdata => rdata.month);

                var gpsloadingarr = []
                var categories = []
                cluster_wise_data.forEach((item, key) =>{
                    // //console.log("item ", item)
                    // //console.log("key ", key)
                    var trandrilseries = [];
                    if(item.length > 0)
                    {
                        item.map((itm) => {
                            categories.push({c:itm.cluster});
                            gpsloadingarr.push({
                                "name":itm.cluster,
                                "y":parseInt(itm.avg_transit_time),
                                "drilldown":itm.cluster
                            })
                        })
                    }
                    
                })
                let newArray = [];               
                // Declare an empty object 
                let totuniqueObject = {}; 
                var totobjTitle;
                // Loop for the array elements 
                for (let i in categories) { 

                    // Extract the title 
                    totobjTitle = categories[i]['c']; 

                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = categories[i]; 
                } 

                // Loop to push unique object into array 
                for (var a in totuniqueObject) { 
                    newArray.push(totuniqueObject[a].c); 
                } 

                
                var grplist = []
                month_wise_data.forEach((item,key) =>{
                    var groupfy = groupBy(item, rdata => rdata.cluster);
                    groupfy.forEach((itm,ky) => {
                        var ll = []
                        // itm.sort(GetSortASCOrder("avg_transit_time"));
                        itm.map((t) => {
                            if(t.avg_transit_time > 0)
                            {
                                ll.push({"name": t.month,
                                            y: Math.round(parseFloat(t.avg_transit_time)),
                                        "drilldown": t.month+"-"+t.cluster})
                            }
                        })
                        grplist.push({
                            "name":key,
                            "id":key,
                            "data":ll
                        })
                    })
                })
                transporter_wise_data.forEach((item,key1) =>{
                    var groupfy = groupBy(item, rdata => rdata.month);
                    groupfy.forEach((it,key2) =>{
                        var groupmonth = groupBy(it, rdata => rdata.cluster);
                        groupmonth.forEach((itm,key3) => {
                            var ll = []
                            itm.sort(GetSortASCOrder("avg_transit_time"));
                            itm.map((t) => {
                                if(t.avg_transit_time > 0)
                                {
                                    ll.push({"name": t.transporter_name,
                                                "y": Math.round(parseFloat(t.avg_transit_time)),
                                            "cluster": t.cluster})
                                }
                            })
                            grplist.push({
                                "name":key2,
                                "id":key2+"-"+key3,
                                "data":ll
                            })
                        })
                    })
                    // console.log(groupfy,"groupfy")
                    
                })
                gpsloadingarr = gpsloadingarr.sort(GetSortASCOrder("y"));
                var thirdchartdata = [{
                    series:gpsloadingarr,
                    categories:newArray,
                    drilldown:grplist
                }];
                console.log(thirdchartdata,"thirdchartdatathirdchartdata")
                this.setState({
                    raildispatchthird:thirdchartdata
                })
                this.setState({
                    thirdChartGridData: ""
                })
                // redirectURL.post("/consignments/thirdchartdatagrid", parameters)
                // .then((resp) => {
                //     this.setState({
                //         thirdChartGridData: resp.data
                //     })
                // })  
            }
            catch(e){
                console.log(e, "thirdchartdatathirdchartdataerror")
                this.setState({
                    raildispatchthird:[{message:"no data"}]
                })
            }
        })
    }


    onLoadFourth(params){
        this.setState({
            raildispatchfourth : []
        })
        //console.log("sourceoption ",this.state.sourceoption)
        //console.log("clusters ",this.state.clusters)
        //console.log("transporters ",this.state.transporters)
        var selectedtrans = []
        if(this.state.transporters.length > 0)
        {
            this.state.transporters.map((trans) => {
                selectedtrans.push(trans.value)
            })
        }
        if(this.state.sourceoption2.value == "F")
        {
            var sourceop = ["F", "M"]
        }
        else{
            var sourceop = [this.state.sourceoption2.value]
        }
        try{
            var parameters ={
                consigner_code:sourceop,
                cluster:this.state.clusters.value,
                transporter_codes:selectedtrans,
                fy: params.fy
            }    
        }
        catch(e){
            var parameters ={
                consigner_code:sourceop,
                cluster:this.state.clusters.value,
                transporter_codes:selectedtrans,
             //   fy: params.fy
            }
        }
        
        //console.log("parameters fourth ",parameters)
        redirectURL.post("/consignments/railfourthchartdata", parameters)
        .then((response) => {
            ////console.log("fourth ", response.data)
            try{
                var records = response.data;
                var series_data = JSON.parse(records.month_wise_data);
                var drilldown_data = JSON.parse(records.transporter_wise_data);
                //var drilldown_data1 = JSON.parse(records.drilldown_data);
                var categories = []
                console.log(series_data);
               // console.log(drilldown_data1);
                console.log(categories);
                var firstmilearr = []
                var secondmilearr = []
                var lastmilearr = []
                var innerdata=[]
                if(series_data.length > 0)
                {
                    series_data.map((item) => {
                        categories.push(item.month);
                        firstmilearr.push({
                            name:item.month,
                            y:item.first_mile_transit_time,
                            drilldown:item.month
                        })
                        secondmilearr.push({
                            name:item.month,
                            y:item.second_mile_transit_time,
                            drilldown:item.month
                        })
                        lastmilearr.push({
                            name:item.month,
                            y:item.last_mile_transit_time,
                            drilldown:item.month
                        })
                    })
                }
                if(drilldown_data.length > 0)
                {
                    var transgroup = groupBy(drilldown_data, rdata => rdata.month);
                    //console.log("transgroup ", transgroup)
                    transgroup.forEach((item,key) =>{
                        var arr = []
                        var arr2 = []
                        var arr3 = []
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                arr.push([itm.transporter_name,itm.first_mile_transit_time]);
                                arr.push([itm.transporter_name,itm.second_mile_transit_time]);
                                arr.push([itm.transporter_name,itm.last_mile_transit_time]);
                            })
                        }

                            innerdata.push({
                                name:key,
                                id:key,
                                data:arr
                            }
                            // ,
                            // {
                            //     name:"Second mile transit time",
                            //     id:key,
                            //     data:arr2
                            // },
                            // {
                            //     name:"Last mile transit time",
                            //     id:key,
                            //     data:arr3
                            // }
                            )
                    })
                    
                }

               
            //     var first = []
            //     var second = []
            //     var last = []
            //    try
            //     {
            //         var ff = drilldown_data1.first_mile_transit_time;
            //         var fgroup = groupBy(ff, rdata => rdata.month);
            //         //console.log("fgroup ", fgroup)
            //         fgroup.forEach((item,key) => {
            //            if(item.length > 0)
            //            {
            //                var tdt = []
            //                item.map((itm) => {
            //                 tdt.push([itm.transporter_name, itm.first_mile_transit_time])
            //                })
            //            }
            //            first.push({
            //                name:key,
            //                id:key,
            //                data:tdt
            //            })
            //         })

            //         var ss = drilldown_data1.second_mile_transit_time;
            //         var sgroup = groupBy(ss, rdata => rdata.month);
            //         //console.log("fgroup ", fgroup)
            //         sgroup.forEach((item,key) => {
            //            if(item.length > 0)
            //            {
            //                var tdt = []
            //                item.map((itm) => {
            //                 tdt.push([itm.transporter_name, itm.second_mile_transit_time])
            //                })
            //            }
            //            second.push({
            //                 name:key,
            //                 id:key,
            //                 data:tdt
            //             })
            //         })

            //         var ll = drilldown_data1.first_mile_transit_time;
            //         var lgroup = groupBy(ll, rdata => rdata.month);
            //         //console.log("fgroup ", fgroup)
            //         lgroup.forEach((item,key) => {
            //            if(item.length > 0)
            //            {
            //                var tdt = []
            //                item.map((itm) => {
            //                 tdt.push([itm.transporter_name, itm.last_mile_transit_time])
            //                })
            //            }
            //            last.push({
            //                 name:key,
            //                 id:key,
            //                 data:tdt
            //             })
            //         })
                    
            //     }
            //     catch(e){

            //     }
            //     console.log("First ", first)
            //     console.log("second ", second)
            //     console.log("last ", last)
            //     var first = first.concat(second)
            //     var first = first.concat(last)

            var first_mile_dict = JSON.parse(records.first_mile_dict)
            var second_mile_dict = JSON.parse(records.second_mile_dict)
            var last_mile_dict = JSON.parse(records.last_mile_dict)
                var fourthchartdata = [];
                fourthchartdata.push({
                    series: [{
                        name:"Last mile transit time",
                        data:lastmilearr
                    },
                    {
                        name:"Second mile transit time",
                        data:secondmilearr
                    },{
                        name:"First mile transit time",
                        data:firstmilearr
                    }
                    ],
                    categories: categories,
                    drilldown: [first_mile_dict,second_mile_dict,last_mile_dict]
                })

                console.log("fourthchartdata", fourthchartdata)
                console.log("first_mile_dict ", first_mile_dict)
                console.log("second_mile_dict ", second_mile_dict)
                console.log("last_mile_dict ", last_mile_dict)
                this.setState({
                    raildispatchfourth:fourthchartdata
                })
            }
            catch(e){
                console.log(e)
                this.setState({
                    raildispatchfourth:[{message:"no data"}]
                })
            }
            
        })
    }

    onLoadFifth(parameters){
        redirectURL.post("/consignments/railfifthchartdata",parameters)
        .then((response) => {
            if(response.data.categories == "no data")
            {
                this.setState({
                    raildispatchfifth:[{message:"no data"}]
                });
                
            }
            else{
                try{
                    var records = response.data;
                    var cluster_data = JSON.parse(records.cluster_data)
                    var transporter_wise_data = JSON.parse(records.transporter_wise_data)
                    //console.log("cluster_data ", cluster_data)
                    var categories = []
                    var total_invoiced = []
                    var total_delayed = []
                    var delay_percent = []
                    if(cluster_data.length > 0)
                    {
                        cluster_data.map((item) => {
                            categories.push(item.cluster)
                            total_invoiced.push(Math.round(parseFloat(item.total_invoiced)))
                            total_delayed.push(Math.round(parseFloat(item.total_delayed)))
                            //delay_percent.push(Math.round(parseFloat(item.delay_percent)))
                            delay_percent.push({
                                name:item.cluster,
                                y:Math.round(parseInt(item.delay_percent)),
                                drilldown:item.cluster
                            })
                            

                        })
                    }
                    var transdrilldown = []
                    var transgroup = groupBy(transporter_wise_data, rdata => rdata.cluster);
                    //console.log("transgroup ", transgroup)
                    if(transporter_wise_data.length > 0)
                    {
                        try{
                            transgroup.forEach((item,key) => {
                                //console.log("item ", item)
                                var transdata = []
                                if(item.length > 0)
                                {
                                    item.map((itm) => {
                                        transdata.push([itm.transporter_name,parseInt(itm.delay_percentage)])
                                    })
                                }
                                transdrilldown.push({
                                    name:key,
                                    id:key,
                                    type:"column",
                                    data:transdata,
                                    dataLabels:{
                                        format:"{point.y}%"
                                    }
                                })
                            })
                        }
                        catch(e){
                            
                        }
                         
                            

                        
                    }
                    var fifthchartdata = []
                    var series = [
                        {
                            name: 'Total Invoiced',
                            type: 'column',
                            yAxis: 1,
                            data: total_invoiced
                        },
                        {
                            name: 'Total Ontime',
                            type: 'column',
                            yAxis: 1,
                            data: total_delayed
                        },
                        {
                            name: 'Ontime Percent',
                            type: 'spline',
                            data: delay_percent,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y}%'
                            },
                        }
                    ]
                    fifthchartdata.push({
                        seriesinvoice:total_invoiced,
                        seriesdelay:total_delayed,
                        seriesdelaypercent:delay_percent,
                        series:series,
                        categories:categories,
                        drilldown:transdrilldown
                    })
                    console.log("fifthchartdata ",fifthchartdata)
                    this.setState({
                        raildispatchfifth:fifthchartdata
                    });
                }
                catch(e){
                    this.setState({
                        raildispatchfifth:[{message:"no data"}]
                    });
                }
                
                
            }
        })
        
    }


    onLoadSixth(params){
         this.setState({
            loadshow:"show-m"
        })
        var parameters = {
            chart_type:"transporter",
            fy:params.fy
        }
        redirectURL.post("/consignments/railsixthchartdata",parameters)
        .then((response) => {
            //console.log("Sixfth ", response.data)
            
            var records = response.data;
            if(response.data.transporter_wise_data == 'no data')
            {
                this.setState({
                    raildispatchsixth:[{message:"no data"}],
                    loadshow:"show-n"
                });
            }
            else{
                try{
                    var transporter_data = JSON.parse(records.transporter_wise_data)
                    var categories = JSON.parse(records.categories)
                    var sixthchartdata = []
                   //console.log("listarr ", listarr)
                    sixthchartdata.push({
                        seriesdelaypercent: transporter_data,
                        categories: categories
                    })
                    //console.log("sixthchartdata ",sixthchartdata)
                    this.setState({
                        raildispatchsixth:sixthchartdata,
                        loadshow:"show-n"
                    });
                }
                catch(e){
                    this.setState({
                        raildispatchsixth:[{message:"no data"}],
                        loadshow:"show-n"
                    });
                }   
                
            }
           
            
        })
    }

    onLoadSeventh(parameters){
       
        redirectURL.post("/consignments/railseventhchartdata",parameters)
        .then((response) => {
            console.log("Seventh ", response.data)
            if(response.data.month_wise_dealer_compensation == 'no data')
            {
                this.setState({
                    raildispatchseventh:[{message:"no data"}],
                    loadshow:"show-n"
                });
            }
            else{
                try{
                    var records = response.data;
                    var cluster_wise_dealer_compensation = JSON.parse(records.cluster_wise_dealer_compensation)
                    //console.log("cluster_wise_dealer_compensation ", cluster_wise_dealer_compensation)
                    var categories = []
                    var totalcomp = []
                    var carsdata = []
                    if(cluster_wise_dealer_compensation.length > 0)
                    {
                        cluster_wise_dealer_compensation.map((item) => {
                            categories.push(item.cluster);
                            totalcomp.push(Math.round(item.dealer_compensation));
                            carsdata.push(Math.round(item.compensation_per_car));
                            // categories.push(item.month)
                            // var m = item.month.split("-");
                            // var t = yyyy +"=="+ m[1]
                            // ////console.log('t ',t)
                            // if(yyyy == m[1])
                            // {
                            //     var mmt =  moment.parseZone(new Date(mm+"-"+yyyy)).format("MM")
    
                            //     var smmt =  moment.parseZone(new Date(item.month)).format("MM")
                            //     ////console.log("mmt ", smmt)
                            //     if(smmt<mmt)
                            //     {
                            //         actl = parseInt(actl)+parseInt(item.actual)
                            //         totalcomp.push(actl)
                            //     }
                            //     else
                            //     {
                            //         raildispatchactualseries.push(parseInt(item.actual))
                            //     }
                            // }
                            // else{
                            //     raildispatchactualseries.push(parseInt(item.actual))
                            // }
                            // raildispatchplanseries.push(parseInt(item.planned))
                        })
                    
                    }
                    
                    var series = [
                        {
                            name: 'Total Compensation',
                            type: 'column',
                            yAxis: 1,
                            data: totalcomp
                        },
                        {
                            name: 'Rs/Car',
                            type: 'spline',
                            data: carsdata,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y}'
                            },
                        }
                    ]
                    var raildispatcharr = [];
                    raildispatcharr.push({
                        series : series,
                        categories : categories
                    })
                    //console.log("raildispatcharr ", raildispatcharr)
                    this.setState({
                        raildispatchseventh:raildispatcharr,
                        loadshow:"show-n"
                    });
                }
                catch(e){
                    this.setState({
                        raildispatchseventh:[{message:"no data"}],
                        loadshow:"show-n"
                    });
                }
                
            }
        })
    }

    onLoadEighth(parameters)
    {
        redirectURL.post("/consignments/raileighthchartdata",parameters)
        .then((response) => {
            //console.log("Eighth ", response.data)
            var records = response.data
            if(response.data.month_wise_dealer_compensation == 'no data')
            {
                this.setState({
                    raildispatcheigth:[{message:"no data"}],
                    loadshow:"show-n"
                });
            }
            else{
                try{
                    var month_wise_dealer_compensation = JSON.parse(records.month_wise_dealer_compensation);
            
                    var categories = []
                    var totalcomp = []
                    var carsdata = []
                    var drilldowncols = []
                    var drilldownlines = []
                    if(month_wise_dealer_compensation.length > 0)
                    {
                        month_wise_dealer_compensation.map((item) => {
                            categories.push(item.month);
                           // totalcomp.push(Math.round(item.dealer_compensation));
                           totalcomp.push({
                                name:item.month,
                                y:Math.round(item.dealer_compensation),
                                drilldown:item.month
                            })
                            //carsdata.push(Math.round(item.compensation_per_car));
                            carsdata.push({
                                name:item.month,
                                y:Math.round(item.compensation_per_car),
                                drilldown:item.month
                            })      
                          //  console.log("monthly_cluster_wise_data ",item.monthly_cluster_wise_data)
                            var allmonddcar = []
                            var allmonddcomp = []
                            if(item.monthly_cluster_wise_data.length > 0)
                            {
                                item.monthly_cluster_wise_data.map((im) => {
                                    allmonddcar.push([im.cluster,parseInt(im.compensation_per_car)])
                                    allmonddcomp.push([im.cluster,parseInt(im.dealer_compensation)])
                                })
                            }
                            var mth = item.month;
                            // drilldowncols.push({
                            //         name:mth,
                            //         id:mth,
                            //         data:allmonddcar
                            //     }
                            // )
                            // drilldownlines.push({
                            //     mth:{
                            //         name:mth,
                            //         data:allmonddcomp
                            //     }
                            // })
                            // {
                            //     'May-2020': {
                            //         name: 'May-2020',
                            //         data: [
                            //             ['Cows', 2],
                            //             ['Sheep', 3]
                            //         ]
                            //     }
                        })               
                    }
                    var series = [
                        {
                            name: 'Total Compensation',
                            type: 'column',
                            yAxis: 1,
                            data: totalcomp
                        },
                        {
                            name: 'Rs/Car',
                            type: 'spline',
                            data: carsdata,
                            dataLabels: {
                                enabled: true,
                                format: '{point.y}'
                            },
                        }
                    ]
                    var drilldowncols = records.dealer_compensation;
                    var drilldownlines = records.compensation_per_car;
                    var raildispatcharr = [];
                    raildispatcharr.push({
                        series : series,
                        categories : categories,
                        drilldowncols:drilldowncols,
                        drilldownlines:drilldownlines
                    })
                    console.log("raildispatcheigth ", raildispatcharr)
                    this.setState({
                        raildispatcheigth:raildispatcharr,
                        loadshow:"show-n"
                    });
                }
                catch(e){
                    this.setState({
                        raildispatcheigth:[{message:"no data"}],
                        loadshow:"show-n"
                    });
                }
                
            }
            
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    changeArrayHandler = month => {
        this.setState(
            { month }
        );
    }
    changeTransporterHandler = transporters => {
        this.setState(
            { transporters }
        );
    }
    changeSourceHandler = sourceoption => {
        this.setState(
            { sourceoption }
        );
    }
    changeHandler = (event) => {
    	console.log("Select", event);
    	// let name = event.target.name;
        // let value = event.target.value;
        this.setState({clusters:event});
    }
    sourceoptions(){
        var sources = [
           {"value":"F", label:"Haryana"},
           {"value":"G", label:"Gujarat"}
          // {"value":"T", label:"Gurgoan"},
        ]
        
        return sources;
    }
    transportersoptions(){
        var items = []
        items.push(
            {
                "value":"ALL",
                "label":"All"
            }
        )
        if(this.state.transdropdown.length > 0)
        {
            this.state.transdropdown.map((itm) => {
                items.push(
                    {
                        "value":itm.transporter_code,
                        "label":itm.transporter_name
                    }
                )
                
            })
        }
        return items;
    }
    clusteroptions(){
        var item = []
        if(this.state.clusterdropdown.length > 0)
        {
            this.state.clusterdropdown.map((itm) => {
                if(itm != "" && itm != null)
                {
                    item.push(
                        {
                            "value":itm,
                            "label":itm
                        }
                    )
                }
                
            })
        }
        return item;
    }

    monthoptions(){
        var months = []
        var curenty = moment.parseZone().format("YYYY")
        for(var y = 2020; y<=curenty; y++)
        {
            //console.log("y ", y)
            var strt = moment.parseZone(new Date(y+"-01-01")).add(1, 'years').format("YYYY");
            //console.log("strt ", strt)
            var first = y
            var end = strt
            var fy = first+","+end;
            var labelfy = "Apr-"+first+" - Mar-"+end;
            months.push({
                value:fy,
                label:labelfy
            })
        }
       // console.log("months ", months)
        return months;
    }
    onClickShowFYData = async () =>{
        //console.log("month ", this.state.month)
        var fy = this.state.month.value;
        var split = fy.split(",");
        var fyear = "Apr-"+split[0]+" - Mar-"+split[1]
        this.setState({
            financialyear:fyear,
            finyear: split[0].slice(2)+" - "+split[1].slice(2)
        })
        var parameters = {
            financialyear:fyear
        }
        var params = {
            fy: [split[0],split[1]]
        }
        var olderfy = [];
        //await this.onLoadShowData(parameters)
        var curenty = moment.parseZone().format("YYYY")
        for(var y = 2015; y<curenty; y++)
        {
            //console.log("y ", y)
            var strt = moment.parseZone(new Date(y+"-01-01")).add(1, 'years').format("YYYY");
            //console.log("strt ", strt)
            var first = y
            var end = strt
            var fy = first+","+end;
            var labelfy = "Apr-"+first+" - Mar-"+end;
            olderfy.push(labelfy)
        }
        var pm = {
            olderfy:olderfy
        }
        console.log(pm,parameters)
        this.onLoadOlderFys(pm, parameters)
        await this.onLoadsecond();
        this.onLoadThrid(params);
        this.onLoadFourth(params);
        this.onLoadFifth(params);
        //this.onLoadSixth(params);
        this.onLoadSeventh(params);
        this.onLoadEighth(params);

    }
    onClickShowFilterData(){
        var fy = this.state.month.value;
        var split = fy.split(",");
        var params = {
            fy:[split[0],split[1]]
        }
        this.onLoadFourth(params)
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
    }
    
    onRowSelected(event) {

    }
    closeAlert = async() => {
        await this.setState({
            show: false
		});
		await window.location.reload()
    }


    onClickSourceData()
    {
        this.setState({
            raildispatchthird: []
        })
        var fy = this.state.month.value;
        var split = fy.split(",");
        var params = {
            fy:[split[0],split[1]]
        }
        this.onLoadThrid(params)
    }

    onClickThirdChartTab(tabType)
    {
        if(tabType == "chart")
        {
            this.setState({
                showThirdChart: "show-m",
                showThirdGrid: "show-n"
            })
        }
        else
        {
            this.setState({
                showThirdChart: "show-n",
                showThirdGrid: "show-m"
            })
        }
    }
    render(){
       const thirdChartColumns = [
            {
               "headerName": "Truck No",
               "field": "truck_no",
               "width": 130,
            },
            {
                "headerName": "Load No",
                "field": "load_no",
                "width": 120,
            },
            {
                "headerName": "Vin",
                "field": "vin",
                "width": 130,
            },
            {
                "headerName": "Consignment Code",
                "field": "consignment_code",
                "width": 130,
            },
            {
                "headerName": "Consigner Code",
                "field": "consigner_code",
                "width": 110,
            },
            {
                "headerName": "Consignee Code",
                "field": "consignee_code",
                "width": 120,
            },
            
            {
                "headerName": "Invoice Date",
                "field": "invoice_date",
                "width": 130,
                "valueGetter": function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_date)
                }
            },
            {
                "headerName": "Gate In Time",
                "field": "gate_in_time",
                "width": 130,
                "valueGetter": function(params){
                    if(params.data.gate_in_time != ""&& params.data.gate_in_time != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                    }
                    else{
                        return ""
                    }
                    
                }
            },
            {
                "headerName": "Gate Out Time",
                "field": "gate_out_time",
                "width": 130,
                "valueGetter": function(params){
                    if(params.data.gate_out_time != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
                    }
                    else
                    {return ""}
                }
            },
            {
                "headerName": "Cluster",
                "field": "cluster",
                "width": 120,
            },
            {
                "headerName": "Transporter Code",
                "field": "transporter_code",
                "width": 130,
            },
            {
                "headerName": "Transporter Name",
                "field": "transporter_name",
                "width": 130,
            },
            {
                "headerName": "Delivery Date",
                "field": "",
                "width": 130,
                "valueGetter": function(params)
                {
                    if(params.data.tvp_gate_in != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.tvp_gate_in)
                    }
                    else
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.trip_delivery_date)
                    }
                }
            },
       ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-pie-chart cus-i"></i> <span>Analytics Dashboard ( {this.state.pageTitle} )</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <div className="row">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Financial Year: </label>
                                        <Select 
                                            placeholder={"All"} 
                                          //  isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.month} 
                                            className="border-radius-0" 
                                            onChange={this.changeArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.monthoptions()} required
                                        />
                                    </div>
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.onClickShowFYData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                </div>

                                <div className="row">
                                    {/*1st chart*/}
                                    <div className="col-xl-12 col-lg-12">
                                        {(this.state.raildispatchdata.length > 0)?
                                            ((this.state.raildispatchdata[0].message != "no data")?
                                                (this.state.raildispatchdata.map(item => 
                                            <RailFinancialColumnLineChart 
                                                title={"Rail Dispatches FY " + (this.state.finyear)}
                                                subtitle={"Plan Vs Actual"}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                xtitle={"Monthly Planned Cars"}
                                                ytitle={"Monthly Actual Cars"}
                                                yaxistitle={"Total Invoiced & Delayed Cars"}
                                            />))
                                                :
                                                <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                    <h5>Rail Dispatches FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Rail Dispatches FY  {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                            }
                                       
                                    </div> 
                                    {/*2nd chart*/}
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatchdataavg.length > 0)?
                                         ((this.state.raildispatchdataavg[0].message != "no data")?
                                             
                                            (this.state.raildispatchdataavg.map(item => 
                                            <RailFinancialStackDrilldownChart 
                                                title={"Rail Mode Dispatches Monthly Averages"}
                                                subtitle={"Monthly Avg"}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                yaxistitle={"Cars"}
                                                drilldown={item.drilldown}
                                                dcategories={item.dcategories}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Rail Mode Dispatches Monthly Averages</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Rail Mode Dispatches Monthly Averages</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                          }
                                       
                                    </div>

                                    {/*3rd chart*/}
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        <div className="row">
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Select Source: </label>
                                                <Select 
                                                    name="sourceoption"
                                                    closeMenuOnSelect={true} 
                                                    value={this.state.sourceoption} 
                                                    className="border-radius-0" 
                                                    onChange={this.changeSourceHandler.bind(this)} 
                                                    style={{borderRadius:"0px"}} 
                                                    options={this.sourceoptions()} required
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <button style={{marginTop:"30px"}} onClick={this.onClickSourceData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12">
                                        <button className="btn btn-info" onClick={this.onClickThirdChartTab.bind(this, "chart")}>Chart</button>
                                        {/* <button className="btn btn-success" onClick={this.onClickThirdChartTab.bind(this,"grid")}>Complete Data</button> */}
                                    </div>
                                    
                                    <div className={"col-xl-12 col-lg-12 mt-40p "+this.state.showThirdChart}>
                                        {(this.state.raildispatchthird.length > 0)?
                                            ((this.state.raildispatchthird[0].message != "no data")?
                                             
                                            (this.state.raildispatchthird.map(item => 
                                            <RailFinancialColumnDrillChart 
                                                title={"Total Transit Time FY " + (this.state.finyear)}
                                                subtitle={""}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                yaxistitle={"Transit Time (Days)"}
                                                drilldown={item.drilldown}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Total Transit Time FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                        <h5>Total Transit Time FY {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                        }
                                       
                                    </div>
                                    <div className={"col-xl-12 col-lg-12 mt-40p "+this.state.showThirdGrid}>
                                        <div id="myGrid" style={{ height: "350px",width: "100%"}}  className="ag-theme-balham col-xl-12 col-lg-12">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={thirdChartColumns}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.thirdChartGridData}
                                                enableCharts={false}
                                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                components={this.state.components}
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                // onRowSelected={this.onRowSelected.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                // onCellEditingStopped={this.onCellBrmDamageData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    {/*4th chart*/}
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        <div className="row">
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Select Source: </label>
                                                <Select 
                                                    name="sourceoption"
                                                    closeMenuOnSelect={true} 
                                                    value={this.state.sourceoption2} 
                                                    className="border-radius-0" 
                                                    onChange={(e)=>{this.setState({sourceoption2: e})}} 
                                                    style={{borderRadius:"0px"}} 
                                                    options={this.sourceoptions()} required
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Select Cluster: </label>
                                                <Select 
                                                    name="clusters"
                                                    closeMenuOnSelect={true} 
                                                    value={this.state.clusters} 
                                                    className="border-radius-0" 
                                                    onChange={this.changeHandler.bind(this)} 
                                                    style={{borderRadius:"0px"}} 
                                                    options={this.clusteroptions()} required
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Select Transporters: </label>
                                                <Select 
                                                    placeholder={"All"} 
                                                    isMulti={true}
                                                    name="transporters"
                                                    closeMenuOnSelect={true} 
                                                    value={this.state.transporters} 
                                                    className="border-radius-0" 
                                                    onChange={this.changeTransporterHandler.bind(this)} 
                                                    style={{borderRadius:"0px"}} 
                                                    options={this.transportersoptions()} required
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <button style={{marginTop:"30px"}} onClick={this.onClickShowFilterData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatchfourth.length > 0)?
                                            ((this.state.raildispatchfourth[0].message != "no data")?
                                             
                                            (this.state.raildispatchfourth.map(item => 
                                            <RailFinancialBreakColumnDrillChart 
                                                title={"Breakup of transit times FY " + (this.state.finyear)}
                                                subtitle={"Month on month"}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                yaxistitle={"Avg Transit Time (Days)"}
                                                drilldown={item.drilldown}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Breakup of transit times FY {this.state.finyear}</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Breakup of transit times FY {this.state.finyear}</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                       
                                    </div>

                                    {/*5th chart*/}
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatchfifth.length > 0)?
                                            ((this.state.raildispatchfifth[0].message != "no data")?
                                             
                                            (this.state.raildispatchfifth.map(item => 
                                            <RailFinancialColumnGroupLineChart 
                                                title={"Transit Time Analysis FY "+(this.state.finyear)}
                                                subtitle={"Increasing to Decreasing trend"}
                                                context={this}
                                                seriesinvoice={item.seriesinvoice}
                                                seriesdelay={item.seriesdelay}
                                                seriesdelaypercent={item.seriesdelaypercent}
                                                series={item.series}
                                                categories={item.categories}
                                                drilldown={item.drilldown}
                                                yaxistitle={""}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Transit Time Analysis FY</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Transit Time Analysis FY</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                          }
                                       
                                    </div>

                                    {/*6th chart*/}
                                    
                                    {/* <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatchsixth.length > 0)?
                                            ((this.state.raildispatchsixth[0].message != "no data")?
                                             
                                            (this.state.raildispatchsixth.map(item => 
                                            <RailFinancialStackChart 
                                                title={"Region wise LSP wise delays"}
                                                subtitle={""}
                                                context={this}
                                                series={item.seriesdelaypercent}
                                                categories={item.categories}
                                                yaxistitle={"Delay Percent"}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Region wise LSP wise delay</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Region wise LSP wise delay</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                    }
                                       
                                    </div> */}
                                    
                                   {/*7th chart*/}

                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatcheigth.length > 0)?
                                             ((this.state.raildispatcheigth[0].message != "no data")?
                                             
                                             (this.state.raildispatcheigth.map(item => 
                                            <RailFinancialDelayMonthCompenseChart 
                                                title={"Monthly Dealer Compensation (Rs) FY "+ (this.state.finyear)}
                                                subtitle={""}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                drilldowncols={item.drilldowncols}
                                                drilldownlines={item.drilldownlines}
                                                yaxistitle={""}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Monthly  Compensation(Rs)</h5>No Data
                                                </div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Monthly  Compensation(Rs)</h5>
                                            <span>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></span>
                                            </div>
                                          }
                                       
                                    </div>

                                    {/*8th chart*/}
                                    <div className="col-xl-12 col-lg-12 mt-40p">
                                        {(this.state.raildispatchseventh.length > 0)?
                                            ((this.state.raildispatchseventh[0].message != "no data")?
                                             
                                            (this.state.raildispatchseventh.map(item => 
                                            <RailFinancialDelayCompenseChart 
                                                title={"Dealer Compensation FY "+(this.state.finyear)}
                                                subtitle={""}
                                                context={this}
                                                series={item.series}
                                                categories={item.categories}
                                                yaxistitle={""}
                                            />))
                                            :
                                            <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                                <h5>Dealer Compensation(Rs)</h5>No Data</div>
                                            )
                                            
                                        :
                                        <div className="col-xl-12 col-lg-12" style={{minHeight:"350px",textAlign:"center"}}>
                                            <h5>Dealer Compensation(Rs)</h5>
                                        <span style={{position:"absolute",top:"30%",left:"43%"}}>Loading</span></div>
                                          }
                                       
                                    </div>
                                    <div className="form-group pull-right col-xl-9 col-lg-9"></div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 


function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 