import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY, getHyphenDDMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
//import CustomDateComponent from "../common/customdatepicker";
import $ from 'jquery';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class CoilsDispatch extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Coil Dispatch (Kandla)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowData1: [],      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
			tab1:"btn-danger",
			tab2:"btn-defaultc",
			shtab1:"show-m",
			shtab2:"show-n"
		}
		
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.selectContainersOptionsItems = this.selectContainersOptionsItems.bind(this);
		// this.onLoadPageKandlaData = this.onLoadPageKandlaData.bind(this);
	}
	componentDidMount(){
		this.setState({
			loadshow:'show-m'
		});
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		this.onLoadPageData();
		this.onLoadPageKandlaData();
	};
	onLoadPageData()
	{
		redirectURL.post("/imports/dispatchcoilsimports")
        .then((response) =>{
			var recordarr = response.data;
			// console.log(recordarr);
            var records=[];
            if(recordarr.length > 0)
            {
                recordarr.map((item) => {
                    // console.log("item.coils ", item.coils)
                    if(item.coils.length > 0)
                    {
                        item.coils.map((coil) => {
							// console.log(coil.status,coil.port_of_discharge);
							if(coil.status == 2 && (coil.port_of_discharge == "KANDLA" || coil.port_of_discharge == "DEENDAYAL") )
							{
								var data = {
									nyk_ship_name: item.nyk_ship_name,
									nyk_voyage_no: item.nyk_voyage_no,
									nyk_bl_no: item.nyk_bl_no,
									nyk_bl_date: item.nyk_bl_date,
									nyk_no_coils: item.nyk_no_coils,
									nyk_gross_weight: item.nyk_gross_weight,
									nyk_bis_certificate:item.nyk_bis_certificate,
									nyk_bl_copy: item.nyk_bl_copy,
									nyk_boe_amount: item.nyk_boe_amount,
									nyk_boe_date: item.nyk_boe_date,
									nyk_boe_no: item.nyk_boe_no,
									nyk_cepa_fta: item.nyk_cepa_fta,
									nyk_coc_date: item.nyk_coc_date,
									nyk_freight_bill: item.nyk_freight_bill,
									nyk_mill_mtc: item.nyk_mill_mtc,
									nyk_original_invoice: item.nyk_original_invoice,
									nyk_packing_list: item.nyk_packing_list,
									nyk_sims_register: item.nyk_sims_register,
									coilrowid:coil._id, 
									coil_no:coil.coil_no,
									cargo_description:coil.cargo_description,
									case_no:coil.case_no,
									coil_desc:coil.coil_desc,
									coil_gross_weight:coil.coil_gross_weight,
									coil_net_weight:coil.coil_net_weight,
									coil_size:coil.coil_size,
									coil_size1:coil.coil_size1,
									imid_item_code:coil.item_no,
									impi_no:coil.impi_no,
									invoice_date:coil.invoice_date,
									port_of_discharge:coil.port_of_discharge,
									priotity:coil.priotity,
									process_code:coil.process_code,
									ship_name:coil.ship_name,
									ship_no:coil.ship_no,
									status:coil.status,
									vendor_code:coil.vendor_code,
									voyage_no:coil.voyage_no,
									ata_date:coil.ata_date,
									atb_date:coil.atb_date,
									coc_date:coil.coc_date,
									eta_date:coil.eta_date,
									modified_date:coil.modified_date,
									departure_to_adani_date:coil.departure_to_adani_date,
									transit_mode:coil.transit_mode,
									line_voyage_no:coil.line_voyage_no,
									transporter_name:coil.transporter_name
								}
								records.push(data);
							}
                            
                        })
                        
                    }
                });
                try{
                    this.setState({
                        rowData:records,
                        loadshow:'show-n'
                    })
                }
                catch(e){
                    console.log("Response Error ", e)
                }
            }
            else
            {
                try{
                    this.setState({
                        rowData:[],
                        loadshow:'show-n'
                    })
                }
                catch(e){
                    console.log("Response Error ", e)
                }
            }
            console.log("records ", records)
            
        })
        .catch(e => {
            console.log("Error ",e);
        })
	}
	onLoadPageKandlaData()
	{
		redirectURL.post("/imports/dispatchcoilskandlaimports")
        .then((response) =>{
			var recordarr = response.data;
			// console.log("recordarr ",recordarr);
            var records=[];
            if(recordarr.length > 0)
            {
                recordarr.map((item) => {
                    // console.log("item.coils ", item)
                    if(item.nykvoyages.length > 0)
                    {
                        item.nykvoyages.map((nykvoyages) => {
							// console.log("Kandla ", nykvoyages);
							if(item.status == 3 && item.port_of_discharge == "KANDLA" || item.port_of_discharge == "DEENDAYAL" )
							{
								var data = {
									nyk_ship_name: nykvoyages.nyk_ship_name,
									nyk_voyage_no: nykvoyages.nyk_voyage_no,
									nyk_bl_no: nykvoyages.nyk_bl_no,
									nyk_bl_date: nykvoyages.nyk_bl_date,
									nyk_no_coils: nykvoyages.nyk_no_coils,
									nyk_gross_weight: nykvoyages.nyk_gross_weight,
									nyk_bis_certificate:nykvoyages.nyk_bis_certificate,
									nyk_bl_copy: nykvoyages.nyk_bl_copy,
									nyk_boe_amount: nykvoyages.nyk_boe_amount,
									nyk_boe_date: nykvoyages.nyk_boe_date,
									nyk_boe_no: nykvoyages.nyk_boe_no,
									nyk_cepa_fta: nykvoyages.nyk_cepa_fta,
									nyk_coc_date: nykvoyages.nyk_coc_date,
									nyk_freight_bill: nykvoyages.nyk_freight_bill,
									nyk_mill_mtc: nykvoyages.nyk_mill_mtc,
									nyk_original_invoice: nykvoyages.nyk_original_invoice,
									nyk_packing_list: nykvoyages.nyk_packing_list,
									nyk_sims_register: nykvoyages.nyk_sims_register,
									coilrowid:item._id, 
									coil_no:item.coil_no,
									cargo_description:item.cargo_description,
									case_no:item.case_no,
									coil_desc:item.coil_desc,
									coil_gross_weight:item.coil_gross_weight,
									coil_net_weight:item.coil_net_weight,
									coil_size:item.coil_size,
									coil_size1:item.coil_size1,
									imid_item_code:item.item_no,
									impi_no:item.impi_no,
									invoice_date:item.invoice_date,
									port_of_discharge:item.port_of_discharge,
									priotity:item.priotity,
									process_code:item.process_code,
									ship_name:item.ship_name,
									ship_no:item.ship_no,
									status:item.status,
									vendor_code:item.vendor_code,
									voyage_no:item.voyage_no,
									ata_date:item.ata_date,
									atb_date:item.atb_date,
									coc_date:item.coc_date,
									eta_date:item.eta_date,
									modified_date:item.modified_date,
									departure_to_adani_date:item.departure_to_adani_date,
									transit_mode:item.transit_mode,
									line_voyage_no:item.line_voyage_no,
									transporter_name:item.transporter_name,
									departure_to_kandla_wh_date:item.departure_to_kandla_wh_date,
                                    transit_mode_kandla_Wh:item.transit_mode_kandla_Wh,
                                    line_voyage_no_kandla_wh:item.line_voyage_no,
                                    transporter_name_kandla_wh:item.transporter_name
								}
								records.push(data);
							}
                            
                        })
                        
                    }
                });
			
				this.setState({
					rowData1:recordarr,
					loadshow:'show-n'
				})
                
            }
            else
            {
                try{
                    this.setState({
                        rowData1:[],
                        loadshow:'show-n'
                    })
                }
                catch(e){
                    console.log("Response Error ", e)
                }
            }
            console.log("records ", records)
            
        })
        .catch(e => {
            console.log("Error ",e);
        })
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	
	
	onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
   
	selectOptionsItems() {
        let items = [];     
        //console.log("transporterslist ",this.state.transporterslist);
        this.state.transporterslist.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push({value:item.transporter_code,label:item.transporter_name}); 
    		}
        	
        });
        return items;
	}

	handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeArrayHandler = async searates => {
    	
    	await this.setState(
	      { searates },
	      () => console.log(`Option selected:`, this.state.searates)
	    );
    	
		//await console.log("Selecton ",this.state.searates.value)
		
    }

    changeContainerArrayHandler = container_no =>{
        this.setState(
	      { container_no },
	      () => console.log(`Container Option selected:`, this.state.container_no)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    changeTransporterHandler = transporter_code => {
        this.setState(
            { transporter_code },
            () => console.log(`Container Option selected:`, this.state.transporter_code)
          );
    }

    selectContainersOptionsItems(){
        let items = [];     
        //console.log(this.state.trucks);
        if(this.state.containerslist.length > 0)
        {
            this.state.containerslist.forEach(item => {
                if(item != '' && item != null)
                {
                    items.push({value:item,label:item}); 
                }
                
            });
        }
        
        return items;
    }
    
   

    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
            console.log(rowNode.data);
            if(rowNode.data.departure_to_adani_date != undefined || 
                rowNode.data.line_voyage_no != undefined || 
                rowNode.data.transporter_name != undefined)
            {
                var data = rowNode.data;
                itemsToUpdate.push(data);
            }
           
			
        });
       // console.log("itemsToUpdate ",itemsToUpdate);
		
	   var urlpath = '/imports/storeDispatchCoilsImportsData';
	   var reqparams = {
		   updateData:itemsToUpdate
	   } 
		redirectURL.post(urlpath,reqparams)
		.then((response) => {
			console.log("Response ", response)
			//if(response.data.status == 'Success')
			//{
				this.setState({
					loadshow:'show-n',
					show:true,
					basicTitle:"Successfully updated",
					basicType:"success"
                })
				this.onLoadPageData();
                //window.location.reload();
			//}
		})
		.catch(function (error) {
			console.log(error);
		});
		
	}

	

    formAssignKandlaHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		var nvald = [];
		var nempty = [];
		this.gridApi1.forEachNodeAfterFilterAndSort(function(rowNode, index) {
            // console.log(rowNode.data);
            if(rowNode.data.departure_to_adani_date != undefined || 
                rowNode.data.line_voyage_no != undefined || 
                rowNode.data.transporter_name != undefined)
            {
				var data = rowNode.data;
				// console.log("departure_to_kandla_wh_date ", data)
				try{
					var s = data.departure_to_adani_date;
					if(s != "" && s != undefined)
					{
						var dm = s.split("/");
						if(dm[0].length == 4)
						{
							var datedep = dm[0]+"-"+dm[1]+"-"+dm[2]; 
							}
						else
						{
							var datedep = dm[2]+"-"+dm[1]+"-"+dm[0]; 
						}
					}
					else
					{
						var datedep = "";
					}
					var s1 = getHyphenDDMMYYYY(data.departure_to_kandla_wh_date);
					if(s1 != "" && s1 != undefined)
					{
						// console.log("s1 ", s1)
						var dm1 = s1.split("-");
						if(dm1[0].length == 4)
						{
							var datedep1 = dm1[0]+"-"+dm1[1]+"-"+dm1[2]; 
							// var departurekandladate = moment.parse(new Date(datedep1+" 00:00:00")).utcOffset("+05:30")._d
						}
						else
						{
							var datedep1 = dm1[2]+"-"+dm1[1]+"-"+dm1[0]; 
							// var departurekandladate = moment.parse(new Date(datedep1+" 00:00:00")).utcOffset("+05:30")._d
						}
					}
					else
					{
						var datedep1 = "";
					}
					// console.log("datedep ", datedep)
					// console.log("datedep1s ", datedep1)
					if(datedep != "" && datedep1 != "")
					{
						var t = moment.parseZone(new Date(datedep+" 00:00:00")).format("x")
						var t1 = moment.parseZone(new Date(datedep1+" 00:00:00")).format("x")
						if(t>= t1)
						{
							itemsToUpdate.push(data);
						}
						else
						{
							nvald.push(1)
						}
					}
					else
					{
						nempty.push(1)
					}
				}
				catch(e){

				}
				
            }
           
			
		});
		if(nvald.length > 0)
		{
			this.setState({
				loadshow:"show-n",
				show:true,
				basicTitle:"Departure to Adani greater than or equals to Inside Kandla WH On",
				basicType:"danger"
			})
		}
		else if(nempty.length > 0)
		{
			this.setState({
				loadshow:"show-n",
				show:true,
				basicTitle:"Departure to Adani / Inside Kandla WH On should not be empty",
				basicType:"danger"
			})
		}
		else
		{
			console.log("itemsToUpdate ",itemsToUpdate);
		
			var urlpath = '/imports/storeDispatchKandlaCoilsImportsData';
			var reqparams = {
				updateData:itemsToUpdate
			} 
			 redirectURL.post(urlpath,reqparams)
			 .then((response) => {
			 	console.log("Response ", response)
			 	//if(response.data.status == 'Success')
			 	//{
			 		this.setState({
			 			loadshow:'show-n',
						show:true, 
						basicTitle:"Successfully updated",
						basicType:"success"
						 
					 })
					 this.onLoadPageKandlaData();
			         //window.location.reload();
			 	//}
			 })
			 .catch(function (error) {
			 	console.log(error);
			 });
		}
       
		
	}
	
	applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
	showTabContent(tab) {
		if(tab == 1)
		{
			this.setState({
				tab1:"btn-danger",
				tab2:"btn-defaultc",
				shtab1:"show-m",
				shtab2:"show-n"
			})
			this.onLoadPageData();
		}
		if(tab == 2)
		{
			this.setState({
				tab1:"btn-defaultc",
				tab2:"btn-danger",
				shtab1:"show-n",
				shtab2:"show-m"
			})
			this.onLoadPageKandlaData();
		}
	}
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
			
				{
					headerName: "Bl NO. ",
					field: "nyk_bl_no",
					width: 200,
					filter: true,
					resizable: true,
                    editable:false
					//cellEditor: "datePicker"
				},
				{
					headerName: "Bl Date ",
					field: "nyk_bl_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
					editable:false,
					comparator : dateComparator1,
                    valueGetter:function(params){
                        var splitdt = params.data.nyk_bl_date.split("/");
                        //console.log("splitdt ",splitdt);
                        if(splitdt[2] == "20")
                        {
                            var date = "2020-"+splitdt[1]+"-"+splitdt[0];
                        }
                        else
                        {
                            var date = params.data.nyk_bl_date
                        }
                        //console.log("params.data.nyk_bl_date ", date)
                        return getHyphenDDMMMYYYY(new Date(date));
					},
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				
				{
					headerName: "Ship Name",
					field: "nyk_ship_name",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
				},
				
				{
					headerName: "Coil No",
					field: "coil_no",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Item Code",
					field: "imid_item_code",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Coil Net Weight",
					field: "coil_net_weight",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Coil Gross Weight",
					field: "coil_gross_weight",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Case No",
					field: "case_no",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Coil Size",
					field: "coil_size",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
                },
                
				{
					headerName: "Coil Vendor",
					field: "vendor_code",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:false,
                    //cellEditor: "datePicker"
				},
				{
					headerName: "Destination",
					field: "destination",
					width: 140,
					resizable: true,
					editable:true,
					cellEditor: "agSelectCellEditor",
				    cellEditorParams: {
					  values: ["ICD Patli", "Kandla WH"],
					  cellRenderer: function(params) {
						  	return params.value
						}
					}
				},
				{
					headerName: "Departure to ICD / WH",
					field: "departure_to_adani_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:true,
                    cellEditor: "datePicker"
				},
				// {
				// 	headerName: "Transit Mode",
				// 	field: "transit_mode",
				// 	width: 200,
				// 	//filter: "agDateColumnFilter",
				// 	resizable: true,
                //     editable:true
                //     //cellEditor: "datePicker"
				// },
				{
					headerName: "Truck No",
					field: "line_voyage_no",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:true,
                    //cellEditor: "datePicker"
				},
				
				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
                    editable:true,
					cellEditor: "agSelectCellEditor",
				    cellEditorParams: {
					  values: ["KMT", "RRC", "ATS", "KRL", "TMC"],
					  cellRenderer: function(params) {
						  	return params.value
						}
					}
				},
				
			];
		const columnwithDefs1 = [
		
			{
				headerName: "Bl NO. ",
				field: "bl_no",
				width: 200,
				filter: true,
				resizable: true,
				editable:false
				//cellEditor: "datePicker"
			},
			{
				headerName: "Bl Date ",
				field: "bl_date",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				comparator : dateComparator1,
				valueGetter:function(params){
					var splitdt = params.data.bl_date.split("/");
					//console.log("splitdt ",splitdt);
					if(splitdt[2] == "20")
					{
						var date = "2020-"+splitdt[1]+"-"+splitdt[0];
					}
					else
					{
						var date = params.data.bl_date
					}
					//console.log("params.data.nyk_bl_date ", date)
					return getHyphenDDMMMYYYY(new Date(date));
				},
				
			},
			
			{
				headerName: "Ship Name",
				field: "ship_name",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Coil No",
				field: "coil_no",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Item Code",
				field: "imid_item_code",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Coil Net Weight",
				field: "coil_net_weight",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Coil Gross Weight",
				field: "coil_gross_weight",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Case No",
				field: "case_no",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Coil Size",
				field: "coil_size",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Coil Vendor",
				field: "vendor_code",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:false,
				//cellEditor: "datePicker"
			},
			{
				headerName: "Inside Kandla WH on",
				field: "departure_to_kandla_wh_date",
				width: 140,
				resizable: true,
				editable:false,
				comparator : dateComparator1,
				valueGetter:function(params){
					try{
						var splitdt = params.data.departure_to_kandla_wh_date.split("/");
						//console.log("splitdt ",splitdt);
						if(splitdt[2] == "20")
						{
							var date = "2020-"+splitdt[1]+"-"+splitdt[0];
						}
						else
						{
							var date = params.data.departure_to_kandla_wh_date
						}
						//console.log("params.data.nyk_bl_date ", date)
						return getHyphenDDMMMYYYY(new Date(date));
					}
					catch(e)
					{
						return "";
					}
					
				},
			},
			{
				headerName: "Departure to Adani",
				field: "departure_to_adani_date",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:true,
				cellEditor: "datePicker",
				// valueGetter:function(params){
				// 	try{
				// 		var splitdt = params.data.departure_to_adani_date.split("/");
				// 		//console.log("splitdt ",splitdt);
				// 		if(splitdt[2] == "20")
				// 		{
				// 			var date = "2020-"+splitdt[1]+"-"+splitdt[0];
				// 		}
				// 		else
				// 		{
				// 			var date = params.data.departure_to_adani_date
				// 		}
				// 		//console.log("params.data.nyk_bl_date ", date)
				// 		return getHyphenDDMMMYYYY(new Date(date));
				// 	}
				// 	catch(e)
				// 	{
				// 		return "";
				// 	}
					
				// },
			},
			// {
			// 	headerName: "Transit Mode",
			// 	field: "transit_mode",
			// 	width: 200,
			// 	//filter: "agDateColumnFilter",
			// 	resizable: true,
			// 	editable:true,
			// 	// valueSetter:"Road"
			// 	//cellEditor: "datePicker"
			// },
			{
				headerName: "Truck No",
				field: "line_voyage_no",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:true,
				//cellEditor: "datePicker"
			},
			
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 200,
				//filter: "agDateColumnFilter",
				resizable: true,
				editable:true,
				cellEditor: "agSelectCellEditor",
				cellEditorParams: {
				  values: ["KMT", "RRC", "ATS", "KRL", "TMC"],
				  cellRenderer: function(params) {
						  return params.value
					}
				}
			},
			
		];
		const dStyles={
			width:this.props.width,
            height:'500px',	
            marginLeft:'0px !important'

	    }
        
       
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ship cus-i"></i> <span>  {this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                   
                                </div>
                                <div className="col-xl-12 col-lg-12">
									<ul className="d-tabs">
										<li>
											<a className={"btn "+this.state.tab1} 
											href="javascript:;" 
											onClick={this.showTabContent.bind(this,1)}>Coil Dispatches (Kandla)</a>
										</li>
										<li>
											<a className={"btn "+this.state.tab2} 
											href="javascript:;" 
											onClick={this.showTabContent.bind(this,2)}>Coil Dispatch (Kandla WH to ICD Patli)</a>
										</li>
									</ul>
								</div>
                                <div className={"row col-xl-12 col-lg-12 "+(this.state.shtab1)}>
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													// enableRangeSelection={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													components={this.state.components}
													
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                           <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button>
										</div>
										
                                </div>

								<div className={"row col-xl-12 col-lg-12 "+(this.state.shtab2)}>
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid1" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs1}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData1}
													enableCharts={false}
													// enableRangeSelection={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady1}
													onGridState={this.onGridState1}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													components={this.state.components}
													
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                           <button type="button" onClick={this.formAssignKandlaHandler.bind(this)} className="btn btn-primary float-right">Update Now</button>
										</div>
										
                                </div>

				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  this.eInput.setAttribute("class", "dateinput");
      window.$(this.eInput).datepicker({ dateFormat: 'dd/mm/yy' });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }

  function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}