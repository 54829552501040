
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const DeleteRakeItem = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.deleteRakeData(props.data);
    };
    var params = props.data;
   // console.log(params);
    if(params.status_text == "Completed")
    {
        var disabled = 1;
    }
    else
    {
        var disabled = 0;
    }
    return (
        <div className="map-icon-component">
            {(disabled == 0)?
            <button onClick={handleRouteClick} className="custom-btn label label-success"><i className="icofont icofont-trash f15"></i></button>    
            :
            <button className="custom-btn"><i className="icofont icofont-trash f15" style={{color:"#d0d0d0"}}></i></button>}
            
        </div>
    );
};

export default DeleteRakeItem;
