
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UpdateButton = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.updateLmStates(props.data);
    };

    return (
        <div className="map-icon-component">
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className="btn btn-success" title="Update" style={{padding:"0px 0px 9px 0px",width: "97px"}}>Update </button>
       
        </div>
    );
};

export default UpdateButton;
