import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentDashboardCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            active_trucks:0
    	}
    
    }
    componentWillReceiveProps(){
        this.setState({
            active_consignments:this.props.counters.active_consignments,
    		overspeeding:this.props.counters.overspeeding,
            night_driving:this.props.counters.night_driving,
            transit_delay:this.props.counters.transit_delay,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:this.props.counters.gps_data_available,
            active_trucks:this.props.counters.active_trucks
        });
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d">
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 greenfont"></i><br /> Active Consignments</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.active_consignments != '')?this.state.active_consignments:0}/></span></h4>
                                    
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Active Trucks</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>
                                    
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f50"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div>
                               

                                
                            </div>
                            <div className="row mt-60p">
                               
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 redfont"></i><br /> GPS Data NA</span>
                                    <h4 className="txt-secondary f50"><span className="counter"><CountUp end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/></span></h4>
                                    
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="fa fa-tachometer f22 redfont"></i><br /> Overspeeding Trucks</span>
                                    <h4 className="txt-success f50"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                   
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-full-night f24 bluefont"></i><br /> Night Driving Trucks</span>
                                    <h4 className="txt-primary f50"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>

                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






