import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class SlideBarComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return (
                <div className={"slide-r "+(this.props.sliderTranslate)} >
				 	
                    {this.props.displayData}
                </div>
        );
    }
}