import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';


import 'react-vertical-timeline-component/style.min.css';

import Breadcrumb from '../common/breadcrumb.component';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class Changepassword extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Change Password",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentPassword: null,
			newPassword: null,
			confirmPassword: null,
			errorEmptyValue: false,
			errorConfirmPassword: false,
			messageUpdateSuccessful: false,
			messageFromServer: "",
			errorUpdateFailed: false,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			allRowData:0,
			highseascount:0,
			inportcount:0,
			

			inicdcount:0,
			transitmsilcount:0,
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',


      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			confirmPassword:"",
			currentPassword:"",
			newPassword:""
				
			
		}
		this.cipher = this.cipher.bind(this);
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		this.logPageView(); // Log page view to GA
	};
	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	FormHandler(e){
		console.log("In ChangePassword, formhandler, e = ", e);
		e.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedChangePassword,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({errorConfirmPassword: false});
		this.setState({errorEmptyValue: false});
		this.setState({messageUpdateSuccessful: false});
		this.setState({errorUpdateFailed: false, messageFromServer: ""})
		let currentPassword = this.state.currentPassword;
		let newPassword = this.state.newPassword;
		let confirmPassword = this.state.confirmPassword;
		//console.log("In ChangePassword, formhandler, - currentPassword, newPassword, onfirmPassword = ", currentPassword, newPassword, confirmPassword )

		if(!currentPassword || !newPassword || !confirmPassword) {
			this.setState({errorEmptyValue: true});
			return false;
		}
		if (newPassword !== confirmPassword) {
			this.setState({errorConfirmPassword: true});
			return false;
		}
		var verifypwd = CheckPassword(newPassword)
		//console.log("verifypwd ",verifypwd)
		if(verifypwd == true)
		{
			var em = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var email = em(localStorage.getItem('email'))

			var chpwd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var cpwd = chpwd(currentPassword)

			var npwd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var newpwd = npwd(newPassword)

			var cnpwd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var cnfpwd = cnpwd(confirmPassword)


			const layoutdata={
				//token:localStorage.getItem('token'),
				e:email,
				userId:localStorage.getItem('userid'),
				cp:cpwd,
				n: newpwd,
				c: cnfpwd
			}
			redirectURL.post('/masters/changepassword', layoutdata,{
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			  .then((response) => {
				  // console.log(response);
				var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Records = ", records);
				if (records.status == "success") {
					this.setState({
						messageUpdateSuccessful: true,
						show:true,
						basicType:"success",
						basicTitle:"Password update successfully",
						confirmPassword:"",
						newPassword:"",
						currentPassword:""

					})
				}

				else if (records.status == "error") {
					let message = (records.message) ? records.message : "Password updated failed. Please try again";
					this.setState({errorUpdateFailed: true, messageFromServer: message})
				}
				else if(records.status == "invalid")
				{
					let message = records.message;
					this.setState({errorUpdateFailed: true, messageFromServer: message})
				}
				e.preventDefault();
			  })
			  .catch(function (error) {
				  console.log(error);
			  });
			  
	
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
				basicType:"danger"
			})
		}
		

	}

	changeHandler(event){
		let name = event.target.name;
		let value = event.target.value;
		//console.log("name and value = ", name, value);
		this.setState({[name]:value});
		// Reset messages
		this.setState({errorConfirmPassword: false});
		this.setState({errorEmptyValue: false});
		this.setState({messageUpdateSuccessful: false});
		this.setState({errorUpdateFailed: false, messageFromServer: ""})
	}
	closeAlert(){
		this.setState({
			show:false,
		})

		if (this.state.basicTitle = "Password update successfully") {
			localStorage.clear();
			// Optionally, you can force a re-render to reflect the changes
			window.location.reload();
		}
	}
	render(){
		return(
			<div className="container-fluid">
				<Breadcrumb parent="Profile" title="Change Password" />
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert.bind(this)}
	        >
	        </SweetAlert>
	            {this.state.alert}

            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ui-password cus-i"></i> <span>{this.state.pageTitle} </span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
								 	<form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.FormHandler.bind(this)}>
										<div className="col-xl-4 col-lg-6 col-sm-12">
											{(this.state.messageUpdateSuccessful) ? 
												<div className="txt-success f15"  style={{padding:'0px 0px 20px 0px'}}>Password updated successfully.</div>
												: ""
											}
											{(this.state.errorUpdateFailed) ? 
												<div className="txt-danger f15"  style={{padding:'0px 0px 20px 0px'}}> {this.state.messageFromServer}</div>
												: ""
											}
											{(this.state.errorConfirmPassword) ? 
											    <div className="txt-danger f15"  style={{padding:'0px 0px 20px 0px'}}>New and Confirm Password should be same.</div>
												: ""
											}
											{(this.state.errorEmptyValue) ? 
											    <div className="txt-danger f15"  style={{padding:'0px 0px 20px 0px'}}>All fields are required.</div>
												: ""
											}
										</div>
										<div className="col-xl-4 col-lg-6 col-sm-12" style={{paddingRight:'0px'}}>
											<label>Current Password *:</label>
											<input 
											type="password" 
											placeholder="Current Password" 
											name="currentPassword" 
											value={this.state.currentPassword}
											    className="form-control rselect" 
											    onChange={this.changeHandler.bind(this)}  required />
										</div>

										<div className="col-xl-4 col-lg-6 col-sm-12" style={{paddingRight:'0px'}}>
											<label>New Password *:</label>
											<input type="password" 
											placeholder="New Password"
											 name="newPassword" 
											 value={this.state.newPassword}
											    className="form-control rselect" 
												onChange={this.changeHandler.bind(this)}  required />
										</div>

										<div className="col-xl-4 col-lg-6 col-sm-12" style={{paddingRight:'0px'}}>
											<label>Confirm Password *:</label>
											<input type="password" 
											placeholder="Confirm Password" 
											name="confirmPassword" 
											value={this.state.confirmPassword}
											    className="form-control rselect" 
												onChange={this.changeHandler.bind(this)}  required />
											
											
										</div>

										<div className="col-xl-12 col-lg-12 col-sm-12" style={{padding:"0"}}>
											<button name="changePassword" id="go"style={{height:"38px",margin:"10px 15px"}} 
											    className="btn btn-primary" type="submit">Change Password</button>
										</div>
									</form>

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
            </div>
              	
		);
    }
}
function CheckPassword(inputtxt) 
{ 
	var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if(inputtxt.match(decimal)) 
	{ 
		return true;
	}
	else
	{ 
		return false;
	}
} 
