import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class PieComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.y:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.props.columnaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {
			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.props.columnyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        
        return ( 
        		
				<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} />
		
			);
		}
}












