import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ImportsConsignmentCounterSetComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            records:[],
            boe_filing_delay_total:0,
            boe_filing_delay_lessthree:0,
            boe_filing_delay_greaterthree:0,
    		icd_detention_delay_first:0,
            icd_detention_delay_second:'',
            icd_detention_delay_third:0,
            userroles:"",
            arrivedindiancnt:0,
            new_screen:0
    	}
    
    }
    componentWillReceiveProps(newprops){
        //console.log("newprops ", newprops)
       this.setState({
        boe_filing_delay_total:newprops.boe_filing_delay_total,
        boe_filing_delay_lessthree:newprops.boe_filing_delay_lessthree,
        boe_filing_delay_greaterthree:newprops.boe_filing_delay_greaterthree,
        icd_detention_delay_first:newprops.icd_detention_delay_first,
        icd_detention_delay_second:newprops.icd_detention_delay_second,
        icd_detention_delay_third:newprops.icd_detention_delay_third,
        userroles:newprops.userroles,
        arrivedindiancnt:newprops.arrivedindiancnt,
        new_screen:newprops.new_screen,
       })
      
    }
    onClickBOECounter(data){
        this.props.context.onClickBOECounterShowData(data)
    }
    onClickICDCounter(data){
        this.props.context.onClickICDCounterShowData(data)
    }
    render(){

        var clos =(this.state.userroles == "yes" || this.state.new_screen == 1)?"col-xl-12 col-lg-12 col-sm-12 beffect":"col-xl-4 col-lg-4 col-sm-4 beffect";
//var inportDelay=0;

        return (
            <div className="row">
                {(this.state.userroles == "yes" || this.state.new_screen == 1)?"":
                <div className="col-xl-4 col-lg-4 col-sm-4 beffect">
                    <div className="card">
                        <div className="card-header" style={{padding:'10px'}}>
	                        <h5>BOE Filing Delay</h5>
                        </div>         
                        <div className="card-body"  id="consignment-overdue-component">
                            <div className="u-steps row">
                                <div className="u-step col-sm-4 cpointer" onClick={this.onClickBOECounter.bind(this,0)}>
                                    <div className="u-step-desc">
                                        <span className="txt-black">Total</span>
                                        <h4 className="txt-info fnt2">
                                            <span className="counter">
                                                <CountUp end={(this.state.boe_filing_delay_total)?this.state.boe_filing_delay_total:0}/>
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="u-step col-sm-4  bgColorDangerLight cpointer" onClick={this.onClickBOECounter.bind(this,1)}>
                                    <div className="u-step-desc ">
                                        <span className="txt-black">&lt;3 days</span>
                                        <h4 className="colorWhite fnt2">
                                            <span className="counter">
                                                <CountUp end={(this.state.boe_filing_delay_lessthree)?this.state.boe_filing_delay_lessthree:0}/>
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="u-step col-sm-4 bgColorDangerMedium cpointer" onClick={this.onClickBOECounter.bind(this,2)}>
                                    <div className="u-step-desc ">
                                        <span className="txt-black">&gt;3 days</span>
                                        <h4 className="colorWhite fnt2">
                                            <span className="counter">
                                                <CountUp end={(this.state.boe_filing_delay_greaterthree)?this.state.boe_filing_delay_greaterthree:0}/>
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                            </div>

                        
                        </div>
                    </div>
                </div>
            
                }
                 
                 {(this.state.new_screen != 1)?
                 <div className="col-xl-4 col-lg-4 col-sm-4 beffect">
                    <div className="card">
                        <div className="card-header" style={{padding:'10px'}}>
	                        <h5>Arrived at Indian Port - BOE Not Filed</h5>
                        </div>         
                        <div className="card-body"  id="consignment-overdue-component">
                            <div className="u-steps row">
                                <div className="u-step col-sm-12 textAlignCenter cpointer" onClick={this.onClickBOECounter.bind(this,"arrivedindian")}>
                                    <div className="u-step-desc">
                                        <span className="txt-black">&nbsp;</span>
                                        <h4 className="txt-info fnt2 textAlignCenter">
                                            <span className="counter">
                                                <CountUp end={(this.state.arrivedindiancnt)?this.state.arrivedindiancnt:0}/>
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                            </div>

                        
                        </div>
                    </div>
                </div>
                :""}
                <div className={clos}>
                                
                    <div className="card">
                        <div className="card-header" style={{padding:'10px'}}>
	                        <h5>Overdue Deliveries from ICD</h5>
                        </div>  
                        <div className="card-body"  id="consignment-overdue-component">
                            <div className="u-steps row">
                               
                                <div className="u-step col-sm-4  bgColorDangerLight cpointer" onClick={this.onClickICDCounter.bind(this,0)}>
                                    <div className="u-step-desc ">
                                        <span className="txt-black">5 -14 days</span>
                                        <h4 className="colorWhite fnt2">
                                            <span className="counter">
                                            <CountUp end={(this.state.icd_detention_delay_first)?this.state.icd_detention_delay_first:0}/>
                                            
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                                <div className="u-step col-sm-4 bgColorDangerMedium cpointer" onClick={this.onClickICDCounter.bind(this,1)}>
                                    <div className="u-step-desc ">
                                        <span className="txt-black">15 - 21 days</span>
                                        <h4 className="colorWhite fnt2">
                                            <span className="counter">
                                            <CountUp end={(this.state.icd_detention_delay_second)?this.state.icd_detention_delay_second:0}/>
                                           
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                                <div className="u-step col-sm-4 bgColorDangerDark cpointer" onClick={this.onClickICDCounter.bind(this,2)}>
                                    <div className="u-step-desc ">
                                        <span className="txt-black"> &gt; 21 days</span>
                                        <h4 className="colorWhite fnt2">
                                            <span className="counter">
                                                <CountUp end={(this.state.icd_detention_delay_third)?this.state.icd_detention_delay_third:0}/>
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}






