import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var mapZoom=5;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;
var bounds;
var fencingLength=0;
var geoFencingArea;
export default class BreaksClusterComponent extends Component {

    constructor(props) {
		super(props);
		console.log(this.props.match.url)
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            defaultColDef : {sortable:true},
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'ALL',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
            onTripCounter : 0,
            statesList : [],
            breaksData : [],
            tolls : [],
            plants : [],
            selectedState : {"label":"Haryana","value":"Haryana"},
            modules: AllModules,
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }


	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
		
    componentDidMount(){
		//console.log(this.props.match.)
		this.logPageView(); // Log page view to GA
	
		this.setState({
			loadshow:'show-m',
			coordinates:[],
			rowData:[]
		});
		redirectURL.post("/vicinity/getBreakClusterInitialData").then((response)=>{
            var states = [];
            response.data.states.map(function(e){
                if(e != "" && e != "NA"){
                    states.push({"label":e,"value":e})
                }
            })
            this.setState({
                statesList : states,
                tolls : response.data.tolls,
                plants : response.data.plants,
                loadshow : "show-n",
                overly : "show-n"
            });
            var state = this.state.selectedState.value;
            redirectURL.post("/vicinity/getStateBreakCluster",{"state":state}).then((response)=>{
                this.setState({
                    breaksData : response.data,
                    overly : "show-n",
                    loadshow : "show-n"
                })
                this.renderMap();
            }).catch((e)=>{
                console.log(e);
            });
        }).catch((e)=>{
            console.log(e);
        })
		
		
	}
	
	renderMap = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}
	
	 initMap = () => {
        bounds = new window.google.maps.LatLngBounds();
        map = new window.google.maps.Map(document.getElementById('breaks_cluster_map'), {
            zoom: 5,
            center: new window.google.maps.LatLng(28.4595,77.0266),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
            },            
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
        });
        if(this.state.tolls.length > 0)
        {
            var infowindow = new window.google.maps.InfoWindow();
            var l;
            var selectedState = this.state.selectedState.value;
            this.state.tolls.map(function(e,index){
                if(e.state != undefined)
                {
                    if(e.state.toLowerCase() == selectedState.toLowerCase())
                    {
                        bounds.extend(new window.google.maps.LatLng(e.lat,e.lon));
                        var tollMarker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lon),
                            icon:require('../../assets/icons/barrier.png'),
                            map: map,
                        });
                        window.google.maps.event.addListener(tollMarker, 'click', (function(tollMarker, index) {
                            return function() 
                            {
                                var contentarr = []
                                var header = "Toll - "+e.name
                                contentarr.push({"key":"Address", "value":e.location})
                                var contentString = infoBox(tollMarker.icon, header, contentarr)
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, tollMarker);
                            }
                        })(tollMarker, index));
                        window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                            infowindow.close();
                            });
                    }
                }
                
                
            })  
            
        }
        if(this.state.plants.length > 0){
            this.state.plants.map(function(params){
                var coords = JSON.parse(params.coordinates);
                bounds.extend(new window.google.maps.LatLng(coords.lat,coords.lng));
                var plant = {
                    url: require("../../assets/icons/"+params.icon), // url
                    scaledSize: new window.google.maps.Size(32,32), // scaled size
                    origin: new window.google.maps.Point(0,0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor
                };
                
                // console.log(coords);
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(coords.lat,coords.lng),
                    map: map,
                    icon: plant,
                    title : params.plant_name
                });
                var cityCircle = new window.google.maps.Circle({
                    strokeColor: '#9fc9b9',
                    strokeOpacity: 0.3,
                    strokeWeight: 2,
                    fillColor: '#9fc9b9',
                    fillOpacity: 0.6,
                    map: map,
                    center:coords,
                    radius: 5000
                });
                })
          }
        try{
            var infowindow = new window.google.maps.InfoWindow();
            this.state.breaksData.map(function(breaks,index)
            {
                bounds.extend(new window.google.maps.LatLng(breaks.lat,breaks.lng));
                
                var breaksmarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(breaks.lat,breaks.lng),
                    map: map,
                    icon: require("../../assets/icons/cf.png"),
                    // title : ""+params.address+","+params.forecast_date+","+params.description
                });
                window.google.maps.event.addListener(breaksmarker, 'click', (function(breaksmarker, index) {
                    return function() 
                    {
                        var contentarr = []
                        var header = breaks.area
                        contentarr.push({"key":"No. of breaks", "value":breaks.no_of_breaks})
                        contentarr.push({"key":"Latitude", "value":breaks.lat})
                        contentarr.push({"key":"Longitude", "value":breaks.lng})
                        if(breaks.near_toll == 1){
                            contentarr.push({"key":"Near Toll", "value":""})
                            contentarr.push({"key":"Toll Name", "value":breaks.toll_name})
                            contentarr.push({"key":"Distance From Toll (KM)", "value":breaks.distance_from_toll})
                        }
                        if(breaks.near_state_border == 1){
                            contentarr.push({"key":"Near State Border", "value":""})
                            contentarr.push({"key":"State Border Name", "value":breaks.state_border_name})
                            contentarr.push({"key":"Distance From State Border (KM)", "value":breaks.distance_from_state_border})
                        }
                        var contentString = infoBox(breaksmarker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        var currentwindow = infowindow;
                        infowindow.open(map, breaksmarker);
                    }
                })(breaksmarker, index));
                window.google.maps.event.addListener(breaksmarker, 'mouseout', function() {
                    infowindow.close();
                    });
                
                
            })
        }catch(e){
            console.log(e,"Breaks data is not found");
        }
        map.addListener('zoom_changed', function() {
            mapZoom=map.getZoom();
          });
        map.fitBounds(bounds)
    }
    
    setSelectedState(event){
        console.log(event)
        this.setState({
            selectedState : event
        })
    }
	getStateBreakCluster(){
        this.setState({
            overly : "show-m",
            loadshow : "show-m"
        })
        var state = this.state.selectedState.value;
        redirectURL.post("/vicinity/getStateBreakCluster",{"state":state}).then((response)=>{

            this.setState({
                breaksData : response.data,
                overly : "show-n",
                loadshow : "show-n"
            })
            this.renderMap();
        }).catch((e)=>{
            console.log(e);
        });
        
    }
    focusBreakPoint(event)
    {
        if(event.colDef.field == "_id")
        {
            map.setCenter(new window.google.maps.LatLng(event.data.lat,event.data.lng));
            map.setZoom(22)
        }
    }
    resetZoom(){
        map.fitBounds(bounds)
    }
    render(){
							
    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
        }
        const columnwithDefs = [
            {
                headerName : "",
                field : "_id",
                resizable : true,
                filter : true,
                editable : false,
                width : 60,
                cellRenderer : function(params){
                    return "<i class='icofont icofont-map-pins f25'></i>"
                }
            },
            {
                headerName : "Latitude",
                field : "lat",
                resizable : true,
                width : 120,
                filter : true,
                editable : false
            },
            {
                headerName : "Longitude",
                field : "lng",
                resizable : true,
                width : 120,
                filter : true,
                editable : false
            },
            {
                headerName : "Area / City",
                field : "area",
                resizable : true,
                filter : true,
                editable : false
            },
            {
                headerName : "State",
                field : "state",
                resizable : true,
                filter : true,
                editable : false
            },
            {
                headerName : "No. of Breaks",
                field : "no_of_breaks",
                resizable : true,
                width : 120,
                filter : true,
                editable : false
            },
            {
                headerName : "Toll Name",
                field : "toll_name",
                resizable : true,
                width : 150,
                filter : true,
                editable : false
            },
            {
                headerName : "Distance from Toll (KM)",
                field : "distance_from_toll",
                resizable : true,
                width : 120,
                filter : true,
                editable : false
            },
            {
                headerName : "State Border Name",
                field : "state_border_name",
                resizable : true,
                width : 150,
                filter : true,
                editable : false
            },
            {
                headerName : "Distance from State Border (KM)",
                field : "distance_from_state_border",
                resizable : true,
                width : 120,
                filter : true,
                editable : false
            },
            {
                headerName : "Near Plant",
                field : "near_plant",
                resizable : true,
                width : 120,
                filter : true,
                editable : false,
                valueGetter : function(params){
                    if(params.data.near_plant)
                    {
                        return "Yes";
                    }
                    else
                    {
                        return "No"
                    }
                }
            },
            {
                headerName : "Plant Name",
                field : "plant_name",
                resizable : true,
                width : 150,
                filter : true,
                editable : false
            },
            {
                headerName : "Near Dealer",
                field : "near_dealer",
                resizable : true,
                filter : true,
                width : 120,
                editable : false,
                valueGetter : function(params){
                    if(params.data.near_dealer == 1)
                    {
                        return "Yes";
                    }
                    else if(params.data.near_dealer == 0)
                    {
                        return "No"
                    }
                }
            },
            {
                headerName : "Dealer Name",
                field : "dealer_name",
                resizable : true,
                width : 150,
                filter : true,
                editable : false
            },
            {
                headerName : "Near Competitor",
                field : "near_competitor",
                resizable : true,
                filter : true,
                width : 120,
                editable : false,
                valueGetter : function(params){
                    if(params.data.near_competitor == 1)
                    {
                        return "Yes";
                    }
                    else if(params.data.near_competitor == 0)
                    {
                        return "No"
                    }
                }
            },
            {
                headerName : "Competitor Name",
                field : "competitor_name",
                resizable : true,
                width : 150,
                filter : true,
                editable : false
            },
             
        ]
		
		
        return (
            
    		<div className="container-fluid mb-60p">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                > 
                </SweetAlert>
    
                <div className="row col-xl-12 col-lg-12 mb-20p">
                    <Select placeholder="Select Trip Type"
                    isMulti={false}
                    className="col-xl-3 col-lg-3"
                    value={this.state.selectedState}
                    onChange={this.setSelectedState.bind(this)} 
                    options={this.state.statesList} 
                    required/>
                    <button className="btn btn-info col-xl-1 col-lg-1" onClick={this.getStateBreakCluster.bind(this)}>Submit</button>
                </div>
            	<div className="row">
                    <div className="col-xl-12 col-lg-12" style={{textAlign:"right"}}>
                        <button className="btn btn-success" onClick={this.resetZoom.bind(this)}>Reset Zoom</button>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <div className="ag-theme-balham" id="mygrid" style={{width:"100%",height:"95%"}}>
                            <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.breaksData}
                            enableRangeSelection={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            rowSelection="single"
                            onCellClicked={this.focusBreakPoint.bind(this)}
                            />
                        </div>
                    </div>
            		<div className="col-xl-8 col-lg-8">
		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-coffee-cup cus-i"></i> <span> Breaks Cluster </span>
								</h5>
				   			</div>
				   			<div className="card-body row">
								<div className="row col-xl-12 col-lg-12">
									<div id="breaks_cluster_map" className={this.state.mwidth} style={dStyles}></div>
								</div>
                            </div>
				   		</div>	
				   	</div> 
				</div>

                {/* LOADER AND OVERLY */}
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})