import React, { Component } from 'react';
import {Link, IndexLink} from 'react-router-dom';
import Fullscreen from "react-full-screen";


export default class Topmenus extends Component {

    constructor(props) {
        super(props);
        
      }
     
    render(){
        return (
            <div className="col-xl-12 col-lg-12">
            	<ul className="col-xl-12 col-lg-12 tmenus">
            		<li>
            			<a href={"/zoho/token"}>Generate Token</a>
            		</li>
            		<li>
	        			<a href={"/zoho/accounts"}>My Account</a>
	        		</li>
            		<li>
	        			<a href={"/zoho/refreshToken"}>Refresh Token</a>
	        		</li>
            	</ul>
            </div>
       );
    }
}


                	
                	
                	