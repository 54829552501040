'Use State';
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import KpiTransitDelayActions from '../manage/transitdelay.js';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYYHHMMSS } from '../common/utils';
// import KpiNoTransitDelayActions from '../manage/transitdelay.js';
//import { transitdelay } from '../manage/transitdelay.js';
// import NoGps from '../manage/nogpsActionComponent.js';
// import KpiActions from '../manage/kpiActionsComponent';
// import Nooverspeed from '../manage/nooverspeedComponent.js';
// import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
// import Nonightdrive from '../manage/nonightdriveComponent.js';
// import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
// import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
// import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
// import Gps from '../manage/gpsActionComponent';





import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
// import StateManager from 'react-select';
var moment = require('moment');

export default class TruckSidebarComponent extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            truckData:this.props.truckInfo,
            consignmentlists:this.props.consignment,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
			rowData: this.props.consignment,
            animateRows: true,
            truckLocation:"",
            route_details:"",
            route_info:"", 

        };
                
        this.onClickShowMap=this.onClickShowMap.bind(this);
       //console.log("Contxt", this.props.consignments);
       // this.showRelatedData()
    }
    componentWillReceiveProps (newprops){
        // console.log("this.state.consignments",this.state.consignments);
        // console.log("newprops.consignments",newprops.consignments);
        if(this.state.rowData !== newprops.rowData){
            this.setState({
                truckData:newprops.truckInfo,
                consignmentlists:newprops.consignment,
                rowData: newprops.consignment,
            })
        }
    }

    handleClick = (e) => {
        e.stopPropagation();
        this.props.context.onCloseUploadDiv();
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
    
     
    onClickShowMap(){
        console.log(this.state.rowData,"rowData");
        var erparmas = {
            consignment_code:this.state.rowData[0].consignment_code,
            consignee_code:this.state.rowData[0].consignee_code,
            truck_no:this.state.rowData[0].truck_no,
        }   
        redirectURL.post("/consignments/sidebarconsignmentmap", erparmas)
        .then((response) => {
            // console.log("resp map", response.data)
            var consignment_details = response.data;
            if(consignment_details.invoice_time)
            {
                // console.log("came into invoice time", consignment_details.invoice_time)
                //console.log("Step1", e.invoice_time)
                var gateouttime = moment.parseZone(consignment_details.invoice_time).format('YYYY-MM-DD HH:mm:ss');
            }
            else{
                //console.log("Step2")
                if(consignment_details.gate_in_time)
                {
                    // console.log("came into gate in time", consignment_details.gate_in_time)
                    //console.log("Step3")
                    var gateouttime = moment.parseZone(consignment_details.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
                }
                else{
                    //console.log("Step4")
                    if(consignment_details.gate_out_time){
                        //console.log("Step5")
                        // console.log("came into gate out time", consignment_details.gate_out_time)
                        var gateouttime = moment.parseZone(consignment_details.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
                    }
                }
            }
            
            // if (e.gate_out_time) {
            // 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            // }
            
            var edate ='';
            var end_date;
            if(consignment_details.status <= 4)
            {
                //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
                //var edatesecond = new Date(ms);
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                
                edate = getDDMMYYYYHHMMSS(end_date);
            }
            else if(consignment_details.status == 5)
            {
                if (consignment_details.left_dealer_marked) {
                    end_date = consignment_details.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                    edate = getDDMMYYYYHHMMSS(consignment_details.left_dealer_marked);
                }
                
            }
            else{
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                
                edate = getDDMMYYYYHHMMSS(end_date);
                
            }
            
            //console.log("end date",end_date);
            //console.log("checking e",e)

            
            if (!end_date) {
                // When end date is unavailable then current date is considered as end date.
                end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
                //console.log("Defining end_date ", end_date);
            } 
            // console.log("consignmentResponse",response.data);
            // console.log(consignment_details);
            this.setState({
                truckLocation : consignment_details,
                route_details:{"start_time":gateouttime,"end_time":edate},
                // rowData:consignment_details,

            });
            // console.log(this.state.truckLocation);
        })        
    }
    
     
    render(){
        // console.log("list ", this.state.consignmentlists);
    const consigneecols = [
        {
            headerName: "",
            field: "transit_delay",
            colId: "transit_delay",
            width: 50,
            pinned: 'left',
            pivot: false,
            cellRenderer:transitDelay,
            filter: true,resizable: true
        },
        {
            headerName: "",
            field: "no_gps_data",
            colId: "no_gps_data",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:noGpsData,
        },
        {
            headerName: "Overspeed",
            field: "overspeeding_exception",
            colId: "overspeeding_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:OverspeedException,
        },
        {
            headerName: "Night Drive",
            field: "nightdriving_exception",
            colId: "nightdriving_exception",
            width: 40,
            pinned: 'left',					
            pivot: true,
            cellRenderer:nightDrive,
            // hide:hideonlyPRT,
            
        },
        {
            headerName: "Loading Delay",
            field: "loading_delay_exception",
            colId: "loading_delay_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:loadingDelayExcept,
        },

        {
            headerName: "Enroute Stoppages",
            field: "enroute_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:enrouteExcept,
        },
    ];
    console.log("this.state.rowData",this.state.rowData);
    var status = this.state.rowData[0].status;
    var status_value;
    if(status == 1)
    {
        status_value="Inside Plant";
    }
    else if(status == 2){
        status_value="In Transit";
    }
    else if(status == 3){
        status_value="Near Destination";
    }
    else if(status == 4){
        status_value="Reached Destination";
    }
    else if(status == 5){
        status_value="Left Destination";
    }
    else if(status == 6){
        status_value="Force Closed";
    }
    else{
        status_value=""
    }


        return ( 
                <div className="col-xl-12 xol-lg-12">
                    <div className="row">
                        <div className="slide-r-title col-xl-12 col-lg-12" style={{padding:"0px"}}>
                            <h4>
                               
                                Consignment Code #{this.state.rowData[0].consignment_code}
                            </h4>
                        </div>
                        <div className="slide-r-body col-xl-12 col-lg-12" style={{position:"relative"}}>
                            <div className="col-xl-12 col-lg-12">
                            <Accordion preExpanded='info'>
                                <AccordionItem uuid='info'>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Basic Information:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={this.state.rowData[0]}>
                                        
                                         <table className="table table-border">
                                             <tbody>
                                             {this.state.rowData.map(item=>{
                                            return [
                                            <tr>
                                                <th>Status</th>
                                                <td>{ status_value }</td>
                                                
                                            </tr>,
                                            <tr>
                                                <th>Truck Number</th>
                                                <td>{item.truck_no}</td>
                                            </tr>,
                                            <tr>
                                                <th>State</th>
                                                { (item.hasOwnProperty('state'))?item.truck[0].state:<td>NA</td> }
                                                
                                            </tr>,

                                            <tr>
                                                <th>City</th>
                                                { (item.hasOwnProperty('city'))?item.truck[0].city:<td>NA</td> }
                                            </tr>,

                                            <tr>
                                                <th>Transporter Name</th>
                                                <td>{item.transporter_name}</td>
                                            </tr>,
                                            <tr>
                                                <th>Consignee Code</th>
                                                <td>{ item.consignee_code }</td>
                                                
                                            </tr>,
                                            // <tr>
                                            //     <th>Consignee Name</th>
                                            //     <td>{ item.consignee_name }</td>
                                                
                                            // </tr>,
                                            // <tr>
                                            //     <th>Consignee City</th>
                                            //     <td>{ item.consignee_city }</td>
                                                
                                            // </tr>,
                                            <tr>
                                                <th>Cluster</th>
                                                <td>{ item.cluster }</td>
                                                
                                            </tr>,
                                            <tr>
                                                <th>Gate in Time</th>
                                                <td>{ getDDMMYYYYHHMMSS(item.gate_in_time) }</td>
                                                
                                            </tr>,
                                            <tr>
                                                <th>Gate Out Time</th>
                                                <td>{ getDDMMYYYYHHMMSS(item.gate_out_time) }</td>
                                                
                                            </tr>,
                                            <tr>
                                                <th>Invoice time</th>
                                                <td>{ getDDMMYYYYHHMMSS(item.invoice_time) }</td>
                                                
                                            </tr>,
                                            ]
                                             })
                                             }
                                            </tbody>
                                        </table>
                                           
                                        
                                    </AccordionItemPanel>
                                </AccordionItem>
                                
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                                Exceptions:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={this.state.consignmentlists}>
                                         
                                        <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={consigneecols}
                                                frameworkComponents={this.state.frameworkComponents}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                            
                                            />
                                        </div>
                                           
                                    </AccordionItemPanel>
                                </AccordionItem>
                                
                                 <AccordionItem>
                                    <AccordionItemHeading  onClick={this.onClickShowMap}>
                                        <AccordionItemButton>
                                         Map Location:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    {(this.state.truckLocation != '')?
                                        <Map width={"92%"} mapFor={'consignemntSidebarMap'} mapinfo={this.state.truckLocation} route_details={this.state.route_details} marginLeft={"15px"} />
                                    :""}
                                    </AccordionItemPanel>
                                </AccordionItem> 
                            </Accordion>

                            </div>
                            <div className="col-xl-12 col-lg-12 mt-30p">
                                <button onClick={this.handleClick.bind(this)} className="btn btn-default">Close</button>
                            </div>
                        </div>
                     </div>
                </div>
			);
		}
}

function transitDelay(params){
    // console.log("PP ", params.data.transit_delay)
    if(params.data.transit_delay == 1)
    {
        return '<i class="f22 icofont icofont-clock-time" style="color:#ff0000"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-clock-time" style="color:#ccc"></i>';
    }
}

function noGpsData(params){
    // console.log(params.data.no_gps_data, typeof params.data.no_gps_data)
    if(params.data.no_gps_data == 1)
    {
        return '<i class="f22 icofont icofont-social-google-map" style="color:#26c6da"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-social-google-map" style="color:#ccc"></i>';
    }
}


function OverspeedException(params){
    // console.log("params.data.overspeeding_exception",params.data.overspeeding_exception);
    if(params.data.overspeeding_exception == 1)
    {
        return '<i class="fa fa-tachometer fa-2x" style="color:#00c292"></i>';
    }
    else{
        return '<i class="fa fa-tachometer fa-2x" style="color:#ccc"></i>';
    
    }
}

function nightDrive(params){
    if(params.data.nightdriving_exception == 1)
    {
        return '<i class="f22 icofont icofont-full-night txt-primary" style="color:#ab8ce4"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-full-night" style="color:#ccc"></i>';
    
    }  
}

function loadingDelayExcept(params){
    if(params.data.loading_delay_exception){
        return '<i class="f22 icofont icofont-truck-loaded darkorange" style="color:#d3a337"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-truck-loaded" style="color:#ccc"></i>';
    
    }
    
}

function enrouteExcept(params){
    if (params.data.enroute_exception)
    {
        return '<i class="f22 icofont icofont-pause maroon" style="color:#a34518"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-pause maroon" style="color:#ccc"></i>';
    
    }
}