import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ExportsDeliveredConsignmentsCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	// this.state={
        //     records:[],
        //     allRowDatacount:0,
    	// 	highseascount:0,
    	// 	inportcount:0,
        //     intransiticdcount:0,
        //     inicdcount:0,
        //     transitmsilcount:0
    	// }
    	this.state={
            records:[],
            allRowDatacount:0,
            totalConsignmentsCount:0,
            
            inTransitToMsilCount:0,
            inTransitToIcdCount:0,
    		insideIcdCount:0,
            inTransitToPortCount:0,            
            insidePortCount:0,
            highSeasCount:0
    	}
    
    }
    // <ExportsConsignmentsCounters 
	// 				context={this}
	// 				allRowDatacount={this.state.allRowData.length}
	// 				inTransitToMsilCount={this.state.inTransitToMsilCount.length}
	// 				insideIcdCount={this.state.insideIcdCount.length}
	// 				inTransitToMsilCount={this.state.inTransitToMsilCount.length}
	// 				inTransitToPortCount={this.state.inTransitToPortCount.length}
	// 				totalConsignmentsCount={this.state.totalConsignmentsCount.length}
	// 			 />
    componentWillReceiveProps(newprops){
       this.setState({
            allRowDatacount:newprops.allRowDatacount,
            totalConsignmentsCount:newprops.totalConsignmentsCount,
            inTransitToMsilCount:newprops.inTransitToMsilCount,
            inTransitToIcdCount:newprops.inTransitToIcdCount,
            insideIcdCount:newprops.insideIcdCount,
            inTransitToPortCount:newprops.inTransitToPortCount,
            insidePortCount:newprops.insidePortCount,
            highSeasCount:newprops.highSeasCount
       })

    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                               
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> Delivered</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.totalConsignmentsCount != '')?this.state.totalConsignmentsCount:0}/></span></h4>
                                </div> 

                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






