import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYYHHMMSS, 
    getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import redirectURL from '../redirectURL';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


var todaydateis = moment.parseZone().format("YYYY/MM/DD")
export default class VinRoadPickUpComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            //components : {datepicker:getDatePicker()}
        }
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
       // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle='Last Mile Transportation';
        redirectURL.post("/consignments/getGeoFences").then((response)=>{
            var geofencelist = response.data.geofencelist;
            console.log(geofencelist);
            var geoFenceArray = [];
            geofencelist.map(function(params){
                geoFenceArray.push(params.geofence_name);
            });
            this.setState({
                pagetitle:pagetitle,
                geofencelist:geoFenceArray,
                geoFenceData:geofencelist,
                rowData : []
            });
        }).catch(function(error){
            console.log("error");
        });
        

    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    selectGeofence(){
        let arr=[];
        try{
            this.state.geofencelist.forEach((item,index) =>{
            arr.push({value:this.state.geoFenceData[index]._id,label:item});
        });
        
        return arr;
        }
        catch(e){

        } 
    }
    getRailConsginments(params){
        console.log("params ", params)
        //try{
            //var geoFenceId = params
            var geofenceidarr = [];
            //geoFenceId.map(function(e){
                geofenceidarr.push(params.value);
            //});
             console.log(geofenceidarr);
            var dept_code = "LOG-TNP";
            var transportercode = ''
            try{
                transportercode = localStorage.getItem("transportercode")
            }catch(e){
                transportercode = '';
            }
            //console.log("roadpickup ", localStorage.getItem("transportercode"))
            var reqParams = {
                geoFenceId : geofenceidarr,
                dept_code : dept_code,
                transportercode: transportercode
            }
            redirectURL.post("/consignments/geoFenceRailVinConsignments",reqParams).then((response)=>{
                console.log(response.data);
                if(response.data.consignments.length > 0){
                    this.setState({
                        rowData:response.data.consignments
                    });
                }
                else{
                    this.setState({
                        rowData : [],
                    })
                }
                // console.log(this.state.rowData);
            }).catch(function(error){
                console.log(error);
            });
        // }
        // catch(e){

        // }        
    }
    changeUploadYard(e){
        this.setState({
            uploadYard : e.value,
        })
    }
    changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		//var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'vin',
					inputName: 'vin',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
                var contents = await e.target.result;
                console.log(contents)
				resolve(contents);
			};
			//var tt =  reader.readAsText(e.target.files[0]);
		});
		
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
}

uploadBulkFormHandler(event){
    event.preventDefault();
    
    if(this.state.file != '')
    {
        //console.log("this.state.file ",this.state.file)
       // var csvdd = this.state.file;
        
        // console.log("event ",event.target.uploadFile.value);
        
        var rparams = {
            csvcontent:this.state.csvcontent,
            bulkUploadYard : this.state.uploadYard,
            dispatch_date : $(".uploadDispatchDate").val()
        }
        //console.log(rparams);
        var csvContent = this.state.csvcontent;
        // console.log(csvContent)
        // console.log(typeof(csvContent));
        var emptyFlag  = 0;
        for(var i=1;i<csvContent.length;i++)
        {
            var truck_no = csvContent[i].truck_no;
            var vin = csvContent[i].vin;
            //console.log(vin)
            if(truck_no == "" || vin == "" || truck_no == null || truck_no == undefined || vin == undefined || vin ==null)
            {
                emptyFlag = 1;
            }
            
        }
        
        this.setState({
            loadshow:'show-m'
        }); 
        if(emptyFlag == 0)
        {
            redirectURL.post("/consignments/updateVinBulkRoadpickUp",rparams).then((response)=>{
                var result = response.data;
                //console.log("response bulk upload ", result)
                if(result.notvalidrecords.length == 0)
                {
                    if(result.flag == 0)
                    {
                        this.setState({
                            //csverrmessage:response.data.status,
                            show: true, basicType:'success', basicTitle:"Successfuly update File.",
                            uploadDivWidth:'0%',
                            showDiv:'show-n',
                            loadshow:'show-n',
                            uploadFile:'',
                            bulkslide:"",
                            overly:"show-n",
                            file:''
                        });
                    }
                    else{
                        this.setState({
                            show: true, basicType:'danger', 
                            basicTitle:"Dispatch date should be greater than destination reached date",
                            uploadDivWidth:'0%',
                            showDiv:'show-n',
                            loadshow:'show-n',
                            uploadFile:'',
                            bulkslide:"",
                            overly:"show-n",
                            file:''
                        });
                    }
                    
                }
                else{
                    if(result.flag == 0)
                    {
                        this.setState({
                            //csverrmessage:response.data.status,
                            show: true, basicType:'danger', 
                            basicTitle:"Following Vin nos does not have destination yard. "+result.notvalidrecords,
                            uploadDivWidth:'0%',
                            showDiv:'show-n',
                            loadshow:'show-n',
                            uploadFile:'',
                            bulkslide:"",
                            overly:"show-n",
                            file:''
                        });
                    }
                    else
                    {
                        this.setState({
                            show: true, basicType:'danger', 
                            basicTitle:"Dispatch date should be greater than destination reached date",
                            uploadDivWidth:'0%',
                            showDiv:'show-n',
                            loadshow:'show-n',
                            uploadFile:'',
                            bulkslide:"",
                            overly:"show-n",
                            file:''
                        });
                    }
                }
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            $("#bulkUploadID").val("");
            
                this.setState({
                    //csverrmessage:response.data.status,
                    show: true, basicType:'danger', basicTitle:"Consignment Code or Truck No Must Not be Empty.",
                    uploadDivWidth:'0%',
                    sliderTranslate:'',
                    showDiv:'show-n',
                    loadshow:'show-n',
                    uploadFile:'',
                    file:''
                });
        }
        
    }
    
}
    
formAssignHandler(){
        // event.preventDefault();
        //console.log(this.gridApi);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
        });
        var editedGeoFenceArr = [];
        var flag=0;
        itemsToUpdate.map(function(e){
            // console.log(cleanTruckNo(e.new_truck_no),getDDMMYYYYHHMMSS(e.new_rake_dispatch_date));
            if(e.new_truck_no != '' && e.new_rake_dispatch_date != '')
            {
                if(cleanTruckNo(e.new_truck_no) != '')
                {
                    console.log("e.new_rake_dispatch_date ", e.new_rake_dispatch_date)
                    var temp = {
                        vin : e.vin,
                        consignment_code : e.consignment_code,
                        consignee_code : e.consignee_code,
                        new_truck_no : cleanTruckNo(e.new_truck_no),
                        new_rake_dispatch_date : getDDMMYYYYHHMMSS(e.new_rake_dispatch_date)
                    };
                    editedGeoFenceArr.push(temp);
                    flag=1
                }
                
            }
            else
            {
                flag=0
            }
            
        });
        //console.log("Flage ", flag)
        if(flag == 1)
        {
            var reqParams = {
                geoFenceArr : editedGeoFenceArr
            }
            redirectURL.post("/consignments/updateVinRoadPickUp",reqParams).then((response)=>{
                this.setState({
                    show:true,
                    basicTitle:"Updated Successfully.",
                    basicType:"success",
                });
               /// window.location.reload();
            }).catch(function (error){
                console.log(error);
            })
        }
        else
        {
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"Fields should not be empty"
            })
        }
        
}
onClickShowBulkUpload(){
    this.setState({
        bulkslide:"slider-translate",
        overly:'show-m'
    });
}
resetUpload = () => {
    this.setState({
        uploadDivWidth:'0%',
        bulkslide:'',
        showDiv:'show-n',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = async () => {
    await this.setState({
        show: false
    });
    await window.location.reload();
}

validateDateWithConsignment (params) {
    //console.log("params ", params)
    
    if(params.colDef.field == "new_rake_dispatch_date")
    {
        var flag=0;
        if(params.value != "")
        {
            var u = params.value.split(" ");
            var t = u[0].split("/");
            var st =t[2]+"-"+t[1]+"-"+t[0]+" "+u[1];
           // console.log("st ", st) 
            var s = getHyphenYYYYMMDDHHMMSS(st);
            //console.log("S ", s);
            var s1 = moment.parseZone(s).format("x");
           // console.log("params.data.rake_delivery_point_reached_on ",params.data.rake_delivery_point_reached_on)
            var g = getHyphenYYYYMMDDHHMMSS(params.data.rake_delivery_point_reached_on);
            var s2 = moment.parseZone(g).format("x");
            //console.log("S1 ", s1);
           //console.log("S2 ", s2);
            if(parseInt(s1) > parseInt(s2))
            {
                flag = 1;
            }
            else{
                flag = 0;
                this.setState({
                    show:true,
                    basicTitle:"Truck Dispatch Date should be greater than Destination Yard Reached On",
                    basicType:"danger"
                });
            }
        }
       
    }
   
}
    render(){
        const columnwithDefs = [
            {
                headerName: "VIN",
                field: "vin",
                filter: true,
                resizable: true,
                
            },
             {
                headerName: "Consignment Code",
                field: "consignment_code",
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consignment_code == ""){
                        return "N/A";
                    }
                    else{
                        return params.data.consignment_code;
                    }
                }
            },
            {
                headerName: "Consignee Code",
                width:140,
                field: "consignee_code",
                filter: true,
                resizable: true,
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consignee_name == ""){
                        return "N/A";
                    }
                    else{
                        return params.data.consignee_name;
                    }
                }
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                width:140,
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consignee_city == ""){
                        return "N/A";
                    }
                    else{
                        return params.data.consignee_city
                    }
                }
            },
            // {
            //     headerName: "Existing Truck No",
            //     field: "truck_no",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter:function(params){
            //         if(params.data.truck_no == ""){
            //             return "N/A";
            //         }
            //         else{
            //             return params.data.truck_no
            //         }
            //     }
            // },
            // {
            //     headerName: "Expected Rake Dispatch Date",
            //     field: "expected_rake_dispatch_date",
            //     filter: true,
            //     resizable: true,
            //     // cellEditor:DateEditor,
            //     valueGetter:function(params){
            //         if(params.data.expected_rake_dispatch_date == ""){
            //             return "N/A";
            //         }
            //         else{
            //             return params.data.expected_rake_dispatch_date
            //         }
            //     }
            // },
            {
                headerName: "Truck No",
                field: "new_truck_no",
                editable:true,
                filter: false,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.new_truck_no != ""){
                        var newTruckNo = params.data.new_truck_no;
                        var cleanedTruckNo = cleanTruckNo(newTruckNo);
                        return cleanedTruckNo;
                    }
                    
                }
            },
            {
                headerName: "Truck Dispatch Date",
                field: "new_rake_dispatch_date",
                editable:true,
                filter: false,
                resizable: true,
                cellEditor: DateEditor,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.new_rake_dispatch_date != null)
                    {
                        if(moment(params.data.new_rake_dispatch_date,'DD-MM-YYYY hh:mm',true).isValid() == false)
                        {
                            return getDDMMYYYYHHMMSS(params.data.new_rake_dispatch_date);
                        }
                        else{
                            return params.data.new_rake_dispatch_date;
                        }
                        
                    }
                    else
                    {
                        return ""
                    }

                }
            },
        ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                        
                    </h5>
                    <div className="form-theme col-xl-12 col-lg-12 row">
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Select : </label>
                            <Select 
                            placeholder={"Select "}
                            closeMenuOnSelect={true}
                            // isMulti="true"
                            onChange={this.getRailConsginments.bind(this)} 
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            options={this.selectGeofence()} />            
                        </div>
                        <div className="form-group col-xl-7 col-lg-7 "></div>
                        <div className="col-xl-2 col-lg-2" style={{paddingTop:"40px"}}>
                            <button className="float-right f15 custom-btn btn-success" onClick={this.onClickShowBulkUpload.bind(this)}>
                                Bulk Upload
                            </button>    
                        </div>                
                    </div>
                </div>
                <div id="myGrid" style={{width:"100%",height:"380px"}} className="ag-theme-balham">    
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        enableCharts={false}
                        enableRangeSelection={false}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        onCellEditingStopped={this.validateDateWithConsignment.bind(this)}
                        components={this.state.components}
                        />

                </div>

                <div className="col-xl-12 col-lg-12 ml-0p n-p-0 road-pick-up-grid">
                    {/* <div className="col-xl-2 col-lg-2 form-group"> */}
                        {/* <label className="replacement-time"><br /></label> */}
                        {/* <button type="submit" className="btn btn-warning " id="replacement-submit">Update</button> */}
                        <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-secondary float-right">Update Now</button>
                    {/* </div>  */}
                </div>  
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> 

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden",width:"30%"}}>
					<h3 className="subH">Bulk Update</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                            <div className="form-group col-xl-12 col-lg-12">
                                <label >Select Yard </label>
                                <Select 
                                placeholder={"Select "}
                                closeMenuOnSelect={true}
                                onChange={this.changeUploadYard.bind(this)}
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectGeofence()} />            
                            </div>
                            <div className={"form-group col-xl-12 col-lg-12"}>
                                <label >Dispatch Date</label>
                                <input type="text" autoComplete="off" className="col-xl-12 col-lg-12 form-control uploadDispatchDate datetimepicker_date col-xl-7" id="replaced_Date" style={{height:"41px"}} />
                            </div>
                            <div className={"form-group col-xl-12 col-lg-12"}>
                                <label >Upload File</label>
                                <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control col-xl-12 col-lg-12" required  />
                            </div>
                                {/* <div className="row col-xl-12">
                                    <label className="col-xl-4 form-group mt-10p">Select Yard</label>
                                    <div className="col-xl-8">
                                        
                                    </div>
                                </div>
                                <div class="form-group mt-20p row">
                                    <label className="col-xl-4 form-group mt-10p">Dispatch Date</label>
                                    <input type="text" autoComplete="off" className="form-control uploadDispatchDate datetimepicker_date col-xl-7" id="replaced_Date" style={{height:"41px"}} />
                                </div> */}
                                {/* <div className="form-group mt-20p row">
                                    <label className="col-xl-4 form-group mt-10p" style={{paddingLeft:"31px"}}>Upload File</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control col-xl-7" required  />
                                </div> */}
                                <div className="form-group" style={{textAlign:"center"}}>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>*Note: All fields are mandatory and should not be empty.</p>
								
							

							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_vin_bulk_upload_last_mile.csv')} rel="Sample Template" target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>                 
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        maxDate:todaydateis
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
