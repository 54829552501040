
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentLoadReplayAttach = (props) => {
    console.log("props ", props)
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowPlantReplyCommentLoad(props);
    };
    if(parseInt(props.data.notify_plant) == 1 && props.data.plant_approval == 0)
    {
        var btnTitle  = 'Pending Plant'
    }
    else{
        var btnTitle  = 'Notify TPT'
    }
    //console.log("props.data.tpt_approval ", props.data.tpt_approval)
    //console.log("props.data.notify_tpt ", props.data.notify_tpt)
    return (
        <div className="map-icon-component">
            <button onClick={handleRouteClick} className="mybtn btn btn-success" title="View VIN List">{btnTitle} </button>
        </div>
    );
};

export default CommentLoadReplayAttach;
