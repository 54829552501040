
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const IntransitToDestinationYard = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowIntransitDestinationLegs(props);
    };
    

    return (
        <div>
            {(props.data.status == 4)?
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="In Transit To Destination Yard" style={{color:"#ff0000"}}><i className="icofont icofont-clock-time"></i> &nbsp;</button>
            :
            <button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="In Transit To Destination Yard" style={{color:"#ccc"}}><i className="icofont icofont-clock-time"></i> &nbsp;</button>
            
            }
        </div>
    );
};

export default IntransitToDestinationYard;
