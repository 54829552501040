
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const StockUploadFile = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation(); 
        props.context.componentParent.onClickShowStockUploadModal(props.data);
    };

    return (
        <div>
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="Upload File"><i className="icofont icofont-upload f20"></i> Upload </button>
       
        </div>
    );
};

export default StockUploadFile;
