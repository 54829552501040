
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ShowTollsList = (props) => {
    
    const handleClick = (e) => {
        e.stopPropagation();
        console.log("Props Routmap ", props);
        props.context.componentParent.onClickShowTollsList(props);
    };

    return (
        <div>
            <button 
                title="View Tolls" 
                onClick={handleClick}
                className="f14"
                style={{background:"transparent",border:"0px"}}>
                    {props.value}
            </button>
       
        </div>
    );
};

export default ShowTollsList;
