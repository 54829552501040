import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var containerslist = [];
var uncheckcontainerslist = [];

export default class GoldeZoneDashboard extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Golden Zone Dashboard",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: false,
				filter: false,
				resizable: true,
                width:117
      	    },
      	    rowData: [],
      	    rowPlannedData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
            },
            rowSelection: "multiple",
            autoGroupColumnDef: {
                
              },
			showAppBtn:"show-n",		  
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			
		}
	}
	componentDidMount = async() => {
		
        
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	 
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   this.gridApi.forEachLeafNode( (node) => {
			   //console.log(node)
				if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != undefined) {
					node.setSelected(true);
				}
			});
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false,
            pickup_plan_date:''
        });
    }
   
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'icd garhi plan';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	render(){
		var rowdata = [
            {
                wg1:"warehouse",
                wg2:"warehouse",
                wg3:"warehouse",
                wg4:"warehouse",
                wg5:"warehouse",
                wg6:"warehouse",
                wg7:"warehouse",
                wg8:"warehouse",
                wg9:"warehouse",
                wg10:"warehouse",
            },
            {
                wg1:"E01-F-01",
                wg1_value:"4600",
                wg2:"E01-F-02",
                wg2_value:"4100",
                wg3:"E02-F-01",
                wg3_value:"5600",
                wg4:"E02-F-02",
                wg4_value:"3600",
                wg5:"E03-F-01",
                wg5_value:"700",
                wg6:"E03-F-02",
                wg6_value:"4000",
                wg7:"E07-F-01",
                wg7_value:"4400",
                wg8:"E07-F-02",
                wg8_value:"3900",
                wg9:"E08-F-01",
                wg9_value:"5400",
                wg10:"E08-F-02",
                wg10_value:"3400"
            },
            {
                wg1:"E01-E-01",
                wg1_value:"1200",
                wg2:"E01-E-02",
                wg2_value:"700",
                wg3:"E02-E-01",
                wg3_value:"2200",
                wg4:"E02-E-02",
                wg4_value:"200",
                wg5:"E03-E-01",
                wg5_value:"500",
                wg6:"E03-E-02",
                wg6_value:"600",
                wg7:"E07-E-01",
                wg7_value:"1000",
                wg8:"E07-E-02",
                wg8_value:"500",
                wg9:"E08-E-01",
                wg9_value:"2000",
                wg10:"E08-E-02",
                wg10_value:"500"
            },
            {
                wg1:"E01-D-01",
                wg1_value:"5000",
                wg2:"E01-D-02",
                wg2_value:"4500",
                wg3:"E02-D-01",
                wg3_value:"6000",
                wg4:"E02-D-02",
                wg4_value:"4000",
                wg5:"E03-D-01",
                wg5_value:"4500",
                wg6:"E03-D-02",
                wg6_value:"4400",
                wg7:"E07-D-01",
                wg7_value:"4800",
                wg8:"E07-D-02",
                wg8_value:"4300",
                wg9:"E08-D-01",
                wg9_value:"5800",
                wg10:"E08-D-02",
                wg10_value:"3800"
            },
            {
                wg1:"E01-C-01",
                wg1_value:"500",
                wg2:"E01-C-02",
                wg2_value:"500",
                wg3:"E02-C-01",
                wg3_value:"1500",
                wg4:"E02-C-02",
                wg4_value:"800",
                wg5:"E03-C-01",
                wg5_value:"3500",
                wg6:"E03-C-02",
                wg6_value:"700",
                wg7:"E07-C-01",
                wg7_value:"300",
                wg8:"E07-C-02",
                wg8_value:"600",
                wg9:"E08-C-01",
                wg9_value:"1300",
                wg10:"E08-C-02",
                wg10_value:"600"
            },
            {
                wg1:"E01-B-01",
                wg1_value:"3000",
                wg2:"E01-B-02",
                wg2_value:"2500",
                wg3:"E02-B-01",
                wg3_value:"4000",
                wg4:"E02-B-02",
                wg4_value:"2000",
                wg5:"E03-B-01",
                wg5_value:"900",
                wg6:"E03-B-02",
                wg6_value:"2400",
                wg7:"E07-B-01",
                wg7_value:"2800",
                wg8:"E07-B-02",
                wg8_value:"2300",
                wg9:"E08-B-01",
                wg9_value:"3800",
                wg10:"E08-B-02",
                wg10_value:"1800"
            },
            {
                wg1:"E01-A-01",
                wg1_value:"1500",
                wg2:"E01-A-02",
                wg2_value:"1000",
                wg3:"E02-A-01",
                wg3_value:"2500",
                wg4:"E02-A-02",
                wg4_value:"500",
                wg5:"E03-A-01",
                wg5_value:"1000",
                wg6:"E03-A-02",
                wg6_value:"900",
                wg7:"E07-A-01",
                wg7_value:"1300",
                wg8:"E07-A-02",
                wg8_value:"800",
                wg9:"E08-A-01",
                wg9_value:"2300",
                wg10:"E08-A-02",
                wg10_value:"300"
            },
            {
                wg1:"gangway",
                wg2:"gangway",
                wg3:"gangway",
                wg4:"gangway",
                wg5:"gangway",
                wg6:"gangway",
                wg7:"gangway",
                wg8:"gangway",
                wg9:"gangway",
                wg10:"gangway",
            },
            {
                wg1:"E04-F-01",
                wg1_value:"4800",
                wg2:"E04-F-02",
                wg2_value:"4200",
                wg3:"E05-F-01",
                wg3_value:"5700",
                wg4:"E05-F-02",
                wg4_value:"3700",
                wg5:"E06-F-01",
                wg5_value:"800",
                wg6:"E06-F-02",
                wg6_value:"4100",
                wg7:"E09-F-01",
                wg7_value:"4500",
                wg8:"E09-F-02",
                wg8_value:"4000",
                wg9:"E10-F-01",
                wg9_value:"5500",
                wg10:"E10-F-02",
                wg10_value:"3500"
            },
            {
                wg1:"E04-E-01",
                wg1_value:"1400",
                wg2:"E04-E-02",
                wg2_value:"800",
                wg3:"E05-E-01",
                wg3_value:"2300",
                wg4:"E05-E-02",
                wg4_value:"300",
                wg5:"E06-E-01",
                wg5_value:"600",
                wg6:"E06-E-02",
                wg6_value:"700",
                wg7:"E09-E-01",
                wg7_value:"1100",
                wg8:"E09-E-02",
                wg8_value:"600",
                wg9:"E10-E-01",
                wg9_value:"2100",
                wg10:"E10-E-02",
                wg10_value:"600"
            },
            {
                wg1:"E04-D-01",
                wg1_value:"5200",
                wg2:"E04-D-02",
                wg2_value:"4600",
                wg3:"E05-D-01",
                wg3_value:"6100",
                wg4:"E05-D-02",
                wg4_value:"4100",
                wg5:"E06-D-01",
                wg5_value:"4600",
                wg6:"E06-D-02",
                wg6_value:"4500",
                wg7:"E09-D-01",
                wg7_value:"4900",
                wg8:"E09-D-02",
                wg8_value:"4400",
                wg9:"E10-D-01",
                wg9_value:"5900",
                wg10:"E10-D-02",
                wg10_value:"3900"
            },
            {
                wg1:"E04-C-01",
                wg1_value:"700",
                wg2:"E04-C-02",
                wg2_value:"900",
                wg3:"E05-C-01",
                wg3_value:"1600",
                wg4:"E05-C-02",
                wg4_value:"900",
                wg5:"E06-C-01",
                wg5_value:"3600",
                wg6:"E06-C-02",
                wg6_value:"800",
                wg7:"E09-C-01",
                wg7_value:"400",
                wg8:"E09-C-02",
                wg8_value:"700",
                wg9:"E10-C-01",
                wg9_value:"1400",
                wg10:"E10-C-02",
                wg10_value:"700"
            },
            {
                wg1:"E04-B-01",
                wg1_value:"3200",
                wg2:"E04-B-02",
                wg2_value:"2600",
                wg3:"E05-B-01",
                wg3_value:"4100",
                wg4:"E05-B-02",
                wg4_value:"2100",
                wg5:"E06-B-01",
                wg5_value:"1000",
                wg6:"E06-B-02",
                wg6_value:"2500",
                wg7:"E09-B-01",
                wg7_value:"2900",
                wg8:"E09-B-02",
                wg8_value:"2400",
                wg9:"E10-B-01",
                wg9_value:"3900",
                wg10:"E10-B-02",
                wg10_value:"1900"
            },
            {
                wg1:"E04-A-01",
                wg1_value:"1700",
                wg2:"E04-A-02",
                wg2_value:"1100",
                wg3:"E05-A-01",
                wg3_value:"2600",
                wg4:"E05-A-02",
                wg4_value:"600",
                wg5:"E06-A-01",
                wg5_value:"1100",
                wg6:"E06-A-02",
                wg6_value:"1000",
                wg7:"E09-A-01",
                wg7_value:"1400",
                wg8:"E09-A-02",
                wg8_value:"900",
                wg9:"E10-A-01",
                wg9_value:"2400",
                wg10:"E10-A-02",
                wg10_value:"400"
            }
        ]
        var columnwithDefs = [
            {
                headerName: "",
                field:"wg1",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    
                    else if(params.value == "warehouse")
                    {
                        return ["litepinkbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg1;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg1_value;
                           if(fieldscan>4000)
                            {
                                return ["literedbg","textAlignCenter"]
                            }
                            else if(fieldscan > 1000 && fieldscan <= 4000)
                            {
                                return ["litebluebg","textAlignCenter"]
                            }
                            else
                            {
                                return ["yellowlitebg","textAlignCenter"]
                            }
                            
                        }
                    }
                },
                colSpan: function (params) {
                    if(params.data.wg1 == "gangway")
                    {
                        return 10;
                    }
                    else if(params.data.wg1 == "warehouse")
                    {
                        return 10
                    }
                    else
                    {
                        return 1;
                    }
                }

                
            },
            {
                headerName: "",
                field:"wg2",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg2;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg2_value;
                           if(fieldscan>4000)
                            {
                                return ["literedbg","textAlignCenter"]
                            }
                            else if(fieldscan > 1000 && fieldscan <= 4000)
                            {
                                return ["litebluebg","textAlignCenter"]
                            }
                            else
                            {
                                return ["yellowlitebg","textAlignCenter"]
                            }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg3",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg3;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg3_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg4",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg4;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg4_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg5",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg5;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg5_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg6",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg6;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg6_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg7",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg7;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg7_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg8",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg8;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg8_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg9",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg9;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg9_value;
                           if(fieldscan>4000)
                           {
                               return ["literedbg","textAlignCenter"]
                           }
                           else if(fieldscan > 1000 && fieldscan <= 4000)
                           {
                               return ["litebluebg","textAlignCenter"]
                           }
                           else
                           {
                               return ["yellowlitebg","textAlignCenter"]
                           }
                            
                        }
                    }
                }
            },
            {
                headerName: "",
                field:"wg10",
                cellClass:function(params){
                    //console.log("params ",params)
                    if(params.value == "gangway")
                    {
                        return ["greenbg","textAlignCenter","uppercase"]
                    }
                    else
                    {
                        var vale = params.data.wg10;
                        if(vale == "gangway")
                        {
                            return vale;
                        }
                        else
                        {
                           var fieldscan = params.data.wg10_value;
                            if(fieldscan>4000)
                            {
                                return ["literedbg","textAlignCenter"]
                            }
                            else if(fieldscan > 1000 && fieldscan <= 4000)
                            {
                                return ["litebluebg","textAlignCenter"]
                            }
                            else
                            {
                                return ["yellowlitebg","textAlignCenter"]
                            }
                            
                        }
                    }
                }
            }
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-table cus-i"></i> <span> {this.state.pageTitle} </span>
										
									   <span className="layoutbtns float-right">
										{/* <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button> */}
									</span>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
                                    <div className="col-xl-12 col-lg-12">										
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={rowdata}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                rowSelection={this.state.rowSelection}
                                                
                                            />
                                        </div>
                                       
                                    </div>
                            
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

