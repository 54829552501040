import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var containerslist = [];
var uncheckcontainerslist = [];

export default class ACTLContainers extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "ACTL Containers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowPlannedData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            rowSelection: "multiple",
            autoGroupColumnDef: {
                headerName: "Container No",
                field: "container_no",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              },
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			pickup_plan_date:'',
			showAppBtn:"show-n",
			components: { datePicker: getDatePicker() },			  
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			
		}
	}
	componentDidMount = async() => {
		//console.log("Props ", this.props.match)
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		var urlpath;
        var dpt='';
	   
		//var d = new Date(moment(event).format("YYYY-MM-DD"));
		var c = new Date();
		var e = new Date(moment(c).format("YYYY-MM-DD"));
		var tomorrow = moment.parseZone(c).add(1,"days").format('YYYY-MM-DD');
        //console.log("d ", d.getTime())
		//console.log("tomorrow ", tomorrow)
		//var ddt = moment.parseZone(d).format("x");
		var edt = moment.parseZone(e).format("x");
		//console.log("ddt ", ddt)
		this.setState({
			loadshow:'show-m',
			rowData:''
		});
		
        var startdate = e.getFullYear()+"-"+(e.getMonth() +1)+"-"+(e.getDate()<10?("0"+e.getDate()):e.getDate());
        //console.log("d ", getDDMMYYYY(startdate))
        this.setState({
            pickup_plan_date:getYYYYMMDD(startdate)
        });
        
        

        //console.log("alloycontainers ", alloycontainers.length)
        //icdgarhialloyplandatabydate
        var reqparams={
        }
        var urlpath = '/consignments/actlcontainers';
        redirectURL.post(urlpath,reqparams)
        .then((response) => {
            console.log("response ",response.data)
            var recordsarr = []
            if(response.data.records.length > 0)
            {
                recordsarr = response.data.records
            }
            
            var containerNumbersFromImportsConsignments = [];
            var blFromImportsConsignments = [];
            var recordset = [];
            recordsarr.map(obj =>{
                    containerNumbersFromImportsConsignments.push(obj.container_no)
                    blFromImportsConsignments.push(obj.hbl_no);
                    recordset.push(obj);
			});
			//console.log("recordset" , recordset)
            let queryByContainerNumbers = {
                "container_no": { $in: containerNumbersFromImportsConsignments}
            };
            
            redirectURL.post('/consignments/actlinlandcontainers', {"queryByContainerNumbers":queryByContainerNumbers})
            .then((inlandResponse) => {
				var inlandRecords = inlandResponse.data;
				//console.log("inlandRecords ", inlandRecords)
				if(inlandRecords.length > 0)
				{
					const inlandRecordsRewritten = inlandRecords.map(obj => {
						Object.entries(obj).forEach(
							([key, value]) => {
								let newKey = 'inland_'+key;
									obj[newKey] = value;
									delete(obj[key]) ; // remove original key after renaming
							}
						);
						//console.log("Obj ", obj)
						return obj;
					});
					const inlandRecordsRewrittenBlank = Object.entries(inlandRecordsRewritten[0]).forEach(
						([key, value]) => {
							let blankObj = {};
							let newKey = 'inland_'+key;
							blankObj[newKey] = null; // create a blank object with same new keys
						}
					);
					// merge both results to prepare grid columns
					//console.log('recordset ', recordset)
					for (let i = 0; i < recordset.length; i++) {
						for (let j = 0; j < inlandRecordsRewritten.length; j++) {
							var tt = recordset[i].container_no+ " == "+recordset[i].hbl_no+ " == " +inlandRecordsRewritten[j].inland_bill_of_lading;
							//console.log("tt ", tt)
							if ((recordset[i].container_no == inlandRecordsRewritten[j].inland_container_no) && 
							(recordset[i].hbl_no == inlandRecordsRewritten[j].inland_bill_of_lading)) 
							{
								if(inlandRecordsRewritten[j].inland_source == 'actl')
								{
									recordset[i] = Object.assign(recordset[i], inlandRecordsRewritten[j]);
								}
								
							} else {
								// assign null values for no match
								recordset[i] = Object.assign(recordset[i], inlandRecordsRewrittenBlank);
							}
						}
					}
					
				}

               // console.log("recordset ", recordset)
				var allrecords = [];
				if(recordset.length > 0)
				{
					recordset.map((item) => {
						
						if(item.target_icd == "actl" || item.last_status == undefined)
						{
							if(item.last_status == 'In Transit to MSIL' && item.inland_source == 'actl')
							{
								allrecords.push(item)
							}
							else{
								if(item.last_status != 'In Transit to MSIL')
								{
									if(item.last_status == 'Port Out' && item.inland_source == 'actl')
									{
										allrecords.push(item)
									}
									else{
										if(item.last_status != 'Port Out')
										{
											allrecords.push(item)
										}
									}
								}
							}
						}
						else{
							
							if(item.last_status != 'In ICD Ghari')
							{
								if(item.last_status == 'In Transit to MSIL' && item.inland_source == 'actl')
								{
									allrecords.push(item)
								}
								else{
									if(item.last_status != 'In Transit to MSIL')
									{
										if(item.last_status == 'Port Out' && item.inland_source == 'actl')
										{
											allrecords.push(item)
										}
										else{
											if(item.last_status != 'Port Out')
											{
												allrecords.push(item)
											}
										}
									}
								}
							}
						}
					})
				}
				//console.log('allrecords ', allrecords)
                this.setState({
                    loadshow:"show-n",
                    overly:'show-n',
                    rowData:allrecords
                });

            })
            .catch(function(e){

            })
                
           
        })
        .catch(function(e){

        })
        
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	//    this.gridApi.forEachLeafNode( (node) => {
	// 		if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != '0000-00-00' && node.data.pickup_plan_date != undefined) {
	// 			node.setSelected(true);
	// 		}
	// 	});
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   this.gridApi.forEachLeafNode( (node) => {
			   //console.log(node)
				if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != undefined) {
					node.setSelected(true);
				}
			});
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false,
            pickup_plan_date:''
        });
    }
   
    onRowSelected(event) {
        //console.log("row " + event.node.data.container_no+ " selected = " + event.node.selected);
       // var items=[];
	   var rowCount = event.api.getSelectedNodes();
	  
	   containerslist=[];
	   if(event.node.selected == false)
	   {
		uncheckcontainerslist.push(event.node.data.container_no)
	   }
       //console.log("rowCount ", rowCount)
       
	   
        rowCount.map((item) => {
            containerslist.push(item.data.container_no)
        })
			
		//console.log("checkcontainerslist ", this.state.containerslist)
		//console.log("uncheckcontainerslist ", uncheckcontainerslist)
		if(containerslist.length > 0)
		{
			this.setState({
				showAppBtn:'show-m'
			});
		}
        else{
			if(uncheckcontainerslist.length > 0)
			{
				this.setState({
					showAppBtn:'show-m'
				});
			}
			else
			{
				this.setState({
					showAppBtn:'show-n'
				});
			}
		}
      }
   
    formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedApplyChanges,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
        if(this.state.pickup_plan_date != '' && this.state.pickup_plan_date != undefined)
        {
			var pickdate=this.state.pickup_plan_date+" 23:59:00";
			var d = new Date(moment(pickdate).format("YYYY-MM-DD"));
			var ed = new Date();
			var c = new Date(moment(ed).format("YYYY-MM-DD"));
			//console.log("pickdate D", d.getTime())
			//console.log("pickdate C", c.getTime())
            if(d.getTime() >= c.getTime())
            {
                if(containerslist.length > 0 || uncheckcontainerslist.length > 0)
                {
					var pplant = this.state.pickup_plan_date;
					var pickplandate = pplant.split("/");
					var pdate =  pickplandate[0]+"-"+pickplandate[1]+"-"+pickplandate[2]
                    var reqparams= {
						containerslist:containerslist,
						unchecklist:uncheckcontainerslist,
                        pickup_plan_date:pdate
                    }
                    //console.log("formdata ", formdata)
                    var urlpath = '/container/updateICDDispacth';
                
                    redirectURL.post(urlpath,reqparams)
                    .then((response) => {
                        var records = JSON.parse(JSON.stringify(response.data));
                        console.log("records", records);
                       
                        this.setState({
                            loadshow:'show-n'			
                        })
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Please select atleast one container from grid",
                        basicType:"danger",
						showAppBtn:'show-n'
                        
                    });
                }
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Previous date is not allowed",
                    basicType:"danger",
                    pickup_plan_date:'',
					showAppBtn:'show-n'
                    
                });
            }
            /*
            
            */
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Previous date is not allowed",
                basicType:"danger",
                pickup_plan_date:'',
				showAppBtn:'show-n'
                
            });
        }
    }

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'icd garhi plan';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
		});

		var rowCount = this.gridApi.getSelectedNodes();
		console.log("rowCount ", rowCount)
        var assignarr=[];
		rowCount.map((dt) => {
			var item = dt.data;
			//console.log("item ", item)
			var cdate =new Date();
			var currentDate = getDDMMYYYYHHMMSS(cdate);
            if(item.inland_smtp_handover_date != undefined || item.inland_boe_no != undefined
                || item.inland_boe_date != undefined || item.inland_departure_to_grfl_date != undefined
                || item.inland_transit_mode != undefined || item.inland_line_voyage_no != undefined
                || item.inland_grfl_arrival_date != undefined || item.inland_coc_date != undefined
				|| item.inland_handover_date != undefined || item.inland_dispatch_date != undefined
				|| item.inland_trailer_no != undefined
            )
			{
				assignarr.push({
                    inland_smtp_handover_date:item.inland_smtp_handover_date,
                    inland_boe_no:item.inland_boe_no,
                    inland_boe_date:item.inland_boe_date,
                    inland_departure_to_grfl_date:item.inland_departure_to_grfl_date,
                    inland_transit_mode:item.inland_transit_mode,
                    inland_line_voyage_no:item.inland_line_voyage_no,
                    inland_grfl_arrival_date:item.inland_grfl_arrival_date,
                    inland_coc_date:item.inland_coc_date,
                    inland_handover_date:item.inland_handover_date,
					inland_dispatch_date:item.inland_dispatch_date,
					inland_trailer_no:item.inland_trailer_no,
                    container_no:item.container_no,
                    mbl_no:item.mbl_no,
                    hbl_no:item.hbl_no,
                    searates_pod:item.searates_pod,
                    last_status:item.last_status,
                    searates_etd_pol:item.searates_etd_pol,
                    searates_atd_pol:item.searates_atd_pol,
                    searates_eta_pod:item.searates_eta_pod,
                    searates_ata_pod:item.searates_ata_pod,
                    country_code:item.country_code,
                    supplier_code:item.supplier_code,
					booking_no:item.booking_no,
					empty_returned_date : item.empty_return_date,
					msil_gate_in : item.msil_gate_in
                })
			}
			
        })
        
		//console.log("itemsToUpdate " , itemsToUpdate)
		//console.log("assignarr " , assignarr)

		if(assignarr.length > 0)
		{
			var urlpath = '/consignments/updateactlcontainers';
			var reqparams = {
				updateData:assignarr
			} 
			 redirectURL.post(urlpath,reqparams)
			 .then((response) => {
				 //console.log("Response ", response)
				 var response = response.data;
				 if(response.status == 'success')
				 {
					 this.setState({
						 loadshow:'show-n',
						 show:true,
						 basicTitle:"Successfully updated",
						 basicType : "success"
					 })
				 }
				 else
				 {
					 this.setState({
						 loadshow:'show-n',
						 show:true,
						 basicTitle:"Failed",
						 basicType : "danger"
					 })
				 }
				 
			 })
			 .catch(function (error) {
				 console.log(error);
			 });
		}
		else{
			this.setState({
				loadshow:'show-n',
				show:true,
				basicTitle:"Please select checkbox",
				basicType : "warning"
			})
		}
	  
		
	}
	
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
            	
            {
                headerName: "Container No",
                field: "container_no",
                width: 140,
				filter: true,
				pinned:'left',
                resizable: true,
				editable:false,
				headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
            },
            
            {
                headerName: "MBL NO",
                field: "mbl_no",
                width: 140,
                filter: true,
				pinned:'left',
                resizable: true,
                editable:false
            },
            {
                headerName: "HBL NO",
                field: "hbl_no",
                width: 140,
                filter: true,
				pinned:'left',
                resizable: true,
                editable:false
            },
            {
                headerName: "Searates POD",
                field: "searates_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Status",
                field: "last_status",
                width: 140,
                filter: true,
                resizable: true,
				editable:false,
				valueGetter:function(params){
					if(params.data.last_status == "Port Out")
					{
						return "In Transit to ICD";
					}
					else
					{
						return params.data.last_status;
					}
				}
            },
            {
                headerName: "ETD POL",
                field: "searates_etd_pol",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_etd_pol != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_etd_pol);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ATD POL",
                field: "searates_atd_pol",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_atd_pol != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_atd_pol);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ETA POD",
                field: "searates_eta_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_eta_pod != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_eta_pod);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ATA POD",
                field: "searates_ata_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_ata_pod != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_ata_pod);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Country",
                field: "country_code",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Supplier",
                field: "supplier_code",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Booking NO",
                field: "booking_no",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            
            /*Editable Start*/
            {
                headerName: "SMTP Handover",
                field: "inland_smtp_handover_date",
                width: 150,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                   // console.log("params ", params.data);
                    if (params.data.inland_smtp_handover_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_smtp_handover_date);
                    }
                    else
                    {
                        return "";
                    }
                },
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
            {
                headerName: "BOE No",
                field: "inland_boe_no",
                width: 100,
                editable:true,
                filter: "agSetColumnFilter",
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                resizable: true
               
            },
            {
                headerName: "BOE Date",
                field: "inland_boe_date",
                width: 100,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_boe_date == '1753-01-01')
                    {
                        return "";
                    }
                    else
                    {
                        if (params.data.inland_boe_date != null){
                            return getHyphenDDMMMYYYY(params.data.inland_boe_date);
                        }
                        else{
                            return "";
                        }
                    }

                },
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
            {
                headerName: "Departure to ICD",
                field: "inland_departure_to_grfl_date",
                width: 150,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_departure_to_grfl_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_departure_to_grfl_date);
                    }
                    else
                    {
                        return "";
                    }
                },
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },

            {
                headerName: "Transit Mode",
                field: "inland_transit_mode",
                width: 100,
                editable:true,
                filter: "agSetColumnFilter",
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                resizable: true
            },
            {
                headerName: "Train/Truck No",
                field: "inland_line_voyage_no",
                width: 100,
                editable:true,
                filter: "agSetColumnFilter",
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                resizable: true
            },
            
            {
                headerName: "ICD Arrival",
                field: "inland_grfl_arrival_date",
                width: 150,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_grfl_arrival_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_grfl_arrival_date);
                    }
                    else
                    {
                        return "";
                    }
                },
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
            {
                headerName: "COC Date",
                field: "inland_coc_date",
                width: 150,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_coc_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_coc_date);
                    }
                    else
                    {
                        return "";
                    }
                },
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },					
            {
                headerName: "Handover to ICD/TPT",
                field: "inland_handover_date",
                width: 180,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_handover_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_handover_date);
                    }
                    else
                    {
                        return "";
                    }
				},
				
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
            {
                headerName: "Dispatch from ICD",
                field: "inland_dispatch_date",
                width: 180,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.inland_dispatch_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.inland_dispatch_date);
                    }
                    else
                    {
                        return "";
                    }
                },
                
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
			{
				headerName: "Trailer No",
				field: "inland_trailer_no",
				width: 150,
				editable:true,
				filter: "agSetColumnFilter",
				resizable: true,
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
				
			},
			{
                headerName: "MSIL Gate In",
                field: "msil_gate_in",
                width: 180,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.msil_gate_in != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.msil_gate_in);
                    }
                    else
                    {
                        return "";
                    }
                },
                
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
            },
			{
                headerName: "Empty Return Date",
                field: "empty_return_date",
                width: 180,
                editable:true,
                resizable: true,
                valueGetter:function(params){
                    //console.log("params ", params);
                    if (params.data.empty_return_date != null)
                    {
                        return getHyphenDDMMMYYYY(params.data.empty_return_date);
                    }
                    else
                    {
                        return "";
                    }
                },
                
				headerClass:"cellstylegrid",
				cellClass:'cellstylegrid',
                cellEditor: DateEditor
			},
			
        ];

		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
										
									   <span className="layoutbtns float-right">
										{/* <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button> */}
									</span>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
                                    <div className="col-xl-12 col-lg-12">										
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                //sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                rowSelection={this.state.rowSelection}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                suppressRowClickSelection={true}
                                                //floatingFilter={true}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                //onCellClicked={this.popmarker}
                                            />
                                        </div>
                                        <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button>
										
                                    </div>
                            
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};