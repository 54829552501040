import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class TrucksReturnToPlant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            oldTruckNo: "",
            truckNumList: "",
            loadshow: 'show-n',
            truckLocation: "",
            replaced_date_time: "",
            route_details: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            route_info: "",
            rowData: null,
            animateRows: true,
            consignment_details: "",
            dept_code: "",
            replacement_truck: "",
            pagetitle: "",
            showonlytransport: 'show-m',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            from_date: moment.parseZone(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
            to_date: moment.parseZone(new Date()).format("YYYY-MM-DD")
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount() {
        this.loadCustomerToPlant()
    }

    loadCustomerToPlant = () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var reqParams = {
            from_date: this.state.from_date,
            to_date: this.state.to_date
        }

        redirectURL.post("/consignments/getTrucksReturntoPlantDetails", reqParams)
            .then((response) => {
                var records = response.data.records;
                if (records.length > 0) {

                    this.setState({
                        loadshow: 'show-n',
                        rowData: records,
                    });
                }
                else {
                    this.setState({
                        loadshow: 'show-n',
                        rowData: [],
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });

        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                await this.setState({
                    usergridstate: resp.data
                });
                await this.restoreGridStates();
            })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }


    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    onRowSelection(event) {
        var containerslist = [];
        //console.log("rowselect", event)
        var rwCount = event.api.getSelectedNodes();
        //console.log("rwCount ", rwCount)
        //var containerlist=[];
        console.log("rowselect", event.data)
        var rowCount = event.data
        //console.log("rowCount ", rowCount)

        rwCount.map((item) => {
            containerslist.push(item.data.truck_no)
        });

        this.setState({
            containerslist: containerslist
        })
        // console.log("checkedlist ", containerslist)

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }



    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = '';

        if (this.props.match.path == "/sndtranshipmentreports") {
            screenpage = 'snd transhipment reports';
        }
        else if (this.props.match.path == "/prttranshipmentreports") {
            screenpage = 'prt transhipment reports';
        }
        else if (this.props.match.path == "/tnptranshipmentreports") {
            screenpage = 'tnp transhipment reports';
        }
        else if (this.props.match.path == "/railtranshipmentreports") {
            screenpage = 'rail transhipment reports';
        }

        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        //console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    restoreGridStates() {

        if (this.state.usergridstate.length > 0) {
            var windowstates = this.state.usergridstate;
            this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
            this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
            this.gridApi.setSortModel(windowstates[0].gridcolsort);
            this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

        }
    }

    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })

    };


    handlerStartDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var from_date = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())

        if (from_date <= this.state.to_date) {
            this.setState({
                from_date: from_date,
            });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'From date should be less than or equal To date',
                basicType: "success",
                loadshow: "show-n",
                overly: "show-n",
            })
        }
    }

    handlerEndDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var to_date = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (to_date >= this.state.from_date) {
            this.setState({
                to_date: to_date
            });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'To date should be greater than or equal to From date',
                basicType: "success",
                loadshow: "show-n",
                overly: "show-n",
            })
        }
    }

    filterDataByFromAndToDate = (event) => {
        event.preventDefault()
        this.loadCustomerToPlant()
    }

    render() {
        var columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Truck Type",
                field: "truck_type",
                width: 120,
                filter: true,
                resizable: true,
               
            },
            {
                headerName: "Customer Reached On",
                field: "left_dealer_marked",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.left_dealer_marked == "") {
                        return "N/A";
                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
                    }
                },
                comparator: dateComparator,
            },
            {
                headerName: "Return to Plant",
                width: 150,
                field: "return_to_plant_at",
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.return_to_plant_at != '' && params.data.return_to_plant_at != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.return_to_plant_at);
                    }
                    else {
                        return "NA";
                    }
                },
                comparator: dateComparator,

            },
            {
                headerName: "Return to Plant in (Days)",
                width: 150,
                field: "",
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // if (params.data.return_to_plant_at != undefined && params.data.return_to_plant_at != '' && params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != '') {
                    //     var left_dealer_marked = params.data.left_dealer_marked;
                    //     var return_to_plant_at = params.data.return_to_plant_at;
                    //     if(params.data.return_to_plant_at.includes(".") == true)
                    //     {
                    //         return_to_plant_at = return_to_plant_at.split(".")[0];
                    //     }
                    //     if(params.data.left_dealer_marked.includes(".") == true)
                    //     {
                    //         left_dealer_marked = left_dealer_marked.split(".")[0];
                    //     }
                    //     const fromDate = moment(left_dealer_marked, "YYYY-MM-DD HH:mm:ss");
                    //     const toDate = moment(return_to_plant_at, "YYYY-MM-DD HH:mm:ss");  
                    //     const differenceInMinutes = toDate.diff(fromDate, 'minutes');                   
                    //     // console.log(differenceInMinutes, "hours");                   
                    //     const differenceInDays =  differenceInMinutes / (24 * 60); // Convert minutes to hours
                    //     // console.log(differenceInHours, "hours");
                    //     // const differenceInDays = differenceInHours/24; 
                    //     if(differenceInDays.toString().includes(".") == true)
                    //     {              
                    //         if(parseFloat(differenceInDays) > 1)
                    //         {
                    //             return Math.round(parseFloat(differenceInDays));
                    //         }
                    //         else
                    //         {
                    //             return differenceInDays.toFixed(2);
                    //         }
                    //     }
                    //     else
                    //     {
                    //         return differenceInDays;
                    //     }
                    // }

                    var ste = params.data.left_dealer_marked;
                    var stsec = moment.parseZone(ste).format("x");
                    if(params.data.return_to_plant_at != "" && params.data.return_to_plant_at != undefined){
                        if(params.data.left_dealer_marked < params.data.return_to_plant_at){
                            var etsec = moment.parseZone(params.data.return_to_plant_at).format("x")
                            var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                            // console.log(diff,"line 2047")
                            var timespent = secondsToDhms(diff);
                            return timespent;
                        }
                        else{
                            return "0 Days 0 Hrs 0 Mins";
                        }
                        
                    }
                    else{
                        return "Not Available";
                    }
                }

            },
            {
                headerName: "Last Shipment ID",
                field: "shipment_id",
                width: 120,
                filter: true,
                resizable: true,
            },

            {
                headerName: "Last Shipment Time",
                field: "shipment_time",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.shipment_time == "") {
                        return "N/A";
                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.shipment_time)
                    }
                },
                comparator: dateComparator,
            },
            {
                headerName: "Customer Name",
                width: 140,
                field: "consignee_name",
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer State",
                width: 120,
                field: "consignee_state",
                filter: true,
                resizable: true,
            },

            {
                headerName: "Customer City",
                field: "transporter_zone",
                filter: true,
                resizable: true,
                windth:120,
            },
            {
                headerName: "Transporter Name",
                
                field: "transporter_name",
                filter: true,
                resizable: true,
            },


        ]



        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="col-xl-12 col-lg-12">
                    <div className='card'>
                        <div className="card-header">
                            <div className="row col-xl-12 col-lg-12">
                                <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>From Date</label>
                                        {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                        <Datetime
                                            value={this.state.from_date}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: 'From Date',
                                                name: 'plan_month',
                                                autoComplete: 'off',
                                                readOnly: "readOnly"
                                            }}
                                            dateFormat="YYYY-DD"
                                            name="plan_month"
                                            onChange={this.handlerStartDate.bind(this)}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>To Date</label>
                                        {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                        <Datetime
                                            value={this.state.to_date}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: 'To Date',
                                                name: 'plan_month',
                                                autoComplete: 'off',
                                                readOnly: "readOnly"
                                            }}
                                            dateFormat="YYYY-DD"
                                            name="plan_month"
                                            onChange={this.handlerEndDate.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>&nbsp;</label><br />
                                        <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByFromAndToDate.bind(this)}>Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Trucks Returned to Plant from the Customer</span>
                            </h5>
                        </div>


                        <div className="card-body pt-15px" >
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">

                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>
                                <div className="col-xl-12 col-lg-12">
                                    {/* {this.state.rowData != null ? */}
                                    <div id="myGrid" style={{ width: "100%", height: "500px" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            rowSelection={this.state.rowSelection}
                                            // frameworkComponents={this.state.frameworkComponents}
                                            // detailCellRendererParams={this.state.detailCellRendererParams}
                                            //editType={this.state.editType}
                                            stopEditingWhenGridLosesFocus={true}
                                            //floatingFilter={true}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            //onCellClicked={this.onRowClicked.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onRowSelected={this.onRowSelection.bind(this)}
                                            suppressRowClickSelection={true}
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
