import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentImportsCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            records:[],
            allRowDatacount:0,
            highseascount:0,
            highseasinfo:'',
    		inportcount:0,
            inportinfo:'',
            intransiticdcount:0,
            intransittoicdinfo:'',
            inicdcount:0,
            inicdinfo:'',
            transitmsilcount:0,
            emptyreturned:0,
            countertype:''
    	}
    
    }
    componentWillReceiveProps(newprops){
        //console.log("newprops ", newprops)
       this.setState({
            allRowDatacount:newprops.allRowDatacount,
            highseascount:newprops.highseascount,
            highseasinfo:newprops.highseasinfo,
            inportcount:newprops.inportcount,
            inportinfo:newprops.inportinfo,
            intransiticdcount:newprops.intransiticdcount,
            intransittoicdinfo:newprops.intransittoicdinfo,
            inicdcount:newprops.inicdcount,
            inicdinfo:newprops.inicdinfo,
            transitmsilcount:newprops.transitmsilcount,
            emptyreturned:newprops.emptyreturned,
            countertype:newprops.countertype
       })
      
    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    onClickSubCounter(data){
        this.props.context.onClickSubCounterShowData(data)
    }
    render(){

        if(this.state.highseasinfo.delays != undefined)
        {
            var highseastotl=this.state.highseasinfo.total.length;
            var highseasDelay=this.state.highseasinfo.delays.length;
            var highseasNoDelay=this.state.highseasinfo.nodelays.length;
        }
        else{
            var highseastotl = 0;
            var highseasDelay = 0;
            var highseasNoDelay = 0;
        }
        if(this.state.inportinfo.delays != undefined)
        {
            var inportDelay=this.state.inportinfo.delays.length;
        }
        else{
            var inportDelay = 0;
        }


        if(this.state.intransittoicdinfo.delays != undefined)
        {
            var intransittoicdDelay=this.state.intransittoicdinfo.delays.length;
        }
        else{
            var intransittoicdDelay = 0;
        }


        if(this.state.intransittoicdinfo.total != undefined)
        {
            var intransittoicdTotal=this.state.intransittoicdinfo.total.length;
        }
        else{
            var intransittoicdTotal = 0;
        }

        if(this.state.inicdinfo.delays != undefined)
        {
            var inicdDelay=this.state.inicdinfo.delays.length;
        }
        else{
            var inicdDelay = 0;
        }

//var inportDelay=0;

        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body n-p-0">
                        <div className="crm-numbers pb-0">
                            {(this.state.countertype != 'deliver')?
                            <div className="row">
                               {/*ALL*/}
                                <div className="col cirlce-d cpointer" onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13"><i className="icofont icofont-ship-alt txt-info f24"></i><br /> All</span>
                                    <h5 className="txt-info f35">
                                        <span className="counter">
                                            <CountUp end={(this.state.allRowDatacount)?this.state.allRowDatacount:0}/>
                                        </span>
                                    </h5>
                                    
                                </div>
                               {/*High Seas*/}
                                <div className="col cirlce-d cpointer">
                                    <span className="f13"><i className="icofont icofont-ship-alt txt-info f24"></i><br /> High Seas</span>
                                    <h5 className="txt-info f35">
                                        <span className="counter" onClick={this.onClickSubCounter.bind(this,1)}>
                                            
                                            <CountUp className="txt-danger" end={(highseasDelay != '')?highseasDelay:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,1)}>
                                            <CountUp end={(this.state.highseascount != '')?this.state.highseascount:0}/>
                                        </span>    
                                        
                                    </h5>
                                    
                                </div>

                                {/*In Port*/}
                                <div className="col cpointer">
                                    <span className="f13"><i className="icofont icofont-ship f24 txt-success"></i><br /> In Port</span>
                                      <h5 className="txt-success f35">
                                        <span className="counter" onClick={this.onClickSubCounter.bind(this,2)}>
                                            
                                            <CountUp className="txt-danger" end={(inportDelay != '')?inportDelay:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,2)}>
                                            <CountUp end={(this.state.inportcount)?this.state.inportcount:0}/>
                                        </span>    
                                        
                                    </h5>
                                </div>

                                {/*In Tranist to ICD Garhi*/}
                                <div className="col cpointer">
                                    <span className="f13"><i className="icofont icofont-train-line f22 txt-danger"></i><br /> In Transit to ICD </span>
                                    <h5 className="txt-danger f35">
                                        <span className="counter" onClick={this.onClickSubCounter.bind(this,3)}>
                                            
                                            <CountUp className="txt-danger" end={(intransittoicdDelay != '')?intransittoicdDelay:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter purple" onClick={this.onClickCounter.bind(this,3)}>
                                            <CountUp end={(intransittoicdTotal)?intransittoicdTotal:0}/>
                                        </span>    
                                        
                                    </h5>
                                </div>

                                {/*Inside ICD Garhi*/}
                                <div className="col cpointer">
                                    <span className="f13"><i className="icofont icofont-ui-home f24 txt-secondary"></i><br /> Inside ICD </span>
                                     <h5 className="txt-secondary f35">
                                        <span className="counter" onClick={this.onClickSubCounter.bind(this,4)}>
                                            
                                            <CountUp className="txt-danger" end={(inicdDelay != '')?inicdDelay:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,4)}>
                                            <CountUp end={(this.state.inicdcount)?this.state.inicdcount:0}/>
                                        </span>    
                                        
                                    </h5>
                                </div>

                                {/*In Transit to MSIL*/}
                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,5)}>
                                    <span className="f13"><i className="icofont icofont-truck-alt f22 txt-success"></i><br /> In Transit to MSIL</span>
                                    <h4 className="txt-success f35"><span className="counter"><CountUp end={(this.state.transitmsilcount)?this.state.transitmsilcount:0}/></span></h4>
                                   
                                </div>
                                
                                
                            </div>
                            :
                            <div className="row">
                                <div className="col cpointer" onClick={this.onClickCounter.bind(this,6)}>
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f22 txt-success"></i><br /> Empty Returned</span>
                                    <h4 className="txt-success f35"><span className="counter"><CountUp end={(this.state.emptyreturned)?this.state.emptyreturned:0}/></span></h4>
                                
                                </div>
                            </div>
                            }
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






