import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentExceptionCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		deptcode:this.props.deptcode,
            triptype:this.props.triptype,
            probableAccident : 0,
            notransittime : 0,
            nodealercoords : 0,
            hidecol:true
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{
        if(newprops.deptcode != this.state.deptcode)
        {
        //console.log("Conters ",newprops.deptcode)
            if(newprops.triptype && newprops.triptype != 0)
            {
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode,
                    trip_type:newprops.triptype
                }
            }
            else{
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode
                }   
            }
            
            /*Venkatesh Code*/
            if(newprops.gridData.length > 0)
            {
               
                /*New Code for Counters*/
                var counter = newprops.countersjson;
                var probableAccident =0;
                var notransittime = 0;
                var nodealercoords = 0;
                try{
                    if(counter.probableAccident.length > 0)
                    {
                        var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    }
                }catch(e){}
                try{
                    if(counter.notransitTime.length > 0)
                    {
                        var notransittime = counter.notransitTime[0].notransitTimeCount;
                    }
                }catch(e){}
                
                try{
                    if(counter.nodealer.length > 0)
                    {
                        var nodealercoords = counter.nodealer[0].nodealerCount;
                    }
                }catch(e){}
                
                await this.setState({
                    active_consignments:newprops.gridData.length,
                    probableAccident : probableAccident,
                    notransittime : notransittime,
                    nodealercoords : nodealercoords
                });
            }
            else{
                await this.setState({
                    probableAccident : 0
                }); 
            }
            this.setState({
                hidecol:newprops.hide
            });
        }
        

    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '')?this.state.active_consignments:0}/></span></h4>
                                    
                                </div>
                                    {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"probable_accident")}>
                                        <span className="f13"><i className="icofont icofont-barricade f22 txt-danger"></i><br />Probable Accident</span>
                                        <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.probableAccident != '')?this.state.probableAccident:0}/></span></h4>                                   
                                    </div> */}
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_dealer_coords")}>
                                    <span className="f13"><i className="icofont icofont-user f22 txt-info"></i><br /> Dealers without Coordinates</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.nodealercoords != '')?this.state.nodealercoords:0}/></span></h4>                                   
                                </div>
                               <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_transit_times")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 txt-warning"></i><br /> Dealers without Transit Times</span>
                                    <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.notransittime != '')?this.state.notransittime:0}/></span></h4>                                   
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}






