import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

export default class DeleteAction extends Component {
	deleteItem = (e) => {
		console.log(e.data);
		console.log(this.props.actionCall+" , "+this.props.actionMethod);
		var actionCall=this.props.actionCall;
		var actionMethod=this.props.actionMethod;
		var fdata = {
	    		rownode:e.data
	    }
	    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				console.log(response.data);
				let deletedRow = this.props.node.data;
		        e.gridApi.updateRowData({ remove: [deletedRow] })
			}
		)
		.catch(function(error){
			console.log(error);
		});
		
		
	}
    render() {
        return (
            <button onClick={() => this.deleteItem(this.props.node)} className="custom-btn label label-success"><i className="icofont icofont-trash"></i> Delete </button>
        );
    }
}