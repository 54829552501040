import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class LineComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	//console.log(this.props.lineseries);
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
        		
        		    
        		title: {
        	        text: ''
        	    },


        	    yAxis: {
        	        title: {
        	            text: 'Number of Employees'
        	        }
        	    },
        	    legend: {
        	        layout: 'vertical',
        	        align: 'right',
        	        verticalAlign: 'middle'
        	    },

        	    plotOptions: {
        	        series: {
        	            label: {
        	                connectorAllowed: false
        	            },
        	            pointStart: 2010
        	        }
        	    },

        	    series: this.props.lineseries,

        	    responsive: {
        	        rules: [{
        	            condition: {
        	                maxWidth: 500
        	            },
        	            chartOptions: {
        	                legend: {
        	                    layout: 'horizontal',
        	                    align: 'center',
        	                    verticalAlign: 'bottom'
        	                }
        	            }
        	        }]
        	    }
      		};
        
        return ( 
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>{this.props.lineTitle}</h5>
                        </div>
                        <div className="card-body">
                            <div className="dashboard-chart-container sales-overview-chart">
                            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

                            </div>
                        </div>
                    </div>
                </div>
			);
		}
}












