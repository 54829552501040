/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ComponentIndex from '../common/appendComponents';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CSVFileValidator from 'csv-file-validator';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpitrucktransitdelayaction';
import kpiDayWiseDistanceActions from '../manage/kpiDayWiseDistanceActionsComponent';
import RouteMapForDayWiseDistanceTrip from '../manage/routeMapForDayWiseDistanceTrip';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import CommentActions from "../layouts/commentsComponent";
import RoutemapTransitAction from '../manage/routemaptransit';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import TruckConsignmentLegs from '../manage/truckConsignmentLegs';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import $ from "jquery";
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Counters from './consignmenttruckscountersComponent';
import DateFormater from './dateComponent';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import LoadingAreaLegs from "../amns/loadingarealegs";
import ShipmentLegs from "../amns/shipmentlegs";
import LegRouteMap from "../amns/legrouteforactivetruck";
import { zIndex } from 'material-ui/styles';
var infoBox = require('../common/google-infowindow');

var Constant = require('../common/Constant');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ManageTruckConsignments extends Component {
	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			originalData: [],
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			mapinfo: '',
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			sliderBulkForceClose:"",
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			pivotmodeis: false,
			sliderCommentTranslate: "",
			commentsRowData: [],
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			slideBar: "",
			truckInfo: "",
			consignments: "",
			currenttrucklocation: "",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rownode: [],
			rowData: [],
			maprowData: [],
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				truckConsignmentLegs: TruckConsignmentLegs,
				dateFormater: DateFormater,
				gps: Gps,
				consignmentforceclose:Consignmentforceclose,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				LoadingAreaLegs:LoadingAreaLegs,
				ShipmentLegs:ShipmentLegs
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			selectedReason:"",
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: '',
			tripvalue: '',
			consigner: '',
			consigner_coordinates: [],
			consignee_coordinates: [],
			pagecounters: '',
			startDate: '',
			containerslist: [],
			endDate: '',
			defaultsdate: '',
			defaultedate: '',
			tolls: [],
			loginplant: "",
			filterCities: [],
			filterCustomers: [],
			filterStates: [],
			filterZones: [],
			filterTransporters: [],
			filterTransportersZones: [],
			flCity: "",
			flCustomer: [],
			flState: "",
			flZone: "",
			flTransporter: "",
			flTransporterZone: "",
			movementtype: { "value": "all", "label": "All" },
			plants: [],
			selectedFilter: "",
            usergridstate:[],
			screenurl: "",
			screentitle: "",
            map_type_of_tracking: "",
			filterNames: "Truck / Shipment No/ Invoice No",
			filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "Shipment No", value: "shipment_id" }, { label: "Invoice", value: "sales_order" }],
			rowIds : [],
			priority : 0,
			priorityBtnShow : 'show-m',
			alltrucksBtnShow : 'show-n',
			isunmarkpriority:true,
			selectedReason:"",
			deliveryDate:"",
			displayReasonField:"show-n"

		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
		this.onShowShipmentLegRouteMap = this.onShowShipmentLegRouteMap.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		// var checkerData;
		// if(this.props.data)
		// {
		// 	checkerData="Yes";
		// 	this.onPropsApply(this.props.data);
		// }
		// else{
		// 	checkerData="No";
		// 	this.onPropsApply("testing success");
		// }
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) { }
	}
	componentDidMount() {
		loadDateTimeScript();
		console.log(localStorage.getItem("roles").indexOf("SITE_ADMIN"),localStorage.getItem("roles"),"rolessss")
		if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0)
		{
			this.setState({
				sideBar: {
					toolPanels: [
						{
							id: "columns",
							labelDefault: "Columns",
							labelKey: "columns",
							iconKey: "columns",
							toolPanel: "agColumnsToolPanel"
						},
						{
							id: "filters",
							labelDefault: "Filters",
							labelKey: "filters",
							iconKey: "filter",
							toolPanel: "agFiltersToolPanel"
						}
					]
				}
			});
		}
		if(localStorage.getItem("user_type") == "CUSTOMER")
		{
			this.setState({
				filterNames: "Truck / Invoice No",
				filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "Invoice", value: "sales_order" }]
			});
		}
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}
		if(localStorage.getItem("pc") != "HZ")
		{
			this.setState({
				isunmarkpriority : false,
				priorityBtnShow : "show-n"
			});
		}
		var urlpath;
		var dpt;
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		if (this.props.match.path == "/sndtrucks") {
			urlpath = '/trucks/sndtrucks';
			dpt = 'SNDG';
		}
		else if (this.props.match.path == "/prttrucks") {
			urlpath = '/trucks/prttrucks';
			dpt = 'LOG-PRT';
		}
		else if (this.props.match.path == "/tnptrucks") {
			urlpath = '/trucks/tnptrucks';
			dpt = 'LOG-TNP';
		}
		else {
			urlpath = '/trucks';
			dpt = 'all';
		}
		var edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');

		//console.log("Props ", this.props.match)
		//filters data
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		redirectURL.post("/consignments/plants", { dept_code: (dpt) })
			.then(async(resp) => {
				let responseData = resp.data;
				if(responseData.length > 0)
				{
					responseData = await distinctArrayBy(responseData,"plant_code");
				}
				this.setState({
					plants: responseData
				});
			})
			console.log(localStorage.getItem('roles'),"line 395")
		redirectURL.post("/consignments/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: encode(dpt),email:localStorage.getItem('email'),role:localStorage.getItem('roles'),customer_code:localStorage.getItem('customer_code'),consigner_code:localStorage.getItem('pc')})
			.then((resp) => {
				console.log("responseforDropDown", resp.data)
				var transporters = resp.data.transportersList;
				var data = resp.data.data
				let tlist = [];
				tlist.push({
					"value": "",
					"label": "All",
				});
				transporters.map((t) => {
					tlist.push({
						"value": t.transporter_code,
						"label": t.transporter_name,
					});
				});
				var transportersZones = data.transportersZonesList;
				let filterTransportersZones = [];
				filterTransportersZones.push({
					"value": "",
					"label": "All",
				});
				if(transportersZones !=undefined && transportersZones !="")
				{
					transportersZones.map((t) => {
						if(t !="" && t !=undefined && t !="null" && t !=null)
						{
							filterTransportersZones.push({
								"value": t,
								"label": t,
							});
						}
					});
				}
				var cities = data.cities;
				var filterCities = [];
				if (cities.length > 0) {
					filterCities.push({
						label: "All",
						value: "",
					});
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var customers = data.customers;
				var filterCustomers = [];
				if (customers.length > 0) {
					filterCustomers.push({
						label: "All",
						value: "",
					});
					customers.map(function (c) {
						filterCustomers.push({
							label: c.customer_code+" - "+c.customer_name,
							value: c.customer_code,
						});
					});
				}
				var states = data.states;
				var filterStates = [];
				if (states.length > 0) {
					filterStates.push({
						label: "All",
						value: "",
					});
					states.map(function (s) {
						filterStates.push({
							label: s,
							value: s,
						});
					});
				}
				var zones = data.zones;
				var filterZones = [];
				
				var transporters = data.transporters;
				var filterTransporters = [];
				if (transporters.length > 0) {
					filterTransporters.push({
						label: "All",
						value: ""
					});
					transporters.map(function (t) {
						filterTransporters.push({
							label: t,
							value: t,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterCustomers: filterCustomers,
					filterStates: filterStates,
					filterZones: filterZones,
					filterTransporters: tlist,
					filterTransportersZones: filterTransportersZones,
				});
			})



		this.setState({
			loadshow: 'show-m',
			//startDate: sdate,
			//endDate: edate,
			deptcode: dpt,
			defaultsdate: sdate,
			defaultedate: edate
		});

		var reqparams = {
			dept_code: dpt,
			startDate: sdate,
			endDate: edate,
			// consigner_code: ["ALL"],
			//from_date: sdate,
			//to_date: edate
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = [localStorage.getItem("pc")];
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = ["ALL"];
			}
		}catch(e){
			
		}
		var activeconsignemntarr = [];
		var activetrucksarr = [];
		var transitdelaysarr = [];
		var gpsdatanaarr = [];
		var overspeedtrucksarr = [];
		var nightdrivearr = [];
		var alltrucksarr = [];

		// try {
		// 	var userrols = localStorage.getItem("roles");
		// 	var splitrole = userrols.split(",")
		// 	if (splitrole.length > 0) {
		// 		if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true) {
		// 			reqparams.transporter_codes = localStorage.getItem("transportercode")
		// 		}
		// 	}
		// }
		// catch (e) {

		// }
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		this.onLoadShowTrucksList(urlpath, reqparams)
		
		console.log(reqparams,"reqparams")
	};

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}


	onLoadShowTrucksList = (urlpath, parameters) => {
		console.log("parameters", parameters, "urlpath", urlpath)
		if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
		{
			parameters.consigner_code = [localStorage.getItem("pc")];
		}
		if(parameters.flCustomer !=undefined && parameters.flCustomer !="undefined" && parameters.flCustomer !="")
		{
			parameters.customer_code = JSON.stringify(parameters.flCustomer);
		}
		parameters.user_type = localStorage.getItem("user_type");
		var urlpath = '/amns/intranitRoadMobileData';
		parameters.screen_type = "Active";
		parameters.data_type = "allTrucks";
		parameters.priority = this.state.priority
        var showRail = 0;
        if(localStorage.getItem("roles").indexOf("AMNS_OPS") >= 0 || localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("email") == "hazira.test@amns.in")
        {
            showRail = 1;
        }
        parameters.showRail = showRail;
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				//console.log(response);
				// console.log("response", response.data);
				// let trucksArr = JSON.parse(response.data.active_trucks)
				var trucksArr = response.data;
				var trucksArr = trucksArr.filter((item) => {
					return item.transporter_name != "SELF"
				})
				console.log(trucksArr,"123")
				// if(localStorage.getItem("pc")!="HZ"){
				// 	trucksArr=trucksArr.filter(e=>e.)
				// }
				
			   
				if(localStorage.getItem("user_type") == "CUSTOMER")
				{
					if(trucksArr.length > 0)
					{
						trucksArr = trucksArr.filter((t) => t.is_tracking_available == 1);
					}
				}
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					//console.log(JSON.parse(dd))
					var recordsarr = JSON.parse(response.data);
				}
				//console.log("Total records ", recordsarr)
				//console.log("records", typeof recordsarr.active_trucks);
				var records = [];
				
				var counters = recordsarr.counters_data;
				// console.log("records ", records)

				records = records.sort(GetSortDescOrder("invoice_time"));
				this.setState({
					rowData: trucksArr,
					allRowData: trucksArr,
					originalData: trucksArr,
					loadshow: 'show-n',
					pagecounters: counters
				});


			})
			.catch(function (error) {
				console.log(error);
			});
	}


	// onPropsApply = (params) =>{
	// 	console.log("Props Params ", params)
	// }
	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		// console.log("function call");
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			sideBar: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onShowCommentsDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Comments",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var reqparams = {
			truck_no: e.truck_no,
			dept_code: this.state.deptcode,
			screen: "Active Trucks"
		}
		redirectURL.post("/consignments/getCommentLogs", reqparams).then((response) => {
			// console.log(response.data);
			this.setState({
				commentsRowData: response.data.data
			})
		}).catch(function (error) {
			console.log(error);
		})
		this.setState({
			sliderCommentTranslate: "slider-translate-60p",
			showDiv: 'show-m',
			loadshow: 'show-n',
			overly: 'show-m',
		});
	}
	onShowRouteDiv = (e) => {
		// console.log("e ", e)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		var legs = JSON.parse(e.leg_details);
		legs.map((item) => {
			consignmentcodes.push(item.consignment_code)
		})
		// console.log("consignmentcodes ", consignmentcodes)
		this.setState({
			loadshow: 'show-m'
		});
		var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		var startdate = ""
		if(e.loading_yard_exit_time != undefined && e.loading_yard_exit_time != "" && e.loading_yard_exit_time != null){
			startdate = getYYYYMMDDHHMMSS(e.loading_yard_exit_time)
		}
		else{
			startdate = getYYYYMMDDHHMMSS(e.parking_in_time)
		}
		let sD = startdate.split(" ")
		let date = sD[0].replaceAll("/", "-")
		startdate = date + " " + sD[1]
		var reqData = {
			truck_no: e.truck_no,
            shipment_id:e.shipment_id,
			consignment_codes: consignmentcodes,
			fromdate: startdate,
			todate: edate
		}
		// console.log("reqData: ", reqData);
		var mapUrl = '/trucks/truckmap';
		if(e.shipment_mode == "RAIL" && e.rail_consignment == 1)
		{
			if(e.status <= 12)
			{
				var cdate = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
			}
			else
			{
				var cdate = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
			}
			if(e.parking_in_time != "")
			{
				var fdate = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
			}
			else
			{
				var fdate = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
			}
			reqData.consignment_code = legs[0].consignment_code;
			reqData.from_date = fdate;
			reqData.to_date = cdate;
			mapUrl = '/dashboard/mllmaproute';
		}
		redirectURL.post(mapUrl, reqData)
			.then((response) => {
				var records = response.data;
				console.log("check ",Array.isArray(records))
				// console.log("Props data ", response.data)
				try{
					if(Array.isArray(records) == false)
					{
						var recs = records.replace(/NaN/g,"0")
						records = JSON.parse(recs)
					}
				}
				catch(e){

				}
				
				// console.log("records list ", records);
				this.setState({
					loadshow: 'show-m'
				});
				// console.log("S1")
				try {
					// console.log("S2")
					// console.log("records.coords ", records.coords)
					if (records.coords.length == 0) {
						// console.log("S3")
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'In transit',
							basicType: "success",
							loadshow: 'show-n'
						});
					}
					else {
						// console.log("S4")
						// console.log("recordss ", records)
						// if (records != '') {

							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: records.consignee_coordinates,
								consignment_code: "Truck NO : " + e.truck_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time },
								loadshow: 'show-n',
								sidebarSubHeader: "Truck Route Details",
								overly: 'show-m',
								rownode: e,
								consigner_coordinates: records.consigner_coordinates,
								consignee_coordinates: records.consignee_coordinates,
								tolls: records.tolls,
								map_type_of_tracking : records.type_of_tracking

							});
							console.log("s5 ")
							this.renderMap();
						// }
					}
				}
				catch (e) {
					if (records.message == "no data found") {
						this.setState({
							show: true,
							basicTitle: 'In transit',
							basicType: "success",
							loadshow: 'show-n'
						});
					}
				}



			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}

	/*Truck Sidebar Component Starts*/
	async onShowSidebar(e) {
		this.setState({
			tabsliderTranslate: ""
		});
		//console.log(e);
		if (e.colDef.field == 'truck_no') {
			let ComponentInfo = AppendComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no: e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						truckInfo: truckrecords
					});
				})

				.catch(function (error) {
					console.log(error);
				});

			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						consignments: consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						currenttrucklocation: currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})

			this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}

		/*Transporters Sidebar*/

		if (e.colDef.field == 'transporter_name') {
			let ComponentInfo = AppendComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name: e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					// console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes = [];
					var transportName = '';
					var transportCode = '';
					transporterrecords.map(item => {
						transportName = item.transporter_name;
						transportCode = item.transporter_code;
						deptcodes.push(item.dept_code + ", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name: transportName,
						transporter_code: transportCode,
						dept_code: deptcodes
					}]
					this.setState({
						transporterInfo: transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
				.then((response) => {
					//console.log("Total Trucks Operating response", response);
					this.setState({
						truckoperates: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
				.then((response) => {
					// console.log("Total Trucks Operating response", response);
					this.setState({
						truckgpslifetime: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
				.then((response) => {
					//console.log("Total Trucks Operating response", response);
					this.setState({
						gpstoday: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist = [];
					consignrecords.map(item => {
						if (item.consigns.length > 0) {
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})

						}
					});

					// console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments: consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});


			this.displayData = await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}
		/*End*/

		/*LSP Sidebar*/

		if (e.colDef.field == 'actual_lspuser') {
			let ComponentInfo = AppendComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser: e.data[e.colDef.field]
			};
			// console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);

					var result = lsprecords.reduce(function (sum, item) {
						return sum = sum + item.count;
					}, 0);
					// console.log("result", result);
					this.setState({
						lspInfo: result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
				.then((response) => {
					var deprecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("LSP DeptServe response", deprecords);

					var deptcnt = [];
					deprecords.map(item => {
						deptcnt.push(item._id)
					});
					this.setState({
						deptServes: deptcnt.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
				.then((response) => {
					var transrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("Transporter serve response", transrecords);
					var trasportcnt = [];
					transrecords.map(item => {
						trasportcnt.push(item._id)
					});
					this.setState({
						transrecordserves: trasportcnt.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
				.then((response) => {
					var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("GPS Send Data response", gpssendrecords);

					this.setState({
						gpssendtoday: gpssendrecords.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
				.then((response) => {
					var notransrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("No Trasnporter Data response", notransrecords);

					this.setState({
						nopetransporters: notransrecords.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			this.displayData = await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}
		/*End*/
	}


	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	renderMap = () => {
		// loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		// window.initMap = this.initMap
	}

	initMap = () => {
		// console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);




				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'satellite' || map.getMapTypeId() == 'roadmap') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						var contentarr = []
						var header = "Break - " + (l + 1)
						contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
						contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) + " (" + secondsToString(locations[l].break_time_seconds) + ")" })
						var contentString = infoBox(marker.icon, header, contentarr)
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = "Starting Point"
					contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
					var contentString = infoBox(marker.icon, header, contentarr, '')


					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					var header = "End Point"
					contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });
					try{
						// console.log(jsondata.trucksdetails)
						if(jsondata.trucksdetails != undefined)
						{
							if(jsondata.trucksdetails.length > 0)
							{
								contentarr.push({"key":"Address ", "value":jsondata.trucksdetails[0].last_known_address});
							}
							
						}
					}
					catch(e){

					}
					var contentString = infoBox(marker.icon, header, contentarr)

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
		}
		try {
			// console.log("consigner_coordinates ", this.state.consigner_coordinates)
			if (this.state.consigner_coordinates != undefined) {
				if ((this.state.consigner_coordinates[0].lat != '' || this.state.consigner_coordinates[0].lat != 0)
					&& (this.state.consigner_coordinates[0].lng != '' || this.props.consigner_coordinates[0].lng != 0)) {
					// console.log(this.state.consigner_coordinates[0].lat+" ==   "+ this.state.consigner_coordinates[0].lng);
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(this.state.consigner_coordinates[0].lat, this.state.consigner_coordinates[0].lng),
						icon: require('../../assets/icons/business.png'),
						map: map,
					});
					var nplantname = this.state.consigner_coordinates[0].name;
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						console.log(marker)
						return function () {
							//	console.log(clusters[0])
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var header = "Plant"
							// contentarr.push({ "key": "End at ", "value": nplantname })
							contentarr.push({ "key": "Start at ", "value": nplantname })
							var contentString = infoBox(marker.icon, header, contentarr)

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						}
					})(marker));

				}
			}
		}
		catch (e) {

		}
		try {

			if (this.state.consignee_coordinates != undefined) {
				if (this.state.consignee_coordinates.length > 0) {
					var consigneecrds = this.state.consignee_coordinates;
					consigneecrds.map((item) => {
						//console.log("Coords itm ", item)
						try {
							//console.log(typeof(item))
							var coordinate = JSON.parse(item);
						}
						catch (e) {
							var coordinate = item;
						}
						if (coordinate.lat != 0 && coordinate.lng != 0) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
								icon: require('../../assets/icons/customer_pin_38.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "Customer"
									contentarr.push({ "key": "End at ", "value": coordinate.name })
									var contentString = infoBox(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

						}

					});

				}
			}
		}
		catch (e) {

		}
		map.fitBounds(bounds)
	}



	onShowOverspeedKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ",params);
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		if (params != 0) {

			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", width: 140, field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", width: 100, field: "speed", filter: true, resizable: true },
							{
								headerName: "Reported From Date", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.first_instance != "" && params.data.first_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.first_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},

							{
								headerName: "Reported End Date", field: "recent_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									try {
										if (params.data.recent_instance != "" && params.data.recent_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.recent_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }

								}
							},
						],

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ",param)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/truckoverspeed', {
							consignment_code: consignments,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowTransitKpi = async (params) => {
		// console.log("params ", params);
		//console.log("Transit ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var row = eval(params.data.leg_details)
			//console.log("Row ",row);
			var consignments = []
			var consignees = []
			row.map((item) => {
				consignments.push(item.consignment_code)
				consignees.push(item.consignee_code)
			})
			var transitedelaydata = [];
			
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "Invoice No",
								field: "sales_order",
								width: 140,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}, {
								headerName: "Consignment",
								field: "consignment_code",
								width: 140,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Original ETA",
								field: "expected_trip_end",
								width: 140,
								valueGetter: function (params) {
									return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return 'N/A';
									}
								},

							},
							{
								headerName: "Revised ETA",
								field: "revised_trip_end",
								width: 140,
								valueGetter: function (params) {
									return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return 'N/A';
									}

								}
							},
							// {
							// 	headerName: "Invoice Time",
							// 	field: "invoice_time"
							// },
							// {
							// 	headerName: "Gate In Time",
							// 	field: "gate_in_time"
							// },
							// {
							// 	headerName: "Gate Out Time",
							// 	field: "gate_out_time"
							// },
							
							{
								headerName: "Status",
								field: "status",
								width: 140,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								},
								valueGetter: function (params) {
									//console.log("GPS ", params.data.gps_data_available);
									if(localStorage.getItem("pc")=="HZ"){
										if(params.data.status == 1)
									{
										return Constant.INSIDE_TPA;
									}
									else if(params.data.status == 2)
									{
										return Constant.INSIDE_MY_PMILL;
									}
									
									if(params.data.status == 1 || params.data.status == "1")
									{
										return Constant.INSIDE_TPA;
									}
									if(params.data.status == 2 || params.data.status == "2")
									{
										return  Constant.INTRANSIT_TO_MY;
									}
									if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
									{
										return  Constant.INSIDE_MY_PMILL;
									}			
									else if(params.data.status == 6)
									{
										return Constant.FORCE_CLOSE;
									}
									else if(params.data.status == 7)
									{
										return Constant.INSIDE_TPA_AFTER_LOADING;
									}
									else if(params.data.status == 8 )
									{
										return Constant.INTRANSIT;
									}
									else if(params.data.status == 9 )
									{
										return Constant.NEAR_CUSTOMER;
									}
									else if(params.data.status == 10 )
									{
										return Constant.REACHED_CUSTOMER;
									}
									else if(params.data.status == 11 )
									{
										return Constant.LEFT_CUSTOMER;
									}
									else{
										return ""
									}
									} 
									else{
										if(params.data.status == 1 || params.data.status == 2||params.data.status == 3||
											params.data.status == 4||params.data.status == 5){
												return "At Plant"
											}
											else if(params.data.status == 7)
											{
												return Constant.INSIDE_TPA_AFTER_LOADING;
											}
											else if(params.data.status == 8 )
											{
												return Constant.INTRANSIT;
											}
									}
									
								}
							},
							// {
							// 	headerName: "Zone",
							// 	field: "zone",
							// 	width: 100,
							// 	cellClass: function (params) {
							// 		if (params.data.transit_delay == 1) {
							// 			return 'lightred';
							// 		}
							// 		else {
							// 			return '';
							// 		}

							// 	}
							// },
							{
								headerName: "Consigner Code",
								field: "consigner_code",
								width: 100,

								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Customer Code",
								field: "consignee_code",
								width: 100,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Customer City",
								field: "transporter_zone",
								width: 120,
								resizable: true,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							// {
							// 	headerName: "Dealer State",
							// 	field: "consignee_state",
							// 	width: 120,
							// 	resizable: true,
							// 	cellClass: function (params) {
							// 		if (params.data.transit_delay == 1) {
							// 			return 'lightred';
							// 		}
							// 		else {
							// 			return '';
							// 		}

							// 	}
							// },
							{
								headerName: "Trip Distance (KM)",
								field: "distance_in_km",
								width: 120,
								resizable: true,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Distance from Customer (KM)",
								field: "distance_from_dealer_location",
								width: 160,
								valueGetter: function (params) {
									if (params.data.distance_from_dealer_location != '' && params.data.distance_from_dealer_location != null) {
										return params.data.distance_from_dealer_location;
									}
									else {
										return "";
									}
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							// {
							// 	headerName: "Cluster",
							// 	field: "cluster",
							// 	cellClass: function (params) {
							// 		if (params.data.transit_delay == 1) {
							// 			return 'lightred';
							// 		}
							// 		else {
							// 			return '';
							// 		}

							// 	}
							// },

							// {
							// 	headerName: "Transit Time",
							// 	field: "transit_time"
							// },

						]
					},
					getDetailRowData: async function (params) {
						var output = eval(params.data.leg_details);
						output.map((e) => e.type_of_tracking = params.data.type_of_tracking);
						if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
						{
							var customer_code = JSON.parse(localStorage.getItem("customer_code"));
							// console.log(customer_code,"customer_code--")
							if(customer_code.length > 0)
							{
								var output1 = [];
								var otpt = await output.map(async(e) => {
									var customer_codes = e.consignee_code;
									customer_codes = parseInt(customer_codes).toString();
									// console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
									if(customer_code.indexOf(customer_codes) >= 0)
									{
										output1.push(e);
									}
								})
								var output = output1;
							}             
						}
						console.log("output ", output)
						var legs = (output);
						//console.log("Data ", legs)
						await params.successCallback(legs);
					}
				}
			});


			//if( params.column.colId == 'transit_delay' ||  params.column.colId == 'no_gps_data')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.nightDrive;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({
				map_type_of_tracking : params.data.type_of_tracking,
				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								cellRendererFramework: RouteNightDrivemap,
								width: 100,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								width: 140,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (KM)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/trucknightdriving', {
							consignment_code: consignments,
							gate_out_time: params.data.parking_in_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.gpsAvailable;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							/*{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							*/
							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})

						redirectURL.post('/consignments/trucknogpsdata', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowEnrouteStoppagesKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.enrouteStoppages;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Address",
								field: "address",
								width: 250,
								resizable: true
							},
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break End",
								field: "reset_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.reset_datetime != undefined && params.data.reset_datetime != "Total") {
										return getDDMMYYYYHHMMSS(params.data.reset_datetime)
									}
									else {
										if(params.data.reset_datetime == 'Total')
										{
											return "Total";
										}
										else{
											return '';
										}
									}

								}
							},
							
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.elaped_time_from_last_moved_seconds != undefined) {
										var seconds = params.data.elaped_time_from_last_moved_seconds;
										var hours = parseInt(seconds / 3600);
										var minutes = Math.round((seconds - (hours * 3600)) / 60)
										//return hours+" Hr(s) "+minutes+" Min(s)";
										return secondsToString(seconds);
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Update on",
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.modified_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.modified_date)
									}
									else {
										return '';
									}

								}
							},
						]
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/truckenroutes', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								var summaryrow = {};
								var elaped_time_from_last_moved_seconds = 0;
								if(overspeedData.length > 0){
									overspeedData.map((it) => {
										elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds+it.elaped_time_from_last_moved_seconds;
									})
								}
								if(params.data.enroute_exception == 1)
								{
									if(overspeedData.length > 0)
									{
										summaryrow.stoppage_start_time = "";
										summaryrow.reset_datetime = "Total";
										summaryrow.elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds;
										summaryrow.modified_date = "";
										overspeedData.push(summaryrow)
									}
								}
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowLoadingDelayKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ", params);
		//console.log(params.column.colId);
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.loadingDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							// {
							// 	headerName: "Gate In Time",
							// 	field: "gate_in_time",
							// 	width: 180,
							// 	resizable: true,
							// 	valueGetter: function (params) {
							// 		if (params.data.gate_in_time != undefined) {
							// 			return params.data.gate_in_time;
							// 		}
							// 		else {
							// 			return "NA"
							// 		}

							// 	}
							// },
							// {
							// 	headerName: "Gate Out Time",
							// 	field: "gate_out_time",
							// 	width: 180,
							// 	resizable: true,
							// 	valueGetter: function (params) {
							// 		if (params.data.gate_out_time != undefined) {
							// 			return params.data.gate_out_time;
							// 		}
							// 		else {
							// 			return "NA"
							// 		}
							// 	}
							// },
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,
								valueGetter: function (params) {
									if (params.data.loading_time != "" && params.data.loading_time != undefined) {
										return params.data.loading_time;
									}
									else {
										return "";
									}
								}
							}

						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						// if (params.data.gate_in_time != undefined) {
						// 	var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
						// 	//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
						// 	var gdate1 = new Date(params.data.gate_in_time);
						// 	var fromDate = g1;
						// }
						// else {

						var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
						var gdate1 = new Date(params.data.inside_fence);
						var fromDate = g1;
						// }
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if (params.data.parking_in_time != undefined) {
							var g2 = getDDMMYYYYHHMMSS(params.data.parking_in_time);
							var gdate2 = new Date(params.data.parking_in_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else {
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout - gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						let loadingTimeDifference = null;

						// let timeDiffereceDays = null;
						// let timeDiffereceHours = null;
						// let timeDiffereceMinutes = null;
						// let totalDifferece = null;

						// gdate1 = g1;
						// gdate2 = g2;
						// console.log("gdate1 && gdate2 =", gdate1, gdate2);
						// if(gdate1 && gdate2) {
						// 	console.log("IN IF gdate1 && gdate2");
						// 	timeDiffereceDays = gdate2.diff(gdate1, 'days');  // calculating time difference in days between two dates
						// 	timeDiffereceHours = gdate2.diff(gdate1, 'hours');  // calculating time difference in days between two dates
						// 	timeDiffereceMinutes = gdate2.diff(gdate1, 'minutes');  // calculating time difference in days between two dates
						// 	totalDifferece = gdate2.diff(gdate1);  // calculating time difference in days between two dates

						// } else {
						// 	console.log("IN ELSE  gdate1 && gdate2");
						// }
						// console.log("timeDiffereceDays = ", timeDiffereceDays);
						// console.log("timeDiffereceHours = ", timeDiffereceHours);
						// console.log("timeDiffereceMinutes = ", timeDiffereceMinutes);
						// console.log("totalDifferece = ", totalDifferece);

						var dataarr = []
						// dataarr.push({
						// 	"gate_in_time":fromDate,
						// 	"gate_out_time": toField,
						// 	"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// })

						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }

						// eslint-disable-next-line use-isnan
						if (fromDate != "" && toField != "") {
							if (diffHrs != "" && diffHrs != undefined && diffHrs != "NaN" && diffHrs != NaN) {
								var adiffHrs = diffHrs;
							}
							else {
								var adiffHrs = 0;
							}
							// eslint-disable-next-line use-isnan
							if (diffMins != "" && diffMins != undefined && diffMins != "NaN" && diffMins != NaN) {
								var adiffMins = diffMins;
							}
							else {
								var adiffMins = 0;
							}
							var ltime = adiffHrs + " Hr(s) " + adiffMins + " Min(s)";
						}
						else {
							var ltime = "";
						}

						// console.log("diffMins ", adiffMins)
						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							"loading_time": ltime
						})
						params.successCallback(dataarr);
					}

				}
			});

			if (params.column.colId == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}



	onShowUnLoadingDelayKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.unloadingDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);

			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],

					},
					getDetailRowData: function (params) {
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						if (diffDays) {
							loadingTimeDifference = diffDays + " Day(s) " + diffHrs + " Hr(s) " + diffMins + " Min(s)";
						} else {
							loadingTimeDifference = diffHrs + " Hr(s) " + diffMins + " Min(s)";
						}
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	onShowDeviate = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.deviation;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]
					},
					getDetailRowData: async function (params) {
						// console.log("Step 4 ", overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});

			if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onRowClicked(params) {
		//console.log("row click ", params)
		if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	}

	onShowRouteMap(rownode) {
		//console.log("rownode", rownode);
		//console.log("rownode.consignment_code ", rownode.consignment_code)

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}

		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						tolls: response.data.tolls

					});
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		redirectURL.post('/consignments/googlemaproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				// console.log("Google ", response)
				//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
				this.setState({
					googelRoutes: response.data.googleroute
				})
			})
			.catch(function (e) {
				console.log(e)
			})
	}
	onShowNoGPSRouteMap(rownode) {
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNightDriveRouteMap(rownode) {
		//console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		//var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.first_instance);
		var eintance = getHyphenYYYYMMDDHHMMSS(rownode.recent_instance);

		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata", response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});


	}
	formBulkForceCloseHandler(event){
		event.preventDefault();
		var dept_code = this.state.deptcode;
		//console.log(this.state.csvcontent);
		if(this.state.file != '')
		{
			//console.log("this.state.file ",this.state.file)
			var csvdd = this.state.file;
			
			//console.log("event ",event.target.uploadFile.value);
			
			var rparams = {
				uploadFile:this.state.file,
				userId:localStorage.getItem('userid'),
				csvcontent:this.state.csvcontent,
				dept_code : dept_code
			}
			
			//console.log('file',formData);
			console.log('rparams3267',rparams);
			this.setState({
				loadshow:'show-m'
			});
			var reasonFlag = 0;
			var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
			this.state.csvcontent.map(function(e,index){
				if(index != 0)
				{
					if(e.reason == "" || e.reason == null || e.reason == undefined)
					{
						reasonFlag = 1;
					}
					if(reasonFlag == 0)
					{
						if(format.test(e.reason)){reasonFlag=1;}
					}
				}
				
			})
			console.log(reasonFlag)
			if(reasonFlag == 0)
			{
				redirectURL.post("/consignments/uploadRailBulkForceClose", rparams)
				.then(
				(response)=>{
					console.log(response,"3293");
					//console.log(response.data);
					// document.getElementById("upform").reset();
					if(response.data.message == 'success')
					{
						console.log(response.data.message,"err")
						this.setState({
							show: true, 
							basicType:'success',
							basicTitle:"Force Closed Successfully",
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
						$('#forceCloseFileHandler').val("")
			
					}	
				}).catch(function(error){
					console.log(error);
				});
			}
			else{
				this.setState({
					//csverrmessage:response.data.status,
					show: true, basicType:'danger', basicTitle:"Reason Must not be Empty and Must not Contain Special Charecters",
					loadshow : "show-n",
				});
			}
			
			
		}
	}
	
	onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderRakeTranslate:'',
			bulkslide:'',
			sliderForceDestination:'',
			sliderForceLoadingYard:'',
			sliderBulkForceClose:"",
			bulkdeliverslide:""
		});
		
	}
	changeBulkForceFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }
		if(e.target.files.length > 0){
			const config = {
				headers: [
					{
						name: 'truck_no',
						inputName: 'truck_no',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					// { 
					// 	name: 'consignment_code',
					// 	inputName: 'consignment_code',
					// 	required: true,
					// 	requiredError: function (headerName, rowNumber, columnNumber) {
					// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					// 	}
					// },
					{
						name: 'reason',
						inputName: 'reason',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					},
					{
						name: 'shipment_id',
						inputName: 'shipment_id',
						required: true,
						requiredError: function (headerName, rowNumber, columnNumber) {
							return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
						}
					}
				]
			}
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				var file = e.target.files[0];
				var fileType = file.name.split('.')
				var type_check = false
				if (fileType.length >2) {
					type_check = true
				}
				if (type_check) {
					$("#uploadFile").val("")
					this.setState({
						uploadFile: '',
						show: true,
						file: '',
						basicType: 'danger',
						basicTitle: "Upload file can't have double extension",
					});
				} else {
					var data = CSVFileValidator(e.target.files[0], config)
						.then((csvData) => {
							console.log(csvData , "3443")
							if (csvData.data.length > 0 && csvData.inValidMessages.length == 0) {
								let keys = Object.keys(csvData.data[0])
								let items = ["truck_no", "reason", "shipment_id"]
								let check = false
								if (keys.length == items.length) {
									for (let i in items) {
										if (keys.includes(items[i])) {
										} else {
											check = true
											break
										}
									}
								} else {
									check = true
								}
	
								if (check) {
									$("#uploadFile").val("")
									this.setState({
										show: true,
										basicTitle: 'dont upload other fields',
										basicType: 'danger'
									});
								}
								else {
									this.setState({
										csvcontent: csvData.data
									});
								}
							} else {
								$("#uploadFile").val("")
								// document.getElementById("appednCSVData").innerHTML = ''
								this.setState({
									show: true,
									basicTitle: 'dont upload other fields',
									basicType: 'danger'
								});
							}
						})
						.catch(err => { })
				}
	
			}
			else {
				e.target.value = null;
				$("#uploadFile").val("")
				this.setState({
					uploadFile: '',
					show: true,
					file: "",
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}
		}
		

	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			sliderCommentTranslate: "",
			slideBar: ''
		});

	}
	
	onClickHideForForceClosure(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkPrioritySlide:"",
		});
		
	}
	selecetedReason(params) {
        //console.log(params);
        try{
            this.setState({
                selectedReason : params.value
            });
            //console.log(params.value);
            if(params.value == "Others"){
                this.setState({
                    displayReasonField : "show-m"
                })
            }
            else{
                this.setState({
                    displayReasonField : "show-n"
                })
            }
        }catch(e){}

    }
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({reasonforceclose:value});
    }
	handleSubmit(){
        // var valuesArr = [this.state.reasonforceclose,this.state.selectedReason]
        // this.props.context.handleInput(valuesArr);

        const { deliveryDate, selectedReason, reasonforceclose } = this.state
        const toDate = $("#datetimepicker_mask").val();
        var valuesArr = [reasonforceclose, selectedReason, deliveryDate]
        console.log(valuesArr,"valuesArr")
        this.props.handleInput(valuesArr);

    }
	handlerDeliveryDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        this.setState({
            deliveryDate: getHyphenYYYYMMDDHHMMSS(d)
        });
		console.log(this.state.deliveryDate,"3492")
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

	selectReason(){
        
        try{
        let arr=[
            {value:"Empty Exited",label:"Empty Exited"},
            {value:"Shipment Cancelled",label:"Shipment Cancelled"},
			{value:"Driver Change",label:"Driver Change"},
			{value:"Transhipment",label:"Transhipment"},
			{value:"Gps Data Not Available",label:"Gps Data Not Available"},
			{value:"Sim Issue",label:"Sim Issue"},
            {value:"Others",label:"Others"},
			

        ];
        if(this.props.deptcode == "SNDG")
        {
            // arr.splice(4,0,{value:"TVP-B: Chennai Stockyard Consignment",label:"TVP-B: Chennai Stockyard Consignment"})
        }
        if(this.props.deptcode == "LOG-PRT")
        {
            arr.splice(4,0,{value:"Pass Through Courier - Direct Delivery Project",label:"Pass Through Courier - Direct Delivery Project"},{value:"Same GR - Multi Truck Dispatch",label:"Same GR - Multi Truck Dispatch"},
            {value:"North East Trans-shipment",label:"North East Trans-shipment"},)
        }
        return arr;
        }
        catch(e){

        }
    }
	formForceCloseHandler(event){
		event.preventDefault()
		var forcecloserow = this.state.forceCloseRowNode;
		console.log("forceCloseRowNode ", this.state.forceCloseRowNode);
		var consignemnt = this.state.forceCloseRowNode.data;
		var consignment_code = consignemnt.shipment_id;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = "SNDG";
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		 var others_Reason = this.state.reasonforceclose;
		var reasonforceclose = this.state.selectedReason;
		let deliveryDate = this.state.deliveryDate
	//    if(this.state.deptcode == "SNDG")
	// 	{
		   var reqdata = {
			   dept_code:dept_code,
			   shipment_id:[consignment_code],
			   truck_no:truck_no,
			   userid:userid,
			   reasonforceclose:reasonforceclose,
			   others_reason : others_Reason,
			   deliveryDate:deliveryDate,
			   consignee_code:consignee_code
		   }
		// }
		// else
		// {
		//    var reqdata = {
		// 	   dept_code:dept_code,
		// 	   consignment_code:[consignment_code],
		// 	   truck_no:truck_no,
		// 	   userid:userid,
		// 	   screen : "TMS",
		// 	   others_Reason : others_Reason,
		// 	   reasonforceclose:reasonforceclose,
		//    }
		// }
	   var flag = 0;
	   if(others_Reason == "" && reasonforceclose == "Others"){
		   flag = 1;
	   }
	   if(flag == 0 && reasonforceclose == "Others")
	   {
		//    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
		   if(others_Reason.replace(/\s/g,"").length == 0)
		   {
			flag = 1;
		   }
	   }
	   console.log("reqdata ",reqdata)
	   if(flag == 0)
	   {
		   redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
		   .then((response) => {
			   console.log("Resposne updateConsignmentForceClose ", response)
			   if(response.data.status == 'Success')
			   {
				// document.getElementById("reached_dealer_time").value='';
				// document.getElementById("left_dealer_time").value='';
				   this.setState({
					   show:true,
					   basicTitle:"Success",
					   basicType:"success",
					   loadshow:'show-n',
					   overly:'show-n',
					   left_dealer_time:'',
					   reached_dealer_time:'',
					   reachedhh:'',
					   reachedmm:'',
					   reachedss:'',
					   lefthh:'',
					   leftmm:'',
					   leftss:'',
					   reasonforceclose:"",
					   openforceclose:false,
					   sliderForceCloseTranslate:''
				   });
			   }
			   else
			   {
				   this.setState({
					   show:true,
					   basicTitle:"Error",
					   basicType:"danger",
					   loadshow:'show-n',
					   left_dealer_time:'',
					   reached_dealer_time:'',
					   reachedhh:'',
					   reachedmm:'',
					   reachedss:'',
					   lefthh:'',
					   leftmm:'',
					   leftss:'',
					   reasonforceclose:'',
					   openforceclose:false,
					   sliderForceCloseTranslate:'',
					   overly:'show-n'
				   });
			   }

		   })
		   .catch(function(e){
			   console.log("Error ", e)
		   })
	   }
	   else{
		   this.setState({
			   show: true,
			   basicTitle:'Reason for closue should not be empty',
			   basicType:"danger",
		   });
	   }
	   event.target.reset();
	}
	closeAlert = () => {
		this.setState({
			show: false,
			loadshow: 'show-n'
		});
		// window.location.reload()
	}



	onClickCounterShowData(params) {
		//console.log("Counter Params Here ", params);
		this.setState({
			loadshow: 'show-m'
		});
		var filterInstance;
		let filterActivated = false;
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
		console.log("params ", params)
		switch (params) {
			case "transit_delay":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "is_gps_working":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "no_gps_data_lifetime":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "overspeeding_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "nightdriving_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "loading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "unloading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "enroute_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "sim_issue":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			default:
				//console.log("filterDeavtivated params = ", params);
				filterActivated = false;
		}
		// console.log("filterActivated ", filterActivated)
		// if (filterActivated) {
		// 	filterInstance.selectNothing();
		// 	let isFilterDataAvailable = this.state.allRowData.filter(rec => {
		// 		if (rec[params]) {
		// 			return rec[params] == 1;
		// 		}
		// 	});
		// 	//console.log("isFilterDataAvailable ", isFilterDataAvailable)
		// 	if (isFilterDataAvailable.length) {
		// 		filterInstance.selectValue("1");
		// 	}
		// 	//console.log("filterInstance ", filterInstance)
		// 	filterInstance.applyModel();
		// 	this.gridApi.onFilterChanged();
		// 	this.setState({
		// 		activeFilter: params
		// 	});
		// }

		if (filterActivated) {

			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if(params == "is_gps_working")
				{
					return (rec['is_gps_working'] == "NOT_WORKING");
				}
				else{
					if (rec[params]) {
						return rec[params] == 1;
					}
				}
			});
			// console.log("filterInstance ", this.gridApi.getFilterInstance(params))
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			if (isFilterDataAvailable.length) {
				//this.gridApi.getFilterInstance(params).selectNothing();
				//filterInstance.selectValue("1");
				//filterInstance.applyModel();
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
			else {
				// filterInstance.selectNothing();
				//filterInstance.selectValue("");
				// filterInstance.applyModel();
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
		}
		else {
			this.gridApi.setRowData(this.state.allRowData);
			this.gridApi.onFilterChanged();
			this.setState({
				loadshow: 'show-n'
			});
		}


	}



	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onClickTripButton = async () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filter Button",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		});
		//console.log("Buton this.state.triptype ", this.state.triptype)
		var selectcons = []
		if (this.state.consigner.length > 0) {
			this.state.consigner.forEach(item => {
				selectcons.push(item.value);
			});
		}

		if (this.state.triptype.value != "0") {
			var tdata = {
				triptype: this.state.triptype.value,
				consigner_code: selectcons
			}
			await redirectURL.post("/trucks/triptrucks", tdata)
				.then((response) => {
					// console.log("Trip array ", response.data)
					var respData = response.data;
					if(localStorage.getItem("user_type") == "CUSTOMER")
					{
						if(respData.length > 0)
						{
							respData = respData.filter((t) => t.is_tracking_available == 1);
						}
					}
					this.setState({
						tripvalue: this.state.triptype.value,
						rowData: respData,
						allRowData: respData,
						loadshow: 'show-n',
						//consigner:''
					});
				})
				.catch(function (e) {
					console.log("Counters Grid Error ", e)
				})

		}
		else {
			// console.log("this.state.allRowData ", this.state.originalData)
			this.setState({
				rowData: this.state.originalData,
				allRowData: this.state.originalData,
				tripvalue: this.state.triptype.value,
				loadshow: 'show-n'
			});
		}



	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'ALL', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}


		return items;
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + ((d.getMonth() +1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + ((d.getMonth() +1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			endDate: edate
		});
	}

	async onShowSidebarComponent(e) {
		if (e.colDef.field == "truck_no") {
			this.setState({
				slideBar: "",
				loadshow: "show-m"
			});
			let ComponentInfo = ComponentIndex['activeTrucks'];
			var layoutdata = {
				truck_no: e.data['truck_no'],
			};
			// console.log(layoutdata);
			await redirectURL.post("/consignments/activeTruckDetails", layoutdata)
				.then(async (response) => {
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo: truckrecords,
						consignments: consignrecords,
					});
					let obj = this.state.rowData.find(o => o.truck_no === e.data[e.colDef.field]);
					var objArray = [];
					objArray.push(obj);
					// console.log("truckInfo",this.state.truckInfo);
					// console.log("consignRecords",this.state.consignments);
					this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={truckrecords} consignments={consignrecords} truckData={objArray}></ComponentInfo>);

				}).catch(function (error) {
					console.log("ERROR");
				});
			await this.setState({
				showdata: this.displayData,
				slideBar: "slider-translate",
				loadshow: 'show-n',
				overly: 'show-m'
			});

		}
	}

	onGetConsigners = async (event) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}


		// var sDate = document.getElementById("from_date").value;
		// var eDate = document.getElementById("to_date").value;
		var sDate = this.state.startDate
		console.log(sDate,"sDate12")
		var eDate = this.state.endDate
		console.log(eDate,"eDate21")

		this.setState({
			// startDate:moment(sDate).format("DD-MM-Y"),
			// endDate:moment(eDate).format("DD-MM-Y"),
			loadshow: 'show-m'
		});

		var selectcons = []
		//console.log("this.state.consigner.length ", this.state.consigner.length)
		var datechk = 1;
		var errpopmsg = "";
		if(sDate != undefined && sDate != "")
		{
			console.log(eDate,"eDate")
			if(eDate == undefined || eDate == "")
			{
				datechk = 0;
				errpopmsg = "Please select Invoice To Date"
			}
		}
		if(eDate != undefined && eDate != "")
		{
			if(sDate == undefined || sDate == "")
			{
				console.log(sDate,"sDate")
				datechk = 0;
				errpopmsg = "Please select Invoice From Date"
			}
		}
		if(sDate != undefined && sDate != "" && eDate != undefined && eDate != ""){
			if(sDate > eDate)
			{
				datechk = 0;
				errpopmsg = "Invoice From Date should be less than Invoice To Date"
			}
		}
		if(datechk == 1){
			if (this.state.consigner != null) {
				if (this.state.consigner.length > 0) {
					this.state.consigner.forEach(item => {
						if(item.value !="" && item.value !=undefined)
						{
							selectcons.push(item.value);
						}
					});
				}
				else {
					selectcons.push("ALL");
				}
			}
			else {
				selectcons.push("ALL");
			}

			var parameters = {
				consigner_code: selectcons,
				dept_code: this.state.deptcode,
				from_date: sDate,
				to_date: eDate
			}
			if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
			{
				parameters.transporter_codes = localStorage.getItem("transportercode")
			}
			if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
			{
				parameters.customer_code = localStorage.getItem("customer_code")
			}
			if (this.state.deptcode == 'SNDG') {
				let zone = this.state.flZone
				if (zone !== "" && zone !== undefined && zone !== null) {
					parameters['zone'] = [zone.value]
				}
			}

			let transporter = this.state.flTransporter
			// console.log("transporter type", typeof (transporter.value))
			if (transporter !== "" && transporter !== undefined && transporter !== null) {
				if (transporter.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter'] = [transporter.value]
				}

			}
			let flTransporterZone = this.state.flTransporterZone;
			if (flTransporterZone !== "" && flTransporterZone !== undefined && flTransporterZone !== null) {
				if (flTransporterZone.value != "") {
					// parameters['transporter'] = [transporter.label]
					parameters['transporter_zone'] = [flTransporterZone.value]
				}

			}
			if (this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') {
				let state = this.state.flState
				let city = this.state.flCity
				let flCustomer = this.state.flCustomer
				if ((state) !== "" && (state) !== undefined && state !== null) {
					if (state.value != "") {
						parameters['state'] = [state.value]
					}
				}
				if (city !== "" && (city) !== undefined && city !== null) {
					if (city.value != "") {
						parameters['city'] = [city.value]
					}
				}
				if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
					var flList = []; 
					flCustomer.map((fl) => {
						flList.push(fl.value);
					});
					if (flList.length > 0) {
						parameters['flCustomer'] = flList
					}
				}
			}
			var urlpath;
			if (this.props.match.path == "/sndtrucks") {
				urlpath = '/trucks/sndtrucks';
			}
			else if (this.props.match.path == "/prttrucks") {
				urlpath = '/trucks/prttrucks';
			}
			else if (this.props.match.path == "/tnptrucks") {
				urlpath = '/trucks/tnptrucks';
			}
			else {
				urlpath = '/trucks';
			}
			console.log(parameters,"parameters")

			this.onLoadShowTrucksList(urlpath, parameters)
		}
		else{
			this.setState({
				show: true,
				basicTitle: errpopmsg,
				basicType: "warning",
			})
		}
			
	}

	onShowTruckConsignments = async (legData) => {
		//console.log("legData", legData)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Legs",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Consignment",
							field: "consignment_code"
						},
						{
							headerName: "Expected Trip End",
							field: "expected_trip_end"
						},
						{
							headerName: "Revised Trip End",
							field: "revised_trip_end"
						},
						// {
						// 	headerName: "Invoice Time",
						// 	field: "invoice_time"
						// },
						// {
						// 	headerName: "Gate In Time",
						// 	field: "gate_in_time"
						// },
						// {
						// 	headerName: "Gate Out Time",
						// 	field: "gate_out_time"
						// },
						{
							headerName: "Status",
							field: "status"
						},
						{
							headerName: "Consigner Code",
							field: "consigner_code"
						},
						{
							headerName: "Cusomter Code",
							field: "consignee_code"
						},

						// {
						// 	headerName: "Cluster",
						// 	field: "cluster"
						// },

						{
							headerName: "Transit Time",
							field: "transit_time"
						},

					]
				},
				getDetailRowData: async function (params) {
					var data = params.data.leg_details;
					//console.log("Data ", params)
					var legs = JSON.parse(data);
					//console.log("Data ", legs)
					await params.successCallback(legs);
				}
			}
		});
		if (legData.column.colDef.field == 'truck_no') {

			legData.node.setExpanded(!legData.node.expanded);
		}
		else {

			legData.node.setExpanded(false);
		}
	}

	onClickShowMapView = async () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				// console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onRowSelection(event) {
		var rowIds = [];
		var rwCount = event.api.getSelectedNodes();
		rwCount.map((item) => {
			rowIds.push(item.data.shipment_id)
		});

		this.setState({
			rowIds: rowIds
		})
		console.log("checkedlist ", rowIds)
	}

	

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};


	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};


	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = '';

			if (this.props.match.path == "/sndtrucks") {
				screenpage = 'snd trucks';
			}
			else if (this.props.match.path == "/prttrucks") {
				screenpage = 'prt trucks';
			}
			else if (this.props.match.path == "/tnptrucks") {
				screenpage = 'tnp trucks';
			}
			else {
				screenpage = 'all trucks';
			}

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}

	restoreGridStates() {
		if (this.state.usergridstate.length !== 0) {
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch (e) {

			}
		}
	}

	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}

	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter
		console.log("selectedFilter", selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		console.log("selFilter",inputTruckConsignment)
		if (selectedFilter == "" || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			const { allRowData, pagecounters } = this.state
			// console.log("allRowData",allRowData,"pagecounters",pagecounters )	
			if (selectedFilter == "Truck") {
				let filteredData = allRowData.filter(item => item.truck_no.includes(inputTruckConsignment))
				console.log("filteredData", filteredData)
				// this.gridApi.setRowData(filteredData);
				// this.gridApi.onFilterChanged();
				this.setState({
					rowData: filteredData,
					// allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});

			}
			if (selectedFilter == "GR No") {
				let filteredData = allRowData.filter(item => item.consignment_codes.includes(inputTruckConsignment))

				// console.log("Consingments", filteredData)
				this.setState({
					rowData: filteredData,
					// allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"

				});
			}
			if (selectedFilter == "Invoice") {
				let filteredData = allRowData.filter(item => item.sales_order_list.indexOf(inputTruckConsignment) >= 0)
				console.log("Invoice", filteredData)
				this.setState({
					rowData: filteredData,
					// allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
			if(selectedFilter == "Shipment No"){
				let filteredData = allRowData.filter(item => item.shipment_id.includes(inputTruckConsignment))
				console.log("Shipment", filteredData)
				this.setState({
					rowData: filteredData,
					// allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
		}
	}

	reloadPageData = () => {
		window.location.reload();
	}

	onShowDayWiseDistanceTrip = async (params) => {
		// console.log("params", params)
		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var transitedelaydata = [];
			var checkboxSelectionval = true
			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RouteMapForDayWiseDistanceTrip,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Distance Travelled(Km)",
								field: "distance_travelled_km",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Day",
								field: "day",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Start time",
								field: "start_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.start_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "End time",
								field: "end_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.end_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (param) {
						//console.log("Step 4 ",transitedelaydata)
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/getDayWiseDistanceTravelled', {
							invoice_nos: param.data.invoice_nos,
							invoice_time: getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
						})
							.then(async (response) => {
								// console.log("Step 1 ", response.data)
								transitedelaydata = response.data;
								var legsarr = []
								if (transitedelaydata.length !== 0)
									transitedelaydata.map((item) => {
										//if(item.trip_completed < 2)
										//{
										legsarr.push(item)
										//}
									})
								// console.log('transitedelaydata ', transitedelaydata);

								param.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'day_wise_distance_trip' || params.column.colDef.field == 'day_wise_distance_trip') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			// console.log("elseData")
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Distance Travelled(Km)", field: "distance_travelled_km" },
							{ headerName: "Day", field: "day" },
							{ headerName: "Start Time", field: "start_time" },
							{ headerName: "End Time", field: "end_time" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}
	}

	onShowDayWiseRouteMap = (rownode) => {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.end_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			// consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			startDate: fintance,
			endDate: eintance,
			screen: "consignment"
		}
		redirectURL.post('/gmap', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("Inner Grid ",JSON.parse(response.data.data).coords);
				var records = JSON.parse(response.data.data);
				// console.log("records ", records)
				if (records.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					// if (records.route_details.start_time != '' && records.route_details.start_time != undefined) {
					// 	sdate = records.route_details.start_time;
					// }
					// if (records.route_details.end_time != '' && records.route_details.end_time != undefined) {
					// 	edate = getDDMMYYYYHHMMSS(records.route_details.end_time)
					// }
					this.setState({
						mapinfo: records,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
						loadshow: 'show-n',
						sidebarSubHeader: "View Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	onClickShowLoadingAreaLegs = async (rownode) =>  {
        console.log("rownode", rownode)
        var hideForCustomer="";
		var hideForNotCustomer="";
        if(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0)
        {
            var hideForCustomer = true;
			var hideForNotCustomer = false;
			var hideforsales = true;
        }
        else
        {
            var hideForCustomer = false;
			var hideForNotCustomer = true;
        }
        var commentHeaders = [        
            {
                headerName : Constant.COL_CONSIGNMENT_CODE,
                field : "consignment_code",
                resizable : true,
                width:140,
                filter : true
            }, 
			{
                headerName : Constant.COL_STATUS,
                field : "status",
                resizable : true,
                width:140,
                filter : true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if(params.data.status == 1)
					{
						return Constant.INSIDE_TPA;
					}
					else if(params.data.status == 2)
					{
						return Constant.INSIDE_MY_PMILL;
					}
					
					if(params.data.status == 1 || params.data.status == "1")
					{
						return Constant.INSIDE_TPA;
					}
					if(params.data.status == 2 || params.data.status == "2")
					{
						return  Constant.INTRANSIT_TO_MY;
					}
					if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
					{
						return  Constant.INSIDE_MY_PMILL;
					}			
					else if(params.data.status == 6)
					{
						return Constant.FORCE_CLOSE;
					}
					else if(params.data.status == 7)
					{
						return Constant.INSIDE_TPA_AFTER_LOADING;
					}
					else if(params.data.status == 8 )
					{
						return Constant.INTRANSIT;
					}
					else if(params.data.status == 9 )
					{
						return Constant.NEAR_CUSTOMER;
					}
					else if(params.data.status == 10 )
					{
						return Constant.REACHED_CUSTOMER;
					}
					else if(params.data.status == 11 )
					{
						return Constant.LEFT_CUSTOMER;
					}
					else{
						return ""
					}
				}
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
                resizable : true,
                filter : true,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.MATERIAL_INFO,
                field:"material_desc",
                resizable : true,
                filter : true,
                width:120,
            },
			
            {
                headerName:Constant.SR_GRADE,
                field:"sr_grade",
                resizable : true,
                filter : true,
                width:120,
            },            
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                width:200,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                width:120,
                resizable : true,
                filter : true,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"transporter_zone",
                width:140,
                resizable : true,
                filter : true,
            },
			{
                headerName:Constant.COL_CONSIGNEE_STATE,
                field:"consignee_state",
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
                resizable : true,
            },
            {
                headerName:Constant.COL_CONSIGNEE_ADDRESS,
                field:"consignee_address",
                width:200,
				valueGetter:function(params){
					try{
						if(params.data.consignee_address != "" && params.data.consignee_address != undefined)
						{
							return params.data.consignee_address;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
                resizable : true,
            },
			{
				headerName: "Original ETA",
				field: "expected_trip_end",
				width: 120,
				resizable: true,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				valueGetter: function (params){
					if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}else{
						return "N/A"
					}
				},
				comparator: dateComparator,
			},
			{
				headerName: "Revised ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						{
						  if(params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined){
							  return "N/A"
						  }
						  else{
							  return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						  }
						}
						else{
							return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				cellClass: function (params) {
					let hourDifference = 0;
					if (params.data.revised_trip_end && params.data.expected_trip_end) {
						let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
						let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (hourDifference >= 1) {
						return 'bgColorDangerMedium'
					}

					if (hourDifference <= -1) {
						return 'bgColorSuccessMedium'
					}
					else {
						return 'N/A'
					}
				}

			},
            {
                headerName:Constant.COL_EXPECTED_DISTANCE_KM,
                field:"distance_in_km",
                width:180,
                resizable : true,
                filter : true,
            },
			{
				headerName: "Remaining Distance To Customer (KM)",
				field: "distance_from_dealer_location",
				width: 180,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.distance_from_dealer_location != "" && params.data.distance_from_dealer_location != undefined)
						{
							return params.data.distance_from_dealer_location ;
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			
			{
				headerName: "ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				hide: hideForNotCustomer,
					valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
					{
						if(params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined){
							return "N/A"
						}
						else{
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
					}
					else{
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}
				},
				
			}


			
			// {
			// 	headerName: "AMNS Distance (KM)",
			// 	field: "distance_in_km",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	cellRenderer: '', resizable: true
			// },

			// {
			// 	headerName: "Transit Time (Days)",
			// 	field: "transit_time",
			// 	width: 200,
			// 	filter: "agSetColumnFilter",
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	cellRenderer: '', resizable: true
			// },

        ]
        await this.setState({
					
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    if(param.data.leg_details != "" && param.data.leg_details != undefined)
                    {
                        try{
							var output = JSON.parse(param.data.leg_details);
                            output.map((e) => e.type_of_tracking = param.data.type_of_tracking);
							if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		                    {
                                var customer_code = JSON.parse(localStorage.getItem("customer_code"));
								// console.log(customer_code,"customer_code--")
                                if(customer_code.length > 0)
                                {
									var output1 = [];
                                    var otpt = await output.map(async(e) => {
										var customer_codes = e.consignee_code;
										customer_codes = parseInt(customer_codes).toString();
										// console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
										if(customer_code.indexOf(customer_codes) >= 0)
										{
											output1.push(e);
										}
                                    })
									var output = output1;
                                }             
                            }
                            console.log("output ", output)
                            param.successCallback(output);
                        }
                        catch(e){}
                    }
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'loadingarealegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
	
    onClickShowShipmentLegs= async (rownode) =>  {
        console.log("rownode", rownode)
        var commentHeaders = [
            {
				headerName : "",
				field : "",
				resizable : true,
				width:60,
				filter : true,
                cellRendererFramework:LegRouteMap,
			  },
			{
				headerName : Constant.COL_TRUCK_NO,
				field : "truck_no",
				resizable : true,
				width:120,
				filter : true
			  },
			//   {
			// 	headerName : Constant.COL_CONSIGNMENT_CODE,
			// 	field : "consignment_code",
			// 	resizable : true,
			// 	width:140,
			// 	filter : true
			//   },
			  {
				headerName : Constant.COL_LOADING_AREA_NAME,
				field : "fence_name",
				resizable : true,
				width:200,
				filter : true
			  },
			  {
				headerName : Constant.COL_LEGT_START_TIME,
				field : "first_inside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_LEGT_END_TIME,
				field : "first_outside_fence",
				resizable : true,
				width:160,
				filter : true,
                valueGetter:function(params){
                    try{
                        if(params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
			  },
			  {
				headerName : Constant.COL_TIME_SPEND,
				field : "time_spent",
				resizable : true,
				width:200,
				filter : true,
                valueGetter:function(params){
                    try{
                        
						return params.data.time_spent;
                        
                    }
                    catch(e){}
                }
			  },

        ]
        await this.setState({
			map_type_of_tracking : rownode.data.type_of_tracking,
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',
                                                    
                },
                getDetailRowData:async function(param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    if(param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined){
                        var startdate = param.data.tpa_entry_time;
                    }
                    else{
                        var startdate = param.data.parking_in_time;
                    }
                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
					// if(param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11)
					// {
					// 	var enddate = param.data.recent_dealer_reported;
					// }
					// else{
					// 	if(param.data.status == 6 || param.data.status == "6")
					// 	{
					// 		var enddate = param.data.force_closure_time;
					// 	}
					// 	else{
					// 		var enddate = moment.parseZone(new Date(cdate+":00")).utcOffset("+05:30")._d;
					// 	}
						
					// }
                    var parameters = {
                        consignment_code : param.data.consignment_code,
                        truck_no : param.data.truck_no,
                        startdate:startdate,
                        enddate:enddate
                    }
                    await redirectURL.post("/amns/loadingarealegsdata",parameters).then(async (response) =>{
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments=response.data;
                        var output=[]
                        if(comments.length > 0){
                            comments.map((item) => {
                                try{
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        var ste = item.first_inside_fence;
                                        var stsec = moment.parseZone(ste).format("x");
                                        item.orderpos = stsec;
                                    }
                                    if(item.first_outside_fence != "" && item.first_outside_fence != undefined){
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else{
                                        var etsec = moment.parseZone().format("x");
                                    }
                                    if(item.first_inside_fence != "" && item.first_inside_fence != undefined)
                                    {
                                        // console.log("etsec ", etsec)
                                        // console.log("stsec ", stsec)
                                        var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                        var timespent = secondsToDhms(diff);
                                    }
                                    else{
                                        var timespent = "";
                                    }
                                    item.time_spent = timespent;
                                        
                                }
                                catch(e){

                                }
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);
                        
                        
                        //console.log("Step 2 ",response.data)
                        
                    }).catch(function(error){
                        console.log(error);
                    })
                     
                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if(rownode.column.colDef.field == 'shipmentlegs')
        {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    }
	onClickForceClose = (params) =>{
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata:params.data,
			forceCloseRowNode : params,
			sliderForceCloseTranslate:"slider-translate-40p",
			overly:'show-m',
			reasonforceclose:""
		})
	}
	

    onShowShipmentLegRouteMap = (e) => {
		console.log(e,"nightdrivingData")
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if(e.first_inside_fence != "" && e.first_inside_fence != undefined)
        {
            var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
		}
        else{
            var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
		}
        if(e.first_outside_fence != "" && e.first_outside_fence != undefined)
        {
            var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");        
        }
        else{
            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
		
        var reqData = {
            truck_no:e.truck_no,
			// consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:ptime,
			seconddate:end_date,
			screen:"truck"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			// try{
				if(records.status == "failure" || records.status == "Failure")
				{
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle:'No Route data available',
						basicType:"danger",
						loadshow:'show-n'
					});
				}
				else{
                    if(records.coords.length == 0)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
                    else{
                        if(records != '')
                        {
                            
                            this.setState({
                                sliderRouteTranslate:"slider-translate-60p",
                                showDiv:'show-m',
                                mapinfo:records,
                                dealer:e.consignee_code,
                                consignment_code:"Truck : "+e.truck_no,
                                maptruckno:e.truck_no,
                                routeTruck:	{"truck_no":e.truck_no,"startTime":ptime,"endTime": end_date},
                                loadshow:'show-n',
                                sidebarSubHeader:"Truck Information",
                                overly:'show-m',
                                rownode:e,
                                leg_no:0,
                                tolls : records.tolls,
                                // map_type_of_tracking:e.type_of_tracking,
                                // baseRouteCoords: baseRouteCoords
                            });
                        
                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
				}
			// }
			// catch(e){
            //     console.log("here")
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:'No Route data available',
			// 		basicType:"danger",
			// 		loadshow:'show-n'
			// 	});
			// }
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onClickBulkForceClose()
	{
		this.setState({
			sliderBulkForceClose:'slider-translate',
			overly:"show-m"
		});
	}
	
	unmarkPriority=()=>{
		var rowIds = this.state.rowIds;
		console.log(rowIds,"rowIds")
		if(rowIds.length > 0)
		{
			var reqparams = {
				rowIds : rowIds
			}
			redirectURL.post("/amns/updateunmarkpriority",reqparams)
				.then((response)=>{
					console.log(response.data.message)
					if(response.data.message == "Success")
					{
						this.setState({
							show: true,
							basicTitle:'Successfully unmarked as priority',
							basicType:"success",
							loadshow:'show-m'
						});
						var reqparams = {
						}
						try{
							if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
							{
								reqparams.consigner_code = [localStorage.getItem("pc")];
								this.setState({
									consigner:[localStorage.getItem("pc")]
								})
							}
							else{
								reqparams.consigner_code = ["ALL"];
							}
						}catch(e){
							
						}
						if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
						{
							reqparams.transporter_codes = localStorage.getItem("transportercode")
						}
						if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
						{
							reqparams.customer_code = localStorage.getItem("customer_code")
						}
						this.onLoadShowTrucksList("urlpath", reqparams)
					}
					else
					{
						
						this.setState({
							show: true,
							basicTitle:'Failed to unmark as priority',
							basicType:"danger",
							loadshow:'show-n'
						});
					}
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
				
			}
			else
			{
				
				this.setState({
					show: true,
					basicTitle:'Select data to proceed',
					basicType:"danger",
					loadshow:'show-n'
				});
			}

	}
	updatePriority = () => {
		var rowIds = this.state.rowIds;
		console.log(rowIds, "rowIds")
		if (rowIds.length > 0) {
			var reqparams = {
				rowIds: rowIds
			}
			redirectURL.post("/amns/updatepriority", reqparams)
				.then((response) => {
					console.log(response.data.message)
					if (response.data.message == "Success") {
						this.setState({
							show: true,
							basicTitle: 'Successfully marked as priority',
							basicType: "success",
							loadshow: 'show-m'
						});
						var reqparams = {
						}
						try {
							if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
								reqparams.consigner_code = [localStorage.getItem("pc")];
								this.setState({
									consigner: [localStorage.getItem("pc")]
								})
							}
							else {
								reqparams.consigner_code = ["ALL"];
							}
						} catch (e) {

						}
						if (localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "") {
							reqparams.transporter_codes = localStorage.getItem("transportercode")
						}
						if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
							reqparams.customer_code = localStorage.getItem("customer_code")
						}
						this.onLoadShowTrucksList("urlpath", reqparams)
					}
					else {

						this.setState({
							show: true,
							basicTitle: 'Failed to mark as priority',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
				})
				.catch(function (e) {
					console.log("Error ", e)
				})

		}
		else {

			this.setState({
				show: true,
				basicTitle: 'Select data to proceed',
				basicType: "danger",
				loadshow: 'show-n'
			});
		}

	}
	showPriorityTrucks = async() => {
		var rowIds = this.state.rowIds;
		console.log(rowIds,"rowIds")
		await this.setState({
			priority : 1,
			priorityBtnShow : 'show-n',
			alltrucksBtnShow : 'show-m',
			loadshow:"show-m",
			isunmarkpriority:false
		})
		var reqparams = {
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = [localStorage.getItem("pc")];
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = ["ALL"];
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		await this.onLoadShowTrucksList("urlpath", reqparams)
	}
	showAllTrucks = async() => {
		await this.setState({
			priority:0,
			priorityBtnShow : 'show-m',
			alltrucksBtnShow : 'show-n',
			loadshow:"show-m",
			isunmarkpriority:true
		})
		var reqparams = {
		}
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				reqparams.consigner_code = [localStorage.getItem("pc")];
				this.setState({
					consigner:[localStorage.getItem("pc")]
				})
			}
			else{
				reqparams.consigner_code = ["ALL"];
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			reqparams.transporter_codes = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			reqparams.customer_code = localStorage.getItem("customer_code")
		}
		await this.onLoadShowTrucksList("urlpath", reqparams)
	}
	
	

	render() {
		var isNotSideAdmin = true; // By Default user is not an admin
		var userAdminRole = localStorage.getItem('roles') || [] // Retrieves the user roles ,if not assigns an empty array
		if(userAdminRole && userAdminRole.includes("SITE_ADMIN")){ //If roles is there and it includes "SITE_ADMIN" Enters into the if block
			var isNotSideAdmin = false 	// Indicates that it is "SITE_ADMIN" is true
		}
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;

		var maptransporter = '';
		if (this.state.rownode.transporter) {
			///console.log("map rownode ",this.state.rownode.transporter[0])
			maptransporter = this.state.rownode.transporter[0].transporter_name;
		}
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT"||localStorage.getItem("roles")=="CUSTOMER" ) ? true : false;
		//console.log("Rendere ",this.state.deptcode)			
		if(localStorage.getItem('user_type') == "MARKETING")
        {
            var hideforsales = true;
        }
        else
        {
            var hideforsales = false;
        }
        var hideData="";
        if(localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0)
        {
            var hideData = false;
        }
        else
        {
            var hideData = true;
        }
        var hideForCustomer="";
        if(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 )
        {
            var hideForCustomer = true;
			var hideData = true;
			var hideforsales = true;
        }
        else
        {
            var hideForCustomer = false;
        }
		
        var hideForbgs="";
        if(localStorage.getItem("pc") != "HZ" || localStorage.getItem('roles').indexOf("CUSTOMER") >= 0)
        {
            hideForbgs = true;
        }
		const columnwithDefs = [
			
			 {
			 	headerName: "Actions",
			 	field: "_id",
				width: 40,
			 	pinned: 'left',
				filter: false, resizable: true,
			headerCheckboxSelection: false,
		    	checkboxSelection: true

			 },
			{
				headerName: "View Route",
				field: "_id",
				width: 40,
				pinned: 'left',
				cellRenderer: 'consignmentActions'

			},


			{
				headerName: "Transit Delay",
				field: "transit_delay",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide : hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRendererSelector: function (params) {
					if (params.data.transit_delay == 1) {
						var rendComponent = {
							component: 'kpiTransitDelayActions'
							// component: 'notransit'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'notransit'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			{
				headerName: "Gps Data",
				field: "is_gps_working",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide : hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRendererSelector: function (params) {
					//console.log(params.data)
					var comp = 'gps';
					/*if(params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1 && params.data.sim_tracking_lifetime_in_trip == 0)
					{
						comp='noGpsLifetime'
					}					
					else if(params.data.no_gps_data == 0 || params.data.sim_tracking == 1)
					{
						comp='gps'						
					}
					else if(params.data.no_gps_data == 1 && params.data.sim_tracking == 0) 
					{
						comp='nogps'
					}*/
					if (params.data.is_gps_working == "WORKING"){
						comp = 'gps'
					} 
					else if (params.data.is_gps_working == "NOT_WORKING"){
						comp = 'nogps'
					}
					var rendComponent = {
						component: comp
					};
					return rendComponent
				},
				filter: true, resizable: true,
				
			},
			{
				headerName: "Overspeed",
				field: "overspeeding_exception",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide : hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRendererSelector: function (params) {
					if (params.data.overspeeding_exception == 1) {
						var rendComponent = {
							component: 'kpiActions'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'nooverspeed'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			{
				headerName: "Night Drive",
				field: "nightdriving_exception",
				width: 40,
				pinned: 'left',
				pivot: true,
				hide: hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRendererSelector: function (params) {
					//console.log("Except ",params.data.nightdriving_exception)
					//console.log("Consignemnt code ", params.data.consignment_code)
					if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
						var rendComponent = {
							component: 'kpiNightDriveActions'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'nonightdrive'
							//component: 'kpiNightDriveActions'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			// {
			// 	headerName: "Loading Delay",
			// 	field: "loading_delay_exception",
			// 	width: 40,
			// 	pinned: 'left',
			// 	pivot: false,
			// 	hide: hideonlyPRT,
			// 	cellRendererSelector: function (params) {
			// 		var rendComponent = {
			// 			component: 'kpiLoadingDelayActions'
			// 		};
			// 		return rendComponent;
			// 	},
			// 	filter: true, resizable: true
			// },
			// {
			// 	headerName: "Unloading Delay",
			// 	field: "unloading_delay_exception",
			// 	width: 40,
			// 	pinned: 'left',
			// 	pivot: false,
			// 	hide:hideonlyPRT,
			// 	cellRendererSelector:function(params){
			// 		if(params.data.unloading_delay_exception != undefined && params.data.unloading_delay_exception == 1)
			// 		{
			// 			var rendComponent = {
			// 				component: 'kpiUnLoadingDelayActions'
			// 			};
			// 			return rendComponent;
			// 		}
			// 		else{
			// 			var rendComponent = {
			// 				component: 'kpiUnLoadingDelayActions'
			// 			};
			// 			return rendComponent;
			// 		}
			//
			// 	},
			// 	filter: true,resizable: true
			// },

			{
				headerName: "Enroute Stoppages",
				field: "enroute_exception",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide : hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: 'kpiEnrouteStoppagesActions'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},
			
            {
                headerName: "",
                field: "shipmentlegs",
                width: 50,
                pinned: 'left',
                cellRenderer:'ShipmentLegs',
                filter: false,resizable: true,
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,               
            },
			{
                headerName: "",
                field: "loadingarealegs",
                width: 50,
                pinned: 'left',
                cellRenderer:'LoadingAreaLegs',
                filter: false,resizable: true
                
            },

			// {
			// 	headerName: "Day Wise Distance Trip",
			// 	field: "day_wise_distance_trip",
			// 	colId: "day_wise_distance_trip",
			// 	width: 40,
			// 	pinned: 'left',
			// 	pivot: false,
			// 	hide: hideonlyPRT,
			// 	cellRenderer: 'kpiDayWiseDistanceActions',
			// 	filter: true, resizable: true
			// },
			// {
			// 	headerName: "",
			// 	field: "truck_no",
			// 	width: 40,
			// 	pinned: 'left',
			//     pivot: false,
			// 	cellRendererSelector:function(params){

			// 		var rendComponent = {
			// 			component: 'truckConsignmentLegs'
			// 		};
			// 		return rendComponent

			// 	},
			// 	filter: true,resizable: true
			// },
			/*
			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 120,
				pinned: 'left',
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			*/
			// {
			// 	headerName: "Actions",
			// 	field: "_id",
			// 	colId: "_id",
			// 	pinned: 'left',
			// 	width: 40,
			// 	cellRenderer:'commentActions',
			// 	filter: false,resizable: true
			//
			// },
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				//rowGroup: true
			},
			{
				headerName: "Truck Type",
				field: "truck_type",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				//rowGroup: true
			},            
			{
				headerName: "Sim Provider",
				field: "sim_tracking_service_provider",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				hide : isNotSideAdmin,
				cellRenderer: '', resizable: true,
				valueGetter: function(params){
					if(params.data.sim_tracking_service_provider !=undefined && params.data.sim_tracking_service_provider !="")
					{
						if(params.data.sim_tracking_service_provider == 1 ||params.data.sim_tracking_service_provider == "1" ){
							return "Jio"
						}
						else if (params.data.sim_tracking_service_provider == 2 ||params.data.sim_tracking_service_provider == "2"){
							return "Non-Jio"
						}
					}
				},
			},         

            {
                headerName:Constant.COL_TRACKING_MODE,
                field:"type_of_tracking",
                width:120,
                pinned:"left",
                valueGetter: function(params){
                    if(params.data.type_of_tracking !=undefined && params.data.type_of_tracking !="")
                    {
                        if(params.data.type_of_tracking == 0)
                        {
                            return "Waiting For GPS";
                        }
                        else if(params.data.type_of_tracking == 1)
                        {
                            return "GPS Tracking";
                        }
                        else if(params.data.type_of_tracking == 2)
                        {
                            return "SIM Tracking";
                        }
                        else if(params.data.type_of_tracking == 3)
                        {
                            return "GPS & SIM Tracking";
                        }
                        else if(params.data.type_of_tracking == 4)
                        {
                            return "Sim Tracking Consent Pending";
                        }
                    }
                    else
                    {
                        return "Waiting For GPS";
                    }
                },
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
            },
			{
				headerName:Constant.COL_SHIPMENT_DATE,
				field: "shipment_time",
				colId: "shipment_time",
				width: 150,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				//filter: "agDateColumnFilter",
				cellRenderer:'',resizable: true,
				comparator: dateComparator,
				
				valueGetter:function(params){
					if(params.data.shipment_time == "Yet to Invoice"){
						return "Yet to Invoice"
					}
					else if(params.data.shipment_time != undefined && params.data.shipment_time != "")
					{
						return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
					}
					else{
						return "Not Available";
					}
				},	
			},   
			{
                headerName: Constant.COL_TIME_SPEND_AFTER_SHIPMENT_CREATION,
                field: "shipment_time",
                colId: "shipment_time",					
                id:"shipment_time",
                width: 180,   
                //pinned: 'left',              
                resizable: true,
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,
                valueGetter:function(params){
                    try{ 
                        // console.log( params.data.shipment_time)
                        var ste = params.data.shipment_time;
                        var stsec = moment.parseZone(ste).format("x");
                        if(params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "" && params.data.timestamp != null){
                            if(params.data.tpa_exit_time != "" && params.data.tpa_exit_time != undefined){
                                if(params.data.shipment_time < params.data.tpa_exit_time){
                                    var etsec = moment.parseZone(params.data.tpa_exit_time).format("x")
                                    var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                    // console.log(diff,"line 2047")
                                    var timespent = secondsToDhms(diff);
                                    return timespent;
                                }
                                else{
                                    return "0 Days 0 Hrs 0 Mins";
                                }
                                
                            }
                            else{
                                return "Not Available";
                            }   
                        }
                        else{
                            if(params.data.gate_in_time != "No Data Available" && params.data.gate_in_time != undefined && params.data.gate_in_time != "" && params.data.gate_in_time != null){
                                if(params.data.shipment_time < params.data.gate_in_time){
                                    var etsec = moment.parseZone(params.data.gate_in_time).format("x")
                                    var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                                    // console.log(diff,"line 2047")
                                    var timespent = secondsToDhms(diff);
                                    return timespent;
                                }
                                else{
                                    return "0 Days 0 Hrs 0 Mins";
                                }
                            }
                            else
                            {
                                return "Not Available";
                            }
                        }
                    }	
                    catch(e){
                        
                    }
                },           
            },
			{
				headerName: "Invoice Date",
				field: "invoice_time",
				width: 150,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {
					// if (params.data.parking_in_time != '' || params.data.parking_in_time != undefined) {
					// 	return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
					// }
					// else {
					// 	return "NA";
					// }
					if(params.data.invoice_time == "Yet to Invoice"){
						return "Yet to Invoice"
					}
					else if(params.data.invoice_time != undefined && params.data.invoice_time != "" )
					{
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					}
					else{
						return "Not Available";
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,

			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName:Constant.COL_CUSTOMER_CITY,
				field:"transporter_zone",
				width:150,
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,
			},
			{
				headerName:Constant.COL_LAST_CITY,
				field:"area",
				colId:"area",					
				width:150,
				// hide:showOnlyCol,
				// valueGetter:function(params){
				// 	//console.log(params.data.truck);
				// 	if (params.data.hasOwnProperty('truck'))
				// 	{
				// 		if (params.data.truck.length > 0)
				// 		{
				// 		  return params.data.truck[0].area;
				// 		}
				// 	}
					  
				// },
				filter: true,
				cellRenderer:'',resizable: true
			},    
			  {
				headerName:Constant.COL_LAST_STATE,
				field:"state",
				colId:"state",
				width:120,
				// hide:showOnlyCol,
				filter: true,
				cellRenderer:'',resizable: true,
				// valueGetter:function(params){
				// 	//console.log(params.data.truck);
				// 	if (params.data.hasOwnProperty('truck'))
				// 	{
				// 		if (params.data.truck.length > 0)
				// 		{
				// 		  return params.data.truck[0].state;
				// 		}
				// 	}
					  
				// }
			},
            {
                headerName: "Route Id",
                field: "consignee_route_id",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },            
			{
				headerName: Constant.COL_FORCE_CLOSE,
				field: "",
				colId: "consignment_codes",					
				width: 80,
				cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'consignmentforceclose'
					};
					return rendComponent;
					
				},
				hide:hideForCustomer || !localStorage.getItem('email').includes('enmovil'),
				suppressColumnsToolPanel:hideForCustomer,
				filter: true,resizable: true,
				
			},        
			   
			{
				headerName: Constant.COL_DRIVER_NAME,
				field: "driver_name",
				colId: "driver_name",				  
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer:'',
				resizable: true   
			  },
			  {
				headerName: Constant.COL_DRIVER_NUMBER,
				field: "driver_mobile_no",
				colId: "driver_mobile_no",				  
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer:'',
				resizable: true,
				hide:hideforsales,
				suppressColumnsToolPanel:hideforsales,
			  },
			  {
				headerName: "Shipment No",
				field: "shipment_id",
				width: 150,
				filter: "agSetColumnFilter",
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				cellRenderer: '', resizable: true
			},
			{
				headerName:Constant.COL_CUSTOMER_NAME,
				field:"consignee_name",
				width:200,
				resizable : true,
				filter : true,
			},
			{
				headerName:Constant.COL_CUSTOMER_CODE,
				field:"consignee_code",
				width:120,
				resizable : true,
				filter : true,
			},
			// {
			// 	headerName:Constant.COL_CUSTOMER_CITY,
			// 	field:"transporter_zone",
			// 	width:140,
			// 	resizable : true,
			// 	filter : true,
			// },
			{
				headerName:Constant.COL_CONSIGNEE_STATE,
				field:"consignee_state",
				width:140,
				valueGetter:function(params){
					try{
						if(params.data.consignee_state != "" && params.data.consignee_state != undefined)
						{
							return params.data.consignee_state;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				resizable : true,
			},
			{
				headerName:Constant.COL_CONSIGNEE_ADDRESS,
				field:"consignee_address",
				width:200,
				valueGetter:function(params){
					try{
						if(params.data.consignee_address != "" && params.data.consignee_address != undefined)
						{
							return params.data.consignee_address;
						}
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				},
				resizable : true,
			},
            
			// {
			// 	headerName: "Original ETA",
			// 	field: "expected_trip_end",
			// 	width: 120,
			// 	resizable: true,
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	valueGetter: function (params){
			// 		if(params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined) {
			// 			return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
			// 		}else{
			// 			return "N/A"
			// 		}
			// 	},
			// 	comparator: dateComparator,
			// },
			// {
			// 	headerName: "Revised ETA",
			// 	field: "revised_trip_end",
			// 	width: 120,
			// 	resizable: true,
			// 	hide:hideForCustomer,
			// 	suppressColumnsToolPanel:hideForCustomer,
			// 	valueGetter: function (params) {
			// 		//console.log(" ETA ", params.data)
			// 		if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
			// 			{
			// 			  if(params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined){
			// 				  return "N/A"
			// 			  }
			// 			  else{
			// 				  return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
			// 			  }
			// 			}
			// 			else{
			// 				return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
			// 			}
			// 	},
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	cellClass: function (params) {
			// 		let hourDifference = 0;
			// 		if (params.data.revised_trip_end && params.data.expected_trip_end) {
			// 			let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
			// 			let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
			// 			hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
			// 			//console.log("diff= ", params.data.consignment_code, hourDifference);
			// 		} else {
			// 			//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
			// 		}

			// 		if (hourDifference >= 1) {
			// 			return 'bgColorDangerMedium'
			// 		}

			// 		if (hourDifference <= -1) {
			// 			return 'bgColorSuccessMedium'
			// 		}
			// 		else {
			// 			return 'N/A'
			// 		}
			// 	}

			// },

			
			{
				headerName: "Status",
				field: "status",
				width: 120,
				pinned: 'left',
				filter: "agSetColumnFilter",
				resizable: true,
				//  hide:hideData,
				suppressColumnsToolPanel:hideData,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if(localStorage.getItem("pc")=="HZ"){
						if(params.data.status == 1)
						{
							return Constant.INSIDE_TPA;
						}
						else if(params.data.status == 2)
						{
							return Constant.INSIDE_MY_PMILL;
						}
						
						if(params.data.status == 1 || params.data.status == "1")
						{
							return Constant.INSIDE_TPA;
						}
						if(params.data.status == 2 || params.data.status == "2")
						{
							return  Constant.INTRANSIT_TO_MY;
						}
						if(params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5")
						{
							return  Constant.INSIDE_MY_PMILL;
						}			
						else if(params.data.status == 6)
						{
							return Constant.FORCE_CLOSE;
						}
						else if(params.data.status == 7)
						{
							return Constant.INSIDE_TPA_AFTER_LOADING;
						}
						else if(params.data.status == 8 )
						{
							return Constant.INTRANSIT;
						}
						else if(params.data.status == 9 )
						{
							return Constant.NEAR_CUSTOMER;
						}
						else if(params.data.status == 10 )
						{
							return Constant.REACHED_CUSTOMER;
						}
						else if(params.data.status == 11 )
						{
							return Constant.LEFT_CUSTOMER;
						}
						else{
							return ""
						}
						 
					} 
					else{
						if(params.data.status==1||params.data.status==2||params.data.status==3||params.data.status==4||
							params.data.status==5){
								return "At Plant"
						}
						else if(params.data.status == 6)
						{
							return Constant.FORCE_CLOSE;
						}
							
						else if(params.data.status == 7 || params.data.status == 8 )
						{
							return Constant.INTRANSIT;
						}
						else if(params.data.status == 9 )
						{
							return Constant.NEAR_CUSTOMER;
						}
						else if(params.data.status == 10 )
						{
							return Constant.REACHED_CUSTOMER;
						}
						else if(params.data.status == 11 )
						{
							return Constant.LEFT_CUSTOMER;
						}
					}
					

				}
			},
			// {
			//   headerName: "Transporter Name",
			//   field: "transporter",
			//   valueGetter:function(params){
			// 	  //console.log(params.data.transporter);
			// 	  if (params.data.hasOwnProperty('transporter'))
			// 	  {
			// 		  if (params.data.transporter.length > 0)
			// 		  {
			// 			return params.data.transporter[0].transporter_name;
			// 		  }
			// 	  }

			//   },
			//   width: 150,
			//   filter: "agSetColumnFilter",
			//   cellRenderer:'',resizable: true
			// },
			// {
			// 	headerName: "GPS Provider",
			// 	field: "truck",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer:'',resizable: true,
			// 	valueGetter:function(params){
			// 		//console.log(params.data.transporter);
			// 		if (params.data.hasOwnProperty('truck'))
			// 		{
			// 			if (params.data.truck.length > 0)
			// 			{
			// 			  return params.data.truck[0].actual_lspuser;
			// 			}
			// 		}

			// 	},
			// },
			// {
			// 	headerName: "Invoice Nos",
			// 	field: "invoice_nos",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },

			// {
			// 	headerName: "Batch No",
			// 	field: "consignment_codes",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },

			{
				headerName: "Parking Entry No",
				field: "parking_id",
				width: 150,
				filter: "agSetColumnFilter",
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Parking Entry Time",
				field: "parking_in_time",
				width: 150,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {
					if(params.data.parking_in_time != undefined && params.data.parking_in_time != "" )
					{
						return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
					}
					else{
						return "Not Available";
					}
				},
			},
			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 150,
				filter: "agSetColumnFilter",
				hide:hideData,
				cellRenderer: '', resizable: true
			},
			{
				headerName: Constant.COL_DRIVER_CODE,
				field: "driver_code",
				colId: "driver_code",				  
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer:'',
				resizable: true,
				hide:hideData,
                // hide:hideData,
				suppressColumnsToolPanel:hideData,
			  },
			{
				headerName: "Travel Status",
				field: "travel_status",
				width: 150,
				filter: "agSetColumnFilter",
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
			},

			{
				headerName: "Speed (kmph)",
				field: "speed",
				width: 150,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					try{
						if(params.data.speed != "" && params.data.speed != undefined && params.data.speed != "NA")
						{
							return parseInt(params.data.speed);
						}
						else if (params.data.speed == 0){
							return "0"
						}
						else{
							return "NA";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Last Location",
				field: "address",
				width: 150,
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.address != "" && params.data.address != undefined)
						{
							return params.data.address ;
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				
				resizable: true,
				hide:hideForCustomer,
				suppressColumnsToolPanel:hideForCustomer,
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				valueGetter:function(params){
					try{
						if(params.data.timestamp != "" && params.data.timestamp != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
						}
						
						else{
							return "No Data Available";
						}

					}
					catch(e){}
				}
			

			},
			
            {
                headerName:"Truck Type",
                field:"truck_type",
                width:140,
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,
				valueGetter:function(params){
					try{
						if(params.data.truck_type != "" && params.data.truck_type != undefined)
						{
							return params.data.truck_type ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
			
			{
                headerName:"FME Name",
                field:"fme_contact_name",
                width:140,
            },
            {
                headerName:Constant.COL_SHIP_TO,
                field:"ship_to",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.ship_to != "" && params.data.ship_to != undefined)
						{
							return params.data.ship_to ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SHIPP,
                field:"shipp",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.shipp != "" && params.data.shipp != undefined)
						{
							return params.data.shipp ;
						}
						else{
							return "No Data from API";
						}
					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SOLD_TO,
                field:"sold_to",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.sold_to != "" && params.data.sold_to != undefined)
						{
							return params.data.sold_to ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            
            {
                headerName:Constant.COL_SOLDP,
                field:"soldp",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.soldp != "" && params.data.soldp != undefined)
						{
							return params.data.soldp ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SOLD_TO_CITY,
                field:"ship_to_city",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_city != "" && params.data.sold_to_city != undefined)
						{
							return params.data.sold_to_city ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            
            {
                headerName:Constant.COL_SOLD_TO_STATE,
                field:"ship_to_state",
                width:140,
				hide:hideData,
				suppressColumnsToolPanel:hideData,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_state != "" && params.data.sold_to_state != undefined)
						{
							return params.data.sold_to_state ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SOLD_TO_PINCODE,
                field:"ship_to_pincode",
                width:140,
				hide:hideData,
				suppressColumnsToolPanel:hideData,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_pincode != "" && params.data.sold_to_pincode != undefined)
						{
							return params.data.sold_to_pincode ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SOLD_TO_ADDRESS,
                field:"ship_to_address",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:200,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_address != "" && params.data.sold_to_address != undefined)
						{
							return params.data.sold_to_address ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
            {
                headerName:Constant.COL_SOLD_TO_CONTACT_NAME,
                field:"ship_to_contact_name",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_contact_name != "" && params.data.sold_to_contact_name != undefined)
						{
							return params.data.sold_to_contact_name ;
						}
						
						else{
							return "No Data from API";
						}
					}
					catch(e){}
				}
            },
            
            {
                headerName:Constant.COL_SOLD_TO_CONTACT_NO,
                field:"ship_to_contact_no",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
                width:140,
				valueGetter:function(params){
					try{
						if(params.data.sold_to_contact_no != "" && params.data.sold_to_contact_no != undefined)
						{
							return params.data.sold_to_contact_no ;
						}
						
						else{
							return "No Data from API";
						}

					}
					catch(e){}
				}
            },
			{
				headerName:"Is Customer Coords Available",
				field:"is_geo_coords_available",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
				width:200,
				valueGetter: function(params){
					try{
						if(params.data.is_geo_coords_available == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
					catch(e){

					}
				}
			},
			{
				headerName:"Is Customer Geofence Available",
				field:"is_geo_fence_available",
				hide:hideData,
				suppressColumnsToolPanel:hideData,
				width:200,
				valueGetter: function(params){
					try{
						if(params.data.is_geo_fence_available == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
					catch(e){

					}
				}
			},
			{
                headerName: Constant.COL_TIME_SPEND_AFTER_SHIPMENT_CREATION + " Marked From",
                field: "gate_in_time",
                colId: "gate_in_time",					
                id:"gate_in_time",
                width: 230,   
                //pinned: 'left',              
                resizable: true,
				hide:hideForbgs,
				suppressColumnsToolPanel:hideForbgs,
                valueGetter:function(params){
                    try{ 
                        if(params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "" && params.data.timestamp != null){
                            return "Geo Fence";
                        }
                        else{
                            if(params.data.gate_in_time != "No Data Available" && params.data.gate_in_time != undefined && params.data.gate_in_time != "" && params.data.gate_in_time != null){
                                return "API";
                            }
                            else
                            {
                                return "Not Available";
                            }
                        }
                    }	
                    catch(e){
                        
                    }
                },           
            },   
			{
				headerName: "Old Truck No",
				field: "old_truck_no",
				width: 120,
				hide:hideData,
				 resizable: true,
				 
			},
			{
				headerName: "Transshipment Date",
				field: "transshipment_date",
				width: 120,
				hide:hideData,
				 resizable: true,
				 valueGetter:function(params){
					try{
						if(params.data.transshipment_date != "" && params.data.transshipment_date != undefined)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.transshipment_date);
						}
						
						else{
							return "";
						}

					}
					catch(e){}
				}
			},
			{
				headerName: "Is Transshipment",
				field: "is_transshipment",
				width: 120,
				hide:hideData,
				 resizable: true,
				 valueGetter:function(params){
					if(params.data.is_transshipment==1){
						return 'Yes'
					}
					else{
						return 'No'
					}
				 } 
			},
			{
				headerName: "Old Driver Name",
				field: "old_driver_name",
				width: 120,
				hide:hideData,
				 resizable: true,
				 
			},
			{
				headerName: "Old Driver Mobile No",
				field: "old_driver_mobile_no",
				width: 120,
				hide:hideData,
				 resizable: true,
				 
			},
			{
				headerName: "Trip Status",
				field: "trip_status",
				width: 130,
				editable: false,
				filter: true,
			},	
		];
		const commentColumns = [
			{
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				cellClass: ['wraptext'],
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getYYYYMMDDHHMMSS(params.data.comment_date);
					}
				}
			}
		]
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		var hText = 'All';
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = ''
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = ''
			mnFilterClass = { marginLeft: "0px" };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = ''
			filterCardStyle1 = { minHeight: "240px" };
		}
		const tripoptoins = [
			{ "value": "0", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12">
						{localStorage.getItem("pc") == "HZ" || localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 || localStorage.getItem('roles').indexOf("MARKETING") >= 0 ?
							<ul className="ul-tabs">
								{(localStorage.getItem("user_type") != "CUSTOMER" && localStorage.getItem("pc") == "HZ") ?
									<li className="">
										<a href={"/shipmenttrucks"}>Inter Plant Movement</a>
									</li>
									: ""}
								<li>
									{(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) ?
										<a className="active" href={"/customertrucks"}>Active Trucks</a>
										: ""}
									{(localStorage.getItem('roles').indexOf("MARKETING") >= 0) ?
										<a className="active" href={"/customertrucks"}>Active Trucks</a>
										: ""}
									{(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 || localStorage.getItem('roles').indexOf("MARKETING") >= 0) ? "" :
										localStorage.getItem("pc") == "HZ" ?
											<a className="active" href={"/sndtrucks"}>Intransit-Road</a> : ""
									}
								</li>
								{(localStorage.getItem('roles').indexOf("CUSTOMER") >= 0 || localStorage.getItem('roles').indexOf("MARKETING") >= 0 || localStorage.getItem("pc") != "HZ") ? "" :
									<li>
										<a href={"/rail-consignments/default"}>Intransit-Rail</a>
									</li>
								}
								<li className="float-right">
									<button onClick={this.reloadPageData} className="btn btn-info float-right" style={{ marginTop: "-15px" }}>Reset Filters</button>
								</li>
							</ul> : 
							<div className="row">
							<div className="col-xl-12 col-lg-12 col-sm-12">
								<button className="btn btn-info float-right" style={{ marginRight: "-2px", marginBottom: "10px" }} onClick={this.reloadPageData}>
									Reset Filters
								</button>
							</div>
						</div>}
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments}>
									<div className={filterClassName}>
										<label>Select Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={this.state.filterOptions}
											onChange={e => { this.setState({ selectedFilter: e.label }) }}
											required />
									</div>
									<div className={filterClassName}>
										<label className="reason">{this.state.filterNames}</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{(this.state.deptcode == 'LOG-PRT') ?
											<div className="form-group col-xl-2 col-lg-2 n-p-0">
												<label>Select Type</label>
												<Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler} style={{ borderRadius: "0px" }} options={this.selectMovementTypeOptionsItems()} required />

											</div>
											: ""}
										{(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined || localStorage.getItem("roles")=="CUSTOMER")?
										"":	
											<div className="form-group col-xl-4 col-lg-4">
												<label>Select Consigner</label>
												<Select
													placeholder={"All"} closeMenuOnSelect={false}
													value={this.state.consigner} isMulti="true"
													className="border-radius-0"
													onChange={this.changeConsignerArrayHandler}
													style={{ borderRadius: "0px" }}
													options={this.selectConsignerOptionsItems()} required />

											</div>
										}
										<div className="col-xl-3 col-lg-3 form-group" style={{ zIndex: 1 }}>
											<label>Invoice From Date</label>
											{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: 'true'}}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTime} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group" style={{ zIndex: 1 }}>
											<label>Invoice To Date</label>
											{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: 'true'}}
												dateFormat="YYYY-MM-DD"
												onChange={this.handlerEndDateTime} />
										</div>
									</div>
									: ""}
								
								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
								
									{(localStorage.getItem("roles")!="CUSTOMER") ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Customers</label>
											<Select
												className="border-radius-0"
												closeMenuOnSelect={true}
												isMulti={true}
												id="selectedCustomer"
												style={{ borderRadius: "0px" }}
												value={this.state.flCustomer}
												onChange={this.newFilter.bind(this, "flCustomer")}
												options={this.state.filterCustomers}
											/>
										</div>
									: ""}
								
									{/* {(localStorage.getItem("roles")!="CUSTOMER") ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Customer City</label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.newFilter.bind(this, "flCity")}
												options={this.state.filterCities}
											/>
										</div>
									: ""} */}
									{(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")== "CUSTOMER") ? "":
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div>
									}
									{(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")== "CUSTOMER") ? "":
									<div className="form-group col-xl-3 col-lg-3">
										<label>Customer City</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporterZone"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporterZone}
											onChange={this.newFilter.bind(this, "flTransporterZone")}
											options={this.state.filterTransportersZones}
										/>
									</div>
									}
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners}>Submit</button>
									</div>
							</div>
						</div>
					</div>

				</div>
				{/*  :""} */}
				{(localStorage.getItem('roles')!="CUSTOMER")?
				<Counters
					context={this}
					gridData={this.state.rowData}
					deptcode={this.state.deptcode}
				/>:
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounterShowData.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> {Constant.COUNTER_TOTAL_TRUCKS}</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.rowData != '')?this.state.rowData.length:0}/></span></h4>
                                    
                                </div>
							</div>	
							</div>	
							</div>	
							</div>
							</div>
				}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Active Trips </span>

								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
									{(localStorage.getItem('roles')!="CUSTOMER")?
										<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
											<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route
											&nbsp;
											<i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "22px" }}></i> - Transit Delay
											{/* &nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate */}
											&nbsp;<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px	" }}></i> - Overspeed
											{!(this.state.deptcode == 'LOG-PRT') ?
												<span>
													<span>&nbsp;<i className="icofont icofont-full-night txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving
													</span>
													{/* <span>&nbsp;<i className="icofont icofont-truck-alt darkorange" style={{ fontSize: "22px" }}></i> - Loading Delay
													</span> */}

												</span>
												: ""}
											<span>&nbsp;<i className="icofont icofont-pause maroon" style={{ fontSize: "22px" }}></i> - Enroute Stoppages
											</span>
											{/* <span>&nbsp;<i className="icofont icofont-list" style={{ fontSize: "22px" }}></i> - Day Wise Distance Trip
											</span> */}
											{/*&nbsp;<i className="icofont icofont-pause" style={{color:"rgb(174, 70, 239)", fontSize:"22px	"}}></i> - Enroute Stoppages*/}


										</span>:""}

									</div>
									<div className="col-xl-12 mb-10p">
										{/* <button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
											<i className="icofont icofont-social-google-map"></i> Map View
										</button> */}

										<span className="layoutbtns">
										<button className="float-right custom-btn btn-primary white" type="button" onClick={this.onClickBulkForceClose.bind(this)} style={{marginRight:"10px"}}>
											Bulk Force Close
										</button>
											{this.state.isunmarkpriority ? 
											<button className="float-right custom-btn white btn-warning" type="button" onClick={this.updatePriority.bind(this)}> Mark As Priority
												</button> : <button className="float-right custom-btn white btn-warning" type="button" onClick={this.unmarkPriority.bind(this)} > Unmark Priority
												</button>}
											<button className="float-right custom-btn white btn-danger" style={{ marginRight: "10px" }}  onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
											{(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0) ?
											<a href={"/sndconsignments"} className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }}>
												Go To Consignments
											</a>
											:""}
											<button className={"float-right custom-btn white btn btn-secondary " + this.state.priorityBtnShow} type="button" onClick={this.showPriorityTrucks.bind(this)} style={{ marginRight: "10px" }}> Show Priority Trucks
												</button>
												
												<button className={"float-right custom-btn white btn btn-secondary " + this.state.alltrucksBtnShow} type="button"  style={{ marginRight: "10px" }} onClick={this.showAllTrucks.bind(this)}> Show All Trucks
												</button>
										</span>
									</div>
								</div>
								{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}
								<div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        
                                        enableCellChangeFlash={false}
                                        //suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // getRowHeight={this.state.getRowHeight}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                                        
                                        rowSelection={this.state.rowSelection}
                                         onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        // suppressCellSelection= {true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles= {this.state.excelStyles}
                                        // onCellClicked={this.onCellClicked.bind(this)}
		          		             />
									
		          		        </div>

								{/* <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//onRowSelected={this.onRowSelection.bind(this)}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										 rowSelection={this.state.rowSelection}
										// onRowSelected={this.onRowSelection.bind(this)}
									/>


								</div> */}
							</div>
						</div>
					</div>

				</div>
				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}
				{this.state.sliderTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12">
								<VerticalTimeline>
									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										date="Basic Information"
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Truck No</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no}</h4>
										<h3 className="vertical-timeline-element-title">Transporter Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code}</h4>
										<h3 className="vertical-timeline-element-title">Department Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code}</h4>
										<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode}</h4>
										<h3 className="vertical-timeline-element-title">Items</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items}</h4>
										<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km}</h4>

									</VerticalTimelineElement>


									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Gate in time"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence}</h4>

										<h3 className="vertical-timeline-element-title">Invoice time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="MSIL Gate out time"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Consignee Code"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Consignee Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code}</h4>

									</VerticalTimelineElement>

								</VerticalTimeline>

							</div>
						</div>
					</div>
					: ""
				}

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								{/* {console.log("this.state.mapinfo ", this.state.mapinfo)} */}
								<DrawMap
									mapFor={"truck"}
									consignercords={this.state.consigner_coordinates}
									tolls={this.state.tolls}
									consigneecords={this.state.consignee_coordinates}
									rownode={this.state.rownode}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords} 
									map_type_of_tracking={this.state.map_type_of_tracking}
								/>									
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{(localStorage.getItem('roles')!="CUSTOMER")?
												<div className="col route-block">
													<label className="sidebar-label">Tracking Type</label>
													{(this.state.map_type_of_tracking == 1)?
													<div>GPS Tracking</div>
													:
													<div>SIM Tracking</div>
													}
												</div>
											:""}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{ overlay: 'customOverlay', modal: 'customModal' }}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap
								context={this} griddata={this.state.maprowData}
								consigner_coordinates={this.state.consigner_coordinates}
								consigneecoords={this.state.consigneecoords}
								rownode={this.state.rownode}
								mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>
				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideForForceClosure.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input readonly type="text" placeholder="Reached Dealer " value={this.state.startDatetime} id="reached_dealer_time" className="datetimepicker_mask form-control" />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input readonly type="text" placeholder="Left Dealer " value={this.state.endDatetime} id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
							{/* <ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode} /> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
							<div className="col-xl-12 co-xl-12">
                    <div className="form-group col-xl-12 col-lg-12">
                            <label>Select Reason </label>
                            <Select 
                           onChange={this.selecetedReason.bind(this)} 
                            closeMenuOnSelect={true}
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            options={this.selectReason()} />            
                    </div>
                             <div className={"form-group col-xl-12 col-lg-12 "+(this.state.displayReasonField)}>
                                <label className="col-xl-12 col-lg-12">Reason for closure</label>
                                <textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)}  rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
                            </div> 
                           
									<div className="col-xl-4 col-lg-4 form-group">
										<label>Delivery Date</label>
										<Datetime
											value={this.state.deliveryDate}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'From', name: 'deliveryDate', autoComplete: 'off', readOnly: 'true' }}
											dateFormat="YYYY-MM-DD"
											name="deliveryDate"
											onChange={this.handlerDeliveryDateTime} />
									</div>
									<div className="form-group col-xl-12 col-lg-12">
										<button type="submit" className="btn btn-success" >submit</button>
									</div>
								</div>

							</form>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderBulkForceClose)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHide.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkForceCloseHandler.bind(this)}>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input id="formBulkForceCloseHandler" type="file" name="uploadFile" onChange={this.changeBulkForceFileHandler.bind(this)}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rail_bulk_force_close.csv")} target="_blank" download="sample_rail_bulk_force_close.csv" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.slideBar)} >
					{this.displayData}
				</div>
			</div>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function onSetCritical(params, status) {
	// console.log("onSetCritical ", params);
	// console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus', {
		params: params,
		is_critical: status
	})
		.then((response) => {

			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data);
		});

}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
