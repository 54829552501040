
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Submenus = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowMenus(props);
    };
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn label label-success" 
             ><i className="icofont icofont-eye-alt"></i> View Menus</button>
            
        </div>
    );
};

export default Submenus;
