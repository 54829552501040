import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class BarComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	//console.log(this.props.lineseries);
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
        		
        		chart: {
        	        type: 'bar'
        	    },
        	    title: {
        	        text: 'Historic World Population by Region'
        	    },
        	   
        	    xAxis: {
        	        categories: this.props.barxaxis,
        	        title: {
        	            text: null
        	        }
        	    },
        	    yAxis: {
        	        min: 0,
        	        title: {
        	            text: '',
        	            align: 'high'
        	        },
        	        labels: {
        	            overflow: 'justify'
        	        }
        	    },
        	    tooltip: {
        	        valueSuffix: ' millions'
        	    },
        	    plotOptions: {
        	        bar: {
        	            dataLabels: {
        	                enabled: true
        	            }
        	        }
        	    },
        	    legend: {
        	        layout: 'vertical',
        	        align: 'right',
        	        verticalAlign: 'top',
        	        x: -40,
        	        y: 80,
        	        floating: true,
        	        borderWidth: 1,
        	        shadow: true
        	    },
        	    credits: {
        	        enabled: false
        	    },
        	    colors:['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
        	    series: this.props.barseries
      		};
        
        return ( 
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>{this.props.barTitle}</h5>
                        </div>
                        <div className="card-body">
                            <div className="dashboard-chart-container sales-overview-chart">
                            <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

                            </div>
                        </div>
                    </div>
                </div>
			);
		}
}












