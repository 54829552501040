import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import AndriodMap from "../common/andriodcurrentlocationweb";
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'

import FullCalendar, { parseDragMeta } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import Editrailsummary from "./tpteditrailsummary";
// import { getAndriodMapRoutes } from '../../../../tpt_node/routes/controller/consignmentController';

$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class AMNSRailSummary extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
			basicType1:"default",
			showrel: false,
            basicTitlerel:'',
            basicTyperel:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
				Editrailsummary:Editrailsummary
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Summary Dashboard",
            rakeslist:[],
            loadingpoints:[],
            destinationpoints:[],
            transporters:[],
            transporter:'',
            arrival_Date:'',
            msilrakeno:'',
            msilactualrake:'',
            rakeEvents:[],
            gridview:'show-m',
            calcview:'show-m',
            selectedtransporter:'',
            selectedrake:'',
            selectedload:'',
            selecteddestination:'',
            transporterinfo:'',
            formtype:"add",
            rowitem:[],
            dactminDate:"",
            dactmaxDate:"",
            dminDate:"",
            dmaxDate:"",
            destinationloadcapacity:'270',
            sliderRouteTranslate:'',
            rakeitem:"",
            loadpoint:"",
            destinationpoint:"",
            statustext:"",
            calview:"timeGridDay",
            invoicedcnt:0,
            exposedinvoicedcnt:0,
            plannedcnt:0,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            maprakeno:"",
            maprakestart:"",
            maprakeend:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderSearchTruckTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
            actual_rake_records : [],
            revisedCounter : [],
            msilrakenoexists:'',
            to_be_approved_list : [],
			sourceopt:'',
			sources:[],
			destopt:"",
			destinations:[],
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
			active_tab_secondary: '1',
			searchtype:{"value":"","label":"Select Search Type"},
			truck_no:{"value":"","label":"Select Truck"},
			device_id:{"value":"","label":"Select Device"},
			trucks:[],
			devices:[],
			truck:"0",
			device:"0",
			showtruck:"show-m",
			showdevice:"show-n",
			showmap:"0",
			raildata:"",
			oemsources:[],
			sourceoem:{value:"",label:"Select Source"}
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onShowBatchLegs = this.onShowBatchLegs.bind(this);
		this.toggle = this.toggle.bind(this);
		this.onClickUpdateRailSummary = this.onClickUpdateRailSummary.bind(this);
		this.formUpdateSourceDestination = this.formUpdateSourceDestination.bind(this);

	}

    componentDidMount(){
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');

        this.setState({
            gridview:'show-m',
            calcview:'show-n',
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;

			dpt='SNDG';

        this.setState({
			dept_code:dpt,
        });


        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        var parameters = {
            startdate:sdate,
            enddate:edate
		}

        // redirectURL.post("/dashboard/mobiledevicetrucks")
		// .then( async (resp) => {
		// 	//console.log("resp ", resp)
		// 	var records = resp.data.records;
		// 	var trucks = []
		// 	var devicesarr = []
		// 	var devices = []
		// 	if(records.length > 0)
		// 	{
		// 		records.map((item) => {
		// 			trucks.push(item.truck_no);
		// 			devicesarr.push({"device_id":item.device_id});
		// 		})
		// 	}
		// 	// Declare an empty object
		// 	let totuniqueObject = {};
		// 	var totobjTitle;
		// 	// Loop for the array elements
		// 	for (let i in devicesarr) {

		// 		// Extract the title
		// 		totobjTitle = devicesarr[i]['device_id'];

		// 		// Use the title as the index
		// 		totuniqueObject[totobjTitle] = devicesarr[i];
		// 	}

		// 	// Loop to push unique object into array
		// 	for (var a in totuniqueObject) {
		// 		devices.push(totuniqueObject[a].device_id);
		// 	}
		// 	// console.log("devices ", devices)
		// 	this.setState({
		// 		trucks:trucks,
		// 		devices:devices
		// 	});
		// })
		// .catch(function(e){
		// 	console.log("Error")
		// })
		// var prms = {
		// 	transporter_code:localStorage.getItem("transportercode")
		// }
		// redirectURL.post("/dashboard/tptloaddestpoints",prms)
		// .then((response) => {
		// 	var records = response.data.records;
		// 	var loadings = [];
		// 	var destinatinos = [];
		// 	if(records.length > 0)
		// 	{
		// 		records.map((item) => {
		// 			if(item.rail_loading_point == 1)
		// 			{
		// 				loadings.push(item)
		// 			}
		// 			if(item.rail_delivery_point == 1)
		// 			{
		// 				destinatinos.push(item)
		// 			}
		// 		})
		// 	}
		// 	this.setState({
		// 		sources:loadings,
		// 		destinations:destinatinos
		// 	});
		// })

        this.onLoadRakePlanningData(parameters)

	}

    onLoadRakePlanningData(parameters)
    {
		try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				parameters.plant_code = localStorage.getItem("pc");
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
		{
			parameters.transporter_code = localStorage.getItem("transportercode")
		}
		// redirectURL.post("/dashboard/tptplantcoordinates")
		// .then((response) => {
		// 	var records = response.data.records;
		// 	this.setState({
		// 		oemsources:records
		// 	});
		// })
		// var prm = {
		// 	transporter_code:localStorage.getItem("transportercode")
		// }
		// if(localStorage.getItem("oem") != undefined && localStorage.getItem("oem") != "" && localStorage.getItem("oem") != "undefined")
		// {
		// 	prm.oem=localStorage.getItem("oem");
		// }
		redirectURL.post("/dashboard/tptsummary",parameters)
		.then((response) => {
			var records = response.data.records;
			// console.log("records ", records)
			// this.setState({
			// 	rowData:records
			// });
			var alrtflag=0;
			var recordsarr = []
			if(records.length > 0)
			{
				records.map(async (item) => {
					if(item.loadingyard == "" || item.loadingyard == undefined || item.destination == "" || item.destination == undefined){
						alrtflag = 1;
					}

					var recdata = item.consignments;
					var atplant=[];
					var intransitload=[];
					var atload=[];
					var rakeassign=[];
					var intransitdest=[];
					var atdestination=[];
					var intransitdealer=[];
					var intransitrso=[];
					var atrso=[];
					var neardealer=[];
					var podreceipt=[];

					if(recdata.length > 0)
					{
						recdata.map((im) => {

							if(parseInt(im.status) == 1)
							{
								atplant.push(im)
							}
							if(parseInt(im.status) == 2)
							{
								intransitload.push(im)
							}
							if(parseInt(im.status) == 3)
							{
								atload.push(im)
							}
							if(parseInt(im.status) == 4)
							{
								rakeassign.push(im)
							}
							if(parseInt(im.status) == 5)
							{
								intransitdest.push(im)
							}
							if(parseInt(im.status) == 6)
							{
								atdestination.push(im)
							}
							if(parseInt(im.status) == 7)
							{
								intransitrso.push(im)
							}

							if(parseInt(im.status) == 8)
							{
								atrso.push(im)
							}

							if(parseInt(im.status) == 9)
							{
								intransitdealer.push(im)
							}
							if(parseInt(im.status) == 10)
							{
								neardealer.push(im)
							}
							if(parseInt(im.status) == 11)
							{
								podreceipt.push(im)
							}
						})
					}
					recordsarr.push({
						_id:item._id,
						master_ref_no:item.master_ref_no,
						rail_no:item.rail_no,
						fnr_no:item.fnr_no,
						total_vins:item.consignments.length,
						source:item.source,
						loadingyard:item.loadingyard,
						destination:item.destination,
						uploaded_by:item.uploaded_by,
						uploaded_by_email:item.uploaded_by_email,
						uploaded_on:item.uploaded_on,
						atplant:atplant.length,
						intransitload:intransitload.length,
						atload:atload.length,
						rakeassign:rakeassign.length,
						intransitdest:intransitdest.length,
						atdestination:atdestination.length,
						intransitdealer:intransitdealer.length,
						intransitrso:intransitrso.length,
						atrso:atrso.length,
						neardealer:neardealer.length,
						podreceipt:podreceipt.length
					})

				})
			}
			// console.log("recordsarr ", recordsarr)
			// if(alrtflag == 1)
			// {
			// 	this.setState({
			// 		show:true,
			// 		basicType:"info",
			// 		basicTitle:"Source and Destination are not exists for rake, please update source and destination yards to rakes."
			// 	})
			// }
			this.setState({
				rowData:recordsarr
			});

		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='Summary Dashboard';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			try{
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch(e){

			}

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
	}

	reloadcloseAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
	}

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();

        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })

    }

	onClickShowSearchTruckSidebar = () =>{

        this.setState({
            sliderSearchTruckTranslate:"slider-translate-75p",
            overly:'show-m',
            formtype:"add"
        })

    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
			sliderSearchTruckTranslate:"",
			showmap:"0"
		});

    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}

    selectoemsources(){
		let items = [];
		try{
			if(this.state.oemsources.length > 0)
			{
				this.state.oemsources.map((item) =>{
					console.log(item)
					items.push({"value":item.plant_location_code,"label":item.plant_location_name+" ("+item.plant_location_code+")"})
				})
			}
		}
		catch(e){

		}

		return items;
	}
    selectsources(){
		let items = [];
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}

		return items;
	}


    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );


		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	    onChangeOEMSourceItem(sourceoem){
			this.setState(
				{ sourceoem },
				() => console.log(`Option selected:`, this.state.sourceoem)
	          );


			  this.setState({
				  errorMessage:'',
				  successMessage:''
			  })
		}

    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);

       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    // onClickShowView(view)
    // {
    //    // console.log("View ",view)
    //     if(view == "grid")
    //     {
    //         this.setState({
    //             gridview:'show-m',
    //             calcview:'show-n'
    //         })
    //     }
    //     if(view == "calender")
    //     {
    //         this.setState({
    //             gridview:'show-n',
    //             calcview:'show-m',
    //             calview:"timeGridWeek"
    //         })
    //     }
    // }

    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			sliderSearchTruckTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			showmap:"0"
		});
	}
	onClickShowMap = (params) =>{

    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;


	};
	 onGridState = () =>{
		 //console.log(this.gridApi);

		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns,
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();

		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);

    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);

        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }


		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true,
		// 		basicType:'danger',
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }

	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formBulkUpload = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload New Vins",
				"action": "form",
				"label": "Upload New Vins Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }

        // console.log("csvcontent ", this.state.csvcontent);
        // var reqparams = {
		// 	csvcontent:this.state.csvcontent,
		// 	source:this.state.sourceopt.value,
		// 	userid:localStorage.getItem("userid"),
		// 	username:localStorage.getItem("username"),
		// 	useremail:localStorage.getItem("email")
		// }
		// console.log("this.state.sourceopt ", this.state.sourceopt)
		if(this.state.sourceopt == "")
		{
			this.setState({
				show:true,
				basicTitle:"All fields are mandatory",
				basicType:"danger"
			})
		}
		else
		{
			var reqparams = new FormData()
			reqparams.append("file", this.state.file)
			reqparams.append("source", this.state.sourceopt.value)
			reqparams.append("loadingyard", this.state.sourceopt.label)
			reqparams.append("userid", localStorage.getItem("userid"))
			reqparams.append("username", localStorage.getItem("username"))
			reqparams.append("useremail", localStorage.getItem("email"))

			redirectURL.post("/dashboard/readSummaryData", reqparams)
			.then(async (response) => {
				// console.log("XLS ", response.data)
				var records = response.data.records;
				var vins = []
				var emptyvins = []
				if(records.length > 0)
				{
					await records.map((item) => {
						Object.keys(item).forEach(async function(key) {
							// console.log("VEHICLE ID ", key)
							if(key == "VEHICLE ID")
							{
								vins.push(item[key])
								//console.log("item[key] ", item[key])

							}

						})
						// console.log("prpery ", item.hasOwnProperty("VEHICLE ID"))
						if(item.hasOwnProperty("VEHICLE ID"))
						{

						}
						else{
							emptyvins.push(1)
						}
					})

				}
				// cosnsole.log("emptyvins ",emptyvins)
				if(emptyvins.length > 0)
				{
					this.setState({
						show:true,
						basicTitle:"Upload data should not be empty",
						basicType:"danger"
					})
				}
				else
				{
					var params = {
						"vins":vins,
						"xlsdata": records,
						"source" : this.state.sourceopt.value,
						"loadingyard" : this.state.sourceopt.label,
						"userid" : localStorage.getItem("userid"),
						"username": localStorage.getItem("username"),
						"useremail": localStorage.getItem("email"),
						"transporter_code": localStorage.getItem("transportercode")
					}
					redirectURL.post("/dashboard/uploadtptsummarydata", params)
					.then((response) => {

						if(response.data.message == "vins")
						{
							this.setState({
								show:true,
								basicTitle:"ALready vehicle ids are exists",
								basicType:"danger"
							})
						}
						else if(response.data.message == "vinsempty")
						{
							this.setState({
								show:true,
								basicTitle:"Vehicle Id is empty in uploaded file",
								basicType:"danger"
							})
						}
						else
						{
							this.setState({
								sliderRakeTranslate:"",
								overly:'show-n',
								transporter_code:"",
								transporter_name:"",
								csvcontent:[],
								loadshow:'show-n',
								uploadFile:'',
								file:'',
								showrel:true,
								basicTitlerel:"Successfully upload file",
								basicTyperel:"success"
							})
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate
							}
							this.onLoadRakePlanningData(parameters)
						}
					})
				}




			})
			.catch(function(e){
				console.log("Error ", e)
			})
		}

    }

	formSearchTruck = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload New Vins",
				"action": "form",
				"label": "Upload New Vins Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }

		if(this.state.truck_no.value == "" || this.state.device_id.value != "")
		{
			var truck_no = "0"
		}
		else
		{
			var truck_no = this.state.truck_no.value;
		}
		if(this.state.device_id.value == "" || this.state.truck_no.value != "")
		{
			var device_id = "0"
		}
		else
		{
			var device_id = this.state.device_id.value;
		}
		var parameters = {
			truck_no:truck_no,
			device_id:device_id
		}
		this.setState({
			truck:truck_no,
			device:device_id,
			showmap:"1"
		})

		// redirectURL.post("/dashboard/mobiledevicetrucklocation", parameters)
		// .then(async (response) => {
		// 	var records = response.data.records;
		// 	console.log("records ", records)
		// 	if(records.length > 0)
		// 	{
		// 		this.setState({
		// 			truck:truck_no,
		// 			device:device_id,
		// 			showmap:"1"
		// 		})

		// 	}

		// })
		// .catch(function(e){
		// 	console.log("Error ", e)
		// })


	}

    onOpenModal = () => {
		this.setState({ open: true });
	 };

	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };


	//Batch Legs
	onShowBatchLegs = async (params) => {
		//console.log(params);

		//console.log(params.column.colId);
		if(params != 0)
		{

			await this.setState({

				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName:"Batch No",
								field:"batchno",
								width:150,
								resizable:true
							},
							{
								headerName:"Total Vins",
								field:"totalvins",
								width:150,
								resizable:true
							},
							{
								headerName: "",
								field: "batchno",
								cellRenderer:function(params){
									var batchno = params.data.batchno;
									return '<a href="/mllmarkarrivalloadingyard/'+batchno+'" style="color:#333" class="label label-warning" target="_blank">Mark Arrival At Loading Yard</a>'
								},
								width:180,
								resizable:true
							},

						],
						overlayNoRowsTemplate: 'No data available',

					},
					getDetailRowData: function(param) {
						// console.log("param ", param)
						var parameter={
							master_ref_no:param.data.master_ref_no
						}
						redirectURL.post('/dashboard/mllbatchesbyrefno', parameter)
						.then(async (response) =>{
							var records = response.data.records;
							var batchdata = groupBy(records, rdata => rdata.batchno);
							// console.log("batchdata ", batchdata)
							var rows = []
							batchdata.forEach((item,key) => {
								if(key != "undefined" && key != undefined)
								{
									item[0].totalvins = item.length
									rows.push(item[0])
								}
							})
							console.log("rows ", rows)
							param.successCallback(rows);

						});

					}
				}
			});

			if(params.column.colDef.field == '_id')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					// transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [

					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}
	toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

	onChangeTruckTypeItem(searchtype){
		this.setState(
			{ searchtype },
			() => {
				console.log(`Option selected:`, this.state.searchtype)
				if(this.state.searchtype.value == "truck"){
					this.setState({
						showtruck:"show-m",
						showdevice:"show-n",
						device_id:{"value":"", "label":"Select Device"}
					})
				}
				else{
					this.setState({
						showtruck:"show-n",
						showdevice:"show-m",
						truck_no:{"value":"", "label":"Select Truck"}
					})
				}
			}
          );


		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}


    onChangeTruckItem(truck_no){
		this.setState(
			{ truck_no },
			() => console.log(`Option selected:`, this.state.truck_no)
          );


		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

    onChangeDeviceItem(device_id){
		this.setState(
			{ device_id },
			() => console.log(`Option selected:`, this.state.device_id)
          );


		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	selectTrucks()
	{
		var items = []
		var trucks = this.state.trucks;
		if(trucks.length > 0)
		{
			trucks.map((item) => {

				items.push({value:item,label:item})
			})
		}
		return items;
	}

	selectDevices()
	{
		var items = []
		var devices = this.state.devices;
		if(devices.length > 0)
		{
			devices.map((item) => {

				items.push({value:item,label:item})
			})
		}
		return items;
	}
	onClickUpdateRailSummary(params)
	{
		this.setState({
			sliderTranslate:"slider-translate",
			overly:"show-m",
			raildata:params.data
		})
	}

	formUpdateSourceDestination(event)
	{
		event.preventDefault();
		if(this.state.sourceopt != "")
		{
			var source =  this.state.sourceopt.value;
			var loadingyard =  this.state.sourceopt.label;

		}
		else
		{
			var source =  "";
			var loadingyard =  "";
		}

		if(this.state.destopt != "")
		{
			var destination = this.state.destopt.value;
			var destination_name =this.state.destopt.label;
		}
		else
		{
			var destination = "";
			var destination_name ="";
		}
		if(this.state.sourceoem.value != "")
		{
				var sourcecode = this.state.sourceoem.value;
				var sourcename = this.state.sourceoem.label;
		}
		else {
					var sourcecode = "";
					var sourcename = "";
		}
		var raildata = this.state.raildata;
		if(sourcecode != "" && source != "" && destination != "")
		{
			var parameters= {
				source:sourcecode,
				loadingyard:sourcename,
				loading_id:source,
				loading_name:loadingyard,
				destination:destination,
				destination_name:destination_name,
				rownode:raildata
			}
			redirectURL.post("/dashboard/updatesourcedestinationrail",parameters)
			.then((response) => {
				if(response.data.status == "success")
				{
					this.setState({
						show:true,
						basicType:"success",
						basicTitle:"Successfully update data",
						sliderTranslate:"",
						overly:"show-n",
						raildata:"",
						sourceopt:"",
						destopt:""

					});
					var reqparams = {
						startdate:this.state.startDate,
						enddata:this.state.endDate
					}
		        this.onLoadRakePlanningData(reqparams)
				}
			})
		}
	}
	selectsources(){
	let items = [];
	try{
		if(this.state.sources.length > 0)
		{
			this.state.sources.map((item) =>{
				items.push({"value":item._id,"label":item.geofence_name})
			})
		}
	}
	catch(e){

	}
	return items;
}

	selectdestinations(){
	let items = [];
	try{
		if(this.state.destinations.length > 0)
		{
			this.state.destinations.map((item) =>{
				items.push({"value":item._id,"label":item.geofence_name})
			})
		}
	}
	catch(e){

	}

	return items;
}
	onChangeDestinationItem(destopt){
		this.setState(
			{ destopt },
			() => console.log(`Option selected:`, this.state.destopt)
          );


		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeSourceItem(sourceopt){
	this.setState(
		{ sourceopt },
		() => console.log(`Option selected:`, this.state.sourceopt)
				);


		this.setState({
			errorMessage:'',
			successMessage:''
		})
}
    render(){

        var hidecol = false

        var columnwithDefs = [
			{
				headerName:"",
				field:"",
				width:65,
				resizable:true,
				pinned:"left",
				cellRenderer:function(params){
					var refno = params.data.master_ref_no;
					if(params.data.rail_no != "" && params.data.rail_no != undefined)
					{
						var railno = params.data.rail_no;
					}
					else
					{
						var railno = "0";
					}
					if(params.data.loadingyard != "" && params.data.loadingyard != undefined && params.data.destination != "" && params.data.destination != undefined)
					{
						return "<a href='/rail-consignments/"+railno+"' class='label btn-warning custom-btn'>View</a>";
					}
					else {
						return "";
					}
				},
			},
			// {
			// 	headerName:"",
			// 	field:"",
			// 	width:90,
			// 	resizable:true,
			// 	pinned:"left",
			// 	cellRendererSelector:function(params){
			// 		if(params.data.loadingyard != "" && params.data.loadingyard != undefined && params.data.destination != "" && params.data.destination != undefined)
			// 		{
			// 			return "";
			// 		}
			// 		else {
			// 			return {
			// 				component:"Editrailsummary"
			// 			}
			// 		}
			// 	},
			// },

			// {
			// 	headerName:"",
			// 	field:"",
			// 	hide:hidecol,
			// 	width:120,
			// 	resizable:true,
			// 	pinned:"left",
			// 	cellRenderer:function(params){
			// 		var refno = params.data.master_ref_no;
			// 		return "<a style='color:#ffffff' href='/tptlogin/createtptconsignmentbatch/"+refno+"' class='label btn-danger custom-btn'>First Mile Batch</a>";

			// 	},
			// },
			// {
			// 	headerName:"",
			// 	field:"",
			// 	width:90,
			// 	hide:hidelof,
			// 	resizable:true,
			// 	pinned:"left",
			// 	cellRenderer:function(params){
			// 		var refno = params.data.master_ref_no;
			// 		return "<a target='_blank' style='color:#ffffff' href='/viewdeviceplanning/"+refno+"' class='label btn-success custom-btn'>GPS Plan</a>";

			// 	},
			// },
			{
				headerName:"Rail No",
				field:"rail_no",
				width:120,
				resizable:true,
				pinned:"left",
				valueGetter:function(params){
					try{
						if(params.data.rail_no != "" && params.data.rail_no != undefined)
						{
							return params.data.rail_no;
						}
						else
						{
							return "#";
						}
					}
					catch(e){
						return "#";
					}
				}
			},
			{
				headerName:"FNR No",
				field:"fnr_no",
				width:120,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Date of Invoice",
				field:"uploaded_on",
				width:140,
				resizable:true,
				pinned:"left",
				valueGetter:function(params)
				{
					try{
						if(params.data.uploaded_on != "" && params.data.uploaded_on != undefined)
						{
							return getDDMMMYYYYHHMMDefault(params.data.uploaded_on);
						}
						else
						{
							return "";
						}
					}
					catch(e){
						return "";
					}
				}
			},

			{
				headerName:"Source",
				field:"loadingyard",
				width:120,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Destination",
				field:"destination",
				width:120,
				resizable:true,
				pinned:"left"
			},
			{
				headerName:"Total Batches",
				field:"total_vins",
				width:120,
				resizable:true,
				pinned:"left"
			},
			// {
			// 	headerName:"At Plant",
			// 	field:"atplant",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"In transit to Loading Yard",
			// 	field:"intransitload",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"At Loading yard",
			// 	field:"atload",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"Rake Assigned",
			// 	field:"rakeassign",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"In Transit To Destination Yard",
			// 	field:"intransitdest",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"At Destination Yard",
			// 	field:"atdestination",
			// 	width:120,
			// 	resizable:true
			// },
			// // {
			// // 	headerName:"In Transit to RSO",
			// // 	field:"intransitrso",
			// // 	width:120,
			// // 	resizable:true
			// // },
			// // {
			// // 	headerName:"At RSO",
			// // 	field:"atrso",
			// // 	width:120,
			// // 	resizable:true
			// // },
			// {
			// 	headerName:"In Transit To Dealer",
			// 	field:"intransitdealer",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"Near Dealer",
			// 	field:"neardealer",
			// 	width:120,
			// 	resizable:true
			// },
			// {
			// 	headerName:"POD Receipt",
			// 	field:"podreceipt",
			// 	width:120,
			// 	resizable:true
			// },
			{
				headerName:"Uploaded By",
				field:"uploaded_by",
				width:150,
				resizable:true
			},
			{
				headerName:"Uploaded On",
				field:"uploaded_on",
				width:150,
				resizable:true,
				valueGetter:function(params){
					try{
						return getDDMMMYYYYHHMMDefault(params.data.uploaded_on)
					}
					catch(e){
						return "";
					}
				}
			}

		]
        const { calview } = this.state;
        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];
        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>

            <SweetAlert
	            show={this.state.showrel}
	            type={this.state.basicTyperel}
	            title={this.state.basicTitlerel}
	            onConfirm={this.reloadcloseAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">

            		<div className="col-xl-12 col-lg-12 n-p-0" >

		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Summary Dashboard  </span>

									{/* <button className="float-right f14 custom-btn btn btn-warning" onClick={this.onClickShowSearchTruckSidebar.bind(this)} style={{marginRight:"5px"}}>
                                        Search Truck/Device
                                    </button> */}

									{/* <button className="float-right f14 custom-btn btn btn-warning" onClick={this.onClickShowRakeSidebar.bind(this)} style={{marginRight:"5px"}}>
                                        Upload New Cars
                                    </button> */}

                                    {/* <button className={"float-right custom-btn white btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Layout
                                    </button>
                                    <button className={"float-right custom-btn white btn btn-info f14 "} style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Layout
                                    </button>				 */}
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "}>

                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
											masterDetail={true}
                                        />

                                    </div>
                                </div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Upload New Cars
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpload.bind(this)}>


														<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Plant Source *</label>

                                <Select
                                placeholder={"Select Plant Source"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)}
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />
                            </div>
                            <div className="form-group mt-20p">
								<label className="">Upload File</label>
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>

							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/SAP_KIA_Template.xlsx")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								{/* Note: The excel to be uploaded is the extract from MLL's SAP application */}
							</div>
						</form>

                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderSearchTruckTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Search Truck/Device
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formSearchTruck.bind(this)}>
							<div className={"col-xl-4 col-lg-4 row form-group "}>
                                <label className="col-xl-12 col-lg-12">Select Search Type *</label>

                                <Select
                                placeholder={"Select Search Type"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeTruckTypeItem.bind(this)}
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.searchtype}
                                options={[
									{"value":"truck","label":"Truck"},
									{"value":"device","label":"Device"}
								]} />
                            </div>

                            <div className={"col-xl-4 col-lg-4 row form-group "+(this.state.showtruck)}>
                                <label className="col-xl-12 col-lg-12">Select Plant Source *</label>

                                <Select
                                placeholder={"Select Truck"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeTruckItem.bind(this)}
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.truck_no}
                                options={this.selectTrucks()} />
                            </div>

                            <div className={"col-xl-4 col-lg-4 row form-group "+(this.state.showdevice)}>
                                <label className="col-xl-12 col-lg-12">Select Device *</label>

                                <Select
                                placeholder={"Select Device"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDeviceItem.bind(this)}
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.device_id}
                                options={this.selectDevices()} />
                            </div>
							<div className="col-xl-4 col-lg-4 form-group">
								<button type="submit" className="btn btn-success mt-30p">Submit</button>
							</div>

						</form>

                        </div>
						{(this.state.showmap == "1")?
						<div className={"col-sm-12"} style={{padding:'5px 0px', width: '100%'}} >
							<AndriodMap
								truck_no = {this.state.truck}
								device_id = {this.state.device}
							/>
						</div>
						:""}

					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
										<div className="slide-r-title">
												<h4>
							Upload Source and Destination
														<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

												</h4>
										</div>
										<div className="slide-r-body" style={{position:"relative"}}>
												<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
														<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUpdateSourceDestination.bind(this)}>
														<div className="col-xl-12 col-lg-12 row form-group">
																<label className="col-xl-12 col-lg-12">Select OEM *</label>

																<Select
																placeholder={"Select Source"}
																closeMenuOnSelect={true}
																onChange={this.onChangeOEMSourceItem.bind(this)}
																className={"col-xl-12 col-lg-12 border-radius-0"}
																style={{borderRadius:"0px"}}
																value={this.state.sourceoem}
																options={this.selectoemsources()} />
														</div>
														<div className="col-xl-12 col-lg-12 row form-group">
																<label className="col-xl-12 col-lg-12">Select Loading Yard *</label>

																<Select
																placeholder={"Select Loading Yard"}
																closeMenuOnSelect={true}
																onChange={this.onChangeSourceItem.bind(this)}
																className={"col-xl-12 col-lg-12 border-radius-0"}
																style={{borderRadius:"0px"}}
																value={this.state.sourceopt}
																options={this.selectsources()} />
														</div>
														<div className="col-xl-12 col-lg-12 row form-group">
															<label className="col-xl-12 col-lg-12">Select Destination Yard *</label>

															<Select
															placeholder={"Select Destination Yard"}
															closeMenuOnSelect={true}
															onChange={this.onChangeDestinationItem.bind(this)}
															className={"col-xl-12 col-lg-12 border-radius-0"}
															style={{borderRadius:"0px"}}
															value={this.state.destopt}
															options={this.selectdestinations()} />
													</div>

													<div className="col-xl-12 col-lg-12 form-group">
														<button type="submit" className="btn btn-success">Submit</button>
													</div>
												</form>

												</div>
					</div>
				</div>

            </div>

        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript()
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {
	return function(a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function(a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
