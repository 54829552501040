
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const OnTripMapAction = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onCloseUploadDiv(e);
        props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onShowRouteDiv(props.data);
    };

    return (
        <div>
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="View Route"><i className="icofont icofont-map-pins f20"></i> </button>
       
        </div>
    );
};

export default OnTripMapAction;
