import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import redirectURL from '../redirectURL';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import input from 'material-ui/svg-icons/action/input';
import Select from 'react-select';
import confirmationNumber from 'material-ui/svg-icons/notification/confirmation-number';
import Modal from 'react-responsive-modal';

var googleAnalytics = require("../common/googleanalytics");

export default class DistanceTravelledReport extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            legs:'',
            userid:localStorage.getItem('userid'),
            pageTitle : "",
            dept_code :"",
            basicTitle :"",
            basicType : "",
            show : false,
            showTrucksList : "show-n",
            loadshow : "show-n",
            overly : "show-n",
            truckNumList : "",
            filteredData : [],
            report_type : [{"label":"All Stoppages",value:"ALL"}],
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            eventGridAction: "gridAction",
			usermanualmodal:false
		};
		
    }
    componentDidMount(){
        loadDateTimeScript()
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var dept = ""
        if(this.props.match.path == "/snddistancetravelreport")
        {
            dept = "SNDG";
            this.setState({
                pageTitle : "Sales and Dispatch",
                dept_code : dept
            });
        }
        if(this.props.match.path == "/prtdistancetravelreport")
        {
            dept = "LOG-PRT";
            this.setState({
                pageTitle : "Spare Parts",
                dept_code : dept
            });
        }
        if(this.props.match.path == "/tnpdistancetravelreport")
        {
            dept = "LOG-TNP";
            this.setState({
                pageTitle : "Train and Productions",
                dept_code : dept
            });
        }
        redirectURL.post("/consignments/getTrucksListByDept",{dept_code:dept}).then((response)=>{
            var result = response.data;
            var truckNumList = []
            result.map(function(e){
                truckNumList.push(e.truck_no)
            })
            this.setState({
                truckNumList : truckNumList
            })

        }).catch((e)=>{
            console.log(e);
        })
    }
    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setreportType(event){
        console.log(event);
        this.setState({
            report_type : [event]
        })
    }
    changeInputHandler = (event) => {
        const re = /^[0-9\\:\b]+$/;
        if(re.test(event.target.value))
        {
            let name = event.target.name;
            let value = event.target.value;
            this.setState({[name]:value});
        }
    }
	formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventGridAction,
                    "label": googleAnalytics.page.action.viewRoute,
                }
                googleAnalytics.logEvent(eventOptions);
            }
        this.setState({
            overly : "show-m",
            loadshow : "show-m"
        })
       

        var truckno = $("#inputTruck").val();
        truckno = truckno.replace(/\s/g,"");
        truckno = truckno.toUpperCase();
        truckno = truckno.replace(/[^\,\w\s]/gi, '');
        var email = $("#email").val();
        email = email.replace(/\s/g,"");
        email = email.replace(/[^\,\@\.\w\s]/gi, '');
        console.log(truckno,email)
        
        var truckList=[]
        if(truckno.indexOf(",") > 0)
        {
            truckList = truckno.split(",");
        }
        else
        {
            truckList.push(truckno)
        }
        
        var emails = [];
        if(email.indexOf(",") > 0)
        {
            emails = email.split(",");
        }
        else
        {
            emails.push(email)
        }
        var startDate = $("#startDate").val();
        var startTime =  startDate.split(" ")[1];
        startDate = startDate.split(" ")[0];
        startDate = startDate.split("-")[2]+"-"+startDate.split("-")[1]+"-"+startDate.split("-")[0]+" "+startTime+":00";
        var from_date = startDate;
        var endDate = $("#endDate").val();
        var endtime = endDate.split(" ")[1];
        endDate = endDate.split(" ")[0];
        endDate = endDate.split("-")[2]+"-"+endDate.split("-")[1]+"-"+endDate.split("-")[0]+" "+endtime+":00";
        var to_date = endDate;
        var hour_freq = $("#hour_freq").val();
        var flag = 0;
        if(hour_freq != 0)
        {
            var startDate = new Date(startDate);
            var endDate = new Date(endDate);
            console.log(startDate.getHours());
            startDate.setHours( startDate.getHours() + Number(hour_freq) );
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            console.log(startTime,endTime);
            if(startTime > endTime)
            {
                flag = 1;
            }
        }
        //console.log(this.state.report_type[0]);
        var formdata = {
            truck_list:JSON.stringify(truckList),
            dept_code:this.state.dept_code,
            from_date :from_date,
            to_date : to_date,
            requested_user_emails : JSON.stringify(emails),
            hour_freq : Number(hour_freq)
            //report_type : this.state.report_type[0].value
        }
        console.log(formdata);
        if(flag == 0)
        {
            redirectURL.post('/consignments/sendDistanceTravelReport',{"formdata":formdata})
            .then((response) => {
                this.setState({
                    basicTitle :"An E-mail will be Sent Shortly.",
                    basicType : "success",
                    show : true,
                    loadshow : "show-n",
                    overly : "show-n"
                })
            })
        }
        else
        {
         
            this.setState({
                basicTitle :"Frequency hours must be Less than specified duration.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            })
        }
        
        
       
    }
    // AUTOMCOMPLETE DROPDOWN
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNumList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
        try
        {
            $(".trucks-dropdown").removeClass("show-n");
            var dataset = this.state.truckNumList;
            var changedValue = $("#inputTruck").val();
            console.log(changedValue);
            var filteredResult = dataset.filter(function(e){
                if(e != "" && e != null)
                {
                    if(changedValue.indexOf(",") == -1)
                    {
                        return e.toString().toLowerCase().includes(changedValue.toLocaleLowerCase());
                    }
                    else
                    {
                        changedValue = changedValue.split(",");
                        console.log(changedValue[changedValue.length-1]);
                        console.log(e.toString().toLowerCase().includes(changedValue[changedValue.length-1].toLocaleLowerCase()))
                        if(changedValue[changedValue.length-1] != "")
                        {
                            return e.toString().toLowerCase().includes(changedValue[changedValue.length-1].toLocaleLowerCase());
                        }   
                        
                    }
                }
                
            });
            filteredResult = filteredResult.slice(0,100);
            this.setState({
                filteredData : filteredResult,
                showTrucksList : "show-m",
            })
        }catch(e){}
        
    }
   
    
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });  
    }
    // AUTOCOMPLETE DROPDOWN END
    onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
        var listData = [] 
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li key={e} className="stoppage-truck">{e}</li>)
                })
        }catch(e){}
        return (
            <div className="row col-xl-12 col-lg-12">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Distance Travelled Report </span>    
                </h5>
                
                <div className="row col-xl-12 col-lg-12 card">
                    <form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler} style={{padding:"16px"}}>
                        
                        <div className="form-group col-xl-5 col-lg-3">
                            <label>Please Provide Truck Numbers (Comma Separated) : </label>
                            <div className={"trucks-dropdown "+this.state.showTrucksList} style={{marginTop: "28px",marginLeft: "-17px",width: "38em"}}>
                                <ul>
                                    {listData}
                                </ul>
                            </div>
                            {/* onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} */}
                            <textarea class="form-control" name="truck_no" id="inputTruck" autoComplete="off"  />
                        </div>
                        <div className="form-group col-xl-5 col-lg-3">
                            <label>From Date : </label>
                            <input type="text" autoComplete="off" class="form-control datetimepicker_mask" id="startDate" required/>
                        </div>
                        <div className="form-group col-xl-5 col-lg-3">
                            <label>To Date : </label>
                            <input type="text" autoComplete="off" class="form-control datetimepicker_mask" id="endDate" required/>
                        </div>
                        <div className="form-group col-xl-5 col-lg-3">
                            <label>Calculate Distance in Duration of (Hours) :</label>
                            <input type="number" min="1" autoComplete="off"  id="hour_freq" class="form-control" required/>
                        </div>
                        <div className="form-group col-xl-5 col-lg-3">
                            <label>Send Report to (email IDs comma separated) :  </label>
                            <textarea autoComplete="off" placeholder="Please Provide Emails (Comma Separated)" id="email" class="form-control" required/>
                        </div>
                        <span class="col-xl-12" style={{color:"red"}}>
                            Note: : The Distance Travelled report takes time to process and hence cannot be displayed on screen.<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;Please provide your email addresses and the report will be sent to you.
                        </span>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success cs-btn"   id="save" style={{marginLeft:"13em",marginTop:"15px",width: "182px"}}>Send Report</button>
                        </div>
                    </form>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Distance Travelled Report</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
                                This screen provides data for the distances travelled by various set of desired trucks in certain time interval (duration).The report will be mailed to user as per requirement as data can be displayed on screen in a click of button
                            </div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

            </div>
        );
    }
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

$(document).ready(function(){
    $(document).on("click",".stoppage-truck",function(){
        var inputText = $("#inputTruck").val();
        if(inputText == "")
        {
            $("#inputTruck").val($(this).text());
            $(".trucks-dropdown").removeClass("show-m");
            $(".trucks-dropdown").addClass("show-n");
        }
        else{
            var splittedval = inputText.split(",");
            var selectedText = $(this).text();
            if(inputText.includes(selectedText) == false)
            {
                inputText = inputText+","+$(this).text();
                $("#inputTruck").val(inputText);
                $(".trucks-dropdown").removeClass("show-m");
                $(".trucks-dropdown").addClass("show-n");
            }
            
        }
         
        // var vals = $(this).text();
        //  $("#inputTruck").val(vals);
         
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})