
import React from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');


const kpiDayWiseDistanceActions = (props) => {
    const handleDayWiseDistanceTrip = (e) => {
        e.stopPropagation();
        // console.log("daywiseDistance ",props);
        props.context.componentParent.onShowDayWiseDistanceTrip(props);
    };


    return (
        <div>
            <button onClick={handleDayWiseDistanceTrip} className="custom-btn f22 label label-success" title="Day Wise Distance Trip" ><i className="icofont icofont-list"></i> &nbsp;</button>

        </div>
    );
};

export default kpiDayWiseDistanceActions;
