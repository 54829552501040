 
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const NoenroutesActions = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowTransitKpi(0);
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Enroute Stoppages" style={{color:"#ccc"}}><i className="icofont icofont-pause"></i> &nbsp;</button>
            
        </div>
    );
};

export default NoenroutesActions;
