
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentTransporterLoad = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowTransporterCommentLoad(props);
    };
    if(props.data.notify_plant == 1 && props.data.plant_approval == 0)
    {
        var btnTitle  = 'Pending'
    }
    else{
        var btnTitle  = 'Notify Plant'
    }
    
    return (
        <div className="map-icon-component">
            {(btnTitle == 'Pending')?"Pending":
                <button onClick={handleRouteClick} className="mybtn btn btn-success" title="View VIN List">{btnTitle} </button>
            }
			
        </div>
    );
};

export default CommentTransporterLoad;
