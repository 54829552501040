import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ConsignmentDctComment = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickDCTComment(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
       
            var colr = 'txt-secondary'
      
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-warning custom-btn f12 label label-success" 
            title="DCT Commet">Comment</button>
            
        </div>
    );
};

export default ConsignmentDctComment;
