import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import ConsignmentActions from './ConsignmentActionsComponent';
import KpiActions from './kpiActionsComponent';
import KpiDeviationActions from './kpiDeviationActionsComponent';
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Nooverspeed from './nooverspeedComponent.js';
import Notransit from './notransitComponent.js';
import RoutemapAction from './routemapActionComponent';
import RoutemapTransitAction from './routemaptransit';
import RoutemapNoGPSAction from './routemapnogps';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapenrouteAction from './routemapenroute';
import KpiNightDriveActions from './kpiNightDriveActionsComponent';
import Nonightdrive from './nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from './kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from './kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from './kpiUnLoadingDelayActions';
import Noenroutes from './noenroutesComponent.js';
import Gps from './gpsActionComponent.js';
import NoGps from './nogpsActionComponent.js';
import Select from 'react-select';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Counters from '../layouts/consignmentcountersComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import UserDrawMap from '../common/userdrawmap';
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var mailtype;
var mailticketid;
export default class ManageUserMailMap extends Component {

	constructor(props){
		super(props);
		this.state={
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			mailtype:'',
			ticketid:''
			
		}
		
	}
	componentDidMount = async () =>{
		//console.log("this.props.match.params ", this.props.match.params	)

		mailtype = this.props.match.params.type;
		mailticketid = this.props.match.params.ticketid;
		console.log("mail type ", mailtype)
		console.log("mail type ", mailticketid)
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		});
		
		var prms = {
			ticket_id:mailticketid
		}
		await redirectURL.post('/tms/ticket', prms)
		.then(async (response) =>{
			//console.log("Step 1 ",response.data)
			var records = response.data[0];
				//console.log("records ", records)
			/*Enroute Stoppages Start*/
			if(mailtype == 'enroute_stoppage')
			{
				if(records.stoppage_start_time)
				{
					var sstartime = records.stoppage_start_time
				}
				else
				{
					var sstartime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
				}
				await redirectURL.post('/tms/ticketenroutes', {
					consignment_code:records.consignment_codes, 
					truck_no:records.truck_no,
					stoppage_start_time:sstartime
				})
				.then(async (response) =>{
					//console.log("Step 2 ",response.data)
					var rownode = response.data;
					console.log("rownode ", rownode)
					if(rownode.length > 0)
					{
						/*Get Enroute Map Details*/
						//var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var fintance = moment.parseZone(rownode[0].stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
						
						if(rownode[0].reset_datetime)
						{
							//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
							var eintance = moment.parseZone(rownode[0].reset_datetime).format('YYYY-MM-DD HH:mm:ss');
						
						}
						else{
							var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						
						}
						//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode[0].truck_no,
							//consignment_code:rownode.consignment_code,
							//consignee_code:rownode.consignee_code,
							first_instance:fintance,
							recent_instance:eintance,
							screen:"consignment"
						}
						//console.log("params ", params)
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then( async (response) => {	
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							
								var sdate='';
								var edate='';

								if(rownode.leg_start != '' && rownode.leg_start != undefined)
								{
									sdate=rownode.leg_start;
								}
								if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
								}
							await this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									defTransitCoords: rownode.consignee_coordinates,
									maptruckno:rownode.truck_no,
									rownode:rownode,
									leg_no:rownode.leg_no,
									loadshow:'show-n',
									overly:'show-n'

							});
							
							
						})
						.catch(function (error) {
							console.log(error);
						});
					}
					else
					{
						this.setState({
							show: true,
							basicTitle:"No data found",
							basicType:"info",
							loadshow:'show-n'
						});
					}
					/*END*/
				});
			}

			/*Transit Delay Start*/
			if(mailtype == 'transit_delay')
			{
				redirectURL.post('/tms/transitdelay', {
					consignment_code:records.consignment_codes,
					consignee_code:records.consignee_codes, 
					truck_no:records.truck_no,
					leg_no:records.leg_no
				})
				.then(async (response) =>{
					var rownode = response.data;
					//console.log("rownode ", rownode)
					/*Get Enroute Map Details*/
					//var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var fintance = moment.parseZone(rownode[0].leg_start).format('YYYY-MM-DD HH:mm:ss');
					
					if(rownode[0].reset_datetime)
					{
						//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = moment.parseZone(rownode[0].leg_end_eta).format('YYYY-MM-DD HH:mm:ss');
					
					}
					else{
						var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					
					}
					//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var params = { 
						truck_no:rownode[0].truck_no,
						//consignment_code:rownode.consignment_code,
						//consignee_code:rownode.consignee_code,
						first_instance:fintance,
						recent_instance:eintance,
						screen:"consignment"
					}
					//console.log("params ", params)
					await redirectURL.post('/reports/nightdriveroutes',params)
					.then( async (response) => {	
					
					//var records = JSON.parse(JSON.stringify(response)).data;
						//console.log("Inner Grid ",response.data);
						
							var sdate='';
							var edate='';

							if(rownode.leg_start != '' && rownode.leg_start != undefined)
							{
								sdate=rownode.leg_start;
							}
							if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
							{
								edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
							}
						await this.setState({
								mapinfo:response.data,
								dealer:rownode.consignee_code,
								defTransitCoords: rownode.consignee_coordinates,
								maptruckno:rownode.truck_no,
								rownode:rownode,
								leg_no:rownode.leg_no,
								loadshow:'show-n',
								overly:'show-n'

						});
						
						
					})
					.catch(function (error) {
						console.log(error);
					});
					/*END*/

				});
			}

			/*Overspeed*/

			if(mailtype == 'overspeeding' || mailtype == 'overspeed')
			{
				redirectURL.post('/tms/overspeed', {
					consignment_code:records.consignment_codes,
					truck_no:records.truck_no
				})
				.then(async (response) =>{
					var rownode = response.data;
					console.log("rownode ", rownode)
					/*Get Enroute Map Details*/
					//var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var fintance = moment.parseZone(rownode[0].first_instance).format('YYYY-MM-DD HH:mm:ss');
					
					if(rownode[0].reset_datetime)
					{
						//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = moment.parseZone(rownode[0].recent_instance).format('YYYY-MM-DD HH:mm:ss');
					
					}
					else{
						var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					
					}
					//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var params = { 
						truck_no:rownode[0].truck_no,
						//consignment_code:rownode.consignment_code,
						//consignee_code:rownode.consignee_code,
						first_instance:fintance,
						recent_instance:eintance,
						screen:"consignment"
					}
					//console.log("params ", params)
					await redirectURL.post('/tms/overspeedgpsroutes',params)
					.then( async (response) => {	
					
					//var records = JSON.parse(JSON.stringify(response)).data;
						//console.log("Inner Grid ",response.data);
						
							var sdate='';
							var edate='';

							if(rownode.leg_start != '' && rownode.leg_start != undefined)
							{
								sdate=rownode.leg_start;
							}
							if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
							{
								edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
							}
						await this.setState({
								mapinfo:response.data,
								dealer:rownode.consignee_code,
								defTransitCoords: rownode.consignee_coordinates,
								maptruckno:rownode.truck_no,
								rownode:rownode,
								leg_no:rownode.leg_no,
								loadshow:'show-n',
								overly:'show-n'

						});
						
						
					})
					.catch(function (error) {
						console.log(error);
					});
					/*END*/

				});
			}


			/*Night Driving*/

			if(mailtype == 'night_driving')
			{
				//console.log("Hree rcrds ", records);
				redirectURL.post('/tms/ticketnightdriving', {
					consignment_code:records.consignment_codes,
					truck_no:records.truck_no,
					first_instance:records.first_instance,
				})
				.then(async (response) =>{
					var rownode = response.data;
					console.log("rownode ", rownode)
					/*Get Enroute Map Details*/
					//var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var fintance = moment.parseZone(rownode[0].first_instance).format('YYYY-MM-DD HH:mm:ss');
					
					if(rownode[0].reset_datetime)
					{
						//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = moment.parseZone(rownode[0].recent_instance).format('YYYY-MM-DD HH:mm:ss');
					
					}
					else{
						var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					
					}
					//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					var params = { 
						truck_no:rownode[0].truck_no,
						consignment_code:rownode.consignment_codes,
						//consignee_code:rownode.consignee_code,
						first_instance:fintance,
						recent_instance:eintance,
						screen:"consignment"
					}
					//console.log("params ", params)
					await redirectURL.post('/reports/nightdriveroutes',params)
					.then( async (response) => {	
					
					//var records = JSON.parse(JSON.stringify(response)).data;
						//console.log("Inner Grid ",response.data);
						
							var sdate='';
							var edate='';

							if(rownode.leg_start != '' && rownode.leg_start != undefined)
							{
								sdate=rownode.leg_start;
							}
							if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
							{
								edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
							}
						await this.setState({
								mapinfo:response.data,
								dealer:rownode.consignee_code,
								defTransitCoords: rownode.consignee_coordinates,
								maptruckno:rownode.truck_no,
								rownode:rownode,
								leg_no:rownode.leg_no,
								loadshow:'show-n',
								overly:'show-n'

						});
						
						
					})
					.catch(function (error) {
						console.log(error);
					});
					/*END*/

				});
			}


			/*No GPS Data*/
 
			if(mailtype == 'no_gps')
			{
				redirectURL.post('/tms/nogpsdata', {
					consignment_code:records.consignment_codes,
					truck_no:records.truck_no,
					last_packet_datetime:records.last_packet_datetime,
					ticket_id:records.ticket_id
				})
				.then(async (response) =>{
					var rownode = response.data;
					console.log("rownode ", rownode)
					if(rownode.length > 0)
					{
						/*Get Enroute Map Details*/
						//var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						if(rownode[0].last_packet_datetime)
						{
							var fintance = moment.parseZone(rownode[0].last_packet_datetime).format('YYYY-MM-DD HH:mm:ss');
						}
						
						if(rownode[0].reset_datetime)
						{
							//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
							var eintance = moment.parseZone(rownode[0].packet_received_on).format('YYYY-MM-DD HH:mm:ss');
						
						}
						else{
							var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						
						}
						//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode[0].truck_no,
							consignment_code:rownode.consignment_codes,
							//consignee_code:rownode.consignee_code,
							first_instance:fintance,
							recent_instance:eintance,
							screen:"consignment"
						}
						//console.log("params ", params)
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then( async (response) => {	
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							
								var sdate='';
								var edate='';

								if(rownode.leg_start != '' && rownode.leg_start != undefined)
								{
									sdate=rownode.leg_start;
								}
								if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
								}
							await this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									defTransitCoords: rownode.consignee_coordinates,
									maptruckno:rownode.truck_no,
									rownode:rownode,
									leg_no:rownode.leg_no,
									loadshow:'show-n',
									overly:'show-n'

							});
							
							
						})
						.catch(function (error) {
							console.log(error);
						});
					}
					else
					{
						this.setState({
							show: true,
							basicTitle:"No data found",
							basicType:"info",
							loadshow:'show-n'
						});
					}
					/*END*/

				});
			}
		});
		
	};
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	render(){
		//var mapinfo ={"coords": [{"lat": 10.931186666666667, "lng": 76.99907833333333, "stime": "2020-03-30 18:27:17", "received_on": "2020-03-30 18:30:15", "time_from_prev_point": 0.0, "dist_from_prev_point": 0, "speed": 77.8344}, {"lat": 10.91283, "lng": 76.98132166666667, "stime": "2020-03-30 18:30:21", "received_on": "2020-03-30 18:35:15", "time_from_prev_point": 184.0, "dist_from_prev_point": 2815, "speed": 72.2748}, {"lat": 10.896666666666667, "lng": 76.96411166666667, "stime": "2020-03-30 18:33:25", "received_on": "2020-03-30 18:35:15", "time_from_prev_point": 184.0, "dist_from_prev_point": 2600, "speed": 61.1556}], "breaks": [], "route_details": {"start_time": "2020-03-30 18:27:17", "end_time": "2020-03-30 18:33:25", "distance": "5415"}, "consignee_coordinates": ""}
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
			
				 <div className="row">
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
						{(this.state.mapinfo != '')?
						 <UserDrawMap 
						 context={this} 
						 rownode={this.state.rownode} 
						 mapFor={"consignment"} 
						 //googleroutes={this.state.googelRoutes} 
						 truckno={this.state.maptruckno} 
						 dealer={""} 
						 mapinfo={this.state.mapinfo} 
						 defTransitCoords={this.state.defTransitCoords} 
					 />
					
					:''}
                        <div className={"dataLoadpage " +(this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " +(this.state.loadshow)}>
							<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
						</div>
						<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
                    </div>
                </div>
            </div>	
		);
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	console.log("onSetCritical ", params);
	console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}

function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
