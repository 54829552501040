import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class RailCountersConsignment extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		intransittoloadcnt:0,
            rakeattachcnt:0,
            rakeasigncnt:0,
            intransittodestinatecnt:0,
            atdestinationcnt:0,
            transittodealercnt:0,
            delivercnt:0,
            activeconsignemnt:0,
            railtransitdelayscnt:0,
            lastMileTruckCounters: 0,
            userType: ""
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{
        this.setState({
            userType: this.props.userType
        })
        if(newprops.deptcode != this.state.deptcode)
        {        
            await this.setState({
                activeconsignemnt:newprops.activeconsignemnt,
                intransittoloadcnt:newprops.intransittoloadcnt,
                rakeattachcnt:newprops.rakeattachcnt,
                rakeasigncnt:newprops.rakeasigncnt,
                intransittodestinatecnt:newprops.intransittodestinatecnt,
                atdestinationcnt:newprops.atdestinationcnt,
                transittodealercnt:newprops.transittodealercnt,
                delivercnt:newprops.delivercnt,
                railtransitdelayscnt:newprops.railtransitdelayscnt,
                lastMileTruckCounters: newprops.lastMileTruckCounters,
                userType: newprops.userType
            });
        

        }
        

    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Cars<br /><br /></span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.activeconsignemnt != '')?this.state.activeconsignemnt:0}/></span></h4>
                                </div>
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransittoload")}>
                                    <span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br /> In Transit to Loading Yard<br /></span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.intransittoloadcnt != '')?this.state.intransittoloadcnt:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakeattach")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 txt-secondary"></i><br /> At Loading Yard<br /><br /></span>
                                    <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.rakeattachcnt != '')?this.state.rakeattachcnt:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"rakeassign")}>
                                    <span className="f13"><i className="icofont icofont-fast-delivery f22 txt-warning"></i><br /> Rake Assigned<br /><br /></span>
                                    <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.rakeasigncnt != '')?this.state.rakeasigncnt:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransittodestinate")}>
                                    <span className="f13"><i className="icofont icofont-toy-train f22 txt-success"></i><br /> In Transit To Destination Yard</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.intransittodestinatecnt != '')?this.state.intransittodestinatecnt:0}/></span></h4>
                                   
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"atdestination")}>
                                    <span className="f13"><i className="icofont icofont-ui-home txt-primary f24"></i><br /> At Destination Yard<br /><br /></span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.atdestinationcnt != '')?this.state.atdestinationcnt:0}/></span></h4>
                                    
                                </div>
                               
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transittodealer")} >
                                    <span className="f13"><i className="icofont icofont-truck-alt f24 darkorange"></i><br /> In Transit to Destination Dealer</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.transittodealercnt != '')?this.state.transittodealercnt:0}/></span></h4>
                                </div> */}

                                

{/*                                 
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"deliver")} >
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Delivered<br /><br /></span>
                                    <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.delivercnt != '')?this.state.delivercnt:0}/></span></h4>
                                </div>
                                 */}
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}






