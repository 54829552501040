import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import Noenroutes from '../manage/noenroutesComponent.js';

import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import DateFormater from './dateComponent';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYYHHMMSS } from '../common/utils'; 
//var doSomething = require('../common/utils').doSomething;
 
var redirectURL = require('../redirectURL');
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ConsignmentComponent extends Component {

	constructor(props){
		super(props);
		this.state={
            consignmentCode:this.props.match.params.keyword,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			loadshowmap:"show-n",
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
      	      
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			frameworkComponents: {
				consignmentActions:ConsignmentActions,
				kpiActions:KpiActions,
				kpiDeviationActions:KpiDeviationActions,
				kpiTransitDelayActions:KpiTransitDelayActions,
				nooverspeed:Nooverspeed,
				notransit:Notransit,
				kpiNightDriveActions:KpiNightDriveActions,
				nonightdrive:Nonightdrive,
				kpiEnrouteStoppagesActions:KpiEnrouteStoppagesActions,
				noenroutes:Noenroutes,
				dateFormater:DateFormater,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1"
            },
			overspeedData:[],
			transitedelaydata:[],
			xaxis:'',
			yaxis:'',	
			transxaxis:'',
			transyaxis:'',
			consignmentInfo:''		
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		//this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		var checkerData;
		if(this.props.data)
		{
			checkerData="Yes";
			this.onPropsApply(this.props.data);
		}
		else{
			checkerData="No";
			this.onPropsApply("testing success");
		}
		
	}
	componentDidMount(){
		//console.log("Props ", this.state.consignmentCode)
		/*Get Consignment Details */
		redirectURL.post('/consignments/byConsignmentCode', {
            consignment_code:this.state.consignmentCode
        })
        .then(async (response) =>{
			console.log(response.data.length)
			console.log(response.data[0]);
			if(response.data.length > 0)
			{

				this.setState({
					consignmentInfo : response.data[0],
					loadshowmap:"show-m"
				});
				var e=response.data[0];
				/*Consignment Route Map*/
				var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				if(e.status == 1)
				{
					var seconddate = new Date(new Date(e.gate_out_time).getTime() + 4*24*60*60*1000);					
				}
				if(e.status == 2)
				{
					if ((e.transit_time) > 0)
					{
						var seconddate = new Date(new Date(e.gate_out_time).getTime() + (e.transit_time *24*60*60*1000));
					}
					else{
						var seconddate = new Date(new Date(e.gate_out_time).getTime() + 4*24*60*60*1000);
					}	
				}
				if(e.status == 3)
				{
					
					var seconddate = new Date(new Date(e.recent_dealer_reported).getTime());
						
				}
				
				if(e.status == 4)
				{
					
					var seconddate = new Date(new Date(e.locked_dealer_reported).getTime());
						
				}
				
				if(e.status == 5)
				{				
					//var seconddate = new Date(new Date(e.left_dealer_reported).getTime());
					var seconddate = new Date(new Date(e.locked_dealer_reported).getTime() + 2*24*60*60*1000);
						
				}
				//console.log("seconddate ", e.status )
				
				var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
				var reqData = {
					truck_no:e.truck_no,
					consignment_code:e.consignment_code,
					gate_out_time:gateouttime,
					seconddate:sdate
				}
				//console.log("reqData: ", reqData);
				redirectURL.post('/consignments/maproutes',reqData, {
					headers:{
						'content-type':'application/json'
					}
				})
				.then((response) => {
					var records = response.data;
					//console.log("records", records);
					if(records != '')
					{
						this.setState({						
							mapinfo:records,
							loadshowmap:"show-n"
						});
						this.renderMap();
					}
					
					
				})
				.catch(function (error) {
				console.log(error);
				});
           
			}
			else{
				this.setState({
					consignmentInfo : {"truck_no":"NA"},
					loadshowmap:"show-m"
				});
			}

		});
		/*Consignment Overspeed Details*/
		redirectURL.post('/consignments/overspeed', {
            consignment_code:this.state.consignmentCode
        })
        .then(async (response) =>{
			var xdata = []
			var ydata = []
			response.data.map((item) => {
				xdata.push(item.first_instance)
				ydata.push(item.speed)
			});
			//console.log("xdata ", xdata)
			//console.log("ydata ", ydata)
            this.setState({
				overspeedData : response.data,
				xaxis:xdata,
				yaxis:ydata
            });
		});
		/*Consignment Transit Delay Details*/
		redirectURL.post('/consignments/transitdelay', {
			consignment_code:this.state.consignmentCode
		})
		.then(async (response) =>{
			//console.log("Step 1 ",response.data)
			//transitedelaydata=response.data;
			var xdata = []
			var ydata = []
			response.data.map((item) => {
				xdata.push(item.leg_end_eta)
				ydata.push((item.actual_distance_traveled_start_to_leg))
			});
			this.setState({
				transitedelaydata : response.data,
				transxaxis:xdata,
				transyaxis:ydata
            });
			//console.log("Step 2 ",response.data)
			
		});
	};
	onPropsApply = (params) =>{
		//console.log("Props Params ", params)
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onShowRouteDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + (e.transit_time *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}	
		
		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		var reqData = {
            truck_no:e.truck_no,
            consignment_code:e.consignment_code,
			gate_out_time:gateouttime,
			seconddate:sdate
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);
			if(records != '')
			{
				this.setState({
					sliderRouteTranslate:"slider-translate-50p",
					showDiv:'show-m',
					mapinfo:records,
					consignment_code:e.consignment_code,	
					loadshow:'show-n'
				});
				this.renderMap();
			}
			
			
		})
		.catch(function (error) {
		console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}

	
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };

	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   
		   
	}

	renderMap = () => {    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;
    	
		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;
		

		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}
			
		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 17,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		        	 var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+secondsToString(locations[l].break_time)+")</p>";
	    	        		
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
	   	         	
	       		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        	
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
		         	
	   		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
		        	
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds)
    }


	onRowClicked(params) {
		console.log("row click ", params)
		if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
		{

			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}
		
	  }
	
	onShowRouteMap(rownode){
		//console.log("rownode", rownode);
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:finstance,
            recent_instance:rinstance,
		}
		this.setState({
			loadshow:'show-m'
		});
		 redirectURL.post('/reports/overspeedgpsroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    //console.log(response.data);
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				loadshow:'show-n'

		   });


		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}
	
	onShowTransitRouteMap(rownode){
		console.log("rownode", rownode);
		var d = new Date(rownode.leg_start);
    	var r = new Date(rownode.leg_end_eta);
    	var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		this.setState({
			loadshow:'show-m'
		});
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    console.log(response.data);
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: rownode.consignee_coordinates,
				loadshow:'show-n'
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowNightDriveRouteMap(rownode){
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    //console.log(response.data);
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				loadshow:'show-n'
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
	}
	render(){
		const highchartoptions = {
			chart: {
			  type: 'column'
			},
			plotOptions: {
				column: {
					colorByPoint: true
				},
				series: {
				  maxPointWidth: 20
			  }
			},
			title:{
				text:''
			},
		  xAxis: {
			  categories: this.state.xaxis
		   },
			series: [
			  {
				data: this.state.yaxis
			   //data: [64,21,33,42,52]
			  }
			],
		  colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
			credits:false,
			responsive: {
			rules: [{
				condition: {
					maxWidth: '100%'
				},
				chartOptions: {
					chart: {
						height: '100%'
					},
					subtitle: {
						text: null
					},
					navigator: {
						enabled: false
					}
				}
			}]
			}
		  };

		  const highcharttransitoptions = {
			chart: {
			  type: 'column'
			},
			plotOptions: {
				column: {
					colorByPoint: true
				},
				series: {
				  maxPointWidth: 20
			  }
			},
			title:{
				text:''
			},
		  xAxis: {
			  categories: this.state.transxaxis
		   },
			series: [
			  {
				data: this.state.transyaxis
			   //data: [64,21,33,42,52]
			  }
			],
		  colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
			credits:false,
			responsive: {
			rules: [{
				condition: {
					maxWidth: '100%'
				},
				chartOptions: {
					chart: {
						height: '100%'
					},
					subtitle: {
						text: null
					},
					navigator: {
						enabled: false
					}
				}
			}]
			}
		  };
        //console.log("Rendere ",this.state.deptcode)
        const columnDefs = [
            { headerName: "",field: "_id", 
				width:100,
                cellRendererFramework:RoutemapAction,
				resizable: true,
				filter:false
            },
            { headerName: "Truck No",width:100,field: "truck_no", filter:true,resizable: true },
            { headerName: "Speed (km/h)",width:120,field: "speed", filter:true,resizable: true },
            { headerName: "Reported At",width:180,field: "first_instance", filter:true,resizable: true }
        ]	
		
		const columnTransitDefs= [
			{ headerName: "",field: "_id", 
			
				cellRendererFramework:RoutemapTransitAction,
				width:100,
				suppressSizeToFit: true,
				filter:false
			},
			{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
			{ headerName: "Leg Start Time",field: "leg_start", width:180,resizable: true },
			{ headerName: "Leg End Time",field: "leg_end_eta", width:180,resizable: true },
			{ headerName: "Exp. Leg Distance (KM)",field: "leg_expected_distance", width:220,resizable: true },
			{ headerName: "Actual Leg Distance Covered (KM)",field: "actual_leg_distance", width:220,resizable: true },							
			{ headerName: "Exp. Distance from Trip Start(KM)",field: "expected_distance_start_to_leg", width:220,resizable: true },
			{ headerName: "Actual Distance from Trip Start (KM)",field: "actual_distance_traveled_start_to_leg", width:220,resizable: true }							
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}

		return(
			<div className="container-fluid">
				<div className="row">
            		
					<div className="col-xl-5 col-lg-5">
						<div className="card">  
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Consignment  - {this.state.consignmentCode} </span>
								
								</h5>
							</div>

		            		<div className="card-body">
								<table className="table table-border">
									<tbody>
										<tr>
											<th>Truck  No</th>
											<td>{this.state.consignmentInfo.truck_no}</td>
										</tr>
										<tr>
											<th>Department Code</th>
											<td>{this.state.consignmentInfo.dept_code}</td>
										</tr>
										<tr>											
											
											<th>Vehicle Mode</th>
											<td>{this.state.consignmentInfo.vehicle_mode}</td>
										</tr>
										<tr>
										
											<th>Consignee Code</th>										
											<td>{this.state.consignmentInfo.consignee_code}</td>
										</tr>
										<tr>
											
											<th>Consigner Code</th>
											<td>{this.state.consignmentInfo.consigner_code}</td>
										</tr>
										<tr>										
											<th>Gate-in Time</th>
											<td>{getDDMMYYYYHHMMSS(this.state.consignmentInfo.gate_in_time)}</td>
										</tr>
										<tr>
											<th>Gate-out Time</th>
											<td>{this.state.consignmentInfo.gate_out_time}</td>
										</tr>
										<tr>
											<th>Distance (Kms)</th>
											<td>{this.state.consignmentInfo.distance_in_km}</td>
										</tr>
										<tr>
									
											<th>Distance per leg</th>
											<td>{this.state.consignmentInfo.distance_per_leg}</td>
										</tr>
										<tr>
										
											<th>Items</th>
											<td>{this.state.consignmentInfo.items}</td>
										</tr>
										<tr>
											<th>Overspeed</th>
											<td>{(this.state.consignmentInfo.overspeeding_exception == 1)?<span style={{color:'#ff0000'}}>Yes</span>:"No"}</td>
										</tr>
										<tr>  
											<th>Transit Time Delay</th>
											<td>{(this.state.consignmentInfo.transit_delay == 1)?<span style={{color:'#ff0000'}}>Yes</span>:"No"}</td>
										</tr>
										<tr>
										
											<th>Night Driving</th>
											<td>{(this.state.consignmentInfo.nightdriving_exception == 1)?<span style={{color:'#ff0000'}}>Yes</span>:"No"}</td>
										</tr>
									</tbody>
								</table>
								
							</div>
						</div>
					</div>
					<div className="col-xl-7 col-lg-7">
						<div className="card">  
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Route Map  - {this.state.consignmentCode} </span>
								
								</h5>
							</div>

		            		<div className="card-body">
								<div className={"dataLoadpage " +(this.state.loadshowmap)}>
								</div>
								<div className={"dataLoadpageimg " +(this.state.loadshowmap)}>
									<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
								</div>
								<div id="map" className="" style={{width:'100%',height:"88vh"}}></div>
							
							</div>
						</div>
					</div>
					{(this.state.consignmentInfo.overspeeding_exception == 1)?
                    <div className="col-xl-6 col-lg-6">
		            	<div className="card">  
                            <div className="card-header">
				       			<h5>
				       				<i className="fa fa-tachometer f20"></i> <span>Overspeed </span>
									
								</h5>
				   			</div>  			   			
		            		<div className="card-body">
							
								 <div id="myGrid" style={{ height: "340px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.overspeedData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									
		          		          />

									

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					 :""}
					 {(this.state.consignmentInfo.overspeeding_exception == 1)?  
                    <div className="col-xl-6 col-lg-6">
		            	<div className="card"> 
                            <div className="card-header">
				       			<h5>
				       				<i className="fa fa-tachometer f20"></i> <span>Overspeed </span>
									
								</h5>
				   			</div> 
		            		<div className="card-body n-p-0">
								<HighchartsReact containerProps={{ style: { width: '100%', height: '90%' } }} highcharts={Highcharts}  options={highchartoptions} />
							</div>
						</div>
					</div>
					
					:""}
				{(this.state.consignmentInfo.transit_delay == 1)?
                    <div className="col-xl-6 col-lg-6">
		            	<div className="card"> 
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-clock-time f20"></i> <span>Transit Time Delay </span>
									
								</h5>
				   			</div> 
		            		<div className="card-body n-p-0">
								<HighchartsReact containerProps={{ style: { width: '100%', height: '90%' } }} highcharts={Highcharts}  options={highcharttransitoptions} />
							</div>
						</div>
					</div>
					:""}
					{(this.state.consignmentInfo.transit_delay == 1)?
					<div className="col-xl-6 col-lg-6">
		            	<div className="card"> 
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-clock-time f20"></i> <span>Transit Time Delay </span>
									
								</h5>
				   			</div> 
		            		<div className="card-body">
								 <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnTransitDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.transitedelaydata}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									
		          		          />

									
		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   :""}
					{(this.state.consignmentInfo.nightdriving_exception == 1)?
                    <div className="col-xl-6 col-lg-6">
		            	<div className="card"> 
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-full-night f20"></i> <span>Night Driving </span>
									
								</h5>
				   			</div> 
		            		<div className="card-body n-p-0">
								<HighchartsReact containerProps={{ style: { width: '100%', height: '90%' } }} highcharts={Highcharts}  options={highcharttransitoptions} />
							</div>
						</div>
					</div>
					:""}
					{(this.state.consignmentInfo.nightdriving_exception == 1)?
					<div className="col-xl-6 col-lg-6">
		            	<div className="card"> 
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-full-night f20"></i> <span>Night Driving </span>
									
								</h5>
				   			</div> 
		            		<div className="card-body">
								 <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnTransitDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.transitedelaydata}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									
		          		          />

									
		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   :""}
					
				 </div>
				 {this.state.sliderTranslate != ''?
					<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12"> 
							<VerticalTimeline>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%"}}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									date="Basic Information"
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Truck No</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no }</h4>
									<h3 className="vertical-timeline-element-title">Transporter Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code }</h4>
									<h3 className="vertical-timeline-element-title">Department Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code }</h4>
									<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode }</h4>
									<h3 className="vertical-timeline-element-title">Items</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items }</h4>
									<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km }</h4>
									
								</VerticalTimelineElement>


								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Gate in time"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
									
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence }</h4>
									
									<h3 className="vertical-timeline-element-title">Invoice time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time }</h4>
									
								</VerticalTimelineElement>

								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="MSIL Gate out time"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence }</h4>
									
								</VerticalTimelineElement>
									
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Consignee Code"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Consignee Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code }</h4>
									
								</VerticalTimelineElement>
								
								</VerticalTimeline>

							</div>
						</div>
					 </div>
				:""	
				}
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Routes #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<div id="map" className="" style={{width:'100%',height:"90vh"}}></div>
							</div>
						</div>
					</div>
				:""	
				}
				
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>

            </div>
              	
		);
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	console.log("onSetCritical ", params);
	console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}
                              

function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
