import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export default class LspComponent extends Component {

    constructor(props) {
        super(props);
    }

    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.props.lspaxis
	      	     },
	      		  series: [
	      		    {
	      		      data: this.props.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
	      		};
        
        return ( 
        		<div className="col-xl-12 col-lg-12">
	                <div className="card">
	                    <div className="card-header">
	                        <h5>LSP wise trucks with GPS Data</h5>
	                    </div>
	                    <div className="card-body">
	                        <div className="dashboard-chart-container sales-overview-chart">
	                        <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lsphighchartoptions} />
	
	                        </div>
	                    </div>
	                </div>
	           </div>
			);
		}
}












