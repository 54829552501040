
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const EditRakeItem = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.editGridRakeData(props.data);
    };

    //console.log("Edit option ",props.data)

    return (
        <div className="map-icon-component">
            {(props.data.status_text == "Completed")?
            <button className="custom-btn label label-success" style={{color:"#ccc"}}><i className="icofont icofont-edit f15"></i></button>
            :<button onClick={handleRouteClick} className="custom-btn label label-success"><i className="icofont icofont-edit f15"></i> </button>
        }
        </div>
    );
};

export default EditRakeItem;
