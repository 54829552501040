
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const IntransitToLoadingYard = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowTransitLoadLegs(props);
    };


    let revisedDateOnlyMoment = 0
    let expectedDateOnlyMoment  = 0
    //console.log("props.data.truck_loading_yard_reached_on ", props.data.truck_loading_yard_reached_on)
    if (props.data.truck_loading_yard_reached_on != undefined && props.data.truck_loading_yard_reached_on != 'undefined' && props.data.eta_first_mile != 'undefined') {
        revisedDateOnlyMoment  = moment.parseZone(props.data.truck_loading_yard_reached_on).format("x");
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_first_mile).format("x");
       
    } else {
        var today = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        
        revisedDateOnlyMoment  = moment.parseZone(today).format("x");
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_first_mile).format("x");
        
    }
    //console.log("today ", revisedDateOnlyMoment)
    //console.log("expectedDateOnlyMoment ", expectedDateOnlyMoment)
    
    if(props.data.status > 2)
    {
        if(revisedDateOnlyMoment > expectedDateOnlyMoment)
        {
            var colr = 'redfont'
        }
        else if(revisedDateOnlyMoment < expectedDateOnlyMoment)
        {
            var colr = 'greenfont'
        }
        else{
            var colr = 'greenfont'
        }
    }
    else if(props.data.status < 2)
    {
        
        var colr = 'grayfont'
        
    }
    else{
        if(revisedDateOnlyMoment > expectedDateOnlyMoment)
        {
            var colr = 'redfont'
        }
        else if(revisedDateOnlyMoment < expectedDateOnlyMoment)
        {
            var colr = 'greenfont'
        }
        else{
            var colr = 'yellowfont'
        }
    }
    // if(props.data.status > 2)
    // {
    //     var colr = 'greenfont'
    // }
    // else{
    //     var colr = 'yellowfont'
    // }
    //console.log("props.data.status ",props.data.status)
    return (
        <div>
            {(props.data.status == 2)?
			<button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="In Transit to Loading Yard" ><i className="icofont icofont-truck-alt"></i> &nbsp;</button>
            :
            <button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="In Transit to Loading Yard"><i className="icofont icofont-truck-alt"></i> &nbsp;</button>
            
            }
        </div>
    );
};

export default IntransitToLoadingYard;
