
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiLoadingDelayActions = (props) => {
    //console.log("LoadingDelay ",props);
    const handleLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowLoadingDelayKpi(props);
    };
    const handleNoLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowLoadingDelayKpi(0);
    }; 
   
    return (
        <div>
            {(props.data.loading_delay_exception == 1)?
            <button onClick={handleLoadingDelayClick} className="custom-btn f22 label label-success" title="Loading Delay"><i className="icofont icofont-truck-loaded darkorange"></i> &nbsp;</button>
            
            :
            <button onClick={handleNoLoadingDelayClick} className="custom-btn f22 label label-success" title="Loading Delay" style={{color:"rgb(204, 204, 204)"}}><i className="icofont icofont-truck-loaded"></i> &nbsp;</button>
            
            }
           
        </div>
    );
};

export default KpiLoadingDelayActions;
