import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


var redirectURL = require('../redirectURL');
var moment = require('moment');

class DelayDetentionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicType: "",
            basicTitle: "",
            loadshow: "show-n",
            overly: "show-n",
            url_path: window.location.pathname,
            road_delay_list: [],
            rail_delay_list: [],
            rail_delay_list_names: [],
            road_delay_list_names: [],
            rail_delay_list_count: [],
            road_delay_list_count: [],
            unloading_delay_names: [],
            unloading_delay_count: [],

            from_date: moment().subtract(10, 'days').endOf('day').format('YYYY-MM-DD'),
            to_date: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),

        };

    }

    componentDidMount() {
        this.fetchDelay();
    }
    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }



    onClickFilterData = () => {
        if (this.state.from_date != "") {
            if (this.state.to_date != "") {
                if (new Date(this.state.from_date + " 00:00:00") < new Date(this.state.to_date + " 23:59:59")) {
                    var differenceMs = Math.abs(new Date(this.state.to_date) - new Date(this.state.from_date))
                    var differenceDays = differenceMs / (1000 * 60 * 60 * 24)

                    var weekDifferenceMs = Math.abs(new Date(moment()) - new Date(this.state.from_date))
                    var weekDifferenceDays = weekDifferenceMs / (1000 * 60 * 60 * 24)
                    console.log(weekDifferenceDays,'weekDifferenceDays')
                    if(weekDifferenceDays<6){
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "From Date Should be Less than 6 Days From Current Date"
                        })
                    }
                    else if (differenceDays <= 31) {
                        var params = {
                            from_date: this.state.from_date + " 00:00:00",
                            to_date: this.state.to_date + " 23:59:59",
                        }
                        this.fetchDelay(params)
                    }
                    else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Date Range can't be greater than 1 month"
                        })
                    }
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "From Date Can't be Greater Than To Date"
                    })
                }
            } else {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Please fill To Date"
                })
            }
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please fill From Date"
            })
        }
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var from_date = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            from_date: from_date
        });

    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            to_date: edate
        });
    }

    onShowFilters = () => {
        return (
            <div className='card-body'>
                <div class="row">
                    <div class="col-xl-2 col-lg-2 form-group">
                        <label>From Date</label>
                        <Datetime
                            value={this.state.from_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'From Date', name: 'from_date', autoComplete: 'off', readOnly: 'true', style: { backgroundColor: "white" } }}
                            dateFormat="YYYY-MM-DD"
                            name="from_date"
                            onChange={this.handlerStartDateTime.bind(this)} />
                    </div>
                    <div class="col-xl-2 col-lg-2 form-group">
                        <label>To Date</label>
                        <Datetime
                            value={this.state.to_date}
                            disableCloseOnClickOutside={false}
                            closeOnSelect={true}
                            inputProps={{ placeholder: 'To Date', name: 'to_date', autoComplete: 'off', readOnly: 'true', style: { backgroundColor: "white" } }}
                            dateFormat="YYYY-MM-DD"
                            onChange={this.handlerEndDateTime.bind(this)} />
                    </div>

                    <div class="col-xl-2 col-lg-2 form-group">
                        <button type="submit" onClick={this.onClickFilterData.bind(this)} class="btn btn-success mt-30p">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            </div>

        )
    }

    fetchDelay = async (params) => {
        var edate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD 23:59:59');
        var sdate = moment().subtract(10, 'days').startOf('day').format('YYYY-MM-DD 00:00:00');
        try {
            if (params) {
                if (params.from_date != undefined && params.from_date != null && params.from_date != '' && params.from_date != "undefined") {
                    var sdate = params.from_date
                }
                if (params.to_date != undefined && params.to_date != null && params.to_date != '' && params.to_date != "undefined") {
                    var edate = params.to_date
                }
            }

            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            })


            var parameters = {
                start_date: sdate,
                end_date: edate
            };
            if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
            {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }

            var response = await redirectURL.post("/consignments/detentionDelayReport", parameters);
            var rail_transit_delay_data = response.data.rail_data || []
            if(!Array.isArray(rail_transit_delay_data)){
                rail_transit_delay_data = []
            }
            var rail_transit_delay_names_Arr = []
            var rail_transit_delay_data_Aarr = []
            rail_transit_delay_data = rail_transit_delay_data.map(item => {
                var { transporter_name = '', rake_loading_point_name = '', total_quantity = 0 } = item
                var transporter = transporter_name + " - " + rake_loading_point_name

                rail_transit_delay_names_Arr.push(transporter)
                rail_transit_delay_data_Aarr.push(total_quantity)
                return ({ transporter_name, rake_loading_point_name, total_quantity })
            })
            var road_transit_delay_data = response.data.road_data || []
            if(!Array.isArray(road_transit_delay_data)){
                road_transit_delay_data = []
            }
            var road_tranist_delay_names = []
            var road_transit_delay_data_Arr = []
            road_transit_delay_data = road_transit_delay_data.map(item => {
                const { transporter_zone = '', transporter_name = '', count = 0 } = item
                var transporter = transporter_name + " - " + transporter_zone
                road_tranist_delay_names.push((transporter))
                road_transit_delay_data_Arr.push(count)
                return { transporter_zone, transporter_name, count }
            })
            // console.log(rail_transit_delay_data,'road64')
            var response2 = await redirectURL.post("/consignments/detentionUnloadingReport", parameters);
            console.log(response2,"response202")
            var unloading_delay_data = response2.data.result_data

            if(!Array.isArray(unloading_delay_data)){
                unloading_delay_data = []
            }
            var unloading_delay_data_names_Arr = []
            var unloading_delay_data_count_Arr = []
            unloading_delay_data = unloading_delay_data.map(item => {
                const { consignee_name = '', item_quantity = 0, transporter_zone = '', truck_no = 0 } = item
                var unloading_delay_name = consignee_name + " - " + transporter_zone
                unloading_delay_data_names_Arr.push(unloading_delay_name)
                unloading_delay_data_count_Arr.push(item_quantity)
                return ({ consignee_name, item_quantity, transporter_zone, truck_no })

            })

            this.setState({
                loadshow: "show-n", overly: "show-n", road_delay_list: road_transit_delay_data, rail_delay_list: rail_transit_delay_data,
                rail_delay_list_names: rail_transit_delay_names_Arr, road_delay_list_names: road_tranist_delay_names,
                rail_delay_list_count: rail_transit_delay_data_Aarr, road_delay_list_count: road_transit_delay_data_Arr,
                unloading_delay_names: unloading_delay_data_names_Arr, unloading_delay_count: unloading_delay_data_count_Arr,
            })
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    render() {

        const getRandomColor = () => {
            // Generate a random hex color code
            return '#' + Math.floor(Math.random() * 16777215).toString(16);
        };
        const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '10px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '8px',
            textAlign: 'center',
            textTransform: 'uppercase',
        };
        // rail_delay_list_names
        //     road_delay_list_names
        const road_list_arr = this.state.road_delay_list
        const rail_list_arr = this.state.rail_delay_list
        const optionsRoadDispatch = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Road Dispatch &gt;5 Days Delayed from Planned Date (IOD Pending)',
                align: 'left'
            },

            xAxis: {
                categories: this.state.road_delay_list_names,
                crosshair: true,
                accessibility: {
                    description: 'Count'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'NO of Trucks'
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'No of Trucks',
                    showInLegend: false,
                    data: this.state.road_delay_list_count.map((count, index) => ({
                        y: count,
                        color: getRandomColor() // Assign a random color to each bar
                    }))
                },

            ]
        }
        const optionsRailDispatch = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Rail Dispatch &gt;5 Days Delayed from Planned Date ',
                align: 'left'
            },

            xAxis: {
                categories: this.state.rail_delay_list_names,
                crosshair: true,
                accessibility: {
                    description: 'Count'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Quantity (in Tons)'
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Quantity (in Tons)',
                    showInLegend: false,
                    data: this.state.rail_delay_list_count.map((count, index) => ({
                        y: count,
                        color: getRandomColor() // Assign a random color to each bar
                    }))
                },

            ]
        }
        const optionsUnloadingDelay = {
            chart: {
                type: 'column'
            },
            title: {
                text: ' Unloading delay &gt;3 Days',
                align: 'left'
            },

            xAxis: {
                categories: this.state.unloading_delay_names,
                crosshair: true,
                accessibility: {
                    description: 'Count'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Quantity (in Tons)'
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Quantity (in Tons)',
                    showInLegend: false,
                    data: this.state.unloading_delay_count.map((count, index) => ({
                        y: count,
                        color: getRandomColor() // Assign a random color to each bar
                    }))
                },


            ]
        }

        return (
            <>
                <div className="card">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={() => (this.setState({ show: false }))}
                    >
                    </SweetAlert>
                    <div className="card-header">
                        <h5>
                            <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                            {"Delay Report"}
                        </h5>
                    </div>
                    {this.onShowFilters()}
                    {/* <div className='row' style={{ width: '100%', }}>
                        <div className="card-body pt-15px " style={{ width: "100%" }}>
                            <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px', marginRight: '20px' }}>
                                <div className="card-header" style={{ textAlign: 'center' }}>
                                    <h5>
                                        TRANSIT DELAY
                                    </h5>

                                </div>
                                <div>
                                    <div>
                                        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Road Dispatch &gt;5 Days Delayed from Planned Date (IOD Pending)</th>
                                                </tr>
                                                <tr>
                                                    <th style={tableHeaderStyle}>Transporter Name</th>
                                                    <th style={tableHeaderStyle}>Zone</th>
                                                    <th style={tableHeaderStyle}>No of Trucks</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {road_list_arr.map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={tableCellStyle}>{(row.transporter_name)}</td>
                                                        <td style={tableCellStyle}>{row.transporter_zone}</td>
                                                        <td style={tableCellStyle}>{row.count}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Rail Dispatch &gt;5 Days Delayed from Planned Date </th>
                                                </tr>
                                                <tr>
                                                    <th style={tableHeaderStyle}>Rail Zone / Siding</th>
                                                    <th style={tableHeaderStyle}>Transporter</th>
                                                    <th style={tableHeaderStyle}>Quantity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rail_list_arr.map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={tableCellStyle}>{(row.transporter_name)}</td>
                                                        <td style={tableCellStyle}>{row.rake_loading_point_name}</td>
                                                        <td style={tableCellStyle}>{row.total_quantity}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="card-body pt-15px" style={{ width: "50%" }}>
                            <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px', marginRight: '20px' }}>
                                <div className="card-header" style={{ textAlign: 'center' }}>
                                    <h5>
                                        UNLOADING DELAY
                                    </h5>

                                </div>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Unloading delay &gt;3 Days</th>
                                        </tr>
                                        <tr>
                                            <th style={tableHeaderStyle}>Customer Name</th>
                                            <th style={tableHeaderStyle}>Zone</th>
                                            <th style={tableHeaderStyle}>No of Trucks</th>
                                            <th style={tableHeaderStyle}>Qty</th>
                                        </tr>
                                    </thead>
                                   
                                </table>


                            </div>
                        </div>
                    </div> */}
                    <div className="card-header" style={{ textAlign: 'center' }}>
                        <h5>
                            TRANSIT DELAY
                        </h5>

                    </div>
                    <div className='card-body pt-15px'>
                        <HighchartsReact highcharts={Highcharts} options={optionsRoadDispatch} />
                    </div>
                    {/* // Changes to Plantwise Data - bug id - 5391 */}
                    {(!localStorage.getItem("pc") || localStorage.getItem("pc").toLocaleLowerCase() == "all" || localStorage.getItem("pc") == "HZ") &&
                        <div className='card-body pt-15px'>
                            <HighchartsReact highcharts={Highcharts} options={optionsRailDispatch} />
                        </div>
                    } 
                    <div className="card-header" style={{ textAlign: 'center' }}>
                        <h5>
                            UNLOADING DELAY
                        </h5>
                    </div>
                    <div className='card-body pt-15px'>
                        <HighchartsReact highcharts={Highcharts} options={optionsUnloadingDelay} />
                    </div> 
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </>
        );
    }
}
export default DelayDetentionReport;

