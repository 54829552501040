import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import DateFormater from '../layouts/dateComponent';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');
// var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class BillingComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: '',
            modules: AllModules,
            eventGridAction: "gridAction",
            eventFormAction: "formAction",
            pageTitle: "Transporter Performance Dashboard",
            loadshow: 'show-n',
            overly: "show-n",
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                // editable: true,
                resizable: true
            },
            animateRows: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            paginationPageSize: 50,
            rowClassRules: {
                "colorForDangerLight": function (params) {
                    if (params.data.parameters == "growth") {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            billableTrucks: 1000,
            basePrice: 44,
            totalPrice: '',
            gpscount: 0,
            simcount: 0,
            billablecountforgps: 0,
            billablecountforsim: 0,
            validmonth: ''
        }
    }
    componentDidMount() {
        console.log(localStorage.getItem('pc'), "111")
        var sdate = moment.parseZone().format('YYYY-MM-DD');

        var date = sdate.split("-")
        var year = date[0]
        var month = date[1]
        var month2 = month[1] - 1
        var month3 = "0" + month2
        console.log(month3, "123")
        var lastmonth = year + "-" + month3
        console.log(date, "date")
        console.log(lastmonth, "lastmonth")
        let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        this.setState({
            dept_code: dpt,
            startDate: sdate,
            loadshow: 'show-m',
            overly: "show-m",
            validmonth: lastmonth
        })
        this.loadPageData(lastmonth)
    }
    loadPageData = (lastmonth) => {
        let params = {
        }
        params.lastmonth = lastmonth
        console.log(params, "122")
        redirectURL.post("/amns/billingData", params).then(response => {
            let recievedData = response.data
            let rowData = []
            console.log(rowData, "before")
            try {
                rowData = JSON.parse(recievedData.billing_data)
                console.log(rowData.length, "after")

                let gpstrcks = rowData.filter((e) => {
                    if (e.type_of_tracking == "GPS") {
                        return e
                    }
                })
                let simtrucks = rowData.filter((e) => {
                    if (e.type_of_tracking == "SIM") {
                        return e
                    }
                })
                let gpsbillcount = 0
                if (gpstrcks.length > 3000) {
                    gpsbillcount = gpstrcks.length
                }
                else {
                    gpsbillcount = 3000
                }
                let simbillcount = 0
                if (simtrucks.length < 8000) {
                    simbillcount = 8000
                }
                else {
                    simbillcount = simtrucks.length
                }
                this.setState({

                    rowData,
                    gpscount: gpstrcks.length,
                    billablecountforgps: gpsbillcount,
                    billablecountforsim: simbillcount,
                    simcount: simtrucks.length,
                    loadshow: 'show-n',
                    overly: "show-n",
                })
            }
            catch (e) {
                this.setState({

                    rowData: [],
                    loadshow: 'show-n',
                    overly: "show-n",
                    'totalPrice': ''
                })
            }
        })
    }
    handlerStartDateTime = e => {
        var d = new Date(e._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        this.setState({
            startDate: startdate
        });
    }
    selectMonthDate = e => {
        console.log("e", e.target.value)
        this.setState({
            startDate: `${e.target.value}`,
            validmonth: `${e.target.value}`
        });
    }
    onGetTruckDetails = () => {
        this.setState({
            loadshow: 'show-m',
            overly: "show-m",
        })
        const { validmonth } = this.state
        var date1 = validmonth.split("-")[1]
        var date = moment.parseZone().format('YYYY-MM-DD');
        const newDate = moment(date).subtract(1, 'months').format('YYYY-MM-DD');
        var defaultdate = newDate.split("-")
        var defdate = defaultdate[0] + "-" + defaultdate[1]
        console.log(defdate, "212")
        var date2 = newDate.split("-")[1]
        console.log(date1,date2,"date1,date2")
        // if (date1 == date2) {
            this.loadPageData(validmonth)
        // }
        // else {
        //     this.setState({
        //         show: true,
        //         basicTitle: 'Please Select Valid Month',
        //         basicType: "danger",
        //         loadshow: 'show-n',
        //         overly: 'show-n',
        //         validmonth: defdate

        //     });
        // }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render() {
        var costperunitforgps = this.state.billablecountforgps * 15
        console.log(this.state.billablecountforsim,'simcount236')
        var costperunitforsim = this.state.simcount * 24
        var totalshipments = this.state.gpscount + this.state.simcount
        var total = costperunitforgps + costperunitforsim + 100000
        const columnWithDefs = [
            {
                headerName: "Plant Name",
                field: "consigner_name",
                resizable: true,
                width: 130,
                filter: true
            },
            {
                headerName: "Shipment ID",
                field: "shipment_id",
                resizable: true,
                width: 200,
                filter: true
            },
            {
                headerName: "Shipment Time",
                field: "shipment_time",
                resizable: true,
                width: 200,
                filter: true,
                valueGetter:function(params){
                    if(params.data.shipment_time != '' && params.data.shipment_time != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                    }
                    else{
                        return "NA";
                    }
              },	
            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                resizable: true,
                width: 200,
                filter: true,
                valueGetter:function(params){
                    if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                    }
                    else{
                        return "NA";
                    }
              },	
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                resizable: true,
                width: 200,
                filter: true
            },
            {
                headerName: "Shipment Mode",
                field: "shipment_mode",
                resizable: true,
                width: 200,
                filter: true
            },
            {
                headerName: "Type Of Tracking",
                field: "type_of_tracking",
                resizable: true,
                width: 200,
                filter: true,

            },
            {
                headerName: "Billable Amount",
                field: "billable_amount",
                resizable: true,
                width: 200,
                filter: true
            },
        ]
        const { show, basicType, basicTitle, dept_code, startDate, modules, rowData, defaultColDefNew, statusBar, sideBar, paginationPageSize, totalTrucks, basePrice, totalPrice, billableTrucks } = this.state
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5 className="float-left row">
                            <i className="icon-map cus-i"></i> <span>Trucks Billing Info</span>
                        </h5>
                    </div>
                    <div className="card-body col-xl-12 col-lg-12">
                        <div class="row" style={{ marginTop: "17px", marginLeft: "29px" }}>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label> Select Month</label>
                                <input type="month" id="start" name="start" style={{ padding: "5px"}}
                                    min="2022-01" value={this.state.validmonth} onChange={this.selectMonthDate} onKeyDown={(e) => e.preventDefault()} />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <button type="button" className="btn btn-success" style={{ marginTop: "2em" ,marginLeft: "10px"}} onClick={this.onGetTruckDetails}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                       
                            <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                                <table className="table table-bordered tptinfo">
                                    <tr>
                                        <th style={{ width: "20%" }}>DESCRIPTION</th>
                                        <th style={{ width: "15%" }}>Actual Count</th>
                                        {/* <th style={{ width: "15%" }}>MOQ</th>
                                        <th style={{ width: "15%" }}>Billable Count</th> */}
                                        <th style={{ width: "15%" }}>Cost Per Unit</th>
                                        <th style={{ width: "35%" }}>Billable Amount</th>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }} >No of GPS Tracking Shipments</td>
                                        <td style={{ fontWeight: 'bold' }} >{this.state.gpscount}</td>
                                        {/* <td style={{ fontWeight: 'bold' }} >3000</td>
                                        <td style={{ fontWeight: 'bold' }} > {this.state.billablecountforgps}</td> */}
                                        <td style={{ fontWeight: 'bold' }} >15.00</td>
                                        <td style={{ fontWeight: 'bold' }} >{costperunitforgps}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }} >No of SIM Tracking Shipments</td>
                                        <td style={{ fontWeight: 'bold' }} >{this.state.simcount}</td>
                                        {/* <td style={{ fontWeight: 'bold' }} >8000</td>

                                        <td style={{ fontWeight: 'bold' }} > {this.state.billablecountforsim}</td> */}
                                        <td style={{ fontWeight: 'bold' }} >24.00</td>
                                        <td style={{ fontWeight: 'bold' }} >{costperunitforsim}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }} >Total Shipments</td>
                                        <td style={{ fontWeight: 'bold' }} >{totalshipments}</td>
                                        {/* <td style={{ fontWeight: 'bold' }} ></td>
                                        <td style={{ fontWeight: 'bold' }} ></td> */}
                                        <td style={{ fontWeight: 'bold' }} ></td>
                                        <td style={{ fontWeight: 'bold' }} ></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }} >DCT Man Power Resource Cost</td>
                                        <td style={{ fontWeight: 'bold' }} >4</td>
                                        {/* <td style={{ fontWeight: 'bold' }} ></td>
                                        <td style={{ fontWeight: 'bold' }} >4</td> */}
                                        <td style={{ fontWeight: 'bold' }} >25,000</td>
                                        <td style={{ fontWeight: 'bold' }} >100,000</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }} >Total Billable Value</td>
                                        <td style={{ fontWeight: 'bold' }} ></td>
                                        {/* <td style={{ fontWeight: 'bold' }} ></td>
                                        <td style={{ fontWeight: 'bold' }} ></td> */}
                                        <td style={{ fontWeight: 'bold' }} ></td>
                                        <td style={{ fontWeight: 'bold' }} >{total}</td>
                                    </tr>
                                </table>
                            </div> 
                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "800px" }} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={modules}
                                    columnDefs={columnWithDefs}
                                    defaultColDef={defaultColDefNew}
                                    rowData={rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={statusBar}
                                    sideBar={sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>

            </div>
        )
    }

}