import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import Grid from './gridReportComponent';
var redirectURL = require('../redirectURL');
 

export default class ReportOverspeed extends Component {

	constructor(props){
		super(props);
		this.state={
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			truck_no:'',
			transporter_code:'',
			dept_code:'',
			file:'',
			alltransporters:[],
			alltransporter_codes:[],
			allDepts:[],
			csvmessage:'',
			csverrmessage:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			loadshow:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			overspeermapRoutes:'',
			overspeedmapdata:'',
			
		}
        this.onShowSidebarDiv = this.onShowSidebarDiv.bind(this);
        this.onCloseSidebarDiv = this.onCloseSidebarDiv.bind(this);
	}
	componentDidMount(){

		this.setState({
			loadshow:'show-m'
		});
		 /*Truck with gpsdata*/	
	    redirectURL.post('/reports/overspeed')
		  .then((response) => {
			 
		   //var records = JSON.parse(JSON.stringify(response)).data;
		   //console.log(response.data);
		   this.setState({
			   rowData:response.data,
			   loadshow:'show-n'
		   });
		     
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	    
	};
	
	
	onShowSidebarDiv = () => {
		//console.log("Click Upload Button", this.state.overspeermapRoutes)
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate-50p",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
		this.renderMap();
		if(this.state.overspeermapRoutes != '')
		{
			this.setState({
				loadshow:'show-n'
			});
		}
	}
	onCloseSidebarDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	this.setState({
    		routemap:'a'
    	});
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDK7rJXja-G1rLkZMH4LBBQOyLxEcKP2kc&callback=initMap");
           window.initMap = this.initMap
   }
   
    initMap = () => {
    	//console.log("Click Upload Button", this.state.overspeermapRoutes)
		var currentwindow;
		var jsondata = this.state.overspeermapRoutes;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;
		if(this.state.overspeermapRoutes == '' || arr.length == '0')
		{
			var defaultlat=28.4519751;
			var defaultlan=77.0310713;
			arr['lat']=defaultlat;
			arr['lng']=defaultlan;
	
		}
		else{
		var arr = jsondata.coords;
			console.log(arr.length);
			var defaultlat=arr[0].lat;
			var defaultlan=arr[0].lng;
	
		}
        //console.log("Here",arr);
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 12,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(defaultlat,defaultlan),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

    	       
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });
			if(arr.length > 0)
			{
				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(arr[0].lat,arr[0].lng));
				
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

			map.fitBounds(bounds);
			
		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		        	 var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+secondsToString(locations[l].break_time)+")</p>";
	    	        		
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
	   	         	
	       		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        	
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
		         	
	   		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
		        	
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			
		}
    }
    showLoader(){
		this.setState({
			loadshow:'show-m'
		});
	}

    hideLoader(){
		this.setState({
			loadshow:'show-n'
		});
	}
	render(){
		//console.log("Overspedd : ",this.state.overspeedmapdata)
		const columnwithDefs = [
				{
					headerName: "Action",
		          	field: "_id",
		          	width: 100,
				  	filter: false,
		          	cellRenderer:'actionItem',
		          	
			        editable:false
				},
				{
	  	          headerName: "Truck No",
	  	          field: "truck_no",
	  	          width: 150,
				  filter: "agTextColumnFilter"
	    	    
		        },
		        {
					headerName: "Transporter Code",
					field: "transporter_code",
					width: 200
			  },
			  {
		  	        headerName: "Transporter Name",
		  	        field: "transporter_name",
					width: 300,
					
					  
		        },
	  	        {
		          headerName: "Speed (Kmph )",
		          field: "speed",
		          width: 120,
	  	          
		        },
	  	        {
			          headerName: "Reported at",
			          field: "first_instance",
			          width: 200,
					  filter: "agTextColumnFilter",
					  sort: "desc"
		    	          
		        },
	    	       
		];
		var totaltime=0;
		if(this.state.overspeermapRoutes != '')
		{

			var d1=this.state.overspeermapRoutes.route_details.start_time;
			var d2=this.state.overspeermapRoutes.route_details.end_time;
			var date1 = new Date(d1);
			var date2 = new Date(d2);
			/*var seconds1 = Math.floordate1.getTime() / 1000);
			var seconds2 = Math.floor(date2.getTime() / 1000);
			var minutes=Math.floor((seconds2-seconds1)/60)
			*/

			var seconds = Math.floor((date2 - (date1))/1000);
			var minutes = Math.floor(seconds/60);
			var hours = Math.floor(minutes/60);
			var days = Math.floor(hours/24);

			hours = hours-(days*24);
			minutes = minutes-(days*24*60)-(hours*60);
			seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
			//console.log("minutes",minutes);
			totaltime=minutes+" mins "+seconds+" sec";
		}
		
		
		return(
			<div className="container-fluid">
		
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Reports - Overspeed </span>
                                </h5>
				   			</div>
				   			<div className="card-body">
				   			
								   <Grid 
								  	rowData={this.state.rowData} 
                                    columnwithDefs={columnwithDefs}  
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
									overspeermapRoutes={this.state.overspeermapRoutes}
									overspeedmapdata={this.state.overspeedmapdata}
									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	<div className="slide-r-body" style={{position:"relative"}}>
						{(this.state.overspeermapRoutes != '')?
							<div className="col-xl-12 col-lg-12">
								<div id="map" style={{width:'100%',height:'400px'}}>Map Here</div>
								<div className="col-xl-12 col-lg-12 slide-r-title n-p-0">
									<h4>
											Truck #{this.state.overspeedmapdata.truck_no} - Overspeed
										<span className="float-right closebtn" onClick={this.onCloseSidebarDiv} >X</span>
									</h4>
								</div>
								<div className="col-xl-12 col-lg-12 n-p-0 react-bootstrap-table">
									<table className="table table-bordered">
										<tbody>
											<tr>
												<th>Start Time</th>
												<td>{this.state.overspeermapRoutes.route_details.start_time}</td>
											</tr>
											<tr>
												<th>End Time</th>
												<td>{this.state.overspeermapRoutes.route_details.end_time}</td>
											</tr>
											<tr>
												<th>Total Time (Minutes)</th>
												<td>
													{totaltime}
												</td>
											</tr>
											<tr>
												<th>Distance (Kms)</th>
												<td>
												{(this.state.overspeermapRoutes.route_details.distance > 0)?(this.state.overspeermapRoutes.route_details.distance/1000).toFixed(2):"0"}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								
							</div>
						:''}
				 		
						
				 	</div>
				 </div>
            </div>
              	
		);
	}
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
