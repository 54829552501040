
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const SubmitBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickSubmitBtn(props);
    };
    var colr = props.data.comment_exists;
    console.log(props.data.incident_type,"rsamya")
    console.log(props.data,"111")
    return (

        <div>
            {(props.data.incident_type =="Accident"||props.data.incident_type=="Break Down") ?
            <button onClick={handleRouteClick} className="btn btn-danger custom-btn f12 label label-success" 
            title="" >Submit</button>
            :""}
        </div>
    );
};

export default SubmitBtn;
