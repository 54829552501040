
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentLoadAttach = (props) => {
    //console.log("props ", props)
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowCommentLoadAttachment(props);
    };
    if(parseInt(props.data.notify_tpt) == 1 && props.data.tpt_approval == 0)
    {
        var btnTitle  = 'Pending TPT'
    }
    else{
        var btnTitle  = 'Notify TPT'
    }
    //console.log("props.data.tpt_approval ", props.data.tpt_approval)
    //console.log("props.data.notify_tpt ", props.data.notify_tpt)
    return (
        <div className="map-icon-component">
            {(btnTitle == 'Pending')?"Pending"
            :
            <button onClick={handleRouteClick} className="mybtn btn btn-success" title="View VIN List">{btnTitle} </button>
             }
			
        </div>
    );
};

export default CommentLoadAttach;
