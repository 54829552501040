import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PrimaryCounters from "./primarydashboardcounters";
import ViewDownloadPayments from "./viewdownloadsecondarypayments"
import ViewPrimaryDownloadPayments from "./viewdownloadprimarysecondarypayments";;
 
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class SecondaryDashboardSummary extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Secondary Payment Summary",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
            cacheBlockSize: 10,
            headerHeight:80,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
			updatedRecords : [],
            openUpdatedModal : false,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            zerofifteen:[],
            fifteenthirty:[],
            thirtyonefortyfive:[],
            morefortyfive:[],
			pagetype:{"value":"all", "label":"All"},
			primarytrans:[{"value":"all", "label":"All"}],
			secondtrans:[{"value":"all", "label":"All"}],
			primarytransporter:{"value":"all", "label":"All"},
			secondtransporter:{"value":"all", "label":"All"},			
			stockzerofifteen:[],
			stockfifteenthirty:[],
			stockthirtyonefortyfive:[],
			stockmorefortyfive:[],
			zeroamt:0,
			fifteen:0,
			thirty:0,
			moreforty:0,
			stockzeroamt:0,
			stockfifteen:0,
			stockthirty:0,
			stockmoreforty:0
		}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onFilterLoadDashboardData = this.onFilterLoadDashboardData.bind(this);
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        var transportercode = "";
        // console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
        
         var parameters = {
             pagetype:"all",
             startDate:this.state.startDate,
			 endDate:this.state.endDate,
			 initial:1
         }

		this.onLoadDashboardData(parameters)
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};
    
    onLoadDashboardData(parameters)
    {
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		
		redirectURL.post("/consignments/secondarypaymentscounter", parameters)
		.then((response) => {
			var records = []
			var zerofifteen=[]
			var fifteenthirty=[]
			var thirtyonefortyfive=[]
			var morefortyfive=[]
			
			var stockzerofifteen=[]
			var stockfifteenthirty=[]
			var stockthirtyonefortyfive=[]
			var stockmorefortyfive=[]
			var zeroamt =0
			var fifteen = 0
			var thirty = 0
			var moreforty = 0

			records = response.data;
			// console.log("records ", records)
			if(records.length > 0)
			{
				records.map((item) => {
					var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
					//console.log("inv ", inv);
					var s2 = moment.parseZone(inv).format("x")
					//console.log(s2);
					var diffreetime = moment.duration(moment(createdate).diff(inv));
					var delaydays =Math.round(diffreetime.asDays());
					console.log("prim delaydays ", delaydays)
					if(item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received")
					{

					}
					else{
						
						if(delaydays>=0 && delaydays<=15)
						{
							zerofifteen.push(item)
							if(item.sec_status != "Payment received")
							 {								 
								zeroamt = parseFloat(zeroamt)+parseFloat(item.SEC_FRET_AMT)
							 }
							
						}
						if(delaydays>=16 && delaydays<=30)
						{
							//console.log("item",item)
							fifteenthirty.push(item)
							stockfifteenthirty.push(item)
							if(item.sec_status != "Payment received")
							 {								 
								fifteen = parseFloat(fifteen)+parseFloat(item.SEC_FRET_AMT)
							 }
							
						}
						if(delaydays>=31 && delaydays<=45)
						{
							thirtyonefortyfive.push(item)
							stockthirtyonefortyfive.push(item)
							if(item.sec_status != "Payment received")
							 {								 
								thirty = parseFloat(thirty)+parseFloat(item.SEC_FRET_AMT)
							 }
							
						}
						if(delaydays > 45)
						{
							morefortyfive.push(item)
							stockmorefortyfive.push(item)
							if(item.sec_status != "Payment received")
							 {								 
								moreforty = parseFloat(moreforty)+parseFloat(item.SEC_FRET_AMT)
							 }
							
						}
					}

				})
				
			}
			
			this.setState({
				zerofifteen:zerofifteen,
				fifteenthirty:fifteenthirty,
				thirtyonefortyfive:thirtyonefortyfive,
				morefortyfive:morefortyfive,
				zeroamt:zeroamt,
				fifteen:fifteen,
				thirty:thirty,
				moreforty:moreforty
            })

		})

		

        redirectURL.post("/consignments/secondarypayments", parameters)
        .then((response) => {
            //console.log("parameters ",parameters)
            var records = response.data.records;
            console.log("records ", records)
			var recordsarr = []
			var primarytrans=[]
			var secondtrans=[]
			// var zerofifteen=[]
			// var fifteenthirty=[]
			// var thirtyonefortyfive=[]
			// var morefortyfive=[]
			var allinv = []
            var rows = []
            if(records.length > 0)
            {
                var secondarydata =  groupBy(records, rdata => rdata.TRANS_NAME);
                console.log("secondarydata ", secondarydata)
                secondarydata.forEach((item,key) => {
                    // console.log("item ", item)
                    var primarytrans = groupBy(item, rdata => rdata.SEC_TPT_CODE);
                    // console.log("primarytrans  ", primarytrans)
                    
                    primarytrans.forEach((prim,pkey) => {

                        // console.log("prim ", prim)
                        var zerofifteen=[]
                        var fifteenthirty=[]
                        var thirtyonefortyfive=[]
                        var morefortyfive=[]
                        
                        var stockzerofifteen=[]
                        var stockfifteenthirty=[]
                        var stockthirtyonefortyfive=[]
                        var stockmorefortyfive=[]
                        var zeroamt =0
                        var fifteen = 0
                        var thirty = 0
                        var moreforty = 0
                        var totamt = 0
                        var notpaidbill = 0
                        var notpaidbillamt = 0

                        if(prim.length > 0)
                        {
                            prim.map((itm) => {
                                // console.log("ITEM ", itm)
                                var inv = moment.parseZone(itm.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
                                //console.log("inv ", inv);
                                var s2 = moment.parseZone(inv).format("x")
                                //console.log(s2);
                                var diffreetime = moment.duration(moment(createdate).diff(inv));
                                var delaydays =Math.round(diffreetime.asDays());
                                // console.log("prim delaydays ", delaydays)
                                totamt = parseFloat(totamt)+parseFloat(itm.SEC_FRET_AMT);
                                // console.log("totamt ", totamt)
                                if(itm.sec_is_payment_receive == "Yes" && itm.sec_status == "Payment received")
                                {
                                    if(delaydays>=0 && delaydays<=15)
                                    {
                                        zerofifteen.push(itm)
                                        if(itm.sec_status == "Payment received")
                                        {								 
                                            zeroamt = parseFloat(zeroamt)+parseFloat(itm.SEC_FRET_AMT)
                                        }
                                        
                                    }
                                    if(delaydays>=16 && delaydays<=30)
                                    {
                                        //console.log("item",item)
                                        fifteenthirty.push(itm)
                                        stockfifteenthirty.push(itm)
                                        if(itm.sec_status == "Payment received")
                                        {								 
                                            fifteen = parseFloat(fifteen)+parseFloat(itm.SEC_FRET_AMT)
                                        }
                                        
                                    }
                                    if(delaydays>=31 && delaydays<=45)
                                    {
                                        thirtyonefortyfive.push(itm)
                                        stockthirtyonefortyfive.push(itm)
                                        if(itm.sec_status == "Payment received")
                                        {								 
                                            thirty = parseFloat(thirty)+parseFloat(itm.SEC_FRET_AMT)
                                        }
                                        
                                    }
                                    if(delaydays > 45)
                                    {
                                        morefortyfive.push(itm)
                                        stockmorefortyfive.push(itm)
                                        if(itm.sec_status == "Payment received")
                                        {								 
                                            moreforty = parseFloat(moreforty)+parseFloat(itm.SEC_FRET_AMT)
                                        }
                                        
                                    }
                                }
                                else{
                                    
                                    notpaidbill = parseInt(notpaidbill)+1
                                    notpaidbillamt = parseFloat(notpaidbillamt)+parseFloat(itm.SEC_FRET_AMT)
                                }
                                // console.log("totamt ", totamt)
                               
                            })
                        }
                        rows.push({
                            // CONS_NAME:item[0].CONS_NAME,
                            // SEC_TPT_CODE:item[0].SEC_TPT_CODE,
                            TRANS_NAME:item[0].TRANS_NAME,
							TRTR:item[0].TRTR,
							CONS_NAME:prim[0].CONS_NAME,
							SEC_TPT_CODE:prim[0].SEC_TPT_CODE,
                            bills:prim.length,
                            billamount:totamt,
                            notpaidbillamt:notpaidbillamt,
                            notpaidbill:notpaidbill,
                            zeroamt:zeroamt,
                            zerofifteen:zerofifteen.length,
                            fifteen:fifteen,
                            fifteenthirty:fifteenthirty.length,
                            thirty:thirty,
                            thirtyonefortyfive:thirtyonefortyfive.length,
                            moreforty:moreforty,
                            morefortyfive:morefortyfive.length
                        })

                    })
                })
               
			}
		//	console.log("allinv ", allinv)

			
			this.setState({
				rowData:rows,
				loadshow:'show-n',
				overly:'show-n',
				// zerofifteen:zerofifteen,
				// fifteenthirty:fifteenthirty,
				// thirtyonefortyfive:thirtyonefortyfive,
				// morefortyfive:morefortyfive
        
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("Columns ", window.colState)
		console.log("Group ", window.groupState)
		console.log("Sort ", window.sortState)
		console.log("Filter ", window.filterState)
		let screenpage = 'Primary Payments Dashboard';

	

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}

    onClickFilterButton = async () => {
		//console.log("onClickFilterButton ")
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
        }

		// console.log("primarytransporter ", this.state.primarytransporter)
		// console.log("secondtransporter ", this.state.secondtransporter)
		var primarytransportercode = [];
		var secondarytransportercode = [];
		if(this.state.primarytransporter != "")
		{
			primarytransportercode.push(this.state.primarytransporter.value);
		}
		if(this.state.secondtransporter != "")
		{
			secondarytransportercode.push(this.state.secondtransporter.value);
		}
         var parameters = {
             pagetype:"all",
			 primary_transporter_code:primarytransportercode,
			 secondary_transporter_code:secondarytransportercode,
             startDate:sDate,
             endDate:eDate
         }
        
        // console.log("requestparams ", parameters)
        this.onFilterLoadDashboardData(parameters)
	}
	

    onFilterLoadDashboardData(parameters)
    {
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		
        redirectURL.post("/consignments/secondarypaymentsbyparams", parameters)
        .then((response) => {
            //console.log("parameters ",parameters)
            var records = response.data.records;
			var recordsarr = []
			var zerofifteen=[]
			var fifteenthirty=[]
			var thirtyonefortyfive=[]
			var morefortyfive=[]
            if(records.length > 0)
            {
                records.map((item) => {
					
                    var outstand_delay_days = ""
                    if(item.prim_outstand_delay_days === undefined)
                    {
                        outstand_delay_days=""
                    }
                    else
                    {
                        outstand_delay_days=item.prim_outstand_delay_days
                    }
                    var status = ""
                    if(item.prim_status === undefined)
                    {
                        status="Pending"
                    }
                    else
                    {
                        status=item.prim_status
                    }
                    var payment_date = ""
                    if(item.prim_payment_date === undefined)
                    {
                        payment_date=""
                    }
                    else
                    {
                        payment_date=item.prim_payment_date
					}
					var inv = moment.parseZone(item.INV_DATE).format("YYYY-MM-DD HH:mm:ss");
					//console.log("inv ", inv);
					var s2 = moment.parseZone(inv).format("x")
					//console.log(s2);
					var diffreetime = moment.duration(moment(createdate).diff(inv));
					var delaydays =Math.round(diffreetime.asDays());
					
					if((delaydays<0 || delaydays>=0) && delaydays<=15)
					{
						zerofifteen.push(item)
					}
					if(delaydays>=16 && delaydays<=30)
					{
						fifteenthirty.push(item)
					}
					if(delaydays>=31 && delaydays<=45)
					{
						thirtyonefortyfive.push(item)
					}
					if(delaydays > 45)
					{
						morefortyfive.push(item)
					}
					var secstatus = ""
					if(item.sec_status === undefined)
					{
						secstatus="Pending"
					}
					else
					{
						secstatus=item.sec_status
					}
					var sec_payment_date = ""
					if(item.sec_payment_date === undefined)
					{
						sec_payment_date=""
					}
					else
					{
						sec_payment_date=item.sec_payment_date
					}
                    recordsarr.push({
                        "_id" : item._id,
                        "TRIP" : item.TRIP,
                        "LOAD_NO" : item.LOAD_NO,
                        "INV_DATE" : item.INV_DATE,
                        "POD_DATE" : item.POD_DATE,
                        "IMM" : item.IMM,
                        "IMM_CITY" : item.IMM_CITY,
                        "FOR" : item.FOR,
                        "FOR_CITY" : item.FOR_CITY,
                        "TRTR" : item.TRTR,
                        "TRANS_NAME" : item.TRANS_NAME,
                        "REGN_NO" : item.REGN_NO,
                        "T_MO" : item.T_MO,
                        "YR_MFG" : item.YR_MFG,
                        "N" : item.N,
                        "BMODE" : item.BMODE,
                        "QTY" : item.QTY,
                        "CAR_EQUI" : item.CAR_EQUI,
                        "TOT_FREIGHT" : item.TOT_FREIGHT,
                        "TOT_DMG_AMT" : item.TOT_DMG_AMT,
                        "TOT_SORT_AMT" : item.TOT_SORT_AMT,
                        "TOT_PANALITY" : item.TOT_PANALITY,
                        "TOT_DELAY_AMT" : item.TOT_DELAY_AMT,
                        "NET_TOT_FRET_AMT" : item.NET_TOT_FRET_AMT,
                        "PRIM_FRET_AMT" : item.PRIM_FRET_AMT,
                        "PRIM_DMG_AMT" : item.PRIM_DMG_AMT,
                        "PRIM_SORT_AMT" : item.PRIM_SORT_AMT,
                        "PRIM_PANALTY_AMT" : item.PRIM_PANALTY_AMT,
                        "PRIM_DELAY_AMT" : item.PRIM_DELAY_AMT,
                        "NET_PRIM_FRET_AMT" : item.NET_PRIM_FRET_AMT,
                        "TVP_STOCK_CHARGES" : item.TVP_STOCK_CHARGES,
                        "SEC_LOAD_NO" : item.SEC_LOAD_NO,
                        "SEC_REG_NO" : item.SEC_REG_NO,
                        "CONS_NAME" : item.CONS_NAME,
                        "SEC_FRET_AMT" : item.SEC_FRET_AMT,
                        "SEC_DMG_AMT" : item.SEC_DMG_AMT,
                        "SEC_SORT_AMT" : item.SEC_SORT_AMT,
                        "SEC_PANALTY_AMT" : item.SEC_PANALTY_AMT,
                        "SEC_DELAY_AMT" : item.SEC_DELAY_AMT,
                        "NET_SEC_FRET_AMT" : item.NET_SEC_FRET_AMT,
                        "SEC_LOAD_DATE" : item.SEC_LOAD_DATE,
                        "SEC_TPT_CODE" :item.SEC_TPT_CODE,
                        "prim_outstand_delay_days":outstand_delay_days,
                        "prim_status":status,
                        "prim_payment_date":payment_date,
                        "sec_status":secstatus,
                        "sec_payment_date":sec_payment_date,
						"prim_uploadfiles":item.prim_uploadfiles,
						"sec_uploadfiles":item.sec_uploadfiles,
						"prim_upload_date":item.prim_upload_date,
						"prim_bill_date":item.prim_bill_date,
						"sec_upload_date":item.sec_upload_date,
						"sec_bill_date":item.sec_bill_date,
						"sec_is_payment_receive":item.sec_is_payment_receive,
						"sec_remarks":item.sec_remarks,
						"prim_remarks":item.prim_remarks,
						"stockyard_outstand_delay_days":item.stockyard_outstand_delay_days,
						"stockyard_status":item.stockyard_status
                    })
                })
			}
		//	console.log("primarytrans ", primarytrans)

            this.setState({
				rowData:recordsarr,
				loadshow:'show-n',
				overly:'show-n',
				zerofifteen:zerofifteen,
				fifteenthirty:fifteenthirty,
				thirtyonefortyfive:thirtyonefortyfive,
				morefortyfive:morefortyfive
            })
        })
        
    }

    onClickFilterResetButton(){
		var transportercode = "";
		$("#inputTruckConsignment").val("");
		$("#selectedFilter").val("");
        // console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
         var parameters = {
			pagetype:"all",
			startDate:"",
			endDate:"",
			initial:1
		}
        this.setState({			
			primarytransporter:{"value":"all", "label":"All"},
			secondtransporter:{"value":"all", "label":"All"}
		})
       // console.log("requestparams ", parameters)
        this.onLoadDashboardData(parameters)
    }

    onClickUpdateData()
    {
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
          //  loadshow:'show-m'
        });
        var rowCount = this.gridApi.getSelectedNodes();
        //console.log("rowCount ", rowCount)
        var updateitems = []
        if(rowCount.length > 0)
        {
            rowCount.map((item) => {
                updateitems.push(item.data)
            })
        }
        //console.log("updateitems ", updateitems)
        if(updateitems.length > 0)
        {
            // var paramtrs = {
            //     updatedata : updateitems,

            // }
            redirectURL.post("/consignments/updateSecondaryPaymentData", updateitems)
            .then((response) => {
                //console.log(response.data)
                if(response.data.status == 'success')
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully updated",
                        basicType:"success"
                    });
                    this.gridApi.deselectAll(); 
                }
            })
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"No Item selected",
                basicType:"info"
            })
        }
        //redirect
    }
    onRowSelected(event) {

	}
	onSelectPrimaryOptions= primarytransporter =>{
		this.setState({
			primarytransporter 
		});
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onSelectSecondOptions= secondtransporter =>{
		this.setState({
			secondtransporter 
		});
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	getTruckConsignments=(event)=>{
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "Select..." || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true
			})
		}
		else
		{
			var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			//console.log("createdate ",createdate);
			var s1 = moment.parseZone(createdate).format("x")
			//console.log("s1 ",s1)
			
			console.log(selectedFilter,inputTruckConsignment);
			var parametes = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
			}
			redirectURL.post("/consignments/searchpaymentinfo",parametes)
			.then((response)=>{
				var records = response.data.records;
				var recordsarr = []
				var zerofifteen=[]
				var fifteenthirty=[]
				var thirtyonefortyfive=[]
				var morefortyfive=[]
				if(records.length > 0)
				{
					records.map((item) => {
						
						var outstand_delay_days = ""
						if(item.prim_outstand_delay_days === undefined)
						{
							outstand_delay_days=""
						}
						else
						{
							outstand_delay_days=item.prim_outstand_delay_days
						}
						var status = ""
						if(item.prim_status === undefined)
						{
							status="Pending"
						}
						else
						{
							status=item.prim_status
						}
						var payment_date = ""
						if(item.prim_payment_date === undefined)
						{
							payment_date=""
						}
						else
						{
							payment_date=item.prim_payment_date
						}

						var secstatus = ""
						if(item.sec_status === undefined)
						{
							secstatus="Pending"
						}
						else
						{
							secstatus=item.sec_status
						}
						var sec_payment_date = ""
						if(item.sec_payment_date === undefined)
						{
							sec_payment_date=""
						}
						else
						{
							sec_payment_date=item.sec_payment_date
						}
						var inv = moment.parseZone(item.INV_DATE).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays =Math.round(diffreetime.asDays());
					
						if((delaydays<0 || delaydays>=0) && delaydays<=15)
						{
							zerofifteen.push(item)
						}
						if(delaydays>=16 && delaydays<=30)
						{
							fifteenthirty.push(item)
						}
						if(delaydays>=31 && delaydays<=45)
						{
							thirtyonefortyfive.push(item)
						}
						if(delaydays > 45)
						{
							morefortyfive.push(item)
						}
						recordsarr.push({
							"_id" : item._id,
							"TRIP" : item.TRIP,
							"LOAD_NO" : item.LOAD_NO,
							"INV_DATE" : item.INV_DATE,
							"POD_DATE" : item.POD_DATE,
							"IMM" : item.IMM,
							"IMM_CITY" : item.IMM_CITY,
							"FOR" : item.FOR,
							"FOR_CITY" : item.FOR_CITY,
							"TRTR" : item.TRTR,
							"TRANS_NAME" : item.TRANS_NAME,
							"REGN_NO" : item.REGN_NO,
							"T_MO" : item.T_MO,
							"YR_MFG" : item.YR_MFG,
							"N" : item.N,
							"BMODE" : item.BMODE,
							"QTY" : item.QTY,
							"CAR_EQUI" : item.CAR_EQUI,
							"TOT_FREIGHT" : item.TOT_FREIGHT,
							"TOT_DMG_AMT" : item.TOT_DMG_AMT,
							"TOT_SORT_AMT" : item.TOT_SORT_AMT,
							"TOT_PANALITY" : item.TOT_PANALITY,
							"TOT_DELAY_AMT" : item.TOT_DELAY_AMT,
							"NET_TOT_FRET_AMT" : item.NET_TOT_FRET_AMT,
							"PRIM_FRET_AMT" : item.PRIM_FRET_AMT,
							"PRIM_DMG_AMT" : item.PRIM_DMG_AMT,
							"PRIM_SORT_AMT" : item.PRIM_SORT_AMT,
							"PRIM_PANALTY_AMT" : item.PRIM_PANALTY_AMT,
							"PRIM_DELAY_AMT" : item.PRIM_DELAY_AMT,
							"NET_PRIM_FRET_AMT" : item.NET_PRIM_FRET_AMT,
							"TVP_STOCK_CHARGES" : item.TVP_STOCK_CHARGES,
							"SEC_LOAD_NO" : item.SEC_LOAD_NO,
							"SEC_REG_NO" : item.SEC_REG_NO,
							"CONS_NAME" : item.CONS_NAME,
							"SEC_FRET_AMT" : item.SEC_FRET_AMT,
							"SEC_DMG_AMT" : item.SEC_DMG_AMT,
							"SEC_SORT_AMT" : item.SEC_SORT_AMT,
							"SEC_PANALTY_AMT" : item.SEC_PANALTY_AMT,
							"SEC_DELAY_AMT" : item.SEC_DELAY_AMT,
							"NET_SEC_FRET_AMT" : item.NET_SEC_FRET_AMT,
							"SEC_LOAD_DATE" : item.SEC_LOAD_DATE,
							"SEC_TPT_CODE" :item.SEC_TPT_CODE,
							"prim_outstand_delay_days":outstand_delay_days,
							"prim_status":status,
							"prim_payment_date":payment_date ,
							"sec_status":secstatus,
							"sec_payment_date":sec_payment_date,
							"prim_uploadfiles":item.prim_uploadfiles,
							"sec_uploadfiles":item.sec_uploadfiles ,
							"prim_upload_date":item.prim_upload_date,
							"prim_bill_date":item.prim_bill_date,
							"sec_upload_date":item.sec_upload_date,
							"sec_bill_date":item.sec_bill_date,
							"sec_remarks":item.sec_remarks,
							"prim_remarks":item.prim_remarks,
							"stockyard_upload_date":item.stockyard_upload_date
						})
					})
				}
			//	console.log("primarytrans ", primarytrans)

				this.setState({
					rowData:recordsarr,
					loadshow:'show-n',
					overly:'show-n',
					zerofifteen:zerofifteen,
					fifteenthirty:fifteenthirty,
					thirtyonefortyfive:thirtyonefortyfive,
					morefortyfive:morefortyfive
				})
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}

	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
			{
                headerName : "Primary Transporter Name",
                field : "TRANS_NAME",
                width:200,
                editable : false
			},
			{
                headerName : "Primary Transporter Code",
                field : "TRTR",
                width:100,
                editable : false
            },
            
			{
                headerName : "Secondary Transporter Name",
                headerClass:"cellstylegridB",
                field : "CONS_NAME",
                width:200,
                editable : false,
                cellClass:"cellstylegridB"
            }, 
            {
                headerName : "Secondary Transporter Code",
                headerClass:"cellstylegridB",
                field : "SEC_TPT_CODE",
                width:100,
                editable : false,
                cellClass:"cellstylegridB"
            }, 
           
           
            {
                headerName : "No of Bill/Total Amount",
                field : "bills",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.bills
                        html = html+" / "+params.data.billamount
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            },
            
            {
                headerName : "Bills not Raised (Bills/Total Amount)",
                field : "TRANS_NAME",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.notpaidbill
                        html = html+" / "+params.data.notpaidbillamt
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            },
            
            {
                headerName : "Upto 15 days  (Bills/Total Amount)",
                field : "TRANS_NAME",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.zerofifteen
                        html = html+" / "+params.data.zeroamt
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            },
            
            {
                headerName : "16-30 Days (Bills/Total Amount)",
                field : "TRANS_NAME",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.fifteenthirty
                        html = html+" / "+params.data.fifteen
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            },
           
            {
                headerName : "31-45 Days (Bills/Total Amount)",
                field : "TRANS_NAME",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.thirtyonefortyfive
                        html = html+" / "+params.data.thirty
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            },
            {
                headerName : ">45 days (Bills/Total Amount)",
                field : "TRANS_NAME",
                width:140,
                editable : false,
                valueGetter:function(params){
                    try{
                        // console.log("params ", params.data)
                        var html =params.data.morefortyfive
                        html = html+" / "+params.data.moreforty
                        return html;
                    }
                    catch(e){
                        return "";
                    }
                },
                filter: true,
                resizable: false,
                cellClass: ['wraptext'],
                autoHeight: true,
            }
            
            
        ];
		var optoins = [
			{
				value:"all",
				label:"All"
			},
			{
				value:"primary",
				label:"Primary"
			},
			{
				value:"secondary",
				label:"Secondary"
			}
		]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
					
                    
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
                                    
                                    <span className="">
                                        <button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn f14 white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                
                                <div className="row">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
										<div className="col-xl-12 col-lg-12">
											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
                                                    enableRangeSelection={true}
                                                    headerHeight={this.state.headerHeight}
													components={this.state.components}
                                                    rowSelection={this.state.rowSelection}
                                                    suppressRowClickSelection={true}
                                                    onRowSelected={this.onRowSelected.bind(this)}
													groupDefaultExpanded={this.state.groupDefaultExpanded}
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                        </div>
                                       
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }



  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}