import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getHyphenYYYYMMDD } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Editicdrate from "./editicdrate";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class ICDRates extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:moment.parseZone().subtract(30, 'days').format("YYYY-MM-DD"),
            endDate:moment.parseZone().format("YYYY-MM-DD"),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true
            },
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
             overly : "show-n",
            loadshow : "show-n",
            rowData : [],
            frameworkComponents : {
                Editicdrate:Editicdrate
            },
            conversion_rate:'',
            conversion_date:'',
            selectrowid:'',
            show:false,
            basicTitle:"",
            basicType:"",
            sliderRouteTranslate:'',
            daystart:"",
            dayend:"",
            rate:""
            
        }
        this.onLoadICDRates = this.onLoadICDRates.bind(this); 
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickEditData = this.onClickEditData.bind(this);
    }
    componentDidMount(){
        
        
        this.onLoadICDRates();
    }

    onLoadICDRates(){
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/consignments/icdrates")
        .then((response) => {
           // console.log("Response ", response.data)
            this.setState({
                rowData:response.data,
                loadshow : "show-n",
                overly : "show-n"
            })
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }
   
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:"",
		});
		
    }
   
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    changeHandler = (event) => {
    	console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    onClickEditData(params){
        //console.log("params ", params.data)
        var data = params.data;
        this.setState({
            target_icd:params.data.target_icd,
            daystart:params.data.day_start,
            dayend:params.data.day_end,
            container_size:params.data.container_size,
            rate:params.data.rate,
            selectrowid:params.data._id,            
            sliderRouteTranslate:"slider-translate-30p",
            overly:'show-m'
        })
    }
    onCloseRouteDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
            overly:'show-n'
		});
    }
    onClickUpdateData(){
        var daystart = this.state.daystart;
        var dayend = this.state.dayend;
        var rate = this.state.rate;
        if(daystart != "" && dayend != "" && rate != "")
        {
            var parameters = {
                daystart:daystart,
                dayend:dayend,
                rate:rate,
                selectrowid:this.state.selectrowid
            }
            redirectURL.post("/consignments/updateicdrates", parameters)
            .then((response)=>{
                if(response.data.message == "success")
                {
                    this.setState({
                        daystart:"",
                        dayend:"",
                        rate:"",
                        show:true,
                        basicTitle:"Successfully update data",
                        basicType:"success",
                        sliderRouteTranslate:"",
                    })
                }
                else
                {
                    this.setState({
                        daystart:"",
                        dayend:"",
                        rate:"",
                        show:true,
                        basicTitle:"Failed",
                        basicType:"danger",
                        sliderRouteTranslate:""
                    })
                }
                this.onLoadICDRates();
            })
        }
        else
        {
            this.setState({
                daystart:"",
                dayend:"",
                rate:"",
                show:true,
                basicTitle:"Fields can not be empty",
                basicType:"danger",
                sliderRouteTranslate:""
            })
        }
    }
    render(){
       var columnwithDefs = [
            {
                headerName:"",
                field:"_id",
                width:80,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'Editicdrate'
                    };
                    return rendComponent
                
                },
            },
            {
               headerName:"Target",
               field:"target_icd",
               width:160               
           },
           {
              headerName:"Day Start",
              field:"day_start",
              width:160               
          },
          {
             headerName:"Day End",
             field:"day_end",
             width:160               
         },
         {
            headerName:"Container Size",
            field:"container_size",
            width:160               
        },
        {
           headerName:"Rate",
           field:"rate",
           width:160               
       },
       ]
        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow"}>		
                        <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>ICD Rates</span>
                            </h5>                    
                        </div>
{/*                        
                        <div className=" col-xl-12 col-lg-12 mt-35p">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Choose Date: </label>
                                    <Datetime value={this.state.conversion_date} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Date', name: 'conversion_date', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="conversion_date" 
                                    onChange={this.handlerDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Conversion Rate: </label>
                                    <input
                                        value={this.state.conversion_rate}
                                        type="text"
                                        name="conversion_rate"
                                        onChange={this.changeHandler} 
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickStoreData.bind(this)}>Submit</button>
                                </div>
                            </div> 
                        </div>
                         */}
                            
                        
                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        frameworkComponents={this.state.frameworkComponents}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                        </div>
                                            
	                </div> 

                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	           
                    {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Edit Rate
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
                            <div className="row col-xl-12 col-lg-12">
                               
                                <div className="form-group col-xl-12 col-lg-12">
                                    <label>Day Start: </label>
                                    <input
                                        value={this.state.daystart}
                                        type="text"
                                        name="daystart"
                                        onChange={this.changeHandler} 
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-xl-12 col-lg-12">
                                    <label>Day End: </label>
                                    <input
                                        value={this.state.dayend}
                                        type="text"
                                        name="dayend"
                                        onChange={this.changeHandler} 
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-xl-12 col-lg-12">
                                    <label>Rate: </label>
                                    <input
                                        value={this.state.rate}
                                        type="text"
                                        name="rate"
                                        onChange={this.changeHandler} 
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-xl-12 col-lg-12">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickUpdateData.bind(this)}>Submit</button>
                                </div>
                            </div> 
						</div>
						
					</div>
				:""	
				}
               </div>
			);
		}
}
