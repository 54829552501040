import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY  } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import PrimaryCounters from "./secondarypaymentscounters";
import ViewPrimaryDownloadPayments from "./viewdownloadprimarysecondarypayments";
import ViewDownloadPayments from "./viewdownloadsecondarypayments";
import SecondaryBillAcceptReject from "./secondarybillacceptreject";
import UploadFile from "./uploadsecondarypaymentfile";
 
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class SecondaryDashboard extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Secondary Dashboard",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			showreload: false,
            basicTitlereload:'',
			basicTypereload:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				SecondaryBillAcceptReject:SecondaryBillAcceptReject
			},
			headerHeight:60,
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

			},
			groupDefaultExpanded:-1,
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
			updatedRecords : [],
			openUpdatedModal : false,
			open:false,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            zerofifteen:[],
            fifteenthirty:[],
            thirtyonefortyfive:[],
			morefortyfive:[],
			uploadrowid:'',
			files:[],
			upload_bill_date:"",
			rejectopen:false,
			rejectdata:"",
			remarks:"",
			stockzerofifteen:[],
			stockfifteenthirty:[],
			stockthirtyonefortyfive:[],
			stockmorefortyfive:[],
			zeroamt:0,
			fifteen:0,
			thirty:0,
			moreforty:0
		}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
        this.resetState = this.resetState.bind(this);
		this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
		this.onClickAcceptPrimaryBill = this.onClickAcceptPrimaryBill.bind(this);
		this.onClickRejectPrimaryBill = this.onClickRejectPrimaryBill.bind(this);
		this.onClickRejectPrimaryBillModal = this.onClickRejectPrimaryBillModal.bind(this);
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        var transportercode = "";
        // console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
         if(localStorage.getItem('transportercode') === undefined)
         {
             transportercode = "";
         }
         else{
             transportercode = localStorage.getItem('transportercode');
         }
         var parameters = {
             pagetype:"secondary",
             transporter_code:transportercode,
             startDate:this.state.startDate,
             endDate:this.state.endDate
         }

		this.onLoadDashboardData(parameters)
		//dateScript("https://code.jquery.com/ui/1.12.1/jquery-ui.js");
	};
    
    onLoadDashboardData(parameters)
    {
		var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		//console.log("createdate ",createdate);
		var s1 = moment.parseZone(createdate).format("x")
		//console.log("s1 ",s1)
		
		redirectURL.post("/consignments/secondarypaymentscounter", parameters)
		.then((response) => {
			var records = []
			var zerofifteen=[]
			var fifteenthirty=[]
			var thirtyonefortyfive=[]
			var morefortyfive=[]
			
			var stockzerofifteen=[]
			var stockfifteenthirty=[]
			var stockthirtyonefortyfive=[]
			var stockmorefortyfive=[]
			var zeroamt =0
			var fifteen = 0
			var thirty = 0
			var moreforty = 0

			records = response.data;
			console.log("records ", records)
			if(records.length > 0)
			{
				records.map((item) => {
					var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
					//console.log("inv ", inv);
					var s2 = moment.parseZone(inv).format("x")
					//console.log(s2);
					var diffreetime = moment.duration(moment(createdate).diff(inv));
					var delaydays =Math.round(diffreetime.asDays());
					
					if(item.sec_is_payment_receive == "Yes" && item.sec_status == "Payment received")
					{

					}
					else{
						console.log("prim delaydays ", delaydays)
						if(delaydays>=0 && delaydays<=15)
						{
							zerofifteen.push(item)
							stockzerofifteen.push(item)
							zeroamt = parseFloat(zeroamt)+parseFloat(item.SEC_FRET_AMT)
						}
						if(delaydays>=16 && delaydays<=30)
						{
							console.log("item",item)
							fifteenthirty.push(item)
							stockfifteenthirty.push(item)
							fifteen = parseFloat(fifteen)+parseFloat(item.SEC_FRET_AMT)
						}
						if(delaydays>=31 && delaydays<=45)
						{
							thirtyonefortyfive.push(item)
							stockthirtyonefortyfive.push(item)
							thirty = parseFloat(thirty)+parseFloat(item.SEC_FRET_AMT)
						}
						if(delaydays > 45)
						{
							morefortyfive.push(item)
							stockmorefortyfive.push(item)
							moreforty = parseFloat(moreforty)+parseFloat(item.SEC_FRET_AMT)
						}
					}

				})
				
			}
			
			this.setState({
				zerofifteen:zerofifteen,
				fifteenthirty:fifteenthirty,
				thirtyonefortyfive:thirtyonefortyfive,
				morefortyfive:morefortyfive,
				stockzerofifteen:stockzerofifteen,
				stockfifteenthirty:stockfifteenthirty,
				stockthirtyonefortyfive:stockthirtyonefortyfive,
				stockmorefortyfive:stockmorefortyfive,
				zeroamt:zeroamt,
				fifteen:fifteen,
				thirty:thirty,
				moreforty:moreforty
            })

		})

		// redirectURL.post("/consignments/stockyardpaymentscounter", parameters)
		// .then((response) => {
		// 	var recordstock = []
			
		// 	var stockzerofifteen=[]
		// 	var stockfifteenthirty=[]
		// 	var stockthirtyonefortyfive=[]
		// 	var stockmorefortyfive=[]
		// 	var zeroamt =0
		// 	var fifteen = 0
		// 	var thirty = 0
		// 	var moreforty = 0
		// 	recordstock = response.data;
		// 	console.log("recordstock ", recordstock)
		// 	if(recordstock.length > 0)
		// 	{
		// 		recordstock.map((item) => {
					
		// 			//stockcounter
		// 			console.log("item.stockyard_upload_date ", typeof item.stockyard_upload_date)
		// 			if(item.stockyard_upload_date != "")
		// 			{
		// 				console.log("item.stockyard_upload_date ", item.stockyard_upload_date)
		// 				console.log("item.stockyard_is_payment_receive ", item.stockyard_is_payment_receive)
							
		// 				if(item.stockyard_is_payment_receive == "Yes")
		// 				{

		// 				}
		// 				else{
		// 					var inv1 = moment.parseZone(item.stockyard_upload_date).format("YYYY-MM-DD HH:mm:ss");
		// 					//console.log("inv ", inv);
		// 					var s2 = moment.parseZone(inv1).format("x")
		// 					//console.log(s2);
		// 					var diffreetime1 = moment.duration(moment(createdate).diff(inv1));
		// 					var delaydays1 =Math.round(diffreetime1.asDays());
		// 					console.log("delaydays1 ", delaydays1)
		// 					if(delaydays1>=0 && delaydays1<=15)
		// 					{
		// 						stockzerofifteen.push(item)
		// 						zeroamt = parseFloat(zeroamt)+parseFloat(item.SEC_FRET_AMT)
		// 					}
		// 					if(delaydays1>=16 && delaydays1<=30)
		// 					{
		// 						stockfifteenthirty.push(item)
		// 						fifteen = parseFloat(fifteen)+parseFloat(item.SEC_FRET_AMT)
		// 					}
		// 					if(delaydays1>=31 && delaydays1<=45)
		// 					{
		// 						stockthirtyonefortyfive.push(item)
		// 						thirty = parseFloat(thirty)+parseFloat(item.SEC_FRET_AMT)
		// 					}
		// 					if(delaydays1 > 45)
		// 					{
		// 						stockmorefortyfive.push(item)
		// 						moreforty = parseFloat(moreforty)+parseFloat(item.SEC_FRET_AMT)
		// 					}
		// 				}
		// 			}
		// 		})
				
		// 	}

		// 	// console.log("stockzerofifteen ", stockzerofifteen.length)
		// 	// console.log("stockfifteenthirty ", stockfifteenthirty.length)
		// 	// console.log("stockthirtyonefortyfive ", stockthirtyonefortyfive.length)
		// 	// console.log("stockmorefortyfive ", stockmorefortyfive.length)
		// 	this.setState({
		// 		stockzerofifteen:stockzerofifteen,
		// 		stockfifteenthirty:stockfifteenthirty,
		// 		stockthirtyonefortyfive:stockthirtyonefortyfive,
		// 		stockmorefortyfive:stockmorefortyfive,
		// 		zeroamt:zeroamt,
		// 		fifteen:fifteen,
		// 		thirty:thirty,
		// 		moreforty:moreforty
        //     })

		// })

		var records = []
        redirectURL.post("/consignments/secondarypayments", parameters)
        .then((response) => {
            //console.log(response.data)
			var records = response.data.records;
			//console.log("records ", records)
			var recordsarr = []
			var zerofifteen=[]
			var fifteenthirty=[]
			var thirtyonefortyfive=[]
			var morefortyfive=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    var sec_outstand_delay_days = "";
					if(item.sec_outstand_delay_days === undefined)
					{
						sec_outstand_delay_days = ""
					}
					else{
						sec_outstand_delay_days = item.sec_outstand_delay_days
					}

					var sec_status = "";
					if(item.sec_status === undefined)
					{
						sec_status = ""
					}
					else{
						sec_status = item.sec_status
					}
					var sec_payment_date = "";
					if(item.sec_payment_date === undefined)
					{
						sec_payment_date = ""
					}
					else{
						sec_payment_date = item.sec_payment_date
					}
					var status = ""
					if(item.prim_status === undefined)
					{
						status="Pending"
					}
					else
					{
						status=item.prim_status
					}
					var payment_date = ""
					if(item.prim_payment_date === undefined)
					{
						payment_date=""
					}
					else
					{
						payment_date=item.prim_payment_date
					}

					var secstatus = ""
					if(item.sec_status === undefined)
					{
						secstatus="Pending"
					}
					else
					{
						secstatus=item.sec_status
					}
					var sec_payment_date = ""
					if(item.sec_payment_date === undefined)
					{
						sec_payment_date=""
					}
					else
					{
						sec_payment_date=item.sec_payment_date
					}
					// var inv = moment.parseZone(item.INV_DATE).format("YYYY-MM-DD HH:mm:ss");
					// //console.log("inv ", inv);
					// var s2 = moment.parseZone(inv).format("x")
					// //console.log(s2);
					// var diffreetime = moment.duration(moment(createdate).diff(inv));
					// var delaydays =Math.round(diffreetime.asDays());
					
					// if((delaydays<0 || delaydays>=0) && delaydays<=15)
					// {
					// 	zerofifteen.push(item)
					// }
					// if(delaydays>=16 && delaydays<=30)
					// {
					// 	fifteenthirty.push(item)
					// }
					// if(delaydays>=31 && delaydays<=45)
					// {
					// 	thirtyonefortyfive.push(item)
					// }
					// if(delaydays > 45)
					// {
					// 	morefortyfive.push(item)
					// }
                    recordsarr.push({
                        "_id" : item._id,
                        "TRIP" : item.TRIP,
                        "LOAD_NO" : item.LOAD_NO,
                        "INV_DATE" : item.INV_DATE,
                        "POD_DATE" : item.POD_DATE,
                        "FOR_CITY" : item.FOR_CITY,
                        "TRTR" : item.TRTR,
                        "TRANS_NAME" : item.TRANS_NAME,
                        "REGN_NO" : item.REGN_NO,
                        "Chassis_No" : item.Chassis_No,
                        "QTY" : item.QTY,
						"SEC_LOAD_NO" : item.SEC_LOAD_NO,
						"SEC_LOAD_DATE" : item.SEC_LOAD_DATE,
						"SEC_REG_NO" : item.SEC_REG_NO,
						"SEC_TPT_CODE" :item.SEC_TPT_CODE,
                        "SEC_LSP_NAME" : item.CONS_NAME,
                        "SEC_FRET_AMT" : item.SEC_FRET_AMT,
                        "SEC_DMG_AMT" : item.SEC_DMG_AMT,
                        "SEC_SORT_AMT" : item.SEC_SORT_AMT,
                        "SEC_PANALTY_AMT" : item.SEC_PANALTY_AMT,
                        "SEC_DELAY_AMT" : item.SEC_DELAY_AMT,
                        "NET_SEC_FRET_AMT" : item.NET_SEC_FRET_AMT,
                        "sec_outstand_delay_days":sec_outstand_delay_days,
						"sec_status":sec_status,
						"sec_remarks":item.sec_remarks,
						"prim_remarks":item.prim_remarks,
						"sec_payment_date":sec_payment_date,
						"prim_status":status,
                        "prim_payment_date":payment_date,
						"sec_status":secstatus,
                        "sec_payment_date":sec_payment_date,
						"prim_uploadfiles":item.prim_uploadfiles,
						"sec_uploadfiles":item.sec_uploadfiles,
						"prim_upload_date":item.prim_upload_date,
						"prim_bill_date":item.prim_bill_date,
						"sec_upload_date":item.sec_upload_date,
						"sec_bill_date":item.sec_bill_date,
						"sec_is_payment_receive":item.sec_is_payment_receive
                    })
                })
            }
            this.setState({
				rowData:recordsarr,
				// zerofifteen:zerofifteen,
				// fifteenthirty:fifteenthirty,
				// thirtyonefortyfive:thirtyonefortyfive,
				// morefortyfive:morefortyfive
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = async() => {
        await this.setState({
			show: false,
			showreload:false
		});
		//await window.location.reload()
    }
	closeAlertReload = async() => {
        await this.setState({
			showreload:false
		});
		await window.location.reload()
    }

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("Columns ", window.colState)
		console.log("Group ", window.groupState)
		console.log("Sort ", window.sortState)
		console.log("Filter ", window.filterState)
		let screenpage = 'Primary Payments Dashboard';

	

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	handlerBillDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		upload_bill_date:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}
	
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}

    onClickFilterButton = async () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Date Filter Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		

		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
        }

        var transportercode = "";
        // console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
         if(localStorage.getItem('transportercode') === undefined)
         {
             transportercode = "";
         }
         else{
             transportercode = localStorage.getItem('transportercode');
         }
         var parameters = {
             pagetype:"secondary",
             transporter_code:transportercode,
             startDate:sDate,
             endDate:eDate
         }
        
       // console.log("requestparams ", parameters)
        this.onLoadDashboardData(parameters)
    }

    onClickFilterResetButton(){
        var transportercode = "";
        // console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
         if(localStorage.getItem('transportercode') === undefined)
         {
             transportercode = "";
         }
         else{
             transportercode = localStorage.getItem('transportercode');
         }
         var parameters = {
             pagetype:"secondary",
             transporter_code:transportercode,
             startDate:"",
             endDate:""
         }
        
       // console.log("requestparams ", parameters)
        this.onLoadDashboardData(parameters)
    }

    onClickUpdateData()
    {
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
          //  loadshow:'show-m'
        });
        var rowCount = this.gridApi.getSelectedNodes();
        //console.log("rowCount ", rowCount)
        var updateitems = []
        if(rowCount.length > 0)
        {
            rowCount.map((item) => {
                updateitems.push(item.data)
            })
        }
        //console.log("updateitems ", updateitems)
        if(updateitems.length > 0)
        {
            var paramtrs = {
                updatedata : updateitems,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username")

			}
            redirectURL.post("/consignments/updateSecondaryPaymentLSPData", paramtrs)
            .then((response) => {
                //console.log(response.data)
                if(response.data.status == 'success')
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully updated",
                        basicType:"success"
                    });
					this.gridApi.deselectAll(); 
					var transportercode = "";
					// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
					if(localStorage.getItem('transportercode') === undefined)
					{
						transportercode = "";
					}
					else{
						transportercode = localStorage.getItem('transportercode');
					}
					var parameters = {
						pagetype:"secondary",
						transporter_code:transportercode,
						startDate:this.state.startDate,
						endDate:this.state.endDate
					}

					this.onLoadDashboardData(parameters)
                }
            })
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"No Item selected",
                basicType:"info"
            })
        }
        //redirect
    }
    onRowSelected(event) {

	}
	
		
	getTruckConsignments=(event)=>{
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "Select..." || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true
			})
		}
		else
		{
			if (googleAnalytics.page.enableGA) {
				let pageTitle = this.state.pageTitle;
				let eventOptions = {
					"category": pageTitle,
					"action": "Search record by "+selectedFilter,
					"label": googleAnalytics.page.action.formAction,
				}
				googleAnalytics.logEvent(eventOptions);
			}

			var createdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			//console.log("createdate ",createdate);
			var s1 = moment.parseZone(createdate).format("x")
			//console.log("s1 ",s1)
			
			console.log(selectedFilter,inputTruckConsignment);
			var parametes = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
			}
			redirectURL.post("/consignments/searchpaymentinfo",parametes)
			.then((response)=>{
				var records = response.data.records;
				var recordsarr = []
				var zerofifteen=[]
				var fifteenthirty=[]
				var thirtyonefortyfive=[]
				var morefortyfive=[]
				if(records.length > 0)
				{
					records.map((item) => {
						
						var outstand_delay_days = ""
						if(item.prim_outstand_delay_days === undefined)
						{
							outstand_delay_days=""
						}
						else
						{
							outstand_delay_days=item.prim_outstand_delay_days
						}
						var status = ""
						if(item.prim_status === undefined)
						{
							status="Pending"
						}
						else
						{
							status=item.prim_status
						}
						var payment_date = ""
						if(item.prim_payment_date === undefined)
						{
							payment_date=""
						}
						else
						{
							payment_date=item.prim_payment_date
						}

						var secstatus = ""
						if(item.sec_status === undefined)
						{
							secstatus="Pending"
						}
						else
						{
							secstatus=item.sec_status
						}
						var sec_payment_date = ""
						if(item.sec_payment_date === undefined)
						{
							sec_payment_date=""
						}
						else
						{
							sec_payment_date=item.sec_payment_date
						}
						var inv = moment.parseZone(item.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
						//console.log("inv ", inv);
						var s2 = moment.parseZone(inv).format("x")
						//console.log(s2);
						var diffreetime = moment.duration(moment(createdate).diff(inv));
						var delaydays =Math.round(diffreetime.asDays());
						console.log("delaydays ", delaydays)
						if((delaydays<0 || delaydays>=0) && delaydays<=15)
						{
							zerofifteen.push(item)
						}
						if(delaydays>=16 && delaydays<=30)
						{
							fifteenthirty.push(item)
						}
						if(delaydays>=31 && delaydays<=45)
						{
							thirtyonefortyfive.push(item)
						}
						if(delaydays > 45)
						{
							morefortyfive.push(item)
						}
						recordsarr.push({
							"_id" : item._id,
							"TRIP" : item.TRIP,
							"LOAD_NO" : item.LOAD_NO,
							"INV_DATE" : item.INV_DATE,
							"POD_DATE" : item.POD_DATE,
							"IMM" : item.IMM,
							"IMM_CITY" : item.IMM_CITY,
							"FOR" : item.FOR,
							"FOR_CITY" : item.FOR_CITY,
							"TRTR" : item.TRTR,
							"TRANS_NAME" : item.TRANS_NAME,
							"REGN_NO" : item.REGN_NO,
							"T_MO" : item.T_MO,
							"YR_MFG" : item.YR_MFG,
							"N" : item.N,
							"BMODE" : item.BMODE,
							"QTY" : item.QTY,
							"CAR_EQUI" : item.CAR_EQUI,
							"TOT_FREIGHT" : item.TOT_FREIGHT,
							"TOT_DMG_AMT" : item.TOT_DMG_AMT,
							"TOT_SORT_AMT" : item.TOT_SORT_AMT,
							"TOT_PANALITY" : item.TOT_PANALITY,
							"TOT_DELAY_AMT" : item.TOT_DELAY_AMT,
							"NET_TOT_FRET_AMT" : item.NET_TOT_FRET_AMT,
							"PRIM_FRET_AMT" : item.PRIM_FRET_AMT,
							"PRIM_DMG_AMT" : item.PRIM_DMG_AMT,
							"PRIM_SORT_AMT" : item.PRIM_SORT_AMT,
							"PRIM_PANALTY_AMT" : item.PRIM_PANALTY_AMT,
							"PRIM_DELAY_AMT" : item.PRIM_DELAY_AMT,
							"NET_PRIM_FRET_AMT" : item.NET_PRIM_FRET_AMT,
							"TVP_STOCK_CHARGES" : item.TVP_STOCK_CHARGES,
							"SEC_LOAD_NO" : item.SEC_LOAD_NO,
							"SEC_REG_NO" : item.SEC_REG_NO,
							"CONS_NAME" : item.CONS_NAME,
							"SEC_FRET_AMT" : item.SEC_FRET_AMT,
							"SEC_DMG_AMT" : item.SEC_DMG_AMT,
							"SEC_SORT_AMT" : item.SEC_SORT_AMT,
							"SEC_PANALTY_AMT" : item.SEC_PANALTY_AMT,
							"SEC_DELAY_AMT" : item.SEC_DELAY_AMT,
							"NET_SEC_FRET_AMT" : item.NET_SEC_FRET_AMT,
							"SEC_LOAD_DATE" : item.SEC_LOAD_DATE,
							"SEC_TPT_CODE" :item.SEC_TPT_CODE,
							"prim_outstand_delay_days":outstand_delay_days,
							"prim_status":status,
							"prim_payment_date":payment_date,
							"sec_status":secstatus,
							"sec_remarks":item.sec_remarks,
							"prim_remarks":item.prim_remarks,
							"sec_payment_date":sec_payment_date,
							"prim_uploadfiles":item.prim_uploadfiles,
							"sec_uploadfiles":item.sec_uploadfiles,
							"prim_upload_date":item.prim_upload_date,
							"prim_bill_date":item.prim_bill_date,
							"sec_upload_date":item.sec_upload_date,
							"sec_bill_date":item.sec_bill_date,
							"sec_is_payment_receive":item.sec_is_payment_receive
						})
					})
				}
			//	console.log("primarytrans ", primarytrans)

				this.setState({
					rowData:recordsarr,
					loadshow:'show-n',
					overly:'show-n',
					zerofifteen:zerofifteen,
					fifteenthirty:fifteenthirty,
					thirtyonefortyfive:thirtyonefortyfive,
					morefortyfive:morefortyfive
				})
			}).catch=((e)=>
			{
				console.log(e);
			})	
		}
		
	}


	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	formHandler(event){
		//	event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Form Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}

			var formdata = new FormData()
			formdata.append("rowid", this.state.uploadrowid)
			formdata.append("pagetype", "secondary")
			formdata.append("billdate", this.state.upload_bill_date)
			//formdata.append("files", this.state.files)
	
			for(var x = 0; x<this.state.files.length; x++) {
				formdata.append('file', this.state.files[x])
			}
			formdata.append('upload_bill_date', this.state.upload_bill_date)
			formdata.append('email',localStorage.getItem("email") )
			formdata.append('username',localStorage.getItem("username") )
			formdata.append('screen',"secondary" )
			var files = {
				rowid:this.state.uploadrowid,
				files:this.state.files,
				pagetype:"secondary",
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username")
			}
			//console.log("files", files)
			if(this.state.upload_bill_date != "" && this.state.upload_bill_date != "NaN-NaN-NaN")
			{
				redirectURL.post("/consignments/uploadsecondarypaymentsdocs", formdata,{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
				})
				.then((response) => {
					//console.log("Res ", response.data)
					if(response.data.status == "success")
					{
						this.setState({
							showreload:true,
							basicTitlereload:"Successfully upload files",
							basicTypereload:"success",
							open:false,
							uploadrowid:"",
							upload_bill_date:"",
							files:[]
						})
					}
					else
					{
						this.setState({
							show:true,
							basicTitle:"Failed to upload",
							basicType:"danger"
						})
					}
				})

				var transportercode = "";
				// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
				if(localStorage.getItem('transportercode') === undefined)
				{
					transportercode = "";
				}
				else{
					transportercode = localStorage.getItem('transportercode');
				}
				var parameters = {
					pagetype:"secondary",
					transporter_code:transportercode,
					startDate:this.state.startDate,
					endDate:this.state.endDate
				}

				this.onLoadDashboardData(parameters)
			}
			else
			{
				this.setState({
					show:true,
					basicTitle:"Bill Date is mandatory",
					basicType:"danger"
				})
			}
		}
	onClickShowUploadModal(params)
	{
		this.setState({
			open:true,
			uploadrowid:params._id
		})
	}

	onCloseModal = () => {
		this.setState({ open: false, rejectopen:false });
	 };
 
	changeFileHandler = async (e) => {
		console.log(e.target.files)
		var files = Array.from(e.target.files);
		this.setState({
			files:files
		})
	}

	onClickAcceptPrimaryBill(params){
		console.log("Accet prim bill ", params)
		var parameters = {
			rowid : params.data._id,
			sec_approve_status:1,
			screen:"secondary",
			prim_status:params.data.prim_status,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username")
		}
		redirectURL.post("/consignments/acceptrejectprimarybillbysecondary",parameters)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Approved Primary Bill",
				basicType:"success"
			})
		})
	}
	onClickRejectPrimaryBillModal(params){
		
		this.setState({
			rejectopen:true,
			rejectdata:params.data
		})
	}
	onClickRejectPrimaryBill(){
		//console.log("Reject prim bill ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": "Reject Action",
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var data = this.state.rejectdata;
		var remarks = this.state.remarks;
		var parameters = {
			rowid : data._id,
			sec_approve_status:0,
			screen:"secondary",
			prim_status:data.prim_status,
			remarks:remarks,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username")
		}
		redirectURL.post("/consignments/acceptrejectprimarybillbysecondary",parameters)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Rejected Payment",
				basicType:"success",
				rejectopen:false,
				rejectdata:"",
				remarks:""
			})
			var transportercode = "";
			// console.log("localStorage.getItem('transportercode') ", localStorage.getItem('transportercode'))
			if(localStorage.getItem('transportercode') === undefined)
			{
				transportercode = "";
			}
			else{
				transportercode = localStorage.getItem('transportercode');
			}
			var parameters = {
				pagetype:"secondary",
				transporter_code:transportercode,
				startDate:this.state.startDate,
				endDate:this.state.endDate
			}

			this.onLoadDashboardData(parameters)
		})
	}


	render(){
		const modalStyles  = {
			width:'300px !important',
		}
		const { open } = this.state;
		const {rejectopen} =  this.state;
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
            {
                headerName: "",
                field: "_id",
                width: 40,
                pinned:"left",
                resizable: true,
                //headerCheckboxSelection: true,
                checkboxSelection: true

            },
				{
					headerName : "TRIP",
					field : "TRIP",
					width:120,
					editable : false
                },
                {
					headerName : "LOAD_NO",
					field : "LOAD_NO",
					width:120,
					editable : false
				},
                {
					headerName : "INV_DATE",
					field : "INV_DATE",
					width:160,
                    editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.INV_DATE != "" && params.data.INV_DATE != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.INV_DATE);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
				},
                {
					headerName : "POD_DATE",
					field : "POD_DATE",
					width:160,
					editable : false,
                    valueGetter:function(params){
                        try{
                            if(params.data.POD_DATE != "" && params.data.POD_DATE != undefined)
                            {
                                return getHyphenDDMMMYYYYHHMM(params.data.POD_DATE);
                            }
                        }
                        catch(e){
                            return "";
                        }
                    }
				},
                {
					headerName : "FOR_CITY",
					field : "FOR_CITY",
					width:120,
					editable : false
				},
                {
					headerName : "TRTR",
					field : "TRTR",
					width:100,
					editable : false
				},
                {
					headerName : "TRANS_NAME",
					field : "TRANS_NAME",
					width:200,
					rowGroup : true,
					editable : false
				},
                {
					headerName : "REGN_NO",
					field : "REGN_NO",
					width:120,
					editable : false
				},
                {
					headerName : "Chassis_No",
					field : "Chassis_No",
					width:120,
					editable : false
				},
                {
					headerName : "QTY",
					field : "QTY",
					width:120,
					editable : false
				},
				{
						headerName: "SECONDARY",
						headerClass:["cellstylegridB","cellTopHeader"],
						children: 
					[
						{
							headerName : "SEC_LOAD_NO",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_LOAD_NO",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_LOAD_DATE",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_LOAD_DATE",
							width:120,
							editable : false,
							valueGetter:function(params){
								try{
									if(params.data.SEC_LOAD_DATE != "" && params.data.SEC_LOAD_DATE != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.SEC_LOAD_DATE);
									}
								}
								catch(e){
									return "";
								}
							}
						},
						{
							headerName : "SEC_REG_NO",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_REG_NO",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_LSP_CODE",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_TPT_CODE",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_LSP_NAME",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_LSP_NAME",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_FRET_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_FRET_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_DMG_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_DMG_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_SORT_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_SORT_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_PANALTY_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_PANALTY_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "SEC_DELAY_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "SEC_DELAY_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "NET_SEC_FRET_AMT",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "NET_SEC_FRET_AMT",
							width:120,
							editable : false
						},
						{
							headerName : "Outstanding delay days",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "sec_outstand_delay_days",
							width:120,
							editable : true,
							valueGetter:function(params)
							{
								try{
									var currentdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
									var inv = moment.parseZone(params.data.sec_upload_date).format("YYYY-MM-DD HH:mm:ss");
									
									var diffreetime = moment.duration(moment(currentdate).diff(inv));
									var delaydays =Math.round(diffreetime.asDays());
									return delaydays;
								}
								catch(e)
								{
									return "";
								}
							}
						},
						{
							headerName : "Status",
							headerClass:"cellstylegridB",
							cellClass:"cellstylegridB",
							field : "sec_status",
							width:160,
							editable : true
						}
					]
				},
				{
					headerName: "PRIMARY",
					headerClass:["cellstylegrid","cellTopHeader"],
					children: [
						// {
						// 	headerName : "Primary Status",
						// 	headerClass:"cellstylegrid",
						// 	cellClass:"cellstylegrid",
						// 	field : "prim_status",
						// 	width:120,
						// 	editable : false
						// },
						{
							headerName : "Primary Payment Updated On",
							headerClass:"cellstylegrid",
							cellClass:"cellstylegrid",
							field : "prim_payment_date",
							width:140,
							editable : false,
							cellEditor: DateEditor,
							valueGetter:function(params){
								//console.log("params ", params);
								try{
									if(params.data.prim_payment_date != "" && params.data.prim_payment_date != undefined)
									{
										return getHyphenDDMMMYYYY(params.data.prim_payment_date);
									}
								}
								catch(e){
									return "";
								}

							}
						},
					]
				},
				{
					headerName : "Primary Payment Proof",
					field : "prim_uploadfiles",
					width:120,
					editable : false,					
                    cellRendererFramework:ViewPrimaryDownloadPayments
				},
				{
					headerName : "Primary Payment Updated On",
					field : "prim_upload_date",
					width:120,
					editable : false,
					valueGetter:function(params){
						try{
							return getHyphenDDMMMYYYY(params.data.prim_upload_date);
						}
						catch(e){
							return "";
						}
					}
					
				},

				{
					headerName : "Secondary Reject/Remarks",
					field : "sec_bill_accept",
					width:160,
					editable : false,
					cellClass:["wraptext"],
					cellRendererSelector:function(params){
						try{
							//console.log("params.data.prim_uploadfiles ", params.data.prim_uploadfiles)
							if(params.data.prim_uploadfiles != undefined && params.data.prim_uploadfiles.length > 0
								&& params.data.sec_status == "Payment released")
							{
							
								var rendComponent = {
									component: 'SecondaryBillAcceptReject'
								};
								return rendComponent
							}
							else
							{
								// console.log("params.data.sec_remarks ", params.data.sec_remarks)
								// if(params.data.sec_remarks != "" && params.data.sec_remarks != undefined)
								// {
								// 	return params.data.sec_remarks;
								// }
								// else
								// {
								// 	return "";
								// }

								// var rendComponent = {
								// 	component: 'SecondaryBillAcceptReject'
								// };
								// return rendComponent
								return ""
								
							}
						}
						catch(e)
						{
							return "";
						}
						
					},
				},

				{
					headerName : "File",
					field : "_id",
					width:160,
                    editable : false,
                    cellEditor: DateEditor,
                    cellRendererFramework:UploadFile
				},
				
				{
					headerName : "Primary Remarks",
					field : "prim_remarks",
					width:200,
					editable : false,
					cellClass:["wraptext"]
				},
				
				{
					headerName : "Secondary Bills",
					field : "sec_uploadfiles",
					width:120,
					editable : false,					
                    cellRendererFramework:ViewDownloadPayments
				},
				{
					headerName : "Secondary Bill Date",
					field : "sec_bill_date",
					width:120,
					editable : false,
					valueGetter:function(params){
						try{
							return getHyphenDDMMMYYYY(params.data.sec_bill_date);
						}
						catch(e){
							return "";
						}
					}
					
				},
				{
					headerName : "Invoice Raised On",
					headerClass:"cellstylegridB",
					cellClass:"cellstylegridB",
					field : "sec_upload_date",
					width:120,
					editable : true,
					cellEditor: DateEditor,
					valueGetter:function(params){
						//console.log("params ", params);
						try{
							if(params.data.sec_upload_date != "" && params.data.sec_upload_date != undefined)
							{
								return getHyphenDDMMMYYYY(params.data.sec_upload_date);
							}
						}
						catch(e){
							return "";
						}

					}
				},
				{
					headerName : "Payment Receive (Yes/No)",
					field : "sec_is_payment_receive",
					width:120,
					editable:true,
                    //cellRenderer: "genderCellRenderer",
                    cellEditor: "agRichSelectCellEditor",
                    cellEditorParams: function(params){
						if(params.data.sec_uploadfiles != "" && params.data.sec_uploadfiles != undefined
						&& params.data.prim_uploadfiles != "" && params.data.prim_uploadfiles != undefined)
						{
							var attr = {
								cellHeight: 30,
								values:['Yes', 'No']
							  }
							return attr;
						}
						else{
							return {};
						}
						
					},
					valueGetter: function(params) {
						//console.log("params ", params)
						try{
							if(params.data.sec_is_payment_receive !="" && params.data.sec_is_payment_receive != undefined)
							{
								return params.data.sec_is_payment_receive;
							}
							else{
								return "";
							}
						}
						catch(e){
							return "";
						}
					}
				}
				
			];
		
		return(
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
	        	</SweetAlert>
				<SweetAlert
					show={this.state.showreload}
					type={this.state.basicTitlereload}
					title={this.state.basicTitlereload}
					onConfirm={this.closeAlertReload}
				>
	        	</SweetAlert>
	            {this.state.alert}
				<div className="row">
				<div className="col-xl-5 col-lg-5">
						<div className=" card">
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className="form-group col-xl-6 col-lg-6">
										<label>Select Type :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedFilter"
										style={{borderRadius:"0px"}} 
										options={[{
											label:"REGN_NO",
											vaue:"REGN_NO"
											},
											{
												label:"TRIP",
												value:"TRIP"
											},
											{
												label:"Load_No",value:"Load_No"
											}]} required />
									</div>
									<div className={"col-xl-6 col-lg-6 form-group"}>
										<label className="reason">REGN NO / TRIP / Load No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
                    <div className="row col-xl-7 col-lg-7"> 
						<div className=" card">
							<div className="row card-body" style={{padding:"10px 30px"}}>                     
								<div className="col-xl-6 col-lg-6 form-group">
									<label>From Date</label>
									<Datetime value={this.state.startDate} 
									disableCloseOnClickOutside={false} 
									closeOnSelect={true} 
									inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete:'off' }}
									dateFormat="YYYY-MM-DD" 
									name="startDate" 
									onChange={this.handlerStartDateTime.bind(this)} />
								
								</div>

								<div className="col-xl-6 col-lg-6 form-group">
									<label>To Date</label>
									{/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
									<Datetime value={this.state.endDate} 
									disableCloseOnClickOutside={false}
									closeOnSelect={true} 
									inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete:'off'}} 
									dateFormat="YYYY-MM-DD" 
									onChange={this.handlerEndDateTime.bind(this)} />
									
								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
									<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickFilterButton.bind(this)}>Submit</button>
									&nbsp;<button type="button" style={{margin:"0 auto"}} className="btn btn-info" onClick={this.onClickFilterResetButton.bind(this)}>Reset</button>
								</div>
							</div>
						</div>
                        
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <PrimaryCounters 
                            zerofifteen={this.state.zerofifteen.length}
                            fifteenthirty={this.state.fifteenthirty.length}
                            thirtyonefortyfive={this.state.thirtyonefortyfive.length}
							morefortyfive={this.state.morefortyfive.length}
							zeroamt={parseInt(this.state.zeroamt)}
							fifteen={parseInt(this.state.fifteen)}
							thirty={parseInt(this.state.thirty)}
							moreforty={parseInt(this.state.moreforty)}
                        />
                    </div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
                                    
                                    <span className="">
                                        <button className="float-right custom-btn f14 white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn f14 white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                
                                <div className="row">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
										<div className="col-xl-12 col-lg-12">
											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={true}
													components={this.state.components}
                                                    rowSelection={this.state.rowSelection}
                                                    suppressRowClickSelection={true}
                                                    onRowSelected={this.onRowSelected.bind(this)}
													frameworkComponents={this.state.frameworkComponents}
													gridOptions={{
														context: { componentParent: this }
													}}
													headerHeight={this.state.headerHeight}
													groupDefaultExpanded={this.state.groupDefaultExpanded}
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                        </div>
                                        <div className="col-xl-12 col-lx-12" style={{textAlign:"right"}}>
                                            <button name="submit" onClick={this.onClickUpdateData.bind(this)} className="btn btn-success">Update Now</button>
                                        </div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				

				 <Modal open={open} onClose={this.onCloseModal} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
							
							<div className="col-xl-12 col-lg-12" style={{marginLeft:"22px"}}>
								<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data" onSubmit={this.formHandler.bind(this)}>
									
									<div className="form-group mt-20p">
										<label className="">Bill Date</label> 
										<Datetime value={this.state.upload_bill_date} 
											disableCloseOnClickOutside={false} 
											closeOnSelect={true} 
											inputProps={{ placeholder: 'Bill Date', name: 'upload_bill_date', autoComplete:'off' }}
											dateFormat="YYYY-MM-DD" 
											name="upload_bill_date" 
											onChange={this.handlerBillDateTime.bind(this)} />
											
									</div>
									<div className="form-group mt-20p">
										<label className="">Upload File</label> 
										<input type="file" name="uploadFile[]" multiple={true} onChange={this.changeFileHandler.bind(this)}  className="form-control" required  />
									</div>
									<div className="col-xl-12 col-lg-12 form-group">
										<button type="button" onClick={this.formHandler.bind(this)} className="btn btn-success">Submit</button>
									</div>
									<br /><br /><br />
								</form>
							</div>

						</div>
					</Modal>

					<Modal open={rejectopen} onClose={this.onCloseModal} styles={modalStyles}>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
							
							<div className="col-xl-12 col-lg-12" style={{marginLeft:"22px"}}>
								<form method="POST" className="theme-form col-xl-12 col-lg-12" enctype="multipart/form-data" onSubmit={this.formHandler.bind(this)}>
									<div className="form-group mt-20p">
										<label className="">Remarks</label> 
										<textarea className="col-xl-12 col-lg-12" rows="8" name="remarks" value={this.state.remarks} onChange={this.changeHandler.bind(this)}></textarea>
									</div>
								
									<div className="col-xl-12 col-lg-12 form-group">
										<button type="button" onClick={this.onClickRejectPrimaryBill.bind(this)} className="btn btn-success">Submit</button>
									</div>
									<br /><br /><br />
								</form>
							</div>

						</div>
					</Modal>

				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }



  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}