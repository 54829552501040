import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
var redirectURL = require('../redirectURL');
let Components = {};
//Components['chart'] = require('../dashboard/chartComponent').default;
//Components['summary'] = require('../dashboard/summaryComponent').default;
//Components['maps'] = require('../dashboard/mapsComponent').default;

redirectURL.post('/layouts/gridComponents')
.then((response) => {
	var res = JSON.stringify(response);
	//console.log(JSON.parse(res).data[0]);
	let citems = JSON.parse(res).data;
	//console.log("citems ", citems)
	try{
	if(citems != undefined && citems != '' && citems != null && citems.message != 'Error: MongoError: pool destroyed')
	{
		var comps = citems.map((item) => 
			Components[item.component_tag] = require(`../layouts/`+item.component_tag+`Component`).default 
		);
	}}
	catch(e){
		
	}
	//console.log("dsdsa",citems);
	
	//components[citems.component_tag] = require('../dashboard/'+citems.component_tag+'Component').default;
});

export default Components;