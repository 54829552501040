import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ContainerCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		block1:0,
            block2:0,
            block3:0,
            block4:0,
            
    	}
    
    }
    componentWillReceiveProps(newprops){
       //console.log("new props ", newprops)
        if(newprops.counters != '')
        {
            this.setState({
                block1:newprops.counters.highseas,
                block2:newprops.counters.inport,
                block3:newprops.counters.icd,
                block4:newprops.counters.msil,
            });
        }
        
    }
    handleClick(e){
        this.props.context.onTabClick(e)
    }
    render(){
        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" style={{padding:"10px"}}>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d">
                                        <span className="f13"><i className="icofont icofont-ship-alt f24 txt-info"></i><br /> High Seas</span>
                                        <h4 className="txt-info f50 cpointer" onClick={this.handleClick.bind(this,1)}><span className="counter"><CountUp end={(this.state.block1 != '')?this.state.block1:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col">
                                        <span className="f13"><i className="icofont icofont-ship f24 txt-success"></i><br /> In Port</span>
                                        <h4 className="txt-success f50 cpointer" onClick={this.handleClick.bind(this,2)}><span className="counter"><CountUp end={(this.state.block2 != '')?this.state.block2:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col">
                                        <span className="f13"><i className="icofont icofont-ui-home f22 txt-danger"></i><br /> In ICD Gahri</span>
                                        <h4 className="txt-danger f50 cpointer" onClick={this.handleClick.bind(this,3)}><span className="counter"><CountUp end={(this.state.block3 != '')?this.state.block3:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col">
                                        <span className="f13"><i className="icofont icofont-truck f24 txt-secondary"></i><br /> In Transit to MSIL</span>
                                        <h4 className="txt-secondary f50 cpointer" onClick={this.handleClick.bind(this,4)}><span className="counter"><CountUp end={(this.state.block4 != '')?this.state.block4:0}/></span></h4>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>


               {/* <div className="col-xl-2 col-lg-6 col-sm-6 beffect">
	                <div className="card">
	                    <div className="card-body">
	                    	<div className="brd brd-txt3"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt3 counter font-success"><CountUp className="font-success" end={this.state.notrans} /></h4>
	                                    
	                                </div>
	                               
	                            </div>
	
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">Current Enroute Stoppages</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                */}
            </div>
        );
    }
}






