import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';
export default class TooltipAgGrid extends Component {
    getReactContainerClasses() {
      return ['custom-tooltip'];
    }
  
    render() {
      const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
      return (
        <div
          className="custom-tooltip"
        >
          <p>
            <span>{data.dct_marked_user}</span>
          </p>
          
          <p>
            <span>{getDDMMYYYYHHMMSS(data.dct_comment_on)}</span>
          </p>
          <p>
            <span>{data.dct_comment}</span>
          </p>
        </div>
      );
    }
  }