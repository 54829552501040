import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';


import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';

import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Counters from './consignmentdashboardcountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
var infoBox = require('../common/google-infowindow');
HC_more(Highcharts) 
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class DashboardConsignment extends Component {

	constructor(props){
		super(props);
		this.state={
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			dealer:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			deptcode:'',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			counters:{},
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				consignmentActions:ConsignmentActions,
				kpiActions:KpiActions,
				kpiDeviationActions:KpiDeviationActions,
				kpiTransitDelayActions:KpiTransitDelayActions,
				nooverspeed:Nooverspeed,
				notransit:Notransit,
				kpiNightDriveActions:KpiNightDriveActions,
				nonightdrive:Nonightdrive,
				kpiEnrouteStoppagesActions:KpiEnrouteStoppagesActions,
				noenroutes:Noenroutes,
				dateFormater:DateFormater,
				gps:Gps,
				nogps:NoGps,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

			},
			transitbubble:[],
			overspeedbubble:[],
			gpsnobubble:[],
			nightdrivebubble:[],		
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		var checkerData;
		if(this.props.data)
		{
			checkerData="Yes";
			this.onPropsApply(this.props.data);
		}
		else{
			checkerData="No";
			this.onPropsApply("testing success");
		}
		
	}
	componentDidMount(){
		//console.log("Props ", this.props.match)

		var urlpath;
		var dpt;
		
		urlpath = '/consignments';
		dpt='all';
		this.setState({
			deptcode:dpt
		});
		/*
		//Use following code after ACL enable
		var consignData = {
			dept_code:dpt
		}
		urlpath = '/consignments/consignmentByDepartment';
			dpt=localStorage.getItem('dept_code');
		redirectURL.post(urlpath,consignData)    */
		redirectURL.post(urlpath)
		  .then((response) => {
            var records = response.data;
            //console.log("records", records);
            this.setState({
                rowData:records
			});
			
		  })
		  .catch(function (error) {
		    console.log(error);
		});

		/*Dashboard Counters*/
		redirectURL.post('/consignments/consignmentCounters')
		  .then((response) => {
            var records = response.data;
			console.log("records", records);
			
            this.setState({
                counters:records
			});
			var transbubble = [];
			var oversped=[];
			var ngps=[];
			var nightrd=[];
			/*for(var t=0;t<records.transit_delay;t++)
			{
				if(t == 200)
				{
					break;
				}
				transbubble.push({'name':'Transit Delay', 'value':records.transit_delay})
			}
			//console.log("Bubble ", transbubble)


			for(var v=0;v<records.overspeeding;v++)
			{
				if(v == 200)
				{
					break;
				}
				oversped.push({'name':'Overspeed', 'value':records.overspeeding})
			}


			for(var ng=0;ng<records.gps_data_available;ng++)
			{
				if(ng == 200)
				{
					break;
				}
				ngps.push({'name':'GPS Data Available', 'value':records.gps_data_available})
			}


			for(var nd=0;nd<records.night_driving;nd++)
			{
				if(nd == 200)
				{
					break;
				}
				nightrd.push({'name':'Night Driving', 'value':records.night_driving})
			}
			*/
			transbubble.push(records.transit_delay)
			
			oversped.push(records.overspeeding)
		
			ngps.push(records.gps_data_available)
			
			nightrd.push(records.night_driving)
			this.setState({
				transitbubble:transbubble,
				overspeedbubble:oversped,
				gpsnobubble:ngps,
				nightdrivebubble:nightrd
			});

		  })
		  .catch(function (error) {
		    console.log(error);
		});
	};
	onPropsApply = (params) =>{
		console.log("Props Params ", params)
	}
	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var reqData = {
            consignment_code:e.consignment_code
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);
		
			this.setState({
				uploadDivWidth:'30%',
				sliderTranslate:"slider-translate-50p",
				showDiv:'show-m',
				timelinedata:records,
				loadshow:'show-n',
				overly:'show-m'
			});
		})
		.catch(function (error) {
		console.log(error);
		});
		
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	onShowRouteDiv = (e) => {
		
		this.setState({
			loadshow:'show-m'
		});
		var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		var edate ='';
		var end_date;
		if(e.status == 2)
		{
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			
			edate = getDDMMYYYYHHMMSS(end_date);
		}

		else if(e.status == 3 || e.status == 4)
		{
			end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
			edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
		}

		else if(e.status == 5)
		{
			end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
			edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
		}
		else{
			end_date = e.created_date.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
			edate = getDDMMYYYYHHMMSS(e.created_date);
		}
		
		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}	
		
		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		var reqData = {
            truck_no:e.truck_no,
			consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", e)

			this.setState({
				loadshow:'show-m'
			});
			if(records.coords.length == 0)
			{
				//console.log("records", records.coords);
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				if(records != '')
				{
					var sdate = e.gate_out_time;
					var edate ='';
					if(e.status == 2)
					{
						//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
						//var edatesecond = new Date(ms);
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						edate = getDDMMYYYYHHMMSS(end_date);
					}

					else if(e.status == 3 || e.status == 4)
					{
						edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
					}

					else if(e.status == 5)
					{
						edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
					}
					else{
						edate = getDDMMYYYYHHMMSS(e.created_date);
					}
					//console.log("End Date ", edate)
					this.setState({
						sliderRouteTranslate:"slider-translate-60p",
						showDiv:'show-m',
						mapinfo:records,
						dealer:e.consignee_code,
						consignment_code:"Consignment : "+e.consignment_code,
						routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
						loadshow:'show-n',
						sidebarSubHeader:"Consignment Information",
						overly:'show-m'
					});
					this.renderMap();
				}
			}
			
			
		})
		.catch(function (error) {
		console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}

	/*Truck Sidebar Component Starts*/
	async onShowSidebar(e){
		this.setState({
			tabsliderTranslate:""
		});
		console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = AppendComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						truckInfo:truckrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

				/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						consignments:consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
					
					this.setState({
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				tabsliderTranslate:"slider-translate"
			});
		}

		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = AppendComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				tabsliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = AppendComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				tabsliderTranslate:"slider-translate"
			});
		}
		/*End*/
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };

	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;
    	
		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;
		

		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}
			
		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
				 var contentarr = []
				 var header = "Break - "+(l+1)
				 contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
				 contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)+" ("+secondsToString(locations[l].break_time_seconds)+")"})
				  var contentString = infoBox(marker.icon, header, contentarr)
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
					   var contentarr = []
	       		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        var header = "Starting Point"
				contentarr.push({"key":"Started at ", "value":getDDMMYYYYHHMMSS(routeinfo.start_time)})
				var contentString = infoBox(marker.icon, header, contentarr,'')
				  
				  
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
					var contentarr = []
	   		    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
				   var header = "End Point"
				   contentarr.push({"key":"End at ", "value":getDDMMYYYYHHMMSS(routeinfo.end_time)})
				   var contentString = infoBox(marker.icon, header, contentarr)
						
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds)
    }



	onShowOverspeedKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],
													
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/overspeed', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			if(params.column.colId == 'overspeeding_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	onShowTransitKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapTransitAction,
								width:100,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_start);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ headerName: "Leg End Time",field: "leg_end_eta", 
							width:120,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	},
							{ headerName: "Exp. Leg Distance (KM)",
							field: "leg_expected_distance", width:140,resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.leg_expected_distance);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ headerName: "Actual Leg Distance Covered (KM)",
							field: "actual_leg_distance", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
									{
										return Math.round(params.data.actual_leg_distance);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},							
							{ headerName: "Exp. Distance from Trip Start(KM)",
							field: "expected_distance_start_to_leg", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
									{
										return Math.round(params.data.expected_distance_start_to_leg);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
							},
							{ headerName: "Actual Distance from Trip Start (KM)",
							field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
									{
										return Math.round(params.data.actual_distance_traveled_start_to_leg);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							},
							
							{ 
								headerName: "GPS Data Available",
								field: "no_gps_data", 
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.no_gps_data == 1)
									{
										return "Not Available";
									}
									if(params.data.no_gps_data == 0)
									{
										return "Available";
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							},
							
							
							{ 
								headerName: "Trip Completed",
								field: "trip_completed", 
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.trip_completed == 1)
									{
										return "Yes";
									}
									if(params.data.trip_completed == 2)
									{
										return "";
									}
									if(params.data.trip_completed == 0)
									{
										return "";
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							}							
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',
												
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/transitdelay', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if( params.column.colId == 'transit_delay' ||  params.column.colId == 'no_gps_data')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}


	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RouteNightDrivemap,
								width:100,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
							{ headerName: "Traveled From",field: "first_instance", width:180,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{ headerName: "Traveled Until",field: "recent_instance",resizable: true ,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{ headerName: "Distance Traveled (km)",field: "distance_from_first_instance", width:180,
								resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.distance_from_first_instance)
								}
							}							
						],
									
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
					
				}
			});
			
			if(params.column.colId == 'nightdriving_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}


	onShowEnrouteStoppagesKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapTransitAction,
								width:100,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
							{ headerName: "Leg Start Time",field: "leg_start", width:180,resizable: true },
							{ headerName: "Leg End Time",field: "leg_end_eta", width:180,resizable: true },
							{ headerName: "Exp. Leg Distance (KM)",field: "leg_expected_distance", width:220,resizable: true },
							{ headerName: "Act. Leg Distance(KM)",field: "actual_leg_distance", width:220,resizable: true },							
							{ headerName: "Exp. Distance From Start (KM)",field: "expected_distance_start_to_leg", width:220,resizable: true },
							{ headerName: "Act. Distance From Start (KM)",field: "actual_distance_traveled_start_to_leg", width:220,resizable: true }							
						]							
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/transitdelay', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if(params.column.colId == '_id_4')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}
	onShowDeviate = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
	
		if(params != 0)
		{
			var overspeedData=[];
		
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]							
					},
					getDetailRowData: async function(params) {
						console.log("Step 4 ",overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});
			
			if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}
	onRowClicked(params) {
		console.log("row click ", params)
		if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
		{

			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}
		
	  }
	
	onShowRouteMap(rownode){
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:finstance,
			recent_instance:rinstance,
			screen:"consignment"
		}
		
		 redirectURL.post('/reports/overspeedgpsroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    //console.log(response.data);
		   this.setState({
				mapinfo:response.data,
				dealer:rownode.consignee_code,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				overly:'show-n',
				loadshow:'show-n'
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}
	
	onShowTransitRouteMap(rownode){
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.leg_start);
    	var r = new Date(rownode.leg_end_eta);
    	var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m'

			});
				this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowNightDriveRouteMap(rownode){
		console.log("nightdrivenode", rownode);
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			console.log("nightroutedata",response.data);
			
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				loadshow:'show-n',
				overly:'show-m'
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
				{
					headerName: "Critical",
					field: "is_critical",
					width: 90,
					pinned: 'left',
					cellRenderer: function(params) { 
						var input = document.createElement('input');
						input.type="checkbox";
						params.value=0;
						var status = false;
						if(params.data.is_critical == true)
						{
							status=true;
							input.checked=true;
							params.value=1;
						}
						input.addEventListener('click', function (event) {
							console.log(event);
							params.value=!params.value;
							console.log(params);
							onSetCritical(params.data, params.value)
						});
						return input;
					},
					filter: false,resizable: true
					
				},
				{
					headerName: "Actions",
					field: "_id",
					width: 90,
					pinned: 'left',
					cellRenderer:'consignmentActions',
					filter: false,resizable: true
					
				},
				
				
				{
					headerName: "Transit Delay",
					field: "transit_delay",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.transit_delay == 1)
						{
							var rendComponent = {
								component: 'kpiTransitDelayActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'notransit'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "",
					field: "no_gps_data",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						//console.log(params.data)
						if(params.data.no_gps_data == 1)
						{
							var rendComponent = {
								component: 'nogps'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'gps'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "Overspeed",
					field: "overspeeding_exception",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.overspeeding_exception == 1)
						{
							var rendComponent = {
								component: 'kpiActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'nooverspeed'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "Night Drive",
					field: "nightdriving_exception",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.nightdriving_exception == 1)
						{
							var rendComponent = {
								component: 'kpiNightDriveActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'nonightdrive'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				/*{
					headerName: "Enroute Stoppages",
					field: "_id",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.enroute_stop == 1)
						{
							var rendComponent = {
								component: 'kpiEnrouteStoppagesActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'noenroutes'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				*/
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 120,
					pinned: 'left',
					editable:false,
					filter: "agSetColumnFilter",resizable: true
					//cellRenderer: "agGroupCellRenderer"
				},
				
		        {
		  	        headerName: "Truck No",
		  	        field: "truck_no",
		  	        width: 100,
		  	        filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					width: 120,   
					pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.expected_trip_end);
						 
					} 	          
				  },          
				  {
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 120,   
					pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						//console.log(" ETA ", params.data)
						  if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						  {
							  return getDDMMYYYYHHMMSS(params.data.expected_trip_end);
						  }
						  else{
							  return getDDMMYYYYHHMMSS(params.data.revised_trip_end);
						  }
					},
					cellClass:function(params){
						var g1 = getDDMMYYYY(params.data.revised_trip_end);
						var g2 = getDDMMYYYY(params.data.expected_trip_end);
						var s1 = g1.split("-");
						var s2 = g2.split("-");
						var gdate1 = new Date(s1[2],s1[1], s1[0]);
						var gdate2 = new Date(s2[2],s2[1], s2[0]);
						//console.log("gdate1 ", gdate1.getTime())
						//console.log(g1);
						//console.log("gdate2 ", gdate2.getTime())
						//console.log(g2);
						
						if(gdate1.getTime() > gdate2.getTime())
						{
							return 'cellstylegridR'
						}

						if(gdate1.getTime() < gdate2.getTime())
						{
							return 'cellstylegridGBg'
						}
						else{
							return ''
						}
					}	          
				  },

				{
					headerName: "Status",
					field: "status",
					width: 120, 
					pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						//console.log("GPS ", params.data.gps_data_available);
						if(params.data.status == 1)
						{
							return "Inside Plant"
						}
						else if(params.data.status == 2)
						{
							return "In Transit"
						}
						else if(params.data.status == 3)
						{
							return "Near Destination"
						}
						else if(params.data.status == 4)
						{
							return "Reached Destination"
						}	
						
						else if(params.data.status == 5)
						{
							return "Left Destination"
						}					
						else{
							return ""
						}
						 
					} 	          
				  },   
	  	        {
		          headerName: "Transporter Name",
                  field: "transporter",
                  valueGetter:function(params){
					  //console.log(params.data.transporter);
					  if (params.data.hasOwnProperty('transporter'))
					  {
						  if (params.data.transporter.length > 0)
						  {
							return params.data.transporter[0].transporter_name;
						  }
					  }
                        
                  },
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true	          
                },                
	  	        {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    width: 150,
                    filter: "agSetColumnFilter",
					cellRenderer:''	,resizable: true    	          
                },               
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  width: 150,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true	    	          
                },             
                {
                  headerName: "Department Code",
                  field: "dept_code",
                  width: 120,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:''  ,resizable: true  	          
                },          
                
                {
                    headerName: "Gate in Time",
                    field: "gate_in_time",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.gate_in_time);
					},
					resizable: true	    	          
				},
				{
                    headerName: "GPS Gate in Time",
                    field: "inside_fence",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.inside_fence);
					},
					resizable: true 
                },				
                {
                    headerName: "Gate out Time",
                    field: "gate_out_time",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.gate_out_time);
					},
					resizable: true    
                },
                {
                    headerName: "GPS Gate out Time",
                    field: "outside_fence",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.outside_fence);
					},
					resizable: true    
				},

                {
                    headerName: "Reached Destination At",
                    field: "first_dealer_reported",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.recent_dealer_reported);
					},
					resizable: true    
				},

                {
                    headerName: "Left Destination At",
                    field: "left_dealer_marked",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.left_dealer_marked);
					},
					resizable: true    
				},
                {
                    headerName:"Local Movement(Yes/No)",
                    field:"local_movement",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },			
                {
                    headerName:"Transit Time (Days)",
                    field:"transit_time",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Expected Distance (KM)",
                    field:"distance_in_km",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Cluster",
                    field:"cluster",
                    width:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Vehicle Mode",
                    field:"vehicle_mode",
                    widht:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },                             				
                {
                    headerName: "Items",
                    field: "items",
                    width: 200,                 
                    filter: "agSetColumnFilter",
					cellRenderer:'' ,resizable: true   	          
                }
	  	        
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}
	var hText = 'All';
	if(this.state.deptcode == 'SNDG')
	{
		hText = 'Sales and Dispatch'
	}

	if(this.state.deptcode == 'LOG-PRT')
	{
		hText = 'Spare Parts'
	}
	const highchartgpsinterval = {
		title: {
			text: ''
		},
		xAxis: {
			categories: ['Transit Delay', 'Overspeed', 'NightDriving', 'GPS Data Available']
		},
		colors: [
			'#FF5370', 
			'#00c292', 
			'#ab8ce4', 
			'#26c6da', 
			'#3D96AE', 
			'#DB843D', 
			'#92A8CD', 
			'#A47D7C', 
			'#B5CA92'
			],
			
		plotOptions: {
			column: {
				colorByPoint: true
			}
		},   
		labels: {
			items: [{
				html: '',
				style: {
					left: '50px',
					top: '18px',
					color: ( // theme
						Highcharts.defaultOptions.title.style &&
						Highcharts.defaultOptions.title.style.color
					) || 'black'
				}
			}]
		},
		/*series: [{
			name: 'Overspeeding',
			color:'rgba(0, 194, 146, 0.8)',
			data: this.state.overspeedbubble
		}
		,{
			name: 'Transit Delay',
			color:'rgba(239, 18, 18, 0.8)',
			data: this.state.transitbubble
		},  {
			name: 'Night Driving',
			color:'rgba(57, 113, 235, 0.8)',
			data: this.state.nightdrivebubble
		}, {
			name: 'GPS Data Available',
			color:'rgba(66, 151, 250, 0.8)',
			data: this.state.gpsnobubble
		}
	]
	*/
	series: [{
        type: 'column',
		name: '',
		//colors:["#FF0000", "#F0BA32", "#FA3423", "#f00ffa"],
        data: [this.state.transitbubble,this.state.overspeedbubble,this.state.nightdrivebubble,this.state.gpsnobubble]
    }, {
        type: 'spline',
        name: '',
        data: [this.state.transitbubble,this.state.overspeedbubble,this.state.nightdrivebubble,this.state.gpsnobubble],
		marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[3],
            fillColor: 'white'
        }
    }, {
        type: 'pie',
        name: '',
        data: [{
            name: 'Transit Delay',
            y: parseFloat(this.state.transitbubble)
        }, {
            name: 'Overspeeding',
            y: parseFloat(this.state.overspeedbubble)
        }, {
            name: 'Night Driving',
            y: parseFloat(this.state.nightdrivebubble)
        }, {
            name: 'GPS Data Available',
            y: parseFloat(this.state.gpsnobubble)
        }],
        center: [100, 80],
        size: 100,
        showInLegend: false,
        dataLabels: {
            enabled: false
        }
    }]
	};
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
            	<div className="row">
            		<div className="row col-xl-12 col-lg-12">
						<div className="col-xl-6 col-lg-6">
							<Counters counters={this.state.counters} />
						</div>
						<div className="col-xl-6 col-lg-6">
							<HighchartsReact containerProps={{ style: { width: '100%', height: '93%' } }} highcharts={Highcharts} options={highchartgpsinterval} />
						</div>
					</div>
					<div className="col-xl-12 col-lg-12 mt-10p">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>All Consignments </span>
									<span className="float-right" style={{fontSize:"13px",fontWeight:"100"}}>
									<i className="icofont icofont-clock-time" style={{color:"#ff0000", fontSize:"22px	"}}></i> - Transit Delay
									&nbsp;<i className="icofont icofont-social-google-map" style={{color:"rgb(239, 18, 18)", fontSize:"22px	"}}></i> - GPS Not Available
									&nbsp;<i className="fa fa-tachometer" style={{color:"rgb(239, 18, 18)", fontSize:"22px	"}}></i> - Overspeed
										&nbsp;<i className="icofont icofont-full-night" style={{color:"rgb(34, 117, 157)", fontSize:"22px	"}}></i> - Night Driving
										{/*&nbsp;<i className="icofont icofont-pause" style={{color:"rgb(174, 70, 239)", fontSize:"22px	"}}></i> - Enroute Stoppages*/}
										

									</span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
							{/*		
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									columnyaxis={this.state.rowData} 
									columnaxis={columnwithDefs} 
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }
									
									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/} 
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 {this.state.sliderTranslate != ''?
					<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12"> 
							<VerticalTimeline>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%"}}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									date="Basic Information"
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Truck No</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no }</h4>
									<h3 className="vertical-timeline-element-title">Transporter Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code }</h4>
									<h3 className="vertical-timeline-element-title">Department Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code }</h4>
									<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode }</h4>
									<h3 className="vertical-timeline-element-title">Items</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items }</h4>
									<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km }</h4>
									
								</VerticalTimelineElement>


								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Gate in time"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
									
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence }</h4>
									
									<h3 className="vertical-timeline-element-title">Invoice time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time }</h4>
									
								</VerticalTimelineElement>

								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="MSIL Gate out time"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time }</h4>
									
									<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence }</h4>
									
								</VerticalTimelineElement>
									
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Consignee Code"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
								
								>
									<h3 className="vertical-timeline-element-title">Consignee Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code }</h4>
									
								</VerticalTimelineElement>
								
								</VerticalTimeline>

							</div>
						</div>
					 </div>
				:""	
				}
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap mapFor={"consignment"} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	console.log("onSetCritical ", params);
	console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
