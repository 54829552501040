var axios = require('axios');

var axiosInstance = axios.create({
  // baseURL: 'http://localhost:5001/foxtrot',
  baseURL:'https://am-autometrics.in/foxtrot_new',
  // baseURL:'https://am-autometrics.in/foxtrot',
  /* other custom settings */
});

module.exports = axiosInstance;
