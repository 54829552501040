import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
var criticaldata=[]
export default class RailFinancialDelayCompenseChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
		
	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown
			})
            //console.log("newprops ",newprops);
		//}

		
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }
    
    render(){
        // console.log("categories ",this.props.categories);
        // console.log("newprops ",this.props.series);
        const dStyles={
				width:'100%',
				height:'100%'
        }
        var drilldowncols = this.props.drilldowncols;
        var drilldownlines = this.props.drilldownlines;
        // console.log("drilldowncols ", JSON.stringify(drilldowncols))
        // console.log("drilldownlines ", JSON.stringify(drilldownlines))
        highchartoptions = {
            chart: {
                zoomType: 'xy',
                type:"column",
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            var chart = this,
                                drilldowns = drilldowncols,
                                drilldownsLines = drilldownlines,
                                series = drilldowns[e.point.name],
                                seriesLine = drilldownsLines[e.point.name];
    
                                //console.log("EE ",chart.xAxis[0].ticks)
                            try{
                                //chart.zoomType("y");
                                chart.addSingleSeriesAsDrilldown(e.point, series);
                                chart.addSeriesAsDrilldown(e.point, seriesLine);
                                chart.applyDrilldown();
    
                            }
                            catch(e){
                               // console.log("EEE ", e)
                                chart.applyDrilldown();
                            }
                        }
    
                    }
                }
            },
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            // xAxis: [{
            //     categories:this.props.categories,
            //     crosshair: true
			// }],
			xAxis: {
				type: 'category',
				// categories:this.props.categories,
                crosshair: true
			},
            yAxis: [
                { // Secondary yAxis
                    title: {
                        text: 'Rs/Car',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                },{ // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Compensation (Rs)',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }],
			plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
					},
				},
				line:{
					dataLabels:{
                        enabled:true,
                        format:'{point.y}'
					}
				}
			},
            tooltip: {
                shared: true
            },
            // legend: {
            //     layout: 'vertical',
            //     align: 'left',
            //     x: 120,
            //     verticalAlign: 'top',
            //     y: 100,
            //     floating: true,
            //     backgroundColor:
            //         Highcharts.defaultOptions.legend.backgroundColor || // theme
            //         'rgba(255,255,255,0.25)'
			// },
			 colors:[ '#f35e82','#7f84e9','#FF5370', '#f15c80', '#92A8CD','#A47D7C','#f3d800',  '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
		
            series: this.props.series,
            drilldown: {
                chartOptions: {
                    
                    yAxis: [
                        { // Primary yAxis
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Compensation (Rs)',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        }
                    },{ // Secondary yAxis
                        title: {
                            text: 'Rs/Car',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            format: '{value} ',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        opposite: true
                    }],
                },
                
                series: [],
                // seriesLine: this.props.drilldownlines
            },
			credits:false
        };
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

$(document).ready(function(){
	
})
