import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Check from './checkboxComponent';
import  CheckBox  from './checkbox'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class AdminMenuReOrder extends Component {
    state = {
        pageTitle: "Menu Order Positions",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        menu_title:'',
        loadshow:'show-n',
        overly:'show-n',
        updateForm:'show-n',
        addForm:'show-m',
        allmenus:[],
        role_name:"",
        alert:null,
        show: false,
        basicTitle:'',
        basicType:"default",
        sliderTranslate:'',
        allmenus:[],
        allmenustree:[],
        submenuitems:[],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        modules: AllModules,
        rowData: [],
        headerHeight: 40,
        groupHeaderHeight:60,
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        rowSelection: "multiple",
        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,
        allroles:[], 
        childconfs: [],
        childrow:[],
        rowModelType: "serverSide",
        paginationPageSize:100,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        // isRowSelectable: function(node) {
        //     this.onRowSelecteable(node);
        // },
        frameworkComponents: {
            customLoadingCellRenderer: CustomLoadingCellRenderer,
            check:Check
        },
        autoGroupColumnDef: {
            headerName: "Menu Level",
            field: "level1",
            width: 300,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: { checkbox: true }
          },
        detailCellRendererParams:{},
        statusBar: {
            statusPanels: [
                {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left"
                },
                {
                statusPanel: "agTotalRowCountComponent",
                align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
                },
                {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        checked: [],
        expanded: [],
        role_name:'',
        responsemessage:''
    }
    constructor(props) {
        super(props);
        
        this.onRowSelecteable= this.onRowSelecteable.bind(this)
        this.getRoleMenus = this.getRoleMenus.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    logEvent = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                let label = googleAnalytics.page.action.formSubmittedUpdateMenuOrder;
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventFormAction,
                    "label": label,
                }
                googleAnalytics.logEvent(eventOptions);

            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView(); // Log page view to GA
        redirectURL.post("/superadmin/roles")
        .then( async (response) => {
        
            this.setState({
                allroles:response.data,
                //loadshow:'show-n'
            });
         
        })
        .catch(function(e){
            console.log(e)
        })

        this.setState({
            loadshow:'show-m'
        });
        redirectURL.post("/superadmin/menus")
        .then( async (response) => {
            // console.log("Menus ",JSON.parse(response.data.body));
            // var menusarr = [];
            // var menus = JSON.parse(response.data.body);
            // if(menus.length > 0)
            // {
            //     menus.map((item) => {
            //         var chid=[]
            //         if(item.children.length > 0)
            //         {
                        
            //             item.children.map((mn) => {
            //                 if(mn.children.length > 0)
            //                 {
            //                     var sublevelchild = []
            //                     mn.children.map((sublevel) => {
            //                         sublevelchild.push({"value":sublevel.code+"-"+sublevel.parent_code, "label":sublevel.name})
            //                     })
            //                 }
            //                 chid.push({"value":mn.code+"-"+mn.parent_code, "label":mn.name, "children":sublevelchild})
            //             })
            //         }
                    
            //         menusarr.push({"value":item.code, "label":item.name, "children":chid})
            //     })
            // }
                this.setState({
                   // allmenustree:menusarr,
                  //  allmenus:menus,
                    rowData:response.data,
                    loadshow:'show-n'
                });
         
        })
        .catch(function(e){
            console.log(e)
        })
        
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    onShowRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-m',
            updateForm:'show-n'

        });
    }
    onShowUpdateRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-n',
            updateForm:'show-m'

        });
    }
    formHandler = (event) =>{
        event.preventDefault();
        this.logEvent(); // Log event view to GA
        var selecteditems = this.gridApi.getSelectedNodes();
        var allselectitems = [];
        if(selecteditems.length > 0)
        {
            selecteditems.forEach((item) => {
                allselectitems.push({
                    "code":item.data.code,
                    "display_order":item.data.display_order
                })
            })
        }
        //console.log("selecteditems ", selecteditems);

        var formdata = {
            menus:allselectitems
        }
        //console.log("formdata ", formdata)

        redirectURL.post("/superadmin/updateMenuRole", formdata)
        .then((response) => {
             if(response.data.message == 'success')
            {
                this.setState({
                    role_name:'',
                    responsemessage:"Successfully assigne menus to "+this.state.role_name.value+" role"
                });
                window.location.reload();
                //this.gridApi.deselectAll();
            }
            
            this.setState({
                loadshow:'show-n',
            });
        })
		
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
	handlerForm = async role_name => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ role_name },
			() => console.log(`Option selected:`, this.state.role_name)
          );
        await this.getRoleMenus()
    }
    onCheck(checked) {
        this.setState({ checked });
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }

    getRoleMenus = async () => {
        //console.log("role ", this.state.role_name.value)
        var rolesmenus = [];
          var reqdata = {
              role_name:this.state.role_name.value
            }
          await redirectURL.post("/superadmin/defaultrolemenus",reqdata)
          .then( async (response) => {
              if(response.data.length > 0)
              {
                rolesmenus = response.data[0].menus;
                //console.log("resp ", rolesmenus)
                this.setState({
                    checked:rolesmenus,
                    expanded:rolesmenus
                });
                this.onCheck(rolesmenus)
              }
              //console.log(this.gridApi.rowModel.rootNode.allLeafChildren);
              this.gridApi.deselectAll();
              var rownodesarr = this.gridApi.rowModel.rootNode.allLeafChildren;
              rownodesarr.forEach(node => {
                //console.log("Node ", node.data.code)
                    if(rolesmenus.length > 0)
                    {
                        rolesmenus.forEach(item=>{
                            //console.log("(node.data.code ", item)
                            if(node.data.code)
                            {
                                if(node.data.code == item)
                                {
                                    node.setSelected(true);
                                }
                            }
                        })
                    }
              })
              //console.log("rownodesarr ", rownodesarr)
          })
          .catch(function(e){
              console.log(e);
          })
          
    }
    onClickMenu(event){
        console.log("event ", event.target.value)
    }

	selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.allroles.forEach(item => {
            var rname = item.role_name;
            var role = rname.toUpperCase();
            items.push({"value":item.role_name,"label":role});	
        });
        return items;
    }
    handleCheckChieldElement = (event) => {
        let allmenus = this.state.allmenus
        allmenus.forEach(menu => {
            if (menu.code === event.target.value)
            {
                menu.isChecked =  event.target.checked
                if(menu.isChecked == true)
                {
                    console.log(menu.code)
                }
            }
        })
        this.setState({allmenus: allmenus})
      }
    
    handleCheckSubMenuElement = (event) => {
        let submenuitems = this.state.submenuitems
        submenuitems.forEach(submenu => {
           if (submenu.code === event.target.value)
           submenu.isChecked =  event.target.checked
        })
        this.setState({submenuitems: submenuitems})
    }
    onRowSelecteable = (params) =>{
        console.log("params ",params)
        var formdata = {
            parentmenu:params.data.code
        }
      
            this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{
                                headerName: "Menu Title",
                                field: "name",
                                width: 250,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellRenderer: "agGroupCellRenderer",
                                cellRendererParams: { checkbox: true }
                                
                            },
													
						],
						detailCellRendererParams: {
                            detailGridOptions: {
                              columnDefs: [
                                {
                                    headerName: "Menu Title",
                                    field: "name",
                                    width: 250,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    cellRenderer: "agGroupCellRenderer",
                                    cellRendererParams: { checkbox: true }
                                    
                                },
                                    
                              ],
                            },
                            getDetailRowData: function(params) {
                                var formdata = {
                                    parentmenu:params.data.code
                                }
                                console.log("formdata ", params)
                        
                                redirectURL.post("/superadmin/submenus", formdata)
                                .then((response) => {
                                    console.log("Chlde Submenu response ", response)
                                    params.successCallback(response.data);
                                });
                            }
                          },			
					},
					getDetailRowData: function(params) {
						var formdata = {
                            parentmenu:params.data.code
                        }
                        //console.log("formdata ", formdata)
                
                        redirectURL.post("/superadmin/submenus", formdata)
                        .then((response) => {
                            console.log("Submenu response ", response)
                            params.successCallback(response.data);
                        });
						
					}
					
				}
			});
			
			//if(params.column.colId == 'unloading_delay_exception')
			if(params.column.colDef.field == 'name')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
            }
            

            this.setState({
                loadshow:'show-n',
            });
       
    }
    onSelectedCheckedItem(){
        console.log(this.state.checked)
    }

    
    render(){
        const { checked, expanded } = this.state;
        const columnDefs = [
            
            {
                headerName: "Menu Level",
                field: "level1",
                width: 300,
                editable:false,
                rowGroup: true,
                hide: true,
                
            },
            {
                headerName: "Menu Level2",
                field: "level2",
                width: 250,
                editable:false,
                rowGroup: true,
                hide: true,
                
            },
            {
                headerName: "Menu Title",
                field: "name",
                width: 250,
                editable:false,
                filter: "agSetColumnFilter",
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: {
                    checkbox: function(params) {
                        return params.node.group === true;
                    }
                }
                
            },
            {
                headerName: "Menu Order",
                field: "display_order",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
                
            }
        ]
       var allparentmenus = [];
       this.state.allmenus.map((menu) => {
            if(menu.parent_code == null)
            {
                var pcode = menu.code;
                allparentmenus.push({"code":pcode, "name":menu.name}) 
            }
                
        });
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span> {this.state.pageTitle} </span>
                                    <a href="/superadmin/roles" className="btn btn-default ml-5p float-right">Back</a>
                                </h5>
				   			</div>
				   			<div className="card-body row">
                               
                                <div className=" col-xl-12 col-lg-12">
                                    {this.state.responsemessage != ''?
                                    <div className="alert alert-success">
                                        {this.state.responsemessage}
                                    </div>
                                    :""}
                                    <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                                        
                                        <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
		          		                    <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                rowSelection={this.state.rowSelection}
                                                groupSelectsChildren={true}
                                                suppressRowClickSelection={true}
                                                suppressAggFuncInHeader={true}
                                                onGridReady={this.onGridReady}
                                                rowData={this.state.rowData}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-success">Update Order</button>
                                    </form>

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                
                                {/*
                                        <ul>
                                            {this.state.allmenus.map((r) => (
                                                (r.children.length > 0)?
                                                <li>
                                                    <div>
                                                        <input type="checkbox" />{r.name}
                                                    </div>
                                                    <uL style={{marginLeft:"10px"}}>
                                                     {r.children.map((m) => (
                                                       
                                                        <li>
                                                            <input type="checkbox" />{m.name}
                                                        </li>
                                                       
                                                    ))
                                                    }
                                                        </uL>
                                                    </li>
                                                :""    
                                                ))
                                            }
                                                
                                            {
                                                
                                                this.state.allmenus.map((menu) => {
                                                   
                                                        if(menu.children.length > 0)
                                                        {
                                                             menu.children.map((smenu) => {
                                                                return (
                                                                    <CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...smenu} />
                                                                )
                                                            })
                                                        }
                                                        else{
                                                            return (
                                                                <CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...menu} />
                                                            )
                                                        }
                                                        
                                                })
                                                
                                            }
                                        </ul>
                                    </div>
                                    <div className=" col-xl-8 col-lg-8">
                                        <ul>
                                            {
                                                (this.state.submenuitems.length > 0)?
                                                    this.state.submenuitems.map((submenu) => {
                                                        return (<CheckBox handleCheckChieldElement={this.handleCheckSubMenuElement}  {...submenu} />)
                                                    })
                                                :""
                                            }
                                        </ul>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
				 
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}