//Sim Tracking Info Via Projects
import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';


export default class SimTrackingDashboardCharts extends Component{
    constructor(props){
        super(props)
        this.state = {
            applicationCount : props.applicationCount,
        }
    }
    
    chartsSeriesAndDrillDownData = (applicationCount) => {
        const unique_projects = [...new Set(applicationCount.map(item => item.source_from))]

        const unique_projects_count = {}
        unique_projects.forEach(name => {
            unique_projects_count[name] = applicationCount
            .filter(item => item.source_from === name)
            .reduce((total,item) => total+item.count,0)
        })


        const total = Object.values(unique_projects_count).reduce((acc, value) => acc + value, 0);

        const percentages = {};
        for (const key in unique_projects_count) {
        if (unique_projects_count.hasOwnProperty(key)) {
            const value = unique_projects_count[key];
            const percentage = (value / total) * 100;
            percentages[key] = percentage;
        }
        }

        const data_colors = [
            "#3498db", 
            "#e74c3c", 
            "#9b59b6", // Purple
            "#f1c40f", // Yellow
            "#e67e22", // Orange
            "#1abc9c", // Teal
            "#d35400", // Pumpkin
            "#34495e", // Midnight Blue
            "#95a5a6", // Gray
          ];
        
        const series_data_array = unique_projects.map((name,index) => ({
            name: name,
            y: percentages[name],
            drilldown: name,
            color:data_colors[index]
        }))

        console.log(unique_projects_count,unique_projects,series_data_array,"336699")

        const apiTypeData = {};

        for (const item of applicationCount) {
        const { source_from, api_type } = item;

        if (!apiTypeData[source_from]) {
            apiTypeData[source_from] = {};
        }

        if (!apiTypeData[source_from][api_type]) {
            apiTypeData[source_from][api_type] = 0;
        }

        apiTypeData[source_from][api_type]++;
        }


        const totalApiTypesWRTSourceFrom = {};

        for (const name in apiTypeData) {
        totalApiTypesWRTSourceFrom[name] = Object.values(apiTypeData[name]).reduce((acc, count) => acc + count, 0);
        }


        const result = [];

        for (const name in apiTypeData) {
        result[name] = {};

        for (const type in apiTypeData[name]) {
            const percentage = (apiTypeData[name][type] / totalApiTypesWRTSourceFrom[name]) * 100;
            result[name][type] = percentage;
        }
        }

        console.log(result,"result");
        const resultArray = Object.keys(result).map((name) => ({
            name,
            id: name,
            data: Object.entries(result[name]),
        }));

        const chartOptions = {
            chart: {
                type: 'pie'
            },
            title: {
                text: 'Customer Hits',
                style:{
                    color: '#3498db', 
                    fontWeight: 'bold',
                    fontSize: '20px',
                    marginBottom: 15
                },
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'center'
            },

            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                series: {
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.2f}%'
                    },
                    // showInLegend:true

                },
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            },

            series: [
                {
                    name: 'Customers',
                    colorByPoint: true,
                    data: series_data_array
                }
            ],
            drilldown: {
                series: resultArray,
                drillUpButton: {
                    position: {
                      align: 'right', 
                      verticalAlign: 'top', 
                      x: -10, 
                      y: -20 
                    },
                    theme: {
                      
                      fill: 'white', 
                      stroke: 'silver',
                      'stroke-width': 1, 
                      r: 0, 
                      style: {
                        color: 'black', 
                        fontWeight: 'bold' 
                      },
                      states: {
                        hover: {
                          fill: 'gray'
                        }
                      }
                    }
                }
            }
            }
            
        return chartOptions
    
    }

    componentDidUpdate(){
        const {applicationCount} = this.props
        const resultantChartOptions = this.chartsSeriesAndDrillDownData(applicationCount)
        Highcharts.chart('container', resultantChartOptions);
    }

    componentDidMount(){
        const {applicationCount} = this.props
        const resultantChartOptions = this.chartsSeriesAndDrillDownData(applicationCount)
        Highcharts.chart('container', resultantChartOptions);
    }

    render() { 
    return (
        <div id="container" />
    )
  }
}
