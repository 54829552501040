
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const NoGpsLifetime = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowNoGPSKpi(props);
    };
    
   // if(props.data.msil_transit_delay == 1)
    //{
   //     var colr = 'redfont'
   // }
   // else{
       
            var colr = 'txt-secondary'
      
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="GPS Data Not Adequate" ><i className={"icofont icofont-social-google-map  "+(colr)}></i> &nbsp;</button>
            
        </div>
    );
};

export default NoGpsLifetime;
