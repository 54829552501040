import React, { Component } from 'react';
import { Redirect } from 'react-router';

// Import custom components
import ErrorHandler from './common/errorHandler';
import Header from './common/header/header.component';
import Sidebar from './common/sidebar/sidebar.component';
//import Footer from './common/footer/footer.component';
//import './api-config';
import '../assets/css/encss.css';
import axios from 'axios';

import { Provider } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { Router, browserHistory } from 'react-router';
import { sessionService, sessionReducer } from 'redux-react-session';
import { useBeforeunload } from 'react-beforeunload';
import Modal from 'react-responsive-modal';
import Select from 'react-select';


import $ from 'jquery';
var redirectURL = require('./redirectURL');


var moment = require('moment');
var googleAnalytics = require("./common/googleanalytics");
const reducer = combineReducers({
    session: sessionReducer
  });
  
  const store = createStore(reducer);
  
  // Init the session service
  sessionService.initSessionService(store, { driver: 'COOKIES' });

var rurl='';


class App extends Component {
    constructor(props){
        super(props);
        this.showside = this.showside.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            sidebar:true,
            navMenus:true,
            ssinner:'show-n',
            sideimg:require('../assets/icons/sidenav-blue.png'),
            imgbg:'whgb',
            l20n:'',
            showOutIcon:'show-m',
            sclose:'show-m',
            logginStatus: true,
            userroles:[],
            pageStyle:{},
            userip:'',
            useripdata:"",
            check:false,
            open:true,
            select_type:[{label:"Customer",value:"CUSTOMER"},{label:"Operations",value:"AMNS_OPS_SC"}],
            selectType:"",
            selectdiv:"#3b7be3",
            selectdivops:"#3b7be3",
            inactiveTime: 0,
        }
        this.timer = null;
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
          ];
      
        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.autologout = this.autologout.bind(this);
        this.userLastActivity = this.userLastActivity.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.checkLoginTime = this.checkLoginTime.bind(this);
		this.decipher = this.decipher.bind(this);
        //this.clearStorage = this.clearStorage.bind(this);
        this.getData = this.getData.bind(this);

        var userroles = localStorage.getItem("roles");
        this.setState({
            userroles:userroles
        })
        //console.log("userroles", userroles);
        try{
            if (userroles.indexOf("led") == -1)
            {
        
                for (var i in this.events) {
                    window.addEventListener(this.events[i], this.resetTimeout);
                }
                this.setTimeout();
            }
        }
        catch(e){
            console.error(e);
        }
        //console.log(this.state.sidebar);
    }

    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
    
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      }
    
      setTimeout() {
       // this.setupBeforeUnloadListener();
       var usertype = localStorage.getItem("user_type");

        this.warnTimeout = setTimeout(this.warn, 1 * 1000);
        if(usertype == 'DCTUSER')
        {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 120 * 1000);
        }
        else
        {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 120 * 1000);
        }
        
      }
    
      resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
      }
    
      warn() {
        //alert("You will be logged out automatically in 1 minute.");
      }
    
      logout() {
          console.log("loginggggOuttt")
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"manual"

        }
        redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)
        })

        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
    	localStorage.removeItem('userid');
    	localStorage.removeItem('dept_code');
    	localStorage.removeItem('firstname');
    	localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
    	localStorage.removeItem('loadingPointName');
        localStorage.clear();

        sessionStorage.setItem('ref',null);
    	//localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer:''
        });
        //console.log("logout successfully",localStorage.getItem('token'));
        
        //localStorage.setItem('token',"");        
        //localStorage.setItem('userid',"");

        //window.location.reload();
      }

      autologout() {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"auto"

        }
        redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)
        })
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
    	localStorage.removeItem('userid');
    	localStorage.removeItem('dept_code');
    	localStorage.removeItem('firstname');
    	localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
    	localStorage.removeItem('loadingPointName');
        localStorage.clear();
        
        sessionStorage.setItem('ref',null);
        
    	//localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer:''
        });
        if(is_enmovil_transporter == 1)
        {
            window.location.href="/tptlogin";
        }
        //console.log("logout successfully",localStorage.getItem('token'));
        
        //localStorage.setItem('token',"");        
        //localStorage.setItem('userid',"");

        //window.location.reload();
      }

      checkLoginTime(loggedTime){
        console.log("initial loggedTime", loggedTime);
        var now = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        //var now = moment.parseZone(new Date(nowdate)).utcOffset("+05:30")._d;
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("+05:30")._d;
        // var loggedTime = moment.parseZone(new Date(loggedTime)).format('YYYY-MM-DD HH:MM:ss')
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("-05:30")._d
        var lTime = moment.parseZone(loggedTime).format("YYYY-MM-DD HH:mm:ss");
        console.log("now", now);
        console.log("loggedTime", loggedTime);
        console.log("loggedTime", lTime);

        if(loggedTime !=undefined)
        {
            //var sessionDuration = moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(loggedTime,"DD/MM/YYYY HH:mm:ss"))).format("mm");
            //moment.parseZone(new Date(data1[0].createdon))._d
            var sessionDurationInHrs = moment.duration(moment(now).diff(lTime));
            var sessionDuration = sessionDurationInHrs.asMinutes();
            console.log("Sess Dur", parseInt(sessionDuration));
            //console.log("Token", localStorage.getItem('token'));
            if(parseInt(sessionDuration) >= parseInt(30))
            {
                console.log("Sess Dur1", sessionDuration);
                this.autologout();
            }
            else
            {
                this.userLastActivity();
            }
        }
        else
        {
            this.userLastActivity();            
        }
      }

      userLastActivity()
      {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid')
        }
        var comp_code = localStorage.getItem('comp_code');
        if(comp_code !=undefined && comp_code !='')
        {
            userloggparams.comp_code = comp_code;
        }
        redirectURL.post("/manage/updateUserLastActivity",userloggparams)
        .then((resp) => {
            console.log("userActivity ", resp)
        })
      }

    // // Setup the `beforeunload` event listener
    // setupBeforeUnloadListener = () => {
    //     window.onbeforeunload((ev) => {
    //         ev.preventDefault();
    //        return this.logout();
    //     });

        
    // };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    // setupBeforeUnloadListener = () => {
    //     useBeforeunload(() => "Are you sure to close this tab?");
    //     window.addEventListener("beforeunload", (ev) => {
    //         ev.preventDefault();
    //         console.log("Bhanu");
    //         //this.autologout();
    //     });
    // };
    
    // useBeforeunload = () => {
    //         console.log("Are you sure to close this tab?");
    //         this.autologout();
    // };

   
   async componentDidMount() {
    if(![undefined,null,"","undefined" , "shivaram.a@enmovil.in" , "rahul@enmovil.in"].includes(localStorage.getItem("email"))){
        await redirectURL.post("manage/getUserDetails" , {email : localStorage.getItem("email")}).then(response => {
            if(response.data.length > 0){
                if(localStorage.getItem("token") == response.data[0].token){
              
                }else{
                    this.autologout()
                }
            }
            
        })
    }
    if (![undefined,null,"","undefined" , "shivaram.a@enmovil.in" , "rahul@enmovil.in"].includes(localStorage.getItem("email"))) {
        this.resetTimerForInactiveScreen();
        window.addEventListener('mousemove', this.handleMouseMove);
    }
        var is_multiple_roles = localStorage.getItem("is_multiple_roles");
        if(is_multiple_roles == 1 || is_multiple_roles == "1")
        {
            var roles = localStorage.getItem("roles");
            if(roles !=undefined && roles !="")
            {
                if(roles.includes(",") == true)
                {
                    this.getpopup()
                }
            }
        }
        //this.clearStorage() 
       // 
        // Activate the event listener
        //this.setupBeforeUnloadListener();
        //useBeforeunload(() => "Are you sure to close this tab?");
        //window.addEventListener('beforeunload', this.keepOnPage);
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        
        if(localStorage.getItem('token') == '' && localStorage.getItem('token') == null)
        {

        }
        else
        {   
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId:localStorage.getItem('userid')
            }
            //console.log("userloggparams ", userloggparams)
            

            redirectURL.post("/manage/loggeduser",userloggparams)
            .then((resp) => {
                //console.log("resp ", resp)
                var recc = resp.data;
                if(recc.length > 0)
                {
                    if(recc[0].is_logged == 0)
                    {
                       this.autologout();
                    }
                    else{

                        this.checkLoginTime(recc[0].last_activity);
                    }
                }
                else
                {
                   // this.logout();
                }
            })

            
        }
        //console.log("usermenus ",localStorage.getItem("usermenus"))
        var allmenus = []
        var dynmicemus = []
        if(localStorage.getItem("usermenus") != '' && typeof localStorage.getItem("usermenus") != "undefined")
        {
            var menulist = JSON.parse(localStorage.getItem("usermenus"));
             console.log("menulist ", menulist)
            try{
                menulist.map((menu) => {
                    //console.log("Menu ", menu)
                    if(menu.sublinks != '' && menu.sublinks != undefined)
                    {
                        
                        menu.sublinks.map((item) => {
                           
                                allmenus.push(item.value)
                            
                        })
            
                    }
                    if(menu.path == '' || menu.path == null)
                    {
                        var level1 = menu.children;
                        //console.log("level1 ", level1)
                        
                        if(level1.length > 0)
                        {
                            level1.map((mn) => {
                                
                            if(mn.sublinks != '' && mn.sublinks != undefined)
                            {
                                mn.sublinks.map((item) => {
                                   
                                        allmenus.push(item.value)
                                    
                                })
    
                                
                            }
                                
                                
                                if(mn.path != '' && mn.path != null)
                                {
                                    allmenus.push(mn.path)
                                }
                                else
                                {
                                    var level2 = mn.children
                                    
                                    if(level2.length > 0)
                                    {
                                        level2.map((mnu) => {
                                            if(mnu.path != '' && mnu.path != null)
                                            {
                                                allmenus.push(mnu.path)
                                            }
                                            
                                            if(mnu.sublinks != '' && mnu.sublinks != undefined)
                                            {
                                                mnu.sublinks.map((item) => {
                                                   
                                                        allmenus.push(item.value)
                                                    
                                                })
                                            }
                                            
                                        })
                                    }
                                    
                                }
                                
                            })
                        }
                        
                    }
                    else{
                        allmenus.push(menu.path)
                        
                        if(menu.sublinks != '' && menu.sublinks != undefined)
                        {
                            menu.sublinks.map((item) => {
                               
                                    allmenus.push(item.value)
                                
                            })
                        }
                        
                    }
                })
            }
            catch(e){
                
            }
        }
        //console.log("window.location ", window.location)
        var pageurl = window.location.pathname
        allmenus.push("/")
        allmenus.push("/profile/changepassword")
        allmenus.push("/error-404")
        allmenus.push("/error-405")
        allmenus.push("/logout")
        allmenus.push("/returnTripsDashboard")
        allmenus.push("/get_cyclone_report");
        allmenus.push("/analytics");
        // allmenus.push("/railvinconsignments");
        // allmenus.push("/sndrailvintransshipments");
        // allmenus.push("/prtrailvintransshipments");
        // allmenus.push("/tnprailvintransshipments");
        // allmenus.push("/vinroadpickup");
        // allmenus.push("/sndmanuallocations");
        // allmenus.push("/sndaddlocation");
        // allmenus.push("/editlocation");
        allmenus.push("/sndlocalconsignments");
        allmenus.push("/prtlocalconsignments");
        allmenus.push("/tnplocalconsignments");
        allmenus.push("/raildefault");
        //allmenus.push('/railloadattachment');
        //allmenus.push('/railexitloadattachment');
        //allmenus.push('/actlcontainers');
        //allmenus.push('/userlogreport');
        //allmenus.push('/assignactlcontainers');
        allmenus.push('/livemaptrucklocation');
        allmenus.push('/livemaptruck');
        allmenus.push("/editweatherevent");
        allmenus.push("/newroutedeviation");
        allmenus.push("/batchwisecatalysis");
        allmenus.push("/livetrucksinfo");
        // allmenus.push("/sndbilling");
        // allmenus.push("/s");
        // allmenus.push("/tnpbilling");
        // allmenus.push("/scimportsbilling");
        // allmenus.push("/scexportsbilling");
        // allmenus.push("/sccoilsbilling");
        // allmenus.push("/sndprimarybilling");
        // allmenus.push("/sndsecondarybilling");
        //allmenus.push("/railanalytics");
        //allmenus.push("/updaterailanalytics");
        allmenus.push("/deviationroutes");
        allmenus.push("/optimizeroutes");
        allmenus.push("/goldenzonedashboard");
        allmenus.push("/returntrips");
        allmenus.push("/deviationroutesnew");
        allmenus.push("/usdrates");
        allmenus.push("/icdrates");
        allmenus.push("/automotivegeofences");
        allmenus.push("/addautomotivegeofence");
        allmenus.push("/editautomotive");
        allmenus.push("/railinventory");
        allmenus.push("/oembreaks");
        // allmenus.push("/prtdeviationroutes");
        allmenus.push("/markdeliveredrailconsignments");
        // allmenus.push("/sndrouteplanning");
        // allmenus.push("/prtrouteplanning");
        // allmenus.push("/tnpoptimizeroutes");
        // allmenus.push("/tnpdeviationroutes");
        // allmenus.push("/tnprouteplanning");
        // allmenus.push("/prtreturnTripsDashboard");
        allmenus.push("/railplanning");
        allmenus.push("/tnpreturnrtripclusters");
        // allmenus.push("/sndTransitAnalyticsDashbord");
        // allmenus.push("/exportstracking");
        allmenus.push("/analyticsdashboard");
        allmenus.push("/orgrailconsignments");
      
        allmenus.push("/railloadoptmization");
        allmenus.push("/getOptimizedLoadReport")
        allmenus.push("/sndemptytruckvicinity");

        allmenus.push("/orders");
        allmenus.push("/podshipments");

        var rurl = [];
        //console.log("allmenus ",allmenus)
        //console.log("path is ",pageurl)
        var otherdepts = ["AD","SA"];
       // console.log("appjsx deptcode ",localStorage.getItem("dept_code"));
        if(allmenus.length > 0)
        {
            try{
                //console.log(localStorage.getItem("dept_code").indexOf(""))
                if(localStorage.getItem("dept_code").indexOf("AD") >= 0 || localStorage.getItem("dept_code").indexOf("SA") >= 0
                )
                {

                }
                else
                {
                   // if(localStorage.getItem("is_load_management") != 1  && localStorage.getItem("roles").includes("air_cargo") != true && localStorage.getItem("roles").includes("air_cargo_form")  != true && localStorage.getItem("roles").includes("ff_air_cargo")  != true && localStorage.getItem("roles").includes("cha_air_cargo")  != true && localStorage.getItem("roles").includes("DCT")  != true)
                    if(localStorage.getItem("is_load_management") != 1 && localStorage.getItem("roles").includes("DCT")  != true)
                     {
                        if(allmenus.indexOf(pageurl) >= 0)
                        {
                            console.log("Valid url")
                        }
                        else{
                           var pgrl =  pageurl.split("/")
                          // console.log("Splitpgrl ",pgrl)
                           if(pgrl.length > 0)
                           {
                               var loadurl=''
                               if(pgrl.length > 1)
                               {
                                 loadurl = "/"+pgrl[1];
                               }  
                               if(allmenus.indexOf(loadurl) >= 0 )
                               {
                                //   console.log("yes")
                               }
                               else
                               {
                                 //  console.log("no");
                                 window.location.href="/"
                               }                    
                           }
                           
                            //window.location.href="/"
                        }
                    }
                    else
                    {
                        // allmenus.push("/lmwelcome");
                        // allmenus.push("/viewLoadSummaryDetails");
                        // if(allmenus.indexOf(pageurl) >= 0)
                        // {
                        //     console.log("Valid url")
                        // }
                        // else{
                        //     window.location.href="/lmwelcome"
                        // }
                    }
                }
            }
             catch(e){

             }
            //console.log(allmenus.indexOf(pageurl))
             var spliturl = pageurl.split("/")
            
                allmenus.map((a) => {
                    //console.log("a ", a)
                    //console.log("pageurl length ", pageurl.length)
                    if(a.length > 1)
                    {
                        //console.log("pageurl ", pageurl)
                        if(pageurl.indexOf(a) >= 0)
                        {
                            //console.log("Yes")
                            rurl.push(0)
                        }
                        else{
                            //console.log("No")
                            //rurl.push(1)
                            //window.location.href="/error-404"
                            
                        }
                    }
                    
                    
                })
                
            //}
            //console.log("rurl ", rurl)
            if(rurl.length > 0)
            {
                if(rurl.indexOf(0) >= 0)
                {
                    
                }
                else{
                    // window.location.href="/error-404"
                }
            }
            else{
                if(pageurl == "/")
                {

                }
                else
                {
                    // window.location.href="/error-404"
                }
                
            }
            
        }
        if(pageurl == "/loadplandashboard")
        {
            var wstyles={
                "padding": '9px 0 0 0',
            }
            this.setState({
                pageStyle : wstyles
            })
        }
        if(pageurl == "/shareofbusiness")
        {
            var wstyles={
                "padding": '54px 0 0 22px',
            }
            this.setState({
                pageStyle : wstyles
            })
        }
        if(localStorage.getItem("email") != "rahul@enmovil.in" && localStorage.getItem("email") != "nanda@enmovil.in")
        {
            this.getData();
        }
        
        
        
    }
    resetTimerForInactiveScreen = () => {
        clearTimeout(this.timer);
        const lastActiveTime = localStorage.getItem('lastActiveTime');
        const currentTime = moment();
        const inactiveTime = 30 * 60 * 1000; // 30 minutes in milliseconds
        if (lastActiveTime) {
            const lastActiveMoment = moment(lastActiveTime);
            const timeDiff = currentTime.diff(lastActiveMoment);

            if (timeDiff >= inactiveTime) {
                this.setState({ inactiveTime: inactiveTime });
                this.autologout();
            } else {
                this.timer = setTimeout(() => {
                    this.resetTimerForInactiveScreen();
                }, inactiveTime - timeDiff);
            }
        } else {
            localStorage.setItem('lastActiveTime', currentTime.format()); // Store current time
            this.timer = setTimeout(() => {
                this.resetTimerForInactiveScreen();
            }, inactiveTime);
        }
    };
    handleMouseMove = (event) => {
        if([null,'',undefined,'undefined'].includes(localStorage.getItem('roles')))
		{
            localStorage.clear()
            this.autologout();
		}
        if(localStorage.length ==1){
            window.location.href = "/"
        }
        const currentTime = moment();
        localStorage.setItem('lastActiveTime', currentTime.format()); // Update last active time
        this.resetTimerForInactiveScreen();
    };

    getData = async () => {
        // await axios.get('https://geolocation-db.com/json/')
        // .then((res) => {
        //     // console.log(res.data);
        //     this.setState({
        //         userip:res.data.IPv4,
        //         useripdata:res.data
        //     })
            
        //     this.saveipadr()
        // })
        
      }
    saveipadr(){
        var pageurl = window.location.pathname;
        
        var p = {
            idaddress:this.state.userip,
            useripdata:this.state.useripdata,
            screen_viewed:pageurl,
            user:localStorage.getItem("email")
        }
        // console.log("prms ", p)
        redirectURL.post("/amns/saveuseripadrs",p)
        .then((response) => {
            // console.log("response", response.data)
        })
    }
    componentWillReceiveProps(newProps){
       
        
        if(localStorage.getItem("email") != "rahul@enmovil.in" && localStorage.getItem("email") != "nanda@enmovil.in")
        {
            this.getData();
        }
        //console.log(newProps)
        
        //this.clearStorage() 
        
        //console.log("userloggparams ", userloggparams)
        

        if(localStorage.getItem('token') == '' && localStorage.getItem('token') == null)
        {

        }
        else
        {   
            // var userloggparams = {
            //     token: localStorage.getItem('token'),
            //     userId:localStorage.getItem('userid')
            // }
            //console.log("userloggparams ", userloggparams)
            // redirectURL.post("/manage/updateUserLastActivity",userloggparams)
            // .then((resp) => {
            //     console.log("userActivity ", resp)
            // })
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId:localStorage.getItem('userid')
            }

            redirectURL.post("/manage/loggeduser",userloggparams)
            .then((resp) => {
                //console.log("resp ", resp)
                var recc = resp.data;
                if(recc.length > 0)
                {
                    if(recc[0].is_logged == 0)
                    {
                       this.autologout();
                    }
                    else{
                        console.log("Last Activity", recc[0].last_activity);
                        this.checkLoginTime(recc[0].last_activity);
                    }
                }
                else
                {
                   // this.logout();
                }
            })
        }
    }



    componentWillUnmount() {
        // window.removeEventListener('beforeunload', this.keepOnPage);
        if (window.removeEventListener) {
            window.removeEventListener('mousemove', this.handleMouseMove);
            clearTimeout(this.timer);
        }
    }
    
    // keepOnPage(e) {
    //     //this.autologout();
    //     var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    //     e.returnValue = message;
    //     return message;
    // }

    // componentDidUpdate(){
    //     var userloggparams = {
    //         token: localStorage.getItem('token'),
    //         userId:localStorage.getItem('userid')
    //     }
    //     redirectURL.post("/manage/loggeduser",userloggparams)
    //     .then((resp) => {
    //         console.log("resp ", resp)
    //         if(resp.data.length > 0)
    //         {

    //         }
    //         else
    //         {
    //             this.logout();
    //         }
    //     })

    // }
    
      destroy() {
        this.clearTimeout();
    
        for (var i in this.events) {
          window.removeEventListener(this.events[i], this.resetTimeout);
        }
      }

    showside(){
        //console.log("asdas");
        showSideBar();
    }
    openCloseSidebar = () => {
        console.log("this.state.sidebar ", this.state.sidebar)
        if(this.state.sidebar){
            this.setState({ 
                sidebar:true, 
                ssinner:'show-m', 
                //sideimg:require('../assets/icons/SIDE-NAV.png'),
                imgbg:'',
                l20n:'l20n',
                showOutIcon:'show-m',
                sclose:'show-n'
            })
            document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
            var sidebaricon = document.querySelector(".sidebaricon");
            if(sidebaricon.classList.contains('show-n'))
            {
                document.querySelector(".sidebaricon").classList.remove('show-n');
                document.querySelector(".sidebaricon").classList.add('show-m');    
            }
            else{
                document.querySelector(".sidebaricon").classList.remove('show-m');
                document.querySelector(".sidebaricon").classList.add('show-n');
            }
        }
        // else{
        //     this.setState({ 
        //         sidebar:true, 
        //         ssinner:'show-m', 
        //         //sideimg:require('../assets/icons/SIDE-NAV.png'),
        //         imgbg:'',
        //         l20n:'l20n',
        //         showOutIcon:'show-n',
        //         sclose:'show-n'
        //     })
        //     document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        //     this.state.sidebar=false;
        // }
    }

    toggle() {
      //  console.log("test", this.state.navMenus);
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    } 
    onClickCustomer=()=>{
        this.setState({selectType:"CUSTOMER",selectdiv:"red"})
    }
    onClickOperations=()=>{
        this.setState({selectType:"AMNS_OPS_SC",selectdivops:"red"})
    }

    formUpdateConsigneeDetails=(selectedone)=>{
        var selectType = selectedone
        var udetails = JSON.parse(localStorage.getItem("udetails"));
        console.log(udetails,"udetails")
        localStorage.setItem("roles",selectType)
        if(selectType == "CUSTOMER")
        {
            localStorage.setItem("user_type","CUSTOMER")
            if(udetails !=undefined && udetails !="")
            {
                localStorage.setItem("customer_code",udetails.customer_code)
            }
            localStorage.setItem("pc","")
        }
        if(selectType == "AMNS_OPS_SC" || selectType == "AMNS_OPS")
        {
            localStorage.setItem("user_type","ORGUSER")
            if(udetails !=undefined && udetails !="")
            {
                localStorage.setItem("pc",udetails.plant_code)
            }
            localStorage.setItem("customer_code","")
        }
        redirectURL.post("/amns/getmenus",{roles:selectType})
        .then(async(resp) => {
            var response = resp.data 
            console.log(response,"response")
            var usermenucodes = response.result[0].menus 
            localStorage.removeItem("usermenucodes")
            localStorage.setItem("usermenucodes",JSON.stringify(usermenucodes))   
            console.log(localStorage.getItem("usermenucodes"),"1234") 
            var usermenus = await parseMenus(response.menuDetails)
            console.log(usermenus,"usermenus1233")
             localStorage.removeItem("usermenus")
            localStorage.setItem("usermenus",JSON.stringify((usermenus)))
            
            if(selectType == "AMNS_OPS_SC"){
                var dashboardUrl = "sndtrucks";	
            }
            else if(selectType == "AMNS_OPS")
            {
                var dashboardUrl = "shipmenttrucks";		
            }
            else if(selectType == "security")
            {
            var dashboardUrl = "securitydashboard";		
            }
            else if(selectType == "vehicletrackingreport")
            {
                var dashboardUrl = "tpttruckgpsstatus";		
            }
            else if(selectType == "CUSTOMER")
            {
                var dashboardUrl = "customertrucks";		
            }
            else if(selectType == "RAIL")
            {
                var dashboardUrl = "rail-consignments/default";		
            }
            else if(selectType == "TRANSPORTER")
            {
                var dashboardUrl = "sndtranshipments";		
            }
            else{
                var dashboardUrl = "shipmenttrucks";		
            }
            console.log(dashboardUrl,"941")
            window.location.href = dashboardUrl          
        })
    this.setState({ open: false,check:false });
   
       
    }
    onClickOperation=(e)=>{
        this.formUpdateConsigneeDetails(e)
    }
    getpopup=()=>{
        this.setState({check:true})
    }
    onShowFullscreen()
    {
        fullscreen()
    }
    // clearStorage() {

    //     let session = sessionStorage.getItem('ref');
    //    // console.log("session ",session)
    //     if (session == null) {
    //         this.autologout()
    //         localStorage.removeItem('token');
            
    //     }
    //     //sessionStorage.setItem('ref', 0);
    // }

    
	decipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		return encoded => encoded.match(/.{1,2}/g)
			.map(hex => parseInt(hex, 16))
			.map(applySaltToChar)
			.map(charCode => String.fromCharCode(charCode))
            
			.join('');
	}


    render(){
        if ([null, '', undefined, 'undefined'].includes(localStorage.getItem('roles'))) {
            localStorage.clear()
            this.autologout();
        }
        const modalStyles = {
			width: '100px !important',
		}
        const { open } = this.state;
        var hideyes=false
       try{
            if(localStorage.getItem("roles").indexOf("led") >= 0)
            {
                hideyes=true
            }
            else{
                hideyes = false
            }
        }
        catch(e){
            hideyes = false
        }

        // Site Under Maintainence code
        // Uncomment site maintainence code
        // console.log("rols", localStorage.getItem("roles"))
        // if(localStorage.getItem("is_load_management") != "1" && localStorage.hasOwnProperty("email") != false
        // && localStorage.getItem("user_type") != "TECHUSER" )
        // {
        //         return <Redirect to="/pages/maintenance" push={true} />
        // }
        // End

        // Original Code
        if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
        {
            return <Redirect to="/" push={true} />
        }
        // End


        //console.log("Check",localStorage.getItem('token'));
        var pageStyle = this.state.pageStyle;var actual_roles = localStorage.getItem("actual_roles");
        
        return (
            
            <div className="page-wrapper">

                {
                (hideyes == true)?
                    <div className="page-body-wrapper" style={{overflow:"hidden"}} id="plivebody">
                        <Header />
                        {/* <div  id="fscreen" className="fs-body">
                            <div className="fs-content">
                                <button onClick={this.onShowFullscreen.bind(this)}>FullScreen Mode</button>
                            </div>
                         </div> */}
                         {this.props.children}
                    </div>
                    :
                <div className="page-body-wrapper sidebar-close" id="pageBoby">
                <Header />
                    <Sidebar context= { this } />
                    <div className="page-body" id="pbody" style={this.state.pageStyle}>
                        <a href={"javascript:void(0)"} className={"sidebaricon "+(this.state.showOutIcon)}>
                            {/*<span className="vline"></span>*/}
                            <span className="whbg-bg" id="whbg-bg"></span>
                            <span id="ssinner" className={"siconbg "+ (this.state.ssinner)}></span>
                            {/*
                            <img src={this.state.sideimg} id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar} className={"sidemenu "+(this.state.imgbg)+" "+(this.state.l20n)}  alt=""/>
                            */}
                            <img src={require('../assets/icons/sidenav-blue.png')} id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar} className={"sidemenu sidebrclose "+(this.state.imgbg)+" "+(this.state.l20n)+" "+(this.state.sclose)}  alt=""/>
                       
                        </a>
                        <ErrorHandler>
                        {this.props.children}
                        
                        </ErrorHandler>
                    </div>
                </div>
                }
                {this.state.check ? 
                <Modal open={open} styles={modalStyles} >
                    <div className={"row"} 
                    // style={{ padding: '5px 0px', marginLeft:"20%", width:"60%", overflow: 'hidden', zIndex: '9999',display: "flex", justifyContent: "center", alignItems: "center" }} 
                    >
                        <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUpdateConsigneeDetails} >
                            <div className="row form-group " >
                            <div className="row col-xl-12 col-lg-12 form-group " style={{ display:"flex",flexDirection:"row",justifyContent:"center" }}>
                                <h5 className='f25'>Login As</h5>
                            </div>
                                <div className="row col-xl-12 col-lg-12 form-group"  style={{ display:"flex",flexDirection:"row",justifyContent:"center" }}>
                                <button type="button" className='btn btn-info mr-3' onClick={this.onClickOperation.bind(this,"CUSTOMER")}>Customer</button>
                                <button type="button" className='btn btn-warning' onClick={this.onClickOperation.bind(this,"AMNS_OPS_SC")}>Operations</button>

                                    {/* <div className="col-xl-6 col-lg-6 col-sm-6 beffect text-center" >
                                        <button type="button" className='btn btn-info' onClick={this.onClickOperation.bind(this,"CUSTOMER")}>Customer</button>
                                    </div> */}
                                    {/* <div className="col-xl-6 col-lg-6 col-sm-6 beffect  text-center" >
                                    <button type="button" className='btn btn-info' onClick={this.onClickOperation.bind(this,"AMNS_OPS_SC")}>Operations</button>

                                    </div> */}
                                </div>
                            </div>
                           
                        </form>
                    </div>
				</Modal>
                : ""}

            </div>
           
        );
    }
}



function showSideBar()
{
    alert("test");
    var c=document.getElementById('pageBoby');
    c.removeClass=' sidebar-close';                                                                                                                                                                                                                                                                                                                                                                                                                                 
    
}
window.onload = function(){
    
}
function fullscreen()
{
    var ele = document.getElementById("plivebody")
   // $("#fscreen").hide();
    console.log("ele ",ele)
    if (ele.requestFullscreen) {
        console.log("S1")
        ele.requestFullscreen();
	} else if (ele.webkitRequestFullscreen) {
        console.log("S2")
		ele.webkitRequestFullscreen();
	} else if (ele.mozRequestFullScreen) {
        console.log("S3")
		ele.mozRequestFullScreen();
	} else if (ele.msRequestFullscreen) {
        console.log("S4")
		ele.msRequestFullscreen();
	} else {
       // $("#fscreen").show();
		console.log('Fullscreen API is not supported.');
	}
}
async function parseMenus(menusAll) {
	var groupHeaders = await getGroupHeaderMenus(menusAll);
	//console.log(groupHeaders, "groupheaders")
	groupHeaders.forEach(groupHeader => {
		var groupHeaderChildren  = getChildren(groupHeader.code, menusAll);
		groupHeader.children = groupHeaderChildren;
		groupHeader.children.forEach(groupHeader => {
			groupHeaderChildren  = getChildren(groupHeader.code, menusAll);
			groupHeader.children = groupHeaderChildren;
		});
	});
	return groupHeaders;
}
async function getGroupHeaderMenus(menus) {
	var result = menus.filter(obj => {
	  return obj.is_group_header === true
	})
	//console.log("Matching Group Headers objects = ", result);
	return result;
}
  
function getChildren(parent_code, menus) {
	var result = menus.filter(obj => {
	  if (obj.parent_code === parent_code) {
		return obj;
	  }
	})
	//console.log("Matching objects = ", result);
	return result;
}

export default App;


