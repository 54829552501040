import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import Select from 'react-select';
import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
} from '../common/utils'; 

import UpdateLRForm from './uploadLRForm';

import DrawMap from '../common/drawmap';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}

export default class AMNSCustomerEPOD extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',            
			sliderAddTranslate:"",
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
			rowModelType: "serverSide",
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                UpdateLRForm:UpdateLRForm
			},
            sliderRouteTranslate:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            
			detailCellRendererParams:{},
            totalcount:[],
            intransitmy:[],
            insidemytpa:[],
            loadingstarted:[],
            loadingcompleted:[],
            insidetpaafterloading:[],
            intransittocustomer:[],
            nearcustomer:[],
            reachedcustomer:[],
            leftcustomer:[],
            forceclose:[],
            insidetpa:[],
            rowid:"",
            shipment_id:"",
            batch_no:"",
            lr_no:"",
            customer_lr_doc:"",
            customer_pod_damage_desc:"",
            customer_pod_remarks:"",
            customer_pod_damages:"",
            customer_pod_done_by:"",
            customer_pod_gst_no:"",
            invoice_time:"",
        }
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
		
    }
    componentDidMount(){
        loadDateTimeScript();
        // var parameters = {
            
        // }
        // redirectURL.post("/amns/loadingarealegsdata",parameters).then(async (response) =>{
        //     console.log("response ", response.data);
        // })

        
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            var customer_code = localStorage.getItem("customer_code");
        }
        else{
            var customer_code = "";
        }
        var parameters = {
            customer_code:customer_code
        }
        this.onLoadGetShipments(parameters);

    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
       /* redirectURL.post("/amns/customershipments",parameters)
        .then((response) => {
            if(response.data.remark == "SUCCESS")
            {
                var records = response.data.shipments;
                let newArray = [];
                let totuniqueObject = {}; 
                var totobjTitle;
                // Loop for the array elements 
                for (let i in records) { 
    
                    // Extract the title 
                    totobjTitle = records[i]['shipment_id']; 
    
                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = records[i]; 
                } 
    
                // Loop to push unique object into array 
                for (var a in totuniqueObject) { 
                    newArray.push(totuniqueObject[a]); 
                } 
                this.setState({
                    rowData:newArray,
                    loadshow:"show-n"
                })
            }
            
        });
        */

        redirectURL.post("/amns/customershipmentlrnos",parameters)
        .then((response) => {
            if(response.data.remark == "SUCCESS")
            {
                var records = response.data.shipments;
                console.log("records ", records)
                // let newArray = [];
                // let totuniqueObject = {}; 
                // var totobjTitle;
                // // Loop for the array elements 
                // for (let i in records) { 
    
                //     // Extract the title 
                //     totobjTitle = records[i]['lr_no']; 
    
                //     // Use the title as the index 
                //     totuniqueObject[totobjTitle] = records[i]; 
                // } 
    
                // // Loop to push unique object into array 
                // for (var a in totuniqueObject) { 
                //     newArray.push(totuniqueObject[a]); 
                // } 
               
                this.setState({
                    rowData:records,
                    loadshow:"show-n"
                })
            }
            
        });

    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
   
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderAddTranslate:'',
		});
		
	}
    
    onClicCloseCounterBlock(){
        this.setState({
            showmys:"show-n",
            showdsc:"show-n",
            showcrm:"show-n",
            showplatemill:"show-n",
            showpipemill:"show-n",
        })
    }
    onClickUpdateItem(rownode){
        console.log("rpownode ", rownode)
        this.setState({
			sliderAddTranslate:"slider-translate",
			overly:'show-m',
            rowid:rownode.data._id,
            lr_no:rownode.data.lr_no,
            shipment_id:rownode.data.shipment_id,
            batch_no:rownode.data.consignment_code,
            invoice_time:rownode.data.invoice_time
        })
    }
    
    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    
    changeFileHandler(event) {
        console.log("event.target.files[0] ", event.target.files)
        try{
            // if(event.target.files[0].size <= 102400){
                this.setState({
                    customer_lr_doc: event.target.files[0]
                })
            // }else {
            //     this.setState({
            //         show: true,
            //         basicTitle: "File Size exceeds 100kb",
            //         basicType: "info",
            //         customer_lr_doc: ""
            //     })
            //     $("#customer_lr_doc").val("")
            // }
            
            
        }
        catch(e){
            this.setState({
                customer_lr_doc: ""
            })
            $("#customer_lr_doc").val("")
        }
        
    }
    saveLRFormData(event){
        event.preventDefault();
        // console.log("this.state.customer_lr_doc ", this.state.customer_lr_doc)
        var customer_lr_doc = this.state.customer_lr_doc;
        // console.log("customer_lr_doc ", customer_lr_doc)
        var customer_pod_date = $("#customer_pod_date").val();
        if(customer_pod_date !=undefined && customer_pod_date !="")
        {
            var invoice_time = this.state.invoice_time;
            console.log(invoice_time,"invoice_time")
            var dateChk = 1;
            if(invoice_time !=undefined && invoice_time !="")
            {
                var compare_invoice_time = moment.parseZone(new Date(invoice_time)).utcOffset("-05:30")._d;
                var formatCpod = customer_pod_date.split(" ")[0].split("-")[2]+"-"+customer_pod_date.split(" ")[0].split("-")[1]+"-"+customer_pod_date.split(" ")[0].split("-")[0]+" "+customer_pod_date.split(" ")[1];
                console.log(formatCpod,"formatCpod")
                var compare_customer_pod_date = moment.parseZone(new Date(formatCpod)).utcOffset("+00:00")._d;
                var comparision = moment(compare_customer_pod_date).isAfter(moment(compare_invoice_time));
                console.log(compare_invoice_time,compare_customer_pod_date,"console dates")
                console.log(comparision,"comparision--")
                if(comparision == true)
                {
                    dateChk = 1;
                }
                else
                {
                    dateChk = 0;
                }
            }
            var customer_pod_remarks = this.state.customer_pod_remarks;
            var customer_pod_damages = this.state.customer_pod_damages;
            var customer_pod_done_by = this.state.customer_pod_done_by;
            var customer_pod_damage_desc = this.state.customer_pod_damage_desc;
            // var customer_pod_gst_no = this.state.customer_pod_gst_no;
            const formData = new FormData();
            formData.append("customer_lr_doc", customer_lr_doc);
            formData.append("customer_pod_date", customer_pod_date);
            formData.append("customer_pod_done_by", customer_pod_done_by);
            formData.append("customer_pod_remarks", customer_pod_remarks);
            formData.append("customer_pod_damages", customer_pod_damages);
            formData.append("customer_pod_damage_desc", customer_pod_damage_desc);
            // formData.append("customer_pod_gst_no",customer_pod_gst_no);
            formData.append("lr_no", this.state.lr_no);
            // console.log("formData ",formData)
            if(dateChk == 1)
            {
                redirectURL.post("/amns/savelrdocumentfields", formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'POST, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                .then((response) => {
                    // console.log("response ", response.data)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicTitle: "Successfully update data",
                            basicType: "success",
                            customer_lr_doc:"",
                            customer_pod_damage_desc:"",
                            customer_pod_remarks:"",
                            customer_pod_damages:"",
                            customer_pod_done_by:""
                        })
                        $("#customer_pod_date").val("");
                    }
                    else {
                        this.setState({
                            show: true,
                            basicTitle: response.data.message,
                            basicType: "danger"
                        })
                    }
                })
                .catch(error => {
                    console.log(error,"error")
                    this.setState({
                        show: true,
                        basicTitle: "Something went wrong. Please try after sometime",
                        basicType: "warning"
                    })
                })
            }
            else
            {
                this.setState({
                    show: true,
                    basicTitle: "POD Date should be greaterthan Invoice Time",
                    basicType: "warning"
                })
            }
        }
        else
        {
            this.setState({
                show: true,
                basicTitle: "Please enter the POD Date.",
                basicType: "warning"
            })
        }
    }
    render(){
        var maptransporter='';
		
        var columnwithDefs = [
            // {
            //     headerName: "",
            //     field: "_id",
            //     width: 100,
            //     pinned: 'left',
            //     cellRenderer:'ViewShipmentData',
            //     filter: false,resizable: true
                
            // },
            {
                headerName:Constant.COL_LRNO,
                field:"lr_no",
                width:120,
                resizable: true
            },
            {
                headerName:"Invoice No",
                field:"sales_order",
                width:150,
                resizable: true
            },
            {
                headerName:"Invoice Time",
                field:"invoice_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.invoice_time != "" && params.data.invoice_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                },                
                resizable: true
            },
           
            // {
            //     headerName:Constant.COL_SHIPMENT_MODE,
            //     field:"shipment_mode",
            //     width:80,
            //     resizable: true
            // },
            {
                headerName:Constant.COL_TRUCK_NO,
                field:"truck_no",
                width:120,
                resizable: true
            },
            {
                headerName:Constant.COL_TPT_NAME,
                field:"transporter_name",
                width:200,
                resizable: true
            },
            
            {
                headerName:Constant.COL_DRIVER_NAME,
                field:"driver_name",
                width:180,
                resizable: true
            },
            {
                headerName: "Upload EPOD",
                field: "_id",
                width: 220,
                cellRenderer:'UpdateLRForm',
                filter: false,
                resizable: true
                
            },
           
            
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
              
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>{Constant.PAGE_EPOD}</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{marginTop:"20px", height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                            
                                        
                                        </div> 
                                        

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								tolls={this.state.tolls} 
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?(this.state.routeTruck.startTime):"NA"}</div>
											</div> */}
											{/* <div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?(this.state.routeTruck.endTime):"NA"}</div>
											</div> */}
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{/* {(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											 */}
											
										
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}

                <div className={"slide-r "+(this.state.sliderAddTranslate)} >
                    
                    <div className="slide-r-title">
                        <h4>
                            Upload EPOD
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"dataLoad " +(this.state.loadshow)}>
                            <div style={{position:"absolute", left:"38%"}}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                        
                            <form id="upform" className="theme-form" onSubmit={this.saveLRFormData.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">POD Date</label> 
                                    <input type="text" name="customer_pod_date" id="customer_pod_date" className="customer_pod_date form-control" required autoComplete="off" />
                                </div>
                                
                                <div className="form-group mt-20p">
                                    <label className="">Remarks</label> 
                                    <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3" onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">POD Done By</label> 
                                    <input type="text" name="customer_pod_done_by" id="customer_pod_done_by" onChange={this.changeHandler} className="form-control" />
                                </div>
                                {/* <div className="form-group mt-20p">
                                    <label className="">Damages (If any)</label> 
                                    <div className="col-sm-12">
                                        <input type="radio" name="customer_pod_damages" id="customer_pod_damages" onChange={this.changeHandler} value="1" />Yes &nbsp;&nbsp;
                                        <input type="radio" name="customer_pod_damages" id="customer_pod_damages" onChange={this.changeHandler} value="0" />No 
                                    </div>
                                </div>
                                
                                <div className="form-group mt-20p">
                                    <label className="">Damage Remarks</label> 
                                    <textarea name="customer_pod_damage_desc" id="customer_pod_damage_desc" rows="3" onChange={this.changeHandler} className="form-control" ></textarea>
                                </div> */}
                                
                                {/* <div className="form-group mt-20p">
                                    <label className="">GST Number</label> 
                                    <input type="text" name="customer_pod_gst_no" onChange={this.changeHandler} id="customer_pod_gst_no" className="form-control" required  />
                                </div> */}
                                <div className="form-group mt-20p">
                                    <label className="">Upload Images Any</label> 
                                    <input type="file" name="customer_lr_doc" id="customer_lr_doc" onChange={this.changeFileHandler} className="form-control" />
                                </div>
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.customer_pod_date').datetimepicker({
		// mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.customer_pod_date').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        maxDate:0
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
        maxDate:1
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       
function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}       
function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
