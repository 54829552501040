import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import AndriodMapView from './andriodmapview';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
export default class AndriodMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  rownode:[], 
		  googelRoutes:[], 
		  maptruckno:'',
		  dealer:'',
		  mapinfo:[],
		  defTransitCoords:'',
		  showdetails:'show-n',
		  showsecond:'show-n'
		};
		
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
	}
	componentDidMount = async() =>{
		this.setState({
			loadshow:'show-m'
		});
		//console.log(this.props.match.params.type);
		
			var reqdata = {
				consignment_code:this.props.match.params.code
			}
			await redirectURL.post('consignments/item',reqdata)	
			.then( async (response) => {

				/*1*/
				if(this.props.match.params.type == "mainmap") 
				{
					var e = response.data[0];
					//console.log(e)
					// If gateouttime is current time - It shows "No Route data available"
					if(e.invoice_time)
					{
						//console.log("came into invoice time", e.invoice_time)
						//console.log("Step1", e.invoice_time)
						var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
					}
					else{
						//console.log("Step2")
						if(e.gate_in_time)
						{
							//console.log("came into gate in time", e.gate_in_time)
							//console.log("Step3")
							var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
						}
						else{
							//console.log("Step4")
							if(e.gate_out_time){
								//console.log("Step5")
								//console.log("came into gate out time", e.gate_out_time)
								var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
							}
						}
					}

					// if (e.gate_out_time) {
					// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
					// }

					var edate ='';
					var end_date;
					if(e.status <= 4)
					{
						//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
						//var edatesecond = new Date(ms);
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						
						edate = getDDMMYYYYHHMMSS(end_date);
					}
					else if(e.status == 5)
					{
						if (e.left_dealer_marked) {
							end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
							edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
						}
						
					}
					else{
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						
						edate = getDDMMYYYYHHMMSS(end_date);
						
					}

					//console.log("end date",end_date);
					//console.log("checking e",e)

					if ((e.transit_time) > 0)
					{
						var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
					}
					else{
						var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
					}	

					var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();

					if (!end_date) {
						// When end date is unavailable then current date is considered as end date.
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						//console.log("Defining end_date ", end_date);
					} 
					var reqData = {
						truck_no:e.truck_no,
						consignment_code:e.consignment_code,
						consignee_code:e.consignee_code,
						gate_out_time:gateouttime,
						seconddate:end_date,
						screen:"consignment"
					}
					//console.log("reqData: ", reqData);
					await redirectURL.post('/consignments/andriodmaproutes',reqData, {
						headers:{
							'content-type':'application/json'
						}
					})
					.then((response) => {
						var records = response.data;
						//console.log("Props data ", e)

						this.setState({
							loadshow:'show-m'
						});
						if(records.coords.length == 0)
						{
							//console.log("records", records.coords);
							this.setState({
								show: true,
								basicTitle:'No Route data available',
								basicType:"danger",
								loadshow:'show-n',overly:'show-n',
							});
						}
						else{
							if(records != '')
							{
								var sdate = e.gate_out_time;
								if(e.invoice_time)
								{
									//console.log("came into invoice time", e.invoice_time)
									//console.log("Step1", e.invoice_time)
									var sdate = e.invoice_time;
								}
								else{
									//console.log("Step2")
									if(e.gate_in_time)
									{
										//console.log("came into gate in time", e.gate_in_time)
										//console.log("Step3")
										var sdate = e.gate_in_time;
									}
									else{
										//console.log("Step4")
										if(e.gate_out_time){
											//console.log("Step5")
											//console.log("came into gate out time", e.gate_out_time)
											var sdate = e.gate_out_time;
										}
									}
								}


								var edate ='';
								if(e.status <= 4)
								{
									//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
									//var edatesecond = new Date(ms);
									end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
									edate = getDDMMYYYYHHMMSS(end_date);
								}
								else if(e.status == 5)
								{
									if (e.left_dealer_marked)
									{
										edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
									}
									else{
										end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
										edate = getDDMMYYYYHHMMSS(end_date);
									}
								}
								//console.log("End Date ", edate)
								this.setState({
									sliderRouteTranslate:"slider-translate-60p",
									showDiv:'show-m',
									mapinfo:records,
									dealer:e.consignee_code,
									consignment_code:"Consignment : "+e.consignment_code,
									maptruckno:e.truck_no,
									routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Information",
									overly:'show-n',
									rownode:e,
									leg_no:0
								});
								this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
								//this.renderMap();

							}
						}
						
						
					})
					.catch(function (error) {
						console.log(error);
					});
				}

				/*2*/
				if(this.props.match.params.type == "transit_delay") 
				{
					var e = response.data[0];
					await redirectURL.post('/consignments/transitdelay', {
						consignment_code:e.consignment_code,
						consignee_code:e.consignee_code, 
						truck_no:e.truck_no
					})
					.then(async (response) =>{
						//console.log("Step 1 ",response.data)
						var transitedelaydata=response.data;
						var legsarr=[]
						transitedelaydata.map((item) => {
							//if(item.trip_completed < 2)
							//{
								if(this.props.match.params.leg == item.leg_no)
								{
									legsarr.push(item)
								}
							//}
						})
						//console.log('transitedelaydata ', transitedelaydata);
						//console.log('legsarr ', legsarr);
						
						
						var rownode = legsarr[0];
						var d = new Date(rownode.leg_start);
						var r = new Date(rownode.leg_end_eta);
						var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode.truck_no,
							consignment_code:rownode.consignment_code,
							consignee_code:rownode.consignee_code,
							first_instance:fintance,
							recent_instance:eintance,
							screen:"consignment"
						}
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then((response) => {
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							if(response.data.coords.length == 0)
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n'
								});
							}
							else{
								var sdate='';
								var edate='';

								if(rownode.leg_start != '' && rownode.leg_start != undefined)
								{
									sdate=rownode.leg_start;
								}
								if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
								}
							this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									sliderRouteTranslate:"slider-translate-60p",
									defTransitCoords: rownode.consignee_coordinates,
									consignment_code:"Consignment : "+rownode.truck_no,
									maptruckno:rownode.truck_no,
									routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Leg Information",
									overly:'show-n',
									rownode:rownode,
									leg_no:rownode.leg_no

							});

							this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
								//this.renderMap();
							
							} 
						})
						.catch(function (error) {
							console.log(error);
						});
						
						
					});

				}


				/*3*/
				if(this.props.match.params.type == "no_gps") 
				{
					var e = response.data[0];
					await redirectURL.post('/consignments/nogpsdata', {
						consignment_code:e.consignment_code, 
						truck_no:e.truck_no
					})
					.then(async (response) =>{
						//console.log("Step 1 no gps",response.data)
						var transitedelaydata=response.data;
						var legsarr=[]
						if(transitedelaydata.length > 0)
						{
							var lgno = (this.props.match.params.leg-1)
							legsarr.push(transitedelaydata[lgno])
						}
						
						//console.log('transitedelaydata ', transitedelaydata);
						//console.log('legsarr ', legsarr);
						
						
						var rownode = legsarr[0];
						var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode.truck_no,
							consignment_code:rownode.consignment_code,
							//consignee_code:rownode.consignee_code,
							first_instance:fintance,
							recent_instance:eintance,
							screen:"consignment"
						}
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then((response) => {
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							if(response.data.coords.length == 0)
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n'
								});
							}
							else{
								var sdate='';
								var edate='';

								if(rownode.last_packet_datetime != '' && rownode.last_packet_datetime != undefined)
								{
									sdate=rownode.last_packet_datetime;
								}
								if(rownode.packet_received_on != '' && rownode.packet_received_on != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.packet_received_on)
								}
							this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									sliderRouteTranslate:"slider-translate-60p",
									defTransitCoords: rownode.consignee_coordinates,
									consignment_code:"Consignment : "+rownode.truck_no,
									maptruckno:rownode.truck_no,
									routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Leg Information",
									overly:'show-n',
									rownode:rownode,
									leg_no:this.props.match.params.leg

							});

							this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, this.props.match.params.leg, rownode.truck_no)
								//this.renderMap();
							
							} 
						})
						.catch(function (error) {
							console.log(error);
						});
						
						
					});

				}


				/*4*/
				if(this.props.match.params.type == "overspeed") 
				{
					var e = response.data[0];
					//console.log("e ", e)
					await redirectURL.post('/consignments/overspeed', {
						consignment_code:e.consignment_code, 
						truck_no:e.truck_no
					})
					.then(async (response) =>{
						//console.log("Step 1 Overspeed",response.data)
						var transitedelaydata=response.data;
						var legsarr=[]
						if(transitedelaydata.length > 0)
						{
							var lgno = (this.props.match.params.leg-1)
							legsarr.push(transitedelaydata[lgno])
						}
						
						//console.log('transitedelaydata ', transitedelaydata);
						//console.log('legsarr ', legsarr);
						
						
						var rownode = legsarr[0];
						var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode.truck_no,
							consignment_code:rownode.consignment_code,
							consignee_code:rownode.consignee_code,
							first_instance:finstance,
							recent_instance:rinstance,
							screen:"consignment"
						}
						
						await redirectURL.post('/reports/overspeedgpsroutes',params)
						.then((response) => {
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							if(response.data.coords.length == 0)
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n'
								});
							}
							else{
								var sdate='';
								var edate='';

								if(rownode.last_packet_datetime != '' && rownode.last_packet_datetime != undefined)
								{
									sdate=rownode.last_packet_datetime;
								}
								if(rownode.packet_received_on != '' && rownode.packet_received_on != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.packet_received_on)
								}
							this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									sliderRouteTranslate:"slider-translate-60p",
									defTransitCoords: rownode.consignee_coordinates,
									consignment_code:"Consignment : "+rownode.truck_no,
									maptruckno:rownode.truck_no,
									routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Leg Information",
									overly:'show-n',
									rownode:rownode,
									leg_no:this.props.match.params.leg

							});

							this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, this.props.match.params.leg, rownode.truck_no)
								//this.renderMap();
							
							} 
						})
						.catch(function (error) {
							console.log(error);
						});
						
						
					});

				}


				/*5*/
				if(this.props.match.params.type == "night_drive") 
				{
					var e = response.data[0];
					//console.log("e ", e)
					await redirectURL.post('/consignments/nightdriving', {
						consignment_code:e.consignment_code,
						gate_out_time:e.gate_out_time,
						truck_no:e.truck_no
					})
					.then(async (response) =>{
						//console.log("Step 1 Night Drive",response.data)
						var transitedelaydata=response.data;
						var legsarr=[]
						if(transitedelaydata.length > 0)
						{
							var lgno = (this.props.match.params.leg-1)
							legsarr.push(transitedelaydata[lgno])
						}
						
						//console.log('transitedelaydata ', transitedelaydata);
						//console.log('legsarr ', legsarr);
						
						
						var rownode = legsarr[0];
						var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode.truck_no,
							first_instance:fintance,
							recent_instance:eintance,
						}
						
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then((response) => {
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							if(response.data.coords.length == 0)
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n'
								});
							}
							else{
								var sdate='';
								var edate='';

								if(rownode.first_instance != '' && rownode.first_instance != undefined)
								{
									sdate=rownode.first_instance;
								}
								if(rownode.recent_instance != '' && rownode.recent_instance != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.recent_instance)
								}
							this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									sliderRouteTranslate:"slider-translate-60p",
									defTransitCoords: rownode.consignee_coordinates,
									consignment_code:"Consignment : "+rownode.truck_no,
									maptruckno:rownode.truck_no,
									routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Leg Information",
									overly:'show-n',
									rownode:rownode,
									leg_no:this.props.match.params.leg

							});

							this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, this.props.match.params.leg, rownode.truck_no)
								//this.renderMap();
							
							} 
						})
						.catch(function (error) {
							console.log(error);
						});
						
						
					});

				}


				/*6*/
				if(this.props.match.params.type == "enroute_stoppage") 
				{
					var e = response.data[0];
					console.log("e ", e)
					await redirectURL.post('/consignments/enroutes', {
						consignment_code:e.consignment_code,
						truck_no:e.truck_no
					})
					.then(async (response) =>{
						console.log("Step 1 Enroute ",response.data)
						var transitedelaydata=response.data;
						var legsarr=[]
						if(transitedelaydata.length > 0)
						{
							var lgno = (this.props.match.params.leg-1)
							legsarr.push(transitedelaydata[lgno])
						}
						
						console.log('transitedelaydata ', transitedelaydata);
						console.log('legsarr ', legsarr);
						
						
						var rownode = legsarr[0];
						var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						if(rownode.reset_datetime)
						{
							var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						
						}
						else{
							var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						
						}
						//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
						var params = { 
							truck_no:rownode.truck_no,
							//consignment_code:rownode.consignment_code,
							//consignee_code:rownode.consignee_code,
							first_instance:fintance,
							recent_instance:eintance,
							screen:"consignment"
						}
						await redirectURL.post('/reports/nightdriveroutes',params)
						.then((response) => {
						
						//var records = JSON.parse(JSON.stringify(response)).data;
							//console.log("Inner Grid ",response.data);
							if(response.data.coords.length == 0)
							{
								this.setState({
									show: true,
									basicTitle:'No Route data available',
									basicType:"danger",
									loadshow:'show-n'
								});
							}
							else{
								var sdate='';
								var edate='';

								if(rownode.stoppage_start_time != '' && rownode.stoppage_start_time != undefined)
								{
									sdate=rownode.stoppage_start_time;
								}
								if(rownode.reset_datetime != '' && rownode.reset_datetime != undefined)
								{
									edate=getDDMMYYYYHHMMSS(rownode.reset_datetime)
								}
								else
								{
									edate=moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
								}
							this.setState({
									mapinfo:response.data,
									dealer:rownode.consignee_code,
									sliderRouteTranslate:"slider-translate-60p",
									defTransitCoords: rownode.consignee_coordinates,
									consignment_code:"Consignment : "+rownode.truck_no,
									maptruckno:rownode.truck_no,
									routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
									loadshow:'show-n',
									sidebarSubHeader:"Consignment Leg Information",
									overly:'show-n',
									rownode:rownode,
									leg_no:this.props.match.params.leg

							});

							this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, this.props.match.params.leg, rownode.truck_no)
								//this.renderMap();
							
							} 
						})
						.catch(function (error) {
							console.log(error);
						});
						
						
					});

				}
			})
			.catch(function(e){
				console.log(e)
			})
				
		//}
		
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}


	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			showsecond:'show-n'
		});
		
	}
	onClickShowDetails(){
		this.setState({
			//loadshow:'show-m',
			overly:'show-m',
			showdetails:'show-m',
			inboxSlider:'slider-translatey',
			showsecond:'show-m'
		});
	}
    render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
					{this.state.alert}
					{(this.state.mapinfo != '')?
						<div className="col-xl-12 col-md-12 n-p-0">
							<AndriodMapView 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								//googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={""} 
								mapinfo={this.state.mapinfo} 
								defTransitCoords={this.state.defTransitCoords} 
							/>
							<div className="andriomap-pos" onClick={this.onClickShowDetails.bind(this)}>
								<i className="icofont icofont-dotted-up ico-color"></i>
							</div>
							
							<div className={"divpos zindex999 "+(this.state.inboxSlider)}>
								<div className={"andriomap-pos2 pt-10px "+(this.state.showsecond)} onClick={this.onClickHideAll.bind(this)}>
									<i className="icofont icofont-dotted-down ico-color"></i>
								</div>
								<div className="crm-numbers mt-20p prel pb-0">
									<div className="col-xl-12 col-lg-12 textAlignCenter row">
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Truck No</label>
											<div>{this.state.routeTruck.truck_no}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Start Time</label>
											<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
										</div>
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">End Time</label>
											<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">GPS Distance</label>
											<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
										</div>
										{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
										:
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Google Distance</label>
											<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
										</div>
										}
										
									</div>
								</div>
							</div>
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div>
							<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
					
					 </div>
					
					:''}
							
			</div>
        )
    }
}
