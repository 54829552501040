import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS, getDDMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

// import AndriodMapView from './andriodmapview';
import DrawMap from '../common/drawmapweb';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
export default class MobileTripMaps extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			defTransitCoords:'',
            consignercords:'',
            consigneecords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  rownode:[], 
		  googelRoutes:[], 
		  maptruckno:'',
		  dealer:'',
		  mapinfo:[],
		  defTransitCoords:'',
		  showdetails:'show-n',
		  showsecond:'show-n',
		  map_type_of_tracking:"",
		  iscustomer:0,
		};
		
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
	}
	componentDidMount = async() =>{
		this.setState({
			loadshow:'show-m',
			iscustomer:this.props.match.params.iscustomer,
		});
		// console.log("reqData: ", reqData);
        var params = {
            shipment_id : this.props.match.params.shipmentid
        }
		console.log(this.props.match.params.shipmentid,"shipmentid")
        redirectURL.post('/consignments/getConsignmentInfoByShipmentId', params)
			.then((response1) => {
            var consignmentcodes = []
            //console.log("leg_details ", JSON.parse(e.leg_details))
            var consignmentInfo = response1.data;
            if(consignmentInfo.length > 0)
            {
                // consignmentInfo.map((item) => {
                //     consignmentcodes.push(item.consignment_code)
                // })
				// if(response1.data[0].consignment_code !=undefined && response1.data[0].consignment_code !="")
				// {
					var consignmentcodes = response1.data[0].consignment_codes;
				// }
                // console.log("consignmentcodes ", consignmentcodes)
                this.setState({
                    loadshow: 'show-m'
                });
                var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
                var startdate = getYYYYMMDDHHMMSS(consignmentInfo[0].parking_in_time)
                let sD = startdate.split(" ")
                let date = sD[0].replaceAll("/", "-")
                startdate = date + " " + sD[1]
                // console.log("startD",startdate)
				console.log(consignmentInfo[0],"123")
				if(consignmentInfo[0].loading_yard_exit_time!="" && consignmentInfo[0].loading_yard_exit_time!=undefined){
					var gateouttime=getYYYYMMDDHHMMSS(consignmentInfo[0].loading_yard_exit_time)
				}
				else if(consignmentInfo[0].parking_in_time!="" && consignmentInfo[0].parking_in_time!=undefined){
					var gateouttime = getYYYYMMDDHHMMSS(consignmentInfo[0].parking_in_time)
				}
				else{
					var gateouttime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
				}			
				// if(consignmentInfo[0].parking_in_time != "" && consignmentInfo[0].parking_in_time != undefined)
				// {
				// 	var gateouttime = moment.parseZone(consignmentInfo[0].parking_in_time).format("YYYY-MM-DD HH:mm:ss");
				// }
				// else{
				// 	var gateouttime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
				// }

				if(consignmentInfo[0].status == "9" || consignmentInfo[0].status == "10" || consignmentInfo[0].status == 9 || consignmentInfo[0].status == 10)
				{
					var end_date = moment.parseZone(consignmentInfo[0].recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
				}
				if(consignmentInfo[0].status == "11" || consignmentInfo[0].status == 11)
				{
					var end_date = moment.parseZone(consignmentInfo[0].left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
				}
				else{
					if(consignmentInfo[0].status == 6 || consignmentInfo[0].status == "6")
					{
						var end_date = moment.parseZone(consignmentInfo[0].force_closure_time).format('YYYY-MM-DD HH:mm:ss');
					}
					else{
						var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					}
					
				}
                var reqData = {
                    truck_no: consignmentInfo[0].truck_no,
                    shipment_id: consignmentInfo[0].shipment_id,
                    consignment_codes: consignmentcodes,
                    fromdate: gateouttime,
                    todate: end_date
                }
                redirectURL.post('/trucks/truckmap', reqData)
                    .then((response) => {
                        var records = response.data;
                        console.log("check ",Array.isArray(records))
                        // console.log("Props data ", response.data)
                        try{
                            if(Array.isArray(records) == false)
                            {
                                var recs = records.replace(/NaN/g,"0")
                                records = JSON.parse(recs)
                            }
                        }
                        catch(e){

                        }
                        
                        // console.log("records list ", records);
                        this.setState({
                            loadshow: 'show-m'
                        });
                        // console.log("S1")
                        try {
                            // console.log("S2")
                            // console.log("records.coords ", records.coords)
                            if (records.coords.length == 0) {
                                // console.log("S3")
                                //console.log("records", records.coords);
                                this.setState({
                                    show: true,
                                    basicTitle: 'No Route data available',
                                    basicType: "danger",
                                    loadshow: 'show-n'
                                });
                            }
                            else {
                                // console.log("S4")
                                // console.log("recordss ", records)
                                // if (records != '') {

                                    this.setState({
                                        mapinfo: records,
                                        dealer: records.consignee_coordinates,
                                        consignment_code: "Truck NO : " + consignmentInfo[0].truck_no,
                                        maptruckno: consignmentInfo[0].truck_no,
										map_type_of_tracking:consignmentInfo[0].type_of_tracking,
                                        routeTruck: { "truck_no": consignmentInfo[0].truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time },
                                        loadshow: 'show-n',
                                        sidebarSubHeader: "Truck Route Details",
                                        overly: 'show-m',
                                        rownode: consignmentInfo[0],
                                        consigner_coordinates: records.consigner_coordinates,
                                        consignee_coordinates: records.consignee_coordinates,
                                        tolls: records.tolls

                                    });
                                    console.log("s5 ")
                                    this.renderMap();
                                // }
                            }
                        }
                        catch (e) {
                            if (records.message == "no data found") {
                                this.setState({
                                    show: true,
                                    basicTitle: 'No Route data available',
                                    basicType: "danger",
                                    loadshow: 'show-n'
                                });
                            }
                        }



                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
                else
                {
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }
        })
        .catch(function (error) {
            console.log(error);
        });
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}


	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			showsecond:'show-n'
		});
		
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	
	onClickShowDetails(){
		this.setState({
			//loadshow:'show-m',
			overly:'show-m',
			showdetails:'show-m',
			inboxSlider:'slider-translatey',
			showsecond:'show-m'
		});
	}
    render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
					{this.state.alert}
					{(this.state.mapinfo != '')?
						<div className="col-xl-12 col-md-12 n-p-0">
                            <DrawMap
                                mapFor={"truck"}
                                consignercords={this.state.consigner_coordinates}
                                tolls={this.state.tolls}
                                consigneecords={this.state.consignee_coordinates}
                                rownode={this.state.rownode}
                                truckno={this.state.maptruckno}
                                dealer={this.state.dealer}
                                mapinfo={this.state.mapinfo}
                                defTransitCoords={this.state.defTransitCoords}
								mobileMap={1} 
								/>
							<div className="andriomap-pos" onClick={this.onClickShowDetails.bind(this)}>
								<i className="icofont icofont-dotted-up ico-color"></i>
							</div>
							
							<div className={"divpos zindex999 "+(this.state.inboxSlider)}>
								<div className={"andriomap-pos2 pt-10px "+(this.state.showsecond)} onClick={this.onClickHideAll.bind(this)}>
									<i className="icofont icofont-dotted-down ico-color"></i>
								</div>
								<div className="crm-numbers mt-40p prel pb-0">
									<div className="col-xl-12 col-lg-12 textAlignCenter row">
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Truck No</label>
											<div>{this.state.routeTruck.truck_no}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Start Time</label>
											<div>{(this.state.routeTruck.startTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
										</div>
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">End Time</label>
											<div>{(this.state.routeTruck.endTime != '')?getHyphenYYYYMMDDHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">GPS Distance</label>
											<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
										</div>
										{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
										:
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Google Distance</label>
											<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
										</div>
										}
										{(this.state.iscustomer != 1)?
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Tracking Type</label>
											{(this.state.map_type_of_tracking == 1)?
											<div>GPS Tracking</div>
											:
											<div>SIM Tracking</div>
											}
										</div> 
										:""}
									</div>
								</div>
							</div>
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div>
							{/* <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
					
					 </div>
					
					:''}
							
			</div>
        )
    }
}
