import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import Modal from 'react-responsive-modal';
import Viewbillaction from "./viewbillaction";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class BillingDashboard extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                Viewbillaction:Viewbillaction
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            billingtrucks:[],
            pagerest:0,
            approveby:"",
            approvedon:"",
            hidebuttons:0,
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false,
            reason:'',
            approveenreason:'',
            revertreason:'',
            approveitemreason:'',
            disapprovereason:'',
            dispproveitem:"",
            approveitem:"",
            billingRowData:"",
            screen:'',
            msilapprover:'',
            revertapprover:'',
            acbtntrucks:"btn-danger",
            acbtntrips:"btn-default",
            columnstrucks:1,
            columnstrips:0,
            truckslist:[],
            rowTripData:[],
            rowTruckData:[]
        }
    
       this.onLoadBillingData = this.onLoadBillingData.bind(this);
       this.onClickGenerateBill = this.onClickGenerateBill.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var mm = moment.parseZone().subtract(1, 'months').format("MM")
        var mmm = moment.parseZone().subtract(1, 'months').format("MMM")
        var month = [{value:mm,label:mmm}]
        var yy = moment.parseZone().subtract(1, 'months').format("YYYY")
        var year = [{value:yy, label:yy}]
        
        // months start at index 0 in momentjs, so we subtract 1
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
        var screen = ''
        if(this.props.match.path == "/billingdashboard")
		{
            var deptcode='all';
            var pageTitle="";
            
        }

        if(this.props.match.path == "/sndbillingdashboard")
		{
            var deptcode='SNDG';
            var pageTitle="Sales and Dispatch";
            screen="primary";
        }

        // if(this.props.match.path == "/sndbillingdashboard")
		// {
        //     var deptcode='SNDG';
        //     var pageTitle="Sales and Dispatch -  Secondary";
        //     screen="secondary";
        // }

        if(this.props.match.path == "/prtbillingdashboard")
		{
            var deptcode='LOG-PRT';
            var pageTitle="Spare Parts";
        }
        if(this.props.match.path == "/tnpbillingdashboard")
		{
            var deptcode='LOG-TNP';
            var pageTitle="Train and Production";
        }
       
        this.setState({
            dept_code:deptcode,
            pageTitle:pageTitle,
            month:month,
            year:year,
            startDate:startDate,
            endDate:endDate,
            screen:screen
        })
        var parameters = {
            dept_code:deptcode,
            startDate:startDate,
            endDate:endDate,
            screen:screen
        }
        this.onLoadBillingData()
    }

    onLoadBillingData(){

        this.setState({
            loadshow:'show-m',
            //overly:'show-m'
        })
        
        redirectURL.post("/consignments/allbillinguniquetrucks")
        .then((response) => {
            console.log("response.data ", response.data)
            this.setState({
                rowData:response.data,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
        .catch(function(e){

        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
    
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	    this.setState({ 
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false, 
        });
     };
    
    onClickGenerateBill(params)
    {
        console.log("params ", params)
    }

    render(){
        const { open } = this.state;
        var columnwithDefs = [
            {
                headerName: "",
                width:120,
                // cellRendererSelector:function(params){                    
				// 	var rendComponent = {
				// 		component: 'Viewbillaction'
				// 	};
				// 	return rendComponent
					
                // }
                cellRenderer: function(params) {
                    var month = params.data.rowmonth;
                    var year = params.data.rowyear;
                    return '<a href="/viewbillaction?month='+month+'&year='+year+'" target="_blank" class="btn btn-warning f12" style="padding:0px 10px"  rel="noopener">View Bill</a>'
                }
            },
            {
                headerName:"Month",
                field:"monthname",
                width:120
            },
            {
                headerName:"Total Unique Trucks",
                field:"totuniquetrucks",
                width:120
            },
            {
                headerName:"Total Approved Trucks",
                field:"totapprovedtrucks",
                width:120
            },
            {
                headerName:"Month",
                field:"monthname",
                width:120
            },
            {
				headerName: "Sales and Dispatch - Primary",
				headerClass:["cellstylegridG"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["cellstylegridG"],
                        field:"primaryunique",
                        width:120,
                        cellClass:["cellstylegridG"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["cellstylegridG"],
                        field:"approvedtruckscnt",
                        width:120,
                        cellClass:["cellstylegridG"]
                    }
                ]
            },
            {
				headerName: "Sales and Dispatch - Secondary",
				headerClass:["cellstylegridY"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["cellstylegridY"],
                        field:"secondaryunique",
                        width:120,
                        cellClass:["cellstylegridY"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["cellstylegridY"],
                        field:"secondaryapproved",
                        width:120,
                        cellClass:["cellstylegridY"]
                    }
                ]
            },
            {
				headerName: "Spare Parts",
				headerClass:["bgColorDangerLight"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["bgColorDangerLight"],
                        field:"prtunique",
                        width:120,
                        cellClass:["bgColorDangerLight"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["bgColorDangerLight"],
                        field:"prtapproved",
                        width:120,
                        cellClass:["bgColorDangerLight"]
                    }
                ]
            },
            {
				headerName: "Train and Production",
				headerClass:["cellstylegridM"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["cellstylegridM"],
                        field:"tnpunique",
                        width:120,
                        cellClass:["cellstylegridM"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["cellstylegridM"],
                        field:"tnpapproved",
                        width:120,
                        cellClass:["cellstylegridM"]
                    }
                ]
            },
            {
				headerName: "Imports",
				headerClass:["cellstylegridSk"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["cellstylegridSk"],
                        field:"scimportsunique",
                        width:120,
                        cellClass:["cellstylegridSk"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["cellstylegridSk"],
                        field:"scimportsapproved",
                        width:120,
                        cellClass:["cellstylegridSk"]
                    }
                ]
            },
            {
				headerName: "Exports",
				headerClass:["cellstylegridG"],
				children: [	
					{
                        headerName:"Unique Trucks",
                        headerClass:["cellstylegridG"],
                        field:"scexportsunique",
                        width:120,
                        cellClass:["cellstylegridG"]
                    },
                    {
                        headerName:"Approved Trucks",
                        headerClass:["cellstylegridG"],
                        field:"scexportsapproved",
                        width:120,
                        cellClass:["cellstylegridG"]
                    }
                ]
            }
            
        ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Billing Dashboard {this.state.pageTitle}</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                {/* <div className="row">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Month: </label>
                                        <Select 
                                            placeholder={"All"} 
                                          //  isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.month} 
                                            className="border-radius-0" 
                                            onChange={this.changeArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.monthoptions()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Year: </label>
                                        <Select 
                                            placeholder={"All"}  
                                            //isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.year} 
                                            className="border-radius-0" 
                                            onChange={this.changeYearArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.yearoptions()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.onClickShowFilterData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                </div> */}


                                
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}
                                        
                                    />
                                        
                                       
                                    </div> 
                                    <div className="col-xl-4 col-lg-4">
                                        {/* {(this.state.approveby != "" || this.state.approvedon != "")?
                                            <span>
                                                Approved By: <span style={{fontWeight:"600"}}>{this.state.approveby}</span>
                                                <br />Approved On: <span style={{fontWeight:"600"}}>{getHyphenDDMMMYYYYHHMM(this.state.approvedon)}</span>
                                            </span>
                                        :""} */}
                                        
                                    </div>
                                   
                                       
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 