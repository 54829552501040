import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
export default class SecurityDashboard extends Component {
  constructor(props) {
    super(props);
    this.displayData = [];
    this.state = {
      loadshow:'show-n',
      alert:null,
      show: false,
      basicTitle:'',
      basicType:"default",
      show1: false,
      basicTitle1:'',
      basicType1:"default",
    }
  }

  render() {
    return (
      <div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
          <SweetAlert
            show={this.state.show1}
            type={this.state.basicType1}
            title={this.state.basicTitle1}
            onConfirm={this.closeAlert1}
        >
        </SweetAlert>
          {this.state.alert}
    
          <div className="row">
                
            <div className="col-xl-3 col-lg-3">                    
              <div className="card overflw-h">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-g">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                        <div className="animation"><i className="icon-truck"></i></div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-r">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-r">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>

    
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32  envcard-g-2">
                          <span className="circle-border-g"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g-2a">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32  envcard-r-2">
                        <span className="circle-border-r"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-r-2a">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>

    
    
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32  envcard-g-2">
                          <span className="circle-border-g-pos"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32  envcard-r-2">
                        <span className="circle-border-r-pos"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-r-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>


    
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32  envcard-g-nbg">
                          <span className="circle-border-g"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body fbold envcard-b-g-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header overflw-h n-p-0 posrel">
                      <h5 className="f32  envcard-g-nbg">
                        <span className="circle-border-r"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body fbold envcard-b-r-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">                    
              <div className="card" style={{overflow:"hidden"}}>
                  <div className="card-header posrel overflw-h n-p-0" style={{overflow:"hidden"}}>
                      <h5 className="f32  envcard-g-nbg">
                          <span className="circle-border-g"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                      <div className="waveg"></div>
                  </div>
                  <div className="card-body envcard-b-g-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>

                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header overflw-h n-p-0 posrel">
                      <h5 className="f32  envcard-g-nbg">
                        <span className="circle-border-r"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                      <div className="wave2"></div>
                  </div>
                  <div className="card-body envcard-b-r-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      {/* <div className="wave"></div> */}
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">                    
              <div className="card" style={{overflow:"hidden"}}>
                  <div className="card-header  overflw-h  n-p-0" style={{overflow:"hidden"}}>
                      <h5 className="f32  envcard-g-nbg">
                          <span className="circle-border-g"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                      <div className="waveg"></div>
                  </div>
                  <div className="card-body fbold envcard-b-g-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>

                      </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3">                    
              <div className="card" style={{overflow:"hidden"}}>
                  <div className="card-header n-p-0 posrel">
                      <h5 className="f32  envcard-g-nbg">
                        <span className="circle-border-r"><i className="icon-truck"></i></span>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                      <div className="wave2"></div>
                  </div>
                  <div className="card-body fbold envcard-b-r-2">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      {/* <div className="wave"></div> */}
                      </div>
                  </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-g">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-g">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>

    
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-g">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-r">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-r">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
    
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-g">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-g">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col-xl-3 col-lg-3">                    
              <div className="card">
                  <div className="card-header n-p-0">
                      <h5 className="f32 textAlignCenter envcard-r">
                          <i className="icon-truck"></i>&nbsp;&nbsp;<span> TS07EN8887</span>
                      </h5>
                  </div>
                  <div className="card-body envcard-b-r">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Shipment Code:</div>
                            <div className="col-sm-7">SHP9000009889</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Driver Name:</div>
                            <div className="col-sm-7">Test Driver</div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-5">Mobile Number:</div>
                            <div className="col-sm-7">09998898898</div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>


          </div>
        </div>
    )
  }
}
