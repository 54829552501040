
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const RemovePickDate = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickRemovePickupdate(props);
    };
    
    return (
        <div>
    		<button onClick={handleOverspeedClick} className={"custom-btn f12 label label-success "} title="Remove" style={{color:"#ff0000"}}>Remove</button>
        </div>
    );
};

export default RemovePickDate;
