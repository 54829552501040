import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import AssignRoles from "./assignmenustorolescheckbox";
import Check from './checkboxComponent';
import  CheckBox  from './checkbox'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
var redirectURL = require('../redirectURL');
var selectpath = '';

var selectedmenus = []
export default class AdminRoleMenus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            allmenus:[],
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            allmenustree:[],
            submenuitems:[],
            
            allroles:[], 
            childconfs: [],
            childrow:[],
            selectedmenus : [],
            checked: [],
            expanded: [],
            role_name:'',
            responsemessage:'',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            rowSelection:"single",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
               
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            
        }
        this.getRoleMenus = this.getRoleMenus.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
    }
    componentDidMount(){
        redirectURL.post("/superadmin/roles")
        .then( async (response) => {
        
            this.setState({
                allroles:response.data,
                //loadshow:'show-n'
            });
         
        })
        .catch(function(e){
            console.log(e)
        })

        this.setState({
            loadshow:'show-m'
        });
        redirectURL.post("/superadmin/allmenus")
        .then( async (response) => {
            console.log("Menus ",response.data);
            var menusarr = [];
            var menus = response.data;
            var gridmenus= []
            if(menus.length > 0)
            {
                menus.map((item) => {
                    var chid=[]
                    if(item.children.length > 0)
                    {
                        
                        item.children.map((mn) => {
                            if(mn.children.length > 0)
                            {
                                var sublevelchild = []
                                mn.children.map((sublevel) => {
                                   // sublevelchild.push({"value":sublevel.code+"-"+sublevel.parent_code, "label":sublevel.name})
                                   sublevelchild.push({"value":sublevel.code, "label":sublevel.name})
                                //    if(sublevel.parent_code != null && sublevel.path != null)
                                //    {
                                        gridmenus.push({
                                            "code":sublevel.code,
                                            "name":sublevel.name,
                                            "path":sublevel.path
                                        })
                                //    }
                                   
                                })
                            }
                            // if(mn.parent_code != null && mn.path != null)
                            // {
                                gridmenus.push({
                                    "code":mn.code,
                                    "name":mn.name,
                                    "path":mn.path
                                })
                            // }
                          // chid.push({"value":mn.code+"-"+mn.parent_code, "label":mn.name, "children":sublevelchild})
                            chid.push({"value":mn.code, "label":mn.name, "children":sublevelchild})
                        })
                    }
                    // if(item.parent_code != null && item.path != null)
                    // {
                        gridmenus.push({
                            "code":item.code,
                            "name":item.name,
                            "path":item.path
                        })
                    // }
                    menusarr.push({"value":item.code, "label":item.name, "children":chid})
                })
            }
            //console.log("gridmenus ", gridmenus)
            //  console.log("menusarr ", JSON.stringify(menusarr))
                this.setState({
                    allmenustree:menusarr,
                    allmenus:menus,
                    rowData:gridmenus,
                    loadshow:'show-n'
                });
         
        })
        .catch(function(e){
            console.log(e)
        })
        
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    onShowRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-m',
            updateForm:'show-n'

        });
    }
    onShowUpdateRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-n',
            updateForm:'show-m'

        });
    }
    formHandler = async (event) =>{
        event.preventDefault()
        //console.log("selectedmenus ", this.state.selectedmenus)
        var rowCount = this.gridApi.getSelectedNodes();
        //onsole.log("rowCount ", rowCount)
        
        try{
            await redirectURL.post("/superadmin/getMenuPath", {
                menu_code:rowCount[0].data.code
            })
            .then((response) => {
                //console.log("response ", response.data)
                selectpath=response.data[0].path
            })
            .catch(function(e){

            })
            var mcode = rowCount[0].data.code;
        }
        catch(e){
            selectpath=''
            var mcode = ''
        }

        var formdata = {
            role_name:this.state.role_name.value,
            menus:this.state.checked,
            dashboard_url:selectpath,
            menu_code:mcode
        }

        await redirectURL.post("/superadmin/navigationmenus", formdata)
        .then( async (response) =>{
            var cmenus = response.data;
            //console.log("childmenus", cmenus)
            var allcmenus = []
            await cmenus.map((mn) => {
                allcmenus.push(mn.parent_code)
                selectedmenus.push(mn.code)
            })
            await redirectURL.post("/superadmin/navigationparentmenus", {menus:allcmenus})
            .then(async (response) =>{
                var pmenus = response.data;
                //console.log("parentmenus", pmenus)
                var allpmenus = []
                await pmenus.map((mnc) => {
                    
                    selectedmenus.push(mnc.code)
                    if(mnc.parent_code != '')
                    {
                        allpmenus.push(mnc.parent_code)
                        selectedmenus.push(mnc.parent_code)
                    }
                })
                //console.log("allpmenus ", allpmenus)
            })

        })
        
        
        //console.log("all selectedmenus ",selectedmenus)
        if(selectedmenus.length > 0)
        {
            var parameters = {
                role_name:this.state.role_name.value,
                menus:selectedmenus,
                dashboard_url:selectpath,
                menu_code:mcode
            }
            redirectURL.post("/superadmin/assingmenutorole", parameters)
            .then((response) => {
                 if(response.data.nModified == 1)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully assigne menus to "+this.state.role_name.value+" role",
                        basicType:"success",
                        responsemessage:"Successfully assigne menus to "+this.state.role_name.value+" role"
                    });
                   
                }
                
                this.setState({
                    loadshow:'show-n',
                });
            })
        }
       
		
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
           
    //     //    this.gridApi.forEachLeafNode( (node) => {
    //     //         if (node.data.id === roleId) {
    //     //             node.setSelected(true);
    //     //         }
    //     //     });
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
	handlerForm = async role_name => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ role_name },
			() => console.log(`Option selected:`, this.state.role_name)
          );
        await this.getRoleMenus()
    }
    onCheck(checked) {
        this.setState({ checked });
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }

    getRoleMenus = async () => {
        //console.log("role ", this.state.role_name.value)
          var reqdata = {
              role_name:this.state.role_name.value
            }
          await redirectURL.post("/superadmin/defaultrolemenus",reqdata)
          .then( async (response) => {
              //console.log("Orignal ", response.data)
              if(response.data.length > 0)
              {
                var rolesmenus = response.data[0].menus;
                
                this.gridApi.forEachLeafNode( (node) => {
                    if (node.data.code === response.data[0].menu_code) {
                        node.setSelected(true);
                    }
                });
                this.setState({
                    checked:rolesmenus,
                    expanded:rolesmenus
                });
                //this.onCheck(rolesmenus)
              }
              
          })
          .catch(function(e){
              console.log(e);
          })
          
    }
    onClickMenu(event){
        console.log("event ", event.target.value)
    }

	selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.allroles.forEach(item => {
            var rname = item.role_name;
            var role = rname.toUpperCase();
            items.push({"value":item.role_name,"label":role});	
        });
        return items;
    }
    handleCheckChieldElement = (event) => {
        let allmenus = this.state.allmenus
        allmenus.forEach(menu => {
            if (menu.code === event.target.value)
            {
                menu.isChecked =  event.target.checked
                if(menu.isChecked == true)
                {
                    console.log(menu.code)
                }
            }
        })
        this.setState({allmenus: allmenus})
      }
    
    handleCheckSubMenuElement = (event) => {
        let submenuitems = this.state.submenuitems
        submenuitems.forEach(submenu => {
           if (submenu.code === event.target.value)
           submenu.isChecked =  event.target.checked
        })
        this.setState({submenuitems: submenuitems})
    }
    
    onSelectedCheckedItem(){
        console.log(this.state.checked)
    }
    // onRowSelected(event) {
    //     event.api.deselectAll();  
    //     var rowCount = event.api.getSelectedNodes();
    //      console.log("rowCount ", rowCount)
	    
	// }
    
    
    
    render(){
        const { checked, expanded } = this.state;
        //console.log("expanded parent ",this.state.expanded)
       var allparentmenus = [];
    //    this.state.allmenus.map((menu) => {
    //         if(menu.parent_code == null)
    //         {
    //             var pcode = menu.code;
    //             allparentmenus.push({"code":pcode, "name":menu.name}) 
    //         }
                
    //     });

        var columnDefs = [
            {
                headerName:"Code",
                field:"code",
                width:180,
                filter:true,
                headerCheckboxSelection: false,
                checkboxSelection: true
            },
            {
                headerName:"Landing Page",
                field:"name",
                width:250,
                filter:true,
                valueGetter:function(params){
                    return params.data.name+" ( "+ params.data.code +" )";
                }
            },

            // {
            //     headerName:"Page URL",
            //     field:"path",
            //     width:200,
            //     filter:true
            // }
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>Assign Roles </span>
                                       <a href="/superadmin/roles" className="btn btn-default ml-5p float-right">Back</a>
                                </h5>
				   			</div>
				   			<div className="card-body row">
                               
                                <div className=" col-xl-12 col-lg-12">
                                    {this.state.responsemessage != ''?
                                    <div className="alert alert-success">
                                        {this.state.responsemessage}
                                    </div>
                                    :""}
                                    <form className={" theme-form row col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                                        <div className="row col-xl-12 col-lg-12">
                                            <div className="form-group  col-xl-3 col-lg-3">
                                                <label className="c-lbl">Select Role *:</label>
                                                <Select name="role_name" placeholder="Select Role" className="rselect" value={this.state.role_name} onChange={this.handlerForm.bind(this)} options={this.selectOptionsItems()} />
                                            </div>
                                        </div>
                                    {/* {(this.state.role_name != '')?
                                      <AssignRoles
                                        nodes={this.state.allmenustree}
                                        sendchecked={this.state.checked}
                                        sendexpanded={this.state.expanded}
                                        rolename={this.state.role_name}
                                    />
                                    
                                    :""} */}
                                       {/*  <CheckboxTree
                                            nodes={this.state.allmenustree}
                                            checked={checked}
                                            expanded={expanded}
                                            onCheck={this.onCheck}
                                            onExpand={this.onExpand}
                                            icons={{
                                                check: <span className="rct-icon rct-icon-check" />,
                                                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                leaf: <span className="rct-icon rct-icon-leaf" />,
                                            }}
                                        /> */}
                                    <div className=" col-xl-6 col-lg-6">
                                        <CheckboxTree
                                            nodes={this.state.allmenustree}
                                            checked={this.state.checked}
                                            expanded={this.state.expanded}
                                            onCheck={checked => this.setState({ checked })}
                                            onExpand={expanded => this.setState({ expanded })}
                                            onClick={selectedmenus => this.setState({selectedmenus})}
                                        />
                                    </div>
                                    <div className=" col-xl-6 col-lg-6">
                                        <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                enableRangeSelection={false}
                                                //onRowSelected={this.onRowSelected.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                suppressCellFlash={true}
                                                masterDetail={true}
                                                
                                            />
                                        </div>
                                    </div>
                                        <button type="submit" className="btn btn-success">Assign</button>
                                    </form>

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}






/*Example Checkbox tree Code*/
/*
import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import Select from 'react-select';

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

const nodes = [{
    value: 'mars',
    label: 'Mars',
    children: [
        { value: 'phobos', label: 'Phobos' },
        { value: 'deimos', label: 'Deimos' },
    ],
},
{
    value: 'mars1',
    label: 'Mars1',
    children: [
        { value: 'phobos1', label: 'Phobos1' },
        { value: 'deimos1', label: 'Deimos1' },
    ],
},
{
    value: 'mars2',
    label: 'Mars2',
    children: [
        { value: 'phobos2', label: 'Phobos2' },
        { value: 'deimos2', label: 'Deimos2' },
    ],
},
{
    value: 'mars3',
    label: 'Mars3',
    children: [
        { value: 'phobos3', label: 'Phobos3' },
        { value: 'deimos3', label: 'Deimos3' },
    ],
},{
    value: 'mars4',
    label: 'Mars4',
    children: [
        { value: 'phobos4', label: 'Phobos4' },
        { value: 'deimos4', label: 'Deimos4' },
    ],
}];

export default class AdminRoleMenus extends React.Component {
    state = {
        checked: [ 'phobos','phobos2','deimos3','mars2','mars4'],
        expanded: [ 'phobos','phobos2','deimos3','mars2','mars4','mars'],
        allroles:[]
    };
    componentDidMount(){
        redirectURL.post("/superadmin/roles")
        .then( async (response) => {
            var roles = response.data;
            this.setState({
                allroles:roles
            });
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    render() {
        return (
            <CheckboxTree
                nodes={nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={checked => this.setState({ checked })}
                onExpand={expanded => this.setState({ expanded })}
            />
        );
    }
}
*/