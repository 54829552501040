import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var redirectURL = require('../redirectURL');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class AndriodMapCurrentLocationWeb extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			open1: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			rowBreakData:[],
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
          returnTripsDashboardRowData: [],
          route_details:[],
          alert:null,
        show: false,
        basicTitle:'',
        basicType:"default",
        truck_no:"",
        device_id:''
		};
        this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this);
        this.onLOadMap = this.onLOadMap.bind(this);
	}
	componentDidMount(){
        var truck_no = this.props.truck_no;
        var device_id = this.props.device_id;
        this.setState({
            truck_no:this.props.truck_no,
            device_id:this.props.device_id,
        })
        var parameters = {
            truck_no: truck_no,
            device_id:device_id
		}
        this.onLOadMap(parameters);
        
    }
    componentWillReceiveProps(newprops)
    {
        // if(newprops.truck_no != this.state.truck_no || newprops.device_id != this.state.device_id)
        // {
            var truck_no = newprops.truck_no;
            var device_id = newprops.device_id;
            this.setState({
                truck_no:newprops.truck_no,
                device_id:newprops.device_id,
            })
            var parameters = {
                truck_no: truck_no,
                device_id:device_id
            }
            this.onLOadMap(parameters);
        // }
        
    }
	onLOadMap(parameters){
        
		redirectURL.post("/dashboard/mobiledevicetrucklocation",parameters)
		.then((response) => {
			console.log("response ", response.data)
            // console.log("records ", response.data.records)
            var records = response.data.records;
			if(response.data.status == "success")
			{
				var mapinfo = {
                    coords:[{
                        lat:records.latitude,
                        lng:records.longitude,
                    }],
                    route_details:{
                        address:records.gps_address[0].formatted_address
                    }
                }
                this.setState({
                    mapinfo:mapinfo
                })
                this.renderMap()
			}
			else
			{
				this.setState({
					show1:true,
					basicTitle1:"No data found",
					basicType1:"danger"
				})
			}
        })
    }
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
	onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi1 = params.columnApi;
	
		
	};
	

	onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
		   
		   
	}


plotMultiColorPolyline(arr2,map,bounds)
{
	if(arr2.length)
	{
		var data_type_list = [...new Set(arr2.map(item => item.type))];
		if(data_type_list.includes("transshipment"))
		{
			var data_type = "transshipment"
		}
		if(data_type_list.includes("rake"))
		{
			var data_type = "rake"
		}
		this.setState({
			data_type : data_type
		})
	}
	
    var icon_cnt = 1;
    var icon_divisible = 1;
	
	//console.log(arr2, "coords")
	
	for(var i=0;i< arr2.length-1;i++)
	{
		var locationLatLng = [];
		locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
		locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
		if(icon_cnt % icon_divisible == 0)
		{
			var lineicon = [{
						icon: {
								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								strokeColor:'#FF0000',
								fillColor:'#FF0000',
								fillOpacity:1,
								strokeWeight: 0.5
						},
						repeat:'100px',
						path:[]
					}];
		}else{lineicon=[]}
		icon_cnt = icon_cnt + 1
		var consignments_missing_route_line = new window.google.maps.Polyline({
			map:map,
			path : locationLatLng,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			//icons: lineicon
		});
		if(arr2[i].msgtype == "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
		}
		
		// if(arr2[i].set == undefined && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#157254"
		// 	})
		// }
		// else if(arr2[i].set == 1 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#157254"
		// 	})
		// }
		// else if(arr2[i].set == 2 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#0000ff",
		// 		strokeWeight: 5.5,
		// 	})
		// }
		// else if(arr2[i].set == 3 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#980000",
		// 		strokeWeight: 5.5,
		// 	})
        // }
        
        
		if(arr2[i].set == undefined)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 1)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 2)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#0000ff",
				strokeWeight: 2,
			})
		}
		else if(arr2[i].set == 3 && arr2[i].directly_to_dealer == 0)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#980000",
				strokeWeight: 2,
			})
        }
		else if(arr2[i].set == 3 && arr2[i].directly_to_dealer == 1)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
        }
		else if(arr2[i].set == 4)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
        }
        
		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
	}
}
plotBoundaryLines(boundaryarr){
	//console.log("boundaries ",boundaryarr)
	if(boundaryarr.length > 0)
	{
		
		boundaryarr.map((item) => {
			if(item.length>0)
			{
				var line=new window.google.maps.Polyline(
					{
						map:map,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5
					});
				for(var i =0; i<item.length;i++)
				{
					var locationLatLng = [];
					locationLatLng.push(new window.google.maps.LatLng(item[i].lat,item[i].lng));
					var consignments_missing_route_line = new window.google.maps.Polyline({
						map:map,
						path : locationLatLng,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						fillColor: '#1D9567',
						fillOpacity: 0.8
						//icons: lineicon
					});
					
					var path=line.getPath().getArray();								
					let latLng=new window.google.maps.LatLng(item[i].lat,item[i].lng);
					path.push(latLng);
					line.setPath(path);
				}


				// var geoFencingArea = new window.google.maps.Polygon({
				// 	//map:map,
				//   paths: item,
				//   editable: true,
				//   strokeColor: '#1D9567',
				//   strokeOpacity: 0.70,
				//   strokeWeight: 0,
				//   fillColor: '#1D9567',
				//   fillOpacity: 0.35,
				//   draggable:true
				  
				// });
				// geoFencingArea.setMap(map);
				// //console.log("test ",geoFencingArea.getPath());
				// //window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);
	  
				// window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
				// window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);
				


				
			}
		})
	}
}

initMap = () => 
{
	var currentwindow;
	var dealer='';
	var tolls = [];
	var chokepoints = [];
	this.setState({
		dealerCode:[]
	});
	var arr=[];
	
    var jsondata = this.state.mapinfo;
    // var locations = jsondata.breaks;
    var arr = jsondata.coords;
    // var plant = jsondata.additional_details.plant_coords;
    // var loading_yard = jsondata.additional_details.loading_yard_coords;
    // var rso = jsondata.additional_details.rso_coords;
    // var deliveryard = jsondata.additional_details.delivery_yard_coords;
        
    // coords_copy = arr;
    var routeArr = []
    // var routeinfo = this.state.route_details;
    // var dealer=jsondata.additional_details.dealer_coords;
    // console.log("jsondata ", jsondata)

	console.log("arr ",arr)
	if( arr == undefined)
	{
		var lt=28.4519751;
		var ln=77.0310713;
	}
	else
	{
		if(arr.length > 0 && typeof arr.length != undefined)
		{
            var lt=arr[0].lat;
            var ln=arr[0].lng;
		}
		else{
			
            var lt=28.4519751;
            var ln=77.0310713;
			
		}
	}

	var routeinfo ;

    routeinfo = jsondata.route_details;
	
	try{
		var mapOptions = {
			zoom: 8,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt,ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	catch(e){
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(28.4519751,77.0310713),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	try{
		map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
	}catch(e){}
	
	var bounds = new window.google.maps.LatLngBounds();
	bounds.extend(new window.google.maps.LatLng(lt,ln));
		// Create our info window content
	var currentinfowindow = null;
	var line=new window.google.maps.Polyline(
	{
		map:map,
		strokeColor: '#157254',
		strokeOpacity: 1.0,
		strokeWeight: 0.5,
		// icons: [{
		// 		icon: {
		// 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		// 				strokeColor:'#ff8c52',
		// 				fillColor:'#ff8c52',
		// 				fillOpacity:1,
		// 				strokeWeight: 0.5
		// 		},
		// 		repeat:'100px',
		// 		path:[]
		// 	}]
	});
	if(arr == undefined){

	}
	else{
		try{
			if(arr.length > 0 )
			{

				var allpoints = [];
				var arr1=[];
				
				var arr2=[];
				var darr=[];
				if(arr.length > 0)
				{
					
                    arr2 = arr
				}
					
                this.plotMultiColorPolyline(arr2,map,bounds)
                
                
                var infowindow = new window.google.maps.InfoWindow();
                var marker, l;
            
                // for (l = 0; l < locations.length; l++) {
                // marker = new window.google.maps.Marker({
                //     position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                //     icon:require('../../assets/icons/cf.png'),
                //     map: map,
                // });

                // window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                //         return function() {
                //         //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
                //         var contentarr = []
                //         var header = "Break - "+(l+1)
                //         contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
                //         contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)})
                //         contentarr.push({"key":"Break Duration", "value":timeConvert(locations[l].break_time_seconds)})
                //         var contentString = infoBox(marker.icon, header, contentarr)
                //         infowindow.setContent(contentString);
                //         currentwindow = infowindow;
                //         infowindow.open(map, marker);
                //         //console.log(marker.position.toJSON());
                //         //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                //         }
                //     })(marker, l));
                // }

                // view markers code
                markersArray = arr2;
                // try{
                //     /*Rail Loading Point*/
                //     //console.log("jsondata ",jsondata)
                //     var loadingpoint = loading_yard;
                //     // console.log("loadingpoint ",loadingpoint)
                //     var markerl = new window.google.maps.Marker({
                //         position: new window.google.maps.LatLng(loadingpoint.lat, loadingpoint.lng),
                //         icon:require('../../assets/icons/train.png'),
                //         map: map,	
                //     });

                //     window.google.maps.event.addListener(markerl, 'click', (function(markerl) {
                //         return function() {
                //             //	console.log(clusters[0])
                //             var contentarr = []
                        
                //         }
                //     })(markerl));
                //     /*END*/

                //     /*Rail Delivery Point*/

                //     var deliverpoint = deliveryard;
                //     var markerd = new window.google.maps.Marker({
                //         position: new window.google.maps.LatLng(deliverpoint.lat, deliverpoint.lng),
                //         icon:require('../../assets/icons/traindeliver.png'),
                //         map: map,	
                //     });
                //     window.google.maps.event.addListener(markerd, 'click', (function(markerd) {
                //         return function() {
                //             //	console.log(clusters[0])
                //             var contentarr = []
                //         }
                //     })(markerd));
                   
                // }
                // catch(e){
                //     console.log("Error ", e)
                // }
                
                    
                // try{
                //     /*DEALER*/
                //     if((dealer.lat != '' || dealer.lat != 0) && (dealer.lng != '' || dealer.lng != 0))
                //     {
                //         console.log(dealer.lat+" ==   "+ dealer.lng);
                //         marker = new window.google.maps.Marker({
                //             position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
                //             icon:require('../../assets/icons/customer_pin_38.png'),
                //             map: map,	
                //         });
                //         window.google.maps.event.addListener(marker, 'click', (function(marker) {
                //             return function() {
                //                 //	console.log(clusters[0])
                //                 var contentarr = []
                //             }
                //         })(marker));
                        
                //         var markerLatlng1;
                //         markerLatlng1 = new window.google.maps.LatLng(dealer.lat,dealer.lng);
                //         //console.log("markerLatlng", markerLatlng1)
                //         var circle = new window.google.maps.Circle({
                //             strokeColor: '#71caab',
                //             strokeOpacity: 0.7,
                //             strokeWeight: 0.5,
                //             fillColor: '#71caab',
                //             fillOpacity: 0.25,
                //             map: map,
                //             center: markerLatlng1,
                //             radius: 25000
                //         });
                        
                //     }
                // }
                // catch(e)
                // {
                //     console.log(e);
                // }
                
                

                /*START ICON*/
                //console.log(arr2);
                try{
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                    icon:require('../../assets/icons/track_start.png'),
                    map: map,	
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker) {
                    return function() {
                        //	console.log(clusters[0])
                        var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "Current Address"
                    contentarr.push({"key":"Address ", "value":routeinfo.address})
                    var contentString = infoBox(marker.icon, header, contentarr,'')
                    
                    
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                    }
                })(marker));
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
                    icon:require('../../assets/icons/truck-end.png'),
                    map: map,	
                });
                }
                catch(e){
                    console.log(e, "e")
                }
                        
                // window.google.maps.event.addListener(marker, 'click', (function(marker) {
                //         return function() {
                //             //	console.log(clusters[0])
                //             var contentarr = []
                //         //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                //         var header = "End Point"
                //         contentarr.push({"key":"End at ", "value":getDDMMYYYYHHMMSS(routeinfo.end_time)})
                //         var contentString = infoBox(marker.icon, header, contentarr)
                                
                //         infowindow.setContent(contentString);
                //         currentwindow = infowindow;
                //         infowindow.open(map, marker);
                //         // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                //         }
                //     })(marker));
            
                

                /*Google Routes Starts*/
                var groutepath = this.state.googleroutepath;
                if(groutepath.length > 0)
                {
                    var lineg=new window.google.maps.Polyline(
                    {
                        map:map,
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 0.5,
                        icons: [{
                                icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        strokeColor:'#FF0000',
                                        fillColor:'#FF0000',
                                        fillOpacity:1,
                                        strokeWeight: 0.5
                                },
                                repeat:'100px',
                                path:[]
                            }]
                        });
                        console.log("groutepath ", groutepath)
                        for (let g = 0; g < groutepath.length; g++) {
                            var pathg=lineg.getPath().getArray();
                                
                            let latLng=new window.google.maps.LatLng(groutepath[g].lat,groutepath[g].lng);
                            pathg.push(latLng);
                            lineg.setPath(pathg);
                            //console.log("path ", pathg)
                            //Change line color based on map type
                        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
                                            
                                var x = map.getZoom();
                                var c = map.getCenter();
                                window.google.maps.event.trigger(map, 'resize');
                                map.setZoom(x);
                                map.setCenter(c);
                                
                                var pathg=lineg.getPath().getArray();
                            let latLng=new window.google.maps.LatLng(groutepath[g].lat,groutepath[g].lng);
                            pathg.push(latLng);
                            lineg.setPath(pathg);
                        } );
                        
                        bounds.extend(new window.google.maps.LatLng(groutepath[g].lat,groutepath[g].lng));
                    }
                }
                
			}
		}
		catch(e){

		}
		map.fitBounds(bounds)
	}
	try{
		if(tolls.length > 0)
		{
			var infowindow = new window.google.maps.InfoWindow();
			var l;
			tolls.map(function(e,index){
				var tollMarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(e.lat, e.lon),
					icon:require('../../assets/icons/barrier.png'),
					map: map,
				});
				window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
					return function() 
					{
						var contentarr = []
						var header = "Toll - "+e.name
						contentarr.push({"key":"Address", "value":e.location})
						var contentString = infoBox(tollMarker.icon, header, contentarr)
						infowindow.setContent(contentString);
						var currentwindow = infowindow;
						infowindow.open(map, tollMarker);
					}
				})(tollMarker, index));
				window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
					infowindow.close();
					});
			})  
			
		}
		map.fitBounds(bounds)
	}catch(e){}

	
	try{
		if(chokepoints.length > 0)
		{
			var infowindow = new window.google.maps.InfoWindow();
			var l;
			chokepoints = chokepoints.filter(function(e){
				return e.qualified_to_display == 1
			})
			chokepoints.map(function(e,index){

				var tollMarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(e.lat, e.lng),
					icon:require('../../assets/icons/cf.png'),
					map: map,
				});
				window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
					return function() 
					{
						var contentarr = []
						var header = "Chokepoint"
						contentarr.push({"key":"Avg. Time Waiting ", "value":e.avg_break_time_text})
						// avg_break_time_text
						if(e.near_state_border != undefined)
						{
							contentarr.push({"key":"State Border ", "value":e.state_border_name})
							contentarr.push({"key":"Choke Point Type", "value": "State Border"})
							contentarr.push({"key":"Distance From State Border ", "value":e.distance_from_state_border})

						}
						if(e.near_toll != undefined)
						{
							contentarr.push({"key": "toll_name", "value": e.toll_name})
							contentarr.push({"key":"Choke Point Type", "value": "Toll"})	
						}
						var contentString = infoBox(tollMarker.icon, header, contentarr)
						infowindow.setContent(contentString);
						var currentwindow = infowindow;
						infowindow.open(map, tollMarker);
					}
				})(tollMarker, index));
				window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
					infowindow.close();
				});
			})  
			
		}
		map.fitBounds(bounds)
	}catch(e){}
	
	
}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false, open1:false });
	 };

	onShowTimelines(){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		if(this.state.viewtimelinemarkers === true)
		{			
			var arr=[];
            var jsondata = this.state.mapinfo;
            var locations = jsondata.breaks;
            var arr = jsondata.coords;
			
			if(arr.length > 0)
			{

				var allpoints = [];
				var darr = [];
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				} );
				if(darr.length < 50)
				{
					var ratio = (arr.length/5);
				}
				else if(darr.length > 50 && arr.length <200)
				{
					var ratio = 5;
				}
				else if(darr.length > 200 && darr.length <400)
				{
					var ratio = 20;
				}
				else{
					var ratio = 100;
				}
				var arr2=[]
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );

				this.setState({
					timelinesmarkers:arr2
				})
			}
		}
		else{
			this.setState({
				timelinesmarkers:[]
			})
		}
			this.renderMap()
		
	}
	onViewTimelines(e){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e){
    
        this.setState({
            loadshow:'show-m'
        })
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var coordinates = jsondata.coords;
        
        var dataarr=[]
        //console.log("coordinates ", coordinates)
        coordinates.map((item) => {
            dataarr.push({
                "truck_no":item.truck_no, 
                "lat":item.lat, 
                "lng":item.lng, 
                "speed":item.speed, 
                "stime":item.stime,
                "dist_from_prev_point":item.dist_from_prev_point, 
                "received_on":item.received_on, 
                "time_from_prev_point":item.time_from_prev_point,
                "truck_type":item.truck_type
            })
        });
        this.setState({
            rowData:dataarr,
            open:true,
            loadshow:'show-n'
        })
		
	}
 
	onShowBreakGridPop(e){
		
        this.setState({
            loadshow:'show-m'
        })
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var coordinates = jsondata.coords;
        var dataarr=[]
        console.log("locations ", locations)
        locations.map((item) => {
            dataarr.push({
                "break_start":item.break_start, 
                "break_end":item.break_end, 
                "break_time_seconds":item.break_time_seconds,
                "lat":item.lat, 
                "lng":item.lng
            })
        });
        this.setState({
            rowBreakData:dataarr,
            open1:true,
            loadshow:'show-n'
        })
		
	}
 
	onShowGoogleRoutes(){
		this.setState({
			viewgoogleroutes:!this.state.viewgoogleroutes
		})
		if(this.state.viewgoogleroutes == true)
		{			
			
		}
		else{
			this.setState({
				googleroutepath:[],
				viewgoogleroutes:!this.state.viewgoogleroutes
			})
		}
			
		this.renderMap()
	}

	showMarkers(event){
		if(event.target.value == 0)
		{
			this.setState({
				showMarkers : 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			var infowindow = new window.google.maps.InfoWindow();
			if(markersArray.length > 0)
			{
				var stepby = Math.round(markersArray.length * 0.007)
				// var counter = ( stepby > 0)?stepby:1;
				// var incrementby = counter
				var counter = 1;
				var incrementby = 1;
				console.log(markersArray.length,counter);
				while(counter < markersArray.length)
				{
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
						icon: {
							path: window.google.maps.SymbolPath.CIRCLE,
							strokeColor:'#157254',
							fillColor:'#157254',
							fillOpacity:1,
								strokeWeight: 5,
								scale:1,
							},
						map: map,
						content:markersArray[counter]
					});
					// marker.setVisible(false);
					window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
						return function() {
							//console.log("Arr ",marker)
							var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Current Status"
						contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
						contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
						
						var contentString = infoBox("", header, contentarr,'')
						
						
						infowindow.setContent(contentString);
						infowindow.open(map, marker);
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					window.google.maps.event.addListener(marker, 'mouseout', function() {
						infowindow.close();
					});
	
					viewMarkersArr.push(marker);
					counter = counter + incrementby;
					console.log("counter",counter)
				}
				// for(var a=0;a<markersArray.length;a++)
				// {
					
				// }
			}
			
		}
		else
		{
			this.setState({
				showMarkers : 0
			});
			viewMarkersArr.map(function(e){
				e.setVisible(false);
			});
		}
	}

setRouteDeviationPathVisibility(routeno){
	routeno = routeno+1
	console.log(route_deviation_polyline_dict[routeno])
	if(route_deviation_polyline_dict[routeno].getVisible())
	{
		route_deviation_polyline_dict[routeno].setVisible(false)
	}
	else{
		route_deviation_polyline_dict[routeno].setVisible(true)
	}
}
onRTDashboardRowClicked(row){
	var breaklocations = [];
	var bounds = new window.google.maps.LatLngBounds();
	row.data.break_clusters.map(function(e){
		bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
	})
	map.fitBounds(bounds)
}
render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  const { open1 } =this.state;
	  const columnwithDefs=[
			{
				headerName: "Trailer/GPS Device",
				field: "truck_no",
				width: 140,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lat.toFixed(6);
				}
				
			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lng.toFixed(6);
				}
				
			},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Packet Time",
				field: "stime",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.stime);
			   }
				// valueGetter:function(params){
				// 	console.log(params);
				// 	return params.data.stime;
				// }
				
			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				filter: true,
				resizable: true,
				 valueGetter:function(params){
				 	//console.log(params);
				 	return getDDMMYYYYHHMMSS(params.data.received_on);
				}
				
			},
			{
				headerName:"Type",
				field:"truck_type",
				width:120,
				filter: true,
				resizable: true,
			}
	  ]
	  
	  const columnwithBreakDefs=[
		{
			headerName: "Start Time",
			field: "break_start",
			width: 140,
			filter: true,
			resizable: true
			
		},
		{
			headerName: "End Time",
			field: "break_end",
			width: 140,
			filter: true,
			resizable: true
			
		},
		{
			headerName: "Break Time",
			field: "break_time_seconds",
			width: 180,
			filter: true,
			resizable: true,
			valueGetter:function(params){
				try{
					if(params.data.break_time_seconds != "" && params.data.break_time_seconds != undefined)
					{
						return secondsToString(params.data.break_time_seconds)
					}
					else
					{
						return "";
					}
				}
				catch(e){
					return "";
				}
			}
			
		},
		{
			headerName: "Latitude",
			field: "lat",
			width: 120,
			filter: true,
			resizable: true,
			valueGetter:function(params){
				return params.data.lat.toFixed(6);
			}
			
		},
		{
			headerName: "Longitude",
			field: "lng",
			width: 120,
			filter: true,
			resizable: true,
			valueGetter:function(params){
				return params.data.lng.toFixed(6);
			}
			
		}
  	]

    return (
           
			<div className="" style={{padding:"0px"}}>
				<SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                    {this.state.alert}
                {/* <ul className="float-right">
                    
                    <li className="float-left">
                        <input type="checkbox" name="viewtimelinemarkers" value={this.state.showMarkers} onClick={this.showMarkers.bind(this)} /> <span style={{fontSize:"14px"}}>View Markers</span>
                    </li>
					<li className="float-right">
						<button type="button" className="btn btn-success btn-xs float-right" onClick={this.onShowGridPop.bind(this)}> <span style={{fontSize:"14px"}}> <i className="icofont icofont-download-alt"></i> Download Data</span></button>
					</li>
					<li className="float-right" style={{marginRight:"10px",marginLeft:"10px"}}>
						<button type="button" className="btn btn-danger btn-xs float-right"  onClick={this.onShowBreakGridPop.bind(this)}> <span style={{fontSize:"14px"}}> <i className="icofont icofont-download-alt"></i> View Breaks</span></button>
					</li>
                </ul>
             */}

				{/* map div  */}
				
{/* 				
                <span>
                    <i class="fa fa-minus fa-2x" style={{color:"#157254",}}></i><b style={{"paddingRight":"10px"}}> First Mile</b>
                    <i class="fa fa-minus fa-2x" style={{color:"#0000ff",}}></i><b style={{"paddingRight":"10px"}}> Middle Mile </b>
                    <i class="fa fa-minus fa-2x" style={{color:"#452a68",}}></i> <b style={{"paddingRight":"10px"}}>Last Mile (Dealer) </b>
                </span>
             */}
				
                <div id="map" className="map-bx" style={{width:'100%',height:"100vh"}}>No Data found</div>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-8 col-lg-8 col-md-8"} style={{padding:'20px', background:'#fff'}}>
	                		{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
	                		<div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
	                	</div>
	                </div>
	           </Modal>	

			   
				<Modal open={open1} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '1185px', overflow: 'hidden',zIndex:'9999',}} >
	                	<div className={"col-sm-8 col-lg-8 col-md-8"} style={{padding:'20px', background:'#fff'}}>
	                		{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
	                		<div id="myGrid1" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithBreakDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowBreakData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady1}
		          		            onGridState={this.onGridState1}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
	                	</div>
	                </div>
	           </Modal>			
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function setBg(){
	const randomColor = Math.floor(Math.random()*16777215).toString(16);
	return "#"+randomColor
  }
export default AndriodMapCurrentLocationWeb;