
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UploadFile = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowUploadModal(props.data);
    };
    // var show = 0;
    // try{
    //     if(typeof props.data.sec_uploadfiles == undefined 
    //         || props.data.sec_uploadfiles == undefined 
    //         || typeof props.data.sec_uploadfiles == "")
    //     {
    //         show = 0;
    //     }
    //     else{
    //         show = 1;
    //     }
    // }
    // catch(e){
    //     show = 0;
    // }
   // console.log("props.data ",props.data)
    return (
        <div>
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="Upload File"><i className="icofont icofont-upload f20"></i> Upload </button>
    
            
        </div>
    );
};

export default UploadFile;
