import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';
import LoadPlantViewData from "./loadplanningdashboard";
// import LoadPlantViewChart from "./loadplanningdashboardchart";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS,getDDMMMYYYYHHMMDefault } from '../common/utils';

import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import LoadColumnChart from "./loadplantcolumnchart";
import LoadPlanMultiChart from "./loadplantmultichart";

window.jQuery = $;
window.$ = $;
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class LoadPlanningToDashboardTabs extends Component{
    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
			modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            loadshow1:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            showTrucksList:"show-n",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            dept_code:"",
            pagetitle:"",
            overly :"show-n",
            startmonth:{"value":"","label":"Start Month"},
            endmonth:{"value":"","label":"End Month"},
            startyear:{"value":"","label":"Start Year"},
            endyear:{"value":"","label":"End Year"},
            financialyear:{"value":"","label":"Select"},
            invoiceseries:[],
            datatab:"show-m",
            charttab:"show-n",
            retrievalseries:[],
            viewtype:{"value":"",lable:"View Type"},
            financialyears:[],
            allrecords8hrs:[],
            allrecords:[],
            totalvins:0,
            totalvins8hrs:0,
            setview:0,
            headerHeight:60,
            gurjatchart:[],
            gurjatretchart:[],
            gurgoanchart:[],
            gurgoanretchart:[],
            manesarchart:[],
            manesarretchart:[],
            remove_fy:"",
            fy:"",
            gujaratdrilldown:[],
            gujaratretdrilldown:[],
            gurgoandrilldown:[],
            gurgoanretdrilldown:[],
            manesardrilldown:[],
            manesarretdrilldown:[],
            filviewtype:""
        };
        this.onLoadData = this.onLoadData.bind(this);
        this.onLoadApiData = this.onLoadApiData.bind(this);
        
		this.toggle = this.toggle.bind(this);
    }
    
	toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async() => {
        $("#errMsg").css("display","none");
        $("#errMsg1").css("display","none");
        this.setState({
            //overly : "show-m",
            loadshow:'show-n',
        })

        //$('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle="Load Planning To Exit Dashboard "
        var dept_code = "SNDG";
        var israil=0;
        var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
        'September', 'October', 'November', 'December'] 
        var startmonth = moment.parseZone().subtract(2, 'months').format("MM")
        // console.log("startmonth ", parseInt(startmonth))
        var smonthtext = mopts[parseInt(startmonth)-1]
        var endmonth = moment.parseZone().format("MM");
        var emonthtext = mopts[parseInt(endmonth)-1]
        var year = moment.parseZone().format("YYYY");
        if(parseInt(endmonth) == 4)
        {
            this.setState({
                remove_fy: year+" - "+(parseInt(year)+1).toString(),
               
            })
        }
        var fy = (year+" - "+(parseInt(year)+1)).toString()
        // console.log("DDDDDD ", fy)
        this.setState({            
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle,
            startyear:{value:year,label:year},
            startmonth:{value:startmonth,label:smonthtext},
            endmonth:{value:endmonth,label:emonthtext},
            viewtype:{value:"invoice",label:"Invoice to Retrieval"},
            fy:fy
        });
        var parameters= {
            startyear:parseInt(year),
            startmonth:parseInt(startmonth),
            endmonth:parseInt(endmonth)
        }
        this.onLoadData(parameters)
        var parameters1= {
            startyear:parseInt(year),
            startmonth:parseInt(startmonth),
            endmonth:parseInt(endmonth),
            viewtype:"invoice"
        }
        this.onLoadPlanData(parameters1) 
        await redirectURL.post("consignments/loadplanfys",parameters)
        .then((response) => {
            // console.log("resposne ", response.data)
            var fys = response.data.records
            var financialyears = []
            if(fys.length > 0)
            {
                fys.map((item) => {
                    financialyears.push(item)
                })
            }
            // console.log("financialyears ", financialyears)
            this.setState({
                financialyears:financialyears
            })
        })

        await redirectURL.post("dashboard/loadplanbyfmg")
        .then((response) => {
            // console.log("FMG ", response.data)
            var mopts = ['Jan', 'Feb', 'Mar', "Apr", 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] 
            
            var gujarat = response.data.gujarat;
            var gurgoan = response.data.gurgaon;
            var manesar = response.data.manesar;
            var gujaratdrilldown = []
            var gujaratretdrilldown = []
            var gurgoandrilldown = []
            var gurgoanretdrilldown = []
            var manesardrilldown = []
            var manesarretdrilldown = []
            if(gujarat.length > 0)
            {
                // var gujaratsort =  gujarat.sort(GetSortOrder("month"));
                var gujaratdata = groupBy(gujarat, rdata => rdata.trip_type);
                gujaratdata.forEach((item,key) => {
                    
                    var fysdata =  groupBy(item, rdata => rdata.financial_year);
                    fysdata.forEach((fyval,fykey) => {
                        var raildata = []
                        var retraildata = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:key
                                })
                                retraildata.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:key
                                })
                            })
                            
                                
                        }
                        gujaratdrilldown.push({
                            name:"Gujarat "+key+" - "+fykey,
                            id:"Gujarat "+key+" - "+fykey,
                            type:"line",
                            data:raildata,
                            tl:key
                        })
                        gujaratretdrilldown.push({
                            name:"Gujarat Retrival "+key+" - "+fykey,
                            id:"Gujarat Retrival "+key+" - "+fykey,
                            type:"line",
                            data:retraildata,
                            tl:key
                        })
                    })
                    
                })
                //gujarat.forEach((item,key) => {
                    var fysdata1 =  groupBy(gujarat, rdata => rdata.financial_year);
                    fysdata1.forEach((fyval,fykey) => {
                        var raildata1 = []
                        var retraildata1 = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata1.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:fykey
                                })
                                retraildata1.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:fykey
                                })
                            })
                            
                                
                        }
                        gujaratdrilldown.push({
                            name:"Gujarat "+fykey,
                            id:"Gujarat "+fykey,
                            type:"line",
                            data:raildata1,
                            tl:"gujarat total"
                        })
                        gujaratretdrilldown.push({
                            name:"Gujarat Retrival "+fykey,
                            id:"Gujarat Retrival "+fykey,
                            type:"line",
                            data:retraildata1,
                            tl:"gujarat retrival total"
                        })
                    })
                    
                //})
            }
            if(gurgoan.length > 0)
            {
                // var gujaratsort =  gujarat.sort(GetSortOrder("month"));
                var gurgoandata = groupBy(gurgoan, rdata => rdata.trip_type);
                gurgoandata.forEach((item,key) => {
                    
                    var fysdata =  groupBy(item, rdata => rdata.financial_year);
                    fysdata.forEach((fyval,fykey) => {
                        var raildata = []
                        var retraildata = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:key
                                })
                                retraildata.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:key
                                })
                            })
                            
                                
                        }
                        gurgoandrilldown.push({
                            name:"Gurgoan "+key+" - "+fykey,
                            id:"Gurgoan "+key+" - "+fykey,
                            type:"line",
                            data:raildata,
                            tl:key
                        })
                        gurgoanretdrilldown.push({
                            name:"Gurgoan Retrival "+key+" - "+fykey,
                            id:"Gurgoan Retrival "+key+" - "+fykey,
                            type:"line",
                            data:retraildata,
                            tl:key
                        })
                    })
                    
                    var fysdata2 =  groupBy(item, rdata => rdata.financial_year);
                    fysdata2.forEach((fyval,fykey) => {
                        var raildata = []
                        var retraildata = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:key
                                })
                                retraildata.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:key
                                })
                            })
                            
                                
                        }
                        gurgoandrilldown.push({
                            name:"Gurgoan "+fykey,
                            id:"Gurgoan "+fykey,
                            type:"line",
                            data:raildata,
                            tl:"gurgoan total"
                        })
                        gurgoanretdrilldown.push({
                            name:"Gurgoan Retrival "+fykey,
                            id:"Gurgoan Retrival "+fykey,
                            type:"line",
                            data:retraildata,
                            tl:"gurgoan retrival total"
                        })
                    })
                })
            }
            
            if(manesar.length > 0)
            {
                // var gujaratsort =  gujarat.sort(GetSortOrder("month"));
                var manesardata = groupBy(manesar, rdata => rdata.trip_type);
                manesardata.forEach((item,key) => {
                    
                    var fysdata =  groupBy(item, rdata => rdata.financial_year);
                    fysdata.forEach((fyval,fykey) => {
                        var raildata = []
                        var retraildata = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:key
                                })
                                retraildata.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:key
                                })
                            })
                            
                                
                        }
                        manesardrilldown.push({
                            name:"Manesar "+key+" - "+fykey,
                            id:"Manesar "+key+" - "+fykey,
                            type:"line",
                            data:raildata,
                            tl:key
                        })
                        manesarretdrilldown.push({
                            name:"Manesar Retrival "+key+" - "+fykey,
                            id:"Manesar Retrival "+key+" - "+fykey,
                            type:"line",
                            data:retraildata,
                            tl:key
                        })
                    })
                    
                    var fysdata3 =  groupBy(item, rdata => rdata.financial_year);
                    fysdata3.forEach((fyval,fykey) => {
                        var raildata = []
                        var retraildata = []
                        if(fyval.length > 0)
                        {
                            var monthsort = fyval.sort(GetSortOrder("month"));
                            var months = groupBy(monthsort, rdata => rdata.month);
                           
                            months.forEach((itm, ky) => {
                                // console.log("Guj itm ", itm )
                                var month = mopts[ky-1];
                                
                                var mwise = 0;
                                var totmwise = 0;
                                var retmwise = 0;
                                var rettotmwise = 0;
                                if(itm.length > 0)
                                {
                                    itm.map((im) => {
                                        if(im.data_type == "Invoice to Retrival")
                                        {
                                            mwise = mwise+parseInt(im.no_of_vins_8_hrs);
                                            totmwise = totmwise+parseInt(im.no_of_vins);
                                        }
                                        else
                                        {
                                            retmwise = retmwise+parseInt(im.no_of_vins_8_hrs);
                                            rettotmwise = rettotmwise+parseInt(im.no_of_vins);
                                        }
                                        // console.log("im.no_of_vins_8_hrs ", im.no_of_vins_8_hrs)
                                        
                                    })
                                    
                                }
                                var perct = parseFloat(Math.round((mwise/totmwise)*100))
                                var retperct = parseFloat(Math.round((retmwise/rettotmwise)*100))
                                // console.log("perct ", perct)
                                raildata.push({
                                    name:month,
                                    y:perct,
                                    vins:mwise,
                                    totalvins:totmwise,
                                    tl:key
                                })
                                retraildata.push({
                                    name:month,
                                    y:retperct,
                                    vins:retmwise,
                                    totalvins:rettotmwise,
                                    tl:key
                                })
                            })
                            
                                
                        }
                        manesardrilldown.push({
                            name:"Manesar "+fykey,
                            id:"Manesar "+fykey,
                            type:"line",
                            data:raildata,
                            tl:"manesar total"
                        })
                        manesarretdrilldown.push({
                            name:"Manesar Retrival "+fykey,
                            id:"Manesar Retrival "+fykey,
                            type:"line",
                            data:retraildata,
                            tl:"manesar retrival total"
                        })
                    })
                })
            }
            // console.log("gujaratdrilldown ", gujaratdrilldown)
            // console.log("gujaratretdrilldown ", gujaratretdrilldown)
            this.setState({
                gujaratdrilldown:gujaratdrilldown,
                gujaratretdrilldown:gujaratretdrilldown,
                gurgoandrilldown:gurgoandrilldown,
                gurgoanretdrilldown:gurgoanretdrilldown,
                manesardrilldown:manesardrilldown,
                manesarretdrilldown:manesarretdrilldown
            })
        })
       
        await this.onLoadApiData(fy);
    }
    onLoadApiData(fy){
        redirectURL.post("dashboard/apiloadplan")
        .then((response) => {
            console.log("data ", response.data)
            var mopts = ['Jan', 'Feb', 'Mar', "Apr", 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] 
            
            // Gujarat Invoice to Retrival
            var gujaratinv = JSON.parse(response.data.gujarat_inv_to_ret);

            // Gujarat Retrival to Exit
            var gujaratret = JSON.parse(response.data.gujarat_ret_to_gate_out);
            // Gurgoan Invoice to Retrival
            var gurgoan_inv_to_ret = JSON.parse(response.data.gurgoan_inv_to_ret);
            // Gurgoan Retrival to Exit
            var gurgoan_ret_to_gate_out = JSON.parse(response.data.gurgoan_ret_to_gate_out);
            console.log(gurgoan_ret_to_gate_out,"gurgoan_ret_to_gate_out data")
            // Manesar Invoice to Retrival
            var manesar_inv_to_ret = JSON.parse(response.data.manesar_inv_to_ret);

            // Manesar Retrival to Exit
            var manesar_ret_to_gate_out = JSON.parse(response.data.manesar_ret_to_gate_out);

            // Gujarat Invoice to Retrival
            gujaratinv = gujaratinv.sort(GetSortOrder("month_flag"));

            // Gujarat Retrival to Exit
            gujaratret = gujaratret.sort(GetSortOrder("month_flag"));

            
            // Gurgoan Invoice to Retrival
            gurgoan_inv_to_ret = gurgoan_inv_to_ret.sort(GetSortOrder("month_flag"));

            // Gurgoan Retrival to Exit
            gurgoan_ret_to_gate_out = gurgoan_ret_to_gate_out.sort(GetSortOrder("month_flag"));

            
            // Manesar Invoice to Retrival
            manesar_inv_to_ret = manesar_inv_to_ret.sort(GetSortOrder("month_flag"));

            // Manesar Retrival to Exit
            manesar_ret_to_gate_out = manesar_ret_to_gate_out.sort(GetSortOrder("month_flag"));

            // Gujarat Invoice to Retrival
            var currFY = gujaratinv.filter(function(e){
                return e.financial_year == fy && e.month_flag == 1
            })
            // Gujarat Retrival to Exit
            var gujretcurrFY = [];
            gujaratret.map(function(g){
                if(g.financial_year == fy && g.month_flag == 1)
                {
                    gujretcurrFY.push(g);
                }
            });
            // var gujretcurrFY = gujaratret.filter(function(e){
            //     return e.financial_year == fy && e.month_flag == 1
            // })
            
            // Gurgoan Invoice to Retrival
            var gurcurrFY = [];
            gurgoan_inv_to_ret.map(function(h){
                if(h.financial_year == fy && h.month_flag == 1)
                {
                    gurcurrFY.push(h);
                }
            });
            // var gurcurrFY = gurgoan_inv_to_ret.filter(function(e){
            //     return e.financial_year == fy && e.month_flag == 1
            // })
           
            // Gurgoan Retrival to Exit
            var gurretcurrFY = [];
            gurgoan_ret_to_gate_out.map(function(j){
                if(j.financial_year == fy && j.month_flag == 1)
                {
                    gurretcurrFY.push(j);
                }
            });
            // var gurretcurrFY = gurgoan_ret_to_gate_out.filter(function(e){
            //     return e.financial_year == fy && e.month_flag == 1
            // })

            // Manesar Invoice to Retrival
            var manesarcurrFY = [];
            manesar_inv_to_ret.map(function(k){
                if(k.financial_year == fy && k.month_flag == 1)
                {
                    manesarcurrFY.push(k);
                }
            });
            // var manesarcurrFY = manesar_inv_to_ret.filter(function(e){
            //     return e.financial_year == fy && e.month_flag == 1
            // })

            // Manesar Retrival to Exit
            var manesarretcurrFY = [];
            manesar_ret_to_gate_out.map(function(l){
                if(l.financial_year == fy && l.month_flag == 1)
                {
                    manesarretcurrFY.push(l);
                }
            });
            // var manesarretcurrFY = manesar_ret_to_gate_out.filter(function(e){
            //     return e.financial_year == fy && e.month_flag == 1
            // })

            if(this.state.remove_fy == "")
            {
                // Gujarat Invoice to Retrival
                //currFY.pop()

                // Gujarat Retrival to Exit
                //gujretcurrFY.pop()

                // Gurgoan Invoice to Retrival
                //gurcurrFY.pop()

                // Gurgoan Retrival to Exit
                //gurretcurrFY.pop()
                
                // Manesar Invoice to Retrival
                //manesarcurrFY.pop()

                // Manesar Retrival to Exit
                //manesarretcurrFY.pop()
            }
            // Gujarat Invoice to Retrival
            var prevFy = gujaratinv.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })

            // Gujarat Retrival to Exit
            var gujretprevFy = gujaratret.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            
            // Gurgoan Invoice to Retrival
            var gurprevFy = gurgoan_inv_to_ret.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })

            // Gurgoan Retrival to Exit
            var gurretprevFy = gurgoan_ret_to_gate_out.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            
            // Manesar Invoice to Retrival
            var manesarprevFy = manesar_inv_to_ret.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })

            // Manesar Retrival to Exit
            var manesarretprevFy = manesar_ret_to_gate_out.filter((e)=>{
                if(this.state.remove_fy != "")
                {
                    return e.month_flag == 0 & e.year != this.state.remove_fy
                }
                else{
                    return e.month_flag == 0
                }
            })
            
            // Gujarat Invoice to Retrival
            // gujaratinv = prevFy.concat(currFY)

            // Gujarat Retrival to Exit
            gujaratret = gujretprevFy.concat(gujretcurrFY)
            // Gurgoan Invoice to Retrival
            gurgoan_inv_to_ret = gurprevFy.concat(gurcurrFY)
            // Gurgoan Retrival to Exit
            gurgoan_ret_to_gate_out = gurretprevFy.concat(gurretcurrFY)
            // Manesar Invoice to Retrival
            manesar_inv_to_ret = manesarprevFy.concat(manesarcurrFY)
            // Manesar Retrival to Exit
            manesar_ret_to_gate_out = manesarretprevFy.concat(manesarretcurrFY)
            
            // Gujarat Invoice to Retrival
            var gurjatchart = []
            var prevFyDataRailVinPer = []
            var prevFyDataRoadVinPer = []
            var gcategories = []
            var railDataVinPer = []
            var roadDataVinPer = []
            var prevFyDataTotalVinPer = []
            var totalDataVinPer = []
            
            // Gujarat Retrival to Exit
            var gujaratrettchart = []
            var gujretprevFyDataRailVinPer = []
            var gujretprevFyDataRoadVinPer = []
            var gujretcategories = []
            var gujretrailDataVinPer = []
            var gujretroadDataVinPer = []
            
            // Gurgoan Invoice to Retrival
            var gurgoanchart = []
            var gurprevFyDataRailVinPer = []
            var gurprevFyDataRoadVinPer = []
            var gurcategories = []
            var gurrailDataVinPer = []
            var gurroadDataVinPer = []
            
            // Gurgoan Retrival to Exit
            var gurgoanrettchart = []
            var gurretprevFyDataRailVinPer = []
            var gurretprevFyDataRoadVinPer = []
            var gurretcategories = []
            var gurretrailDataVinPer = []
            var gurretroadDataVinPer = []

            // Manesar Invoice to Retrival
            var manesarchart = []
            var mnprevFyDataRailVinPer = []
            var mnprevFyDataRoadVinPer = []
            var mncategories = []
            var mnrailDataVinPer = []
            var mnroadDataVinPer = []
            
            // Manesar Retrival to Exit
            var manesarrettchart = []
            var mnretprevFyDataRailVinPer = []
            var mnretprevFyDataRoadVinPer = []
            var mnretcategories = []
            var mnretrailDataVinPer = []
            var mnretroadDataVinPer = []


            prevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     prevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     railDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     prevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     roadDataVinPer.push(null)
                // }
                
            })

            gujretprevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     gujretprevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     gujretrailDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     gujretprevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     gujretroadDataVinPer.push(null)
                // }
                
            })

            

            gurprevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     gurprevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     gurrailDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     gurprevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     gurroadDataVinPer.push(null)
                // }
                
            })

            gurretprevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     gurretprevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     gurretrailDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     gurretprevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     gurretroadDataVinPer.push(null)
                // }
                
            })

            manesarprevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     mnprevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     mnrailDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     mnprevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     mnroadDataVinPer.push(null)
                // }
                
            })

            manesarretprevFy.map(function(e){
                // if(e.trip_type == "Rail"){
                //     mnretprevFyDataRailVinPer.push({
                //         name:"Rail - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Rail - "+e.year
                //     })
                //     mnretrailDataVinPer.push(null) 
                // }
                // if(e.trip_type == "Road"){
                //     mnretprevFyDataRoadVinPer.push({
                //         name:"Road - "+e.year,
                //         y:e.vin_percetage,
                //         vins:e.no_of_vins_8_hrs,
                //         totalvins:e.no_of_vins,
                //         drilldown:"Road - "+e.year
                //     })
                //     mnretroadDataVinPer.push(null)
                // }
                
            })
            var gujdrilldown=[]
            if(gujaratinv.length > 0)
            {
                var gujaiarr = [];
                var vins = 0;
                var totalvns = 0;
                var vinper = 0;
                gujaratinv.map((item) => {
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            // gujdrilldown.push({
                            //     name:"Rail - "+item.year,
                            //     id:"Rail - "+item.year,
                            //     type:"column",
                            //     data:[
                            //         ["jan", 10],
                            //         ['feb', 45]
                            //     ]
                            // })
                            prevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gujarat Rail - "+item.year,
                                tl:"Rail"
                            })
                            railDataVinPer.push(null) 
                            vins=vins+parseFloat(item.no_of_vins_8_hrs)
                            totalvns=totalvns+parseFloat(item.no_of_vins)
                            vinper=vins+parseFloat(item.vin_percetage)
                        }
                        if(item.trip_type == "Road"){
                            prevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gujarat Road - "+item.year,
                                tl:"Road"
                            })
                            roadDataVinPer.push(null)
                            vins=vins+parseFloat(item.no_of_vins_8_hrs)
                            totalvns=totalvns+parseFloat(item.no_of_vins)
                            vinper=vins+parseFloat(item.vin_percetage)
                        }
                        gcategories.push(item.month)
                        // console.log("vinper ",vinper)
                        // console.log("totalvns ",totalvns)
                        // console.log("item.year ", item.year)
                        // prevFyDataTotalVinPer.push({
                        //     name:item.year,
                        //     y:vinper,
                        //     vins:vins,
                        //     totalvins:totalvns,
                        //     drilldown:"Gujarat Total - "+item.year,
                        //     tl:"Total"
                        // });
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            railDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            prevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            roadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            prevFyDataRoadVinPer.push(null)
                        }
                        gcategories.push(mopts[item.month-1])
                        totalDataVinPer.push({
                            name:mopts[item.month-1],
                            y:item.vin_percetage,                                
                            vins:item.no_of_vins_8_hrs,
                            totalvins:item.no_of_vins,
                            tl:"Total"
                        })
                        prevFyDataTotalVinPer.push(null)
                    }
                })
                
            }
            var gujretdrilldown = [];
            if(gujaratret.length > 0)
            {
                gujaratret.map((item) => {
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            gujretprevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gujarat Retrival Rail - "+item.year,
                                tl:"Rail"
                            })
                            gujretrailDataVinPer.push(null) 
                        }
                        if(item.trip_type == "Road"){
                            gujretprevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gujarat Retrival Road - "+item.year,
                                tl:"Road"
                            })
                            gujretroadDataVinPer.push(null)
                        }
                        gujretcategories.push(item.month)
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            gujretrailDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            gujretprevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            gujretroadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            gujretprevFyDataRoadVinPer.push(null)
                        }
                        gujretcategories.push(mopts[item.month-1])
                    }
                })
            }
            
            var gurdrilldown=[]
            if(gurgoan_inv_to_ret.length > 0)
            {
                gurgoan_inv_to_ret.map((item) => {
                    console.log("item ", item)
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            gurprevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gurgoan Rail - "+item.year,
                                tl:"Rail"
                            })
                            gurrailDataVinPer.push(null) 
                        }
                        if(item.trip_type == "Road"){
                            gurprevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gurgoan Road - "+item.year,
                                tl:"Road"
                            })
                            gurroadDataVinPer.push(null)
                        }
                        gurcategories.push(item.month)
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            gurrailDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            gurprevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            gurroadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            gurprevFyDataRoadVinPer.push(null)
                        }
                        gurcategories.push(mopts[item.month-1])
                    }
                })
            }

            var gurretdrilldown = [];
            if(gurgoan_ret_to_gate_out.length > 0)
            {
                gurgoan_ret_to_gate_out.map((item) => {
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            gurretprevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gurgoan Retrival Rail - "+item.year,
                                tl:"Rail"
                            })
                            gurretrailDataVinPer.push(null) 
                        }
                        if(item.trip_type == "Road"){
                            gurretprevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Gurgoan Retrival Road - "+item.year,
                                tl:"Road"
                            })
                            gurretroadDataVinPer.push(null)
                        }
                        gurretcategories.push(item.month)
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            gurretrailDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            gurretprevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            gurretroadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            gurretprevFyDataRoadVinPer.push(null)
                        }
                        gurretcategories.push(mopts[item.month-1])
                    }
                })
            }
            
            var manesardrilldown=[]
            if(manesar_inv_to_ret.length > 0)
            {
                manesar_inv_to_ret.map((item) => {
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            mnprevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Manesar Rail - "+item.year,
                                tl:"Rail"
                            })
                            mnrailDataVinPer.push(null) 
                        }
                        if(item.trip_type == "Road"){
                            mnprevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Manesar Road - "+item.year,
                                tl:"Road"
                            })
                            mnroadDataVinPer.push(null)
                        }
                        mncategories.push(item.month)
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            mnrailDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            mnprevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            mnroadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            mnprevFyDataRoadVinPer.push(null)
                        }
                        mncategories.push(mopts[item.month-1])
                    }
                })
            }

            var manesarretdrilldown = [];
            if(manesar_ret_to_gate_out.length > 0)
            {
                manesar_ret_to_gate_out.map((item) => {
                    if(item.month_flag == 0){
                        if(item.trip_type == "Rail"){
                            mnretprevFyDataRailVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Manesar Retrival Rail - "+item.year,
                                tl:"Rail"
                            })
                            mnretrailDataVinPer.push(null) 
                        }
                        if(item.trip_type == "Road"){
                            mnretprevFyDataRoadVinPer.push({
                                name:item.year,
                                y:item.vin_percetage,
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                drilldown:"Manesar Retrival Road - "+item.year,
                                tl:"Road"
                            })
                            mnretroadDataVinPer.push(null)
                        }
                        mnretcategories.push(item.month)
                    }
                    else
                    {
                        if(item.trip_type == "Rail"){
                            mnretrailDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Rail"
                            })
                            mnretprevFyDataRailVinPer.push(null)
                        }
                        if(item.trip_type == "Road"){
                            // roadDataVinPer.push(item.vin_percetage)
                            mnretroadDataVinPer.push({
                                name:mopts[item.month-1],
                                y:item.vin_percetage,                                
                                vins:item.no_of_vins_8_hrs,
                                totalvins:item.no_of_vins,
                                tl:"Road"
                            })
                            mnretprevFyDataRoadVinPer.push(null)
                        }
                        mnretcategories.push(mopts[item.month-1])
                    }
                })
            }
            var gujcategories = gcategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            var gujretcategories = gujretcategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });

            var gurgoancategories = gurcategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            var gurgoanretcategories = gurretcategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            
            var mancategories = mncategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            var manretcategories = mnretcategories.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            // console.log("prevFyDataRailVinPer ", prevFyDataRailVinPer)
            var prailVinPer = prevFyDataRailVinPer
            var proadVinPer = prevFyDataRoadVinPer
            var totalarr = []
            if(prailVinPer.length > 0)
            {
                for(var i=0;i<prailVinPer.length;i++)
                {
                    // console.log("prailVinPer[i].year ", prailVinPer[i].name)
                    if(prailVinPer[i] != null)
                    {
                        var mulvins1 = (prailVinPer[i].vins+proadVinPer[i].vins);
                        var multotalvins1 = (prailVinPer[i].totalvins+proadVinPer[i].totalvins);
                        var mulper1 = (mulvins1/multotalvins1)*100;
                        var json = {
                            name:prailVinPer[i].name,
                            y:Math.round(mulper1),
                            vins:mulvins1,
                            totalvins:multotalvins1,
                            drilldown:"Gujarat "+prailVinPer[i].name,
                            tl:"gujarat total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    totalarr.push(json)
                }
            }
            var mrailVinPer = railDataVinPer
            var mroadVinPer = roadDataVinPer
            var monthtotalarr = []
            //console.log(mrailVinPer,"mrailVinPer")
            //console.log(mroadVinPer,"mroadVinPer")
            if(mrailVinPer.length > 0)
            {
                for(var i=0;i<mrailVinPer.length;i++)
                {
                    if(mrailVinPer[i] != null)
                    {
                        var totY = mrailVinPer[i].y+mroadVinPer[i].y;
                        if(totY == 0)
                        {
                            var totY = mrailVinPer[i].y+mroadVinPer[i].y
                        }
                        var mulvins = (mrailVinPer[i].vins+mroadVinPer[i].vins);
                        var multotalvins = (mrailVinPer[i].totalvins+mroadVinPer[i].totalvins);
                        var mulper = (mulvins/multotalvins)*100;
                        var json = {
                            name:mrailVinPer[i].name,
                            y:Math.round(mulper),
                            vins:mulvins,
                            totalvins:multotalvins,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    monthtotalarr.push(json)
                }
            }
            var gujseries = [
                
                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    // data: prevFyDataTotalVinPer,
                    data:totalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: prevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: prevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: monthtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    },
                    plotOptions: {
                        column: {
                            minPointLength: 3
                        }
                    },
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: railDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    },
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: roadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]
            
            var gujprailVinPer = gujretprevFyDataRailVinPer
            var gujproadVinPer = gujretprevFyDataRoadVinPer
            var gujtotalarr = []
            if(gujprailVinPer.length > 0)
            {
                for(var i=0;i<gujprailVinPer.length;i++)
                {
                    // console.log("gujprailVinPer[i].year ", gujprailVinPer[i].name)
                    if(gujprailVinPer[i] != null)
                    {
                        var mulvins2 = (gujprailVinPer[i].vins+gujproadVinPer[i].vins);
                        var multotalvins2 = (gujprailVinPer[i].totalvins+gujproadVinPer[i].totalvins);
                        var mulper2 = (mulvins2/multotalvins2)*100;
                        var json = {
                            name:gujprailVinPer[i].name,
                            y:Math.round(mulper2),
                            vins:mulvins2,
                            totalvins:multotalvins2,
                            drilldown:"Gujarat Retrival "+gujprailVinPer[i].name,
                            tl:"gujarat retrival total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gujtotalarr.push(json)
                }
            }

            var gujmrailVinPer = gujretrailDataVinPer
            var gujmroadVinPer = gujretroadDataVinPer
            console.log(gujmrailVinPer,"gujmrailVinPer")
            console.log(gujmroadVinPer,"gujmroadVinPer")
            var gujmonthgujtotalarr = []
            if(gujmrailVinPer.length > 0)
            {
                for(var i=0;i<gujmrailVinPer.length;i++)
                {
                    // console.log("prailVinPer[i].year ", prailVinPer[i].name)
                    if(gujmrailVinPer[i] != null && gujmroadVinPer[i] !=undefined )
                    {
                        var mulvins3 = (gujmrailVinPer[i].vins+gujmroadVinPer[i].vins);
                        var multotalvins3 = (gujmrailVinPer[i].totalvins+gujmroadVinPer[i].totalvins);
                        var mulper3 = (mulvins3/multotalvins3)*100;
                        var json = {
                            name:gujmrailVinPer[i].name,
                            y:Math.round(mulper3),
                            vins:mulvins3,
                            totalvins:multotalvins3,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gujmonthgujtotalarr.push(json)
                }
            }
            var gujretseries = [

                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    // data: prevFyDataTotalVinPer,
                    data:gujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: gujretprevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: gujretprevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: gujmonthgujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    }
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: gujretrailDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: gujretroadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]

            var gurprailVinPer = gurprevFyDataRailVinPer
            var gurproadVinPer = gurprevFyDataRoadVinPer
            var gurtotalarr = []
            if(gurprailVinPer.length > 0)
            {
                for(var i=0;i<gurprailVinPer.length;i++)
                {
                    // console.log("gurprailVinPer[i].year ", gurprailVinPer[i].name)
                    if(gurprailVinPer[i] != null && gurproadVinPer[i] !=undefined)
                    {
                        var mulvins4 = (gurprailVinPer[i].vins+gurproadVinPer[i].vins);
                        var multotalvins4 = (gurprailVinPer[i].totalvins+gurproadVinPer[i].totalvins);
                        var mulper4 = (mulvins4/multotalvins4)*100;
                        var json = {
                            name:gurprailVinPer[i].name,
                            y:Math.round(mulper4),
                            vins:mulvins4,
                            totalvins:multotalvins4,
                            drilldown:"Gurgoan "+gurprailVinPer[i].name,
                            tl:"gurgoan total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gurtotalarr.push(json)
                }
            }

            var gurmrailVinPer = gurrailDataVinPer
            var gurmroadVinPer = gurroadDataVinPer
            var gurmonthgujtotalarr = []
            if(gurmrailVinPer.length > 0)
            {
                for(var i=0;i<gurmrailVinPer.length;i++)
                {
                    if(gurmrailVinPer[i] != null && gurmroadVinPer[i] !=undefined)
                    {
                        var mulvins5 = (gurmrailVinPer[i].vins+gurmroadVinPer[i].vins);
                        var multotalvins5 = (gurmrailVinPer[i].totalvins+gurmroadVinPer[i].totalvins);
                        var mulper5 = (mulvins5/multotalvins5)*100;
                        var json = {
                            name:gurmrailVinPer[i].name,
                            y:Math.round(mulper5),
                            vins:mulvins5,
                            totalvins:multotalvins5,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gurmonthgujtotalarr.push(json)
                }
            }
            var gurseries = [
                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    data:gurtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: gurprevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: gurprevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },

                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: gurmonthgujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    }
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: gurrailDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: gurroadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]
            
            var gurretprailVinPer = gurretprevFyDataRailVinPer
            var gurretproadVinPer = gurretprevFyDataRoadVinPer
            var gurrettotalarr = []
            if(gurretprailVinPer.length > 0)
            {
                for(var i=0;i<gurretprailVinPer.length;i++)
                {
                    // console.log("gurretprailVinPer[i].year ", gurretprailVinPer[i].name)
                    if(gurretprailVinPer[i] != null && gurretproadVinPer[i] !=undefined)
                    {
                        var mulvins6 = (gurretprailVinPer[i].vins+gurretproadVinPer[i].vins);
                        var multotalvins6 = (gurretprailVinPer[i].totalvins+gurretproadVinPer[i].totalvins);
                        var mulper6 = (mulvins6/multotalvins6)*100;
                        var json = {
                            name:gurretprailVinPer[i].name,
                            y:Math.round(mulper6),
                            vins:mulvins6,
                            totalvins:multotalvins6,
                            drilldown:"Gurgoan Retrival "+gurretprailVinPer[i].name,
                            tl:"gurgoan retrival total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gurrettotalarr.push(json)
                }
            }
            var gurretmrailVinPer = gurretrailDataVinPer
            var gurretmroadVinPer = gurretroadDataVinPer
            var gurretmonthgujtotalarr = []
            if(gurretmrailVinPer.length > 0)
            {
                for(var i=0;i<gurretmrailVinPer.length;i++)
                {
                    if(gurretmrailVinPer[i] != null && gurretmroadVinPer[i] !=undefined)
                    {
                        var mulvins7 = (gurretmrailVinPer[i].vins+gurretmroadVinPer[i].vins);
                        var multotalvins7 = (gurretmrailVinPer[i].totalvins+gurretmroadVinPer[i].totalvins);
                        var mulper7 = (mulvins7/multotalvins7)*100;
                        var json = {
                            name:gurretmrailVinPer[i].name,
                            y:Math.round(mulper7),
                            vins:mulvins7,
                            totalvins:multotalvins7,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    gurretmonthgujtotalarr.push(json)
                }
            }
            var gurretseries = [
                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    data:gurrettotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: gurretprevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: gurretprevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: gurretmonthgujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    }
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: gurretrailDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: gurretroadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]

            var maneprailVinPer = mnprevFyDataRailVinPer
            var maneproadVinPer = mnprevFyDataRoadVinPer
            var manetotalarr = []
            if(maneprailVinPer.length > 0)
            {
                for(var i=0;i<maneprailVinPer.length;i++)
                {
                    // console.log("maneprailVinPer[i].year ", maneprailVinPer[i].name)
                    if(maneprailVinPer[i] != null && maneproadVinPer[i] !=undefined)
                    {
                        var mulvins8 = (maneprailVinPer[i].vins+maneproadVinPer[i].vins);
                        var multotalvins8 = (maneprailVinPer[i].totalvins+maneproadVinPer[i].totalvins);
                        var mulper8 = (mulvins8/multotalvins8)*100;
                        var json = {
                            name:maneprailVinPer[i].name,
                            y:Math.round(mulper8),
                            vins:mulvins8,
                            totalvins:multotalvins8,
                            drilldown:"Manesar "+maneprailVinPer[i].name,
                            tl:"manesar total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    manetotalarr.push(json)
                }
            }

            var manemrailVinPer = mnrailDataVinPer
            var manemroadVinPer = mnroadDataVinPer
            var manemonthgujtotalarr = []
            if(manemrailVinPer.length > 0)
            {
                for(var i=0;i<manemrailVinPer.length;i++)
                {
                    if(manemrailVinPer[i] != null && manemroadVinPer[i] !=undefined)
                    {
                        var mulvins9 = (manemrailVinPer[i].vins+manemroadVinPer[i].vins);
                        var multotalvins9 = (manemrailVinPer[i].totalvins+manemroadVinPer[i].totalvins);
                        var mulper9 = (mulvins9/multotalvins9)*100;
                        var json = {
                            name:manemrailVinPer[i].name,
                            y:Math.round(mulper9),
                            vins:mulvins9,
                            totalvins:multotalvins9,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    manemonthgujtotalarr.push(json)
                }
            }
            
            var manesarseries = [
                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    data:manetotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: mnprevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: mnprevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: manemonthgujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    }
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: mnrailDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: mnroadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]
            
            var manesarrprailVinPer = mnretprevFyDataRailVinPer
            var manesarrproadVinPer = mnretprevFyDataRoadVinPer
            var manesarrtotalarr = []
            if(manesarrprailVinPer.length > 0)
            {
                for(var i=0;i<manesarrprailVinPer.length;i++)
                {
                    // console.log("manesarrprailVinPer[i].year ", manesarrprailVinPer[i].name)
                    if(manesarrprailVinPer[i] != null && manesarrproadVinPer[i] !=undefined)
                    {
                        var mulvins10 = (manesarrprailVinPer[i].vins+manesarrproadVinPer[i].vins);
                        var multotalvins10 = (manesarrprailVinPer[i].totalvins+manesarrproadVinPer[i].totalvins);
                        var mulper10 = (mulvins10/multotalvins10)*100;
                        var json = {
                            name:manesarrprailVinPer[i].name,
                            y:Math.round(mulper10),
                            vins:mulvins10,
                            totalvins:multotalvins10,
                            drilldown:"Manesar Retrival "+manesarrprailVinPer[i].name,
                            tl:"manesar retrival total"
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    manesarrtotalarr.push(json)
                }
            }

            var manesarrmrailVinPer = mnretrailDataVinPer
            var manesarrmroadVinPer = mnretroadDataVinPer
            var manesarrmonthgujtotalarr = []
            if(manesarrmrailVinPer.length > 0)
            {
                for(var i=0;i<manesarrmrailVinPer.length;i++)
                {
                    if(manesarrmrailVinPer[i] != null && manesarrmroadVinPer[i] !=undefined)
                    {
                        var mulvins11 = (manesarrmrailVinPer[i].vins+manesarrmroadVinPer[i].vins);
                        var multotalvins11 = (manesarrmrailVinPer[i].totalvins+manesarrmroadVinPer[i].totalvins);
                        var mulper11 = (mulvins11/multotalvins11)*100;
                        var json = {
                            name:manesarrmrailVinPer[i].name,
                            y:Math.round(mulper11),
                            vins:mulvins11,
                            totalvins:multotalvins11,
                            tl:"Total",
                        }
                    }
                    else
                    {
                        var json = null
                    }
                    
                    manesarrmonthgujtotalarr.push(json)
                }
            }

            var manesarretseries = [

                {
                    name: 'Total',
                    type: 'column',
                    color: "red",
                    data:manesarrtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Rail',
                    type: 'column',
                    data: mnretprevFyDataRailVinPer,
                    color: "#8064a2",
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                {
                    name: 'Road',
                    type: 'column',
                    color: "#ff8080",
                    data: mnretprevFyDataRoadVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    }
                },
                
                {
                    name: 'Total (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#FF0000',
                    data: manesarrmonthgujtotalarr,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#FF0000',
                    }
                },
                {
                    name: 'Rail (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                    color: '#d5d5ff',
                    data: mnretrailDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                },
                {
                    name: 'Road (month-wise)',
                    type: 'column',
                   // yAxis: 1,
                   color: "#b84848",
                    data: mnretroadDataVinPer,
                    dataLabels: {
                        enabled: true,
                        rotation: -90
                    },
                    zoneAxis: 'x',
                    zones: [{
                        dashStyle: 'ShortDash'
                    }],
                    marker: {
                        fillColor: '#d5d5ff',
                    }
                }
                
            ]
            // console.log("this.state.gujaratdrilldown ", this.state.gujaratdrilldown)
            
            // console.log("this.state.gujaratretdrilldown ", this.state.gujaratretdrilldown)
            gurjatchart.push({
                series : gujseries,
                categories : gujcategories,
                dcategories : ['jan', 'feb'],
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                yAxis: {
                    min: 0,
                    title: {
                      text: ''
                    }
                  },
                drilldown: this.state.gujaratdrilldown
            })
            gujaratrettchart.push({
                series : gujretseries,
                categories : gujretcategories,
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                drilldown: this.state.gujaratretdrilldown
            })
           
            
            gurgoanchart.push({
                series : gurseries,
                categories : gurgoancategories,
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                drilldown: this.state.gurgoandrilldown
            })
            gurgoanrettchart.push({
                series : gurretseries,
                categories : gurgoanretcategories,
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                drilldown: this.state.gurgoanretdrilldown
            })
            
            manesarchart.push({
                series : manesarseries,
                categories : mancategories,
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                drilldown: this.state.manesardrilldown
            })
            manesarrettchart.push({
                series : manesarretseries,
                categories : manretcategories,
                subtitle: "",
                xtitle:"",
                ytitle:"Percentage of vins (>= 8 Hrs)",
                yaxistitle:"",
                drilldown: this.state.manesarretdrilldown
            })
            // console.log("Gujarat serires ", gurjatchart)
            // console.log("gujaratrettchart serires ", gujaratrettchart)
            // console.log("Gurgoan serires ", gurgoanchart)
            // console.log("gurgoanrettchart serires ", gurgoanrettchart)
            this.setState({
                gurjatchart:gurjatchart,
                gurjatretchart:gujaratrettchart,
                gurgoanchart:gurgoanchart,
                gurgoanretchart:gurgoanrettchart,
                manesarchart:manesarchart,
                manesarretchart:manesarrettchart
            })
        })
    }
    onLoadData(parameters){
        // redirectURL.post("consignments/loadplandashboardparamschart",parameters)
        // .then((response) => {
        //     // console.log("response ", response.data)
        //     var recordarr = response.data.records;
        //     var invrecords = []
        //     var retrecords = []
        //     if(recordarr.length > 0)
        //     {
        //         recordarr.map((item) => {
        //             if(item.data_type == "Invoice to Retrival")
        //             {
        //                 invrecords.push(item)
        //             }
        //             else{
        //                 retrecords.push(item)
        //             }
        //         })
        //     }
        //     var mopts = ['Jan', 'Feb', 'Mar', "Apr", 'May', 'Jun', 'Jul', 'Aug', 
        // 'Sep', 'Oct', 'Nov', 'Dec'] 
        //     // console.log("records ", records)
        //     var series = []
        //     var categories = []
        //     var retrieveseries = []
        //     var invoiceserires = []
        //     var invoicedrillserires1 = []
         
        //     // var monthsort =  invrecords.sort(GetSortOrder("month"));
        //     var monthsdata =  groupBy(invrecords, rdata => rdata.plant_name);
        //     monthsdata.forEach((item,key) => {
        //         // categories.push(mopts[key-1])
        //         categories.push(key)
        //         var monthwisedata=0;
        //         var totmonthwisedata = 0;
        //         var plantdata = []
        //         if(item.length > 0)
        //         {
        //             item.map((itm) => {
        //                 monthwisedata = monthwisedata+itm.no_of_vins_8_hrs;
        //                 totmonthwisedata = totmonthwisedata+itm.no_of_vins;                        
        //             })
        //         }
        //         var percetn = Math.round((monthwisedata/totmonthwisedata)*100)
        //         invoiceserires.push({
        //             name:key,
        //             y:parseFloat(percetn),
        //             vins:monthwisedata,
        //             totalvins:totmonthwisedata,
        //             drilldown:key
        //         })
        //         // drilldown functionality
        //           var invmmseries = []
        //             if(item.length > 0)
        //             {
        //                 var plantsdata =  groupBy(item, rdata => rdata.trip_type);
        //                console.log("/plantsdata ", plantsdata)
        //                 plantsdata.forEach((mm,kk) => {
        //                     // console.log("plant kk ",kk)
        //                     // console.log("plant mm ",mm)
        //                     var mwisedata = 0;
        //                     var totmwisedata = 0;
        //                     mm.map((mi) => {
        //                         mwisedata = mwisedata+parseInt(mi.no_of_vins_8_hrs);
        //                         totmwisedata = totmwisedata+parseInt(mi.no_of_vins);
        //                     })
        //                     var percetndrill = Math.round((mwisedata/totmwisedata)*100)
        //                     plantdata.push({
        //                         name:kk,
        //                         y:parseFloat(percetndrill),
        //                         vins:mwisedata,
        //                         totalvins:totmwisedata,
        //                         drilldown:key+"-"+kk
        //                     })
        //                     var ttypes =  groupBy(mm, rdata => rdata.month);
        //                     var troad =[]
        //                     ttypes.forEach((tval,tkey) => {
        //                         var tmwisedata = 0;
        //                         var ttotmwisedata = 0;
                               
        //                         tval.map((titm) => {
        //                             tmwisedata = tmwisedata+parseInt(titm.no_of_vins_8_hrs);
        //                             ttotmwisedata = ttotmwisedata+parseInt(titm.no_of_vins);
        //                         })
        //                         var tpercetndrill = ((tmwisedata/ttotmwisedata)*100).toFixed(2)
        //                         // troad.push([tkey,parseFloat(tpercetndrill)])
        //                         troad.push({
        //                             name:mopts[tkey-1],
        //                             y:parseFloat(tpercetndrill),
        //                             vins:tmwisedata,
        //                             totalvins:ttotmwisedata,

        //                         })
        //                     })
        //                     invoicedrillserires1.push({
        //                         name:key+"-"+kk,
        //                         id:key+"-"+kk,
        //                         data:troad
        //                     })  
        //                 })
        //             }
        //         invoicedrillserires1.push({
        //             name:key,
        //             id:key,
        //             colorByPoint: true,
        //             data:plantdata
        //         })
        //     })

        //     console.log("invoicedrillserires1 ", invoicedrillserires1)
        //     console.log("invoiceserires ", invoiceserires)
        //     series.push({
        //         name:"Plants",
        //         colorByPoint: true,
        //         data:invoiceserires
        //     })

        //     var retrieveseries = []
        //     var retserires = []
        //     var retdrillserires1 = []
            
        //     // var retmonthsort =  retrecords.sort(GetSortOrder("month"));
        //     var retmonthsdata =  groupBy(retrecords, rdata => rdata.plant_name);
            
        //     retmonthsdata.forEach((item,key) => {
        //         categories.push(mopts[key-1])
        //         var monthwisedata=0;
        //         var totmonthwisedata = 0;
        //         var plantdata = []
        //         if(item.length > 0)
        //         {
        //             item.map((itm) => {
        //                 monthwisedata = monthwisedata+itm.no_of_vins_8_hrs;
        //                 totmonthwisedata = totmonthwisedata+itm.no_of_vins;                        
        //             })
        //         }
        //         var percetn = Math.round((monthwisedata/totmonthwisedata)*100)
        //         retserires.push({
        //             name:key,
        //             y:parseFloat(percetn),
        //             drilldown:key,
        //             vins:monthwisedata,
        //             totalvins:totmonthwisedata
        //         })
        //         // drilldown functionality
        //           var invmmseries = []
        //             if(item.length > 0)
        //             {
        //                 var plantsdata =  groupBy(item, rdata => rdata.trip_type);
        //             //    console.log("plantsdata ", plantsdata)
        //                 plantsdata.forEach((mm,kk) => {
        //                     // console.log("plant kk ",kk)
        //                     // console.log("plant mm ",mm)
        //                     var mwisedata = 0;
        //                     var totmwisedata = 0;
        //                     mm.map((mi) => {
        //                         mwisedata = mwisedata+parseInt(mi.no_of_vins_8_hrs);
        //                         totmwisedata = totmwisedata+parseInt(mi.no_of_vins);
        //                     })
        //                     var percetndrill = Math.round((mwisedata/totmwisedata)*100)
        //                     plantdata.push({
        //                         name:kk,
        //                         y:parseFloat(percetndrill),
        //                         drilldown:key+"-"+kk,
        //                         vins:mwisedata,
        //                         totalvins:totmwisedata
        //                     })
        //                     var ttypes =  groupBy(mm, rdata => rdata.month);
        //                     var troad =[]
        //                     ttypes.forEach((tval,tkey) => {
        //                         var tmwisedata = 0;
        //                         var ttotmwisedata = 0;
                               
        //                         tval.map((titm) => {
        //                             tmwisedata = tmwisedata+parseInt(titm.no_of_vins_8_hrs);
        //                             ttotmwisedata = ttotmwisedata+parseInt(titm.no_of_vins);
        //                         })
        //                         var tpercetndrill = ((tmwisedata/ttotmwisedata)*100).toFixed(2)
        //                         // troad.push([tkey,parseFloat(tpercetndrill)])
        //                         troad.push({
        //                             name:mopts[tkey-1],
        //                             y:parseFloat(tpercetndrill),
        //                             vins:tmwisedata,
        //                             totalvins:ttotmwisedata
        //                         })
        //                     })
        //                     retdrillserires1.push({
        //                         name:key+"-"+kk,
        //                         id:key+"-"+kk,
        //                         data:troad
        //                     })  
        //                 })
        //             }
        //         retdrillserires1.push({
        //             name:key,
        //             id:key,
        //             colorByPoint: true,
        //             data:plantdata
        //         })
        //     })
        //     // console.log("mmseries ", mmseries)
        //     retrieveseries.push({
        //         name:"Plants",
        //         colorByPoint: true,
        //         data:retserires
        //     })

        //     var invoicedagta = []
        //     invoicedagta.push({
        //         series:series,
        //         categories:categories,
        //         drilldown:invoicedrillserires1
        //     })
        //     var retrievaldata = []
        //     retrievaldata.push({
        //         series:retrieveseries,
        //         categories:categories,
        //         drilldown:retdrillserires1
        //     })
        //     // console.log("invoicedagta ", invoicedagta)
        //     // console.log("retrievaldata ", retrievaldata)
        //     this.setState({
        //         invoiceseries:invoicedagta,
        //         retrievalseries:retrievaldata
        //     })
            
        // })
    }
    onLoadPlanData(parameters){
        this.setState({
            loadshow1:'show-m'
        })
        redirectURL.post("consignments/loadplandashboardparams",parameters)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records;
            var allrecords = response.data.allrecords;
            var allrecords8hrs = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(parameters.viewtype == "invoice")
                    {
                        if(item.ret_to_inv_duration_hrs >= 8)
                        {
                            allrecords8hrs.push(item)
                        }
                    }
                    else
                    {
                        if(item.gate_out_to_ret_duration_hrs >= 8)
                        {
                            allrecords8hrs.push(item)
                        }
                    }
                })
            }
            this.setState({
                rowData:records,
                allrecords:allrecords,
                totalvins:allrecords.length,
                totalvins8hrs:allrecords8hrs.length,
                allrecords8hrs:allrecords8hrs,
                loadshow1:'show-n',
                setview:1,
                loadshow:'show-n',
                overly:'show-n',
                filviewtype:parameters.viewtype
            })
            
        })
    
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
        
    }

    
	changeStartMonthHandler = startmonth =>{
        this.setState(
	      { startmonth },
	      () => console.log(`Month Option selected:`, this.state.startmonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectStartMonths(){
        var items = []
        items.push({value:'',label:'Month'});
        items.push({value:'01',label:'Jan'});
        items.push({value:'02',label:'Feb'});
        items.push({value:'03',label:'Mar'});
        items.push({value:'04',label:'Apr'});
        items.push({value:'05',label:'May'});
        items.push({value:'06',label:'Jun'});
        items.push({value:'07',label:'Jul'});
        items.push({value:'08',label:'Aug'});
        items.push({value:'09',label:'Sep'});
        items.push({value:'10',label:'Oct'});
        items.push({value:'11',label:'Nov'});
        items.push({value:'12',label:'Dec'}); 
		return items
    }
    
    
	changeEndMonthHandler = endmonth =>{
        this.setState(
	      { endmonth },
	      () => console.log(`Month Option selected:`, this.state.endmonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectEndMonths(){
        var items = []
        items.push({value:'',label:'Month'});
        items.push({value:'01',label:'Jan'});
        items.push({value:'02',label:'Feb'});
        items.push({value:'03',label:'Mar'});
        items.push({value:'04',label:'Apr'});
        items.push({value:'05',label:'May'});
        items.push({value:'06',label:'Jun'});
        items.push({value:'07',label:'Jul'});
        items.push({value:'08',label:'Aug'});
        items.push({value:'09',label:'Sep'});
        items.push({value:'10',label:'Oct'});
        items.push({value:'11',label:'Nov'});
        items.push({value:'12',label:'Dec'}); 
		return items
    }
        
	changeStartYearHandler = startyear =>{
        this.setState(
	      { startyear },
	      () => console.log(`Year Option selected:`, this.state.startyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectStartYear(){
        var items = []
        var cyear = moment.parseZone().format("YYYY");
        items.push({value:'',label:'Start Year'});
        for(var y = 2021;y<=cyear;y++)
        {
            items.push({value:y,label:y});
        } 
		return items
    }
    
	changeEndYearHandler = endyear =>{
        this.setState(
	      { endyear },
	      () => console.log(`Year Option selected:`, this.state.endyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectEndYear(){
        var items = []
        var cyear = moment.parseZone().format("YYYY");
        items.push({value:'',label:'Start Year'});
        for(var y = 2021;y<=cyear;y++)
        {
            items.push({value:y,label:y});
        }
		return items
    }
	changeFinancialYearHandler = financialyear =>{
        this.setState(
	      { financialyear },
	      () => console.log(`Financial Option selected:`, this.state.financialyear)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectFinancialYears(){
        var items = []
        items.push({value:'',label:'Select'});
        var fys = this.state.financialyears;
        if(fys.length > 0)
        {
            fys.map((item) => {
                items.push({value:item,label:item});
            })
        }
        
		return items
    }
    onClickFilterData(){
        this.setState({
            loadshow:"show-m"
        })
        var startmonth = this.state.startmonth
        var endmonth = this.state.endmonth
        var startyear = this.state.startyear
        if(startmonth.value != "" && endmonth.value != "" && startyear.value != "")
        {
            $("#errMsg1").css("display","none");
            var parameters = {
                startmonth:parseInt(startmonth.value),
                endmonth:parseInt(endmonth.value),
                startyear:parseInt(startyear.value)
            }
            this.onLoadData(parameters);
            var viewtype = this.state.viewtype
            var parameters1= {
                startmonth:parseInt(startmonth.value),
                endmonth:parseInt(endmonth.value),
                startyear:parseInt(startyear.value),
                viewtype:viewtype.value
            }
            this.onLoadPlanData(parameters1) 

            // redirectURL.post("consignments/loadplandashboardparamschart",parameters)
            // .then((response) => {
            //     console.log("response ", response.data)
            //     var records = response.data.records;
                
            //     this.setState({
            //         rowData:records
            //     })
                
            // })
        }
        else{
            $("#errMsg1").css("display","block");
            this.setState({
                // show:true,
                // basicType:"danger",
                // basicTitle:"All * fields are mandatory",
                loadshow:'show-n',
            })
        }
    }
    onClickFilterDataFinancialYearData(){
        
        var viewtype = this.state.viewtype
        var startmonth = this.state.startmonth
        var endmonth = this.state.endmonth
        var startyear = this.state.startyear
        if(viewtype.value != "")
        {
           
            var parameters = {
                startmonth:parseInt(startmonth.value),
                endmonth:parseInt(endmonth.value),
                startyear:parseInt(startyear.value),
                viewtype:viewtype.value
            }
            this.onLoadPlanData(parameters);
            // var parameters = {
            //     financialyear:financialyear.value,
            //     viewtype:viewtype.value
            // }
            // redirectURL.post("consignments/loadplandashboardfy",parameters)
            // .then((response) => {
            //     // console.log("response ", response.data)
            //     var records = response.data.records;
                
            //     this.setState({
            //         rowData:records,
            //         loadshow:'show-n',
            //     })
                
            // })
        }
        else{
            $("#errMsg1").css("display","none");
            this.setState({
                // show:true,
                // basicType:"danger",
                // basicTitle:"All * fields are mandatory",
                loadshow:'show-n',
            })
        }
    }
    onClickFilterFinancialYearData(){
        this.setState({
            loadshow:"show-m"
        })
        var financialyear = this.state.financialyear
        var viewtype = this.state.viewtypeother
        if(financialyear !=undefined && viewtype !=undefined)
        {
            if(financialyear.value != undefined && financialyear.value != "" && viewtype.value != undefined && viewtype.value != "")
            {
                $("#errMsg").css("display","none");
                var parameters = {
                    financialyear:financialyear.value,
                    viewtype:viewtype.value
                }
                redirectURL.post("consignments/loadplandashboardfy",parameters)
                .then((response) => {
                    // console.log("response ", response.data)
                    var records = response.data.records;
                    var allrecords = response.data.allrecords;
                    var allrecords8hrs = []
                    if(records.length > 0)
                    {
                        records.map((item) => {
                            if(parameters.viewtype == "invoice")
                            {
                                if(item.ret_to_inv_duration_hrs >= 8)
                                {
                                    allrecords8hrs.push(item)
                                }
                            }
                            else
                            {
                                if(item.gate_out_to_ret_duration_hrs >= 8)
                                {
                                    allrecords8hrs.push(item)
                                }
                            }
                        })
                    }
                    this.setState({
                        rowData:records,
                        allrecords:allrecords,
                        totalvins:allrecords.length,
                        totalvins8hrs:allrecords8hrs.length,
                        allrecords8hrs:allrecords8hrs,
                        loadshow:'show-n',
                    })
                    
                })
            
                redirectURL.post("consignments/loadplandashboardfychart",parameters)
                .then((response) => {
                    // console.log("response ", response.data)
                    var recordarr = response.data.records;
                    var invrecords = []
                    var retrecords = []
                    if(recordarr.length > 0)
                    {
                        recordarr.map((item) => {
                            if(item.data_type == "Invoice to Retrival")
                            {
                                invrecords.push(item)
                            }
                            else{
                                retrecords.push(item)
                            }
                        })
                    }
                    var mopts = ['Jan', 'Feb', 'Mar', "Apr", 'May', 'Jun', 'Jul', 'Aug', 
                'Sep', 'Oct', 'Nov', 'Dec'] 
                    // console.log("records ", records)
                    var series = []
                    var categories = []
                    var retrieveseries = []
                    var monthsort =  invrecords.sort(GetSortOrder("month"));
                    var monthsdata =  groupBy(monthsort, rdata => rdata.month);
                    var invoiceserires = []
                    var invoicedrillserires1 = []
                
                    monthsdata.forEach((item,key) => {
                        categories.push(mopts[key-1])
                        var monthwisedata=0;
                        var totmonthwisedata = 0;
                        var plantdata = []
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                monthwisedata = monthwisedata+itm.no_of_vins_8_hrs;
                                totmonthwisedata = totmonthwisedata+itm.no_of_vins;                        
                            })
                        }
                        var percetn = Math.round((monthwisedata/totmonthwisedata)*100)
                        invoiceserires.push({
                            name:mopts[key-1],
                            y:parseFloat(percetn),
                            drilldown:mopts[key-1]
                        })
                        // drilldown functionality
                        var invmmseries = []
                            if(item.length > 0)
                            {
                                var plantsdata =  groupBy(item, rdata => rdata.plant_name);
                            console.log("/plantsdata ", plantsdata)
                                plantsdata.forEach((mm,kk) => {
                                    // console.log("plant kk ",kk)
                                    // console.log("plant mm ",mm)
                                    var mwisedata = 0;
                                    var totmwisedata = 0;
                                    mm.map((mi) => {
                                        mwisedata = mwisedata+parseInt(mi.no_of_vins_8_hrs);
                                        totmwisedata = totmwisedata+parseInt(mi.no_of_vins);
                                    })
                                    var percetndrill = Math.round((mwisedata/totmwisedata)*100)
                                    plantdata.push({
                                        name:kk,
                                        y:parseFloat(percetndrill),
                                        drilldown:mopts[key-1]+"-"+kk
                                    })
                                    var ttypes =  groupBy(mm, rdata => rdata.trip_type);
                                    var troad =[]
                                    ttypes.forEach((tval,tkey) => {
                                        var tmwisedata = 0;
                                        var ttotmwisedata = 0;
                                    
                                        tval.map((titm) => {
                                            tmwisedata = tmwisedata+parseInt(titm.no_of_vins_8_hrs);
                                            ttotmwisedata = ttotmwisedata+parseInt(titm.no_of_vins);
                                        })
                                        var tpercetndrill = ((tmwisedata/ttotmwisedata)*100).toFixed(2)
                                        troad.push([tkey,parseFloat(tpercetndrill)])
                                    })
                                    invoicedrillserires1.push({
                                        name:mopts[key-1]+"-"+kk,
                                        id:mopts[key-1]+"-"+kk,
                                        data:troad
                                    })  
                                })
                            }
                        invoicedrillserires1.push({
                            name:mopts[key-1],
                            id:mopts[key-1],
                            colorByPoint: true,
                            data:plantdata
                        })
                    })
                    // console.log("mmseries ", mmseries)
                    series.push({
                        name:"Months",
                        colorByPoint: true,
                        data:invoiceserires
                    })

                    var retrieveseries = []
                    var retmonthsort =  retrecords.sort(GetSortOrder("month"));
                    var retmonthsdata =  groupBy(retmonthsort, rdata => rdata.month);
                    var retserires = []
                    var retdrillserires1 = []
                
                    retmonthsdata.forEach((item,key) => {
                        categories.push(mopts[key-1])
                        var monthwisedata=0;
                        var totmonthwisedata = 0;
                        var plantdata = []
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                monthwisedata = monthwisedata+itm.no_of_vins_8_hrs;
                                totmonthwisedata = totmonthwisedata+itm.no_of_vins;                        
                            })
                        }
                        var percetn = Math.round((monthwisedata/totmonthwisedata)*100)
                        retserires.push({
                            name:mopts[key-1],
                            y:parseFloat(percetn),
                            drilldown:mopts[key-1]
                        })
                        // drilldown functionality
                        var invmmseries = []
                            if(item.length > 0)
                            {
                                var plantsdata =  groupBy(item, rdata => rdata.plant_name);
                            //    console.log("plantsdata ", plantsdata)
                                plantsdata.forEach((mm,kk) => {
                                    // console.log("plant kk ",kk)
                                    // console.log("plant mm ",mm)
                                    var mwisedata = 0;
                                    var totmwisedata = 0;
                                    mm.map((mi) => {
                                        mwisedata = mwisedata+parseInt(mi.no_of_vins_8_hrs);
                                        totmwisedata = totmwisedata+parseInt(mi.no_of_vins);
                                    })
                                    var percetndrill = Math.round((mwisedata/totmwisedata)*100)
                                    plantdata.push({
                                        name:kk,
                                        y:parseFloat(percetndrill),
                                        drilldown:mopts[key-1]+"-"+kk
                                    })
                                    var ttypes =  groupBy(mm, rdata => rdata.trip_type);
                                    var troad =[]
                                    ttypes.forEach((tval,tkey) => {
                                        var tmwisedata = 0;
                                        var ttotmwisedata = 0;
                                    
                                        tval.map((titm) => {
                                            tmwisedata = tmwisedata+parseInt(titm.no_of_vins_8_hrs);
                                            ttotmwisedata = ttotmwisedata+parseInt(titm.no_of_vins);
                                        })
                                        var tpercetndrill = ((tmwisedata/ttotmwisedata)*100).toFixed(2)
                                        troad.push([tkey,parseFloat(tpercetndrill)])
                                    })
                                    retdrillserires1.push({
                                        name:mopts[key-1]+"-"+kk,
                                        id:mopts[key-1]+"-"+kk,
                                        data:troad
                                    })  
                                })
                            }
                        retdrillserires1.push({
                            name:mopts[key-1],
                            id:mopts[key-1],
                            colorByPoint: true,
                            data:plantdata
                        })
                    })
                    // console.log("mmseries ", mmseries)
                    retrieveseries.push({
                        name:"Months",
                        colorByPoint: true,
                        data:retserires
                    })

                    var invoicedagta = []
                    invoicedagta.push({
                        series:series,
                        categories:categories,
                        drilldown:invoicedrillserires1
                    })
                    var retrievaldata = []
                    retrievaldata.push({
                        series:retrieveseries,
                        categories:categories,
                        drilldown:retdrillserires1
                    })
                    // console.log("invoicedagta ", invoicedagta)
                    // console.log("retrievaldata ", retrievaldata)
                    this.setState({
                        invoiceseries:invoicedagta,
                        retrievalseries:retrievaldata
                    })
                    
                })
            }
            else
            {
                $("#errMsg").css("display","block");
                this.setState({
                    // show:true,
                    // basicType:"danger",
                    // basicTitle:"All * fields are mandatory",
                    loadshow:'show-n',
                })
            }
        }
        else
        {
            $("#errMsg").css("display","block");
            this.setState({
                // show:true,
                // basicType:"danger",
                // basicTitle:"All * fields are mandatory",
                loadshow:'show-n',
            })
        }
    }
    changeViewTypeHandler = viewtype =>{
        this.setState(
	      { viewtype },
	    //   () => {
        //     this.onClickFilterDataFinancialYearData()
        //   }
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }

    changeViewTypeHandlerOther = viewtypeother => {
        this.setState(
            { viewtypeother },
          //   () => {
          //     this.onClickFilterDataFinancialYearData()
          //   }
          );
    }
    onClickCounter(clickable){
        if(clickable == "all")
        {
            this.setState({
                rowData:this.state.allrecords
            })
        }
        if(clickable == "totalvins8hrs")
        {
            this.setState({
                rowData:this.state.allrecords8hrs
            })
        }
    }
    
    closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    render(){
        var filviewtype = this.state.filviewtype;
        console.log("filviewtype ", filviewtype)
        if(filviewtype == "invoice")
        {
            var hideinv = false;
            var hideret = true;
        }
        else
        {
            var hideinv = true;
            var hideret = false;
        }
       var columnwithDefs = [
            {
               headerName:"Vin No",
               field:"vin",
               width:140,
               resize:true,
               filter:true
            },
            {
                headerName:"Plant Name",
                field:"plant_name",
                width:140,
                resize:true,
                filter:true
            },
            {
                headerName:"Trip Type",
                field:"trip_type",
                width:140,
                resize:true,
                filter:true
            },
            {
                headerName:"Load No",
                field:"load_no",
                width:100,
                resize:true,
                filter:true
            },
            {
                headerName:"SBVH Code",
                field:"sbvh",
                width:120,
                resize:true,
                filter:true
            },
            {
                headerName:"For Code",
                field:"for_code",
                width:100,
                resize:true,
                filter:true
            },
            {
                headerName:"Month",
                field:"month",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.month != "")
                        {
                            var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
                            'September', 'October', 'November', 'December'] 
                            return mopts[parseInt(params.data.month)-1];
                        }
                    }
                    catch(e){

                    }
                },
                resize:true,
                filter:true
            },
            {
                headerName:"Year",
                field:"year",
                width:140,
                resize:true,
                filter:true
            },
            {
                headerName:"Financial Year",
                field:"financial_year",
                width:140,
                resize:true,
                filter:true
            },
            {
                headerName:"Invoice Date",
                field:"invoice_date",
                hide:hideinv,
                width:140,
                resize:true,
                filter:true,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.invoice_date);
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Retrival Date",
                field:"retrival_date",
                width:140,
                // hide:hideret,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.retrival_date);
                    }
                    catch(e){
                        return "";
                    }
                },
                resize:true,
                filter:true
            },
            {
                headerName:"Gateout Time",
                field:"gate_out_time",
                width:140,
                hide:hideret,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.gate_out_time);
                    }
                    catch(e){
                        return "";
                    }
                },
                resize:true,
                filter:true
            },
            
            {
                headerName:"Invoice to Retrieval Duration (Hrs)",
                field:"ret_to_inv_duration_hrs",
                hide:hideinv,
                width:140,
                resize:true,
                filter:true
            },
            {
                headerName:"Retrieval to Gateout Duration (Hrs)",
                field:"gate_out_to_ret_duration_hrs",
                width:140,
                hide:hideret,
                resize:true,
                filter:true
            }
       ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                   
                    <div className=" col-xl-12 col-lg-12">
                        <Nav tabs className={'tabs-color'}>
                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                    Chart
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                    Data
                                </NavLink>
                            </NavItem>
                           
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                    {/* <LoadPlantViewChart /> */}
                                        {/* <div className="card">
                                            <div className="row card-body">
                                                <div className="row col-sm-12 col-xl-12  col-lg-12">
                                                    <form method="POST" className="col-xl-8 col-lg-8"> 
                                                                                            
                                                    <div className="row">                                       
                                                        <div className="form-group col-xl-3 col-lg-3">
                                                            <lable> Start Month:</lable>
                                                            <Select placeholder={"Start Month"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.startmonth} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeStartMonthHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectStartMonths()} 
                                                            required />                                        
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3">
                                                            <lable>End Month:</lable>
                                                            <Select placeholder={"End Month"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.endmonth} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeEndMonthHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectEndMonths()} 
                                                            required />                                        
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3">
                                                            <lable> Year:</lable>
                                                            <Select placeholder={"Start Year"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.startyear} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeStartYearHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectStartYear()} 
                                                            required />                                        
                                                        </div>
                                                        
                                                        <div className="form-group col-xl-3 col-lg-3">                                        
                                                            <button 
                                                            type="button" 
                                                            className="btn btn-warning mt-22p"
                                                            onClick={this.onClickFilterData.bind(this)}
                                                            >Submit</button>
                                                        
                                                        </div>
                                                    </div>
                                                    </form>
                                                    <form method="POST" className="col-xl-4 col-lg-4">               
                                                    <div className="row">                                          
                                                        <div className="form-group col-xl-9 col-lg-9">
                                                        
                                                            <lable>Financial Year:</lable>
                                                            <Select placeholder={"Select"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.financialyear} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeFinancialYearHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectFinancialYears()} 
                                                            required />
                                                        
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3">                                        
                                                            <button 
                                                            type="button" 
                                                            className="btn btn-warning mt-22p"
                                                            onClick={this.onClickFilterFinancialYearData.bind(this)}
                                                            >Submit</button>
                                                        
                                                        </div>
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card">
                                            <div className="card-header">
                                                <h5>
                                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                                </h5>
                                            </div>
                                            <div className="row card-body">
                                                <div className="row col-sm-12 col-xl-12  col-lg-12">
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.gurjatchart.length > 0)?
                                                        this.state.gurjatchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Gujarat - Invoice to Retrieval (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                dcategories={item.dcategories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.gurjatretchart.length > 0)?
                                                        this.state.gurjatretchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Gujarat - Retrieval to Exit (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.gurgoanchart.length > 0)?
                                                        this.state.gurgoanchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Gurgoan - Invoice to Retrieval (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.gurgoanretchart.length > 0)?
                                                        this.state.gurgoanretchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Gurgoan - Retrieval to Exit (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.manesarchart.length > 0)?
                                                        this.state.manesarchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Manesar - Invoice to Retrieval (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.manesarretchart.length > 0)?
                                                        this.state.manesarretchart.map(item => 
                                                            <LoadPlanMultiChart
                                                                title={"Manesar - Retrieval to Exit (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>

                                                    {/*                                                     
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.invoiceseries.length > 0)?
                                                        this.state.invoiceseries.map(item => 
                                                            <LoadColumnChart
                                                                title={"Invoice to Retrieval (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                        
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        {(this.state.retrievalseries.length > 0)?
                                                        this.state.retrievalseries.map(item => 
                                                            <LoadColumnChart
                                                                title={"Retrieval to Exit (>8 Hrs)"}
                                                                subtitle={""}
                                                                series={item.series}
                                                                categories={item.categories}
                                                                yAxisTitle={"Percentage of Vins ( > 8 Hrs)"}
                                                                drilldown={item.drilldown}
                                                            />
                                                        )
                                                        :""}
                                                    </div> */}
                                                
                                                </div>
                                                <div className="col-xl-12 col-lg-12 mt-40p mb-40p">
                                                    Note: Other OEM Sales and Vins which are carried by Road have not been considered
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                    {/* <LoadPlantViewData /> */}
                                        <div className="card">
                                            <div className="row">
                                                <div className="row col-sm-12 col-xl-12  col-lg-12">
                                                    <form method="POST" className="col-xl-8 col-lg-8"> 
                                                                                            
                                                    <div className="row card-body">                                       
                                                        <div className="form-group col-xl-3 col-lg-3">
                                                            <lable> Start Month *: </lable>
                                                            <Select placeholder={"Select Month"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.startmonth} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeStartMonthHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectStartMonths()} 
                                                            required />                                        
                                                        </div>
                                                        <div className="form-group col-xl-3 col-lg-3">
                                                            <lable>End Month *:</lable>
                                                            <Select placeholder={"Select Month"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.endmonth} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeEndMonthHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectEndMonths()} 
                                                            required />                                        
                                                        </div>
                                                        <div className="form-group col-xl-2 col-lg-2">
                                                            <lable> Year *:</lable>
                                                            <Select placeholder={"Start Year"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.startyear} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeStartYearHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectStartYear()} 
                                                            required />                                        
                                                        </div>
                                                        <div className="form-group col-xl-4 col-lg-4">
                                                            <lable>View Type *:</lable>
                                                            <Select placeholder={"View Type"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.viewtype} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeViewTypeHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={[
                                                                {
                                                                    value:"invoice",
                                                                    label:"Invoice to Retrieval"
                                                                },
                                                                {
                                                                    value:"retrieval",
                                                                    label:"Retrieval to Exit"
                                                                }
                                                            ]} 
                                                            required />                                      
                                                        </div>
                                                        
                                                        <div className="form-group col-xl-4 col-lg-4">                                        <p id="errMsg1" style={{color:"red"}}>All * Fields are mandatory</p>
                                                            <button 
                                                            type="button" 
                                                            className="btn btn-warning mt-22p"
                                                            onClick={this.onClickFilterData.bind(this)}
                                                            >Submit</button>
                                                        
                                                        </div>
                                                    </div>
                                                    </form>
                                                    <form method="POST" className="col-xl-4 col-lg-4" style={{borderLeft:"1px solid #ccc"}}>               
                                                    <div className="row card-body">                                          
                                                        <div className="form-group col-xl-5 col-lg-5">
                                                        
                                                            <lable>Financial Year *:</lable>
                                                            <Select placeholder={"Select "} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.financialyear} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeFinancialYearHandler.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={this.selectFinancialYears()} 
                                                            required />
                                                        
                                                        </div>
                                                        <div className="form-group col-xl-7 col-lg-7">
                                                            <lable>View Type *:</lable>
                                                            <Select placeholder={"View Type"} 
                                                            // closeMenuOnSelect={false} 
                                                            value={this.state.viewtypeother} 
                                                            className="border-radius-0" 
                                                            onChange={this.changeViewTypeHandlerOther.bind(this)} 
                                                            style={{borderRadius:"0px"}} 
                                                            options={[
                                                                {
                                                                    value:"invoice",
                                                                    label:"Invoice to Retrieval"
                                                                },
                                                                {
                                                                    value:"retrieval",
                                                                    label:"Retrieval to Exit"
                                                                }
                                                            ]} 
                                                            required />                                       
                                                        </div>
                                                        <div className="form-group col-xl-12 col-lg-12">                                         <p id="errMsg" style={{color:"red"}}>All * Fields are mandatory</p>
                                                            <button 
                                                            type="button" 
                                                            className="btn btn-warning mt-22p"
                                                            onClick={this.onClickFilterFinancialYearData.bind(this)}
                                                            >Submit</button>
                                                        
                                                        </div>
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                                            <div className="card">
                                                <div className="card-body" id="counterCardBody">
                                                    <div className="crm-numbers pb-0">
                                                        <div className="row">
                                                            <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                                                <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Vins<br /><br /></span>
                                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalvins != '')?this.state.totalvins:0}/></span></h4>
                                                            </div>
                                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"totalvins8hrs")}>
                                                                <span className="f13"><i className="icofont icofont-truck-alt txt-danger f22"></i><br /> Total Vin ( &gt;= 8 Hrs)<br /></span>
                                                                <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.totalvins8hrs != '')?this.state.totalvins8hrs:0}/></span></h4>
                                                                
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h5>
                                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                                </h5>
                                            </div>
                                            <div className="row card-body">
                                                <div className="row col-sm-12 col-xl-12  col-lg-12">
                                                    <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
                                                        <AgGridReact
                                                            modules={this.state.modules}
                                                            columnDefs={columnwithDefs}
                                                            defaultColDef={this.state.defaultColDef}
                                                            rowData={this.state.rowData}
                                                            enableCharts={true}
                                                            enableRangeSelection={true}
                                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                            onGridReady={this.onGridReady}
                                                            onGridState={this.onGridState}
                                                            statusBar={this.state.statusBar}
                                                            sideBar={this.state.sideBar}
                                                            rowClassRules={this.state.rowClassRules}
                                                            paginationPageSize={this.state.paginationPageSize}
                                                            pagination={true}
                                                            frameworkComponents={this.state.frameworkComponents}
                                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                                            stopEditingWhenGridLosesFocus={true}
                                                            enableCellChangeFlash={false}
                                                            gridOptions={{
                                                                context: { componentParent: this }
                                                            }}
                                                            masterDetail={true}
                                                            autoHeight={true}
                                                            headerHeight={this.state.headerHeight}
                                                            rowSelection={this.state.rowSelection}
                                                            suppressRowClickSelection={true}
                                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                        />
                                                    </div>
                                                
                                                </div>
                                                
                                            </div>
                                            <div className={"dataLoadpage " +(this.state.loadshow1)}>
                                                </div>
                                                <div className={"dataLoadpageimg " +(this.state.loadshow1)}>
                                                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                                                </div>
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            
                        </TabContent>

                        
                        
                    </div>

                </div> 
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}     

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
function arrayMax(arr) {
return arr.reduce(function (p, v) {
    return ( p > v ? p : v );
});
}
