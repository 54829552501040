import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import EditItem from './editActionComponent';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class ManualLocationUpdate extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
    	    modules: AllModules,
      	      defaultColDef: {
      	        sortable: true,
				  filter: true,
				  resizable: true
      	        
      	      },
      	      rowData: null,
      	      
      	      rowModelhighchartoptionsType: 'enterprise',
      	      rowGroupPanelShow: 'always',
      	      animateRows: true,
      	      debug: true,
      	      showToolPanel: false,
      	      rowSelection: "single" ,
      	      context: { componentParent: this },
      	      frameworkComponents: {
      	    	  editItem:EditItem
      	      },
            usergridstate:[],
            screenurl:"",
            screentitle:""
            
        };
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = "SNDG";
		let departmentName = " (Sales and Dispatch) ";
		let currentAddGeoFenceLink = "/sndaddlocation";
		switch(pathToMatch) {
			case "/sndmanuallocations":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/sndaddlocation";
				break;
            case "/prtmanuallocations":
                department='LOG-PRT';
                departmentName = " (Spare Parts) ";
                currentAddGeoFenceLink = "/prtaddlocation";
                break;
            case "/tnpmanuallocations":
                department='LOG-TNP';
                departmentName = " (Train and Production) ";
                currentAddGeoFenceLink = "/tnpaddlocation";
                break;
            case "/scmanuallocations":
                department='LOG-SC';
                departmentName = " (Supply Chain) ";
                currentAddGeoFenceLink = "/scaddlocation";
                break;
			default:
				//console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName,
			currentAddGeoFenceLink:currentAddGeoFenceLink
		});
		return department;
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});

    	redirectURL.post("/consignments/manuallocation").
		then(
			(response)=>{
				let records = (response.data) ? response.data : [];
			
				//console.log("/geofence/lists records = ", records);
				this.setState({
					rowData:records
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }
   
    getDepartmentCodes = (params) => {
    	var depts = params.value;
    	var departments=[];
		var deptname='';
		
    	for(var item in depts)
    	{
    		deptname = deptname + depts[item].value +", ";
    		//console.log(depts[item].dept_code);
    		departments.push(depts[item].value);
    	}
    	//console.log("departments",deptname);
    	//console.log("departments",departments);
    	return deptname;
    }
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
    render(){
		var departmentCode = this.state.departmentCode;
		var columnwithDefs;
		if(departmentCode == 'SNDG')
		{
			columnwithDefs = [

				{
					field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var rowid = params.data._id;
						var htmloption = '<a href="/editlocation/SNDG/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
				},
				{
					headerName: "Latitude",
					field: "latitude",
					width: 180
				},
				{
					headerName: "Longitude",
					field: "longitude",
					width: 180
				},
				{
					headerName: "Time at the location",
					field: "timestamp",
					width: 180,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				}
			];
		}
		if(departmentCode == 'LOG-PRT')
		{
			columnwithDefs = [

				{
					field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var rowid = params.data._id;
						var htmloption = '<a href="/editlocation/LOG-PRT/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
				},
				{
					headerName: "Latitude",
					field: "latitude",
					width: 180
				},
				{
					headerName: "Longitude",
					field: "longitude",
					width: 180
				},
				{
					headerName: "Time at the location",
					field: "timestamp",
					width: 180,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				}
				
			];
		}
		if(departmentCode == 'LOG-TNP')
		{
			columnwithDefs = [

				{
					field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var rowid = params.data._id;
						var htmloption = '<a href="/editlocation/LOG-TNP/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
				},
				{
					headerName: "Latitude",
					field: "latitude",
					width: 180
				},
				{
					headerName: "Longitude",
					field: "longitude",
					width: 180
				},
				{
					headerName: "Time at the location",
					field: "timestamp",
					width: 180,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				}
				
			];
		}
		if(departmentCode == 'LOG-SC')
		{
			columnwithDefs = [

				{
					field:'_id',
					headerName:'',
					width: 72,
					cellRenderer:function(params){
						var rowid = params.data._id;
						var htmloption = '<a href="/editlocation/LOG-SC/'+rowid+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
				},
				{
					headerName: "Latitude",
					field: "latitude",
					width: 180
				},
				{
					headerName: "Longitude",
					field: "longitude",
					width: 180
				},
				{
					headerName: "Time at the location",
					field: "timestamp",
					width: 180,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				}
				
			];
		}
		
    	
        return (
            
    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Manual Location Update {this.state.currentDepartmentTitle} </span>
				       				{/* <a href="/manage/sndaddgeofence" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a> */}
				       				<a href={this.state.currentAddGeoFenceLink} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Location</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
                               <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />

                                </div>
                            </div>
				   		</div>	
				   	</div>
				 </div>
            </div>
              
        );
    }
}


