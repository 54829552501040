import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import Select from 'react-select';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
// import Counters from './containercountersComponent';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
 
//import ConsignmentKpiTopCountersComponent from './consignmentkpitopcounters.component';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import ConsignmentActions from '../manage/routemapComponent';
import ConsignmentNoActions from '../manage/routemapNoComponent';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
import Breadcrumb from '../common/breadcrumb.component';
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

const fs = require('fs');


 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ConsignmentsKpi extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageTitle: "KPI Reports",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",

			uploadDivWidth:'0%',
			consignerCode: "ALL",
			allUniqueConsignerCodes: [],
			currentKpi: null,
			currentPageTitle: null,
			
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			transits:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			columnsInTransitExceptions: [
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 100,
					filter: true,
					pivot: false,
				},
				{
					headerName: "Truck Number",
					field: "truck_no",
					width: 150,
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					width: 120,   
					//pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.expected_trip_end);
						 
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },	          
				  },          
				  {
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 120,   
					//pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						//console.log(" ETA ", params.data)
						  if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						  {
							  return getDDMMYYYYHHMMSS(params.data.expected_trip_end);
						  }
						  else{
							  return getDDMMYYYYHHMMSS(params.data.revised_trip_end);
						  }
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					cellClass:function(params){
						let hourDifference = 0;
						if (params.data.revised_trip_end && params.data.expected_trip_end) {
							let revisedDateOnlyMoment  = moment(params.data.revised_trip_end);
							let expectedDateOnlyMoment  = moment(params.data.expected_trip_end);
							hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							//console.log("diff= ", params.data.consignment_code, hourDifference);
						} else {
							//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						}

						if(hourDifference >= 1)
						{
							return 'bgColorDangerMedium'
						}

						if(hourDifference <= -1)
						{
							return 'bgColorSuccessMedium'
						}
						else{
							return ''
						}
					}
				  },

				{
					headerName: "Status",
					field: "status",
					width: 120, 
					//pinned: 'left',              
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						//console.log("GPS ", params.data.gps_data_available);
						if(params.data.status == 1)
						{
							return "Inside Plant"
						}
						else if(params.data.status == 2)
						{
							return "In Transit"
						}
						else if(params.data.status == 3)
						{
							return "Near Destination"
						}
						else if(params.data.status == 4)
						{
							return "Reached Destination"
						}	
						
						else if(params.data.status == 5)
						{
							return "Left Destination"
						}					
						else{
							return ""
						}
						 
					} 	          
				  },   
	  	        /*{
		          headerName: "Transporter Name",
                  field: "transporter_name",
                  valueGetter:function(params){
					  //console.log(params.data.transporter);
					  if (params.data.hasOwnProperty('transporter'))
					  {
						  if (params.data.transporter.length > 0)
						  {
							return params.data.transporter[0].transporter_name;
						  }
					  }
                        
                  },
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true	          
				}, 
				*/  
				{
					headerName: "GPS Provider",
					field: "truck",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter:function(params){
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].actual_lspuser;
							}
						}
						  
					},	          
				},    
				{
					headerName: "Invoice Date",
					field: "invoice_time",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter:function(params){
						  if(params.data.invoice_time != '' || params.data.invoice_time != undefined)
						  {
							  return getDDMMYYYYHHMMSS(params.data.invoice_time);
						  }
						  else{
							  return "NA";
						  }
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },          
				},             
	  	        {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    width: 150,
                    filter: "agSetColumnFilter",
					cellRenderer:''	,resizable: true    	          
                },               
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  width: 150,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true	    	          
                },             
                {
                  headerName: "Department Code",
                  field: "dept_code",
                  width: 120,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:''  ,resizable: true  	          
                },          
                
                {
                    headerName: "Gate in Time",
                    field: "gate_in_time",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.gate_in_time);
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					resizable: true	    	          
				},
				{
                    headerName: "GPS Gate in Time",
                    field: "inside_fence",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.inside_fence);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                },				
                {
                    headerName: "Gate out Time",
                    field: "gate_out_time",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.gate_out_time);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },  
                },
                {
                    headerName: "GPS Gate out Time",
                    field: "outside_fence",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.outside_fence);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },   
				},

                {
                    headerName: "Reached Destination At",
                    field: "first_dealer_reported",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.recent_dealer_reported);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },  
				},

                {
                    headerName: "Left Destination At",
                    field: "left_dealer_marked",
                    width: 180,                 
                    filter: "agSetColumnFilter",
					//cellRenderer:'dateFormater',
					valueGetter:function(params){
						return getDDMMYYYYHHMMSS(params.data.left_dealer_marked);
					},
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },  
				},
                {
                    headerName:"Local Movement(Yes/No)",
                    field:"local_movement",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true, 
					
                },			
                {
                    headerName: "Transit Time (Days)",
                    field:"transit_time",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Expected Distance (KM)",
                    field:"distance_in_km",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Cluster",
                    field:"cluster",
                    width:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Vehicle Mode",
                    field:"vehicle_mode",
                    widht:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },                             				
                {
                    headerName: "Items",
                    field: "items",
                    width: 200,                 
                    filter: "agSetColumnFilter",
					cellRenderer:'' ,resizable: true   	          
				},
			],
      	      
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                consignmentActions:ConsignmentActions,
				consignmentNoActions:ConsignmentNoActions
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			suppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"highlitRow": "data.sea_transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'"
            },
            
            containerCounter:'',
			istabactive:'highseas',
			
			columnwithDefs : [],
			consignerCodeList: []
			
		}
	
		//this.onShowRouteMap = this.onShowRouteMap.bind(this);
		//this.onTabClick = this.onTabClick.bind(this);

		this.selectOptionsItems = this.selectOptionsItems.bind(this);
	}
	componentDidMount(){

		let deptAndKpi = this.getDeptAndKpi();
		console.log("IN componentDidMount, DeptAND PI = ", deptAndKpi);
		
		 // Fetch list of Consignments based on kpi and department
		redirectURL.post('/consignments/consignmentsforkpibydept', deptAndKpi,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		.then(response => {
			console.log("getConsigementsForKpi response  = ", response);
			let consignmentsData = null;
			let consignerCodesData = null;
			if(response.data) {
				consignmentsData = response.data.consignments;
				consignerCodesData = response.data.consignerCodes;
				console.log("consignmentsData  = ", consignmentsData);
				console.log("consignerCodesData  = ", consignerCodesData, consignerCodesData.length);
			}
			this.setState({
				rowData:consignmentsData,
				loadshow:'show-n',
				consignerCodeList:consignerCodesData
			})
		})
		.catch(function (error) {
			console.error("Error in getConsigementsForKpi response  = ", error);
		});

		
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	};
	
	getDeptAndKpi(){
		let dept = null;
		let currentKpi = null;
		let currentUrl = this.props.match.url;
		let kpiName = null;
		let kpiNames = {
			"intransit": " - In Transit Delays",
			"nightdriving": " - Night Driving Exceptions",
			"overspeeding": " - Overspeeding Exceptions",
			"enroute": " - Enroute Exceptions",
			"unloading": " - Unloading Delay",
			"loading": " - Loading Delay",
		};
		console.log("currentUrl = ", currentUrl);
		switch (currentUrl) {
			case '/prtkpiintransit':
				dept='LOG-PRT';
				currentKpi = 'intransit';
				break;
			case '/prtkpinightdriving':
				dept='LOG-PRT';
				currentKpi = 'nightdriving';
				break;
			case '/prtkpioverspeeding':
				dept='LOG-PRT';
				currentKpi = 'overspeeding';
				break;
			case '/prtkpienroute':
				dept='LOG-PRT';
				currentKpi = 'enroute';
				break;
			case '/prtkpiunloading':
				dept='LOG-PRT';
				currentKpi = 'unloading';
				break;
			case '/prtkpiloading':
				dept='LOG-PRT';
				currentKpi = 'loading';
				break;

			case '/sndkpiintransit':
				console.log("IN getSEP = sckpiintransit");
				dept='SNDG';
				currentKpi = 'intransit';
				break;
			case '/sndkpinightdriving':
				dept='SNDG';
				currentKpi = 'nightdriving';
				break;
			case '/sndkpioverspeeding':
				dept='SNDG';
				currentKpi = 'overspeeding';
				break;
			case '/sndkpienroute':
				dept='SNDG';
				currentKpi = 'enroute';
				break;
			case '/sndkpiunloading':
				dept='SNDG';
				currentKpi = 'unloading';
				break;
			case '/sndkpiloading':
				dept='SNDG';
				currentKpi = 'loading';
				break;

				
			case '/tnpkpiintransit':
				console.log("IN getSEP = sckpiintransit");
				dept='LOG-TNP';
				currentKpi = 'intransit';
				break;
			case '/tnpkpinightdriving':
				dept='LOG-TNP';
				currentKpi = 'nightdriving';
				break;
			case '/tnpkpioverspeeding':
				dept='LOG-TNP';
				currentKpi = 'overspeeding';
				break;
			case '/tnpkpienroute':
				dept='LOG-TNP';
				currentKpi = 'enroute';
				break;
			case '/tnpkpiunloading':
				dept='LOG-TNP';
				currentKpi = 'unloading';
				break;
			case '/tnpkpiloading':
				dept='LOG-TNP';
				currentKpi = 'loading';
				break;
	

			default:
				console.log("IN getSEP = default");
				dept='LOG-SC';
				currentKpi = 'enroute';
		}
		
		this.setState({
			deptcode:dept,
			currentKpi:currentKpi,
			currentPageTitle: kpiNames[currentKpi]
		});
		
		let layoutdata = {
			token:localStorage.getItem('token'),
			deptcode:dept,
			kpi: currentKpi
		}
		return layoutdata;
	}
	
	handleActionClick(e){
		/*Transit Delays*/
		if(parseInt(e) == 1)
		{
			this.setState({
				rowData:this.state.transits
			})
		}
	}
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}




	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	closeAlert = () => {
        this.setState({
			show: false,
			overly:'show-n'
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	handleChange = transportervalue => {
    	// this.setState(
		// 	{ transportervalue },
		// 	() => console.log(`Option selected:`, this.state.transportervalue)
		//   );
	}
	selectOptionsItems(){
		//console.info("COming in selectOptions-----------------------------------");
		let items = []; 
		this.state.consignerCodeList.forEach(item=>{
			//items.push({ value: item.consigner_code, label: item.consigner_code });
			if(item != '' && item != null)
    		{
        		items.push(<option key={item} value={item}>{item}</option>); 
    		}

			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
		});

		console.log("IN selectOptions, Items = ", items);
	    return items;
   } 
   formHandler = (event) => {
		event.preventDefault();
		this.setState({
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle + this.state.currentPageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var formdata= {
				startDate:this.state.startDate,
				endDate:this.state.endDate,
				consigner_code:this.state.consigner_code,
				token:localStorage.getItem('token'),
				deptcode:this.state.deptcode,
				kpi: this.state.currentKpi
		}
		console.log("IN FORMHANDLER, formData = ", formdata);
		redirectURL.post("/consignments/consignmentsforkpifilter",formdata,{
			headers:{
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin':"*",
				'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			}
		})
		.then((result) => {
			console.log(result.data) 
			this.setState({
				rowData:result.data,
				loadshow:'show-n'
			});
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}
	
	handlerForm = (event) => {
		console.log("Select", event.target.value);
		if(event.target.value == '')
		{
			window.location.reload();
		}
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }	
	resetForm = () => {
		console.log("in reset form");
		window.location.reload();
    }

	render(){
		
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	    }

		return(
			<div className="container-fluid">
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
						{/* <Breadcrumb parent="Container" title="Dashboard" /> */}
                        <h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>KPI Reports {this.state.currentPageTitle}</span>
                        </h5>
                    </div>


					<div className="row col-xl-12 col-lg-12">
						<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
					
							<div className="col-xl-3 col-lg-3 form-group">
								<select name="consigner_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
									<option value="">Please select a Consigner</option>
									{this.selectOptionsItems()}
								</select>
							</div>

							<div className="col-xl-3 col-lg-3 form-group">
								<Datetime inputProps={{ placeholder: 'Gate out From', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
							</div>

							<div className="col-xl-3 col-lg-3 form-group">
								<Datetime  inputProps={{ placeholder: 'Gate out To', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} />
							</div>

							<div className="col-xl-3 col-lg-3 form-group">
								<button type="submit" className="btn btn-success">Get Data</button>
								&nbsp; &nbsp; &nbsp; &nbsp; 
								<button type="button" onClick={this.resetForm} className="btn btn-info">Reset</button>
							</div>
						</form>
					</div>

                    <div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            		<div className="card-body">
								<div className="tab-pane tabpad active col-xl-12 col-lg-12 col-sm-12">
									<div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={this.state.columnsInTransitExceptions}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										floatingFilter={false}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										headerHeight={this.state.headerHeight}
										groupHeaderHeight={this.state.groupHeaderHeight} 
									/>

									<div className={"dataLoadpage " +(this.state.loadshow)}></div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>
									</div>
								</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
            </div>
              	
		);
    }



}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
  }
function monthToComparableNumber(date) {
	if (date === undefined || date === null || date.length !== 19) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}