
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ExportsForceCloseAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickForceClose(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn btn-warning f12" style={{backgroundColor:"#FF0000",color:"#333333"}}>Force Close </button>
       
        </div>
    );
};

export default ExportsForceCloseAction;
