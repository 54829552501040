import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Submenus from "./submenus";
import Empty from "./empty";
var redirectURL = require('../redirectURL');

export default class AdminViewSubMenus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_title:'',
            loadshow:'show-n',
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            allmenus:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: null,
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                submenus:Submenus,
                empty:Empty
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            }
        }
    }
    componentDidMount(){
        var menuparam = {
            parentmenu:this.props.match.params.parentmenu
        }
        redirectURL.post("/superadmin/submenus", menuparam)
        .then((response) => {
            this.setState({
                rowData:response.data
            });
        })
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    formHandler = (event) =>{
		event.preventDefault();
		this.setState({
			loadshow:'show-m',
        });
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
    onShowMenus = async (params) => {
        if(params != 0)
        {
            //console.log("Menus ", e)

            var menuparams = {
                parentmenu:params.data.menut_alias
            }
            await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Actions",field: "is_children", 
							
                            cellRendererSelector:function(param){
                                 console.log("is child ", param.data.is_children)
                                 if(param.data.is_children == 1)
                                 {
                                     var rendComponent = {
                                         component: 'submenus'
                                     };
                                     return rendComponent;
                                 }
                                 else{
                                     var rendComponent = {
                                         component: 'empty'
                                     };
                                     return rendComponent;
                                 }
                             },
								width:100,
								suppressSizeToFit: true
							},
							{ headerName: "Menu Title",field: "menut_title", width:150,resizable: true },
							{ headerName: "Department Code",field: "dept_code", width:180,resizable: true },
							{ headerName: "Path",field: "path",resizable: true },
                            { headerName: "Type",field: "type", width:180,resizable: true },	
                            { headerName: "Is Children",field: "is_children", width:180,resizable: true },		
                            					
						]							
					},
					getDetailRowData: function(param) {
                        var menuparam = {
                            parentmenu:param.data.menut_alias
                        }
                        redirectURL.post("/superadmin/submenus", menuparam)                        
                        .then(async (resp) =>{
							param.successCallback(resp.data);
						});
						
					}
				}
			});
			
			if(params.column.colId == 'is_children')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
            }
            
        }
        
    }
    render(){
        const columnDefs = [
            {
                headerName: "Actions",
                field: "is_children",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter",
                /*cellRendererSelector:function(params){
                   // console.log("is child ", params.data.is_children)
                    if(params.data.is_children == 1)
                    {
                        var rendComponent = {
                            component: 'submenus'
                        };
                        return rendComponent;
                    }
                    else{
                        var rendComponent = {
                            component: 'empty'
                        };
                        return rendComponent;
                    }
                },*/
                cellRenderer:function(params){
                    if(params.data.is_children == 1)
                    {
                        var htmloption = '<a href="/viewSubmenus/'+params.data.menut_alias+'" class="custom-btn label label-success"><i class="icofont icofont-eye f14"></i> View Menus</a>';
                        return htmloption;
                    }
                    else
                    {
                        var htmloption = '<a href="/editSubmenus/'+params.data.menut_alias+'" class="custom-btn label label-success"><i class="icofont icofont-eye f14"></i> View Menus</a>';
                        return htmloption;
                    }
                } 
                
            },
            {
              headerName: "Menu Title",
              field: "menut_title",
              width: 150,
              editable:true,
              filter: "agSetColumnFilter",
            },
            {
                headerName: "Department Code",
                field: "dept_code",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Menu Type",
                field: "type",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Is Children",
                field: "is_children",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
                valueGetter:function(params){
                    if(params.data.is_children == 1)
                    {
                        return "Yes"
                    }
                    else{
                        return "No"
                    }
                }
            },

            {
                headerName: "Badge Type",
                field: "badgeType",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
        
            {
                headerName: "Badge Value",
                field: "badgeValue",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },

            {
                headerName: "Sidebar Title",
                field: "sidebartitle",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
        

            {
                headerName: "Menu Icon",
                field: "icon",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>Menus </span>
                                    <span className="float-right">
                                        <a href="/superadmin/menus" className="btn btn-info">Back</a>
                                    </span>
                                </h5>
				   			</div>
				   			<div className="card-body row">
                                
                                <div className=" col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
									    detailCellRendererParams={this.state.detailCellRendererParams}
									    enableRangeSelection={false}
                                        floatingFilter={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressCellFlash={true}
									    masterDetail={true}
                                        
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}