
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const CommentTransporterLoadApproval = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowTransporterApprovalCommentLoad(props);
    };
    //console.log("notify tpt ",props.data.notify_tpt)
    //console.log("appr tpt ",props.data.tpt_approval)
    if(parseInt(props.data.notify_tpt) == 1 && props.data.tpt_approval == 0)
    {
      //  console.log("props.data.tpt_approval ", props.data.tpt_approval)
        var btnTitle  = 'Pending'
    }
    else{
        var btnTitle  = 'Notify Plant'
    }
    //console.log("btnTitle ", btnTitle)
    return (
        <div className="map-icon-component">
		    <button onClick={handleRouteClick} className="mybtn btn btn-success" title="View VIN List">{btnTitle} </button>
        </div>
    );
};

export default CommentTransporterLoadApproval;
