
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
const StockBillAcceptReject = (props) => {
    //console.log("props ", props)
    const handleAcceptClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickAcceptPrimaryBill(props);
    };
    const handleRejectClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickRejectPrimaryBillModal(props);
    };
    return (
        <div>
        {/* <button type="button" name="accept" className="custom-btn btn-success f12" onClick={handleAcceptClick}>Accept</button> */}
        &nbsp;<button type="button" name="accept" className="custom-btn btn-danger f12s" onClick={handleRejectClick}>Reject</button>
        </div>
    );
};

export default StockBillAcceptReject;
