import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UpdateWellnessdata = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickUpdateItem(props);
    };
  
    return (
        <div>
            <button onClick={handleEventClick} className="btn btn-warning custom-btn f12" 
            title="Update" >Update</button>
            
        </div>
    );
};

export default UpdateWellnessdata;
