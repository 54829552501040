
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var moment = require('moment');
var redirectURL = require('../redirectURL');

 
const Edittruckavailable = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickEditRecord(props.data);
    };
    var today = moment.parseZone().format("YYYY-MM-DD")
    // var tdate = moment.parseZone(new Date(todayDate)).format("YYYY-MM-DD HH:mm:ss");
    // console.log("params tdate ", tdate)
    // var today = moment.parseZone(tdate).format("x");
    console.log("params today ", today)
    var tentitivetime = moment.parseZone(props.data.tentitivetime).format("YYYY-MM-DD")
    console.log("params tentitivetime ",tentitivetime)
    console.log("Props ", props.data)
    if(tentitivetime >= today)
    {
        if(props.data.gate_out_time == "" || props.data.gate_out_time == undefined)
        {
            var show = 1
        }
        else
        {
            var show = 0
        }
        
    }
    else
    {
        var show = 0
    }
    return (
        (show == 1)?
            <button type="button" onClick={handleClick} className="custom-btn" title="Edit"><i class="icofont icofont-ui-edit"></i></button>
        :""
        
    );
};

export default Edittruckavailable;
