import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import DeleteItem from './deleteActionComponent';
import EditItem from './editActionComponent';
var redirectURL = require('../redirectURL');
var selectRowItemFromAgGrid='';
var url=redirectURL;
export default class GridComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
    		 modules: AllModules,
      	      defaultColDef: {
      	        sortable: true,
				  filter: true,
				  resizable: true
      	        
      	      },
      	      rowData: null,
      	      
      	      rowModelhighchartoptionsType: 'enterprise',
      	      rowGroupPanelShow: 'always',
      	      animateRows: true,
      	      debug: true,
      	      showToolPanel: false,
      	      rowSelection: "single" ,
      	      context: { componentParent: this },
      	      frameworkComponents: {
      	    	  editItem:EditItem,
      	    	  deleteItem:DeleteItem
      	      },
				///usergridstate:[],
				screenurl:"",
				screentitle:""
            };
        
        }
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	
	componentDidMount(){
		// redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		// .then( async (resp) => {
		// 	//console.log("resp ", resp)
		// 	await this.setState({
		// 		usergridstate:resp.data
		// 	});
		// 	await this.restoreGridStates();
		// })

		this.setState({
			screenurl:this.props.screenurl
		})

	}
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
	 
	 deleteRowHandler = () => {
		 var selectedData = this.gridApi.getSelectedRows();
		    var res = this.gridApi.updateRowData({ remove: selectedData });
		    //printResult(res);
		    console.log("Remove to : ",res.remove[0].data._id)
		    var actionCall = this.props.actionCall;
		    var actionMethod = this.props.actionMethod;
		    console.log(actionMethod);
		    var fdata = {
		    		rownode:res.remove[0].data
		    }
		    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
				headers:{
					'Content-Type': 'application/json'
				}
			}).
			then(
				(response)=>{
					console.log(response.data);
					
				}
			)
			.catch(function(error){
				console.log(error);
			});
	 }
	 deleteItem = (e) => {
		 console.log("Here",e)
	 }
	 
	 onSelectionChanged() {
	    var selectedRows = this.gridApi.getSelectedRows();
	    selectRowItemFromAgGrid=selectedRows;
	  }
 

	//   onClickSaveGridState(){
	// 	window.colState = this.gridColumnApi.getColumnState();
	// 	window.groupState = this.gridColumnApi.getColumnGroupState();
	// 	window.sortState = this.gridApi.getSortModel();
	// 	window.filterState = this.gridApi.getFilterModel();
	// 	// console.log("Columns ", window.colState)
	// 	// console.log("Group ", window.groupState)
	// 	// console.log("Sort ", window.sortState)
	// 	// console.log("Filter ", window.filterState)
	// 	let screenpage = 'manage geofence';

	// 	let reqparams = {
	// 		gridcolumns:window.colState,
	// 		gridgroup:window.groupState,
	// 		gridcolsort:window.sortState,
	// 		gridcolfilter:window.filterState,
	// 		userId:localStorage.getItem("userid"),
	// 		screenurl:this.props.screenurl,
	// 		screentitle:screenpage
	// 	}
	// 	//console.log("reqparams ", reqparams)
	// 	redirectURL.post("/consignments/saveGridStates", reqparams)
	// 	.then((response) => {
	// 		//console.log("State response ",response.data)
	// 		this.setState({
	// 			show:true,
	// 			basicTitle:"Successfully saved grid layout",
	// 			basicType:"success",
	// 			screenurl:this.props.screenurl,
	// 			screentitle:screenpage
	// 		})
	// 	})
	// 	.catch(function(e){
	// 		console.log("Error ", e)
	// 	})
	// }


    // restoreGridStates()
	// {

	// 	if(this.state.usergridstate.length > 0)
	// 	{
	// 		var windowstates = this.state.usergridstate;
	// 		this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
	// 		this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
	// 		this.gridApi.setSortModel(windowstates[0].gridcolsort);
	// 		this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
	// 	}
	// }

	// resetState = () => {
	// 	this.gridColumnApi.resetColumnState();
	// 	this.gridColumnApi.resetColumnGroupState();
	// 	this.gridApi.setSortModel(null);
	// 	this.gridApi.setFilterModel(null);
	// 	//console.log('column state reset');
	// 	var reqparams = {
	// 		userId:localStorage.getItem("userid"),
	// 		screenurl:this.state.screenurl,
	// 		screentitle:this.state.screenpage
	// 	}

	// 	redirectURL.post("/consignments/removeUserGridState", reqparams)
	// 	.then((response) => {
	// 		this.setState({
	// 			show:true,
	// 			basicTitle:"Successfully reset default grid layout",
	// 			basicType:"success"
	// 		})
	// 	})

	// };
    render(){
    	
        
        return ( 
        		<div className="row">
        			<span className="layoutbtns float-right">
						{/* <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
							<i className="icofont icofont-save"></i> Save Grid Layout
						</button>
						<button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
							<i className="icofont icofont-refresh"></i> Reset Default Layout
						</button> */}
					</span>   
	                 <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	      		          <AgGridReact
	      		            modules={this.state.modules}
	      		            columnDefs={this.props.columnwithDefs}
	      		            defaultColDef={this.state.defaultColDef}
	      		            rowData={this.props.rowData}
	      	                rowSelection={this.state.rowSelection}
	      		            enableCharts={false}
	      		            enableRangeSelection={true}
	      	                pagination={true}
	      		            onGridReady={this.onGridReady}
	      		            onGridState={this.onGridState}
	      		            deleteRowHandler={this.deleteRowHandler}
	      		            onSelectionChanged={this.onSelectionChanged.bind(this)}
	      	                frameworkComponents={this.state.frameworkComponents}
	      		          />
	  		        </div>
      		    </div>
                    
			);
		}
}


window.onload = function(){
    document.onclick = function(e){
		//console.log(e);
		if(e.target.id == 'delItem')
		{
			var id = e.target.className;
			console.log("selectRowItemFromAgGrid", selectRowItemFromAgGrid)
			var cnf = window.confirm("Are you sure you want to delete this item?");
			if(cnf == true)
			{
				// var selectedData = this.gridApi.getSelectedRows();
				console.log(url+"/")
				//window.location.reload();
			}
		}
	};
};













