
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Ticketkpienroute = (props) => {
   
    const handleEnrouteStoppagesClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowEnrouteStoppagesKpi(props);
    };
    
    return (
        <div>
             <button onClick={handleEnrouteStoppagesClick} className="custom-btn f22 label label-success" title="Enroute Stoppages"><i className="icofont icofont-pause maroon"></i> &nbsp;</button>
            
        </div>
    );
}; 
 
export default Ticketkpienroute;
