/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
// import LoadingAreaLegs from "./loadingarealegs";
import CountUp from 'react-countup';
import LoadingAreaLegs from "./loadingarealegs";
import LegRouteMap from "../manage/legroutemap";
import ShipmentLegs from "./shipmentlegs";
import ReturnToPlantComponent from "./returnToPlantBtn";

import Counters from '../layouts/consignmentkpicountersComponent';
import DateFormater from '../layouts/dateComponent';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getHyphenDDMMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import CustomDateComponent from '../common/dateFilterComponent';

import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import SegmentSideBar from "../layouts/segmentComponent";
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapenrouteAction from '../manage/routemapenroute';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gpsdata from '../manage/gpsdataaction.js';
import NoGps from '../manage/nogpsActionComponent.js';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';


import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class ReturnToPlant extends Component {
    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pagetitle: "Return To Plant",
            deptcode: "SNDG",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            eventAction: null,
            forceCloseRowNode: "",
            allRowData: [],
            activeFilter: null,
            sliderTranslate: '',
            mapinfo: '',
            commentsRowData: [],
            dealer: '',
            maptruckno: '',
            loadshow: 'show-n',
            showDiv: 'show-n',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            overly: 'show-n',
            alerterrshow: 'fade',
            alert: null,
            show: false,
            basicTitle: '',
            rownode: '',
            leg_no: '',
            basicType: "default",
            modules: AllModules,
            filterstatess: {},
            columnsdatas: {},
            rowgroupdcols: [],
            valuecolumns: [],
            activeconsignemnt: [],
            activetrucks: [],
            transitdelays: [],
            gpsdatana: [],
            overspeedtrucks: [],
            nightdrive: [],
            open: false,
            openforceclose: false,
            pivotmodeis: false,
            pivotcols: [],
            defTransitCoords: '',
            routeTruck: {},
            sidebarSubHeader: '',
            googelRoutes: '',
            plants: [],
            bulkPrioritySlide: "",
            hideTransporterBtns: "show-m",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: false,
            },
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                dateFormater: DateFormater,
                LoadingAreaLegs: LoadingAreaLegs,
                LegRouteMap: LegRouteMap,

                consignmentActions: ConsignmentActions,
                commentActions: CommentActions,
                SegmentAction: SegmentAction,
                kpiActions: KpiActions,
                kpiDeviationActions: KpiDeviationActions,
                kpiTransitDelayActions: KpiTransitDelayActions,
                nooverspeed: Nooverspeed,
                notransit: Notransit,
                kpiNightDriveActions: KpiNightDriveActions,
                nonightdrive: Nonightdrive,
                kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
                noenroutes: Noenroutes,
                kpiLoadingDelayActions: KpiLoadingDelayActions,
                kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
                dateFormater: DateFormater,
                gps: Gpsdata,
                nogps: NoGps,
                noGpsLifetime: NoGpsLifetime,
                RoutemapenrouteAction: RoutemapenrouteAction,
                consignmentforceclose: Consignmentforceclose,
                ShipmentLegs: ShipmentLegs,
                returnToPlantComponent: ReturnToPlantComponent

            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 1000,
            cacheBlockSize: 50,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            startDatetime: '',
            endDatetime: '',
            tolls: [],
            geofence_coordinates: [],
            excelStyles: [
                {
                    id: "dateFormat",
                    dataType: 'dateTime',
                    numberFormat: { format: "yyyy-mm-dd" }
                }
            ],
            getRowHeight: function (params) {
                if (typeof params.data.dct_comment != 'undefined') {
                    //console.log("pars ", params.data.dct_comment.length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.dct_comment.length / 50))
                    );
                }
                if (typeof params.data.vin_list != 'undefined') {
                    //console.log("pars ", params.data.vin_list.toString().length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.vin_list.toString().length / 50))
                    );
                }
                else if (typeof params.data.force_closure_reason != 'undefined') {
                    //console.log("pars ", params.data.dct_comment.length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.force_closure_reason.length / 50))
                    );
                }
                else {
                    return 30;
                }
            },
            defaultsdate: '',
            defaultedate: '',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            allplants: [],
            consigner: [{ "value": "ALL", "label": "All" }],
            consignee: [{ "value": "ALL", "label": "All" }],
            filterCities: [],
            filterStates: [],
            filterClusters: [],
            filterTransporters: [],
            countersjson: '',
            originalcountersjson: '',
            consigneecoords: '',
            consigner_coordinates: '',
            containerslist: [],
            forceclosedata: '',
            reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
            reachedhh: '',
            reachedmm: '',
            reachedss: '00',
            left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
            lefthh: '',
            leftmm: '',
            leftss: '00',
            invoice_time: '',
            invoicehh: '',
            invoicemm: '',
            consigner_coordinates: [],
            consignee_coordinates: [],
            map_type_of_tracking: "",
            filterNames: "Truck / Shipment No/ Invoice No",
            filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "Shipment No", value: "shipment_id" }, { label: "Invoice", value: "sales_order" }],
            filterCustomers: [],
            flCustomer: [],
            road_btn: 'btn-danger',
            rail_btn: 'btn-default',
            originalData:[]
        }
        this.onLoadDeliveredTrucks = this.onLoadDeliveredTrucks.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.onShowShipmentLegRouteMap = this.onShowShipmentLegRouteMap.bind(this);
        this.onShowRouteMap = this.onShowRouteMap.bind(this);
        this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
        this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
        this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
        this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
        this.onShowEnrouteStoppagesKpi = this.onShowEnrouteStoppagesKpi.bind(this);
        this.onShowTransitKpi = this.onShowTransitKpi.bind(this);
        this.onShowOverspeedKpi = this.onShowOverspeedKpi.bind(this);
        this.onClickForceClose = this.onClickForceClose.bind(this);
        this.onClickShowLoadingAreaLegs = this.onClickShowLoadingAreaLegs.bind(this);
        this.onClickShowShipmentLegs = this.onClickShowShipmentLegs.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
        this.onClickReturnToPlant = this.onClickReturnToPlant.bind(this)

    }

    componentDidCatch(error, info) {
        console.log("Error here ", error)
        console.log("Error Info here ", info)
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        loadDateTimeScript();
        if (localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0) {
            this.setState({
                sideBar: {
                    toolPanels: [
                        {
                            id: "columns",
                            labelDefault: "Columns",
                            labelKey: "columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel"
                        },
                        {
                            id: "filters",
                            labelDefault: "Filters",
                            labelKey: "filters",
                            iconKey: "filter",
                            toolPanel: "agFiltersToolPanel"
                        }
                    ]
                }
            });
        }
        if (localStorage.getItem("user_type") == "CUSTOMER") {
            this.setState({
                filterNames: "Truck / Invoice No",
                filterOptions: [{ label: "Truck", vaue: "Truck" }, { label: "Invoice", value: "sales_order" }]
            });
        }
        var edate = moment.parseZone().format('YYYY-MM-DD');

        if (this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments")) {
            var sdate = moment.parseZone().subtract(3, 'days').format('YYYY-MM-DD');
        }
        else {
            var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
        }


        // const parts = sdate.split('-');
        // sdate = [parts[0], parseInt(parts[1]), parts[2]].join('-');
        // const parts2 = edate.split('-');
        // edate = [parts2[0], parseInt(parts2[1]), parts2[2]].join('-');
        // var edatetime = moment.parseZone().format('YYYY-MM-DD');
        // var sdatetime = moment.parseZone().format('YYYY-MM-DD');
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });
        //console.log("Props ", this.props.match)
        this.setState({
            loadshow: 'show-m',
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
        });
        var parameters = {
            startDate: sdate,
            endDate: edate,
        }
        console.log(parameters, "412")
        try {
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
                parameters.consigner = [{ value: localStorage.getItem("pc"), label: localStorage.getItem("pc") }];
                var consigner = [{ value: localStorage.getItem("pc"), label: localStorage.getItem("pc") }];
                this.setState({
                    consigner: consigner
                });
            }
            else {
                parameters.consigner = [{ "value": "ALL", "label": "All" }];
            }
        } catch (e) {

        }
        var transportercode = localStorage.getItem('transportercode');
        if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
            parameters.transporter_code = transportercode;
        }
        if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
            parameters.customer_code = localStorage.getItem("customer_code")
        }
        this.onLoadDeliveredTrucks(parameters);
        console.log(parameters, 435)
        redirectURL.post("/consignments/allplants",).then(async (response) => {
            let responseData = response.data
            if (responseData.length > 0) {
                responseData = await distinctArrayBy(responseData, "plant_code");
            }
            // console.log("responseData",responseData)
            this.setState({
                allplants: responseData
            })
        })

        redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: "SNDG", email: localStorage.getItem('email'), role: localStorage.getItem('roles'), customer_code: localStorage.getItem('customer_code') })
            .then((resp) => {
                var cities = resp.data.cities;
                var filterCities = [];
                filterCities.push({
                    "value": "All",
                    "label": "All"
                })
                if (cities != undefined) {
                    if (cities.length > 0) {
                        cities.map(function (c) {
                            filterCities.push({
                                label: c,
                                value: c,
                            });
                        });
                    }
                }
                var customers = resp.data.customers;

                var filterCustomers = [];
                if (customers.length > 0) {
                    filterCustomers.push({
                        label: "All",
                        value: "",
                    });
                    customers.map(function (c) {
                        filterCustomers.push({
                            label: c.customer_code + " - " + c.customer_name,
                            value: c.customer_code,
                        });
                    });
                }
                var states = resp.data.states;
                var filterStates = [];
                if (states != undefined) {
                    if (states.length > 0) {
                        states.map(function (s) {
                            filterStates.push({
                                label: s,
                                value: s,
                            });
                        });
                    }
                }
                var clusters = resp.data.clusters;
                var filterClusters = [];
                if (clusters != undefined) {
                    if (clusters.length > 0) {
                        clusters.map(function (cl) {
                            filterClusters.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                }
                var transporters = resp.data.transporters;
                var filterTransporters = [];
                filterTransporters.push({
                    "value": "All",
                    "label": "All",
                })
                if (transporters != undefined) {
                    if (transporters.length > 0) {
                        transporters.map(function (t) {
                            filterTransporters.push({
                                label: t,
                                value: t,
                            });
                        });
                    }
                }
                var transportersZones = resp.data.transportersZonesList;
                let filterTransportersZones = [];
                filterTransportersZones.push({
                    "value": "",
                    "label": "All",
                });
                if (transportersZones != undefined && transportersZones != "") {
                    transportersZones.map((t) => {
                        if (t != "" && t != undefined && t != "null" && t != null) {
                            filterTransportersZones.push({
                                "value": t,
                                "label": t,
                            });
                        }
                    });
                }
                this.setState({
                    filterCities: filterCities,
                    filterCustomers: filterCustomers,
                    filterStates: filterStates,
                    filterClusters: filterClusters,
                    filterTransporters: filterTransporters,
                    filterTransportersZones: filterTransportersZones,
                });
            });

        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                console.log("resp.data ", resp.data)
                await this.setState({
                    usergridstate: resp.data
                });
                await this.restoreGridStates();
            });

    }
    onLoadDeliveredTrucks(parameters) {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        parameters.user_type = localStorage.getItem("user_type");
        var showRail = 0;
        if (localStorage.getItem("roles").indexOf("AMNS_OPS") >= 0 || localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("email") == "hazira.test@amns.in") {
            showRail = 1;
        }
        parameters.showRail = showRail;
        console.log(parameters, "566")
        redirectURL.post("/amns/getReturnToPlant" ,parameters)
            .then((response) => {
                console.log("response ", response.data);
                var records = response.data;
                var plant_code = localStorage.getItem("pc")
                if(plant_code!="" && plant_code!=undefined){
                    records = records.filter(e=>e.consigner_code==plant_code)
                }
                var pagecounters = response.data.counters;

                console.log("records ", records);
                var gridData = records;

                var selectedCity = $("#selectedCity").text();
                var selectedState = $("#selectedState").text();
                var selectedCluster = $("#selectedCluster").text();
                var selectedTransporter = $("#selectedTransporter").text();
                // console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
                //console.log(filterRowData,"0")
                if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select..." && selectedCity != "All") {
                    gridData = gridData.filter(e => e.consignee_city == selectedCity);
                    // console.log(gridData,"1")
                }
                if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
                    gridData = gridData.filter(e => e.consignee_state == selectedState);
                }
                if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select..." && selectedCluster != "All") {
                    gridData = gridData.filter(e => e.cluster == selectedCluster);
                }
                if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select..." && selectedTransporter != "All") {
                    gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
                }
                if (localStorage.getItem("user_type") == "CUSTOMER") {
                    if (gridData.length > 0) {
                        gridData = gridData.filter((t) => t.is_tracking_available == 1);

                    }
                }
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                    rowData: gridData.filter(e=>this.state.road_btn=="btn-danger"?e.shipment_mode!="RAIL" : e.shipment_mode=="RAIL"),
                    allRowData: gridData,
                    originalData: gridData,
                    beforeNewFilterRowData: gridData,
                    countersjson: pagecounters,
                    originalcountersjson: pagecounters,
                    beforeNewFiltercountersjson: pagecounters,
                    consBtn: "btn-danger",
                    returnConsBtn: "btn-default",
                })
            })
    }


    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            tabsliderTranslate: '',
            showDiv: 'show-n',
            sliderTranslatesidebar: "",
            sliderCommentTranslate: "",
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n',
            loadshow: 'show-n',
            slideuploadeway: ""
        });
    }

    onShowRouteDiv = (e) => {
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
                "category": this.state.pagetitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        //console.log("IN onShowRouteDiv, Rute params ", e)
        this.setState({
            loadshow: 'show-m',
            maptruckno: e.truck_no
        });

        if (e.parking_in_time != "" && e.parking_in_time != undefined) {
            var gateouttime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
        }
        else {
            var gateouttime = moment.parseZone().format('YYYY-MM-DD') + " 00:00:00";
        }

        if (e.status == "9" || e.status == "10" || e.status == 9 || e.status == 10) {
            var end_date = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
        }
        if (e.status == "11" || e.status == 11) {
            var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
        }
        else {
            if (e.status == 6 || e.status == "6") {
                var end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
            }
            else {
                var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            }

        }
        // var reqData = {
        //     truck_no:e.truck_no,
        // 	consignment_code:e.consignment_code,
        // 	consignee_code:e.consignee_code,
        // 	gate_out_time:gateouttime,
        // 	seconddate:end_date,
        // 	screen:"consignment"
        // }
        // console.log("reqData: ", reqData);
        // redirectURL.post('/consignments/maproutes',reqData, {
        

        var reqData = {
            truck_no: e.truck_no,
            shipment_id: e.shipment_id,
            consignment_codes: [e.consignment_code],
            fromdate: gateouttime,
            todate: end_date
        }


        // console.log("reqData: ", reqData);
        // redirectURL.post('/consignments/maproutes',reqData, {
        redirectURL.post('/trucks/truckmap', reqData, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((response) => {
                var records = response.data;
                // console.log("Props data ", records)

                this.setState({
                    loadshow: 'show-m'
                });
                try {
                    if (records.status == "failure" || records.status == "Failure") {
                        //console.log("records", records.coords);
                        this.setState({
                            show: true,
                            basicTitle: 'No Route data available',
                            basicType: "danger",
                            loadshow: 'show-n'
                        });
                    }
                    else {
                        if (records.coords.length == 0) {
                            this.setState({
                                show: true,
                                basicTitle: 'No Route data available',
                                basicType: "danger",
                                loadshow: 'show-n'
                            });
                        }
                        else {
                            if (records != '') {
                                var sdate = e.gateouttime;


                                try {
                                    // records.consignee_coordinates = [records.consignee_coordinates];
                                }
                                catch (e) {

                                }
                                this.setState({
                                    sliderRouteTranslate: "slider-translate-60p",
                                    showDiv: 'show-m',
                                    mapinfo: records,
                                    dealer: e.consignee_code,
                                    consignment_code: "Truck : " + e.truck_no,
                                    maptruckno: e.truck_no,
                                    routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                    loadshow: 'show-n',
                                    sidebarSubHeader: "Truck Information",
                                    overly: 'show-m',
                                    rownode: e,
                                    leg_no: 0,
                                    consigner_coordinates: records.consigner_coordinates,
                                    consignee_coordinates: records.consignee_coordinates,
                                    tolls: records.tolls,
                                    map_type_of_tracking: e.type_of_tracking,
                                    // baseRouteCoords: baseRouteCoords
                                });


                            }
                        }
                    }
                }
                catch (e) {
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderRouteTranslate: "",
            sliderCommentTranslate: "",
            sliderSegmentTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n'
        });
    }



    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    onGridState1 = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState1 = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slideuploadeway: '',
            sliderRouteTranslate: '',
            sliderSegmentTranslate: "",
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderCommentTranslate: "",
            bulkPrioritySlide: "",
        });

    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickCounterShowDataTEST(params) {
        // console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
    }

    onClickCounterShowData(params) {

        this.setState({
            loadshow: "show-m"
        })
        console.log("IN onClickCounterShowData, params = ", params);
        if (params == "all") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData
            })
        }

        if (params == "is_gps_working") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.is_gps_working == "NOT_WORKING")
            })
        }
        if (params == "no_gps_data_lifetime") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.no_gps_data_lifetime == 1)
            })
        }
        if (params == "transit_delay") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.transit_delay == 1)
            })
        }
        if (params == "enroute_exception") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.enroute_exception == 1)
            })
        }
        if (params == "overspeeding_exception") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.overspeeding_exception == 1)
            })
        }
        if (params == "nightdriving_exception") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.nightdriving_exception == 1)
            })
        }

        if (params == "no_gps_data") {
            this.setState({
                loadshow: "show-n",
                rowData: this.state.allRowData.filter(e => e.no_gps_data == 1)
            })
        }
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" +(d.getMonth() + 1) : d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        console.log(startdate, "958")
        this.setState({
            startDate: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" +(d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        this.setState({
            endDate: edate
        });
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.gridApi.deselectAll();
        this.setState({ open: false });
    };

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    onShowShipmentLegRouteMap = (e) => {
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
                "category": this.state.pagetitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        // console.log("IN onShowRouteDiv, Rute params ", e)
        this.setState({
            loadshow: 'show-m',
            maptruckno: e.truck_no
        });
        // console.log("e.data.parking_in_time ", e.parking_in_time)
        if (e.first_inside_fence != "" && e.first_inside_fence != undefined) {
            var ptime = moment.parseZone(e.first_inside_fence).format("YYYY-MM-DD HH:mm:ss");
        }
        else {
            var ptime = moment.parseZone().format('YYYY-MM-DD') + " 00:00:00";
        }
        if (e.first_outside_fence != "" && e.first_outside_fence != undefined) {
            var end_date = moment.parseZone(e.first_outside_fence).format("YYYY-MM-DD HH:mm:ss");
        }
        else {
            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }

        var reqData = {
            truck_no: e.truck_no,
            // consignment_code:e.consignment_code,
            consignee_code: e.consignee_code,
            gate_out_time: ptime,
            seconddate: end_date,
            screen: "truck"
        }
        // console.log("reqData: ", reqData);
        redirectURL.post('/consignments/maproutes', reqData, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((response) => {
                var records = response.data;
                console.log("Props data ", records)

                this.setState({
                    loadshow: 'show-m'
                });
                // try{
                if (records.status == "failure" || records.status == "Failure") {
                    //console.log("records", records.coords);
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }
                else {
                    if (records.coords.length == 0) {
                        this.setState({
                            show: true,
                            basicTitle: 'No Route data available',
                            basicType: "danger",
                            loadshow: 'show-n'
                        });
                    }
                    else {
                        if (records != '') {

                            this.setState({
                                sliderRouteTranslate: "slider-translate-60p",
                                showDiv: 'show-m',
                                mapinfo: records,
                                dealer: e.consignee_code,
                                consignment_code: "Truck : " + e.truck_no,
                                maptruckno: e.truck_no,
                                routeTruck: { "truck_no": e.truck_no, "startTime": ptime, "endTime": end_date },
                                loadshow: 'show-n',
                                sidebarSubHeader: "Truck Information",
                                overly: 'show-m',
                                rownode: e,
                                leg_no: 0,
                                tolls: records.tolls,
                                // baseRouteCoords: baseRouteCoords
                            });

                            // this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                            // this.renderMap();

                        }
                    }
                }
                // }
                // catch(e){
                //     console.log("here")
                // 	this.setState({
                // 		show: true,
                // 		basicTitle:'No Route data available',
                // 		basicType:"danger",
                // 		loadshow:'show-n'
                // 	});
                // }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onShowRouteMap(rownode) {
        // console.log("IN onShowRouteMap, params = ",rownode);
        // console.log("rownode", rownode);
        // console.log("rownode.consignment_code ", rownode.consignment_code)
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m'
        })
        var d = new Date(rownode.first_instance);
        var r = new Date(rownode.recent_instance);
        var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: finstance,
            recent_instance: rinstance,
            screen: "consignment"
        }

        redirectURL.post('/reports/overspeedgpsroutes', params)
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                // console.log(response.data);
                this.setState({
                    mapinfo: response.data,
                    dealer: rownode.consignee_code,
                    sliderRouteTranslate: "slider-translate-50p",
                    consignment_code: rownode.truck_no,
                    maptruckno: rownode.truck_no,
                    routeTruck: {
                        "truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
                        "endTime": response.data['route_details']['end_time']
                    },
                    overly: 'show-m',
                    loadshow: 'show-n',
                    rownode: rownode,
                    tolls: response.data.tolls
                });
                this.renderMap();


            })
            .catch(function (error) {
                console.log(error);
            });

    }

    onShowTransitRouteMap(rownode) {
        //console.log("IN onShowTransitRouteMap, params = ",rownode);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log("rownode", rownode);
        this.setState({
            loadshow: 'show-m'
        })
        var d = new Date(rownode.leg_start);
        var r = new Date(rownode.leg_end_eta);
        var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/reports/nightdriveroutes', params)
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                //console.log("Inner Grid ",response.data);
                if (response.data.coords.length == 0) {
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }
                else {
                    var sdate = '';
                    var edate = '';

                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
                    }
                    this.setState({
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        sliderRouteTranslate: "slider-translate-60p",
                        defTransitCoords: rownode.consignee_coordinates,
                        consignment_code: "Consignment : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls

                    });

                    this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
                    this.renderMap();

                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    onShowTransitKpi = async (params) => {
        if (params != 0) {
            let eventLabel = googleAnalytics.page.action.transitDelay;
            let eventOptions = {
                "category": this.state.pagetitle,
                "action": this.state.eventAction,
                "label": eventLabel,
            }
            googleAnalytics.logEvent(eventOptions);
            //console.log("IN onShowTransitKpi, params = ",params);
            //console.log(params);
            //console.log("Consign onShowTransitKpi params = ",params);
            //console.log(params.column.colId);
            if (params != 0) {
                var transitedelaydata = [];

                await this.setState({

                    detailCellRendererParams: {
                        suppressRefresh: true,
                        detailGridOptions: {
                            headerHeight: 50,
                            columnDefs: [
                                {
                                    headerName: "", field: "_id",

                                    cellRendererFramework: RoutemapTransitAction,
                                    width: 50,
                                    suppressSizeToFit: true,
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 100, resizable: true,
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_LEGT_START_TIME, field: "leg_start", width: 120, resizable: true,
                                    valueGetter: function (params) {
                                        return getDDMMYYYYHHMMSS(params.data.leg_start);
                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_LEGT_END_TIME, field: "leg_end_eta",
                                    width: 120, resizable: true,
                                    valueGetter: function (params) {
                                        return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_EXP_LEG_DISTANCE,
                                    field: "leg_expected_distance", width: 140, resizable: true,
                                    valueGetter: function (params) {
                                        return Math.round(params.data.leg_expected_distance);
                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_ACTUAL_LEG_DISTANCE_COVERED,
                                    field: "actual_leg_distance", width: 140, resizable: true,
                                    valueGetter: function (params) {
                                        //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
                                        if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
                                            return Math.round(params.data.actual_leg_distance);
                                        }
                                        else {
                                            return 0;
                                        }

                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }
                                },
                                {
                                    headerName: Constant.COL_EXP_DISTANCE_FROM_TRIP_START,
                                    field: "expected_distance_start_to_leg", width: 140, resizable: true,
                                    valueGetter: function (params) {
                                        //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
                                        if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
                                            return Math.round(params.data.expected_distance_start_to_leg);
                                        }
                                        else {
                                            return 0;
                                        }

                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }

                                },
                                {
                                    headerName: Constant.COL_ACTUAL_DISTANCE_FROM_TRIP_START,
                                    field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
                                    valueGetter: function (params) {
                                        //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
                                        if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
                                            return Math.round(params.data.actual_distance_traveled_start_to_leg);
                                        }
                                        else {
                                            return 0;
                                        }

                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }


                                },

                                {
                                    headerName: Constant.COL_GOOGLE_DISTANCE_FROM_START,
                                    field: "actual_start_to_leg_google_distance",
                                    width: 140,
                                    resizable: true,
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }


                                },
                                {
                                    headerName: Constant.COL_GPS_AVAILABLE,
                                    field: "is_gps_working",
                                    width: 140,
                                    resizable: true,
                                    valueGetter: function (params) {
                                        //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
                                        if (params.data.is_gps_working == "NOT_WORKING") {
                                            return "Not Available";
                                        }
                                        if (params.data.is_gps_working == "WORKING") {
                                            return "Available";
                                        }

                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }


                                },


                                {
                                    headerName: Constant.COL_TRIP_COMPLETED,
                                    field: "trip_completed",
                                    width: 140,
                                    resizable: true,
                                    valueGetter: function (params) {
                                        //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
                                        if (params.data.trip_completed == 1) {
                                            return "Yes";
                                        }
                                        if (params.data.trip_completed == 2) {
                                            return "";
                                        }
                                        if (params.data.trip_completed == 0) {
                                            return "";
                                        }

                                    },
                                    cellClass: function (params) {
                                        if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                            return 'green';
                                        }
                                        else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                            return 'lightred';
                                        }
                                        else {
                                            return '';
                                        }

                                    }


                                }
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                        },
                        getDetailRowData: function (params) {
                            //console.log("Step 4 ",transitedelaydata)
                            //console.log("Step 4 ",params)
                            redirectURL.post('/consignments/transitdelay', {
                                consignment_code: params.data.consignment_codes,
                                consignee_code: params.data.consignee_code,
                                truck_no: params.data.truck_no
                            })
                                .then(async (response) => {
                                    //console.log("Step 1 ",response.data)
                                    transitedelaydata = response.data;
                                    var legsarr = []
                                    transitedelaydata.map((item) => {
                                        //if(item.trip_completed < 2)
                                        //{
                                        legsarr.push(item)
                                        //}
                                    })
                                    // console.log('transitedelaydata ', transitedelaydata);

                                    params.successCallback(legsarr);
                                    //console.log("Step 2 ",response.data)

                                });

                        }
                    }
                });

                //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
                if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
                    params.node.setExpanded(!params.node.expanded);
                    //console.log("expanded ",params.node.expanded)
                    if (params.node.expanded == false) {
                        transitedelaydata = []
                    }
                }
                else {

                    params.node.setExpanded(false);
                }
                //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

            }
            else {
                this.setState({

                    detailCellRendererParams: {
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
                                { headerName: Constant.COL_SPEED_KMPH, field: "speed" },
                                { headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
                            ]

                        },
                        getDetailRowData: function (params) {
                            //console.log("Child ", params);
                            params.successCallback([]);
                        }
                    }
                });
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: "Transit for this consignment has not yet started",
                basicType: "info"
            });
        }

    }

    /*NO GPS DATA*/


    onShowNoGPSKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.gpsAvailable;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowNoGPSKpi, params = ",params);
        //console.log(params);
        //console.log("Consign onShowNoGPSKpi params = ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var transitedelaydata = [];

            await this.setState({

                detailCellRendererParams: {
                    suppressRefresh: true,
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "",
                                field: "_id",
                                cellRendererFramework: RoutemapNoGPSAction,
                                width: 50,
                                suppressSizeToFit: true,
                                cellClass: function (params) {
                                    if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                        return 'green';
                                    }
                                    else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                        return 'lightred';
                                    }
                                    else {
                                        return '';
                                    }

                                }
                            },

                            {
                                headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 100, resizable: true,
                                cellClass: function (params) {
                                    if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                        return 'green';
                                    }
                                    else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                        return 'lightred';
                                    }
                                    else {
                                        return '';
                                    }

                                }
                            },
                            {
                                headerName: Constant.COL_LAST_DATA_RECEIVED,
                                field: "last_packet_datetime",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
                                },
                                cellClass: function (params) {
                                    if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                        return 'green';
                                    }
                                    else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                        return 'lightred';
                                    }
                                    else {
                                        return '';
                                    }

                                }
                            },
                            {
                                headerName: Constant.COL_DATA_RECEIVED_ON,
                                field: "data_received_on",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.packet_received_on);
                                },
                                cellClass: function (params) {
                                    if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                                        return 'green';
                                    }
                                    else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                                        return 'lightred';
                                    }
                                    else {
                                        return '';
                                    }

                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",transitedelaydata)
                        //console.log("Step 4 ",params)
                        redirectURL.post('/consignments/nogpsdata', {
                            consignment_code: params.data.consignment_codes,
                            truck_no: params.data.truck_no
                        })
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                transitedelaydata = response.data;
                                var legsarr = []
                                transitedelaydata.map((item) => {
                                    //if(item.trip_completed < 2)
                                    //{
                                    legsarr.push(item)
                                    //}
                                })
                                // console.log('transitedelaydata ', transitedelaydata);

                                params.successCallback(legsarr);
                                //console.log("Step 2 ",response.data)

                            });

                    }
                }
            });

            //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
            if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'is_gps_working') {
                params.node.setExpanded(!params.node.expanded);
                //console.log("expanded ",params.node.expanded)
                if (params.node.expanded == false) {
                    transitedelaydata = []
                }
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
                            { headerName: Constant.COL_SPEED_KMPH, field: "speed" },
                            { headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }


    onShowOverspeedKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.overspeed;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowOverspeedKpi, params = ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var overspeedData = [];

            //console.log("Step 3 ",overspeedData)
            var propsdata = params;
            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",
                                width: 50,
                                cellRendererFramework: RoutemapAction,
                                resizable: true
                            },
                            { headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
                            { headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
                            {
                                headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.first_instance);
                                }
                            },
                        ],
                        overlayNoRowsTemplate: 'No rows to show',

                    },
                    getDetailRowData: function (param) {
                        // console.log("Step 4 ",param)
                        redirectURL.post('/consignments/overspeed', {
                            consignment_code: param.data.consignment_codes,
                            truck_no: param.data.truck_no
                        })
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                param.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    },
                    masterDetail: true
                }
            });

            if (propsdata.column.colDef.field == 'overspeeding_exception') {

                propsdata.node.setExpanded(!propsdata.node.expanded);
            }
            else {

                propsdata.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: "Truck No", field: "truck_no" },
                            { headerName: "Speed", field: "speed" },
                            { headerName: "Reported At", field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }

    onShowNoGPSRouteMap(rownode) {
        //console.log("IN onShowNoGPSRouteMap, params = ",rownode);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log("rownode", rownode);
        this.setState({
            loadshow: 'show-m'
        })
        var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        //var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            //consignee_code:rownode.consignee_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/reports/nightdriveroutes', params)
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                //console.log("Inner Grid ",response.data);
                if (response.data.coords.length == 0) {
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }
                else {
                    var sdate = '';
                    var edate = '';

                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
                    }
                    this.setState({
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        sliderRouteTranslate: "slider-translate-60p",
                        defTransitCoords: rownode.consignee_coordinates,
                        consignment_code: "Consignment : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls

                    });

                    this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
                    this.renderMap();

                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    onShowNightDriveKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.nightDrive;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowNightDriveKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",

                                cellRendererFramework: RouteNightDrivemap,
                                width: 50,
                                suppressSizeToFit: true
                            },
                            { headerName: Constant.COL_TRUCK_NO, field: "truck_no", width: 150, resizable: true },
                            {
                                headerName: Constant.COL_TRAVELLED_FROM, field: "first_instance", width: 180, resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.first_instance);
                                }
                            },
                            {
                                headerName: Constant.COL_TRAVELLED_UNTIL, field: "recent_instance", resizable: true,
                                valueGetter: function (params) {
                                    return getDDMMYYYYHHMMSS(params.data.recent_instance);
                                }
                            },
                            {
                                headerName: Constant.COL_DISTANCE_TRAVELLED_KM, field: "distance_from_first_instance", width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    return Math.round(params.data.distance_from_first_instance)
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",overspeedData)
                        redirectURL.post('/consignments/nightdriving', {
                            consignment_code: params.data.consignment_codes,
                            gate_out_time: params.data.gate_out_time,
                            truck_no: params.data.truck_no
                        })
                            .then(async (response) => {
                                //console.log("Step 1 ",response.data)
                                overspeedData = response.data;
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }

                }
            });

            //if(params.column.colId == 'nightdriving_exception')
            if (params.column.colDef.field == 'nightdriving_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
                            { headerName: Constant.COL_SPEED_KMPH, field: "speed" },
                            { headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }

    onShowLoadingDelayKpi = async (params) => {

        let eventLabel = googleAnalytics.page.action.loadingDelay;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowLoadingDelayKpi, params = ",params);
        //console.log(e);
        //console.log("Consign onShowLoadingDelayKpi ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var overspeedData = [];

            var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
            var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            {
                                headerName: Constant.COL_GATE_IN_TIME,
                                field: "gate_in_time",
                                width: 180,
                                resizable: true,
                                hide: hideonTNP,
                                valueGetter: function (params) {

                                    if (params.data.gate_in_time) {
                                        //return getDDMMYYYYHHMMSS(params.data.gate_in_time);
                                        return params.data.gate_in_time;
                                    }
                                    else {
                                        return "NA"
                                    }

                                }
                            },

                            {
                                headerName: Constant.COL_GATE_OUT_TIME,
                                field: "gate_out_time",
                                width: 180,
                                resizable: true,
                                hide: hideonTNP,
                                valueGetter: function (params) {
                                    //	console.log("IN gate_out_time valueGetter, params = ", params);

                                    if (params.data.gate_out_time) {
                                        //console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
                                        //return getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                        return params.data.gate_out_time;
                                    }
                                    else {
                                        //console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
                                        return "NA"
                                    }
                                }
                            },

                            {
                                headerName: Constant.COL_LOADING_TIME,
                                field: "loading_time",
                                width: 180,

                            }

                        ],
                        overlayNoRowsTemplate: 'No rows to show',


                    },
                    getDetailRowData: function (params) {
                        // console.log("Step 4 ",params)
                        if (hideonTNP != true) {
                            // console.log("Inner here")
                            if (params.data.gate_in_time != undefined) {
                                var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
                                //var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
                                var gdate1 = new Date(params.data.gate_in_time);
                                var fromDate = g1;
                                var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
                            }
                            else {

                                var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
                                var gdate1 = new Date(params.data.inside_fence);
                                var fromDate = g1;
                                var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

                            }
                            //console.log("g1 ", g1)//console.log("Step 4 ",params)
                            if (params.data.gate_out_time != undefined) {
                                var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                var gdate2 = new Date(params.data.gate_out_time);
                                var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                //console.log("gatein ", gatein)
                                //console.log("gateout ", gateout)
                                //var total =0 ;
                                var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

                            }
                            else {
                                var c = new Date();
                                var todaydate = moment(new Date(c)).format("x");
                                var g2 = getDDMMYYYYHHMMSS(todaydate);
                                var gdate2 = new Date(todaydate);
                                var toField = getDDMMYYYYHHMMSS(new Date());
                                var secondsgate2 = todaydate

                            }
                        }
                        else {
                            var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
                            var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
                            var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
                            var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
                        }

                        //console.log("secondsg1 ", secondsg1)
                        //var g2 = getDDMMYYYY(params.data.gate_out_time);

                        //console.log("g1 ", g2)
                        // var s1 = g1.split("-");
                        // var s2 = g2.split("-");


                        // var gatein = gdate1.getTime();
                        // var gateout = gdate2.getTime();
                        var total = secondsgate2 - secondsgate1;
                        //console.log("total ", total)
                        var diffMs = total;
                        var diffDays = Math.ceil(diffMs / 86400000); // days
                        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
                        // console.log(diffDays+" "+diffHrs+" "+diffMins);
                        let loadingTimeDifference = null;

                        var dataarr = []

                        dataarr.push({
                            "gate_in_time": fromDate,
                            "gate_out_time": toField,
                            //"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
                            "loading_time": secondsToString(diffMs / 1000)
                        })
                        // console.log("dataarr ", dataarr)
                        params.successCallback(dataarr);
                    }

                }
            });

            //if(params.column.colId == 'loading_delay_exception')

            if (params.column.colDef.field == 'loading_delay_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {

        }

    }

    onShowUnLoadingDelayKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.unloadingDelay;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowUnLoadingDelayKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            {
                                headerName: Constant.COL_FROM_DATE,
                                field: "from_date",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    // console.log("child params ", params)
                                    if (params.data.from_date != undefined) {
                                        return getDDMMYYYYHHMMSS(params.data.from_date);
                                    }
                                    else {
                                        return "NA"
                                    }

                                }
                            },
                            {
                                headerName: Constant.COL_TO_DATE,
                                field: "to_date",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    if (params.data.to_date != undefined) {
                                        return getDDMMYYYYHHMMSS(params.data.to_date);
                                    }
                                    else {
                                        return "NA"
                                    }
                                }
                            },
                            {
                                headerName: Constant.COL_UNLOADING_TIME,
                                field: "unloading_time",
                                width: 180,

                            }

                        ],
                        overlayNoRowsTemplate: 'No rows to show',

                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",params)
                        if (params.data.locked_dealer_reported == undefined) {
                            var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
                            var fromField = g1;
                        }
                        else {

                            var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
                            var fromField = g1;
                        }
                        //console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
                        if (params.data.left_dealer_marked == undefined) {
                            var c = new Date();
                            var todaydate = c;
                            var g2 = getYYYYMMDDHHMMSS(todaydate);
                            var toField = g2;
                        }
                        else {
                            var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
                            var toField = g2;
                            //console.log("toField stee " ,toField);
                        }
                        var fstr = fromField.split("-");
                        //console.log(fstr);
                        var gdate1 = new Date(fromField);
                        var gdate2 = new Date(toField);
                        var frmd = gdate1.getTime();
                        var tod = gdate2.getTime();
                        var total = tod - frmd;
                        //console.log("From Date ", fromField)
                        //console.log("To Date ", toField)
                        //var total =0 ;
                        //console.log("gdate1 ", frmd)
                        //console.log("gdate2 ", tod)
                        //console.log("Total ", total)
                        var diffMs = total;
                        var diffDays = Math.floor(diffMs / 86400000); // days
                        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

                        // var sr = params.data.unloading_time_in_hrs;
                        // var hrs=sr.toString().split(".");
                        // var hours = parseInt(hrs[0]);
                        // var mins = Math.round((hrs[1]/100)*60)
                        var dataarr = []
                        // dataarr.push({
                        // 	"from_date":fromField,
                        // 	"to_date": toField,
                        // 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
                        // 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
                        // })
                        let loadingTimeDifference = null;
                        // if (diffDays) {
                        // 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
                        // } else {
                        // 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
                        // }
                        loadingTimeDifference = secondsToString(diffMs / 1000)
                        dataarr.push({
                            "from_date": fromField,
                            "to_date": toField,
                            "unloading_time": loadingTimeDifference
                            //"unloading_time":hours+"Hr(s) "+mins+" min(s)"
                        })
                        params.successCallback(dataarr);

                    }

                }
            });

            //if(params.column.colId == 'unloading_delay_exception')
            if (params.column.colDef.field == 'unloading_delay_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {

        }

    }
    onShowNightDriveRouteMap(rownode) {
        // console.log("IN onShowNightDriveRouteMap, params = ",rownode);
        // console.log("nightdrivenode", rownode);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m'
        });
        var d = new Date(rownode.first_instance);
        var r = new Date(rownode.recent_instance);
        var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            first_instance: fintance,
            recent_instance: eintance,
        }

        redirectURL.post('/reports/nightdriveroutes', params)
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                // console.log("nightroutedata",response.data);

                this.setState({
                    mapinfo: response.data,
                    sliderRouteTranslate: "slider-translate-50p",
                    consignment_code: rownode.truck_no,
                    defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
                    maptruckno: rownode.truck_no,
                    routeTruck: {
                        "truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
                        "endTime": response.data['route_details']['end_time']
                    },
                    loadshow: 'show-n',
                    overly: 'show-m',
                    tolls: response.data.tolls
                });
                this.renderMap();


            })
            .catch(function (error) {
                console.log(error);
            });


    }


    onShowEnrouteStoppagesKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.enrouteStoppages;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);

        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            // { 
                            //     headerName: "",
                            //     field: "", 
                            // 	width:50,
                            //     cellRendererFramework:RoutemapenrouteAction,
                            // },

                            {
                                headerName: "Address",
                                field: "address",
                                width: 250,
                                resizable: true
                            },
                            {
                                headerName: Constant.COL_BREAK_START,
                                field: "stoppage_start_time",
                                width: 150,
                                resizable: true,
                                valueGetter: function (params) {
                                    if (params.data.stoppage_start_time != undefined) {
                                        return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
                                    }
                                    else {
                                        return '';
                                    }

                                }
                            },
                            // { 
                            // 	headerName: "Break End",
                            // 	field: "reset_start_time", 
                            // 	width:180,
                            // 	resizable: true,
                            // 	valueGetter:function(params){
                            // 		if(params.data.reset_start_time != undefined)
                            // 		{
                            // 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
                            // 		}
                            // 		else{
                            // 			return '';
                            // 		}

                            // 	}
                            // },

                            {
                                headerName: Constant.COL_EXP_CLOSED_ON,
                                field: "modified_date",
                                width: 220,
                                resizable: true,
                                valueGetter: function (params) {
                                    if (params.data.exception_closed == 1 && params.data.modified_date != "Total") {

                                        if (params.data.modified_date != undefined) {
                                            return getDDMMYYYYHHMMSS(params.data.modified_date)
                                        }
                                        else {
                                            return '';
                                        }
                                    }
                                    else {
                                        if (params.data.modified_date == "Total") {
                                            return 'Total';
                                        }
                                        else {
                                            return '';
                                        }
                                    }

                                }
                            },
                            {
                                headerName: Constant.COL_BREAK_TIME,
                                field: "elaped_time_from_last_moved_seconds",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    if (params.data.exception_closed == 1 && params.data.modified_date != "Total") {
                                        // if(params.data.elaped_time_from_last_moved_seconds != undefined)
                                        // {
                                        // 	var seconds = params.data.elaped_time_from_last_moved_seconds;
                                        // 	var days = parseInt(seconds / (24 * 3600)); 
                                        // 	var hours = parseInt(seconds/3600);
                                        // 	var minutes = Math.round((seconds-(hours*3600))/60)
                                        // 	//return hours+" Hr(s) "+minutes+" Min(s)";
                                        // 	return secondsToString(seconds)
                                        // }
                                        // else{
                                        // 	return '';
                                        // }
                                        if (params.data.elaped_time_from_last_moved_seconds != "" && params.data.elaped_time_from_last_moved_seconds != undefined) {
                                            return secondsToString(params.data.elaped_time_from_last_moved_seconds);
                                        }
                                        else {
                                            var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
                                            if (typeof params.data.reset_datetime == 'undefined') {
                                                var edate = moment.parseZone().format("x");
                                            }
                                            else {
                                                var edate = moment.parseZone(params.data.reset_datetime).format("x");
                                            }
                                            var diff = (edate - sdate) / 1000;
                                            return secondsToString(diff);
                                        }

                                    }
                                    else {
                                        if (params.data.modified_date == "Total") {
                                            return secondsToString(params.data.elaped_time_from_last_moved_seconds);
                                        }
                                        else {
                                            return '';
                                        }
                                    }
                                }
                            }
                        ],
                        overlayNoRowsTemplate: 'No rows to show',
                    },
                    getDetailRowData: async function (params) {
                        //console.log("Step 4 ",overspeedData)
                        if (params.data.is_transshipment == 1) {
                            var oldtruck = params.data.old_truck_no
                        }
                        else {
                            var oldtruck = ''
                        }
                        redirectURL.post('/consignments/enroutes', {
                            consignment_code: params.data.consignment_codes,
                            truck_no: params.data.truck_no,
                            old_truck_no: oldtruck
                        })
                            .then(async (response) => {
                                console.log("Step 1 ", response.data)
                                overspeedData = response.data;
                                var summaryrow = {};
                                var elaped_time_from_last_moved_seconds = 0;
                                console.log("elaped_time_from_last_moved_seconds Step1", elaped_time_from_last_moved_seconds)
                                if (overspeedData.length > 0) {
                                    overspeedData.map((it) => {
                                        console.log(" elaped_time_from_last_moved_seconds Step2", it.elaped_time_from_last_moved_seconds)
                                        elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds + it.elaped_time_from_last_moved_seconds;
                                    })
                                }
                                console.log("elaped_time_from_last_moved_seconds Step3	", elaped_time_from_last_moved_seconds)
                                if (params.data.enroute_exception == 1) {
                                    summaryrow.stoppage_start_time = "";
                                    summaryrow.modified_date = "Total";
                                    summaryrow.elaped_time_from_last_moved_seconds = elaped_time_from_last_moved_seconds;
                                    overspeedData.push(summaryrow)
                                }
                                params.successCallback(overspeedData);
                                //console.log("Step 2 ",response.data)

                            });

                    }
                }
            });

            if (params.column.colDef.field == 'enroute_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {
            this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            { headerName: Constant.COL_TRUCK_NO, field: "truck_no" },
                            { headerName: Constant.COL_SPEED_KMPH, field: "speed" },
                            { headerName: Constant.COL_REPORTED_AT, field: "first_instance" }
                        ]

                    },
                    getDetailRowData: function (params) {
                        //console.log("Child ", params);
                        params.successCallback([]);
                    }
                }
            });
        }

    }


    onShowEnrouteRouteMap(rownode) {
        //console.log("IN onShowEnrouteRouteMap, params = ",params);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        // console.log("rownode", rownode);
        this.setState({
            loadshow: 'show-m'
        })
        var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        if (rownode.reset_datetime) {
            var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

        }
        else {
            var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

        }
        //var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            //consignment_code:rownode.consignment_code,
            //consignee_code:rownode.consignee_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment"
        }
        redirectURL.post('/reports/nightdriveroutes', params)
            .then((response) => {

                //var records = JSON.parse(JSON.stringify(response)).data;
                let records = response.data
                // console.log("Inner Grid ",records);
                if (response.data.coords.length == 0) {
                    this.setState({
                        show: true,
                        basicTitle: 'No Route data available',
                        basicType: "danger",
                        loadshow: 'show-n'
                    });
                }
                else {
                    var sdate = '';
                    var edate = '';

                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
                        //edate=rownode.leg_end_eta;
                    }

                    if (response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined) {
                        sdate = records.route_details.start_time;

                    }
                    if (response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined) {
                        edate = records.route_details.end_time;
                    }
                    this.setState({
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        sliderRouteTranslate: "slider-translate-60p",
                        defTransitCoords: rownode.consignee_coordinates,
                        consignment_code: "Consignment : " + rownode.truck_no,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls

                    });

                    this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
                    this.renderMap();

                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    onShowLoadingDelayKpi = async (params) => {

        let eventLabel = googleAnalytics.page.action.loadingDelay;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowLoadingDelayKpi, params = ",params);
        //console.log(e);
        //console.log("Consign onShowLoadingDelayKpi ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var overspeedData = [];

            var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
            var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            {
                                headerName: Constant.COL_GATE_IN_TIME,
                                field: "gate_in_time",
                                width: 180,
                                resizable: true,
                                hide: hideonTNP,
                                valueGetter: function (params) {

                                    if (params.data.gate_in_time) {
                                        //return getDDMMYYYYHHMMSS(params.data.gate_in_time);
                                        return params.data.gate_in_time;
                                    }
                                    else {
                                        return "NA"
                                    }

                                }
                            },

                            {
                                headerName: Constant.COL_GATE_OUT_TIME,
                                field: "gate_out_time",
                                width: 180,
                                resizable: true,
                                hide: hideonTNP,
                                valueGetter: function (params) {
                                    //	console.log("IN gate_out_time valueGetter, params = ", params);

                                    if (params.data.gate_out_time) {
                                        //console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
                                        //return getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                        return params.data.gate_out_time;
                                    }
                                    else {
                                        //console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
                                        return "NA"
                                    }
                                }
                            },

                            {
                                headerName: Constant.COL_LOADING_TIME,
                                field: "loading_time",
                                width: 180,

                            }

                        ],
                        overlayNoRowsTemplate: 'No rows to show',


                    },
                    getDetailRowData: function (params) {
                        // console.log("Step 4 ",params)
                        if (hideonTNP != true) {
                            // console.log("Inner here")
                            if (params.data.gate_in_time != undefined) {
                                var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
                                //var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
                                var gdate1 = new Date(params.data.gate_in_time);
                                var fromDate = g1;
                                var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
                            }
                            else {

                                var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
                                var gdate1 = new Date(params.data.inside_fence);
                                var fromDate = g1;
                                var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

                            }
                            //console.log("g1 ", g1)//console.log("Step 4 ",params)
                            if (params.data.gate_out_time != undefined) {
                                var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                var gdate2 = new Date(params.data.gate_out_time);
                                var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
                                //console.log("gatein ", gatein)
                                //console.log("gateout ", gateout)
                                //var total =0 ;
                                var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

                            }
                            else {
                                var c = new Date();
                                var todaydate = moment(new Date(c)).format("x");
                                var g2 = getDDMMYYYYHHMMSS(todaydate);
                                var gdate2 = new Date(todaydate);
                                var toField = getDDMMYYYYHHMMSS(new Date());
                                var secondsgate2 = todaydate

                            }
                        }
                        else {
                            var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
                            var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
                            var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
                            var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
                        }

                        //console.log("secondsg1 ", secondsg1)
                        //var g2 = getDDMMYYYY(params.data.gate_out_time);

                        //console.log("g1 ", g2)
                        // var s1 = g1.split("-");
                        // var s2 = g2.split("-");


                        // var gatein = gdate1.getTime();
                        // var gateout = gdate2.getTime();
                        var total = secondsgate2 - secondsgate1;
                        //console.log("total ", total)
                        var diffMs = total;
                        var diffDays = Math.ceil(diffMs / 86400000); // days
                        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
                        // console.log(diffDays+" "+diffHrs+" "+diffMins);
                        let loadingTimeDifference = null;

                        var dataarr = []

                        dataarr.push({
                            "gate_in_time": fromDate,
                            "gate_out_time": toField,
                            //"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
                            "loading_time": secondsToString(diffMs / 1000)
                        })
                        // console.log("dataarr ", dataarr)
                        params.successCallback(dataarr);
                    }

                }
            });

            //if(params.column.colId == 'loading_delay_exception')

            if (params.column.colDef.field == 'loading_delay_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {

        }

    }

    onShowUnLoadingDelayKpi = async (params) => {
        let eventLabel = googleAnalytics.page.action.unloadingDelay;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        //console.log("IN onShowUnLoadingDelayKpi, params = ",params);
        //console.log(e);
        //console.log("Consign Overspeed ",params);
        //console.log(params.column.colId);
        if (params != 0) {
            var overspeedData = [];


            //console.log("Step 3 ",overspeedData)

            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [

                            {
                                headerName: Constant.COL_FROM_DATE,
                                field: "from_date",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    // console.log("child params ", params)
                                    if (params.data.from_date != undefined) {
                                        return getDDMMYYYYHHMMSS(params.data.from_date);
                                    }
                                    else {
                                        return "NA"
                                    }

                                }
                            },
                            {
                                headerName: Constant.COL_TO_DATE,
                                field: "to_date",
                                width: 180,
                                resizable: true,
                                valueGetter: function (params) {
                                    if (params.data.to_date != undefined) {
                                        return getDDMMYYYYHHMMSS(params.data.to_date);
                                    }
                                    else {
                                        return "NA"
                                    }
                                }
                            },
                            {
                                headerName: Constant.COL_UNLOADING_TIME,
                                field: "unloading_time",
                                width: 180,

                            }

                        ],
                        overlayNoRowsTemplate: 'No rows to show',

                    },
                    getDetailRowData: function (params) {
                        //console.log("Step 4 ",params)
                        if (params.data.locked_dealer_reported == undefined) {
                            var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
                            var fromField = g1;
                        }
                        else {

                            var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
                            var fromField = g1;
                        }
                        //console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
                        if (params.data.left_dealer_marked == undefined) {
                            var c = new Date();
                            var todaydate = c;
                            var g2 = getYYYYMMDDHHMMSS(todaydate);
                            var toField = g2;
                        }
                        else {
                            var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
                            var toField = g2;
                            //console.log("toField stee " ,toField);
                        }
                        var fstr = fromField.split("-");
                        //console.log(fstr);
                        var gdate1 = new Date(fromField);
                        var gdate2 = new Date(toField);
                        var frmd = gdate1.getTime();
                        var tod = gdate2.getTime();
                        var total = tod - frmd;
                        //console.log("From Date ", fromField)
                        //console.log("To Date ", toField)
                        //var total =0 ;
                        //console.log("gdate1 ", frmd)
                        //console.log("gdate2 ", tod)
                        //console.log("Total ", total)
                        var diffMs = total;
                        var diffDays = Math.floor(diffMs / 86400000); // days
                        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                        var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

                        // var sr = params.data.unloading_time_in_hrs;
                        // var hrs=sr.toString().split(".");
                        // var hours = parseInt(hrs[0]);
                        // var mins = Math.round((hrs[1]/100)*60)
                        var dataarr = []
                        // dataarr.push({
                        // 	"from_date":fromField,
                        // 	"to_date": toField,
                        // 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
                        // 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
                        // })
                        let loadingTimeDifference = null;
                        // if (diffDays) {
                        // 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
                        // } else {
                        // 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
                        // }
                        loadingTimeDifference = secondsToString(diffMs / 1000)
                        dataarr.push({
                            "from_date": fromField,
                            "to_date": toField,
                            "unloading_time": loadingTimeDifference
                            //"unloading_time":hours+"Hr(s) "+mins+" min(s)"
                        })
                        params.successCallback(dataarr);

                    }

                }
            });

            //if(params.column.colId == 'unloading_delay_exception')
            if (params.column.colDef.field == 'unloading_delay_exception') {

                params.node.setExpanded(!params.node.expanded);
            }
            else {

                params.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }
        else {

        }

    }


    selectConsignerOptionsItems() {
        let items = [];
        var consigners = this.state.allplants;

        //console.log("consigners ", consigners)
        items.push({ value: 'ALL', label: 'All' });
        if (consigners.length > 0) {
            consigners.forEach(item => {
                items.push({ value: item.plant_code, label: item.plant_name });
            });
        }

        return items;

    }
    changeConsignerArrayHandler = consigner => {
        this.setState(
            { consigner },
            //   () => console.log(`Consigner Option selected:`, this.state.consigner)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onGetConsigners = async (event) => {


        let consigneeCity = this.state.flCity
        if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
            || this.props.match.path == "/tnpconsignments") {

            if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
                //var sDate = this.state.defaultsdate;
                var sDate = "";
            }
            else {
                var sDate = this.state.startDate;
            }
            if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
                //var eDate = this.state.defaultedate;
                var eDate = "";
            }
            else {
                var eDate = this.state.endDate;
            }

        }
        else {
            if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
                var sDate = this.state.defaultsdate;
            }
            else {
                var sDate = this.state.startDate;
            }
            if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
                var eDate = this.state.defaultedate;
            }
            else {
                var eDate = this.state.endDate;
            }
        }
        // var sDate=this.state.startDate;
        // var eDate = this.state.endDate;
        var selectconsigners = []
        if (this.state.consigner == null) {
            selectconsigners = [{ "value": 'all', "label": 'All' }]
        }
        else {
            selectconsigners = this.state.consigner;
        }
        console.log(selectconsigners, "selectconsigners", this.state.consigner)
        var selectconsignees = []
        if (this.state.consignee == null) {
            selectconsignees = [{ "value": 'all', "label": 'All' }]
        }
        else {
            selectconsignees = this.state.consignee
        }

        var mvmtype = []
        if (this.state.movementtype == null) {
            mvmtype = [{ "value": 'all', "label": 'All' }]
        }
        else {
            mvmtype = [this.state.movementtype]
        }

        //console.log("this.state.movementtype ", this.state.movementtype)
        //console.log("mvmtype ", mvmtype)
        var selecttrip = []
        // if(this.state.triptype == null)
        // {
        // 	selecttrip = [{"value":'all', "label":'All'}]
        // }
        // else{
        selecttrip = [this.state.triptype]
        // }
        let consignmentState = this.state.flState
        var datechk = 1;
        var errpopmsg = "";
        if (this.state.deptcode == 'SNDG') {
            if (sDate != undefined && sDate != "") {
                console.log(eDate, "eDate")
                if (eDate == undefined || eDate == "") {
                    datechk = 0;
                    errpopmsg = "Please select Invoice To Date"
                }
            }
            if (eDate != undefined && eDate != "") {
                if (sDate == undefined || sDate == "") {
                    console.log(sDate, "sDate")
                    datechk = 0;
                    errpopmsg = "Please select Invoice From Date"
                }
            }
            if (sDate != undefined && sDate != "" && eDate != undefined && eDate != "") {
                if (sDate > eDate) {
                    datechk = 0;
                    errpopmsg = "Invoice From Date should be less than Invoice To Date"
                    console.log(sDate, "ss", eDate)
                }
            }
            let cluster = this.state.flCluster
            //console.log("S1 ")
            var requestparams = {
                consigner: selectconsigners,
                dept_code: this.state.deptcode,
                startDate: sDate,
                endDate: eDate
            }
            if (consignmentState !== '' && consignmentState !== undefined) {
                requestparams['consignment_state'] = consignmentState.value
            }
            else if (cluster !== '' && cluster !== undefined) {
                requestparams['cluster'] = cluster.value
            }
            else if (consigneeCity !== '' && consigneeCity !== undefined) {
                requestparams['consigneeCity'] = consigneeCity.value
            }
            let flCustomer = this.state.flCustomer
            if (flCustomer !== "" && (flCustomer) !== undefined && flCustomer !== null) {
                var flList = [];
                flCustomer.map((fl) => {
                    flList.push(fl.value);
                });
                if (flList.length > 0) {
                    requestparams['flCustomer'] = flList
                }
            }
        }
        else if (this.state.deptcode == 'LOG-PRT') {
            //console.log("S2 ")
            var requestparams = {
                consigner: selectconsigners,
                dept_code: this.state.deptcode,
                startDate: sDate,
                endDate: eDate,
                movement: mvmtype
            }
            if (consignmentState !== '' && consignmentState !== undefined) {
                requestparams['consignment_state'] = consignmentState.value
            }
            else if (consigneeCity !== '' && consigneeCity !== undefined) {
                requestparams['consigneeCity'] = consigneeCity.value
            }
        }
        else {
            //console.log("S3 ")
            var requestparams = {
                consigner: selectconsigners,
                consignee: selectconsignees,
                dept_code: this.state.deptcode,
                startDate: sDate,
                endDate: eDate,
                triptype: selecttrip
            }
        }
        if (localStorage.getItem("is_sales_type") == 1) {
            requestparams.sales_type = localStorage.getItem("sales_type");
            requestparams.region = localStorage.getItem("region");
        }
        let transporter = this.state.flTransporter

        if (transporter !== "" && transporter !== undefined && transporter !== null) {
            if (transporter.value != "") {
                // parameters['transporter'] = [transporter.label]
                requestparams['transporter'] = transporter.value
            }
        }
        console.log(requestparams.startDate, "3383")
        let transporter_zone = this.state.flTransporterZone
        if (transporter_zone !== '' && transporter_zone !== undefined) {
            if (transporter_zone.value !== '' && transporter_zone.value !== undefined) {
                requestparams['transporter_zone'] = transporter_zone.value
            }
        }

        var transportercode = localStorage.getItem('transportercode');
        if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
            requestparams.transporter_code = transportercode;
        }
        try {
            if (localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined) {
                requestparams.consigner = [{ value: localStorage.getItem("pc"), label: localStorage.getItem("pc") }];
            }
        } catch (e) {

        }
        if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
            requestparams.customer_code = localStorage.getItem("customer_code")
        }
        var urlpath = '';
        urlpath = '/consignments/allsndconsignments';
        console.log(requestparams,"requestparams")
        if (datechk == 1) {
           this.onLoadDeliveredTrucks(requestparams)
        }
        else {
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: errpopmsg
            })
        }
    }
    onClickReturnToPlant(rowdata) {
        var row = rowdata.data
        var consignee_code = row.consignee_code
        var truck_no = row.truck_no
        var consignment_code = row.shipment_id
        var dept_code = "SNDG";
        var userid = localStorage.getItem('userid');
        var reqdata = {
            dept_code: dept_code,
            shipment_id: [consignment_code],
            truck_no: truck_no,
            userid: userid,
            reasonforceclose: "Return To Plant",
            consignee_code: consignee_code
        }
        console.log(row, "1234")
        redirectURL.post("/amns/returnToPlant", reqdata).then((resp) => {
            if (resp.data.status == "Success") {
                this.setState({
                    show: true,
                    basicTitle: "Successfully ",
                    basicType: "success"
                })
            }
        })
    }
    onLoadGetConsignmentsList(urlpath, parameters) {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        if (localStorage.getItem("region") != undefined && localStorage.getItem("region") != "") {
            parameters.region = localStorage.getItem("region");
            parameters.sales_type = localStorage.getItem("sales_type")
        }
        if (parameters.flCustomer != undefined && parameters.flCustomer != "undefined" && parameters.flCustomer != "") {
            parameters.customer_code = JSON.stringify(parameters.flCustomer);
        }
        redirectURL.post(urlpath, parameters)
            .then((response) => {
                console.log("response.data ", response.data)
                var records = response.data.consignments;
                var pagecounters = response.data.counters;
                pagecounters['deviated_routes'] = records.filter(e => e.deviation_flag == 1)
                var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
                pagecounters.cancelled_count = "Not Applicable"
                var cancelled_data = []
                var pod_received_data = [];
                var trip_closed_by_pod = [];
                records = records.sort(GetSortDescOrder("_id"));
                var gridData = records;
                var selectedCity = $("#selectedCity").text();
                var selectedState = $("#selectedState").text();
                var selectedCluster = $("#selectedCluster").text();
                var selectedTransporter = $("#selectedTransporter").text();
                // console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
                //console.log(filterRowData,"0")
                if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select..." && selectedCity != "All") {
                    gridData = gridData.filter(e => e.consignee_city == selectedCity);
                    // console.log(gridData,"1")
                }
                if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
                    gridData = gridData.filter(e => e.consignee_state == selectedState);
                }
                if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
                    gridData = gridData.filter(e => e.cluster == selectedCluster);
                }
                if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select..." && selectedTransporter != "All") {
                    gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
                }
                if (localStorage.getItem("user_type") == "CUSTOMER") {
                    if (gridData.length > 0) {
                        gridData = gridData.filter((t) => t.is_tracking_available == 1);

                    }
                }
                this.setState({
                    rowData: gridData.filter(e=>this.state.road_btn=="btn-danger"?e.shipment_mode!="RAIL" : e.shipment_mode=="RAIL"),
                    allRowData: gridData,
                    originalData: gridData,
                    beforeNewFilterRowData: gridData,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    countersjson: pagecounters,
                    originalcountersjson: pagecounters,
                    beforeNewFiltercountersjson: pagecounters,
                    consBtn: "btn-danger",
                    returnConsBtn: "btn-default"
                });
                // if(this.state.urlpath == '/consignments/filterTNPActiveConsignments')
                // {

                // }
                // else
                // {
                // 	this.setState({

                // 	})
                // }


            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onClickShowShipmentLegs = async (rownode) => {
        // console.log("rownode", rownode)
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                cellRendererFramework: LegRouteMap,
            },
            {
                headerName: Constant.COL_TRUCK_NO,
                field: "truck_no",
                resizable: true,
                width: 120,
                filter: true
            },
            //   {
            // 	headerName : Constant.COL_CONSIGNMENT_CODE,
            // 	field : "consignment_code",
            // 	resizable : true,
            // 	width:140,
            // 	filter : true
            //   },
            {
                headerName: Constant.COL_LOADING_AREA_NAME,
                field: "fence_name",
                resizable: true,
                width: 200,
                filter: true
            },
            {
                headerName: Constant.COL_LEGT_START_TIME,
                field: "first_inside_fence",
                resizable: true,
                width: 160,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.first_inside_fence != "" && params.data.first_inside_fence != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: Constant.COL_LEGT_END_TIME,
                field: "first_outside_fence",
                resizable: true,
                width: 160,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.first_outside_fence != "" && params.data.first_outside_fence != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_outside_fence);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: Constant.COL_TIME_SPEND,
                field: "time_spent",
                resizable: true,
                width: 200,
                filter: true,
                valueGetter: function (params) {
                    try {

                        return params.data.time_spent;

                    }
                    catch (e) { }
                }
            },

        ]
        await this.setState({

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',

                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    // console.log("Params ", param);
                    if (param.data.tpa_entry_time != "" && param.data.tpa_entry_time != undefined) {
                        var startdate = param.data.tpa_entry_time;
                    }
                    else {
                        var startdate = param.data.parking_in_time;
                    }

                    var cdate = moment.parseZone().format("YYYY-MM-DD HH:mm");
                    var enddate = moment.parseZone(new Date(cdate + ":00")).utcOffset("+05:30")._d;

                    if (param.data.status == "10" || param.data.status == "11" || param.data.status == 10 || param.data.status == 11) {
                        var enddate = param.data.recent_dealer_reported;
                    }
                    else {
                        if (param.data.status == 6 || param.data.status == "6") {
                            var enddate = param.data.force_closure_time;
                        }
                        else {
                            var enddate = moment.parseZone(new Date(cdate + ":00")).utcOffset("+05:30")._d;
                        }

                    }

                    var parameters = {
                        consignment_code: param.data.consignment_code,
                        truck_no: param.data.truck_no,
                        startdate: startdate,
                        enddate: enddate
                    }
                    await redirectURL.post("/amns/loadingarealegsdata", parameters).then(async (response) => {
                        //console.log("Step 1 ",response.data)
                        // console.log(response.data.data);
                        var comments = response.data;
                        var output = []
                        if (comments.length > 0) {
                            comments.map((item) => {
                                try {
                                    if (item.first_inside_fence != "" && item.first_inside_fence != undefined) {
                                        var ste = item.first_inside_fence;
                                        console.log("ste ", ste);
                                        var stsec = moment.parseZone(ste).format("x");
                                        //    item.orderpos = stsec;
                                    }
                                    if (item.first_outside_fence != "" && item.first_outside_fence != undefined) {
                                        var etsec = moment.parseZone(item.first_outside_fence).format("x")
                                    }
                                    else {
                                        var c = moment.parseZone().format("YYYY-MM-DD HH:mm");
                                        var d = moment.parseZone(new Date(c + ":00")).utcOffset("+05:30")._d;
                                        var etsec = moment.parseZone(d).format("x");
                                    }
                                    // console.log("stsec ", stsec);
                                    // console.log("etsec ", etsec);
                                    var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
                                    // console.log("timespent diff ", diff);
                                    var timespent = secondsToDhms(diff);
                                    // console.log("timespent ", timespent);
                                    item.time_spent = timespent;

                                }
                                catch (e) {

                                }
                                output.push(item);
                            })
                        }
                        output.sort(GetSortASCOrder("orderpos"));
                        param.successCallback(output);


                        //console.log("Step 2 ",response.data)

                    }).catch(function (error) {
                        console.log(error);
                    })

                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if (rownode.column.colDef.field == 'shipmentlegs') {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else {

            rownode.node.setExpanded(false);
        }
    }

    onClickShowLoadingAreaLegs = async (rownode) => {
        console.log("rownode", rownode)

        if (localStorage.getItem('user_type') == "MARKETING") {
            var hideforsales = true;
        }
        else {
            var hideforsales = false;
        }
        var hideData = "";
        if (localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) {
            var hideData = false;
        }
        else {
            var hideData = true;
        }
        var hideForCustomer = "";
        var hideForNotCustomer = "";
        if (localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
            var hideForCustomer = true;
            var hideData = true;
            var hideforsales = true;
            var hideForNotCustomer = false;
        }
        else {
            var hideForCustomer = false;
            var hideForNotCustomer = true;
        }
        var commentHeaders = [
            {
                headerName: Constant.COL_CONSIGNMENT_CODE,
                field: "consignment_code",
                resizable: true,
                width: 140,
                filter: true
            },
            {
                headerName: Constant.COL_STATUS,
                field: "status",
                resizable: true,
                width: 140,
                filter: true,
                valueGetter: function (params) {
                    //console.log("GPS ", params.data.gps_data_available);
                    if (params.data.status == 1) {
                        return Constant.INSIDE_TPA;
                    }
                    else if (params.data.status == 2) {
                        return Constant.INSIDE_MY_PMILL;
                    }

                    if (params.data.status == 1 || params.data.status == "1") {
                        return Constant.INSIDE_TPA;
                    }
                    if (params.data.status == 2 || params.data.status == "2") {
                        return Constant.INTRANSIT_TO_MY;
                    }
                    if (params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5") {
                        return Constant.INSIDE_MY_PMILL;
                    }
                    else if (params.data.status == 6) {
                        return Constant.FORCE_CLOSE;
                    }
                    else if (params.data.status == 7) {
                        return Constant.INSIDE_TPA_AFTER_LOADING;
                    }
                    else if (params.data.status == 8) {
                        return Constant.INTRANSIT;
                    }
                    else if (params.data.status == 9) {
                        return Constant.NEAR_CUSTOMER;
                    }
                    else if (params.data.status == 10) {
                        return Constant.REACHED_CUSTOMER;
                    }
                    else if (params.data.status == 11) {
                        return Constant.LEFT_CUSTOMER;
                    }
                    else {
                        return ""
                    }
                }
            },
            {
                headerName: Constant.COL_DELIVERY_NO,
                field: "delivery_no",
                width: 120,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_SALE_ORDER,
                field: "sales_order",
                width: 120,
                resizable: true,
                filter: true,
            },

            {
                headerName: Constant.COL_ITEM_NO,
                field: "item_no",
                width: 120,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_ITEM_DESC,
                field: "item_desc",
                width: 200,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_ITEM_QTY,
                field: "item_quantity",
                width: 120,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.MATERIAL_INFO,
                field: "material_desc",
                resizable: true,
                filter: true,
                width: 120,
            },
            {
                headerName: Constant.SR_GRADE,
                field: "sr_grade",
                resizable: true,
                filter: true,
                width: 120,
            },
            {
                headerName: Constant.COL_CUSTOMER_NAME,
                field: "consignee_name",
                width: 200,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_CUSTOMER_CODE,
                field: "consignee_code",
                width: 120,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_CUSTOMER_CITY,
                field: "transporter_zone",
                width: 140,
                resizable: true,
                filter: true,
            },
            {
                headerName: Constant.COL_CONSIGNEE_STATE,
                field: "consignee_state",
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                width: 140,
                resizable: true,
            },
            {
                headerName: Constant.COL_CONSIGNEE_ADDRESS,
                field: "consignee_address",
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                width: 200,
                resizable: true,
            },
            {
                headerName: Constant.COL_ORIGINAL_ETA,
                field: "expected_trip_end",
                colId: "expected_trip_end",
                id: "expected_trip_end",
                width: 120,
                //pinned: 'left',              
                resizable: true,
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                valueGetter: function (params) {
                    if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
                        return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                    }
                    else {
                        return "N/A";
                    }


                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            {
                headerName: Constant.COL_REVISED_ETA,
                field: "revised_trip_end",
                colId: "revised_trip_end",
                width: 120,
                //pinned: 'left',              
                resizable: true,
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                // cellClass : "dateFormat",
                valueGetter: function (params) {
                    //console.log(" ETA ", params.data)
                    if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                        if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
                            return "N/A"
                        }
                        else {
                            return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                        }

                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
                    }

                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,


                cellClass: function (params) {
                    let hourDifference = 0;
                    if (params.data.revised_trip_end && params.data.expected_trip_end) {
                        let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
                        let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
                        hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
                        //console.log("diff= ", params.data.consignment_code, hourDifference);
                    } else {
                        //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
                    }

                    if (hourDifference >= 1) {
                        return 'bgColorDangerMedium'
                    }

                    if (hourDifference <= -1) {
                        return 'bgColorSuccessMedium'
                    }
                    else {
                        return ''
                    }
                }
            },
            {
                headerName: Constant.COL_TRANSIT_DELAY_HOURS,
                field: "consignment_codes",
                colId: "consignment_codes",
                width: 170, filter: true,
                cellRenderer: '',
                resizable: true,
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                valueGetter: function (params) {
                    var expected = params.data.expected_trip_end;
                    if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                        var revised = params.data.expected_trip_end;
                    }
                    else {
                        var revised = params.data.revised_trip_end;
                    }
                    //console.log("Exp ", expected)
                    //console.log("revised ", revised)
                    var s = moment.parseZone(expected).format("x");
                    var ed = moment.parseZone(revised).format("x");
                    //console.log("Exp ", s)
                    //console.log("revised ", ed)
                    var diff = (ed - s)
                    // console.log("Diff",diff/1000)
                    var days = secondsToDays(diff)
                    //console.log("Days ", Math.round(days/1000))
                    var totaldays = Math.round(days / 1000);
                    if (totaldays > 0) {
                        return totaldays;
                    }
                    else {
                        return 0;
                    }
                    //return Math.round(days/1000);

                }
            },

            {
                headerName: Constant.COL_REACHED_DESTINATION_AT,
                field: "recent_dealer_reported",
                colId: "recent_dealer_reported",
                width: 180,
                //cellRenderer:'dateFormater',
                valueGetter: function (params) {
                    if (params.data.status == 10 || params.data.status == 11) {
                        return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                    }
                    else {
                        return "N/A";
                    }

                },
                resizable: true
            },
            {
                headerName: Constant.COL_LEFT_DESTINATION_AT,
                field: "left_dealer_marked",
                colId: "left_dealer_marked",
                width: 180,
                //filter: "agDateColumnFilter",
                //cellRenderer:'dateFormater',
                valueGetter: function (params) {
                    if (params.data.status == 11) {
                        return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                    }
                    else {
                        return "N/A";
                    }

                },
                resizable: true
            },
            {
                headerName: Constant.COL_EXPECTED_DISTANCE_KM,
                field: "distance_in_km",
                width: 180,
                resizable: true,
                filter: true,
            },
            {
                headerName: "Remaining Distance To Customer (KM)",
                field: "distance_from_dealer_location",
                colId: "distance_from_dealer_location",
                width: 180,
                filter: true,
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    //console.log("params ", params.data);
                    if (typeof params.data.distance_from_dealer_location == 'undefined') {

                    }
                    else {
                        return Math.round(params.data.distance_from_dealer_location);
                    }

                },
            },
            {
                headerName: "Transit Time",
                field: "transit_time",
                colId: "transit_time",
                width: 200,
                filter: true,
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {

                    return params.data.transit_time;

                }
            },
            {
                headerName: "IOD Time",
                field: "first_2_km_entry",
                width: 200,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                hide: hideForNotCustomer,
                suppressColumnsToolPanel: hideForNotCustomer,
                valueGetter: function (params) {
                    try {
                        if (params.data.first_2_km_entry == "No Data Available") {
                            return "No Data Available"
                        }
                        else if (typeof (params.data.first_2_km_entry) == "string") {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_2_km_entry);
                        }
                        else {
                            return "Not Available";
                        }
                    }
                    catch (e) { }
                },

                // filter: "agDateColumnFilter",
                comparator: dateComparator,
            },
            {
                headerName: "Reached Customer Vicinity(IOD)",
                field: "first_2_km_entry",
                width: 200, filter: true,
                cellRenderer: '', resizable: true,
                hide: hideForCustomer,
                suppressColumnsToolPanel: hideForCustomer,
                valueGetter: function (params) {
                    //console.log(params.data.transporter);
                    try {
                        if (params.data.first_2_km_entry != "" && params.data.first_2_km_entry != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.first_2_km_entry);
                        }
                        else {
                            return "No Data Available";
                        }
                    }
                    catch (e) { }

                },
            },
            {
                headerName: Constant.COL_POD_DATE_TIME,
                field: "customer_pod_date",
                width: 200,
                editable: false,
                // cellEditor: DateEditor,
                // //filter: "agDateColumnFilter",
                valueGetter: function (params) {
                    // console.log("params ", params);
                    if (params.data.customer_pod_date != undefined && params.data.customer_pod_date != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.customer_pod_date);
                    }
                    else {
                        return ""
                    }
                },
                resizable: true,
                // comparator: dateComparator1
            },
            {
                headerName: Constant.COL_POD_REMARKS,
                field: "customer_pod_remarks",
                width: 200,
                editable: false,
                resizable: true,
            },
            {
                headerName: Constant.COL_POD_DONE_BY,
                field: "customer_pod_done_by",
                width: 140,
                editable: false,
                resizable: true,
            },
            {
                headerName: "ETA",
                field: "revised_trip_end",
                width: 120,
                resizable: true,
                hide: hideForNotCustomer,
                valueGetter: function (params) {
                    //console.log(" ETA ", params.data)
                    if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
                        if (params.data.expected_trip_end == '' || params.data.expected_trip_end == undefined) {
                            return "N/A"
                        }
                        else {
                            return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
                        }
                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
                    }
                },
            },
        ]
        await this.setState({

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: commentHeaders,
                    overlayNoRowsTemplate: 'No rows to show',

                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    if (param.data.leg_details != "" && param.data.leg_details != undefined) {
                        try {
                            var output = JSON.parse(param.data.leg_details);
                            output.map((e) => e.type_of_tracking = param.data.type_of_tracking);
                            if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
                                var customer_code = JSON.parse(localStorage.getItem("customer_code"));
                                // console.log(customer_code,"customer_code--")
                                if (customer_code.length > 0) {
                                    var output1 = [];
                                    var otpt = await output.map(async (e) => {
                                        var customer_codes = e.consignee_code;
                                        customer_codes = parseInt(customer_codes).toString();
                                        // console.log(customer_codes,customer_code.indexOf(customer_codes),"testing")
                                        if (customer_code.indexOf(customer_codes) >= 0) {
                                            output1.push(e);
                                        }
                                    })
                                    var output = output1;
                                }
                            }
                            var selectedFilter = $("#selectedFilter").text();
                            if (selectedFilter == "Invoice") {
                                var inputTruckConsignment = $("#inputTruckConsignment").val();
                                if (inputTruckConsignment != undefined && inputTruckConsignment != "") {
                                    output = output.filter((e) => e.sales_order == inputTruckConsignment)
                                }
                            }
                            await param.successCallback(output);
                        }
                        catch (e) { }
                    }

                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if (rownode.column.colDef.field == 'loadingarealegs') {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else {

            rownode.node.setExpanded(false);
        }
    }
    getTruckConsignments = (event) => {

        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        event.preventDefault();
        var selectedFilter = $("#selectedFilter").text();
        var inputTruckConsignment = $("#inputTruckConsignment").val();
        if (selectedFilter == "Select..." || inputTruckConsignment == "") {
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "danger",
                show: true,
                loadshow: "show-n",
                overly: "show-n"
            })
        }
        else {
            // console.log(selectedFilter,inputTruckConsignment);
            var urlpath = "/consignments/filterAllByConsignment";
            var transporter_code = "";
            var transportercode = localStorage.getItem('transportercode');
            if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
                var transporter_code = transportercode;
            }
            var parameters = {
                "selectedFilter": selectedFilter,
                "inputTruckConsignment": inputTruckConsignment,
                "dept_code": this.state.deptcode, "transporter_code": transporter_code,
                "returnConsignments": this.state.tnpReturnConsignmentsVisibilty
            }
            if (localStorage.getItem("is_sales_type") == 1) {
                parameters.sales_type = localStorage.getItem("sales_type");
                parameters.region = localStorage.getItem("region");
            }
            if (this.state.tnpReturnConsignmentsVisibilty == true) {
                parameters.tnpReturnConsignmentsVisibilty = 1
            }
            if (localStorage.getItem("transportercode") != "undefined" && localStorage.getItem("transportercode") != undefined && localStorage.getItem("transportercode") != "") {
                parameters.transporter_codes = localStorage.getItem("transportercode")
            }
            if (localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined" && localStorage.getItem("customer_code") != "") {
                parameters.customer_code = localStorage.getItem("customer_code")
            }
            redirectURL.post(urlpath, parameters)
                .then((response) => {
                    var counters = response.data.counters
                    if (this.state.tnpReturnConsignmentsVisibilty) {
                        counters.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
                        counters.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
                        counters.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
                    }
                    counters.deviated_routes = [{ "deviated_routes": response.data.consignments.filter(e => e.deviation_flag == true).length }];
                    var gridData = response.data.consignments;
                    if (localStorage.getItem("user_type") == "CUSTOMER") {
                        if (gridData.length > 0) {
                            gridData = gridData.filter((t) => t.is_tracking_available == 1);

                        }
                    }
                    this.setState({
                        rowData: gridData.filter(e=>this.state.road_btn=="btn-danger"?e.shipment_mode!="RAIL" : e.shipment_mode=="RAIL"),
                        originalData:gridData,
                        allRowData: gridData,
                        countersjson: counters,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }).catch = ((e) => {
                    console.log(e);
                })
        }

    }
    onClickTripButton = async () => {

        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var sDate = this.state.defaultsdate;
        }
        else {
            var sDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var eDate = this.state.defaultedate;
        }
        else {
            var eDate = this.state.endDate;
        }
        // var sDate=this.state.startDate;
        // var eDate = this.state.endDate;
        // console.log("consigner",selectconsigners)
        var selectconsigners = []
        if (this.state.consigner == null) {
            selectconsigners = [{ "value": 'all', "label": 'All' }]
        }
        else {
            selectconsigners = [this.state.consigner]
        }
        // console.log("consignee",selectconsignees)
        var selectconsignees = []
        if (this.state.consignee == null) {
            selectconsignees = [{ "value": 'all', "label": 'All' }]
        }
        else {
            selectconsignees = this.state.consignee
        }

        var selecttrip = []
        if (this.state.triptype == null) {
            selecttrip = { "value": 'all', "label": 'All' }
        }
        else {
            selecttrip = this.state.triptype
        }

        var requestparams = {
            consigner: selectconsigners,
            consignee: selectconsignees,
            dept_code: this.state.deptcode,
            startDate: sDate,
            endDate: eDate,
            triptype: selecttrip,
            returnConsignments: this.state.tnpReturnConsignmentsVisibilty
        }
        if (localStorage.getItem("is_sales_type") == 1) {
            requestparams.sales_type = localStorage.getItem("sales_type");
            requestparams.region = localStorage.getItem("region");
        }
        var urlpath = '';
        if (this.props.match.path == "/tnpconsignments") {
            urlpath = '/consignments/tnpconsignments';
            var transportercode = localStorage.getItem('transportercode');
            if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
                var tptCode = JSON.parse(transportercode);
                requestparams.transporter_code = tptCode[0];
            }
        }
        var transportercode = localStorage.getItem('transportercode');
        if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
            requestparams.transporter_code = transportercode;
        }
        else if (this.props.match.path == "/tnpallconsignments") {
            urlpath = '/consignments/alltnpconsignments';
        }

        else if (this.props.match.path == "/tnpdeliveredconsignments") {
            urlpath = '/consignments/deliveredtnpconsignments';
        }
        console.log("requestparams", requestparams)

        this.onLoadGetConsignmentsList(urlpath, requestparams)
    }

    onClickForceClose = (params) => {
        //console.log("Pareas ", params)
        this.setState({
            forceclosedata: params.data,
            forceCloseRowNode: params,
            sliderForceCloseTranslate: "slider-translate-40p",
            overly: 'show-m',
            reasonforceclose: ""
        })
    }
    handleInput(val) {
        this.setState({ reasonforceclose: val[1], selectedReason: val[0], deliveryDate: val[2] });
        // console.log(val)
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    formForceCloseHandler(event) {
        event.preventDefault()
        var forcecloserow = this.state.forceCloseRowNode;
        console.log("forceCloseRowNode ", this.state.forceCloseRowNode);
        var consignemnt = this.state.forceCloseRowNode.data;
        var consignment_code = consignemnt.shipment_id;
        var consignee_code = consignemnt.consignee_code;

        var truck_no = consignemnt.truck_no;
        var dept_code = "SNDG";
        var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        var userid = localStorage.getItem('userid');
        var reasonforceclose = this.state.reasonforceclose;
        var others_Reason = this.state.selectedReason;
        let deliveryDate = this.state.deliveryDate
        //    if(this.state.deptcode == "SNDG")
        // 	{
        var reqdata = {
            dept_code: dept_code,
            shipment_id: [consignment_code],
            truck_no: truck_no,
            userid: userid,
            reasonforceclose: reasonforceclose,
            others_reason: others_Reason,
            deliveryDate: deliveryDate,
            consignee_code: consignee_code
        }
        // }
        // else
        // {
        //    var reqdata = {
        // 	   dept_code:dept_code,
        // 	   consignment_code:[consignment_code],
        // 	   truck_no:truck_no,
        // 	   userid:userid,
        // 	   screen : "TMS",
        // 	   others_Reason : others_Reason,
        // 	   reasonforceclose:reasonforceclose,
        //    }
        // }
        var flag = 0;
        if (others_Reason == "" && reasonforceclose == "Others") {
            flag = 1;
        }
        if (flag == 0 && reasonforceclose == "Others") {
            //    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
            if (others_Reason.replace(/\s/g, "").length == 0) {
                flag = 1;
            }
        }
        console.log("reqdata ", reqdata)
        if (flag == 0) {
            redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
                .then((response) => {
                    console.log("Resposne updateConsignmentForceClose ", response)
                    if (response.data.status == 'Success') {
                        // document.getElementById("reached_dealer_time").value='';
                        // document.getElementById("left_dealer_time").value='';
                        this.setState({
                            show: true,
                            basicTitle: "Success",
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            left_dealer_time: '',
                            reached_dealer_time: '',
                            reachedhh: '',
                            reachedmm: '',
                            reachedss: '',
                            lefthh: '',
                            leftmm: '',
                            leftss: '',
                            reasonforceclose: "",
                            openforceclose: false,
                            sliderForceCloseTranslate: ''
                        });
                    }
                    else {
                        this.setState({
                            show: true,
                            basicTitle: "Error",
                            basicType: "danger",
                            loadshow: 'show-n',
                            left_dealer_time: '',
                            reached_dealer_time: '',
                            reachedhh: '',
                            reachedmm: '',
                            reachedss: '',
                            lefthh: '',
                            leftmm: '',
                            leftss: '',
                            reasonforceclose: '',
                            openforceclose: false,
                            sliderForceCloseTranslate: '',
                            overly: 'show-n'
                        });
                    }

                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Reason for closue should not be empty',
                basicType: "danger",
            });
        }
        event.target.reset();
    }

    newFilter = (filterType, selOption) => {
        // console.log(filterType,selOption)
        this.setState({ [filterType]: selOption });
    }

    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": "alltrips",
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        try {
            window.colState = this.gridColumnApi.getColumnState();
            window.groupState = this.gridColumnApi.getColumnGroupState();
            window.sortState = this.gridApi.getSortModel();
            window.filterState = this.gridApi.getFilterModel();

            let screenpage = 'alltrips';


            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: "alltrips"
            }
            //console.log("reqparams ", reqparams)
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("State response ",response.data)
                    this.setState({
                        show: true,
                        basicTitle: "Successfully saved grid layout",
                        basicType: "success",
                        screenurl: window.location.pathname,
                        screentitle: screenpage
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        catch (e) { }
    }
    restoreGridStates() {
        if (this.state.usergridstate.length !== 0) {
            try {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
            catch (e) {

            }
        }
    }

    resetState = () => {

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        try {

            this.gridColumnApi.resetColumnState();
            this.gridColumnApi.resetColumnGroupState();
            this.gridApi.setSortModel(null);
            this.gridApi.setFilterModel(null);
            //console.log('column state reset');
            var reqparams = {
                userId: localStorage.getItem("userid"),
                screenurl: window.location.pathname,
                screentitle: this.state.screenpage
            }

            redirectURL.post("/consignments/removeUserGridState", reqparams)
                .then((response) => {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully reset default grid layout",
                        basicType: "success"
                    })
                })
        }
        catch (e) { }

    };

    onClickTab = (tab) => {
        var data = this.state.originalData
        if(tab == 1){
            this.setState({
                rowData:data.filter(e=>e.shipment_mode!="RAIL"),
                road_btn: 'btn-danger',
                rail_btn: 'btn-default',
            })
        }else{
            this.setState({
                rowData:data.filter(e=>e.shipment_mode=="RAIL"),
                road_btn: 'btn-default',
                rail_btn: 'btn-danger',
            })
        }

    }

    reloadPageData = () => {
        window.location.reload();
    }
    render() {
        console.log(this.state.rowData, "rowdata")
        const modalStyles = {
            width: '1300px !important',
        }
        const { open, openforceclose, usermanualncrmodal, usermanualmovemodal } = this.state;

        if (localStorage.getItem('user_type') == "MARKETING") {
            var hideforsales = true;
        }
        else {
            var hideforsales = false;
        }
        var hideData = "";
        if (localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0) {
            var hideData = false;
        }
        else {
            var hideData = true;
        }
        var hideForCustomer = "";
        var hideForNotCustomer = "";
        if (localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
            var hideForCustomer = true;
            var hideData = true;
            var hideforsales = true;
            var hideForNotCustomer = false;
        }
        else {
            var hideForCustomer = false;
            var hideForNotCustomer = true;
        }
        var hideForbgs = "";
        if (localStorage.getItem("pc") != "HZ" || localStorage.getItem('roles').indexOf("CUSTOMER") >= 0) {
            hideForbgs = true;
        }
        const columnwithDefs =
            [
                {
                    headerName: "",
                    field: "_id",
                    colId: "_id",
                    width: 50,
                    pinned: 'left',
                    cellRenderer: 'consignmentActions',
                    filter: true, resizable: true

                },

                // {
                //     headerName: "",
                //     field: "transit_delay",
                //     colId: "transit_delay",
                //     width: 40,
                //     pinned: 'left',
                //     pivot: false,
                //     hide: hideForCustomer,
                //     suppressColumnsToolPanel: hideForCustomer,
                //     cellRendererSelector: function (params) {
                //         if (params.data.transit_delay == 1) {
                //             var rendComponent = {
                //                 component: 'kpiTransitDelayActions'
                //             };
                //             return rendComponent
                //         }
                //         else {
                //             var rendComponent = {
                //                 component: 'notransit'
                //             };
                //             return rendComponent
                //         }
                //     },
                //     filter: true, resizable: true
                // },
                // {
                //     headerName: "",
                //     field: "is_gps_working",
                //     colId: "is_gps_working",
                //     width: 40,
                //     pinned: 'left',
                //     pivot: false,
                //     hide: hideForCustomer,
                //     suppressColumnsToolPanel: hideForCustomer,
                //     cellRendererSelector: function (params) {
                //         var comp = 'gps';
                       
                //         if (params.data.is_gps_working == "WORKING") {
                //             comp = 'gps'
                //         }
                //         else if (params.data.is_gps_working == "NOT_WORKING") {
                //             comp = 'nogps'
                //         }
                //         var rendComponent = {
                //             component: comp
                //         };
                //         return rendComponent
                //     },
                //     filter: true, resizable: true
                // },
                // {
                //     headerName: "",
                //     field: "overspeeding_exception",
                //     colId: "overspeeding_exception",
                //     width: 40,
                //     pinned: 'left',
                //     pivot: false,
                //     hide: hideForCustomer,
                //     suppressColumnsToolPanel: hideForCustomer,
                //     cellRendererSelector: function (params) {
                //         if (params.data.overspeeding_exception == 1) {
                //             var rendComponent = {
                //                 component: 'kpiActions'
                //             };
                //             return rendComponent
                //         }
                //         else {
                //             var rendComponent = {
                //                 component: 'nooverspeed'
                //             };
                //             return rendComponent
                //         }
                //     },
                //     filter: true, resizable: true
                // },
                // {
                //     headerName: "",
                //     field: "nightdriving_exception",
                //     colId: "nightdriving_exception",
                //     width: 40,
                //     pinned: 'left',
                //     pivot: true,
                //     hide: hideForCustomer,
                //     suppressColumnsToolPanel: hideForCustomer,
                //     cellRendererSelector: function (params) {
                //         //console.log("Except ",params.data.nightdriving_exception)
                //         //console.log("Consignemnt code ", params.data.consignment_code)
                //         if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
                //             var rendComponent = {
                //                 component: 'kpiNightDriveActions'
                //             };
                //             return rendComponent
                //         }
                //         else {
                //             var rendComponent = {
                //                 component: 'nonightdrive'
                //                 //component: 'kpiNightDriveActions'
                //             };
                //             return rendComponent
                //         }
                //     },
                //     filter: true, resizable: true
                // },

                // {
                //     headerName: "",
                //     field: "enroute_exception",
                //     colId: "enroute_exception",
                //     width: 40,
                //     pinned: 'left',
                //     pivot: false,
                //     hide: hideForCustomer,
                //     suppressColumnsToolPanel: hideForCustomer,
                //     cellRendererSelector: function (params) {

                //         var rendComponent = {
                //             component: 'kpiEnrouteStoppagesActions'
                //         };
                //         return rendComponent

                //     },
                //     filter: true, resizable: true,
                // },

                // {
                //     headerName: "",
                //     field: "shipmentlegs",
                //     width: 50,
                //     pinned: 'left',
                //     cellRenderer: 'ShipmentLegs',
                //     hide: hideForbgs,
                //     suppressColumnsToolPanel: hideForbgs,
                //     filter: true, resizable: true

                // },
                // {
                //     headerName: "",
                //     field: "loadingarealegs",
                //     width: 50,
                //     pinned: 'left',
                //     cellRenderer: 'LoadingAreaLegs',
                //     filter: true, resizable: true

                // },
                {
                    headerName: Constant.COL_TRUCK_NO,
                    field: "truck_no",
                    colId: "truck_no",
                    width: 120,
                    pinned: "left", filter: true,
                    resizable: true,
                    valueGetter: function (params) {
                        if (params.data.truck_no != undefined && params.data.truck_no != "") {
                            return params.data.truck_no;
                        }
                        else {
                            return "No Data From API";
                        }
                    }
                },
                {
                    headerName: "Truck Type",
                    field: "truck_type",
                    colId: "truck_no",
                    width: 120,
                    pinned: "left",
                    filter: true,
                    resizable: true
                },

                {
                    headerName: "Status",
                    field: "status",
                    width: 120,
                    pinned: 'left',
                    filter: "agSetColumnFilter",
                    resizable: true,
                    // hide:hideData,
                    suppressColumnsToolPanel: hideData,
                    valueGetter: function (params) {
                        //console.log("GPS ", params.data.gps_data_available);
                        if (localStorage.getItem("pc") == "HZ") {
                            if (params.data.status == 1) {
                                return Constant.INSIDE_TPA;
                            }
                            else if (params.data.status == 2) {
                                return Constant.INSIDE_MY_PMILL;
                            }

                            if (params.data.status == 1 || params.data.status == "1") {
                                return Constant.INSIDE_TPA;
                            }
                            if (params.data.status == 2 || params.data.status == "2") {
                                return Constant.INTRANSIT_TO_MY;
                            }
                            if (params.data.status == 3 || params.data.status == "3" || params.data.status == 4 || params.data.status == "4" || params.data.status == 5 || params.data.status == "5") {
                                return Constant.INSIDE_MY_PMILL;
                            }
                            else if (params.data.status == 6) {
                                return Constant.FORCE_CLOSE;
                            }
                            else if (params.data.status == 7) {
                                return Constant.INSIDE_TPA_AFTER_LOADING;
                            }
                            else if (params.data.status == 8) {
                                return Constant.INTRANSIT;
                            }
                            else if (params.data.status == 9) {
                                return Constant.NEAR_CUSTOMER;
                            }
                            else if (params.data.status == 10) {
                                return Constant.REACHED_CUSTOMER;
                            }
                            else if (params.data.status == 11) {
                                return Constant.LEFT_CUSTOMER;
                            }
                            else {
                                return ""
                            }

                        }
                        else {
                            if (params.data.status == 1 || params.data.status == 2 || params.data.status == 3 || params.data.status == 4 ||
                                params.data.status == 5) {
                                return "At Plant"
                            }
                            else if (params.data.status == 6) {
                                return Constant.FORCE_CLOSE;
                            }

                            else if (params.data.status == 7 || params.data.status == 8) {
                                return Constant.INTRANSIT;
                            }
                            else if (params.data.status == 9) {
                                return Constant.NEAR_CUSTOMER;
                            }
                            else if (params.data.status == 10) {
                                return Constant.REACHED_CUSTOMER;
                            }
                            else if (params.data.status == 11) {
                                return Constant.LEFT_CUSTOMER;
                            }
                        }


                    }
                },

                {
                    headerName: Constant.COL_TRACKING_MODE,
                    field: "type_of_tracking",
                    width: 120,
                    pinned: "left",
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    valueGetter: function (params) {
                        if (params.data.type_of_tracking != undefined && params.data.type_of_tracking != "") {
                            if (params.data.type_of_tracking == 0) {
                                return "Waiting For GPS";
                            }
                            else if (params.data.type_of_tracking == 1) {
                                return "GPS Tracking";
                            }
                            else if (params.data.type_of_tracking == 2) {
                                return "SIM Tracking";
                            }
                            else if (params.data.type_of_tracking == 3) {
                                return "GPS & SIM Tracking";
                            }
                            else if (params.data.type_of_tracking == 4) {
                                return "Sim Tracking Consent Pending";
                            }
                        }
                        else {
                            return "Waiting For GPS";
                        }
                    }
                },
                {
                    headerName: Constant.COL_TIME_SPEND_AFTER_SHIPMENT_CREATION,
                    field: "shipment_time",
                    colId: "shipment_time",
                    id: "shipment_time",
                    width: 180,
                    //pinned: 'left',              
                    resizable: true,
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                    valueGetter: function (params) {
                        try {
                            // console.log( params.data.shipment_time)
                            var ste = params.data.shipment_time;
                            var stsec = moment.parseZone(ste).format("x");
                            if (params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "" && params.data.timestamp != null) {
                                if (params.data.tpa_exit_time != "" && params.data.tpa_exit_time != undefined) {
                                    if (params.data.shipment_time < params.data.tpa_exit_time) {
                                        var etsec = moment.parseZone(params.data.tpa_exit_time).format("x")
                                        var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
                                        // console.log(diff,"line 2047")
                                        var timespent = secondsToDhms(diff);
                                        return timespent;
                                    }
                                    else {
                                        return "0 Days 0 Hrs 0 Mins";
                                    }

                                }
                                else {
                                    return "Not Available";
                                }
                            }
                            else {
                                if (params.data.gate_in_time != "No Data Available" && params.data.gate_in_time != undefined && params.data.gate_in_time != "" && params.data.gate_in_time != null) {
                                    if (params.data.shipment_time < params.data.gate_in_time) {
                                        var etsec = moment.parseZone(params.data.gate_in_time).format("x")
                                        var diff = (parseInt(etsec) - parseInt(stsec)) / 1000;
                                        // console.log(diff,"line 2047")
                                        var timespent = secondsToDhms(diff);
                                        return timespent;
                                    }
                                    else {
                                        return "0 Days 0 Hrs 0 Mins";
                                    }
                                }
                                else {
                                    return "Not Available";
                                }
                            }
                        }
                        catch (e) {

                        }
                    },
                },
                {
                    headerName: Constant.COL_TIME_SPEND_AFTER_SHIPMENT_CREATION + " Marked From",
                    field: "gate_in_time",
                    colId: "gate_in_time",
                    id: "gate_in_time",
                    width: 230,
                    //pinned: 'left',              
                    resizable: true,
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                    valueGetter: function (params) {
                        try {
                            if (params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "" && params.data.timestamp != null) {
                                return "Geo Fence";
                            }
                            else {
                                if (params.data.gate_in_time != "No Data Available" && params.data.gate_in_time != undefined && params.data.gate_in_time != "" && params.data.gate_in_time != null) {
                                    return "API";
                                }
                                else {
                                    return "Not Available";
                                }
                            }
                        }
                        catch (e) {

                        }
                    },
                },
               
                {
                    headerName: "Transporter Code",
                    field: "transporter_code",
                    colId: "transporter_code",
                    width: 150, filter: true,
                    cellRenderer: '',
                    resizable: true,
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                },
                {
                    headerName: Constant.COL_TPT_NAME,
                    field: "transporter_name",
                    colId: "transporter_name",
                    width: 150, filter: true,
                    cellRenderer: '',
                    resizable: true
                },
                {
                    headerName: Constant.COL_TPT_ZONE,
                    field: "transporter_zone",
                    width: 150,
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                },
                {
                    headerName: Constant.COL_DRIVER_NAME,
                    field: "driver_name",
                    colId: "driver_name",
                    width: 150, filter: true,
                    cellRenderer: '',
                    resizable: true
                },
                {
                    headerName: Constant.COL_DRIVER_CODE,
                    field: "driver_code",
                    colId: "driver_code",
                    width: 150, filter: true,
                    cellRenderer: '',
                    resizable: true,
                    hide: hideData,
                    suppressColumnsToolPanel: hideForbgs,
                },
                {
                    headerName: Constant.COL_DRIVER_NUMBER,
                    field: "driver_mobile_no",
                    colId: "driver_mobile_no",
                    width: 150, filter: true,
                    cellRenderer: '',
                    resizable: true,
                    hide: hideforsales,
                    suppressColumnsToolPanel: hideforsales,
                },
                {
                    headerName: Constant.COL_PARKING_ENTRY_NO,
                    field: "parking_id",
                    width: 120, filter: true,
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                    resizable: true
                },

                {
                    headerName: Constant.COL_PARKING_DATE,
                    field: "parking_in_time",
                    colId: "parking_in_time",
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                    width: 150,
                    cellRenderer: '', resizable: true,
                    comparator: dateComparator,

                    valueGetter: function (params) {
                        if (params.data.parking_in_time != '' && params.data.parking_in_time != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                        }
                        else {
                            return "NA";
                        }
                    },
                },
                {
                    headerName: Constant.COL_SHIPMENT_DOC_NO,
                    field: "shipment_id",
                    width: 120, filter: true,
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    resizable: true
                },

                {
                    headerName: Constant.COL_SHIPMENT_DATE,
                    field: "shipment_time",
                    colId: "shipment_time",
                    width: 150,
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    cellRenderer: '', resizable: true,
                    comparator: dateComparator,

                    valueGetter: function (params) {
                        if (params.data.shipment_time == "Yet to Invoice") {
                            return "Yet to Invoice"
                        }
                        else if (params.data.shipment_time != undefined && params.data.shipment_time != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else {
                            return "Not Available";
                        }
                    },
                },

                {
                    headerName: Constant.COL_INVOICE_DATE,
                    field: "invoice_time",
                    width: 140,
                    valueGetter: function (params) {
                        try {

                            if (params.data.invoice_time == "Yet to Invoice") {
                                return "Yet to Invoice"
                            }
                            else if (params.data.invoice_time != undefined && params.data.invoice_time != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                            }
                            else {
                                return "Not Available";
                            }


                        }
                        catch (e) { }
                    },
                    comparator: dateComparator,
                },

                {
                    headerName: Constant.COL_LAST_CITY,
                    field: "area",
                    colId: "area",
                    width: 150,
                    filter: true,
                    cellRenderer: '', resizable: true
                },
                {
                    headerName: Constant.COL_LAST_STATE,
                    field: "state",
                    colId: "state",
                    width: 120,
                    filter: true,
                    cellRenderer: '', resizable: true,
                },

                {
                    headerName: Constant.COL_LAST_PACKET_TIME,
                    field: "timestamp",
                    colId: "timestamp",
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    valueGetter: function (params) {
                        try {
                            if (params.data.timestamp != "No Data Available" && params.data.timestamp != undefined && params.data.timestamp != "") {
                                return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                            }
                            else {
                                return "Not Available";
                            }
                        }
                        catch (e) {

                        }
                    },
                    comparator: dateComparator,

                    width: 150,
                    cellRenderer: '', resizable: true
                },
                {
                    headerName: Constant.COL_CONSIGNER_CODE,
                    field: "consigner_code",
                    colId: "consigner_code",
                    width: 150,
                    filter: true,
                    hide: hideData,
                    cellRenderer: '', resizable: true
                },
                {
                    headerName: Constant.COL_CUSTOMER_CITY,
                    field: "transporter_zone",
                    colId: "transporter_zone",
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    width: 150, filter: true,
                    cellRenderer: '', resizable: true
                },
                {
                    headerName: Constant.COL_FORCE_CLOSE,
                    field: "",
                    colId: "consignment_codes",
                    width: 80,
                    cellRendererSelector: function (params) {

                        if (params.data.status != 6 || params.data.status != "6") {
                            var rendComponent = {
                                component: 'consignmentforceclose'
                            };
                            return rendComponent;
                        }
                        else {
                            return ""
                        }

                    },
                    hide: hideForCustomer || !localStorage.getItem('email').includes('enmovil'),
                    suppressColumnsToolPanel: hideForCustomer,
                    filter: true, resizable: true,


                },

                // {
                // 	headerName: "Force Closed By",
                // 	field: "force_closure_performed_by",
                // 	width: 150,
                // 	hide:showOnlyColDeliver,
                // 	filter: true,resizable: true,


                // },

                {
                    headerName: Constant.COL_FORCE_CLOSE_REASON,
                    field: "force_closure_reason",
                    colId: "force_closure_reason",
                    width: 200,
                    hide: hideForCustomer,
                    suppressColumnsToolPanel: hideForCustomer,
                    cellClass: ['wraptext'],
                    filter: true, resizable: true,
                    valueGetter: function (params) {
                        try {
                            if (params.data.force_closure_reason == "Others") {
                                if (params.data.force_closure_other_reason != undefined && params.data.force_closure_other_reason != "") {
                                    return params.data.force_closure_other_reason;
                                }
                                else if (params.data.force_closure_reason != undefined && params.data.force_closure_reason != "") {
                                    return params.data.force_closure_reason;
                                } else {
                                    return "Auto Closure"
                                }

                            }
                            else {
                                if (params.data.force_closure_reason != undefined && params.data.force_closure_reason != "") {
                                    return params.data.force_closure_reason;
                                } else {
                                    return "Auto Closure"
                                }
                                // return params.data.force_closure_reason;
                            }
                        }
                        catch (e) { }
                    }
                },
                {
                    headerName: "Truck Type",
                    field: "truck_type",
                    hide: hideForbgs,
                    suppressColumnsToolPanel: hideForbgs,
                    width: 140
                },

                {
                    headerName: Constant.COL_SHIP_TO,
                    field: "ship_to",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SHIPP,
                    field: "shipp",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO,
                    field: "sold_to",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLDP,
                    field: "soldp",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO_CITY,
                    field: "ship_to_city",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO_STATE,
                    field: "ship_to_state",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO_PINCODE,
                    field: "ship_to_pincode",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO_ADDRESS,
                    field: "ship_to_address",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 200
                },

                {
                    headerName: Constant.COL_SOLD_TO_CONTACT_NAME,
                    field: "ship_to_contact_name",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: Constant.COL_SOLD_TO_CONTACT_NO,
                    field: "ship_to_contact_no",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 140
                },

                {
                    headerName: "Is Customer Coords Available",
                    field: "is_geo_coords_available",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 200,
                    valueGetter: function (params) {
                        try {
                            if (params.data.is_geo_coords_available == 1) {
                                return "Yes";
                            }
                            else {
                                return "No";
                            }
                        }
                        catch (e) {

                        }
                    }
                },
                {
                    headerName: "Is Customer Geofence Available",
                    field: "is_geo_fence_available",
                    hide: hideData,
                    suppressColumnsToolPanel: hideData,
                    width: 200,
                    valueGetter: function (params) {
                        try {
                            if (params.data.is_geo_fence_available == 1) {
                                return "Yes";
                            }
                            else {
                                return "No";
                            }
                        }
                        catch (e) {

                        }
                    }
                },
                {
					headerName: "Trip Status",
					field: "trip_status",
					width: 130,
					editable: false,
					filter: true,
				},	
                // {
                //     headerName: "",
                //     field: "",
                //     width: 150,

                //     filter: true, resizable: true,
                //     cellRendererSelector: function (params) {
                //         var rendComponent = {
                //             component: 'returnToPlantComponent'
                //         };
                //         return rendComponent;
                //     },

                // },
            ];

        const dStyles = {
            width: this.props.width,
            height: '500px',
            marginLeft: this.props.marginLeft,

        }

        const commentColumns = [
            {
                headerName: Constant.COL_TRUCK_NO,
                field: "truck_no",
                resizable: true,
                width: 100,
                filter: true
            },
            {
                headerName: Constant.COL_CONSIGNMENT_CODE,
                field: "consignment_codes",
                resizable: true,
                width: 120,
                filter: true
            },
            {
                headerName: Constant.COL_USER_TYPE,
                field: "user_type",
                width: 100,
                resizable: true,
                filter: true,
                valueGetter: function (params) {
                    if (params.data.user_type == "ORGUSER") {
                        return Constant.USER_TYPE_MSIL;
                    }
                    if (params.data.user_type == "DCTUSER") {
                        return Constant.USER_TYPE_DCT;
                    }
                    if (params.data.user_type == "TRANSPORTER") {
                        return Constant.USER_TYPE_TPT;
                    }
                }
            },
            {
                headerName: Constant.COL_COMMENT,
                field: "comment",
                width: 250,
                cellClass: ['wraptext'],
                resizable: true,
                filter: true
            },
            {
                headerName: Constant.COL_COMMENT_BY,
                field: "username",
                width: 150,
                resizable: true,
                filter: true
            },

            {
                headerName: Constant.COL_COMMENT_DATE,
                field: "comment_date",
                resizable: true,
                filter: true,
                width: 150,
                valueGetter: function (params) {
                    if (params.data.comment_date != "") {
                        return getDDMMYYYYHHMMSS(params.data.comment_date);
                    }
                }
            }

        ]

        return(
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>
				
				<div className="row" style={{marginBottom:"1%"}}>
					<div className="col-xl-12 col-lg-12">
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className="card" >
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
									<div className={"col-sm-12"}>
										<label>Select Type :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={this.state.filterOptions}
											onChange={e => { this.setState({ selectedFilter: e.label }) }}
											required />
									</div>
									<div className={'col-sm-12 mt-20p'}>
										<label className="reason">{this.state.filterNames}</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12 mt-20p" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className="card" style={{minHeight: "253px"}}>
							<div className="row card-body" style={{padding: '10px'}}>
						 
							<div className="row col-xl-12 col-lg-12">
							{(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined||localStorage.getItem("roles")=="CUSTOMER")?"":
								<div className="form-group col-xl-4 col-lg-4">
									<label>Consigner </label>
									<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
								</div>
							}
								<div className="col-xl-3 col-lg-3 form-group">
									<label>Shipment From Date</label>
									<Datetime 
									value={this.state.startDate} 
									disableCloseOnClickOutside={false} 
									closeOnSelect={true} 
									inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off', readOnly: 'true'}} 
									dateFormat="YYYY-MM-DD"  
									name="startDate" 
									onChange={this.handlerStartDateTime.bind(this)} />
								</div>

								<div className="col-xl-3 col-lg-3 form-group">
									<label>Shipment To Date</label>
									<Datetime 
									value={this.state.endDate} 
									disableCloseOnClickOutside={false} 
									closeOnSelect={true} 
									inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off', readOnly: 'true'}} 
									dateFormat="YYYY-MM-DD" 
									onChange={this.handlerEndDateTime.bind(this)} />
								</div>
							</div>
							
								<div className="row col-xl-12 col-lg-12" style={{marginLeft:"0px", padding:"0px"}}>
								
								{/* {(localStorage.getItem("roles")!="CUSTOMER") ?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Customers :  </label>
										<Select
											className="border-radius-0"
											closeMenuOnSelect={true}
											isMulti={true}
											id="selectedCustomer"
											style={{ borderRadius: "0px" }}
											value={this.state.flCustomer}
											onChange={this.newFilter.bind(this, "flCustomer")}
											options={this.state.filterCustomers}
										/>
									</div>
								: ""} */}
								{(localStorage.getItem("roles")!="CUSTOMER") ?
									<div className="form-group col-xl-3 col-lg-3">
										<label>Destination City  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedCity"
										style={{borderRadius:"0px"}}
										value={this.state.flCity}
										onChange={this.newFilter.bind(this,"flCity")}
										options={this.state.filterCities}
										/>
									</div>:""}
									{(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")=="CUSTOMER") ? "":
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedTransporter"
										style={{borderRadius:"0px"}} 
										value={this.state.flTransporter}
										onChange={this.newFilter.bind(this,"flTransporter")}
										options={this.state.filterTransporters}
										/>
									</div>
									}
									{(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" || localStorage.getItem("roles")== "CUSTOMER") ? "":
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transportation Zone:  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporterZone"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporterZone}
											onChange={this.newFilter.bind(this, "flTransporterZone")}
											options={this.state.filterTransportersZones}
										/>
									</div>
									}
								</div>
							
								<div className="form-group col-xl-12 col-lg-12" style={{marginLeft:"0px"}}>
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>
							</div>
					</div>
				</div>
			</div>
			<div className={'col-xl-12 col-lg-12'} >
                        <button className={'btn '+this.state.road_btn} style={{ marginLeft: "-15px" }} 
                        onClick={this.onClickTab.bind(this , 1)}
                        >Road</button>
                        <button className={'btn '+this.state.rail_btn} 
                        onClick={this.onClickTab.bind(this , 2)}
                        >Rail</button>
                    </div>
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									
									<span className="">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</h5>
				   			</div>				   			
		            		<div className="card-body pt-10px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
									{(localStorage.getItem('roles')!="CUSTOMER")?
										<span className="float-right" style={{fontSize:"13px",fontWeight:"100"}}>
											<i className="icofont icofont-clock-time" style={{color:"#ff0000", fontSize:"22px	"}}></i> - Transit Delay
											&nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize:"22px"}}></i> - GPS Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize:"22px"}}></i> - GPS Data Not Adequate
											&nbsp;
												<span>
													<i className="fa fa-tachometer txt-success" style={{fontSize:"22px"}}></i> - Overspeed
												</span>
											<span>
												<span>&nbsp;<i className="icofont icofont-full-night txt-primary" style={{fontSize:"22px"}}></i> - Night Driving
												</span>
											
											</span>
											&nbsp;<i className="icofont icofont-pause maroon" style={{fontSize:"22px	"}}></i> - Enroute Stoppages
										</span>:""}
										
									</div>
									
								</div>
						
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}      
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles= {this.state.excelStyles}
		          		             />
									
		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"truck"} 
								tolls={this.state.tolls} 
								consigneecords={this.state.consignee_coordinates}
								googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={this.state.dealer}
								mapinfo={this.state.mapinfo} 
								deptcode={this.state.deptcode}
								defTransitCoords={this.state.defTransitCoords} 
								baseRouteCoords={this.state.baseRouteCoords}
								geofence_coordinates={this.state.geofence_coordinates}
                                map_type_of_tracking = {this.state.map_type_of_tracking}
                                />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
										
											 <div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											{(localStorage.getItem('roles')!="CUSTOMER")?
											<div className="col route-block">
												<label className="sidebar-label">Tracking Type</label>
                                                {(this.state.map_type_of_tracking == 1)?
												<div>GPS Tracking</div>
                                                :
												<div>SIM Tracking</div>
                                                }
											</div>
											:""}
											
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				
				{this.state.sliderCommentTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderCommentTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>	
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={commentColumns}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady1}
		          		            onGridState={this.onGridState1}
		          		            statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
							
		          		          />
									
									

		          		    </div>
						</div>
						
					</div>
				:""	
				}
				 
                
               
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
     
				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							
							<ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode} />
							
						</form>
                        </div>
					</div>
				</div>
            </div>
              	
		);
    }
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}



function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    //console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
