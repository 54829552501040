
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const UpdateLRForm = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };
    console.log("props.data.customer_lr_doc ", props.data)
    return (
        <div>
            {(props.data.customer_lr_doc != "")?
                <span>
                   
                   {(props.data.customer_lr_doc != "" && props.data.customer_lr_doc != undefined)?
                        <span>
                            <a href={props.data.customer_lr_doc_path} target="_blank" style={{color:"green",fontSize:"11px"}}>View Document</a>
                        &nbsp;|&nbsp;</span>
                    :""}
                    <button onClick={handleOverspeedClick} className={"custom-btn f12 btn btn-success "} title="Upload Document" >Submit POD</button>
                </span>
            :
                <button onClick={handleOverspeedClick} className={"custom-btn f12 btn btn-success "} title="Upload Document" >Submit POD</button>
            }
        </div>
    );
};

export default UpdateLRForm;
