
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const AtDestination = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowAtDestinationLegs(props);
    };
    

    let revisedDateOnlyMoment = 0
    let expectedDateOnlyMoment  = 0
    if (props.data.rake_delivery_point_reached_on != undefined && props.data.eta_rail_middle_mile != undefined) {
        revisedDateOnlyMoment  = moment.parseZone(props.data.rake_delivery_point_reached_on).format("x");
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_rail_middle_mile).format("x");
       
       // console.log("diff= ", props.data.rake_delivery_point_reached_on);
        //console.log("eta_rail_middle_mile= ", props.data.eta_rail_middle_mile);
    } else {
        var today = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        
        revisedDateOnlyMoment  = moment.parseZone(today).format("x");
        //console.log("today ", revisedDateOnlyMoment)
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_rail_middle_mile).format("x");
        //console.log("expectedDateOnlyMoment ", expectedDateOnlyMoment)
    }
   //console.log("today ", revisedDateOnlyMoment)
   //console.log("expectedDateOnlyMoment ", expectedDateOnlyMoment)
    
    if(props.data.status > 5)
    {
        if(revisedDateOnlyMoment > expectedDateOnlyMoment)
        {
            var colr = 'redfont'
        }

        else if(revisedDateOnlyMoment < expectedDateOnlyMoment)
        {
            var colr = 'greenfont'
        }
        else{
            var colr = 'greenfont'
        }
    }
    else if(props.data.status < 5)
    {
        var colr = 'grayfont'
    }
    else{
        if(revisedDateOnlyMoment > expectedDateOnlyMoment)
        {
            var colr = 'redfont'
        }

        else if(revisedDateOnlyMoment < expectedDateOnlyMoment)
        {
            var colr = 'greenfont'
        }
        else{
            var colr = 'yellowfont'
        }
    }
    //console.log("colr ", colr)
    return (
        <div>
            {(props.data.status == 5)?
			<button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="At Destination Yard"><i className={"icofont icofont-ui-home "+(colr)}></i> &nbsp;</button>
            :
            <button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="At Destination Yard" style={{color:"#ccc"}}><i className={"icofont icofont-ui-home "+(colr)}></i> &nbsp;</button>
            
            }
        </div>
    );
};

export default AtDestination;
