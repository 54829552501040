import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import UpdateItem from './updateActionComponent';
import StatusChange from './statusUpdateActionComponent';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";

import SweetAlert from 'react-bootstrap-sweetalert';
import DateFormater from '../layouts/dateComponent';
var redirectURL = require('../redirectURL');
var selectRowItemFromAgGrid='';
var url=redirectURL;
var googleAnalytics = require("../common/googleanalytics");
export default class ChokePointsGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
			pageTitle: "Tolls",
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
                editable: true,
                resizable: true
      		},
			rowData: null,

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "multiple" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2
		};
        
	}
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
    
    // onUpdateTollRates(){
    //     var rows = this.gridApi.getSelectedNodes();
    //    // console.log("rows ", rows.length)
    //     var selected = []
    //    try
    //     {
    //         rows.forEach((item) => {
    //           //  console.log("toll item ", item)
    //             selected.push({
    //                 toll_id:item.data._id,
    //                 rate_to_be_considered:item.data.rate_to_be_considered,
    //             })
    //         })
    //     }
    //     catch(e){

    //     }
        
    //     var tollrowid = this.props.tollrowid
    //     var parameters = {
    //         tolldata:selected
    //     }
    //     redirectURL.post("consignments/updatetollrates",parameters)
    //     .then((response) => {

    //     })
    // }
    

    render(){
    	var columnwithDefs  = [
            {
                headerName:"Latitude",
                field:"lat",
                width:140,
                editable:false
            },
            {
                headerName:"Longitude",
                field:"lng",
                width:140,
                editable:false
            },
            {
                headerName:"Area",
                field:"area",
                width:140,
                editable:false
            },
            {
                headerName:"State",
                field:"state",
                width:140,
                editable:false
            },
            {
                headerName:"No Of Breaks",
                field:"no_of_breaks",
                editable:false,
                width:100
            },{
                headerName:"Avg. Break Time",
                field:"avg_break_time_text",
                width:180,
                editable:false
            },
            {
                headerName:"Toll Name",
                field:"toll_name",
                editable:false,
                width:100
            },
            {
                headerName:"Distance From Toll (Kms)",
                field:"distance_from_toll",
                editable:false,
                width:100
            },
            
            {
                headerName:"State Border Name",
                field:"state_border_name",
                width:160,
                editable:false
            },
            {
                headerName:"Distance From State Border (Kms)",
                field:"distance_from_state_border",
                width:160,
                editable:false
            },
            
            // {
            //     headerName: "is qualified",
            //     field: "qualified_to_display"
            
            // }
        ]
        //console.log("this.props.rowData ",this.props.rowData)
        return ( 
        		<div className="container-fluid">
					<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div id="myGrid" style={{ height: "550px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.props.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    // frameworkComponents={this.state.frameworkComponents}
                                    // stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
      		    </div>
                    
			);
		}
}

