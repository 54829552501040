import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Submenus from "./submenus";
import Empty from "./empty";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
 
export default class AdminRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Add a Role",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            errorDuplicateRole:false,
            errorSelectionDepartment:false,
            selectedDepartment:null,
            departmentList:[],
            errorRoleUpdatedFailed: false,
            errorRoleNotEditable: false,
            errorEmptyValue: false,
            messageRoleUpdateSuccessful: false,
            userType:'',
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                submenus:Submenus,
                empty:Empty
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },



        };
    }
    getUserType = () => {
        let isAdminUser = localStorage.getItem('is_admin');
        let isDeptAdminUser = localStorage.getItem('is_dept_admin');
        let isGlobalUser = localStorage.getItem('is_global');
        //console.log(" isAdminUser and isGlobalUser = ", isAdminUser, isGlobalUser);
        if (isAdminUser == "true") {
            //console.log(" IN getUserType, ADMIN");
            this.setState({
                "userType": "admin"
            });

        }
        if (isDeptAdminUser == "true") {
            //console.log(" IN getUserType, is_dept_admin");
            this.setState({
                "userType": "departmentadmin"
            });
        }
        if (isAdminUser == "true" && isGlobalUser == "true") {
            //console.log(" IN getUserType, SUPERADMIN");
            this.setState({
                "userType": "superadmin"
            });
        }
    }
    
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    
    //logEvent = (actionType, actionName) => {
    logEvent = (actionName) => {
        try {
            if (googleAnalytics.page.enableGA) {
                let label = googleAnalytics.page.action.formSubmittedAddRole;
                
                let action = this.state.eventFormAction;
                if (actionName == "update") {
                    label = googleAnalytics.page.action.rowUpdated;
                    action = this.state.eventGridAction;
                }
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": action,
                    "label": label,
                }
                googleAnalytics.logEvent(eventOptions);

            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView(); // Log page view to GA

        redirectURL.post("/superadmin/roles")
        .then((response) => {
            //console.log("IN componentDidMount, Roles =  ",response.data);
            this.getUserType();
            let currentUserType = this.state.userType;
            let roles = null;
            let userDeptCode = JSON.parse(localStorage.getItem("dept_code"));

            //console.log("currentUserType, roles, userDeptCode = ", currentUserType, roles, userDeptCode);
            if (currentUserType == "superadmin") {
                roles = response.data;
                //console.log("Coming in superadmin, roles = ", roles);
            }
            if (currentUserType == "admin") {
                roles = response.data.filter(role => role.maruti_roles == 1);
                //console.log("Coming in admin, roles = ", roles);
            }
            if (currentUserType == "departmentadmin") {
                roles = response.data.filter(role => {
                    //console.log(role.dept_code, userDeptCode);
                    //return (role.maruti_roles == 0)
                    return (role.maruti_roles == 1) && (role.dept_code == userDeptCode)
                });
                //console.log("Coming in departmentadmin, roles, response.data = ", roles,  response.data);
            }
            this.setState({
                rowData:roles
            });
        })

        redirectURL.post('/masters/getdepartments')
        .then((response) => {
            //console.log("/masters/getdepartments = ",response.data);
            this.setState({departmentList:response.data});
        });
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    onShowRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-m',
            updateForm:'show-n'

        });
    }
    onShowUpdateRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-n',
            updateForm:'show-m'

        });
    }
    formHandler = (event) =>{
        event.preventDefault();
        this.logEvent(); // Log event to GA
        this.setState({errorDuplicateRole: false});
        if (!this.state.selectedDepartment || !this.state.selectedDepartment.value) {
            console.log("IN Form Handler, this.state.selectedDepartment = ",this.state.selectedDepartment)
            this.setState({
                errorSelectionDepartment: true
            })
            return false;
        }
        
        var formdata = {
            role_name:this.state.role_name,
            maruti_roles:1,
            dept_code: this.state.selectedDepartment.value
        }
        //console.log("IN Form Handler, formdata ", formdata);

        redirectURL.post("/superadmin/createrole", formdata)
        .then((response) => {
            console.log("response ", response)
            if (response.data.status == 400) {
                this.setState({errorDuplicateRole: true});
                return false;
            }
            this.setState({
                loadshow:'show-n',
            });
            window.location.reload();
        })
        .catch(function(e){
            console.log(e.message);
        });
		
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           
    }
    
	handlerForm = selectedDepartment => {
		//console.log("Select", event.target.value);
		this.setState(
			{ selectedDepartment },
			() => console.log(`Option selected:`, this.state.selectedDepartment)
		  );
        this.setState({
            errorSelectionDepartment: false
        })
    }
    
	selectOptionsItems() {
        let items = [];
        let departmentList = this.state.departmentList;
        //console.log(departmentList);
        departmentList.forEach(item => {
            items.push({"value":item.dept_code,"label":item.dept_name});	
        });
        return items;
    }
    
    checkRadioEvent = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

    checkGroupRadioEvent = (event) => {
    	let name = event.target.name;
        let value = event.target.value;
        console.log("event.target.value ", event.target.value)
        if(event.target.value == true)
        {
            this.setState({[name]:value, groupheader:"show-m"});
        }
        else{
            this.setState({[name]:value, groupheader:"show-n"});
        }
    }

    render(){
        const columnDefs = [
            {
                headerName: "Role Id",
                field: "role_name",
                width: 250,
                hide: true
            },
            {
                headerName: "isMR",
                field: "maruti_roles",
                width: 150,
                hide: true
            },
            {
              headerName: "Role Name (Editable)",
              field: "role_alias",
              width: 250,
              editable: function(params) {
                if (params.data.is_editable == 1) {
                    return true;
                } else {
                    return false;
                }
              },
              filter: "agSetColumnFilter",
              cellStyle: function(params) {
                  //console.log("params = ", params.data.is_editable, params.value, params);
                if (params.data.is_editable == 1) {
                    //mark police cells as red
                    //console.log("params = ", params.value, params.data.is_editable);
                    return {backgroundColor: '#e6f2ff'};
                } else {
                    return  {backgroundColor: '#d4d4d4'};;
                }
              },
              valueSetter: function(params) {
                if (googleAnalytics.page.enableGA) {
                    let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                    let label = googleAnalytics.page.action.rowUpdated;
                    let pageTitle = "Add a Role";
                    let eventOptions = {
                        "category": pageTitle,
                        "action": eventGridAction,
                        "label": label,
                    }
                    googleAnalytics.logEvent(eventOptions);
                }

                if (params.data.is_editable != 1) {
                  return false;
                }
                var newRoleAlias = params.newValue;
                if (!newRoleAlias || !newRoleAlias.trim()) {
                    return false;
                }
                var data = params.data;
                console.log("Data = ", newRoleAlias, data, params);
                if (data.role_alias !== newRoleAlias) {
                    // Update the Role Alias
                    let layoutdataRole = {
                        role_name: data.role_name,
                        role_alias: newRoleAlias
                    }
                    redirectURL.post('/superadmin/updaterolealias', layoutdataRole,{
                        headers:{
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                        }
                    })
                    .then((response) => {
                        var response = JSON.parse(JSON.stringify(response));
                        console.log("/superadmin/updaterolealias = ",response.data);
                        if (response.data.modifiedCount == 1) {
                            window.location.reload();
                        }
                        if (response.data.status == 400) {
                            alert(response.data.message);   
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                  console.log("Role changed = ", newRoleAlias, data.role_alias);
                  return true;
                } else {
                  return false;
                }
              },
            }
            
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>{this.state.pageTitle} </span>
                                       <a href="/assignmenus" className="btn btn-warning ml-5p float-right">Assign Menus to Role</a>
                                        <button type="button" onClick={this.onShowRoleForm.bind(this)} className="btn btn-info float-right">Add a Role</button>
                                </h5>
				   			</div>
				   			<div className="card-body row">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    {(this.state.messageRoleUpdateSuccessful) ? 
                                        <div className="txt-success f15" style={{padding:'0px 0px 20px 0px'}}>{this.state.messageFromServer}</div>
                                        : ""
                                    }
                                    {(this.state.errorRoleUpdatedFailed) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}> {this.state.messageFromServer}</div>
                                        : ""
                                    }
                                    {(this.state.errorRoleNotEditable) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}>Selected Role is not editable. Please contact Support Team.</div>
                                        : ""
                                    }
                                    {(this.state.errorEmptyValue) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}>Please provide a valid Role Name.</div>
                                        : ""
                                    }
                                </div>
                               
                                <div className=" col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
									    detailCellRendererParams={this.state.detailCellRendererParams}
									    enableRangeSelection={false}
                                        //floatingFilter={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressCellFlash={true}
									    masterDetail={true}
                                        
                                    />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
				 			Add a Role
				 		</h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
					
                         <form className={" theme-form col-xl-12 col-lg-12 " + (this.state.addForm)} onSubmit={this.formHandler}>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Role Name *:</label>
                                    <input type="text" name="role_name" id="role_name" value={this.state.role_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Role Name" required />
                                    {(this.state.errorDuplicateRole)? 
                                        <div id="duplicateRole" className="txt-danger-medium">Role already exists.</div>
                                    : ""}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Department Name *:</label>
                                    {/* <Select name="role_name" placeholder="Select Role" className="rselect" 
                                      value={this.state.role_name} onChange={this.handlerForm.bind(this)}  options={this.selectOptionsItems()} /> */}

                                     <Select name="department_name" placeholder="Select Department" className="rselect" 
                                        value={this.state.department_name} onChange={this.handlerForm.bind(this)} 
                                        options={this.selectOptionsItems()} />
                                    {(this.state.errorSelectionDepartment)? 
                                        <div id="errorSelectionDepartment" className="txt-danger-medium">Please select a department.</div>
                                    : ""}

                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <button type="submit" className="btn btn-success" name="saveMenu" id="saveMenu" >Save Role</button>
                                </div>
                            </div>
                            
                        </form>

                        <form className={" theme-form col-xl-12 col-lg-12 " + (this.state.updateForm)} onSubmit={this.formUpdateHandler}>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Role Name *:</label>
                                    <input type="text" name="role_name" id="role_name" value={this.state.role_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Code" required />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <button type="submit" className="btn btn-success" name="saveMenu" id="saveMenu" >Save Menu</button>
                                </div>
                            </div>
                            
                        </form>

				 	</div>
				 </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}