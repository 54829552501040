import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import Counters from './railcountersComponent';
import DeliverCounters from './raildelivercountersComponent';
import DateFormater from './dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import Edittruckavailable from "./edittruckavailable";
import Deletetruckavailable from "./deletetruckavailable";
import Remarksaction from "./remarkstruckavailableaction";
import Repairaction from "./repairstruckavailableaction";
import TruckGrid from "./truckavailabilitygrid";
import CountUp from 'react-countup';
import { object } from 'prop-types';
import { ImageFilter9 } from 'material-ui/svg-icons';
//import * as XLSX from 'xlsx';

$.datetimepicker.setLocale('en');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
//var rowdefs = []
export default class TruckAvailability extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderBulkForceClose:"",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                edittruckavailable:Edittruckavailable,
                deletetruckavailable:Deletetruckavailable
            },
            emptyframeworkcomponent:{
                Remarksaction:Remarksaction,
                Repairaction:Repairaction
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
            rowModelType: "serverSide",
            headerHeight:60,
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.gate_in_time != ''",
				//"green":"data.status >= 3"

			},
			screenpage:'',
			pagetitle:'Truck Availability',
			open:false,
            truckslist:[],
            truckcategories:[],
            truckcategory:{"value":"","label":""},
            truck:"",
            slidertranslate:'',
            trucktype: "10",
            warehouse:"",
            tentitivetime:"",
			showTrucksList : "show-n",
            filteredData : [],
            startDate:"",
            endDate:"",
            defaultsDate:"",
            defaulteDate:"",
            rowid:"",
            trucktypeother:"show-n",
            othertrucktype:"",
            bulkslide:"",
            file:"",
            csvcontent:'',
            categories:[],
            trucktypes:[],
            transporters:[],
            columns:[],
            rowdefs:[],
            rowloadedtruckdefs:[],
            pinnedRegionBottomRowData:[],
            marktabactive:"btn-success",
            emptytabactive:"",
            emptytabstatusactive:"",
            markedshow:"show-m",
            emptyshow:"show-n",
            emptyrowData:[],
            emptyplant:"",
            emptystatusshow:"show-n",
            emptyrowStatusData:[],
            usermanualmodal:false,
            overly:"show-m",
            loadshow:"show-m",
            loadtruckactive:"",
            loadtruckshow:"show-n",
            rowDataLoadedTrucks:[],
            startDateLoad:"",
            endDateLoad:"",
            auto_marked_by_system:[],
            loaded_auto_marked_by_system:[]
			
		}
		this.trucksoptions = this.trucksoptions.bind(this);
        this.truckcategoryoptions = this.truckcategoryoptions.bind(this);
        this.onLoadTrucksAvailable = this.onLoadTrucksAvailable.bind(this);
        this.onClickEditRecord = this.onClickEditRecord.bind(this);
        this.onClickDeleteRecord = this.onClickDeleteRecord.bind(this);
		this.uploadFormHandler = this.uploadFormHandler.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
        this.createColumns = this.createColumns.bind(this);
        this.createRowData = this.createRowData.bind(this);
        this.consolidateTrucks = this.consolidateTrucks.bind(this);
        this.onChangeRemarkOptions = this.onChangeRemarkOptions.bind(this);
        this.onChangeRemarkRepairOptions = this.onChangeRemarkRepairOptions.bind(this);
        this.submitEmptyFormHandler = this.submitEmptyFormHandler.bind(this);
        this.onLoadEmptyTrucksAvailable = this.onLoadEmptyTrucksAvailable.bind(this);
        this.onLoadLoadedTrucksAvailable = this.onLoadLoadedTrucksAvailable.bind(this);
        this.markedForDeleteClicked = this.markedForDeleteClicked.bind(this);
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        // var userRoles = localStorage.getItem("roles");
        // var pageUrl = this.props.match.path;
        // var usrParams = {
        //     pageUrl : pageUrl,
        //     userRoles : userRoles
        // }
        // redirectURL.post("/consignments/checkPageAccess", usrParams)
        // .then(async (response) => {            
        //     if(response.data.result == 0)
        //     {
        //         window.location.href="/";
        //     }
        // })
        loadDateTimeScript();
        //var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
        var sdate = moment.parseZone().format('YYYY-MM-DD');
        var edate = moment.parseZone().format('YYYY-MM-DD');
        this.setState({
            startDate:sdate,
            endDate:edate,
            startDateLoad:sdate,
            endDateLoad:edate,
            defaultsDate:sdate,
            defaulteDate:edate
        })
		var dept_code=""
        if(this.props.match.path == "/sndtruckavailability")
        {
            dept_code="SNDG"
        }
        if(this.props.match.path == "/prttruckavailability")
        {
            dept_code="LOG-PRT"
        }
        if(this.props.match.path == "/tnptruckavailability")
        {
            dept_code="LOG-TNP"
        }
        var parameters={
            dept_code:dept_code,
            startDate:sdate,
            endDate:edate,
        }
        this.setState({
            dept_code:dept_code,
            startDate:sdate,
            endDate:edate,
        })
        this.onLoadTrucksAvailable(parameters);
        this.onLoadLoadedTrucksAvailable(parameters);
        this.onLoadEmptyTrucksAvailable({dept_code:dept_code});
        try{
            
            var params = {
                dept_code:dept_code,
                transporter_code:JSON.parse(localStorage.getItem("transportercode"))
            }
            redirectURL.post("/consignments/transportertruckslist",params)
            .then((response) => {
                var rec = response.data.records;
                var truckslistarr = []
                if(rec.length > 0)
                {
                    rec.map((item) => {
                        truckslistarr.push({
                            value:item,
                            label:item
                        })
                    })
                }
                this.setState({
                    truckslist:truckslistarr
                })
            })
    
        }
        catch(e){

        }
        
    };
    
    onLoadTrucksAvailable = async(parameters) => {
        this.setState({
            loadshow:"show-m"
        })
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            parameters.transportercode = JSON.parse(localStorage.getItem("transportercode"))   
        }
        else{
            parameters.transportercode = ""
        }
        await redirectURL.post("/consignments/truckavailabledata", parameters)
        .then(async (response) => {
            var auto_marked_by_system = []
            var recrds  = response.data.records;
            if(recrds.length > 0)
            {
                recrds.map((item) => {
                    if(item.auto_marked_by_system == 1)
                    {
                        auto_marked_by_system.push(item)
                    }
                })
            }           
            this.setState({
                rowData: response.data.records,
                loadshow:"show-n",
                auto_marked_by_system:auto_marked_by_system
            })
            await this.consolidateTrucks()

        })
        
    }
    onLoadLoadedTrucksAvailable = async(parameters) => {
        this.setState({
            loadshow:"show-m"
        })
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            parameters.transportercode = JSON.parse(localStorage.getItem("transportercode"))   
        }
        else{
            parameters.transportercode = ""
        }
        await redirectURL.post("/consignments/loadedtruckavailabledata", parameters)
        .then(async (response) => {            
            var loaded_auto_marked_by_system = []
            var recrds = response.data.records;
            if(recrds.length > 0)
            {
                recrds.map((item) => {
                    if(item.auto_marked_by_system == 1)
                    {
                        loaded_auto_marked_by_system.push(item);
                    }
                })
            }
            this.setState({
                rowDataLoadedTrucks: response.data.records,
                loadshow:"show-n",
                loaded_auto_marked_by_system:loaded_auto_marked_by_system
            })
            await this.consolidateLoadedTrucks()

        })
        
    }
    onLoadEmptyTrucksAvailable = async(parameters) => {

        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            parameters.transportercode = JSON.parse(localStorage.getItem("transportercode"))   
        }
        else{
            parameters.transportercode = ""
        }
        // var parameters  = {
        //     dept_code:this.state.dept_code,
        //     transportercode :transportercode
        // }
        redirectURL.post("/consignments/truckstatusavailabledata", parameters)
        .then((response) => {
            //console.log("response ", response.data.records)
            this.setState({
                emptyrowStatusData:response.data.records
            })
        })
        
    }
    markedForDeleteClicked = (e) => {
        if(e.colDef.field == "revert")
        {
            var rowId = e.data._id;
            var remarks_status = e.data.remarks_status;
            if(remarks_status == "delete")
            {
                if(rowId !=undefined && rowId !='')
                {
                    var qry = {
                        rowId : rowId
                    }
                    redirectURL.post("/consignments/revertTruckAvailable", qry)
                    .then((response) => {
                        //console.log("response ", response.data.records)
                        this.setState({
                            show:true,
                            basicTitle:"Successfully reverted",
                            basicType:"success"  
                        })
                        this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})
                    })
                }
            }
        }
    }
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			slidertranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	
	onCloseRouteDiv = () => {
		this.setState({
			slidertranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            slidertranslate:'',
            bulkslide:"",
            showTrucksList : "show-n",
            truck:{"value":"","label":""},
            truckcategory:{"value":"","label":""},
            warehouse:{"value":"","label":""},
            trucktype: "10",
            rowid:""
			
		});
		
	}
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckslist;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var filteredResult = dataset.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        //await window.location.reload();
	}
    changeTruckHandler = truck => {
        this.setState(
            { truck }
        );
        
    }
    changeCategoryHandler = truckcategory => {
        this.setState(
            { truckcategory }
        );
        
    }
    changeWarehouseHandler = warehouse =>{
        this.setState(
            { warehouse }
        );
        
    }
    changeTruckTypeHandler = trucktype =>{
        this.setState(
            { trucktype }
        );

        if(trucktype.value == "Others")
        {
            this.setState({
                trucktypeother:'show-m'
            })
        }
        else
        {
            this.setState({
                trucktypeother:'show-n'
            })
        }
        
    }
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    trucksoptions(){
        var items = []
        return items;
    }
    truckcategoryoptions(){
        var items = [,
            {
                "value":"Delhi NCR","label":"Delhi NCR"
            },
            {
                "value":"Interplant transfer","label":"Interplant transfer"
            },
            {
                "value":"Consignee Delivery","label":"Consignee Delivery"
            },
            {
                "value":"others","label":"Others",
            }
        ]
        
        return items;
    }
    warehouseoptions(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.dept_code == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["Manesar Domestic - DM01", "Gurgaon", "Manesar MTD - EDC/Bumper/TKML/Export", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		// items.push({value:'',label:''}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
    }
    trucktypeoptions(){
        let items = [
            {value:'',label:''},
            {value:'32 feet',label:'32 ft'},
            {value:'20 feet',label:'20 ft'},
            {value:'17 feet',label:'17 ft'},
            {value:'14 feet',label:'14 ft'},
            {value:'Others',label:'Others'}
        ]; 
		
       
        return items;
    }
    onClickShowFilterData(e){
        e.preventDefault()
        var truck = this.state.truck;
        var truckcategory = this.state.truckcategory;
        var warehouse = this.state.warehouse;
        //console.log('warehouse ', warehouse)
        // var trucktype = this.state.trucktype.label;
        var trucktype = this.state.trucktype.toString()+" ft";
        if(trucktype == 'Others')
        {
            var othertrucktype = this.state.othertrucktype;
            if(othertrucktype == "")
            {
                trucktype = ""
            }
        }
        else
        {
            var othertrucktype = "";
        }
        var tentitivetime = document.getElementById("tentitivetime").value;
       // var todayDate = moment.parseZone().add(1,"days").format("YYYY-MM-DD")
    //    console.log("tentitivetime ", tentitivetime)
       var t = tentitivetime.split(" ");
       var ts = t[0].split("-")
    //    console.log("ts ", ts[2].length)
       if(ts[2].length>2)
       {
        var tm = ts[2]+"-"+ts[1]+"-"+ts[0]+" "+t[1]+":00"
       }
       else
       {
        var tm = ts[0]+"-"+ts[1]+"-"+ts[2]+" "+t[1]+":00"
       }
    //    console.log("tm ", tm)
        var todayDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        var tdate = moment.parseZone(new Date(todayDate)).format("YYYY-MM-DD HH:mm:ss");
        // console.log("tdate ", tdate)
        var today = moment.parseZone(tdate).format("x");
        //console.log("tentitivetime ", tentitivetime)
        var ttime = moment.parseZone(new Date(tm)).format("YYYY-MM-DD HH:mm:ss")
        // console.log("ttime ", ttime)
        var tentive = moment.parseZone(ttime).format("x")
    //    console.log("todayDate ", today)
    //   console.log("ttime ", tentive)
    
        var email = localStorage.getItem("email");
        var prt_transporter_code = localStorage.getItem("prt_transporter_code");
        var username = localStorage.getItem("username");
        var rowid = this.state.rowid;
        
        if(truck != "" && truck != null && truckcategory != "" && warehouse != "" && warehouse != "all" && trucktype != ""
                && tentitivetime != "")
        {
            if(parseInt(tentive) >= parseInt(today))
            {
                var parameters = {
                    truck_no:truck.value,
                    truck_category:truckcategory.value,
                    warehouse:warehouse.value,
                    warehouse_text:warehouse.label,
                    truck_type:trucktype,
                    othertrucktype:othertrucktype,
                    tentitivetime:tm+":00",
                    dept_code:this.state.dept_code,
                    email:email,
                    transportercode:JSON.parse(prt_transporter_code),
                    username:username,
                    rowid:rowid
                }
                
                //console.log("tentitivetime ", parameters)
                redirectURL.post("/consignments/storetruckavailabe", parameters)
                .then((response) => {
                // console.log("response ", response.data)
                    if(response.data.message != "Away From Warehouse" && response.data.message != "No GPS Data")
                    {
                        if(response.data.message == "truckexists")
                        {
                            this.setState({
                                show:true,
                                basicTitle:"Combination for Truck, Warehouse and Tentative Date Already Exists..!!",
                                basicType:'info'
                            })
                        }
                        else if(response.data.message == "success"){

                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Successfully saved",
                                //basicTitle:response.data.api_message,
                                basicType:'success',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: "10",
                                othertrucktype:"",
                                rowid:""
                            })
                            var p = {
                                dept_code:this.state.dept_code,
                                startDate:this.state.startDate,
                                endDate:this.state.endDate
                            }
                            this.onLoadTrucksAvailable(p);

                            var formdata = {
                                plant_codes:[this.state.emptyplant.value],
                                radius:100,
                                request_type:3,
                                dept_code:this.state.dept_code
                            }
                            this.submitEmptyFormHandler(formdata);
                            this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})

                        }
                        else
                        {
                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Failed",
                                basicType:'danger',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: 10,
                                othertrucktype:"",
                                rowid:""
                            })
                        }
                    }
                    else
                    {
                        if(response.data.message != "No GPS Data")
                        {
                            this.setState({
                                show: true,
                                basicTitle: "Truck is "+response.data.distance.toString()+"KM away from Selected Warhouse",
                                basicType: "warning"
                            })
                        }
                        else
                        {
                            this.setState({
                                show: true,
                                basicTitle: response.data.api_message,
                                basicType: "warning"
                            })
                        }
                        
                    }
                    
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Tentitive Date should be greate than today",
                    basicType:'danger'
                })
            }
        }
        else{
            this.setState({
                show:true,
                basicTitle:"All * fields are mandatory",
                basicType:'danger'
            })
        }
        
    }

    
    onClickShowFilterLoadedTrucksData(e){
        e.preventDefault()
        var truck = this.state.truck;
        var truckcategory = this.state.truckcategory;
        var warehouse = this.state.warehouse;
        //console.log('warehouse ', warehouse)
        // var trucktype = this.state.trucktype.label;
        var trucktype = this.state.trucktype.toString()+" ft";
        if(trucktype == 'Others')
        {
            var othertrucktype = this.state.othertrucktype;
            if(othertrucktype == "")
            {
                trucktype = ""
            }
        }
        else
        {
            var othertrucktype = "";
        }
        var tentitivetime = document.getElementById("tentitivetime").value;
       // var todayDate = moment.parseZone().add(1,"days").format("YYYY-MM-DD")
    //    console.log("tentitivetime ", tentitivetime)
       var t = tentitivetime.split(" ");
       var ts = t[0].split("-")
    //    console.log("ts ", ts[2].length)
       if(ts[2].length>2)
       {
        var tm = ts[2]+"-"+ts[1]+"-"+ts[0]+" "+t[1]+":00"
       }
       else
       {
        var tm = ts[0]+"-"+ts[1]+"-"+ts[2]+" "+t[1]+":00"
       }
    //    console.log("tm ", tm)
        var todayDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        var tdate = moment.parseZone(new Date(todayDate)).format("YYYY-MM-DD HH:mm:ss");
        // console.log("tdate ", tdate)
        var today = moment.parseZone(tdate).format("x");
        //console.log("tentitivetime ", tentitivetime)
        var ttime = moment.parseZone(new Date(tm)).format("YYYY-MM-DD HH:mm:ss")
        // console.log("ttime ", ttime)
        var tentive = moment.parseZone(ttime).format("x")
    //    console.log("todayDate ", today)
    //   console.log("ttime ", tentive)
    
        var email = localStorage.getItem("email");
        var prt_transporter_code = localStorage.getItem("prt_transporter_code");
        var username = localStorage.getItem("username");
        var rowid = this.state.rowid;
        
        if(truck != "" && truck != null && truckcategory != "" && warehouse != "" && warehouse != "all" && trucktype != ""
                && tentitivetime != "")
        {
            if(parseInt(tentive) >= parseInt(today))
            {
                var parameters = {
                    truck_no:truck.value,
                    truck_category:truckcategory.value,
                    warehouse:warehouse.value,
                    warehouse_text:warehouse.label,
                    truck_type:trucktype,
                    othertrucktype:othertrucktype,
                    tentitivetime:tm+":00",
                    dept_code:this.state.dept_code,
                    email:email,
                    transportercode:JSON.parse(prt_transporter_code),
                    username:username,
                    rowid:rowid
                }
                
                //console.log("tentitivetime ", parameters)
                redirectURL.post("/consignments/storetruckavailabe", parameters)
                .then((response) => {
                // console.log("response ", response.data)
                    if(response.data.message != "Away From Warehouse" && response.data.message != "No GPS Data")
                    {
                        if(response.data.message == "truckexists")
                        {
                            this.setState({
                                show:true,
                                basicTitle:"Combination for Truck, Warehouse and Tentative Date Already Exists..!!",
                                basicType:'info'
                            })
                        }
                        else if(response.data.message == "success"){

                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Successfully saved",
                                //basicTitle:response.data.api_message,
                                basicType:'success',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: "10",
                                othertrucktype:"",
                                rowid:""
                            })
                            var p = {
                                dept_code:this.state.dept_code,
                                startDate:this.state.startDate,
                                endDate:this.state.endDate
                            }
                            this.onLoadTrucksAvailable(p);

                            var formdata = {
                                plant_codes:[this.state.emptyplant.value],
                                radius:100,
                                request_type:3,
                                dept_code:this.state.dept_code
                            }
                            this.submitEmptyFormHandler(formdata);
                            this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})

                        }
                        else
                        {
                            document.getElementById("tentitivetime").value="";
                            this.setState({
                                show:true,
                                basicTitle:"Failed",
                                basicType:'danger',
                                slidertranslate:"",
                                overly:"show-n",
                                truck:{"value":"","label":""},
                                truckcategory:{"value":"","label":""},
                                warehouse:{"value":"","label":""},
                                trucktype: 10,
                                othertrucktype:"",
                                rowid:""
                            })
                        }
                    }
                    else
                    {
                        if(response.data.message != "No GPS Data")
                        {
                            this.setState({
                                show: true,
                                basicTitle: "Truck is "+response.data.distance.toString()+"KM away from Selected Warhouse",
                                basicType: "warning"
                            })
                        }
                        else
                        {
                            this.setState({
                                show: true,
                                basicTitle: response.data.api_message,
                                basicType: "warning"
                            })
                        }
                        
                    }
                    
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Tentitive Date should be greate than today",
                    basicType:'danger'
                })
            }
        }
        else{
            this.setState({
                show:true,
                basicTitle:"All * fields are mandatory",
                basicType:'danger'
            })
        }
        
    }

    onClickShowAvailabilityForm(){
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m"
        })
    }
    setConsignmentValue(e){
        console.log(e)
        if(e != null)
        {
            var st =e.value;
            var stringval = st.replace(/[^A-Z0-9]+/ig, "");
            console.log("stringval  ",stringval)
            var params = {
                truck_no : stringval,
            }
            redirectURL.post("/consignments/getTruckType", params)
            .then((response) => {
                var results = response.data;
                //console.log("response ", response.data.records)
                if(results.length > 0)
                {
                    var truck_type = results[0].truck_type;
                    if(truck_type !=undefined && truck_type !='')
                    {
                        var tt = truck_type;
                        if(truck_type == "32 feet")
                        {
                            var tt = "32 ft";
                        }
                        else if(truck_type == "20 feet")
                        {
                            var tt = "20 ft";
                        }
                        else if(truck_type == "17 feet")
                        {
                            var tt = "17 ft";
                        }
                        else if(truck_type == "14 feet")
                        {
                            var tt = "14 ft";
                        }
                        this.setState({
                            trucktype:truck_type.split(" ")[0],
                        })
                    }
                    
                }                
            })
            this.setState({
                truck : {value:stringval,label:stringval},
            });
        }
        else{
            this.setState({
                truck : e
            });
        }
        
    }

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    
	handlerStartDateTimeLoad = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDateLoad:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTimeLoad  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDateLoad:edate
    	});
    }
    onClickFilterData(){
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters  = {
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate
        }
        this.onLoadTrucksAvailable(parameters)
        this.consolidateTrucks();
    }

    onClickLoadedTruckFilterData(){
        var start = this.state.startDateLoad;
        var end = this.state.endDateLoad;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters  = {
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate
        }
        this.onLoadLoadedTrucksAvailable(parameters)
        this.consolidateLoadedTrucks();
    }

    onClickEditRecord = async (params) => {
        //console.log("Edit ", params)
        var truck = {"value":params.truck_no,"label":params.truck_no};
        var truckcategory = {"value":params.truck_category,"label":params.truck_category};
        var warehouse = {"value":params.warehouse,"label":params.warehouse_text};
        var trucktype = params.truck_type.split(" ")[0];
        var tentitivetime = moment.parseZone(params.tentitivetime).format("YYYY-MM-DD HH:mm")
        var othertrucktype = params.other_trucktype
        document.getElementById("tentitivetime").value = tentitivetime;
        if(params.truck_type == "Others")
        {
            var tothers = "show-m"
        }
        else
        {
            var tothers = "show-n"
        }
        await this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m",
            truck:truck,
            truckcategory:truckcategory,
            warehouse:warehouse,
            trucktype:trucktype,
            othertrucktype:othertrucktype,
            rowid:params._id,
            trucktypeother:tothers
        })
        //await this.onClickShowFilterData()
    }
    onClickDeleteRecord(params){
        console.log("Delete ", params)
        var parameters = {
            rowid:params._id
        }
        redirectURL.post("/consignments/removetruckavailable",parameters)
        .then((response) => {            
            var p = {
                dept_code:this.state.dept_code,
                startDate:this.state.startDate,
                endDate:this.state.endDate
            }
            this.onLoadTrucksAvailable(p)
            var formdata = {
                plant_codes:[this.state.emptyplant.value],
                radius:100,
                request_type:3,
                dept_code:this.state.dept_code
            }
            this.submitEmptyFormHandler(formdata);
            this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})
        })
    }
    onShowBulkUpload(){
		
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
    }
    
   
    changeFileHandler (e){
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'truckno',
					inputName: 'truckno',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'truckcategory',
					inputName: 'truckcategory',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'truck_type',
					inputName: 'truck_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'warehouse code',
					inputName: 'warehousecode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'warehouse text',
					inputName: 'warehousetext',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'tentitivetime',
					inputName: 'tentitivetime',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			 reader.readAsText(e.target.files[0]);
		});
		//console.log("Out ", out);
		// this.setState({
		// 	csvcontent:contents
		// });
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
    }
    
    uploadFormHandler(event){
		event.preventDefault();
		
		if(this.state.file != '')
		{
            var formData = {
                csvcontent:this.state.csvcontent,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username"),
                transportercode:localStorage.getItem("transportercode")
            }
	
			console.log('file',formData);
			this.setState({
			//	loadshow:'show-m'
			}); 
            redirectURL.post("/consignments/uploadbulktruckavailability", formData)
            .then((response) => {
                console.log("response ", response.data)
            })
		}
		
    }
    
    consolidateTrucks =async() =>{
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters={
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate,
        }

        await redirectURL.post("/consignments/alltruckAvailabledata", parameters)
        .then(async (response) => {
            var records = response.data.records;
            console.log(records,"records.length")
            if(this.state.dept_code == "LOG-PRT")
            {
                var r = response.data.records;
                var dst = [];
                r.map(function(e){
                    if(dst.indexOf(e.truck_type) == -1)
                    {
                        dst.push(e.truck_type)
                    }
                })
                var truck_type_aggr = {}
                dst.map(function(e){
                    if(parseInt(e.split(" ")[0]) >= 30)
                    {
                        truck_type_aggr[e] = "32 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) >= 18)
                    {
                        truck_type_aggr[e] = "20 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) < 18)
                    {
                        truck_type_aggr[e] = "Small Trucks"
                    }
                })
                records = response.data.records
                var chk = await records.map(function(e){
                    e.truck_type = truck_type_aggr[e.truck_type]
                    console.log(e.truck_type)
                })
            }
            
            console.log("respnse ", records)
            var categories = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push({
                        warehouse:item.warehouse,
                        warehouse_text:item.warehouse_text,
                        truck_type:item.truck_type
                    });
                // categories.push(item.truck_category);
                })
            }
            //console.log("categoriescategories ", categories)
            this.setState({
                categories:categories,
                transporters:records,
            })
            await this.createColumns();
            await this.createRowData();
            
        })
        
    }
    createColumns()
    {
        var columns=[]
        var transporters = this.state.transporters;
        columns.push({
            headerName:"Transporter Name",
            field:"transporter_name",
            width:180,
        })
        var categories = this.state.categories;
        
        
        var warehousewise = groupBy(transporters, rdata => rdata.warehouse);
        // console.log("warehousewise ", warehousewise)
        warehousewise.forEach((wares, wkeys) => {
            var defs = {
                headerName:wkeys,
                headerClass:["csgpsBg"],	
            }
            //console.log("defs ", defs)
            if(wares.length > 0)
            {
               // console.log("wares ", wares)
                var warehouses = groupBy(wares, rdata=>rdata.truck_type)
               // console.log("warehouses ", warehouses)
                var warechildern = []
                warehouses.forEach((wh, ky) => {
                    warechildern.push({
                        headerName:ky,
                        headerClass:["textAlignCenter"],
                        field:wh[0].warehouse+""+ky,
                        width:100,
                        cellClass:["textAlignCenter"],
                        cellStyle: {borderLeft: '1px solid #ccc'}
                    })
                })
            }
            defs.children = warechildern
            columns.push(defs);
        })
        columns.push({
            headerName:"Total",
            headerClass:["textAlignCenter"],
            field:"total",
            width:100,
            pinned:"right",
            cellClass:["textAlignCenter"],
            cellClass:"boxbgblue",
        })
       // console.log("columns ", columns)
        this.setState({
            columns:columns
        })
    }
    createRowData(){
        var rowdefs = []
        var totalCnt = 0
        var transporters = this.state.transporters;
        var transportername = groupBy(transporters, rdata => rdata.transporter_name);
        console.log(transportername,"transportername")
        transportername.forEach((trans, transkey) =>{
            var rows = {}
            if(transkey.includes("("))
            {
                rows.transporter_name = transkey.split("(")[0]
            }
            else
            {
                rows.transporter_name=transkey
            }
            if(rows.transporter_name != "P S LOGISTICS")
            {

                rows.transporter_name = capitalizeFirstLetter(rows.transporter_name.toLowerCase())
                var rowcnt = 0
                if(trans.length > 0)
                {
                    //console.log("trans ", trans)
                    var warehousewise = groupBy(trans, rdata => rdata.warehouse);
                    warehousewise.forEach((wares,wkey) => {
                        var warehouses = groupBy(wares, rdata=>rdata.truck_type)
                        warehouses.forEach((wh,ky) => {
                            // rows[wh[0].warehouse+""+ky] = wh.length
                            // rowcnt = rowcnt+parseInt(wh.length)
                            if(wh.length == undefined)
                            {
                                var whLen = "";
                            }
                            else{
                                var whLen = wh.length;
                            }
                            var vCnt = 0
                            if(this.state.dept_code == "LOG-PRT")
                            {
                                wh.map(function(k){
                                    if(k.vehicle_count != '' && k.vehicle_count != undefined)
                                    {
                                        vCnt = vCnt + k.vehicle_count;
                                    }
                                    else
                                    {
                                        vCnt = vCnt + 0;
                                    }
                                })
                                rows[wh[0].warehouse+""+ky] = vCnt;
                            }
                            else
                            {
                                if(wh[0].vehicle_count != '' && wh[0].vehicle_count != undefined)
                                {
                                    vCnt = wh[0].vehicle_count;
                                }
                                else
                                {
                                    vCnt =  0;
                                }
                                rows[wh[0].warehouse+""+ky] = wh[0].vehicle_count;

                            }
                            
                            
                            rowcnt = rowcnt+parseInt(vCnt)
                            
                        })
                    })
                }
            }
            totalCnt = totalCnt+parseInt(rowcnt);
            rows["total"] = rowcnt
            rowdefs.push(rows)
        })
        console.log(rowdefs,"rowdefs")
        var pinnedBottomRowData = {}
        var temp = {};
        if(rowdefs.length > 0)
        {
            var gridkeys = Object.keys(rowdefs[0]);
            gridkeys.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        
        gridkeys = gridkeys.filter(function(e){return e != "transporter_name"})
        rowdefs.map(function(e, index){
            gridkeys.map(function(k)
            {
                var keyval;
                if(e[k] == "")
                {
                     keyval = 0;
                }else{ keyval = e[k]}
                temp[k] = temp[k] + keyval
            })
        })
        temp['Total'] = totalCnt;
        temp['transporter_name'] = "Total"
        console.log(temp, "pinnedBottomRowData")
        this.setState({
            rowdefs:rowdefs,
            pinnedRegionBottomRowData:[temp],
            overly:"show-n",
            loadshow:"show-n",
        })
        //console.log("rowdefs ", rowdefs)
    }

    
    consolidateLoadedTrucks =async() =>{
        this.setState({
            loadshow:"show-m"
        })
        var start = this.state.startDate;
        var end = this.state.endDate;
        if(start == '' || start == "NaN-NaN-NaN")
        {
            var sdate = this.state.defaultsDate
        }
        else{
            var sdate = start
        }
        if(end == '' || end == "NaN-NaN-NaN")
        {
            var edate = this.state.defaulteDate            
        }
        else{
            var edate = end
        }
        var parameters={
            dept_code:this.state.dept_code,
            startDate:sdate,
            endDate:edate,
        }

        await redirectURL.post("/consignments/allloadedtruckAvailabledata", parameters)
        .then(async (response) => {
            var records = response.data.records;
            console.log(records,"records.length")
            if(this.state.dept_code == "LOG-PRT")
            {
                var r = response.data.records;
                var dst = [];
                r.map(function(e){
                    if(dst.indexOf(e.truck_type) == -1)
                    {
                        dst.push(e.truck_type)
                    }
                })
                var truck_type_aggr = {}
                dst.map(function(e){
                    if(parseInt(e.split(" ")[0]) >= 30)
                    {
                        truck_type_aggr[e] = "32 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) >= 18)
                    {
                        truck_type_aggr[e] = "20 FT"
                    }
                    else if(parseInt(e.split(" ")[0]) < 18)
                    {
                        truck_type_aggr[e] = "Small Trucks"
                    }
                })
                records = response.data.records
                var chk = await records.map(function(e){
                    e.truck_type = truck_type_aggr[e.truck_type]
                    console.log(e.truck_type)
                })
            }
            
            console.log("respnse ", records)
            var categories = [];
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push({
                        warehouse:item.warehouse,
                        warehouse_text:item.warehouse_text,
                        truck_type:item.truck_type
                    });
                // categories.push(item.truck_category);
                })
            }
            //console.log("categoriescategories ", categories)
            this.setState({
                categories:categories,
                transporters:records,
                loadshow:"show-n"
            })
            await this.createLoadedTruckColumns();
            await this.createLoadedTruckRowData();
            
        })
        
    }
    createLoadedTruckColumns()
    {
        var columns=[]
        var transporters = this.state.transporters;
        columns.push({
            headerName:"Transporter Name",
            field:"transporter_name",
            width:180,
        })
        var categories = this.state.categories;
        
        
        var warehousewise = groupBy(transporters, rdata => rdata.warehouse);
        // console.log("warehousewise ", warehousewise)
        warehousewise.forEach((wares, wkeys) => {
            var defs = {
                headerName:wkeys,
                headerClass:["csgpsBg"],	
            }
            //console.log("defs ", defs)
            if(wares.length > 0)
            {
               // console.log("wares ", wares)
                var warehouses = groupBy(wares, rdata=>rdata.truck_type)
               // console.log("warehouses ", warehouses)
                var warechildern = []
                warehouses.forEach((wh, ky) => {
                    warechildern.push({
                        headerName:ky,
                        headerClass:["textAlignCenter"],
                        field:wh[0].warehouse+""+ky,
                        width:100,
                        cellClass:["textAlignCenter"],
                        cellStyle: {borderLeft: '1px solid #ccc'}
                    })
                })
            }
            defs.children = warechildern
            columns.push(defs);
        })
        columns.push({
            headerName:"Total",
            headerClass:["textAlignCenter"],
            field:"total",
            width:100,
            pinned:"right",
            cellClass:["textAlignCenter"],
            cellClass:"boxbgblue",
        })
       // console.log("columns ", columns)
        this.setState({
            columns:columns
        })
    }
    createLoadedTruckRowData(){
        var rowdefs = []
        var totalCnt = 0
        var transporters = this.state.transporters;
        var transportername = groupBy(transporters, rdata => rdata.transporter_name);
        console.log(transportername,"transportername")
        transportername.forEach((trans, transkey) =>{
            var rows = {}
            if(transkey.includes("("))
            {
                rows.transporter_name = transkey.split("(")[0]
            }
            else
            {
                rows.transporter_name=transkey
            }
            if(rows.transporter_name != "P S LOGISTICS")
            {

                rows.transporter_name = capitalizeFirstLetter(rows.transporter_name.toLowerCase())
                var rowcnt = 0
                if(trans.length > 0)
                {
                    //console.log("trans ", trans)
                    var warehousewise = groupBy(trans, rdata => rdata.warehouse);
                    warehousewise.forEach((wares,wkey) => {
                        var warehouses = groupBy(wares, rdata=>rdata.truck_type)
                        warehouses.forEach((wh,ky) => {
                            // rows[wh[0].warehouse+""+ky] = wh.length
                            // rowcnt = rowcnt+parseInt(wh.length)
                            if(wh.length == undefined)
                            {
                                var whLen = "";
                            }
                            else{
                                var whLen = wh.length;
                            }
                            var vCnt = 0
                            if(this.state.dept_code == "LOG-PRT")
                            {
                                wh.map(function(k){
                                    if(k.vehicle_count != '' && k.vehicle_count != undefined)
                                    {
                                        vCnt = vCnt + k.vehicle_count;
                                    }
                                    else
                                    {
                                        vCnt = vCnt + 0;
                                    }
                                })
                                rows[wh[0].warehouse+""+ky] = vCnt;
                            }
                            else
                            {
                                if(wh[0].vehicle_count != '' && wh[0].vehicle_count != undefined)
                                {
                                    vCnt = wh[0].vehicle_count;
                                }
                                else
                                {
                                    vCnt =  0;
                                }
                                rows[wh[0].warehouse+""+ky] = wh[0].vehicle_count;

                            }
                            
                            
                            rowcnt = rowcnt+parseInt(vCnt)
                            
                        })
                    })
                }
            }
            totalCnt = totalCnt+parseInt(rowcnt);
            rows["total"] = rowcnt
            rowdefs.push(rows)
        })
        console.log(rowdefs,"rowdefs")
        var pinnedBottomRowData = {}
        var temp = {};
        if(rowdefs.length > 0)
        {
            var gridkeys = Object.keys(rowdefs[0]);
            gridkeys.map(function(e){
                temp[e] = 0
            })
        }
        else
        {
            var gridkeys = [];
        }
        
        gridkeys = gridkeys.filter(function(e){return e != "transporter_name"})
        rowdefs.map(function(e, index){
            gridkeys.map(function(k)
            {
                var keyval;
                if(e[k] == "")
                {
                     keyval = 0;
                }else{ keyval = e[k]}
                temp[k] = temp[k] + keyval
            })
        })
        temp['Total'] = totalCnt;
        temp['transporter_name'] = "Total"
        console.log(temp, "pinnedBottomRowData")
        this.setState({
            rowloadedtruckdefs:rowdefs,
            pinnedRegionBottomRowData:[temp],
            overly:"show-n",
            loadshow:"show-n",
        })
        //console.log("rowdefs ", rowdefs)
    }
    onClickShowTabView(tab){
        if(tab == "marked")
        {
            this.setState({
                marktabactive:"btn-success",
                emptytabactive:"",
                emptytabstatusactive:"",
                loadtruckactive:"",
                markedshow:"show-m",
                emptyshow:"show-n",
                emptystatusshow:"show-n",
                loadtruckshow:"show-n"
            })
        }

        if(tab == "empty")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"btn-success",
                emptytabstatusactive:"",
                loadtruckactive:"",
                markedshow:"show-n",
                emptyshow:"show-m",
                emptystatusshow:"show-n",
                loadtruckshow:"show-n"
            })
        }
        if(tab == "emptystatus")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"",
                emptytabstatusactive:"btn-success",
                markedshow:"show-n",
                emptyshow:"show-n",
                emptystatusshow:"show-m",
                loadtruckshow:"show-n"
            })
        }
        if(tab == "loadtruck")
        {
            this.setState({
                marktabactive:"",
                emptytabactive:"",
                loadtruckactive:"btn-success",
                emptytabstatusactive:"",
                markedshow:"show-n",
                emptyshow:"show-n",
                emptystatusshow:"show-n",
                loadtruckshow:"show-m"
            })
        }
    }

    selectOptionsItems() {
		let items = [];     
		var consigners = [];
        //console.log("this.state.dept_code ", this.state.dept_code)
		
		if(this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg')
		{
			var keys = ["B", "F", "G", "I","M", "N","MUNDRA","MBPT"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur", 'Mundra', "Mumbai Port"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		else if(this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt')
		{
			var keys = ["MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = [ "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		else if(this.state.dept_code == 'LOG-TNP' || this.state.dept_code == 'log-tnp')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		//items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
		
        return items;
    }
    changePlantCode = async emptyplant => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ emptyplant },
			() => console.log(`Option selected:`, this.state.emptyplant)
          );
        
    }
    formEmptyHandler(){
        var formdata = {
            plant_codes:[this.state.emptyplant.value],
            radius:60,
            request_type:3,
            dept_code:this.state.dept_code,
        }
        this.submitEmptyFormHandler(formdata);
    }
    submitEmptyFormHandler(formdata){
        var user = localStorage.getItem("user_type")
        var transporterCode = localStorage.getItem("transportercode");
        //console.log(transporterCode,"transporterCode")
        if(user == "TRANSPORTER")
        {
            formdata.transporterCode = transporterCode;
        }
        redirectURL.post('/consignments/emptyplantdata',formdata)
        .then((response) => {
           // console.log("empty ", response.data.data)
           try{
            var records = JSON.parse(response.data.data);
           
           }
           catch(e){
            var records = [];
           
           }
            // console.log("records ", records)
            try{
                var rec = JSON.parse(records.trucks_data);
            }
            catch(e){
                var rec = [];
            }
            var recordsarr = []
            var existstrucks = []
            var trecords = this.state.rowData;
            var erecords = this.state.emptyrowStatusData;
            if(trecords.length > 0)
            {
                trecords.map((item) => {
                    existstrucks.push(item.truck_no)
                })
            }
            if(erecords.length > 0)
            {
                erecords.map((item) => {
                    existstrucks.push(item.truck_no)
                })
            }
            //console.log("existstrucks ", existstrucks)
            if(rec.length > 0)
            {
                var user = localStorage.getItem("user_type")
                
                rec.map((item) => {
                    // if(user == "TRANSPORTER"){
                    //     var username = localStorage.getItem("username").toLocaleUpperCase()
                    //    // console.log(username)
                    //     if(username == item.transporter_name)
                    //     {
                    //         if(existstrucks.indexOf(item.truck_no) >= 0)
                    //         {
                    //         }
                    //         else{
                    //             recordsarr.push(item)
                    //         }
                            
                    //     }
                    // }
                    // else{
                        
                        if(existstrucks.indexOf(item.truck_no) >= 0)
                        {
                        }
                        else{
                            recordsarr.push(item)
                        }
                    //}
                })
            }
            //console.log("recordsarr ", recordsarr)
            this.setState({
                emptyrowData:recordsarr
            })
        })
            
    }
    onChangeRemarkOptions(params)
    {
        var truck = {"value":params.data.truck_no,"label":params.data.truck_no};
    
        this.setState({
            slidertranslate:"slider-translate-30p",
            overly:"show-m",
            truck:truck,
            warehouse:this.state.emptyplant
        })        
        
    }
    onChangeRemarkRepairOptions(params){
        var email = localStorage.getItem("email");
        var transportercode = localStorage.getItem("transportercode");
        var username = localStorage.getItem("username");

        var parameters = {
            truck_no:params.data.truck_no,
            truck_category:"",
            warehouse:this.state.emptyplant.value,
            warehouse_text:this.state.emptyplant.label,
            truck_type:"",
            othertrucktype:"",
            tentitivetime:"",
            dept_code:this.state.dept_code,
            email:email,
            transportercode:JSON.parse(transportercode),
            username:username
        }
        redirectURL.post("/consignments/storeemptytruckavailabe", parameters)
        .then(async (response) => {
           // console.log("resp ", response.data)
            if(response.data.message == "success")
            {
                
                this.setState({
                  show:true,
                  basicTitle:"Successfully update status",
                  basicType:"success"  
                })
                var paras  = {
                    dept_code:this.state.dept_code,
                    startDate:this.state.startDate,
                    endDate:this.state.endDate
                }
                this.onLoadTrucksAvailable(paras)
                var formdata = {
                    plant_codes:[this.state.emptyplant.value],
                    radius:100,
                    request_type:3,
                    dept_code:this.state.dept_code
                }
                this.submitEmptyFormHandler(formdata);
                this.onLoadEmptyTrucksAvailable({dept_code:this.state.dept_code})

            }
            else if(response.data.message == "truckexists")
            {
                this.setState({
                    show:true,
                    basicTitle:"Data already exists",
                    basicType:"info"  
                  })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed",
                    basicType:"danger"  
                  })
            }
            

        })
    }
        
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onClickCounter(params){
        if(params == "automark")
        {
            this.setState({
                rowData:this.state.auto_marked_by_system
            })
        }
    }
    onClickLoadedCounter(params){

        if(params == "loadautomark")
        {
            this.setState({
                rowDataLoadedTrucks:this.state.loaded_auto_marked_by_system
            })
        }
    }
    render(){
        const modalStyles  = {
            width:'1300px !important',
        }
        
        const {usermanualmodal} = this.state;

		const { open } = this.state;
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            var showopts = false
            var showdelete = false
        }
        else{
            var showopts = false
            showdelete = false
        }
        var columnDefs = [
            {
                headerName:"",
                field:"_id",
                width:50,
                hide:showopts,
                cellRendererSelector:function(params){
                    // var todayDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
                    // var tdate = moment.parseZone(new Date(todayDate)).format("YYYY-MM-DD HH:mm:ss");
                    // console.log("params tdate ", tdate)
                    // var today = moment.parseZone(tdate).format("x");
                    // console.log("params today ", today)
                    // var tentitivetime = moment.parseZone(params.data.tentitivetime).format("x")
                    // console.log("params tentitivetime ",tentitivetime)
                    // if(tentitivetime >= today)
                    // {
                        var rendComponent = {
                            component: 'edittruckavailable'
                        };
                        return rendComponent;
                    // }
                    // else
                    // {
                    //     return " ";
                    // }
                    
                }
            },
            {
                headerName:"",
                field:"_id",
                width:50,
                hide:showdelete,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'deletetruckavailable'
                    };
                    return rendComponent
                }
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140
            },
            {
                headerName:"Truck Type",
                field:"truck_type",
                width:140,
                valueGetter:function(params){
                    if(params.data.truck_type == "Others")
                    {
                        return params.data.other_trucktype;
                    }
                    else
                    {
                        return params.data.truck_type;
                    }
                }
            },
            {
                headerName:"Axle Type",
                field:"axle_type",
                width:100,
            },
            {
                headerName:"Report Truck Type",
                field:"report_truck_type",
                width:100,
            },
            {
                headerName:"Distance From Location (Kms)",
                field:"distance_from_plant_location",
                width:140
            },
            {
                headerName:"Ware House Location",
                field:"warehouse_text",
                width:140
            },
            {
                headerName:"Tentative Time",
                field:"tentitivetime",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.tentitivetime)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Last Packet Time",
                field:"last_packet_timestamp",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.last_packet_timestamp)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.last_packet_timestamp);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:140
            },
            {
                headerName:"Category",
                field:"truck_category",
                width:140
            },
            {
                headerName:"Gate In Time",
                field:"gate_in_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.gate_in_time)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.gate_out_time)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.created_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Updated On",
                field:"updated_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.updated_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
            
        ]
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var user = localStorage.getItem("user_type")
        if(user == "TRANSPORTER")
        {
            var showcol = false
        }
        else{
            var showcol = true
        }
        var emptyColumnDefs = [
			{
                headerName: "Action",
                field: "remarks_status",
                width: 150,
                hide:showcol,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'Remarksaction'
                    };
                    return rendComponent
                }
            },
            {
                headerName: "",
                field: "remarks_status",
                width: 180,
                hide:showcol,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'Repairaction'
                    };
                    return rendComponent
                }
            },
            {
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 140,
			  
			  
			}, 	        
			{
				headerName: "LSP Name",
				field: "transporter_name",
				width: 200,
					
			},
                
            
            {
				headerName: "GPS Provider",
				field: "actual_lspuser",
				width: 200,
					
			},
			
			{
				headerName: "Distance from Location (KM)",
				field: "distance_from_coordinates",
				width: 120
			},
			{
				headerName: "Last Known City/Town",
				field: "area",
				width: 150,
			},
			{
				headerName: "Last Known State",
				field: "state",
				width: 150,
			},
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				}
					
			}
			
			
        ];
        var emptyStatusColumnDefs  = [
            
            {
                headerName:"",
                field:"_id",
                width:50,
                hide:showopts,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'deletetruckavailable'
                    };
                    return rendComponent
                }
            },
            
            {
                headerName: "",
                field: "revert",
                width: 80,
                cellStyle: {fontWeight: 'bold',cursor: 'pointer'},
                valueGetter: function(params){
                    if(params.data.remarks_status == "delete")
                    {                        
                        return "Revert";
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Remarks",
                field: "remarks_status",
                width: 140
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140
            },
            {
                headerName:"Ware House Location",
                field:"warehouse_text",
                width:140
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:140
            },
            {
                headerName:"Tentative Time",
                field:"tentitivetime",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.tentitivetime)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.created_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Updated On",
                field:"updated_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.updated_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
        ]
        
        var columnLoadedTrucksDefs = [
            
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140
            },
            {
                headerName:"Truck Type",
                field:"truck_type",
                width:140,
                valueGetter:function(params){
                    if(params.data.truck_type == "Others")
                    {
                        return params.data.other_trucktype;
                    }
                    else
                    {
                        return params.data.truck_type;
                    }
                }
            },
            {
                headerName:"Axle Type",
                field:"axle_type",
                width:100,
            },
            {
                headerName:"Report Truck Type",
                field:"report_truck_type",
                width:100,
            },
            {
                headerName:"Distance From Location (Kms)",
                field:"distance_from_plant_location",
                width:140
            },
            {
                headerName:"Ware House Location",
                field:"warehouse_text",
                width:140
            },
            {
                headerName:"Tentative Time",
                field:"tentitivetime",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.tentitivetime)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.tentitivetime);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Last Packet Time",
                field:"last_packet_timestamp",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.last_packet_timestamp)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.last_packet_timestamp);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:140
            },
            {
                headerName:"Category",
                field:"truck_category",
                width:140
            },
            {
                headerName:"Gate In Time",
                field:"gate_in_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.gate_in_time)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.gate_out_time)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.created_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.created_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Updated On",
                field:"updated_on",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.updated_on)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.updated_on);
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            }
            
        ]
		return(
			<div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <button type="button" className={"btn "+(this.state.emptytabstatusactive)} onClick={this.onClickShowTabView.bind(this,"emptystatus")}>Empty Near Plant</button>
                        <button type="button" className={"btn "+(this.state.marktabactive)} onClick={this.onClickShowTabView.bind(this,"marked")}>Marked for Arrival</button>
                        <button type="button" className={"btn "+(this.state.emptytabactive)} onClick={this.onClickShowTabView.bind(this,"empty")} style={{marginLeft:"20px"}}>Marked for Repair &amp; Delete</button>
                        <button type="button" className={"btn "+(this.state.loadtruckactive)} onClick={this.onClickShowTabView.bind(this,"loadtruck")} style={{}}>Loaded Trucks</button>
                    
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.markedshow)}>
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
                                        <Datetime
                                        value={this.state.startDate} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										onChange={this.handlerStartDateTime.bind(this)} />
										
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>
                                        <Datetime 
                                        value={this.state.endDate} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										onChange={this.handlerEndDateTime.bind(this)} />
										
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.onClickFilterData.bind(this)}>Submit</button>
									</div>
									<div className="col-xl-3 col-lg-3 form-group">
                                        {(this.state.markedshow == "show-m")?
                                            <div className="col cirlce-d cursorPointer" style={{textAlign:"center",padding:"5px 0px 0px 0px", background:"#e6e6e6"}} onClick={this.onClickCounter.bind(this,"automark")}>
                                                Auto Marked By System
                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.auto_marked_by_system.length > 0)?this.state.auto_marked_by_system.length:0}/></span></h4>
                                            </div>
                                        :""}
                                        
										
									</div>
								</div>
							</div>
							
						 </div>
					</div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.markedshow)}>
                        <div className="card">
                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Marked For Availability</span>
                                    {(localStorage.getItem("user_type") == "TRANSPORTER")?
                                    <span>
                                        <span className="float-right f12">
                                            <button type="button" onClick={this.onClickShowAvailabilityForm.bind(this)} className="btn btn-warning">Add Truck Availability</button>
                                        </span>

                                        {/* <span className="float-right f12">
                                            <button
                                                type="button" 
                                                title="Bulk Upload"
                                                onClick={this.onShowBulkUpload.bind(this)}
                                                className={"btn se-ico btn-success ml-5px "}
                                                type="button">
                                                Bulk Update
                                            </button>
                                        </span> */}
                                    </span>
                                    :""}
                                   
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                            {(localStorage.getItem("user_type") != "TRANSPORTER")?
                              
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <TruckGrid
                                                columns={this.state.columns}
                                                rowdefs={this.state.rowdefs}
                                                pinnedRegionBottomRowData={this.state.pinnedRegionBottomRowData}
                                            />
                                        </div>
                                    </div>
                                    :""}
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>

                    {/* Empty Near Plants */}
                    
                    <div className={"col-xl-12 col-lg-12 "+(this.state.emptyshow)}>
                        <div className="card">
                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Empty Near Plants</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                           
                                <div className="row">
                                    <div id="myGrid1" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={emptyStatusColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowStatusData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            headerHeight={this.state.headerHeight}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            onCellClicked={this.markedForDeleteClicked}
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* END */}

                    {/* Update Empty Near Plants */}
                    <div className={"col-xl-12 col-lg-12 "+(this.state.emptystatusshow)}>
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>Select Plant</label>
                                        <Select 
                                            placeholder="Plant"  
                                            //isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.emptyplant} 
                                            className="border-radius-0" 
                                            onChange={this.changePlantCode.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.selectOptionsItems()} required
                                        />
										
									</div>

									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.formEmptyHandler.bind(this)}>Submit</button>
									</div>
									
								</div>
							</div>
							
						 </div>
					</div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.emptystatusshow)}>
                        <div className="card">
                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Update Empty Near Plants</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                           
                                <div className="row">
                                    <div id="myGrid2" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={emptyColumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.emptyrowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.emptyframeworkcomponent}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            headerHeight={this.state.headerHeight}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* END */}

                    {/* Loaded Trucks Start */}
                    <div className={"col-xl-12 col-lg-12 "+(this.state.loadtruckshow)}>
						<div className=" card">                            
							<div className="row card-body" style={{padding:"10px 30px"}}>
						 
							    <div className="row col-xl-12 col-lg-12">
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
                                        <Datetime
                                        value={this.state.startDateLoad} 
										disableCloseOnClickOutside={false} 
										closeOnSelect={true} 
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD" 
										name="startDate" 
										onChange={this.handlerStartDateTimeLoad.bind(this)} />
										
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>
                                        <Datetime 
                                        value={this.state.endDateLoad} 
										disableCloseOnClickOutside={false}
										closeOnSelect={true} 
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
										dateFormat="YYYY-MM-DD" 
										onChange={this.handlerEndDateTimeLoad.bind(this)} />
										
									</div>
									<div className="form-group col-xl-3 col-lg-3">
										<button type="button" className="mt-30p btn btn-success" onClick={this.onClickLoadedTruckFilterData.bind(this)}>Submit</button>
									</div>
									<div className="col-xl-3 col-lg-3 form-group">
                                       
                                        {(this.state.loadtruckshow == "show-m")?
                                            <div className="col cirlce-d cursorPointer" style={{textAlign:"center",padding:"5px 0px 0px 0px", background:"#e6e6e6"}} onClick={this.onClickLoadedCounter.bind(this,"loadautomark")}>
                                                 Auto Marked By System
                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.loaded_auto_marked_by_system.length > 0)?this.state.loaded_auto_marked_by_system.length:0}/></span></h4>
                                            </div>
                                        :""}
                                        {/*  */}
										
									</div>
								</div>
							</div>
							
						 </div>
					</div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.loadtruckshow)}>
                        <div className="card">
                            
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Loaded Trucks</span>
                                    {(localStorage.getItem("user_type") == "TRANSPORTER")?
                                    <span>
                                        <span className="float-right f12">
                                            {/* <button type="button" onClick={this.onClickShowAvailabilityForm.bind(this)} className="btn btn-warning">Add Truck Availability</button> */}
                                        </span>

                                        {/* <span className="float-right f12">
                                            <button
                                                type="button" 
                                                title="Bulk Upload"
                                                onClick={this.onShowBulkUpload.bind(this)}
                                                className={"btn se-ico btn-success ml-5px "}
                                                type="button">
                                                Bulk Update
                                            </button>
                                        </span> */}
                                    </span>
                                    :""}
                                   
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                            {/* {(localStorage.getItem("user_type") != "TRANSPORTER")?
                              
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <TruckGrid
                                                columns={this.state.columns}
                                                rowdefs={this.state.rowloadedtruckdefs}
                                                pinnedRegionBottomRowData={this.state.pinnedRegionBottomRowData}
                                            />
                                        </div>
                                        
                                        
                                    </div>
                                    :""} */}
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnLoadedTrucksDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowDataLoadedTrucks}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            
                                        />
                                        
                                       
                                    </div> 
                                   
                                       
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* End */}
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                <div className={"slide-r "+(this.state.slidertranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Truck Availability
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} className="row col-xl-12 col-lg-12">
                            
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Truck No: * </label>
                                {/* <Select 
                                    placeholder={""} 
                                    //  isMulti={true}
                                    closeMenuOnSelect={true} 
                                    value={this.state.truck} 
                                    className="border-radius-0" 
                                    onChange={this.setConsignmentValue.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.truckslist}
                                    value={this.state.truck}
                                     required
                                /> */}
                                
                                <CreatableSelect
                                   // isMulti={true}
                                   placeholder="Truck No"
                                    isClearable
                                    id="truckid"
                                    onChange={this.setConsignmentValue.bind(this)}
                                    // onInputChange={this.setConsignmentValue.bind(this)}
                                    options={this.state.truckslist}
                                    value={this.state.truck}
                                />
                            </div>
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Select Category: * </label>
                                <Select 
                                    placeholder="Category"
                                    //isMulti={true}
                                    closeMenuOnSelect={true} 
                                    value={this.state.truckcategory} 
                                    className="border-radius-0" 
                                    onChange={this.changeCategoryHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.truckcategoryoptions()} required
                                />
                            </div>
                            <div className="form-group row col-xl-12 col-lg-12">
                                <label className="col-xl-12 col-lg-12">Truck Type: * </label>
                                {/* <input 
                                    type="text" 
                                    placeholder="Ex: 20 ft"
                                    name="trucktype"
                                    onChange={this.changeHandler.bind(this)}
                                    value={this.state.trucktype}
                                    className="form-control"
                                /> */}

                                {/* <Select 
                                    placeholder="Truck Type"  
                                    //isMulti={true}
                                    closeMenuOnSelect={true} 
                                    value={this.state.trucktype} 
                                    className="border-radius-0" 
                                    onChange={this.changeTruckTypeHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.trucktypeoptions()} required
                                />
                                <input 
                                    type="text"
                                    name="othertrucktype"
                                    className={"form-control "+(this.state.trucktypeother)}
                                    value={this.state.othertrucktype}
                                    onChange={this.changeHandler.bind(this)}
                                /> */}
                                <div className="col-xl-1 col-lg-1"></div>
                                <input 
                                    type="number"
                                    min="10"
                                    max="50"
                                    name="trucktype"
                                    className={"form-control col-xl-8 col-lg-8"}
                                    value={this.state.trucktype}
                                    onChange={this.changeHandler.bind(this)}
                                    required
                                />
                                <h5 className='col-xl-2 col-lg-2'> ft</h5>

                            </div>

                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Select Ware House: * </label>
                                <Select 
                                    placeholder={"Ware House"}  
                                    //isMulti={true}
                                    closeMenuOnSelect={true} 
                                    value={this.state.warehouse} 
                                    className="border-radius-0" 
                                    onChange={this.changeWarehouseHandler.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.warehouseoptions()} required
                                />
                            </div>
                            <div className="form-group col-xl-12 col-lg-12">
                                <label>Tentative time of placement: * </label>
                                {/* <Datetime value={this.state.tentitivetime} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: '', name: 'tentitivetime', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="tentitivetime" 
                                    onChange={this.handlerStartDateTime.bind(this)} 
                                />
                                     */}
                                {/* <input 
                                type="text" 
                                placeholder="" 
                                autoComplete="off" 
                                id="tentitivetime"
                                 value={this.state.tentitivetime}  
                                 className="datetimepicker_mask form-control" 
                                 onChange={this.handlerStartDateTime.bind(this)} 
                                 required /> */}
                                <input
                                 type="text"
                                placeholder="Tentitive Time "
                                id="tentitivetime"
                                name="tentitivetime"
                              //  onChange={this.handlerStartDateTime.bind(this)} 
                                className="datetimepicker_mask form-control" required />
							
                            </div>
                            <div className="form-group col-xl-3 col-lg-3">
                                <button style={{marginTop:"30px"}} type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            

                {/* Bulk Upload */}
                

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Upload</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_Trucks_Availability_sample.csv')} target="_blank">Sample Template</a>
                                 <div className="col-xl-12 col-lg-12 mt-20p" >Tentitive Time format should be <span style={{color:"red", fontSize:"13px"}}>MM/DD/YYYY HH:mm:ss</span></div>
							</div>		
							
						</div>
					</div>
				</div>

            
            
                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                        <h5 className="model-title">Truck Availability</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen helps user in identifying vehicles of various transporters that are near the point of origin and can be readily used for loading in MSIL as per requirement. This feature is available only for PRT dept as per the requirement raised.
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                A truck can be marked in following ways by transporters
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        1. Empty Near plant
                                    </li>
                                    <li>
                                        2. Marked for Arrival
                                    </li>
                                    <li>
                                        3. Marked for Repair
                                    </li>
                                </ul>
                            </div>
                            <h6 style={{fontWeight:"bold"}}>Empty Near plant</h6>
                            <div className="col-xl-12 col-lg-12">
                                Vehicles that are marked as empty near plant are those vehicles which are not carrying any MSIL consignments and are in the vicinity of approx. 100 kms. To check the list of empty vehicles following process is to be followed
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Empty Near Plant”</li>
                                    <li>
                                        2. Select the plant for which details are required
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking the submit button the use will get the details of various transporters whose vehicles are empty near selected plant in the format provided below
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Arrival</h6>
                            <div className="col-xl-12 col-lg-12">
                                This screen provides details for all those vehicles which are available for arrival in MSIL and is ready for taking next load from various MSIL locations. To check vehicles that are marked for arrival are:
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>1. Select the option “Marked for arrival”</li>
                                    <li>
                                        2. Select the Time frame for which  data is required ( by default it is for current date)
                                    </li>
                                    <li>
                                        3. Click on submit button
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                After clicking on Submit button user will be able to check the list of vehicles transporter wise and its availability for MSIL near the various MSIL locations
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>

                            <h6 style={{fontWeight:"bold"}}>Marked for Repair</h6>
                            <div className="col-xl-12 col-lg-12">
                            If a transporter marks any vehicle under “marked for repair”, those vehicles will be listed here and will be exempted from getting any new load until marked fit from transporter’s end. One can check list of vehicles that are marked as marked for repair by clicking option Marked for repair. The user will get the list of vehicles in following manner    
                            </div>
                           
                        </div>
                    </div>
                </Modal>    


            </div>
    
              	
		);
	}
}

window.onload = function (){

    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };


	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
    var currentTime = new Date().getTime();
    var todayDate = new Date().getDate();
    var logic = function( currentDateTime ){
        // console.log("currentDateTime ", new Date(currentDateTime).getDate())
        // console.log("todayDate ", todayDate)
        var clickdate = new Date(currentDateTime).getDate()
        if( clickdate == todayDate ){
          this.setOptions({
            minTime:currentTime,
            timepickerScrollbar: true,
            scrollInput:true
          });
        }
        else
        {
            this.setOptions({
                minTime:"00:00",
                timepickerScrollbar: true,
                scrollInput:true
            });
        }
          
      };

    var todayDate = new Date().getDate();
	$('#tentitivetime').datetimepicker({
		mask:'9999-19-39 29:59',
        format:'Y-m-d H:i',
        formatTime:"H:i A",
        minTime:currentTime,
        onChangeDateTime:logic,
        onShow:logic,
        minDate : new Date(new Date().setDate(todayDate)),
        maxDate: new Date(new Date().setDate(todayDate + 2)),
        startDate:new Date(new Date().setDate(todayDate)),
        timepickerScrollbar: true,
        scrollInput:true
	});
	
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:localStorage.getItem("token"),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}
$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
