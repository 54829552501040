import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import CustomDateComponent from "../common/customdatepicker";
import UpdateCustomerPOD from "./updatecustommerpod";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class AMNSCustomerEPODShipments extends Component{

	constructor(props){
		super(props);
		this.state={
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				customDateComponent:CustomDateComponent,
                UpdateCustomerPOD:UpdateCustomerPOD
			},
            animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				
            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			
		}
		
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
	}
	componentDidMount = async () => {
        var shipment_id = this.props.match.params.shipment_id;
        this.setState({
            shipment_id:shipment_id
        })
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            var customer_code = localStorage.getItem("customer_code");
        }
        else{
            var customer_code = "";
        }
        var parameters = {
            shipment_id:shipment_id,
            customer_code:customer_code
        }
        this.onLoadGetShipments(parameters);
       
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/amns/customershipmentitems",parameters).then(async (response) =>{
            console.log("response ", response.data);
            var shipments = response.data.shipments;
            this.setState({
                rowData:shipments,
                loadshow:"show-n"
            })
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
	onCellUpdateData = (params) => {
        // console.log("params ", params)
	}
    onClickUpdateItem(params){
        // console.log("params ", params)
        redirectURL.post("/amns/updatelrno",params.data)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger"
                })
            }
        })
    }
	render(){
		var columnwithDefs = [
            {
                headerName:Constant.COL_CONSIGNMENT_CODE,
                field:"consignment_code",
                width:120,
            },
            {
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
            },
           
            {
                headerName:Constant.COL_UPDATE_LRNO,
                field:"lr_no",
                width:150,
                editable:true,
            },
            {
                headerName:Constant.COL_GSTNO,
                field:"customer_pod_gst_no",
                width:150,
                editable:true,
            },
            
            {
                headerName:"",
                field:"",
                width:100,
                editable:false,
                cellRendererSelector:function(params){
                    return { 
                        component: 'UpdateCustomerPOD'
                    };
                },
            },
            
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>EPOD - Shipment #{this.state.shipment_id} - Batches</span>
                                    <a href={"/viewshipments"} className="float-right btn btn-warning">Back to list</a>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                   
                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
                                    <div className="col-xl-12 col-lg-12">
                                        

                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                components={this.state.components}
                                                frameworkComponents={this.state.frameworkComponents}
                                
                                                //floatingFilter={true}
                                                onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                                //onCellClicked={this.popmarker}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                            />
                                        </div>
                                        {/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
                                    </div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
                 <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}