import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import CountUp from 'react-countup';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils'; 
    
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import LoadMapAction from "./loadMapAction";
import LoadVinLegs from "./loadvinlegs";
import DrawMap from "../common/drawmap";
import CommentLoadAttach from "./commentloadattach";
import CommentTransporterLoad from "./commenttransporterload";
import CommentTransporterLoadApproval from "./commenttransporterloadapproval";
import CommentLoadReplayAttach from "./commentloadreplayattach";
import LoadComments from "./loadcommentsaction";
import Counters from "./loadattachmentcounters";
import LoadGpsAction from "./loadgpsaction";
import { quantity } from 'chartist';

$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class LoadAttachment extends Component {
    constructor(props){
		super(props);
		this.state={
			modules: AllModules,
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot : true,
                enableValue: true,
                enableRowGroup: true,
            },
            originalRowData: null,
            rowData: null,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                loadMapAction:LoadMapAction,
                loadVinLegs:LoadVinLegs,
                commentloadattach:CommentLoadAttach,
                loadcomments:LoadComments,
                commenttransporterload:CommentTransporterLoad,
                commenttransporterloadapproval:CommentTransporterLoadApproval,
                commentloadreplayattach:CommentLoadReplayAttach,
                loadGpsAction:LoadGpsAction
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            excelStyles : [
				{
					id : "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
            detailCellRendererParams:{},
            dept_code:'',
            startDate:"",
			endDate:"",
			defaultsdate:"",
            defaultedate:"",
            totalLoads:[],
            retrivalyettostart:[],
            retrivalinprogress:[],
            retrivalcomplete:[],
            gatein:[],
            gateout:[],
            invoiced:[],
            totalLoadscnt:[],
            retrivalyettostartcnt:[],
            retrivalinprogresscnt:[],
            retrivalcompletecnt:[],
            invoicedcnt:[],
            gateincnt:[],
            gateoutcnt:[],
            mapinfo:'',
            maptruckno:'',
            rownode:[],
            routeTruck:{},
            totalloadscars:0,
            yettostartcars:0,
            inprogresscars:0,
            invoicedcars:0,
            completedcars:0,
            gateincars:0,
            gateoutcars:0,
            pendinginvoice:[],
            pendinginvoicecars:0,
            pendinginvoicecnt:0,
            consigner:[{"value":"all", "label":"All"}],
            transportercode:0,
            commentrownode:[],
            comment:'',
            sliderRouteTranslate:'',
            sliderRouteComment:'',
            delivermodal: false,
            transmodal:false,
            rowClassRules: {
				"yellow defaultclr":"data.notify_tpt == 1 && data.tpt_approval == 0",
				"green defaultclr":"data.notify_tpt == 1 && data.tpt_approval == 1",
				"red defaultclr":"data.notify_tpt == 1 && data.tpt_approval == 2",
				"yellow defaultclr":"data.notify_plant == 1 && data.plant_approval == 0",
				"green defaultclr":"data.notify_plant == 1 && data.plant_approval == 1",
				"red defaultclr":"data.notify_plant == 1 && data.plant_approval == 2"
            },
            platoption:'',
            plantreplyoption:'',
            transoption:'',
            transreplyoption:'',
            pothershow:'show-n',
            prothershow:'show-n',
            taothershow:'show-n',
            trothershow:'show-n',
            plantreason:"",
            plantreplyreason:"",
            transreplyacceptreason:"",
            transreplyrejectreason:"",
            pendingtpt:[],
            pendingtptcnt:0,
            pendingplant:[],
            pendingplantcnt:0,
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"",
            eventGridAction: "gridAction",
            tolls : [],
            dispatch_type: {label:"ALL",vaue:"ALL"}
        }
        this.onLoadShowLoadAttachment = this.onLoadShowLoadAttachment.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onShowVinLegs = this.onShowVinLegs.bind(this);
        this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
        this.onShowCommentLoadAttachment = this.onShowCommentLoadAttachment.bind(this);
        this.onShowTransporterCommentLoad = this.onShowTransporterCommentLoad.bind(this);
        this.onShowTransporterApprovalCommentLoad = this.onShowTransporterApprovalCommentLoad.bind(this);
        this.onShowUserLoadAttachmentComments = this.onShowUserLoadAttachmentComments.bind(this);
        this.refreshGridCell = this.refreshGridCell.bind(this);
        this.onShowPlantReplyCommentLoad = this.onShowPlantReplyCommentLoad.bind(this);



    }
    componentDidMount = async () => {
        loadDateTimeScript()
        var dept_code="SNDG"
        
        var edate = moment.parseZone().format('DD-MM-YYYY 23:59:59');
		var sdate = moment.parseZone().subtract(2, 'days').format('DD-MM-YYYY 00:00:00');
        //var sdate = "2020-07-25"
        var pagetitle = "Load Exits"
        if(this.props.match.path == '/railexitloadattachment')
        {
            var pagetitle = "Rail Load Exits"
        }
        else
        {
            var pagetitle = "Load Exits"
        }
        console.log(sdate, edate)
        this.setState({
            dept_code:dept_code,
            startDate:sdate,
			endDate:edate,
			defaultsdate:sdate,
            defaultedate:edate,
            sliderRouteTranslate:"",
            pagetitle:pagetitle
        });
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        try{

            var transportcode;
            if(typeof localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == "undefined" )
            {
                
                var transportcode=0
                var parameters = {
                    dept_code:dept_code,
                    startdate: moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD 00:00:00'),
                    enddate: moment.parseZone().format('YYYY-MM-DD 23:59:59'),
                    consigner_code:["all"],
                    transportercode:transportcode
                }
                //console.log("transportcode ", transportcode)
                await  this.setState({
                    transportercode:transportcode
                })
                await this.onLoadShowLoadAttachment(parameters);
            }
            else{
                //console.log("Trans ",localStorage.getItem("transportercode"))
                var transportcode=localStorage.getItem("transportercode");
                var parameters = {
                    dept_code:dept_code,
                    startdate: moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD 00:00:00'),
                     enddate: moment.parseZone().format('YYYY-MM-DD 23:59:59'),
                    consigner_code:["all"],
                    transportercode:transportcode
    
                }
                await this.setState({
                    transportercode:transportcode
                })
                await this.onLoadShowLoadAttachment(parameters);
                
            }
        
           
           
        }
        catch(e){
            var transportcode=0
            var parameters = {
                dept_code:dept_code,
                startdate: moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD 00:00:00'),
                enddate: moment.parseZone().format('YYYY-MM-DD 23:59:59'),
                consigner_code:["all"],

            }
            //console.log("Catch Error transportcode ", transportcode)
            await  this.onLoadShowLoadAttachment(parameters);
        }

    }


    onLoadShowLoadAttachment(parameters)
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m'
        });
        //console.log("transportercode ",this.state.transportercode)
        if(this.props.match.path == '/railexitloadattachment')
        {
            if(this.state.transportercode == 0)
            {
                var urlpath = "/consignments/exitrailloadattach";
            }
            else{
                var urlpath = "/consignments/transporterexitrailloadattach";
            }
        }
        else
        {
            if(this.state.transportercode == 0)
            {
                var urlpath = "/consignments/exitloadattach";
            }
            else{
                var urlpath = "/consignments/transporterexitloadattach";
            }
        }

        

        redirectURL.post(urlpath,parameters)
        .then((response) => {
            var records = response.data;
            //console.log("records ", JSON.stringify(records))
            //var counters = records.counters;
            //console.log("counters", counters)
            // var retrivalyettostart = counters.yettostart;
            // var retrivalinprogress = counters.inprogress;
            // var retrivalcomplete = counters.completed;
            // var gatein = counters.gatein;
            var totalrecords = records.loaddata;

            var retrivalyettostart = [];
            var retrivalinprogress = [];
            var retrivalcomplete = [];
            var invoiced = [];
            var gatein = []
            var gateout = []
            var totalloadscars=0;
            var yettostartcars=0;
            var inprogresscars=0;
            var invoicedcars=0;
            var completedcars=0;
            var gateincars=0;
            var gateoutcars=0;
            var pendinginvoice=[];
            var pendinginvoicecars=0;
            var pendingtpt = [];
            var pendingplant = [];
            try{
                if(totalrecords.length > 0)
                {
                    
                    totalrecords.map((item) => {
                        totalloadscars=totalloadscars+item.quantity;

                        if(typeof item.retrival_started == 'undefined' || item.retrival_started == 0)
                        {
                            retrivalyettostart.push(item);
                            yettostartcars=yettostartcars+item.quantity;
                        
                        }
                        if(item.retrival_started == 1 && item.retrival_completed == 0)
                        {
                            retrivalinprogress.push(item);
                            inprogresscars=inprogresscars+item.quantity;
                        }
                        if(item.retrival_completed == 1)
                        {
                            retrivalcomplete.push(item);
                            completedcars=completedcars+item.quantity;
                        } 
                        if(typeof item.gate_in_time == undefined || item.gate_in_time == "1970-01-01T00:00:00.000Z")
                        {

                        }
                        else{
                            gatein.push(item);
                            gateincars=gateincars+item.quantity;
                        }
                        if(typeof item.gate_out_time == undefined || item.gate_out_time == "1970-01-01T00:00:00.000Z")
                        {

                        }
                        else{
                            gateout.push(item);
                            gateoutcars=gateoutcars+item.quantity;
                        }

                        if(item.invoiced == 1)
                        {
                            invoiced.push(item);
                            invoicedcars=invoicedcars+item.quantity;
                        } 
                        if(typeof item.invoiced == undefined ||  item.invoiced == undefined)
                        {
                            pendinginvoice.push(item);
                            pendinginvoicecars=pendinginvoicecars+item.quantity;
                        }
                        else
                        {
                            
                        }
                        if(item.notify_tpt == 1 && item.tpt_approval == 0)
                        {
                            pendingtpt.push(item);
                        }

                        if(item.notify_plant == 1 && item.plant_approval == 0)
                        {
                            pendingplant.push(item);
                        }

                    })
                }
            }
            catch(e){

            }

           // console.log("pendingtptcnt ", pendingtpt)
            // if(totalrecords.length > 0)
            // {
            //     if(this.state.startDate == '' && this.state.endDate == '')
            //     {
            //         var edate = moment.parseZone().format('YYYY-MM-DD');
            //         var sdate = moment.parseZone(totalrecords[0].load_attach_date).format('YYYY-MM-DD');
                    
            //         this.setState({
            //             defaultsdate:sdate,
            //             defaultedate:edate
            //         });
            //     }
            //     else{
            //         var edate = moment.parseZone().format('YYYY-MM-DD');
            //         var sdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD');
            //     }
                
            // }
            // else{
            //     var edate = "";
            //     var sdate = "";
                
            // }
            
           // console.log("edate ", edate)
            //console.log("sdate ", sdate)
            var totrcords = totalrecords.sort(GetSortDescOrder("_id"));

            this.setState({
               //startDate:this.state,
               // endDate:edate,
                originalRowData:totalrecords,
                rowData:totrcords,
                totalLoads:totalrecords,
                totalLoadscnt:totalrecords.length,
                retrivalyettostart:retrivalyettostart,
                retrivalyettostartcnt:retrivalyettostart.length,
                retrivalinprogress:retrivalinprogress,
                retrivalinprogresscnt:retrivalinprogress.length,
                retrivalcomplete:retrivalcomplete,
                retrivalcompletecnt:retrivalcomplete.length,
                gatein:gatein,
                gateincnt:gatein.length,
                gateout:gateout,
                gateoutcnt:gateout.length,
                invoiced:invoiced,
                invoicedcnt:invoiced.length,
                totalloadscars:totalloadscars,
                yettostartcars:yettostartcars,
                inprogresscars:inprogresscars,
                invoicedcars:invoicedcars,
                completedcars:completedcars,
                gateincars:gateincars,
                gateoutcars:gateoutcars,
                pendinginvoice:pendinginvoice,
                pendinginvoicecnt:pendinginvoice.length,
                pendinginvoicecars:pendinginvoicecars,
                loadshow:'show-n',
                overly:'show-n',
                pendingtpt:pendingtpt,
                pendingplant:pendingplant,
                pendingtptcnt:pendingtpt.length,
                pendingplantcnt:pendingplant.length
            });
        })
        .catch(function(e){
            console.log("Error ", e)
        })
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        console.log(d);
        console.log(event)
    	// var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate: event
    	});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        // var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate: d
    	});
    }
    onSubmitFilter = async () => {
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        //console.log("consiger ", this.state.consigner)
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sdate = this.state.defaultsdate;
		}
		else{
			var sdate = this.state.startDate+":00";
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var edate = this.state.defaultedate;
		}
		else{
			var edate = this.state.endDate+":59";
        }
        
        var consigners = []

        if(this.state.consigner == null)
        {
            consigners.push("all")
        }
        else
        {
            var clist= this.state.consigner;
            clist.map((item) => {
                consigners.push(item.value)
            })
        }
        //console.log("selected consigners ", consigners)
        
        // if(this.state.startDate == "NaN-NaN-NaN")
        // {
        //     var sdate =""
        // }
        // else
        // {
        //     sdate = this.state.startDate
        // }
        // if(this.state.endDate == "NaN-NaN-NaN")
        // {
        //     var edate =""
        // }
        // else
        // {
        //     edate = this.state.endDate
        // }
        sdate = sdate.split(" ")[0].split("-")[2]+"-"+sdate.split(" ")[0].split("-")[1]+"-"+sdate.split(" ")[0].split("-")[0]+" "+sdate.split(" ")[1];
        edate = edate.split(" ")[0].split("-")[2]+"-"+edate.split(" ")[0].split("-")[1]+"-"+edate.split(" ")[0].split("-")[0]+" "+edate.split(" ")[1];
        // sdate = moment.parseZone(new Date(sdate)).format("YYYY-MM-DD HH:MM:SS");
        // edate = moment.parseZone(new Date(edate)).format("YYYY-MM-DD HH:MM:SS");
        var requestparams = {
			dept_code: this.state.dept_code,
			startdate: sdate,
            enddate: edate,
            consigner_code:consigners,
            transportercode:this.state.transportercode,
            dispatch_type : this.state.dispatch_type.value
        }
        var defStartDate = moment.parseZone(new Date("2021-02-01 00:00:00")).utcOffset("+05:30")._d;
        if(defStartDate > sdate || defStartDate > edate)
        {
            this.setState({
                basicTitle: "Start Date must not be less than February.",
                basicType: "warning",
                show: true,
                overly: "show-n",
                loadshow: "show-n"
            })   
        }
        else
        {
            this.onLoadShowLoadAttachment(requestparams)
        }
    }


	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	  
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}


    onShowRouteDiv = (e) => {
        console.log("E ",e)
        this.setState({
			overly:'show-m',
			loadshow:'show-m',
		});
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var sdate = getHyphenYYYYMMDDHHMMSS(e.load_attach_date);
        //console.log("S1",e.gate_out_time)
        if(typeof e.gate_out_time == 'undefined' || e.gate_out_time == "" || e.gate_out_time == "undefined")
        {
            //console.log("S2")
            var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        }
        else{
            //console.log("S3")
            var edate = moment.parseZone(new Date(e.gate_out_time)).format("YYYY-MM-DD HH:mm:ss")
        }
        //console.log("Edata ", edate)

        var parameters = {
            from_date:sdate,
            to_date:edate,
            truck_no:e.truck_no,
            load_no:e.load_no
        }
        if(e.truck_no != "" && e.load_no != "" && e.truck_no != null)
        {
            redirectURL.post("/consignments/loadmaproutes",parameters)
            .then((response) => {
                //console.log("Map Routes ", response.data)
                try{
                    if(response.data.status == "failure")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"No data available",
                            basicType:"danger",
                            overly:'show-n',
			                loadshow:'show-n'
                        });
                    }
                    else
                    {
                        this.setState({
                            //loadshow:'show-m',
                            maptruckno:e.truck_no,
                            sliderRouteTranslate:"slider-translate-60p",
                            showDiv:'show-m',
                            overly:'show-m',
                            mapinfo:response.data,
                            routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
                            rownode:e,
                            loadshow:"show-n",
                            tolls : response.data.tolls
                        });
                    }
                }
                catch(e){
                    this.setState({
                        show:true,
                        basicTitle:"No data available",
                        basicType:"danger",
                        overly:'show-n',
                        loadshow:'show-n'
                    });
                } 
               
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
        else{
            this.setState({
				show:true,
				basicTitle:"No data available",
                basicType:"danger",
                overly:'show-n',
                loadshow:'show-n'
			});
        }
        
		
    }

    onShowVinLegs = async (e) => {
        //console.log("VIN Legs ",e)
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": "Show legs",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        

        await this.setState({
						
            detailCellRendererParams:{
                suppressRefresh: true,
                detailGridOptions: {
                    headerHeight:50,
                    columnDefs: [
                        {
                            headerName:"Vin No",
                            field:"vin",
                            width:120,
                        },
                        {
                            headerName:"Club Load No",
                            field:"club_load_no",
                            width:100,
                        },
                       
                        {
                            headerName:"Consignment Code",
                            field:"consignment_code",
                            width:100,
                        },
                        {
                            headerName:"SBVH",
                            field:"sbvh",
                            width:100,
                        },
                        {
                            headerName:"LPD Date",
                            field:"lpd_date",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.lpd_date);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Retrival Start Date",
                            field:"retrival_start_date",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.retrival_start_date);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Retrival End Date",
                            field:"retrival_end_date",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.retrival_end_date);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Invoice No",
                            field:"invoice_no",
                            width:120,
                        },
                        {
                            headerName:"Invoice Date",
                            field:"invoice_date",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.invoice_date);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Gate In Time",
                            field:"gate_in_time",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.gate_in_time);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Gate Out Time",
                            field:"gate_out_time",
                            width:170,
                            valueGetter:function(params){
                                try{
                                    return getDDMMMYYYYHHMMDefault(params.data.gate_out_time);
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        {
                            headerName:"Standart Transit Time",
                            field:"standart_transit_time",
                            width:100,
                        },
                        {
                            headerName:"Trip Transit Time",
                            field:"trip_transit_time",
                            width:100,
                        },
                        {
                            headerName:"Cluster",
                            field:"cluster",
                            width:100,
                        }
                    ],
                    overlayNoRowsTemplate: 'No rows to show',						
                },
                getDetailRowData: function(params) {
                    var parameters={
                        load_no:params.data.load_no
                    }
                    redirectURL.post("/consignments/loadvinlegs",parameters)
                        .then((response) => {
                            console.log("Legs ", response.data)
                            params.successCallback(response.data);
                        })
                        .catch(function(e){
                            console.log("Error ",e)
                        })

                    }
                }
            });
            if(e.column.colDef.field == 'load_no')
            {
                e.node.setExpanded(!e.node.expanded);
                //console.log("expanded ",params.node.expanded)
                if(e.node.expanded == false)
                {
                    //transitedelaydata=[]
                }
            }
            else{

                e.node.setExpanded(false);
            }
    }


	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ delivermodal: false,
            overly:'show-n',
            loadshow:'show-n',
            transmodal:false,
            transreplymodal:false,
            plantreplymodal:false,
            pothershow:'show-n',
            plantoption:''
            
         });
	};
    closeAlert = () => {
        this.setState({
            show: false,
            overly:'show-n',
            loadshow:'show-n'
        });
	}
    onClickCounterShowData(params){
		//console.log(" params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		
		if(params == "0")
		{
			this.gridApi.setRowData(this.state.totalLoads);
			this.gridApi.onFilterChanged();
        }
		if(params == "1")
		{
			this.gridApi.setRowData(this.state.retrivalyettostart);
			this.gridApi.onFilterChanged();
        }

		if(params == "2")
		{
			this.gridApi.setRowData(this.state.retrivalinprogress);
			this.gridApi.onFilterChanged();
        }

		if(params == "3")
		{
			this.gridApi.setRowData(this.state.retrivalcomplete);
			this.gridApi.onFilterChanged();
        }

		if(params == "4")
		{
			this.gridApi.setRowData(this.state.gatein);
			this.gridApi.onFilterChanged();
        }
		if(params == "5")
		{
			this.gridApi.setRowData(this.state.invoiced);
			this.gridApi.onFilterChanged();
        }
		if(params == "6")
		{
			this.gridApi.setRowData(this.state.pendinginvoice);
			this.gridApi.onFilterChanged();
        }
		if(params == "7")
		{
			this.gridApi.setRowData(this.state.gateout);
			this.gridApi.onFilterChanged();
        }
		if(params == "8")
		{
			this.gridApi.setRowData(this.state.pendingplant);
			this.gridApi.onFilterChanged();
        }
		if(params == "9")
		{
			this.gridApi.setRowData(this.state.pendingtpt);
			this.gridApi.onFilterChanged();
        }
    }
    


	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.dept_code == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.dept_code == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    
    onShowCommentLoadAttachment(params){
        //console.log("Params ", params)
        this.setState({
            commentrownode:params.data,
            delivermodal:true,
            showDiv:'show-m',
            overly:'show-m',
            loadshow:"show-n"
        });
    }

    onShowPlantReplyCommentLoad(params){
    this.setState({
            commentrownode:params.data,
            plantreplymodal:true,
            showDiv:'show-m',
            overly:'show-m',
            loadshow:"show-n"
        });
    }

    onShowTransporterCommentLoad(params){
        this.setState({
            commentrownode:params.data,
            transmodal:true,
            showDiv:'show-m',
            overly:'show-m',
            loadshow:"show-n"
        });
    }

    onShowTransporterApprovalCommentLoad(params){
        this.setState({
            commentrownode:params.data,
            transreplymodal:true,
            showDiv:'show-m',
            overly:'show-m',
            loadshow:"show-n"
        });
    }

    onClickHideAll(){
		this.setState({
			uploadDivWidth:'0%',
            sliderRouteTranslate:"",
            sliderRouteComment:"",
			showDiv:'show-n',
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
            sliderRouteComment:"",
			showDiv:'show-n',
			overly:'show-n',
			loadshow:'show-n',
		});
    }
    onClickUpdateComment(e){
        e.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Plant Comment",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(this.state.plantoption.value != '')
        {
            if(this.state.plantoption.value == 'others')
            {
                var comment = this.state.plantreason
            }
            else{
                var comment = this.state.plantoption.value
            }
            var parameters = {
                comment:comment,
                rownode:this.state.commentrownode,
                userid:localStorage.getItem("userid")
            }
            redirectURL.post("/consignments/saveLoadComment", parameters)
            .then((response) => {
                console.log("respons ", response.data)
                if(response.data.ok == 1)
                {
                    this.setState({
                        commentrownode:[],
                        delivermodal:false,
                        showDiv:'show-n',
                        overly:'show-n',
                        loadshow:"show-n",
                        show:true,
                        basicTitle:"Successfuly submitted comment",
                        basicType:"success"
                    })
                }
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please choose an option from dropdown list",
                basicType:"danger"
            });
        }
        
       // console.log("form ", parameters)
    }
    onClickUpdateReplyComment(e){
        e.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Plant Reply Comment",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(this.state.plantreplyoption.value == "")
        {
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"Please choose an option"
            })
        }
        else{
            if(this.state.plantreplyoption.value == "reject-other")
            {
                var comment = this.state.plantreplyreason
                var approval = 2
            }
            else{
                var comment = this.state.plantreplyoption.value
            }
            if(this.state.plantreplyoption.value == "accept")
            {
                var approval = 1
            }

            if(this.state.plantreplyoption.value == "reject")
            {
                var approval = 2
            }

            var parameters = {
                comment:comment,
                rownode:this.state.commentrownode,
                approval:approval,
                userid:localStorage.getItem("userid")
            }
            console.log("parameters ", parameters)
            redirectURL.post("/consignments/saveLoadPlantReplyComment", parameters)
            .then((response) => {
                console.log("respons ", response.data)
                if(response.data.ok == 1)
                {
                    this.setState({
                        commentrownode:[],
                        delivermodal:false,
                        showDiv:'show-n',
                        overly:'show-n',
                        loadshow:"show-n",
                        show:true,
                        basicTitle:"Successfuly submitted comment",
                        basicType:"success"
                    })
                }
            })
            .catch(function(e){
                console.log("Error ", e)
            });
        }
       // console.log("form ", parameters)
    }
    
    onClickTransporterUpdateComment(e){
        e.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Transporter Comment",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(this.state.transoption.value == "")
        {
            this.setState({
                show:true,
                basicTitle:"Please Choose an option",
                basicType:"danger"
            });
        }
        else{
            var comment= this.state.transoption.value;
        
            var parameters = {
                comment:comment,
                rownode:this.state.commentrownode,
                userid:localStorage.getItem("userid")
            }
            redirectURL.post("/consignments/saveLoadTransportComment", parameters)
            .then((response) => {
                console.log("respons ", response.data)
                if(response.data.ok == 1)
                {
                    this.setState({
                        commentrownode:[],
                        delivermodal:false,
                        showDiv:'show-n',
                        overly:'show-n',
                        loadshow:"show-n",
                        show:true,
                        basicTitle:"Successfuly submitted comment",
                        basicType:"success"
                    })
                }
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
    }

    onClickTransporterUpdateReplyComment(e){
        e.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Transporter Reply Comment",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(this.state.transreplyoption.value == "")
        {
            this.setState({
                show:true,
                basicTitle:"Please Choose an option",
                basicType:"danger"
            });
        }
        else{
            if(this.state.transreplyoption.value == 'accept-other')
            {
                var comment = this.state.transreplyacceptreason;
                var approvalstatus = 1
            }
            else if(this.state.transreplyoption.value == 'reject-other')
            {
                var comment = this.state.transreplyrejectreason
                var approvalstatus = 2
            }
            else{
                var comment = this.state.transreplyoption.value
            }
            if(this.state.transreplyoption.value == "accept-queue" || 
                this.state.transreplyoption.value == "accept-sending")
            {
                var approvalstatus = 1
            }

            if(this.state.transreplyoption.value == "reject-carrier" || 
                this.state.transreplyoption.value == "reject-driverissue")
            {
                var approvalstatus = 2
            }
            var parameters = {
                comment:comment,
                approvalstatus:approvalstatus,
                rownode:this.state.commentrownode,
                userid:localStorage.getItem("userid")
            }
            //console.log("parameters ", parameters)
            redirectURL.post("/consignments/saveTransporterReplyLoadComment", parameters)
            .then((response) => {
                console.log("respons ", response.data)
                if(response.data.ok == 1)
                {
                    this.setState({
                        commentrownode:[],
                        delivermodal:false,
                        showDiv:'show-n',
                        overly:'show-n',
                        loadshow:"show-n",
                        show:true,
                        basicTitle:"Successfuly submitted comment",
                        basicType:"success"
                    })
                }
            })
            .catch(function(e){
                console.log("Error ", e)
            })
        }
    }
    onShowUserLoadAttachmentComments = async(e) => {

        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": "Show Comments",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        

        await this.setState({
						
            detailCellRendererParams:{
            suppressRefresh: true,
            detailGridOptions: {
                //onCellEditingStopped:this.onCellUpdateData.bind(this),
                headerHeight:50,
                columnDefs: [
                    {
                        headerName:"Load No",
                        field:"load_no",
                        width:120,
                    },
                    {
                        headerName:"Truck No",
                        field:"truck_no",
                        width:100,
                    },
                    {
                        headerName:"Comment",
                        field:"comment",
                        width:280,
                        cellClass:["wraptext"],
                       // editable:true
                    },
                    {
                        headerName:"Commented BY",
                        field:"username",
                        width:140
                    },

                    {
                        headerName:"Comment At",
                        field:"comment_date",
                        width:170,
                        valueGetter:function(params)
                        {
                            try{
                                return getDDMMMYYYYHHMMDefault(params.data.comment_date)
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"User Type",
                        field:"user_type",
                        width:120
                    },
                    {
                        headerName:"User Email",
                        field:"user_email",
                        width:200
                    }
                    
                ],
                overlayNoRowsTemplate: 'No rows to show',						
            },
            getDetailRowData: function(params) {
                var parameters={
                    load_no:params.data.load_no
                }
                redirectURL.post("/consignments/loadattachmentcomments",parameters)
                    .then((response) => {
                        params.successCallback(response.data);
                    })
                    .catch(function(e){
                        console.log("Error ",e)
                    })

                }
            }
        });
        if(e.column.colDef.field == 'load_no')
        {
            e.node.setExpanded(!e.node.expanded);
            //console.log("expanded ",params.node.expanded)
            if(e.node.expanded == false)
            {
                //transitedelaydata=[]
            }
        }
        else{

            e.node.setExpanded(false);
        }
    }

    onCellEditData(params){
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Update Data",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        //console.log("Ediable Child ", params)
        // var parameters = {
        //     comment:params.value,
        //     rownode:params.data,
        //     userid:localStorage.getItem("userid")
        // }
        // console.log("parameters ", parameters)
        // redirectURL.post("/consignments/updateLoadComment", parameters)
        // .then((response) => {
        //     console.log("child respons ", response.data)
        //     if(response.data.ok == 1)
        //     {
        //         this.setState({
        //             commentrownode:[],
        //             delivermodal:false,
        //             showDiv:'show-n',
        //             overly:'show-n',
        //             loadshow:"show-n",
        //             show:true,
        //             basicTitle:"Successfuly updated comment",
        //             basicType:"success"
        //         })
        //     }
        // })
        // .catch(function(e){
        //     console.log("Error ", e)
        // })

        var parameters = {
            comment:params.value,
            rownode:params.data,
            userid:localStorage.getItem("userid")
        }
        //console.log("parameters ", parameters)
        redirectURL.post("/consignments/saveLoadComment", parameters)
        .then((response) => {
            //console.log("respons ", response.data)
            if(response.data.ok == 1)
            {
                this.setState({
                    commentrownode:[],
                    delivermodal:false,
                    showDiv:'show-n',
                    overly:'show-n',
                    loadshow:"show-n",
                    show:true,
                    basicTitle:"Successfuly submitted comment",
                    basicType:"success"
                })
                this.gridApi.flashCells({
                    rowNodes: [params],
                  });

                try{

                    var transportcode;
                    if(typeof localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == "undefined" )
                    {
                        
                        var transportcode=0
                        var parameters = {
                            dept_code:this.state.dept_code,
                            startdate:"",
                            enddate:"",
                            consigner_code:["all"],
                            transportercode:transportcode
                        }
                        //console.log("transportcode ", transportcode)
                        this.setState({
                            transportercode:transportcode
                        })
                        this.onLoadShowLoadAttachment(parameters);
                    }
                    else{
                        //console.log("Trans ",localStorage.getItem("transportercode"))
                        var transportcode=localStorage.getItem("transportercode");
                        var parameters = {
                            dept_code:this.state.dept_code,
                            startdate:"",
                            enddate:"",
                            consigner_code:["all"],
                            transportercode:transportcode
            
                        }
                        this.setState({
                            transportercode:transportcode
                        })
                        this.onLoadShowLoadAttachment(parameters);
                        
                    }
                
                   
                   
                }
                catch(e){
                    var transportcode=0
                    var parameters = {
                        dept_code:this.state.dept_code,
                        startdate:"",
                        enddate:"",
                        consigner_code:["all"],
        
                    }
                    //console.log("Catch Error transportcode ", transportcode)
                    this.onLoadShowLoadAttachment(parameters);
                }

                //this.refreshGridCell(params)
            }
        })
        .catch(function(e){
            console.log("Error ", e)
        })

    }

    refreshGridCell(rowNode)
    {
        console.log("here")
        var millis = 10 * 100;
        var rowNodes = [rowNode];
        var params = {
            rowNodes: rowNodes,
        };
        setTimeout(function() {
            this.gridApi.refreshCells(rowNode);
          }, millis);
        
    }

    onSelectPlantOption(plantoption){
        this.setState(
			{ plantoption },
			() => console.log(`Plant Option selected:`, this.state.plantoption)
          );
          if(plantoption.value == 'others')
          {
              this.setState({
                  pothershow:'show-m'
              });
          }
          else{
            this.setState({
                pothershow:'show-n'
            });
          }
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    onSelectPlantReplyOption(plantreplyoption){
        this.setState(
			{ plantreplyoption },
			() => console.log(`Plant Option selected:`, this.state.plantreplyoption)
		  );
          if(plantreplyoption.value == "reject-other")
          {
              this.setState({
                prothershow:"show-m"
              });
          }
          else{
            this.setState({
              prothershow:"show-n"
            });
          }
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }

    onSelectTransporterOption(transoption){
        this.setState(
			{ transoption },
			() => console.log(`Transporter Option selected:`, this.state.transoption)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    onSelectTransporterReplyOption(transreplyoption){
        this.setState(
			{ transreplyoption },
			() => console.log(`Transporeter Option selected:`, this.state.transreplyoption)
		  );
		  if(transreplyoption.value == 'accept-other')
          {
              this.setState({
                  taothershow:'show-m',
                  trothershow:'show-n'
              });
          }
          else if(transreplyoption.value == 'reject-other')
          {
            this.setState({
                trothershow:'show-m',
                taothershow:'show-n'
            });
          }
          else{
            this.setState({
                trothershow:'show-n',
                taothershow:'show-n'
            });
          }
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }

   
    setStartDate = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        // console.log(value);
        console.log(value)
        this.setState({startDate:value});
    }
    setEndDate = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        // console.log(value);
        console.log(value)
        this.setState({endDate: value});
    }
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'load exits';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    render()
    {
        const plantoptions = [
            {"value":"", label:"Select Option"},
            {"value":"Kindly bring carrier for loading", label:"Kindly bring carrier for loading"},
            {"value":"others", label:"Others"}
        ]
        const plantreplyoptions = [
            {"value":"", label:"Select Option"},
            {"value":"accept", label:"Accepted- Kindly bring carrier for loading"},
            {"value":"reject", label:"Rejected - Do not bring carrier for loading"},
            {"value":"reject-other", label:"Rejected - Others"}
        ]

        const transoptions = [
            {"value":"", label:"Select Option"},
            {"value":"Carrier ready to take load", label:"Carrier ready to take load"}
        ]
        const transreplyoptions = [
            {"value":"", label:"Select Option"},
            {"value":"accept-queue", label:"Accepted- carrier standing in queue"},
            {"value":"accept-sending", label:"Accepted- Sending carrier to plant for loading"},
            {"value":"accept-other", label:"Accepted- Others"},
            {"value":"reject-carrier", label:"Rejected- Carrier club load from other plant"},
            {"value":"reject-driverissue", label:"Rejected- Driver issue"},
            {"value":"reject-other", label:"Rejected- Others"}
        ]
        
        let columnwithDefs = [
            {
                headerName: "",
                field: "_id",
                width: 50,
                pinned: 'left',
                cellRenderer:'loadMapAction',
                cellClass:function(params){
                    try{
                        
                        if(typeof localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == "undefined"  || localStorage.getItem("transportercode") == undefined )
                        {
                            if(params.data.notify_tpt == 1 && params.data.tpt_approval == 0)
                            {
                                return "yellow";
                            }
                            else if(params.data.notify_tpt == 1 && params.data.tpt_approval == 1)
                            {
                                return "green";
                            }
                            else if(params.data.notify_tpt == 1 && params.data.tpt_approval == 2)
                            {
                                return "redclr";
                            }
                            else{
                                return "defaultclr";
                            }
                            
                        }
                        else{
                           
                            if(params.data.notify_tpt == 1 && params.data.tpt_approval == 0)
                            {
                                return "yellow";
                            }
                            else if(params.data.notify_tpt == 1 && params.data.tpt_approval == 1)
                            {
                                return "green";
                            }
                            else if(params.data.notify_tpt == 1 && params.data.tpt_approval == 2)
                            {
                                return "redclr";
                            }
                            else{
                                return "defaultclr";
                            }
                        }
                    }
                    catch(e){

                    }
                }
                
            },
            {
                headerName: "",
                field: "gps_present",
                width: 50,
                pinned: 'left',
                cellRenderer:'loadGpsAction',
                
            },
            {
                headerName: "",
                field: "load_no",
                width: 50,
                pinned: 'left',
                cellRenderer:'loadVinLegs'
                
            },
           
            {
                headerName: "",
                field: "load_no",
                width: 50,
                pinned: 'left',
                cellRenderer:'loadcomments'
                
            },
            {
                headerName:"TPT Code",
                field:"transporter_code",
                width:80,
                pinned: 'left',
            },
            {
                headerName:"TPT Name",
                field:"transporter_name",
                width:150,
                pinned: 'left',
            },
            {
                headerName:"Load No",
                field:"load_no",
                pinned: 'left',
                width:90
            },
            
            {
                headerName:"No. Cars",
                field:"quantity",
                width:80,
                pinned:'left'
            },

            {
                headerName:"Pending Retrieval",
                field:"total_retrivals",
                width:100,
                pinned:'left',
                valueGetter:function(params){
                    try{
                        var total = params.data.total_retrivals;
                        if(params.data.total_retrivals == undefined)
                        {
                            var totalVal = 0
                        }
                        else{
                            var totalVal = params.data.total_retrivals
                        }
                        return (parseInt(params.data.quantity)-parseInt(totalVal))
                    }
                    catch(e){
                        return 0;
                    }
                }
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:120,
                pinned:'left'
            },
            {
                headerName:"MSIL Rake ID",
                field:"reck_number",
                width:120,
                pinned:'left'
            },
            {
                headerName:"Attach Date",
                field:"load_attach_date",
                width:150,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.load_attach_date);
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            
            {
                headerName:"Consigner Code",
                field:"consigner_code",
                width:100
            },
             
            {
                headerName:"Retrieval Start Date",
                field:"retrival_start_date",
                width:170,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.retrival_start_date);
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            {
                headerName:"Retrieval End Date",
                field:"retrival_end_date",
                width:170,
                valueGetter:function(params){
                    try{
                        if(params.data.retrival_completed == 1)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.retrival_end_date);
                        }
                        else
                        {
                            return "";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            {
                headerName:"Invoice Date",
                field:"invoice_date",
                width:180,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.invoice_date);
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            {
                headerName:"Gate In Time",
                field:"gate_in_time",
                width:170,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.gate_in_time);
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:170,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.gate_out_time);
                    }
                    catch(e){
                        return "";
                    }
                },
                sortingOrder: [null, 'asc'],
            },
            
            {
                headerName:"Carrier Gate In",
                field:"gate_in_time",
                width:180,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.gate_in_time);
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            
           
            {
                headerName:"Last City",
                field:"truck",
                width:120,
                valueGetter:function(params){
                    try{
                        if(params.data.truck_no != "NaN" || typeof params.data.truck_no != "undefined")
                        {
                            return params.data.truck[0].city;
                        }
                        else
                        {
                            return "";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName:"Last Packet Time",
                field:"truck",
                width:180,
                valueGetter:function(params){
                    try{
                        return getDDMMMYYYYHHMMDefault(params.data.truck[0].timestamp);
                    }
                    catch(e){
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                // cellClass : "dateFormat",
                 // filterParams: {
                //     browserDatePicker: true,
                //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
                //     // provide comparator function
                //     comparator: function(filteredDate,cellValue,secondDate) {
                //         cellValue = cellValue.replace(/\//g,"-")
                //         //console.log("cellve ", cellValue)
                //         if(cellValue != "" && cellValue != " "){
                //             cellValue = cellValue.split(" ")[0].split("-");
                //             cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
                //             // console.log(cellValue)
                //             var cellDate = new Date(cellValue);
                //             // console.log(cellDate);
                //             if(filteredDate.getMonth() < 10){
                //             var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             else{
                //                 var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
                //             }
                //             if(filterValue.split("-")[2].length < 2)
                //             {
                //                 var temp = filterValue
                //                 filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
                //             }
                //             // console.log(filterValue);
                //             var filteredDate = new Date(filterValue);
                //             // console.log(filteredDate);
                //             cellDate = cellDate.getTime();
                //             filteredDate = filteredDate.getTime();
                //             //console.log(cellDate,filteredDate);
                //             if(cellDate === filteredDate)
                //             {
                //                 return 0;
                //             }
                //             if(cellDate < filteredDate){
                //                 return -1;
                //             }
                //             if(cellDate > filteredDate)
                //             {
                //                 return 1;
                //             }
                //         }
                //     }
                // }
            },
            {
                headerName:"Dist. from Plant (KM)",
                field:"dist_from_plant",
                width:120
            },
            {
                headerName:"IMM For Code",
                field:"imm_for_code",
                width:100
            },
            {
                headerName:"For Code",
                field:"for_code",
                width:100
            },
            {
                headerName:"Consignee City",
                field:"consignee_city",
                width:120
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:100
            },
            {
                headerName:"Mode",
                field:"truck_type",
                width:100
            },
            {
                headerName:"Comment",
                field:"comment",
                width:140,
                editable:true
            }
        ]

        const {delivermodal} = this.state;
        const {transmodal} = this.state;
        const {plantreplymodal} = this.state;
        const {transreplymodal} = this.state;
        const modalStyles  = {
            width:'1300px !important',
        }
        
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                    {this.state.alert}
                    <div className="row col-xl-12 col-lg-12">
                        <div className="form-group col-xl-4 col-lg-4">
                            <label>Select Consigner: </label>
                            <Select 
                                placeholder={"All"} 
                                closeMenuOnSelect={false} 
                                value={this.state.consigner} 
                                isMulti="true" 
                                className="border-radius-0" 
                                onChange={this.changeConsignerArrayHandler.bind(this)} 
                                style={{borderRadius:"0px"}} 
                                options={this.selectConsignerOptionsItems()} 
                                required 
                            />
                                        
                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <label>Select Dispatch Type</label>
                            <Select 
                            className="border-radius-0"
                            value={this.state.dispatch_type}
                            isMulti={false}
                            id="selectedFilter"
                            onChange={((e)=>{this.setState({dispatch_type:e})})}
                            style={{borderRadius:"0px"}} 
                            options={[{label:"ALL",vaue:"ALL"},{label:"Exports",value:"Exports"},{label:"Domestic",value:"Domestic"}]} required />
                        </div>
						<div className="form-group col-xl-2 col-lg-2">
                            <label>From Date</label>
                            {/* <Datetime 
                                value={this.state.startDate} 
                                disableCloseOnClickOutside={false} 
                                closeOnSelect={true} 
                                inputProps={{ 
                                    placeholder: 'From', 
                                    name: 'startDate', 
                                    autoComplete:'off' 
                                }}
                                dateFormat="YYYY-MM-DD HH:MM:SS" 
                                name="startDate" 
                                onChange={this.handlerStartDateTime.bind(this)} 
                            /> */}
                            <input type="text" value={this.state.startDate} placeholder="" onBlur={this.setStartDate.bind(this)} className="datetimepicker_mask form-control" required />  

                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
							<label>To Date</label>
                            {/* <Datetime 
                            value={this.state.endDate} 
							disableCloseOnClickOutside={false}
							 closeOnSelect={true} 
							 inputProps={{ 
                                 placeholder: 'To', 
                                 name: 'endDate', 
                                 autoComplete:'off'
                            }} 
							//  dateFormat="YYYY-MM-DD" 
							 onChange={this.handlerEndDateTime.bind(this)} />  */}
                            <input type="text" value={this.state.endDate} placeholder="" onBlur={this.setEndDate.bind(this)} className="datetimepicker_mask form-control" required />  

						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label>&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onSubmitFilter.bind(this)}>Submit</button>
						</div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        {/* <Counters 
                            context={this} 
                            totalLoads={this.state.totalLoadscnt}
                            retrivalyettostart={this.state.retrivalyettostartcnt}
                            retrivalinprogress={this.state.retrivalinprogresscnt}
                            retrivalcomplete={this.state.retrivalcompletecnt}
                            gatein={this.state.gateincnt}
                            gateout={this.state.gateoutcnt}
                            invoiced={this.state.invoicedcnt}
                            totalloadscars={this.state.totalloadscars}
                            yettostartcars={this.state.yettostartcars}
                            inprogresscars={this.state.inprogresscars}
                            invoicedcars={this.state.invoicedcars}
                            completedcars={this.state.completedcars}
                            gateincars={this.state.gateincars}
                            gateoutcars={this.state.gateoutcars}
                            pendinginvoice={this.state.pendinginvoicecnt}
                            pendinginvoicecars={this.state.pendinginvoicecars}
                            pendingtptcnt={this.state.pendingtpt.length}
                            pendingplantcnt={this.state.pendingplant.length}
                            screen={"exitloads"}
                        /> */}
                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <div className="crm-numbers pb-0">
                                        <div className="row">
                                            <div className="col cirlce-d cursorPointer">
                                                <span className="f12"><i className="icofont icofont-logout f22 txt-info-medium"></i><br />Gate Out </span>
                                                <h4 className="txt-info-dark f26"><span className="counter"><CountUp end={(this.state.gateoutcnt)?this.state.gateoutcnt:0}/></span></h4>                            
                                                <div className=" f12">
                                                    <div className="counter txt-success-medium">
                                                    Cars: &nbsp;
                                                        <CountUp end={(this.state.gateoutcars != '')?this.state.gateoutcars:0}/>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="col col-xl-6 cpointer">
                                <span className="f12"><i className="icofont icofont-logout f22 txt-info-medium"></i><br />Gate Out </span>
                                <h4 className="txt-info-dark f26"><span className="counter"><CountUp end={(this.state.gateout)?this.state.gateout:0}/></span></h4>
                            
                                <div className=" f12">
                                    <div className="counter txt-success-medium">
                                    Cars: &nbsp;
                                        <CountUp end={(this.state.gateoutcars != '')?this.state.gateoutcars:0}/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
				       			<h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
								
                                    <span className="layoutbtns">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>

                                </h5>
				   			</div>	
                            <div className="card-body pt-15px" >
                                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        rowClassRules={this.state.rowClassRules}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        autoHeight={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onCellEditingStopped={this.onCellEditData.bind(this)}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles= {this.state.excelStyles}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Map Start */}
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
                             {(this.state.mapinfo != "")?
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                
                                <DrawMap context={this} mapFor={"loadattach"} tolls={this.state.tolls}  truckno={this.state.maptruckno} mapinfo={this.state.mapinfo} />
								
                               
								
                                <div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.endTime):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != "")?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											
										
										</div>
									</div>
								</div>

							</div> :""}
						</div>
						
					</div>
				:""	
				}
                {/* Main Map End */}
  
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
                {/* Plant Pop Model */}
                <Modal open={delivermodal} onClose={this.onCloseModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
		    			<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateComment.bind(this)}>
                            <div className={"form-group col-xl-12 col-lg-12 row"}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Select Option</label>
                                    <Select placeholder={"Select Option"} style={{float: "right"}} value={this.state.plantoption} className="col-xl-11 col-lg-11 border-radius-0" onChange={this.onSelectPlantOption.bind(this)} style={{borderRadius:"0px"}} options={plantoptions} required />
									
                            </div>	
                            <div className={"form-group col-xl-12 col-lg-12 row "+(this.state.pothershow)}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Reason</label>
									<textarea name="plantreason" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-9 col-lg-9"></textarea>
                            </div>	
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

                {/* Plant reply model */}
                <Modal open={plantreplymodal} onClose={this.onCloseModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
		    			<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateReplyComment.bind(this)}>
                            <div className={"form-group col-xl-12 col-lg-12 row"}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Select Option</label>
                                    <Select placeholder={"Select Option"} style={{float: "right"}} value={this.state.plantreplyoption} className="col-xl-11 col-lg-11 border-radius-0" onChange={this.onSelectPlantReplyOption.bind(this)} style={{borderRadius:"0px"}} options={plantreplyoptions} required />
									
                            </div>	
                            <div className={"form-group col-xl-12 col-lg-12 row "+(this.state.prothershow)}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Reason</label>
									<textarea name="plantreplyreason" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-9 col-lg-9"></textarea>
                            </div>	
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
                
                
                {/* Transporter Popup MOdal */}
                <Modal open={transmodal} onClose={this.onCloseModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
		    			<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickTransporterUpdateComment.bind(this)}>
                            <div className={"form-group col-xl-12 col-lg-12 row"}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Select Option</label>
                                    <Select placeholder={"Select Option"} style={{float: "right"}} value={this.state.transoption} className="col-xl-11 col-lg-11 border-radius-0" onChange={this.onSelectTransporterOption.bind(this)} style={{borderRadius:"0px"}} options={transoptions} required />
									
                            </div>
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
                {/* Transporter Reply Popup MOdal */}
                <Modal open={transreplymodal} onClose={this.onCloseModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
		    			<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickTransporterUpdateReplyComment.bind(this)}>
                            <div className={"form-group col-xl-12 col-lg-12 row"}>
									<label className="col-xl-2 col-lg-2" style={{float: "left"}}>Select Option</label>
                                    <Select placeholder={"Select Option"} style={{float: "right"}} value={this.state.transreplyoption} className="col-xl-11 col-lg-11 border-radius-0" onChange={this.onSelectTransporterReplyOption.bind(this)} style={{borderRadius:"0px"}} options={transreplyoptions} required />
									
                            </div>
                             <div className={"form-group col-xl-12 col-lg-12 row "+(this.state.taothershow)}>
                                <label className="col-xl-2 col-lg-2" style={{float: "left"}}>Accept Reason</label>
                                <textarea name="transreplyacceptreason" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-9 col-lg-9"></textarea>
                            </div>	
                             <div className={"form-group col-xl-12 col-lg-12 row "+(this.state.trothershow)}>
                                <label className="col-xl-2 col-lg-2" style={{float: "left"}}>Reject Reason</label>
                                <textarea name="transreplyrejectreason" onChange={this.changeHandler.bind(this)} rows="5" className="form-control col-xl-9 col-lg-9"></textarea>
                            </div>	
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

            </div>
        )
    }
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}