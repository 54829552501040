import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DispatchVehicleCountDashboard from "../amns/dispatchVehicleCountDashboarad"
var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');

var redirectURL = require('../redirectURL');
var moment = require('moment');
class ZoneWiseDispachSummaryDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicType: "",
            basicTitle: "",
            loadshow: "show-n",
            overly: "show-n",
            url_path: window.location.pathname,
            zone_wise_data: [],
            yesterday_zone_wise_data: [],

        };
    }


    fetchZoneWiseDispatch = async (truckLoadZone) => {
        try {
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            });
            let todayData = await this.fetchDataForDate(moment().subtract(1, 'month').startOf('day'), moment().endOf('day'),truckLoadZone);
            console.log(truckLoadZone,'truckLoadZone')
            if ((truckLoadZone === "All" && todayData.length > 20)) {
                todayData = todayData.slice(0,20); // Get the first 50 values
            }else if(!Array.isArray(todayData)){
                todayData = []
            }


            const yesterdayStart = moment().subtract(1, 'days').startOf('day'); // Set to yesterday 00:00:00
            const yesterdayEnd = moment().subtract(1, 'days').endOf('day'); // Set to yesterday 23:59:59
            // console.log(truckLoadZone,"truckszone52")
            let yesterdayData = await this.fetchDataForDate(yesterdayStart, yesterdayEnd,truckLoadZone);
            if(Array.isArray(yesterdayData)){
                if (truckLoadZone === "All" && yesterdayData.length > 20) {
                    yesterdayData = yesterdayData.slice(0,20); // Get the first 50 values
                }
            }
            
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                zone_wise_data: todayData,
                yesterday_zone_wise_data: yesterdayData || [],
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            // Optionally, you can set state here to handle error states
        }
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.truckLoadZone !== prevProps.truckLoadZone) {
           
            this.fetchZoneWiseDispatch(this.props.truckLoadZone);
        }
    }
    3
    componentDidMount() {
        this.fetchZoneWiseDispatch(this.props.truckLoadZone);
    }

    

    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }
    

    fetchDataForDate = async (startDate, endDate,truck_load_zone) => {
        try {
            const start_date = startDate.format('YYYY-MM-DD HH:mm:ss');
            const end_date = endDate.format('YYYY-MM-DD HH:mm:ss');

            const parameters = { start_date, end_date ,truck_load_zone};

            const response = await redirectURL.post("/consignments/zonewiseDispatchReport", parameters);
            const data = response.data || [];
            // console.log(data,'zonewisedispatchreport104')
            if (Array.isArray(data)) {
                return data.map(item => ({
                    ...item,
                    total_quantity: Math.round((item.rail_item_quantity || 0) + (item.road_item_quantity || 0))
                }));
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            throw error; // Re-throw the error to be caught in fetchZoneWiseDispatch
        }
    }

    render() {
        const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '10px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '8px',
            textAlign: 'center',
            textTransform: 'uppercase',
        };


        return (
            <>
                <div className='card-body'>

                    <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px' }}>
                        <div className="card-header" style={{ textAlign: 'center' }}>
                            <h5>
                                ZONE WISE DISPATCH
                            </h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '50%', marginRight: '14px' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={6} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Month</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>
                                            <th colSpan={3} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity</th>

                                            <th colSpan={2} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No of Vehicles</th>

                                        </tr>
                                        <tr>
                                            <th style={tableHeaderStyle}>Zone</th>
                                            <th style={tableHeaderStyle}>Road</th>
                                            <th style={tableHeaderStyle}>Rail</th>
                                            <th style={tableHeaderStyle}>Total</th>
                                            <th style={tableHeaderStyle}>Road</th>
                                            <th style={tableHeaderStyle}>Rail</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.zone_wise_data.map((row, index) => (
                                            <tr key={index}>
                                                <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                <td style={tableCellStyle}>{row.road_item_quantity}</td>
                                                <td style={tableCellStyle}>{row.rail_item_quantity}</td>
                                                <td style={tableCellStyle}>{(row.total_quantity)}</td>
                                                <td style={tableCellStyle}>{row.road_truck_no}</td>
                                                <td style={tableCellStyle}>{row.rail_truck_no}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                {this.state.zone_wise_data.length == 0 &&
                                    <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                }
                            </div>
                            <div style={{ width: '50%' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={6} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Yesterday</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}></th>
                                            <th colSpan={3} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity</th>

                                            <th colSpan={3} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No of Vehicles</th>

                                        </tr>
                                        <tr>
                                            <th style={tableHeaderStyle}>Zone</th>
                                            <th style={tableHeaderStyle}>Road</th>
                                            <th style={tableHeaderStyle}>Rail</th>
                                            <th style={tableHeaderStyle}>Total</th>
                                            <th style={tableHeaderStyle}>Road</th>
                                            <th style={tableHeaderStyle}>Rail</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.yesterday_zone_wise_data.map((row, index) => (
                                            <tr key={index}>
                                                <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                <td style={tableCellStyle}>{row.road_item_quantity}</td>
                                                <td style={tableCellStyle}>{row.rail_item_quantity}</td>
                                                <td style={tableCellStyle}>{(row.total_quantity)}</td>
                                                <td style={tableCellStyle}>{row.road_truck_no}</td>
                                                <td style={tableCellStyle}>{row.rail_truck_no}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                {this.state.yesterday_zone_wise_data.length == 0 &&
                                    <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                }
                            </div>


                        </div>

                    </div>
                    <DispatchVehicleCountDashboard />

                </div>

                {/* <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
            </>
        );
    }
}

export default ZoneWiseDispachSummaryDashboard;