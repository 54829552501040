import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import RemoveGridRow from './removegridrow';

import Modal from 'react-responsive-modal';
var Constant = require('../common/Constant');

$.datetimepicker.setLocale('en');
var moment = require('moment');
var redirectURL = require('../redirectURL');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export const validRegistrationNumber = new RegExp(/^[a-zA-Z0-9]{1,11}$/);

// export const validRegistrationNumber = new RegExp(/^[A-Z|a-z]{2}[0-9]{1,2}[A-Z|a-z]{1,2}[0-9]{1,4}$/);

export const validAlphaNumericSpace = new RegExp(/^[a-zA-Z0-9._& ]{1,255}$/);
export const validAlphaNumeric = new RegExp(/^[a-zA-Z0-9]{1,50}$/);
export const validPhone = new RegExp(/^[789]\d{10}$/);
export const validCoords = new RegExp(/^[0-9.]{1,255}$/);
export const validNumber = new RegExp(/^[0-9]{1,30}$/);


export default class CreateShipment extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                RemoveGridRow:RemoveGridRow
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            truck_no:"",
            vehicle_type:{"value":"Truck","label":"Truck"},
            shipment_mode:{"value":"ROAD","label":"Road"},
            total_shipment_qty:"",
            driver_name:"",
            parking_id:"",
            parking_date:"",
            parking_time:"",
            transporter_code:"",
            transporter_name:"",
            shipment_id:"",
            shipment_date:"",
            shipment_time:"",
            shipment_source:{"value":"","label":"Select"},
            shipment_destination:{"value":"","label":"Select"},
            railyards:[],
            shipsrcdestshow:"show-n",
            plants:[],
            plant_code:"",
            plant_name:"",
            driver_code:"",
            customers:[],
            allcustomers:[],
            isreadonly:false,
            newcustomer_code:"",
            newcustomer_name:"",
            newcustomer_city:"",
            newcustomer_latitude:"",
            newcustomer_longitude:"",
            open: false,
            curentRow:"",
            errtruckmsg:"",
            errtruck:"show-n",
			
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.changeVehicleHandler = this.changeVehicleHandler.bind(this);
        this.changeNumberHandler = this.changeNumberHandler.bind(this);
        this.changeAlphaNumericHandler = this.changeAlphaNumericHandler.bind(this);
        this.changeAlphaNumericNOSpeacialHandler = this.changeAlphaNumericNOSpeacialHandler.bind(this);
        this.changeCoords = this.changeCoords.bind(this);
        this.onLoadCustomers = this.onLoadCustomers.bind(this);
    }
    componentDidMount(){
		loadDateTimeScript();
        redirectURL.post("/amns/railyards")
        .then((response) => {
            var geofences = response.data.geofences;
            this.setState({
                railyards:geofences
            })
        })
        redirectURL.post("/amns/plants")
        .then((response) => {
            var plants = response.data.plants;
            this.setState({
                plants:plants,
                plant_code:plants[0].plant_code,
                plant_name:plants[0].plant_name
            })
        })
        this.onLoadCustomers();    
    }
    onLoadCustomers(){
        redirectURL.post("/amns/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            var customers = []
            
            customers.push("");
            if(customersarr.length > 0)
            {
                customersarr.map((item) => {
                    customers.push(item.consignee_name)
                })
            }
            customers.push(Constant.NEW_CUSTOMER);
            this.setState({
                customers:customers,
                allcustomers:customersarr
            })
        })
    }
   
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    changeCoords(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            console.log("validCoords.test(value) ", validCoords.test(value))
            if(validCoords.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
            
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    } 
    changeVehicleHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(value.length <= 10 && (validRegistrationNumber.test(value) == true))
            {
                this.setState({
                    [name]:value,
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
                if(value.length >= 8){
                    var pr = {
                        truck_no:value
                    }
                    redirectURL.post("/amns/truckdetails",pr)
                    .then((response) => {
                        var records = response.data.truckinfo;
                        if(records.length > 0)
                        {
                            try{
                                if(records[0].info.length > 0)
                                {
                                    var transporter_name = records[0].info[0].transporter_name;
                                }
                                else{
                                    var transporter_name = "";
                                }
                                if(records[0].transporter_code != "NA")
                                {
                                    this.setState({
                                        transporter_code:records[0].transporter_code,
                                        transporter_name:transporter_name,
                                        isreadonly:true,
    
                                    })
                                }
                                
                            }
                            catch(e){}
                        }
                        else{
                            this.setState({
                                isreadonly:false,

                            })
                        }
                    })
                }
                else{
                    
                }
            }
        }
        else{
            this.setState({
                [name]:value,
                // errtruckmsg:"Invalid Truck No",
                // errtruck:"show-m"
            })
        }
        
    }
    onBlurTruck(){
        var truck = this.state.truck_no;
        if(truck == "")
        {
            this.setState({
                errtruckmsg:"Please enter vehicle no",
                errtruck:"show-m"
            })
        }
        else{
            if(truck.length < 8){
                this.setState({
                    errtruckmsg:"Invalid Truck No",
                    errtruck:"show-m"
                })
            }
            else{
                this.setState({
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
            }
        }
    }
    changeAlphaNumericHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(validAlphaNumericSpace.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    changeAlphaNumericNOSpeacialHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(validAlphaNumeric.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    
    changeNumberHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        // console.log("Here ",validRegistrationNumber.test(value))
        if(isNaN(value) == true)
        {
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    
    createShipmentForm(event){
        event.preventDefault();
        var items=[];
        this.gridApi.forEachNode(function(node) { 
            items.push(node.data);
        });
        // console.log("AGGrid data ",items);
        
        var parking_date = $("#parking_date").val();
        var parking_time = $("#parking_time").val();
        var shipment_date = $("#shipment_date").val();
        var shipment_time = $("#shipment_time").val();
        var flag = 0;
        var itmerr=[];
        var err = [];
        var transiterr=[];
        var distanceerr=[];
        var customererr=[];
        if(items.length > 0)
        {
            items.map((item) => {
                if(item.consignment_code != "" && item.delivery_no != "" && item.sales_order != "" && item.item_no != "" && item.item_desc != "" && item.item_quantity != "" && item.consignee_code != "" && item.consignee_name != "" && item.consignee_city != "" && item.lat != "" && item.lng != "" && item.distance_in_km != "")
                {
                    // if(item.transit_time < 0)
                    // {
                    //     transiterr.push(1);
                    //     // err.push(1);
                    // }
                    if(item.distance_in_km < 0)
                    {
                        // err.push(1);
                        distanceerr.push(1);
                    }
                    if(item.consignee_name == Constant.NEW_CUSTOMER)
                    {
                        customererr.push(1);
                    }
                }
                else{
                    err.push(1);
                }
            })
        }
        else{
            itmerr.push(1);
        }
        if(itmerr.length > 0)
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Please add atleast one item.",
                basicType:'danger'
            })
        }
        else if(distanceerr.length > 0)
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Expected Distance (Km) should be greater than zero.",
                basicType:'danger'
            })
        }
        else if(transiterr.length > 0){
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Transit Time (Days) should be greater than zero.",
                basicType:'danger'
            })
        }
        else if(customererr.length > 0)
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Please select customer from droppwn list.",
                basicType:'danger'
            })
        }
        else{
            if(err.length > 0)
            {
                flag = 1;
                this.setState({
                    show:true,
                    basicTitle:"Invalid Data.",
                    basicType:'danger'
                })
            }
        }
        if(this.state.truck_no  == "")
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Vehicle No can not be empty.",
                basicType:'danger'
            })
        }
        if(this.state.vehicle_type.value  == "")
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Vehicle Type can not be empty.",
                basicType:'danger'
            })
        }
        if(this.state.shipment_mode.value  == "")
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Shipment Mode can not be empty.",
                basicType:'danger'
            })
        }
        if(this.state.driver_code == "")
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Driver Code can not be empty.",
                basicType:'danger'
            })
        }
        if(this.state.driver_name  == "")
        {
            flag = 1;
            this.setState({
                show:true,
                basicTitle:"Driver name can not be empty.",
                basicType:'danger'
            })
        }
        if(this.state.parking_id  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Parking Entry no can not be empty.",
                basicType:'danger'
            })
        }
        if(parking_date == "" || parking_date == "__-__-____")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Parking Date can not be empty.",
                basicType:'danger'
            })
        }
        if(parking_time == "" || parking_time == "__:__")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Parking Time can not be empty.",
                basicType:'danger'
            })
        }
        
        if(this.state.transporter_code  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter code can not be empty.",
                basicType:'danger'
            })
        }
        
        if(this.state.transporter_name  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter Name can not be empty.",
                basicType:'danger'
            })
        }

        if(shipment_date  == "" || shipment_date  == "__-__-____")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Shipment Date can not be empty.",
                basicType:'danger'
            })
        }
        
        if(shipment_time  == "" || shipment_time  == "__:__")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Shipment Time can not be empty.",
                basicType:'danger'
            })
        }
        try{
            if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
            {
                if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                {
                    flag = 1;
                    
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Source can not be empty.",
                        basicType:'danger'
                    })
                }
                if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                {
                    flag = 1;
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Destination can not be empty.",
                        basicType:'danger'
                    })
                }
            }
        }
        catch(e){}
        if(flag == 0)
        {
            var pd = [];
            var pd1 = [];
            var pdate = "";
            if(parking_date != "" && parking_date != undefined)
            {
                pd = parking_date.split("/");
                if(pd.length == 3){
                    if(pd[0].length == 4)
                    {
                        pdate = pd[0]+"-"+pd[1]+"-"+pd[2];
                    }
                    else{
                        pdate = pd[1]+"-"+pd[0]+"-"+pd[2];
                    }
                     
                }
                else{
                    pd1 = parking_date.split("-");
                    if(pd1.length == 3)
                    {
                        pdate = parking_date;
                    }
                }
                
            }
            
            // console.log("pd",pd)
            // console.log("pdate",pdate)

            var sd = [];
            var sd1 = [];
            var shdate = "";
            if(shipment_date != "" && shipment_date != undefined)
            {
                sd = shipment_date.split("/");
                if(sd.length == 3){
                    if(sd[0].length == 4)
                    {
                        shdate = sd[0]+"-"+sd[1]+"-"+sd[2];
                    }
                    else{
                        shdate = sd[1]+"-"+sd[0]+"-"+sd[2];
                    }
                     
                }
                else{
                    sd1 = shipment_date.split("-");
                    if(sd1.length == 3)
                    {
                        shdate = shipment_date;
                    }
                }
                
            }


            var params ={
                truck_no:this.state.truck_no,
                vehicle_type:this.state.vehicle_type.value,
                shipment_mode:this.state.shipment_mode.value,
                driver_code:this.state.driver_code,
                driver_name:this.state.driver_name,
                parking_id:this.state.parking_id,
                parking_date:pdate,
                parking_time:parking_time,
                transporter_code:this.state.transporter_code,
                transporter_name:this.state.transporter_name,
                shipment_id:this.state.shipment_id,
                shipment_date:shdate,
                shipment_time:shipment_time,
                shipment_source:this.state.shipment_source.value,
                shipment_source_name:this.state.shipment_source.label,
                shipment_destination:this.state.shipment_destination.value,
                shipment_destination_name:this.state.shipment_destination.label,
                shipment_items:items,
                plant_code:this.state.plant_code,
                plant_name:this.state.plant_name,
            }
            // console.log("Form params ", params);
            redirectURL.post("/amns/saveshipmentapi",params)
            .then((response) => {
                console.log(response.data);
                if(response.data.status == "Success")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Successfully created shipment",
                        basicType1:"success",
                        truck_no:"",
                        vehicle_type:{"value":"Truck","label":"Truck"},
                        shipment_mode:{"value":"ROAD","label":"Road"},
                        driver_code:"",
                        driver_name:"",
                        parking_id:"",
                        parking_date:"",
                        parking_time:"",
                        transporter_code:"",
                        transporter_name:"",
                        shipment_id:"",
                        shipment_date:"",
                        shipment_time:"",
                        shipment_source:{"value":"","label":"Select"},
                        shipment_source_name:"",
                        shipment_destination:{"value":"","label":"Select"},
                        shipment_destination_name:"",
                        shipment_items:[],
                        plant_code:"",
                        plant_name:"",
                        shipsrcdestshow:"show-n"

                    })
                    
                    this.gridApi.setRowData([]);
                    $("#parking_date").val("");
                    $("#parking_time").val("");
                    $("#shipment_date").val("");
                    $("#shipment_time").val("");
                    
                }
                else{
                    this.setState({
                        show:true,
                        basicTitle:response.data.message,
                        basicType:"success",
                       
                    })
                }
            });
        }
        else{



            // console.log("S26");
            // this.setState({
            //     show:true,
            //     basicTitle:"Invalid Data",
            //     basicType:"danger"
            // })


            if(itmerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please add atleast one item.",
                    basicType:'danger'
                })
            }
            else if(distanceerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Expected Distance (Km) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(transiterr.length > 0){
                this.setState({
                    show:true,
                    basicTitle:"Transit Time (Days) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(customererr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please select customer from droppwn list.",
                    basicType:'danger'
                })
            }
            else{
                if(err.length > 0)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Invalid Data.",
                        basicType:'danger'
                    })
                }
            }
            if(this.state.truck_no  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle No can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.vehicle_type.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle Type can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.shipment_mode.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Mode can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_code == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver Code can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver name can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.parking_id  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Parking Entry no can not be empty.",
                    basicType:'danger'
                })
            }
            if(parking_date == "" || parking_date == "__-__-____")
            {
                this.setState({
                    show:true,
                    basicTitle:"Parking Date can not be empty.",
                    basicType:'danger'
                })
            }
            if(parking_time == "" || parking_time == "__:__")
            {
                this.setState({
                    show:true,
                    basicTitle:"Parking Time can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(this.state.transporter_code  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter code can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(this.state.transporter_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter Name can not be empty.",
                    basicType:'danger'
                })
            }
    
            if(shipment_date  == "" || shipment_date  == "__-__-____")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Date can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(shipment_time  == "" || shipment_time  == "__:__")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Time can not be empty.",
                    basicType:'danger'
                })
            }
            try{
                if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
                {
                    if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Source can not be empty.",
                            basicType:'danger'
                        })
                    }
                    if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Destination can not be empty.",
                            basicType:'danger'
                        })
                    }
                }
            }
            catch(e){}



        }
        
    }
    
    vehicletypes()
    {
        var items= [];
        items.push({
            value:"Truck",
            label:"Truck"
        },{
            value:"Trailer",
            label:"Trailer"
        })
        return items;
    }
    
    onChangeVehicleType(vehicle_type){
        this.setState({vehicle_type},() => {

        })
    } 
    shipmentsources(){
        var items= [];
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_loading_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        
        return items;
    }
    onChangeSources(shipment_source){
        this.setState({shipment_source},() => {

        })
    }
    shipmentmodes(){
        var items= [];
        items.push({
            value:"ROAD",
            label:"Road"
        },{
            value:"RAIL",
            label:"Rail"
        })
        return items;
    }
    onChangeModeType(shipment_mode){
        this.setState({shipment_mode},() => {
            if(this.state.shipment_mode.value == Constant.SHIPMENT_MODE_ROAD)
            {
                this.setState({
                    shipsrcdestshow:"show-n"
                })
            }
            else{
                this.setState({
                    shipsrcdestshow:"show-m"
                })
            }
        })
    }
    shipmentdestinations(){
        var items= [];
        
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_delivery_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        return items;
    }
    onChangeDestination(shipment_destination){
        this.setState({shipment_destination},() => {

        })
    }
    addRow(){
    
        this.gridApi.updateRowData({
          add: [{ 
              consignment_code: '', 
              delivery_no: '', 
              sales_order: '',
              item_no:"", 
              item_desc:"",
              item_quantity:"",
              consignee_code:"",
              consignee_name:"", 
              consignee_city:"",
              lat:"",
              lng:"",
              distance_in_km : "", 
            //   transit_time:"" 
            }]
        });
        // gridOptions.singleClickEdit = true

    }
    
    onCellEditData = async (params) => {
        if(params.colDef.field == "consignee_name")
        {
            if(params.value == Constant.NEW_CUSTOMER)
            {
                // console.log("params: ", params)
                this.setState({
                    open:true,
                    curentRow:params.data
                })
                
            }
            else{
                
                // console.log("params here ", params)
                var selectcustomer = [];
                if(this.state.allcustomers.length > 0){
                   await this.state.allcustomers.map((item) => {
                        if(item.consignee_name == params.value)
                        {
                            selectcustomer.push(item);
                             
                        }
                    })
                }
                try{
                    // console.log("selectcustomer ", selectcustomer)
                    if(selectcustomer.length > 0)
                    {   
                        var coords = JSON.parse(selectcustomer[0].coordinates);
                        // console.log("coords ", coords)
                        params.data.consignee_code = selectcustomer[0].consignee_code;
                        params.data.consignee_name = selectcustomer[0].consignee_name;
                        params.data.consignee_city = selectcustomer[0].consignee_city;
                        params.data.lat = coords.lat;
                        params.data.lng = coords.lng;
                        // console.log("params here ", params)
                        await this.gridApi.updateRowData({ update: [params.data] });
                    }
                    
                }
                catch(e){

                }
                
            }
            
        }

    }
    deleteRow(params){
        let deletedRow = params.data;
        this.gridApi.updateRowData({ remove: [deletedRow] })
    }
    
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
        try{
            let deletedRow = this.state.curentRow;
            // console.log("deletedRow ", deletedRow)
            if(deletedRow.consignee_name == Constant.NEW_CUSTOMER)
            {
                deletedRow.consignee_name = "";
                deletedRow.consignee_code = "";
                deletedRow.consignee_city = "";
                deletedRow.lat = "";
                deletedRow.lng = "";
            }
            // console.log("After deletedRow ",deletedRow)
            this.gridApi.updateRowData({ update: [deletedRow] })
        } 
        catch(e){

        }
	   this.setState({ open: false });
	 };

     saveNewCustomerForm(event){
        event.preventDefault();
        var newcustomer_code = this.state.newcustomer_code;
        var newcustomer_name = this.state.newcustomer_name;
        var newcustomer_city = this.state.newcustomer_city;
        var newcustomer_latitude =this.state.newcustomer_latitude;
        var newcustomer_longitude = this.state.newcustomer_longitude;
        var parameters = {
            newcustomer_code:newcustomer_code,
            newcustomer_name:newcustomer_name,
            newcustomer_city:newcustomer_city,
            newcustomer_latitude:newcustomer_latitude,
            newcustomer_longitude:newcustomer_longitude,
        }
        redirectURL.post("/amns/savecustomer",parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var row = this.state.curentRow;
                row.consignee_code = this.state.newcustomer_code;
                row.consignee_name = this.state.newcustomer_name;
                row.consignee_city = this.state.newcustomer_city;
                row.lat = this.state.newcustomer_latitude;
                row.lng = this.state.newcustomer_longitude; 
                this.gridApi.updateRowData({ update: [row] })
                
                this.setState({
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
                this.onLoadCustomers();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to add customer",
                    basicType:"danger",
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
        
     }
     onClickHideAll(){
        // try{
        //     let deletedRow = this.state.curentRow;
        //     // console.log("deletedRow ", deletedRow)
        //     if(deletedRow.consignee_name == "New Customer")
        //     {
        //         deletedRow.consignee_name = "";
        //         deletedRow.consignee_code = "";
        //         deletedRow.consignee_city = "";
        //         deletedRow.lat = "";
        //         deletedRow.lng = "";
        //     }
        //     console.log("After deletedRow ",deletedRow)
        //     this.gridApi.updateRowData({ update: [deletedRow] })
        // } 
        // catch(e){

        // }
        
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			bulkslide:'',
		});
		
	}

    render(){
        
		const { open } = this.state;
        var customerslist = this.state.customers;
        var columnwithDefs  = [
            {
                headerName:"Batch No",
                field:"consignment_code",
                editable:true,
                width:120
            },
            {
                headerName:"Delivery No",
                field:"delivery_no",
                editable:true,
                cellEditor:NumericCellEditor,
                width:140
            },
            {
                headerName:"SO Number",
                field:"sales_order",
                editable:true,
                width:140
            },
            {
                headerName:"Item No",
                field:"item_no",
                width:100,
                editable:true,
            },
            {
                headerName:"Item Desctiption",
                field:"item_desc",
                width:220,
                editable:true,
            },
            {
                headerName:"Quantity",
                field:"item_quantity",
                width:100,
                editable:true,
                cellEditor:NumericCellEditor
              
            },
            {
                headerName:"Customer Name",
                field:"consignee_name",
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: customerslist,
                }
            },
            {
                headerName:"Customer Code",
                field:"consignee_code",
                width:100,
                editable:false,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.customer_code != "")
                //         {
                //             return params.data.customer_code;
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            {
                headerName:"Customer City",
                field:"transporter_zone",
                width:184,
                editable:false,
            },
            {
                headerName:"Customer Latitude",
                field:"lat",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Customer Longitude",
                field:"lng",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Expected Distance (KM)",
                field:"distance_in_km",
                width:140,
                editable:true,
                cellEditor:NumericCellEditor
            },
            // {
            //     headerName:"Transit Time (Days)",
            //     field:"transit_time",
            //     width:140,
            //     editable:true,
            //     cellEditor:NumericCellEditor
            // },
            {
                headerName:"",
                field:"",
                width:100,
                editable:false,
                cellRendererSelector:function(params){
                    return { 
                        component: 'RemoveGridRow'
                    };
                },
            }
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <span>Create Shipment </span>
                                    {/* <a href={"/shipment"} className="btn btn-default pull-right">Back to List</a> */}
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                             
                            <form method="POST" className="form-theme col-xl-12 col-lg-12" onSubmit={this.createShipmentForm.bind(this)}>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_PARKING}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_VEHICLE_NO}</label>
                                                <input type="text" className="form-control" name="truck_no" id="truck_no" onChange={this.changeVehicleHandler} value={this.state.truck_no} size="10" onBlur={this.onBlurTruck.bind(this)} required />
                                                <span className={"redfont f12 "+(this.state.errtruck)}>{this.state.errtruckmsg}</span>
                                            </div>
                                            
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_VEHICLE_TYPE}</label>
                                                <Select 
                                                    required ={true}
                                                    className="" 
                                                    name="vehicle_type" 
                                                    id="vehicle_type" 
                                                    onChange={this.onChangeVehicleType.bind(this)} 
                                                    value={this.state.vehicle_type} 
                                                    options={this.vehicletypes()}
                                                />
                                            </div>
                                            
                                            {/* <div className="form-group col-sm-2">
                                                <label>Total Shipment Qty.</label>
                                                <input type="text" className="form-controle" name="total_shipment_qty" id="total_shipment_qty" onChange={this.changeHandler} value={this.state.total_shipment_qty} />
                                            </div> */}
                                            
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_ENTRY_NO}</label>
                                                <input type="text" className="form-control" name="parking_id" id="parking_id" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.parking_id} required />
                                            </div>
                                            
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_DATE}</label>
                                                <input type="text" className="datepicker form-control" name="parking_date" id="parking_date" onChange={this.changeHandler}  required />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_PARKING_TIME}</label>
                                                <input type="text" className="datetimepicker_time form-control" name="parking_time" id="parking_time" onChange={this.changeHandler}  required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-sm-4">
                                                <label>{Constant.LABEL_TPT_CODE}</label>
                                                <input type="text" disabled={this.state.isreadonly} className="form-control" name="transporter_code" id="transporter_code" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.transporter_code} required />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>{Constant.LABEL_TPT_NAME}</label>
                                                <input type="text" disabled={this.state.isreadonly} className="form-control" name="transporter_name" id="transporter_name" onChange={this.changeAlphaNumericHandler} value={this.state.transporter_name} required />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_DRIVER_CODE}</label>
                                                <input type="text" className="form-control" name="driver_code" id="driver_code" onChange={this.changeAlphaNumericHandler} value={this.state.driver_code} required />
                                            </div>
                                            
                                            <div className="form-group col-sm-3">
                                                <label>{Constant.LABEL_DRIVER_NAME}</label>
                                                <input type="text" className="form-control" name="driver_name" id="driver_name" onChange={this.changeAlphaNumericHandler} value={this.state.driver_name} required />
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </fieldset>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_SHIPMENT}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_MODE}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_mode" 
                                                    id="shipment_mode" 
                                                    onChange={this.onChangeModeType.bind(this)} 
                                                    value={this.state.shipment_mode} 
                                                    options={this.shipmentmodes()}
                                                    required={true}
                                                />
                                            </div>
                                            <div className={"form-group col-sm-3 "+(this.state.shipsrcdestshow)}>
                                                <label>{Constant.LABEL_SHIPMENT_SOURCE}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_source" 
                                                    id="shipment_source" 
                                                    onChange={this.onChangeSources.bind(this)} 
                                                    value={this.state.shipment_source} 
                                                    options={this.shipmentsources()}
                                                    required ={true}
                                                />
                                            </div>
                                            
                                            <div className={"form-group col-sm-3 "+(this.state.shipsrcdestshow)}>
                                                <label>{Constant.LABEL_SHIPMENT_DESTINATION}</label>
                                                <Select 
                                                    className="" 
                                                    name="shipment_destination" 
                                                    id="shipment_destination" 
                                                    onChange={this.onChangeDestination.bind(this)} 
                                                    value={this.state.shipment_destination} 
                                                    options={this.shipmentdestinations()}
                                                    required ={true}
                                                />
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label>{Constant.LABEL_SHIPMENT_DOC_NO}</label>
                                                <input type="text" className="form-control" name="shipment_id" id="shipment_id" onChange={this.changeAlphaNumericNOSpeacialHandler} value={this.state.shipment_id} required />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_DATE}</label>
                                                <input type="text" className="datepicker form-control" name="shipment_date" id="shipment_date" onChange={this.changeHandler}  required />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_SHIPMENT_TIME}</label>
                                                <input type="text" className="datetimepicker_time form-control" name="shipment_time" id="shipment_time" onChange={this.changeHandler}  required />
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_ORDER_ITEMS}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                          {/*  
                                            <div className="form-group col-sm-2">
                                                <label>Batch No</label>
                                                <input type="text" className="form-control" name="batch_no" id="batch_no" />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>Delivery No</label>
                                                <input type="text" className="form-control" name="delivery_no" id="delivery_no" />
                                            </div>
                                            <div className="form-group col-sm-1">
                                                <label>SO Number</label>
                                                <input type="text" className="form-control" name="so_number" id="so_number" />
                                            </div>
                                            <div className="form-group col-sm-1">
                                                <label>Item Number</label>
                                                <input type="text" className="form-control" name="so_number" id="so_number" />
                                            </div>
                                            <div className="form-group col-sm-1">
                                                <label>Item Description</label>
                                                <input type="text" className="form-control" name="so_number" id="so_number" />
                                            </div>
                                            
                                            <div className="form-group col-sm-1">
                                                <label>Quantity</label>
                                                <input type="text" className="form-control" name="so_number" id="so_number" />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>Customer Name</label>
                                                <select name="customer_name" id="customer_name">
                                                    <option value="">Select</option>

                                                </select>
                                            </div>
                                            
                                            <div className="form-group col-sm-1">
                                                <label>Customer Code</label>
                                                <input type="text" className="form-control" name="customer_code" id="customer_code" />
                                            </div>
                                           
                                            <div className="form-group col-sm-1">
                                                <label>Customer City</label>
                                                <input type="text" className="form-control" name="customer_city" id="customer_city" />
                                            </div>
                                            
                                            <div className="form-group col-sm-1">
                                                <label>Customer Latitude</label>
                                                <input type="text" className="form-control" name="customer_lat" id="customer_lat" />
                                            </div>
                                            
                                            <div className="form-group col-sm-1">
                                                <label>Customer Longitude</label>
                                                <input type="text" className="form-control" name="customer_lng" id="customer_lng" />
                                            </div>
                                            
                                            <div className="form-group col-sm-1">
                                                <label>Expected Distance (KM)</label>
                                                <input type="text" className="form-control" name="expected_distance" id="expected_distance" />
                                            </div>
                                            <div className="form-group col-sm-1">
                                                <label>Transit Time (Day(s))</label>
                                                <input type="text" className="form-control" name="transit_time" id="transit_time" />
                                            </div>
                                            <div className="col-sm-1">
                                                <button type="button" className="btn btn-default pull-right f28"><i className="icon-plus cus-i"></i></button>
                                            </div>
                                         */}     
                                            <div className="col-sm-12">
                                                <button type="button" onClick={this.addRow.bind(this)} className="btn btn-warning float-right f12">
                                                    <i className="icon-plus"></i>Add Item
                                                </button>
                                            </div>
                                          
                                            <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                                
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													components={this.state.components}
													frameworkComponents={this.state.frameworkComponents}
                                                    singleClickEdit={true}
													//floatingFilter={true}
													onCellEditingStopped={this.onCellEditData.bind(this)}
												    //onCellClicked={this.popmarker}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
												/>
											</div>
                                            
                                            
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row mt-40p">
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-success">Create Shipment</button>
                                    </div>
                                </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				
				<Modal open={open} onClose={this.onCloseModal}  >
	                <div className={""} style={{padding:'5px 0px', overflow: 'hidden',zIndex:'9999',}} >
						<form className="row theme-form" method="post" onSubmit={this.saveNewCustomerForm.bind(this)}>

                            <div className="form-group col-sm-4">
                                <label>Customer Code</label>
                                <input type="text"  value={this.state.newcustomer_code} className="form-control" name="newcustomer_code" id="newcustomer_code" onChange={this.changeAlphaNumericNOSpeacialHandler} required />
                            </div>
                            
                            <div className="form-group col-sm-4">
                                <label>Customer Name</label>
                                <input type="text"  value={this.state.newcustomer_name} className="form-control" name="newcustomer_name" id="newcustomer_name" onChange={this.changeAlphaNumericHandler.bind(this)} required />
                            </div>
                            
                            <div className="form-group col-sm-4">
                                <label>Customer City</label>
                                <input type="text"  value={this.state.newcustomer_city} className="form-control" name="newcustomer_city" id="newcustomer_city" onChange={this.changeAlphaNumericHandler} required />
                            </div>
                            
                            <div className="form-group col-sm-6">
                                <label>Customer Latitude</label>
                                <input type="text"  value={this.state.newcustomer_latitude} className="form-control" name="newcustomer_latitude" id="newcustomer_latitude" onChange={this.changeCoords} required />
                            </div>
                            
                            <div className="form-group col-sm-6">
                                <label>Customer Longitude</label>
                                <input type="text"  value={this.state.newcustomer_longitude} className="form-control" name="newcustomer_longitude" id="newcustomer_longitude" onChange={this.changeCoords} required />
                            </div>
                            <div className="form-group col-sm-12">
                                <button type="submit" className="btn btn-success pull-right f12">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}
$( function() {
    $( ".datepicker" ).datepicker({dateFormat:"dd-mm-yy",maxDate:0});
  } );

function loadDatePicker(){
    $( ".datepicker" ).datepicker();
}
$('body').on('focus',".datepicker", function(){

    if( $(this).hasClass('hasDatepicker') === false )  {
        $(this).datepicker();
    }

});



window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        minDate:1
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
        minDate:0
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false,
        minDate:0
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


