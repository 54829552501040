import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class SecondaryCounters extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            zerofifteen:0,
            fifteenthirty:0,
            thirtyonefortyfive:0,
            morefortyfive:0,
            stockzerofifteen:0,
            stockfifteenthirty:0,
            stockthirtyonefortyfive:0,
            stockmorefortyfive:0,
            zeroamt:0,
            fifteen:0,
            thirty:0,
            moreforty:0
    	}
    
    }
   
    componentWillReceiveProps(newprops){
       this.setState({
            zerofifteen:newprops.zerofifteen,
            fifteenthirty:newprops.fifteenthirty,
            thirtyonefortyfive:newprops.thirtyonefortyfive,
            morefortyfive:newprops.morefortyfive,
            stockzerofifteen:newprops.stockzerofifteen,
            stockfifteenthirty:newprops.stockfifteenthirty,
            stockthirtyonefortyfive:newprops.stockthirtyonefortyfive,
            stockmorefortyfive:newprops.stockmorefortyfive,
            zeroamt:newprops.zeroamt,
            fifteen:newprops.fifteen,
            thirty:newprops.thirty,
            moreforty:newprops.moreforty
       })

    }
    
    render(){
        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body">
                            <div className="crm-numbers pb-0">
                                <div className="row">                               
                                    <div className="col cirlce-d cpointer">
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> 0-15 Days</span>
                                        {/* <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.zerofifteen != '')?this.state.zerofifteen:0}/></span></h4> */}
                                        <div className="row col-xl-12 col-lg-12 n-p-0">
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Secondary Bills: </span>
                                                <span className="counter f26 txt-info"><CountUp end={(this.state.zerofifteen != '')?this.state.zerofifteen:0}/></span>
                                            </div>
                                            {/* <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Stockyard Bills: </span>
                                                <span className="counter f26 greenfont"><CountUp end={(this.state.stockzerofifteen != '')?this.state.stockzerofifteen:0}/></span>
                                            </div>
                                             */}
                                            <div className="col-xl-12 col-lg-12 n-p-0">                                                
                                                <span>Amount: </span>
                                                <span className="f26 redfont"> {this.state.zeroamt}</span>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="col cirlce-d cpointer">
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> 16-30 Days</span>
                                        {/* <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.fifteenthirty != '')?this.state.fifteenthirty:0}/></span></h4> */}
                                        <div className="row col-xl-12 col-lg-12 n-p-0">
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Secondary Bills: </span>
                                                <span className="counter f26 txt-info"><CountUp end={(this.state.fifteenthirty != '')?this.state.fifteenthirty:0}/></span>
                                            </div>
                                            {/* <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Stockyard Bills: </span>
                                                <span className="counter f26 greenfont"><CountUp end={(this.state.stockfifteenthirty != '')?this.state.stockfifteenthirty:0}/></span>
                                            </div> */}
                                            
                                            <div className="col-xl-12 col-lg-12 n-p-0">                                                
                                                <span>Amount:</span>
                                                <span className="f26 redfont"> {this.state.fifteen}</span>
                                            </div>

                                            {/* <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Secondary Bills:</span>
                                                <span className="f18 greenfont"> {this.state.fifteenthirty}</span>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Stockyard Bills:</span>
                                                <span className="f18 greenfont"> {this.state.stockfifteenthirty}</span>
                                            </div>
                                            
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Amount:</span>
                                                <span className="f18 redfont"> {this.state.fifteen}</span>
                                            </div> */}
                                        </div>
                                    </div> 
                                    <div className="col cirlce-d cpointer">
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> 31-45 Days</span>
                                        {/* <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.thirtyonefortyfive != '')?this.state.thirtyonefortyfive:0}/></span></h4> */}
                                        <div className="row col-xl-12 col-lg-12 n-p-0">
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Secondary Bills: </span>
                                                <span className="counter f26 txt-info"><CountUp end={(this.state.thirtyonefortyfive != '')?this.state.thirtyonefortyfive:0}/></span>
                                            </div>
                                            {/* <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Stockyard Bills: </span>
                                                <span className="counter f26 greenfont"><CountUp end={(this.state.stockthirtyonefortyfive != '')?this.state.stockthirtyonefortyfive:0}/></span>
                                            </div> */}
                                            
                                            <div className="col-xl-12 col-lg-12 n-p-0">                                                
                                                <span>Amount:</span>
                                                <span className="f26 redfont"> {this.state.thirty}</span>
                                            </div>
                                            {/* <div className="col-xl-6 col-lg-6 n-p-0">
                                                <span>No of Bills:</span>
                                                <span className="f18 greenfont"> {this.state.stockthirtyonefortyfive}</span>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 n-p-0">
                                                <span>Amount:</span>
                                                <span className="f18 redfont"> {this.state.thirty}</span>
                                            </div> */}
                                        </div>
                                    </div> 
                                    <div className="col cirlce-d cpointer">
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i txt-info f24"></i><br /> More than 45 Days</span>
                                        {/* <h4 className="txt-info f50"><span className="counter"><CountUp end={(this.state.morefortyfive != '')?this.state.morefortyfive:0}/></span></h4> */}
                                        <div className="row col-xl-12 col-lg-12 n-p-0">
                                            <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Secondary Bills: </span>
                                                <span className="counter f26 txt-info"><CountUp end={(this.state.morefortyfive != '')?this.state.morefortyfive:0}/></span>
                                            </div>
                                            {/* <div className="col-xl-12 col-lg-12 n-p-0">
                                                <span>Stockyard Bills: </span>
                                                <span className="counter f26 greenfont"><CountUp end={(this.state.stockmorefortyfive != '')?this.state.stockmorefortyfive:0}/></span>
                                            </div> */}
                                            
                                            <div className="col-xl-12 col-lg-12 n-p-0">                                                
                                                <span>Amount:</span>
                                                <span className="f26 redfont"> {this.state.moreforty}</span>
                                            </div>


                                            {/* <div className="col-xl-6 col-lg-6 n-p-0">
                                                <span>No of Bills:</span>
                                                <span className="f18 greenfont"> {this.state.stockmorefortyfive}</span>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6 n-p-0">    
                                                <span>Amount:</span>
                                                <span className="f18 redfont"> {this.state.moreforty}</span>
                                            </div> */}
                                        </div>
                                    </div> 
                                </div>
                            </div>                       
                        </div>
                    </div>
                </div>
           
            </div>
        );
    }
}






