
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const ViewShipmentItemAction = (props) => {

    return (
        <a href={"/submitlrnos/"+(props.data.shipment_id)} className="custom-btn label label-success f12" title="View Shipment Items"><i className="icofont icofont-eye f12"></i> View</a>
    );
};

export default ViewShipmentItemAction;
