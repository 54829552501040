import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const TruckConsignmentLegs = (props) => {
    //console.log("LoadingDelay ",props);
   const handleClick = (e) => {
        e.stopPropagation();
        console.log("props ", props);
       props.context.componentParent.onShowTruckConsignments(props);
    };
   
    return (
        <div>
            <button onClick={handleClick} className="custom-btn f22 label label-success" title="Consignments" ><i className="icofont icofont-truck mildgreen"></i> &nbsp;</button>
            
        </div>
    );
};

export default TruckConsignmentLegs;
