import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types'
import { isArray } from 'highcharts';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class Loginform extends Component{
	constructor(props) {
		super(props);
		var pageTitle = "Login";
		this.state = {
			//pageTitle: pageTitle,
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			style: {},
			email:'',
			password:'',
			loginMessage:'',
			currentDepartmentCode: null 
		};
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		this.logPageView(); // Log page view to GA		
	};
	componentWillReceiveProps(){
		var pageTitle = "Login";
		this.setState({
			pageTitle: pageTitle,
		});
	};
	changeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
	}
	formHandler = (event) => {
		console.log(this.state.login_id);
		console.log(this.state.password);
	}

	getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []

		
		let dashboardUrl = "dashboard/default";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}

		//console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
		//console.log("localStorage roles ", localStorage.getItem("roles"))
		if(localStorage.getItem("is_enmovil_transporter") == 1)
		{
			dashboardUrl="/tptlogin/gpsintegrationstatus";
		}
		else
		{
			switch(currentDepartmentCode) {
				case "SNDG":
					//dashboardUrl="/dashboard/snddefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_rail") >= 0)
						{
							dashboardUrl="/railconsignments";
						}
						else
						{
							dashboardUrl="/sndtransporterconsignments";
						}
					}
					else{
						var userroles = localStorage.getItem("roles");
						try{

							if (userroles.indexOf("AG2") >= 0)
							{
								dashboardUrl="/sndconsignments";
							}
							else if (userroles.indexOf("led") >= 0)
							{
								dashboardUrl="/livemaptrucklocation";
							}
							else if (userroles.indexOf("GPSUSER") >= 0)
							{
								dashboardUrl="/mlltruckgps";
							}
							else{
								dashboardUrl="/snddashboardtemplate";
							}
						}
						catch(e){
							dashboardUrl="/snddashboardtemplate";
						}
					}
					break;
					
				case "LOG-PRT":
					//dashboardUrl="/dashboard/prtdefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_rail") >= 0)
						{
							dashboardUrl="/railconsignments";
						}
						else if (userroles.indexOf("tpt_courier") >= 0)
						{
							dashboardUrl="/activecourierconsignments";
						}

						else if (userroles.indexOf("tpt_alloybars") >= 0)
						{
							dashboardUrl="/alloybarsComponent";
						}
						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else
						{
							dashboardUrl="/prttransporterconsignments";
						}
					}
					else if (localStorage.getItem("roles") == "PACC")
					{
						dashboardUrl="/prtallconsignments";
					}
					else{
						dashboardUrl="/prtdashboardtemplate";
					}
					
					break;
				case "LOG-TNP":
					//dashboardUrl="/dashboard/tnpdefault";
					dashboardUrl="/tnpdashboardtemplate";
					break;
				case "LOG-SC":
					//dashboardUrl="/dashboard/scdefault";
					if(localStorage.getItem("user_type") == 'TRANSPORTER')
					{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("tpt_alloybars") >= 0)
						{
							dashboardUrl="/alloybarsComponent";
						}

						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else if(userroles.indexOf("lcl_containers") >= 0){
							dashboardUrl="/lclimports";
						}
						else if(userroles.indexOf("fcl_dsr") >= 0){
							dashboardUrl="/importsshipments";
						}
						else if (userroles.indexOf("sc_container_alloys") >= 0)
						{
							dashboardUrl="/imports/consignments";
						}
						else
						{
							dashboardUrl="/imports/consignments";
						}
					}
					else{
						var userroles = localStorage.getItem("roles");
						if (userroles.indexOf("sc_ppc") >= 0)
						{
							dashboardUrl="/imports/consignments";
						}
						else if (userroles.indexOf("sc_exports") >= 0)
						{
							dashboardUrl="/exports/consignments";
						}
						else if(userroles.indexOf("actl") >= 0){
							dashboardUrl="/actlcontainers";
						}
						else if (userroles.indexOf("sc_container_alloys") >= 0)
						{
							dashboardUrl="/imports/consignments";
						}
						else
						{
							dashboardUrl="/imports/consignments";
						}
					}

					
					break;
				case "SUP-CHN":
					//dashboardUrl="/dashboard/scdefault";
					dashboardUrl="/imports/consignments";
					break;
				
				case "Customs Coils":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl="/chacoils";
					break;
				case "Customs Containers":
					//dashboardUrl="/dashboard/prtdefault";
					dashboardUrl="/chanhavasheva";
					break;
				case "AD":
					//dashboardUrl="/dashboard/scdefault";
					var userroles = localStorage.getItem("roles");
					if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
					{
						dashboardUrl="/lmloadsummary";
					}
					else if (userroles.indexOf("SITE_ADMIN") >= 0 || userroles.indexOf("SR MGMT") >= 0)
					{
						dashboardUrl="/managementdashboard";
					}
					
					else if (userroles.indexOf("MahindraAdmin") >= 0)
					{
						dashboardUrl="/mllsummary";
					}
					else
					{
						dashboardUrl="/livetrucksinfo";
					}
					break;
				case "LM":
					var userroles = localStorage.getItem("roles");
					//var usermenus = localStorage.getItem("usermenus");
					// if (userroles.indexOf("lm_admin") >= 0 || userroles.indexOf("lm_user") >= 0)
					// {
						
					// }
					if (userroles.indexOf("lm_site_admin") >= 0)
					{
						dashboardUrl="/lmdepartments";
					}
					else
					{
						dashboardUrl="/lmwelcome";
					}
					break;
				case "SND DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
				case "TNP DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
				
				case "PRT DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
			
				case "SC DDVM":
					//dashboardUrl="/dashboard/snddefault";
					dashboardUrl="/dashboardsummary";
					break;
				default:
					let isTmsUser = false;
					currentDepartmentCode = currentDepartmentCode.trim();
					if (currentDepartmentCode !== "SA") {
						// TMS users don't belong to any department
						let userMenuCodes = JSON.parse(localStorage.getItem("usermenucodes"));
						if (Array.isArray(userMenuCodes)) {
							isTmsUser = userMenuCodes.includes("tms");
						} else if (userMenuCodes == "tms") {
							isTmsUser = true; 
						}
					} else {
						console.log("IN default, ELSE, currentDepartmentCode = ", currentDepartmentCode);
					}
					console.log("isTmsUser ",isTmsUser)
					if (isTmsUser) {
						dashboardUrl="/ticketmanagementsystem";
					} else {
						dashboardUrl="/dashboard/default";
					}
					
					console.log("IN getUserDashboardUrl, default, params = ", departmentCode);
			}
		}
		
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}
	render(){
		if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			let dashboardUrl = this.getUserDashboardUrl();
			//let dashboardUrl = this.state.landingpage;
			// return <Redirect to="dashboard/snddefault" push={true} />
			return <Redirect to={dashboardUrl} push={true} />
		}
		return (
                 <form className="theme-form" onSubmit={this.formHandler} >
					 {(this.state.loginMessage)?
					 <div className="alert alert-danger">{this.state.loginMessage}</div>
						:""}
                     <div className="form-group">
                         <label className="col-form-label pt-0">Login ID</label>
                         <input required type="text" name='loginId' className="form-control form-control-lg" value={this.state.loginId} onChange={this.changeHandler} />
                     </div>
                     <div className="form-group">
                         <label className="col-form-label">Password</label>
                         <input required type="password" name='password' value={this.state.password} onChange={this.changeHandler} className="form-control form-control-lg"/>
                     </div>
                     <div className="form-group form-row mt-3">
						 <div className="col-md-12 textAlignCenter">
                             <button type="submit" id="loginButton" className="btn btn-secondary">LOGIN
                             </button>
                         </div>
                     </div>

                 </form>
		)
	}
};
function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
       try {
         MENUITEMS = JSON.parse(menuText);
       } catch (error) {
           console.log('Error while Parsing menuText', error);
       }
       return MENUITEMS;
}
Loginform.contextTypes={
		router:PropTypes.object.isRequired
}


