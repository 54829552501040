
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiTicketTransitDelay = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props.data);
        
            props.context.componentParent.onShowTransitKpi(props);
        
        
        
    };
    

    return (
        <div>
			<button onClick={handleOverspeedClick} className="custom-btn f22 label label-success" title="Transit Delay" style={{color:"#ff0000"}}><i className="icofont icofont-clock-time"></i> &nbsp;</button>
            
        </div>
    );
};

export default KpiTicketTransitDelay;
