/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import store from '../../store/index'
import Select from "react-select";
import _, { toUpper } from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
// import DrawMap from "../common/drawmap";
// import ConsignmentActions from "../manage/ConsignmentActionsComponent";
// import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import CSVFileValidator from 'csv-file-validator'
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");


export default class BillingReport extends Component {
    state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        uploadDivWidth: "0%",
        sliderTranslate: "",
        sliderRouteTranslate: "",
        tabsliderTranslate: "",
        loadshow: "show-n",
        showDiv: "show-n",
        alertshow: "fade",
        alertmg: "show-n",
        alerterrmg: "show-n",
        overly: "show-n",
        alerterrshow: "fade",
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
        show1: false,
        basicTitle1: "",
        basicType1: "default",
        modules: AllModules,
        sidebarSubHeader: "",
        defTransitCoords: "",
        csvcontent: [],
        consigner: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        rowData: [],
        monthSelected: "",
        yearSelected: "",
        firstMailSent: "",
        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        overlayNoRowsTemplate: "",

        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,

        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        rowClassRules: {
            // "highlitRow": "data.transit_delay == 1",
            // "green":"data.status >= 3"
        },
        startDate: "",
        endDate: "",
        mapinfo: "",
        defaultsdate: "",
        defaultedate: "",
        withdrawmodal: false,
        parkingyard: "show-m",
        truckinfo: "show-n",
        pact: "btn-warning",
        tact: "btn-defaultc",
        truckslist: [],
        truck_no: { value: "", label: "Select Truck" },
        dept_code: "",
        transporters: [],
        transporter_code: { value: "", label: "Select Transporter" },
        struckslist: [],
        struck_no: { value: "", label: "Select Truck" },
        radius: "5000",
        rlatitude: "",
        rlongitude: "",
        latitude: "",
        longitude: "",
        defaultradius: 0,
        coordinates: [],
        alltrucks: [],
        search_truck_no: "",
        search_transporter_name: "",
        search_gps_provider: "",
        search_speed: "",
        search_last_packet_time: "",
        search_gps_status: "",
        search_address: "",
        search_city: "",
        search_state: "",
        search_carrier_reported: "",
        reporttruck: 0,
        plants: [],
        plant_code: { value: "", label: "Select Parking Yard" },
        withdrawdata: "",
        withdraw_reason: "",
        gpsplant: "",
        gpsradius: 15,
        mwidth: "col-xl-5 col-lg-5",
        rowData1: [],
        nsearch: "show-n",
        selectedTabLat: "",
        loginplant: "",
        filteredData: [],
        consignment_code: '',
        showTrucksList: "show-n",
        manual_parking_out: "",
        parkingdata: "",
        slideerParkingOut: "",
        selectedMonth: moment.parseZone(new Date()).format("YYYY-MM"),
        uptime_sheet_1: [],
        uptime_sheet_2: [],
        uptime_sheet_3: [],
        uptime_sheet_4: [],
        date_keys: [],
        overall_btn: "btn-success",
        monthly_btn: "btn-default",
        daywise_btn: "btn-default",
        tptapproval_btn: "btn-default",
        tptremainder_btn: "btn-default",
        overall_data: "show-m",
        monthly_data: "show-n",
        daywise_data: 'show-n',
        tptapproval_data: 'show-n',
        tptremainder_data: 'show-n',
    };





    componentDidMount() {
        this.onLoadData()
    }

    onLoadData = () => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })

        var params = {

        }

        redirectURL.post("/consignments/customersData", params).then(response => {
            var records = response.data
            console.log(records, "records")
            this.setState({
                rowData: records,
                loadshow: 'show-n',
                overly: 'show-n'
            })
        })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
            sliderTranslate : "",
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
    };

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if (parseInt(selDay) < 10) {
            selDay = "0" + selDay;
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            selectedMonth: plan_month
        });
        this.onLoadData(new Date(plan_month))

    }


    onUploadFile = (key) => {
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            overly:"show-m",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
            slider_name: key
        });
    }

    onRemainderMails = (key) => {
        console.log(key, "key")
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
            slider_name: key
        });
    }

    onAddTransporters = (key) => {
        this.setState({
            uploadDivWidth: '40%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
            slider_name: key
        });
    }

    resetUpload = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            overly: 'show-n'
        });
        document.getElementById("upform").reset();
    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly:'show-n'
        });
        document.getElementById("upform").reset();
    }

    handlermonthYear = (event) => {
        var d = new Date(event._d);
        var monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        var month = monthNames[d.getMonth()];
        var year = d.getFullYear();
        this.setState({
            monthSelected: month,
            yearSelected: year,
        })
    }

    handlerFirstMailSentOn = (event) => {
        console.log(event._d, "check_date")
        var d = new Date(event._d);
        var firstMailSent = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + " 00:00:00";
        this.setState({
            firstMailSent
        })
    }

    changeConsignerArrayHandler = consigner => {
        this.setState(
            { consigner },
            // () => console.log(`Consigner Option selected:`, this.state.consigner)
        );

        // this.setState({
        // 	errorMessage: '',
        // 	successMessage: ''
        // })
    }

    selectConsignerOptionsItems = () => {
        let items = [];

        var transporter_codes = this.state.uptime_sheet_4;

        // items.push({ value: 'all', label: 'All' });
        if (transporter_codes.length > 0) {
            transporter_codes.forEach(item => {
                items.push({ value: item.transporter_code, label: item.transporter_code });
            });
        }

        return items;
    }

    changeFileHandler = (e) => {
        const requiredColumns = ['Customer Code','Additional Email Id','Additional Contact Name','Additional Contact Number', 'Customer Name', 'Customer Address', 'Customer City','Customer Country', 'Customer Pincode',
            'Customer State', 'Epod Email Id', 'Epod Contact Name', 'Epod Contact Number', 'Exception Email Id', 'Exception Contact Name', 'Exception Contact Number', 'FME Contact Number', 'FME Contact Name', 'Customer Contact Number',
            'Plant Code'];

        const config = {
            headers: requiredColumns.map((columnName) => ({
                name: columnName,
                inputName: columnName,
                // required: true,
                requiredError: function (headerName) {
                    return `Column ${headerName} is required`;
                },
            })),
        };

        
        if (e.target.files[0] !== undefined) {
            console.log(e.target.files[0].type, 'text/csv')
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                this.setState({
                    file: e.target.files[0]
                });
                CSVFileValidator(e.target.files[0], config)
                    .then((csvData) => {
                        console.log("csvdata", csvData);
                        const columnsInFile = Object.keys(csvData.data[0]);
                        const missingColumns = requiredColumns.filter(column => !columnsInFile.includes(column));

                        if (missingColumns.length === 0) {
                            this.setState({
                                csvcontent: csvData.data,
                            });
                        } else {
                            $(`#uploadFile`).val("")
                            this.setState({
                                show: true,
                                basicType: 'danger',
                                basicTitle: `The following required columns are missing in the CSV file: ${missingColumns.join(', ')}`,
                            });
                        }
                    })
                    .catch((err) => {
                        console.error("Error during CSV validation:", err);
                    });
            }
            else {
                e.target.value = null;
                $(`#uploadFile`).val("")
                this.setState({
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
    };

    uploadFormHandler = (event) => {
        event.preventDefault();

        let csvContent = this.state.csvcontent
        console.log(csvContent, "csvcontent")

        if (csvContent.length > 0) {
            redirectURL.post("/consignments/insertCustomerData", csvContent).then((response) => {
                console.log(response.data,"response.data")
                if (response.data.status == "Failure") {
                    $(`#uploadFile`).val("")
                    this.setState({
                        show: true, basicType: 'danger', basicTitle: response.data.message,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    });
                    this.resetUpload()

                }
                else {
                    $(`#uploadFile`).val("")
                    this.setState({
                        show: true, basicType: 'success', basicTitle: response.data.message,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    });
                    this.resetUpload()

                }
            }).catch(function (error) {
                console.log("error", error);
            });
            // })
        }
    }


    //     if (nonTransporters.length == 0) {


    //         console.log(rparams, "rparams")



    //         console.log('rparams', rparams);
    //         this.setState({
    //             loadshow: 'show-m'
    //         });
    //         let url = "/manage/uploadTruckDetailsCSV"
    //         redirectURL.post(url, rparams,).then((response) => {
    //             // console.log(response);
    //             //console.log(response.data.status);
    //             document.getElementById("upform").reset();
    //             if (response.data.status == "Failure") {
    //                 this.setState({
    //                     //csverrmessage:response.data.status,
    //                     show: true, basicType: 'danger', basicTitle: response.data.message,
    //                     loadshow: 'show-n',
    //                 });

    //             }
    //             else {
    //                 this.setState({
    //                     show: true, basicType: 'success', basicTitle: "Successfully Uploaded",
    //                     loadshow: 'show-n',
    //                 });

    //             }
    //         }).catch(function (error) {
    //             console.log("error", error);
    //         });
    //     }
    //     else {
    //         this.setState({
    //             //csverrmessage:response.data.status,
    //             show: true,
    //             basicType: 'danger',
    //             basicTitle: `Please Add these Transporters to Proceed ${nonTransporters}`,
    //             loadshow: 'show-n'
    //         });
    //     }
    // }
    // else {
    //     this.setState({
    //         //csverrmessage:response.data.status,
    //         show: true,
    //         basicType: 'danger',
    //         basicTitle: "Please Upload File",
    //         loadshow: 'show-n'
    //     });



    onSelectRemainderMailTransporters = (event) => {
        event.preventDefault();
        if (this.state.consigner.length > 0 && this.state.firstMailSent != "" && this.state.monthSelected != "" && this.state.yearSelected != "") {
            var nonTransporters = []
            var nonEmails = []
            this.state.consigner.map((item) => {
                if (this.state.currentTransporters.includes(item.value)) {
                    this.state.uptime_sheet_4.map(items => {
                        if (items.transporter_code == item.value) {
                            if (items.email == "") {
                                nonEmails.push(item.value)
                            }
                        }
                    })
                }
                else {
                    nonTransporters.push(item.value)
                }
            })
            if (nonTransporters.length == 0 && nonEmails.length == 0) {
                var rparams = {
                    monthSelected: this.state.monthSelected,
                    yearSelected: this.state.yearSelected,
                    firstMailSent: this.state.firstMailSent,
                    transporter_list: this.state.consigner.map(item => item.value)
                }

                // console.log(rparams, "rparams")
                this.setState({
                    loadshow: 'show-m'
                });
                let url = "/manage/tptRemainderMails"
                redirectURL.post(url, rparams,).then((response) => {
                    document.getElementById("upform").reset();
                    if (response.data.status == "Failure") {
                        this.setState({
                            //csverrmessage:response.data.status,
                            show: true, basicType: 'danger', basicTitle: response.data.message,
                            loadshow: 'show-n',
                        });

                    }
                    else {
                        console.log(response.data.status, "response.data.status")
                        this.setState({
                            show: true, basicType: 'success', basicTitle: "Successfully Sent Remainder Mails",
                            loadshow: 'show-n',
                        });

                    }
                }).catch(function (error) {
                    console.log("error", error);
                });
            }
            else if (nonTransporters.length > 0) {
                this.setState({
                    //csverrmessage:response.data.status,
                    show: true,
                    basicType: 'danger',
                    basicTitle: `Please Add these Transporters to Proceed ${nonTransporters}`,
                    loadshow: 'show-n'
                });
            }
            else {
                this.setState({
                    show: true,
                    basicType: 'danger',
                    basicTitle: `Please Add emails to this transporters to proceed ${nonEmails}`,
                    loadshow: 'show-n'
                });
            }
        }
        else {
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: "All fields are required",
                loadshow: 'show-n'
            });
        }
    }
    //tptremaindermails
    render() {

        var columnwithDefs = [
            {
                headerName: "Customer Code",
                field: "consignee_code",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Additional Email Id",
                field: "additional_email_id",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Name",
                field: "customer_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant Code",
                field: "consigner_code",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Additional Contact Name",
                field: "additional_contact_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Additional Contact Number",
                field: "additional_contact_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Address",
                field: "consignee_address",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer City",
                field: "consignee_city",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Country",
                field: "consignee_country",
                width: 120,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Consignee Name",
            //     field: "consignee_name",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            // },
            {
                headerName: "Customer Pincode",
                field: "consignee_pincode",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer State",
                field: "consignee_state",
                width: 120,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Customer Code",
            //     field: "customer_code",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            // },
            {
                headerName: "Epod Email Id",
                field: "epod_email_id",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Epod Contact Name",
                field: "epod_contact_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Epod Contact number",
                field: "epod_contact_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Exceptional Email ID",
                field: "exception_email_id",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Exceptional Contact Name",
                field: "exception_contact_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Exceptional Contact Number",
                field: "exception_contact_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "FME Contact Number",
                field: "fme_contact_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "FME Contact Name",
                field: "fme_contact_name",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Customer Mobile Number",
                field: "consignee_mobile_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Source From",
            //     field: "source_from",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            // },

            {
                headerName: "Created Date",
                field: "created_date",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.created_date == "") {
                        return "N/A";
                    }
                    else {
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date)
                    }
                },
                comparator: dateComparator,
            },
        ]



        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="ml-2">
                                Customers
                                <button className="btn btn-success float-right" type="button" onClick={this.onUploadFile.bind(this, "uploadfile")}>Upload File</button>

                            </h4>
                        </div>
                        {/* <div className="col-xl-12 col-lg-12" style={{ float: "right" }}>
                            <div style={{ float: "right" }}>
                                <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                            </div>
                        </div> */}

                        <div className={"card-body "}>
                            <div
                                id="myGrid"
                                style={{ height: "580px", width: "100%" }}
                                className={"ag-theme-balham "}
                            >
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    frameworkComponents={this.state.frameworkComponents}
                                    detailCellRendererParams={
                                        this.state.detailCellRendererParams
                                    }
                                    //editType={editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    //floatingFilter={true}
                                    //enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    rowClassRules={this.state.rowClassRules}
                                    //onCellClicked={this.onRowClicked.bind(this)}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    masterDetail={true}
                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                    <div className={"slide-r " + (this.state.sliderTranslate)} >

                        <div className="slide-r-title">
                            <h4>
                                Upload File
                                {/* <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span> */}
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className={"dataLoad " + (this.state.loadshow)}>
                                <div style={{ position: "absolute", left: "38%" }}>
                                    <div class="loader-box">
                                        <div class="loader-box">
                                            <div class="rotate dashed colored"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                                <form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>
                                    <div className="form-group mt-20p">
                                        <label className="">Upload File</label>
                                        <input type="file" id="uploadFile" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                    <div className="form-group">
                                        <a className="btn btn-primary" href={require('../../assets/json/customers_template.csv')} target="_blank">Sample Template</a>
                                    </div>
                                </form>
                                {/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
                            </div>
                        </div>
                    </div> 
                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}


function ConvertSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return (
        days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
    );
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = num / 3600;
    var rhours = parseInt(hours);
    var minutes = (num - rhours * 3600) / 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker() {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
    });
}

window.onload = function () {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    $(".styles_modal__gNwvD").css({ width: "450px" });
};
document.addEventListener("turbolinks:before-render", () => {
    loadDateTimeScript();
});

function loadDateTimeScript() {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#manual_truck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#manual_truck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        hrs = hh;
    }
    return hrs + ":" + mins;
}