
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
var redirectURL = require('../redirectURL');

 
const RemoveGridRow = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.deleteRow(props);
    
    }   
   
    return (
        <div>
            <button type="button" onClick={handleEventClick} className="custom-btn f11 label label-success" 
            title="Remove Row" ><i className={"icofont icofont-trash "}></i> Remove</button>
            
        </div>
    );
};

export default RemoveGridRow;
