import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { matchesProperty, transform } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');

var redirectURL = require('../redirectURL');
var moment = require('moment');

class LoadTimeInDaysReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            basicType: "",
            basicTitle: "",
            loadshow: "show-n",
            overly: "show-n",
            road_data_arr: [],
            rail_data_arr: [],
            stack_intrasit_data: [],
            url_path: window.location.pathname
        };
    }

    componentDidMount() {
        this.loadTimeDaysData();
        this.loadStackInTransit();
    }
    onClickHideAll = () => {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
        })
    }


    loadTimeDaysData = async () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });

        try {
            const edate = moment().format('YYYY-MM-DD HH:mm:ss');
            const sdate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');

            const parameters = [
                { shipment_mode: "ROAD" },
                { shipment_mode: "RAIL" }
            ];

            const fetchData = async (params) => {
                try {
                    const response = await redirectURL.post("/consignments/truckTimeInDays", {
                        from_date: sdate,
                        to_date: edate,
                        ...params
                    });
                    var data_response = Array.isArray(response.data) ? response.data  : []
                    return data_response;
                } catch (error) {
                    console.error("Error fetching data:", error);
                    return [];
                }
            };

            const [roadData, railData] = await Promise.all(parameters.map(fetchData));

            this.setState({
                road_data_arr: roadData,
                rail_data_arr: railData,
                loadshow: "show-n",
                overly: "show-n",
            });

        } catch (error) {
            console.error("Error:", error);
        }
    };

    loadStackInTransit = async () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });

        try {
            const stackStartDate = moment().subtract(1, 'month');
            const startDate = stackStartDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const params = {
                from_date: startDate,
                to_date: endDate
            }
            const response = await redirectURL.post("/consignments/stackInTransit", params);
            var stack_intrasit_data_arr = response.data || []
            // item_quantity_road
            // item_quantity_rail_load_port
            // item_quantity_rail
            // item_quantity_rail_dis_port
            // item_quantity_last_lag
            stack_intrasit_data_arr = stack_intrasit_data_arr.map(item => {
                const {
                    item_quantity_road = 0,
                    item_quantity_rail_load_port = 0,
                    item_quantity_rail = 0,
                    item_quantity_rail_dis_port = 0,
                    item_quantity_last_lag = 0,
                } = item
                const total_quantity = item_quantity_road + item_quantity_rail_load_port + item_quantity_rail + item_quantity_rail_dis_port + item_quantity_last_lag
                return {
                    ...item,
                    total_quantity
                }
            })
            console.log('responseStackIn', response)
            this.setState({
                loadshow: "show-n",
                overly: "show-n",
                stack_intrasit_data: stack_intrasit_data_arr
            });


        } catch (error) {
            console.error("Error:", error);
        }
    };




    render() {

        const tableHeaderStyle = {
            border: '1px solid #000',
            background: 'rgb(36 120 159)',
            color: 'white',
            textAlign: 'center',
            padding: '10px'
        };

        const tableCellStyle = {
            border: '1px solid #000',
            padding: '8px',
            textAlign: 'center',
            textTransform: 'uppercase',
        };


        return (
            <>
                <div className="card-body pt-15px" style={{ width: 'auto' }}>
                    <div style={{ width: '100%', }}>
                        <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px', marginRight: '12px' }}>
                            <div className="card-header" style={{ textAlign: 'center' }}>
                                <h5>
                                    LEAD TIME IN DAYS
                                </h5>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '100%', marginRight: '14px' }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '14px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Road Days</th>
                                            </tr>

                                            <tr>
                                                <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>Year</th>
                                                <th style={tableHeaderStyle}>Pre Month</th>
                                                <th style={tableHeaderStyle}>Current Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.road_data_arr.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.year * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.last_month * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.current_month * 100) / 100}</td>


                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.road_data_arr.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                                <div style={{ width: '100%', marginLeft:'14px'}}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', marginRight: '12px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Rail Days</th>
                                            </tr>

                                            <tr>
                                                <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>Year</th>
                                                <th style={tableHeaderStyle}>Pre Month</th>
                                                <th style={tableHeaderStyle}>Current Month</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rail_data_arr.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.year * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.last_month * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.current_month * 100) / 100}</td>

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.rail_data_arr.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>
                            </div>

                        </div>
                        <div style={{ width: '100%', paddingTop: '10px', paddingRight: '12px', marginLeft: '12px' }}>
                            <div className="card-header" style={{ textAlign: 'center' }}>
                                <h5>
                                    STOCK IN TRANSIT
                                </h5>
                            </div>
                            <div style={{ width: '100%', }}>
                                <div style={{ width: '100%', }}>
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px', height: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={7} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>Quantity</th>

                                                <th colSpan={1} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000', }}>No's</th>
                                            </tr>

                                            <tr>
                                                <th style={tableHeaderStyle}>Zone</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                                <th style={tableHeaderStyle}>Rail Load Port</th>
                                                <th style={tableHeaderStyle}>Rail</th>
                                                <th style={tableHeaderStyle}>Rail Dis Port</th>
                                                <th style={tableHeaderStyle}>Last Lag</th>
                                                <th style={tableHeaderStyle}>Total</th>
                                                <th style={tableHeaderStyle}>Road</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.stack_intrasit_data.map((row, index) => (
                                                <tr key={index}>
                                                    <td style={tableCellStyle}>{(row.transporter_zone)}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.item_quantity_road * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.item_quantity_rail_load_port * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.item_quantity_rail * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.item_quantity_rail_dis_port * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.item_quantity_last_lag * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{Math.round(row.total_quantity * 100) / 100}</td>
                                                    <td style={tableCellStyle}>{row.truck_no_road}</td>

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                    {this.state.stack_intrasit_data.length == 0 &&
                                        <div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
                {/* <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
            </>
        );
    }
}

export default LoadTimeInDaysReport;

