import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';
import $ from 'jquery';

var moment = require('moment');

// let hostName = window.location.hostname;
// let viewId = 215794226;

// if (hostName == "localhost") {
//   viewId = "226937159";
// } else if (hostName == "164.52.197.10") {
//   // Test Server - http://164.52.197.10:3000/dashboard/default
//   viewId = "UA-162953564-3";
// }else {
//   // trackingId = "G-XSH6DM5EWC";
//   viewId = "UA-162953564-4"; // Autometrics Web
// }
 

(function(w,d,s,g,js,fs){
  g=w.gapi||(w.gapi={});g.analytics={q:[],ready:function(f){this.q.push(f);}};
  js=d.createElement(s);fs=d.getElementsByTagName(s)[0];
  js.src='https://apis.google.com/js/platform.js';
  fs.parentNode.insertBefore(js,fs);js.onload=function(){g.load('analytics');};
}(window,document,'script'));

export default class GoogleAnalytics extends Component{
	constructor(props) {
    super(props);
    this.state = {
    tokenData : "",
    loadshow:"",
    }
	}
	
	componentDidMount(){
     
    loadDateTimeScript();
    var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
    var date = moment.parseZone(d).format("YYYY-MM-DD");
    //date.setDate(date.getDate()-1);
    var yesterdayDate = moment.parseZone(d).subtract(2,'d').format('YYYY-MM-DD');
    console.log(yesterdayDate);
    $("#to_date").val(yesterdayDate);

    //var date1 = moment.parseZone(d).format("YYYY-MM-DD");
    //date1.setDate(date.getDate()-8);
    var eightDaysAgoDate = moment.parseZone(d).subtract(9,'d').format('YYYY-MM-DD');
    console.log(eightDaysAgoDate);
    $("#from_date").val(eightDaysAgoDate);

    redirectURL.post("/consignments/getAccessTokenForGoogleAnalyticsApi")    
    .then((response) => {
        var tokenData = response.data;
        console.log("tokenData ", tokenData)
        window.gapi.analytics.ready(function() {
          window.gapi.analytics.auth.authorize({
            // container: 'embed-api-auth-container',
            // clientid: '760680019538-dcbujo3dbbl0ii3stb88vu5ov0jo0nti.apps.googleusercontent.com'
            'serverAuth': {
              'access_token': tokenData,
            }
          });

          var dataChart = new window.gapi.analytics.googleCharts.DataChart({
            query: {

              metrics: 'ga:uniqueEvents',
              dimensions: 'ga:eventCategory',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226"
            },
            chart: {
              container: 'chart-container',
              type: 'PIE',
              options: {
                width: '100%'
              }
            }
          });

          var dataChart1 = new window.gapi.analytics.googleCharts.DataChart({
            query: {
              metrics: 'ga:totalEvents',
              dimensions: 'ga:date',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226"
            },
            chart: {
              container: 'chart-container-3',
              type: 'LINE',
              options: {
                width: '100%'
              }
            }
          });

          var dataChart2 = new window.gapi.analytics.googleCharts.DataChart({
            query: {
              metrics: 'ga:users',
              dimensions: 'ga:date',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226" 
            },
            chart: {
              container: 'chart-users',
              type: 'LINE',
              options: {
                width: '100%'
              }
            }
          });

          var dataChart3 = new window.gapi.analytics.googleCharts.DataChart({
            query: {
              metrics: 'ga:sessions',
              dimensions: 'ga:date',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226"
            },
            chart: {
              container: 'chart-sessions',
              type: 'LINE',
              options: {
                width: '100%'
              }
            }
          });

          var dataChart4 = new window.gapi.analytics.googleCharts.DataChart({
            query: {
              metrics: 'ga:avgSessionDuration',
              dimensions: 'ga:date',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226"
            },
            chart: {
              container: 'chart-sessions-1',
              type: 'LINE',
              options: {
                width: '100%'
              }
            }
          });

          dataChart.execute();
          dataChart1.execute();
          dataChart2.execute();
          dataChart3.execute();
          dataChart4.execute();

          $(".dataLoadpageimg").hide();
        });
    })
    .catch(function(e){
        console.log("Error ",e)
    });
    this.clickHandler = this.clickHandler.bind(this);
    this.changeUserHandler = this.changeUserHandler.bind(this);

	};

	render(){
		
		return (
                <div className="container-fluid">
                     <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>
                                        <i className="icofont icofont-dashboard-web cus-i"></i> <span>Usage Analytics</span>
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div style={{top:"19%"}} className={"dataLoadpageimg " +(this.state.loadshow)}>
                                      <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                                    </div>
                                    
                                  <div id="date-range-selector-1-container">
                                       <div class="row">
                                       <div id="view-selector-container"></div>
                                          <div class="col-xl-3">
                                              <label className="replacement-time">From Date</label>
                                              <input type="text" name="start-date" autoComplete="off" class="form-control transhipment-datetime datetimepicker_date" id="from_date" required/>
                                          </div>
                                          <div class="col-xl-3">
                                              <label className="replacement-time">To Date</label>
                                              <input type="text" name="end-date" autoComplete="off" class="form-control transhipment-datetime datetimepicker_date" id="to_date" required/>
                                            </div>
                                          <div class="col-xl-3">
                                            <button type="button" class="btn btn-info" id="dateFilter" style={{marginTop: "28px"}} onClick={this.clickHandler}>Fetch</button>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                          <div class="card-header"><h5>Events</h5></div>
                                        </div>
                                        <div class="col-xl-6">
                                          <div id="chart-container-3"></div>
                                        </div>
                                
                                        <div class="col-xl-6">
                                          <div id="chart-container"></div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                          <div class="card-header"><h5>Users</h5></div>
                                        </div>
                                        <div class="col-xl-3">
                                            <label className="replacement-time">Metrics</label>
                                            <select type="text" class="form-control" id="user_metrics" onChange={this.changeUserHandler}>
                                              <option value="ga:users">Users</option>
                                              <option value="ga:newUsers">New Users</option>
                                              <option value="ga:percentNewSessions">New Sessions</option>
                                              <option value="ga:1dayUsers">1 Day Active Users</option>
                                              <option value="ga:7dayUsers">7 Day Active Users</option>
                                              <option value="ga:14dayUsers">14 Day Active Users</option>
                                              <option value="ga:28dayUsers">28 Day Active Users</option>
                                              <option value="ga:30dayUsers">30 Day Active Users</option>
                                              <option value="ga:sessionsPerUser">No Of Sessions Per User</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                          <div id="chart-users"></div>                                          
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                          <div class="card-header"><h5>Sessions</h5></div>
                                        </div>
                                        <div class="col-xl-6">
                                          <div id="chart-sessions"></div>                                          
                                        </div>
                                        <div class="col-xl-6">
                                          <div id="chart-sessions-1"></div>                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
		)
  }

  clickHandler = (event) => {
    var toDate = $("#to_date").val();
    var fromDate = $("#from_date").val();
    $(".dataLoadpageimg").show();
    if(fromDate !='' && toDate !='')
    {
    redirectURL.post("/consignments/getAccessTokenForGoogleAnalyticsApi")    
      .then((response) => {
          var tokenData = response.data;
          console.log("tokenData ", tokenData)
          window.gapi.analytics.ready(function() {
            window.gapi.analytics.auth.authorize({
              // container: 'embed-api-auth-container',
              // clientid: '760680019538-dcbujo3dbbl0ii3stb88vu5ov0jo0nti.apps.googleusercontent.com'
              'serverAuth': {
                'access_token': tokenData,
              }
            });
  
            var dataChart = new window.gapi.analytics.googleCharts.DataChart({
              query: {
  
                metrics: 'ga:uniqueEvents',
                dimensions: 'ga:eventCategory',
                'start-date': fromDate,
                'end-date': toDate,
                'ids': "ga:215794226"
              },
              chart: {
                container: 'chart-container',
                type: 'PIE',
                options: {
                  width: '100%'
                }
              }
            });
  
            var dataChart1 = new window.gapi.analytics.googleCharts.DataChart({
              query: {
                metrics: 'ga:totalEvents',
                dimensions: 'ga:date',
                'start-date': fromDate,
                'end-date': toDate,
                'ids': "ga:215794226"
              },
              chart: {
                container: 'chart-container-3',
                type: 'LINE',
                options: {
                  width: '100%'
                }
              }
            });

            var dataChart2 = new window.gapi.analytics.googleCharts.DataChart({
              query: {
                metrics: 'ga:users',
                dimensions: 'ga:date',
                'start-date': fromDate,
                'end-date': toDate,
                'ids': "ga:215794226" 
              },
              chart: {
                container: 'chart-users',
                type: 'TABLE',
                options: {
                  width: '100%'
                }
              }
            });
  
            var dataChart3 = new window.gapi.analytics.googleCharts.DataChart({
              query: {
                metrics: 'ga:sessions',
                dimensions: 'ga:date',
                'start-date': fromDate,
                'end-date': toDate,
                'ids': "ga:215794226"
              },
              chart: {
                container: 'chart-sessions',
                type: 'LINE',
                options: {
                  width: '100%'
                }
              }
            });
  
            var dataChart4 = new window.gapi.analytics.googleCharts.DataChart({
              query: {
                metrics: 'ga:avgSessionDuration',
                dimensions: 'ga:date',
                'start-date': fromDate,
                'end-date': toDate,
                'ids': "ga:215794226"
              },
              chart: {
                container: 'chart-sessions-1',
                type: 'LINE',
                options: {
                  width: '100%'
                }
              }
            });
  
            dataChart.execute();
            dataChart1.execute();
            dataChart2.execute();
            dataChart3.execute();
            dataChart4.execute();
            
            $(".dataLoadpageimg").hide();
          });
      })
      .catch(function(e){
          console.log("Error ",e)
      });
    }
    else
    {
      alert("Please select dates to continue");
    }
  }

  changeUserHandler = (event) => {
    let value = event.target.value;
    $(".dataLoadpageimg").show();
    redirectURL.post("/consignments/getAccessTokenForGoogleAnalyticsApi")    
    .then((response) => {
        var tokenData = response.data;
        console.log("tokenData ", tokenData)
        window.gapi.analytics.ready(function() {
          window.gapi.analytics.auth.authorize({
            // container: 'embed-api-auth-container',
            // clientid: '760680019538-dcbujo3dbbl0ii3stb88vu5ov0jo0nti.apps.googleusercontent.com'
            'serverAuth': {
              'access_token': tokenData,
            }
          });

          var dataChart2 = new window.gapi.analytics.googleCharts.DataChart({
            query: {
              metrics: value,
              dimensions: 'ga:date',
              'start-date': '8daysAgo',
              'end-date': 'yesterday',
              'ids': "ga:215794226"
            },
            chart: {
              container: 'chart-users',
              type: 'LINE',
              options: {
                width: '100%'
              }
            }
          });

          dataChart2.execute();
          $(".dataLoadpageimg").hide();
        });
    })
    .catch(function(e){
        console.log("Error ",e)
    });
  }
}
function loadDateTimeScript(){
  // alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'Y-m-d',
    timepicker:false,
    maxDate:true,
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}
