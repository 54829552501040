
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiDeviationActions = (props) => {
   
    const handleDeviateClick = (e) => {
        e.stopPropagation();
        console.log("Deviation ",props);
        props.context.componentParent.onShowDeviate(props);
    };

    return (
        <div>
			<button onClick={handleDeviateClick} className="custom-btn f22 label label-success" title="Deviation"><i className="icofont icofont-social-deviantart"></i>  &nbsp;</button>
       
        </div>
    );
};

export default KpiDeviationActions;
