import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
var redirectURL = require('../redirectURL');
let Components = {};
Components['truck'] = require('../layouts/truckSidebarComponent').default;
Components['transporter'] = require('../layouts/transporterSidebarComponent').default;
Components['lsp'] = require('../layouts/lspSidebarComponent').default;
Components['consignment'] = require('../layouts/consignmentSidebarComponent').default;
Components['activeTrucks'] = require('../layouts/activeTrucksSideBar').default;
export default Components;