import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import RoutemapenrouteAction from '../manage/routemapenroute';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import RoutemapTransitAction from '../manage/routemaptransit';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Grid from './gridComponent';
import Select from 'react-select';

import Modal from 'react-responsive-modal';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class RakesComponent extends Component{
    constructor(props){
		super(props);
		this.state={
            dept_code:"",
            title:"",
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            sliderRouteTranslate:"",
            rowData: null,
            maprowData:null,
            rowSelection:'multiple',
            frameworkComponents: {
				RoutemapenrouteAction:RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
            },
            modules: AllModules,
            mapInfo:"",
            route_details:"",
            animateRows: true,
            detailCellRendererParams:{},
      	    debug: true,
      	    showToolPanel: false,
            uppressAggFuncInHeader: true,
            rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            rownode:"",
            defTransitCoords:"",
            maptruckno:"",
            routeTruck:"",
            loadshow:"",
            sidebarSubHeader:"",
            coords:"",
            overly:"",
            leg_no:"",
            maptransporter:"",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            childconfs: [],
            childrow:[],
            detailCellRendererParams:{},

        }
        this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
        this.onCloseRouteDiv = this.onCloseRouteDiv.bind(this);
        this.onClickHideAll = this.onClickHideAll.bind(this);
    }
    componentDidMount(){
        var urlpath;
        var dpt;
        var title;
        if(this.props.match.path == "/sndrakes")
		{
			urlpath = '/consignments/sndrakes';
			dpt='SNDG';
			title = 'Rakes Consignments (Sales and Dispatch)'
        }
        this.setState({
			dept_code:dpt,
			title:title
        });
        redirectURL.post(urlpath,{dept_code:dpt})
		.then((response) => {
            var rakesData = response.data.message;
			rakesData = JSON.parse(rakesData);
			 if(rakesData.length > 0)
			 {
				 var rakesRespData = rakesData;
			 }
			 else{
				var rakesRespData = [];
			}
             console.log("truckinfo,",rakesData);
             this.setState({
                rowData:rakesData,
            });
        });
        
    }
    onShowTransitRouteMap(rownode){
		console.log("IN onShowTransitRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
        var start_date = moment.parseZone(rownode.timestamp).format("YYYY-MM-DD HH:mm:ss");
        var end_date =moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    	// console.log(start_date,end_date);
        var params = { 
			truck_no:rownode.truck_no,
            start_date:start_date,
            end_date:end_date
		}
		 redirectURL.post('/consignments/rakesMapData',params)
		  .then((response) => {	
		  
		   var records = JSON.parse(JSON.stringify(response)).data;
            records.coords = records.coords;
            console.log(records);
			this.setState({
                    maptransporter:rownode.transporter_name,
                    mapInfo:records,
                    route_details:records.route_details,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":start_date,"endTime": end_date},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});
			
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
    }
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
            sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
		});
		
	}
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	

    };

    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();

          this.gridApi.setFilterModel(window.filterState);
          
          
          
   }
    render(){
        // console.log("fkjasshkfhdsak");
        const columnwithDefs = [
            
            { 
                headerName: "",field: "_id", 
                cellRendererFramework:RoutemapTransitAction,
                width:40,
                suppressSizeToFit: true,
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                colId: "truck_no",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                colId: "transporter_name",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "Actual lspuser",
                field: "actual_lspuser",
                colId: "actual_lspuser",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "Dept. Code",
                field: "dept_code",
                colId: "dept_code",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "TimeStamp",
                field: "timestamp",
                colId: "timestamp",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "Area",
                field: "area",
                colId: "area",
                pivot: false,
                filter: true,resizable: true
            },
            {
                headerName: "State",
                field: "state",
                colId: "state",
                pivot: false,
                filter: true,resizable: true
            },  
        ];
        let maptransporter = this.state.maptransporter;
        return(
            <div>
                <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                    <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={true}
                    enableRangeSelection={true}
                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    statusBar={this.state.statusBar}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    frameworkComponents={this.state.frameworkComponents}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    //editType={this.state.editType}
                    stopEditingWhenGridLosesFocus={true}
                    //floatingFilter={true}
                    enableCellChangeFlash={true}
                    // suppressCellFlash={true}
                    // rowClassRules={this.state.rowClassRules}
                    //onCellClicked={this.onRowClicked.bind(this)}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    masterDetail={true}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    
                    rowSelection={this.state.rowSelection}
                    // onRowSelected={this.onRowSelection.bind(this)}
                    // suppressRowClickSelection={true}
                    // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    />
                </div>
                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.truck_no)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {(this.state.mapInfo != "")?
                                <DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} truckno={this.state.maptruckno} route_details={this.state.route_details} dealer={{lat:0,lng:0}} mapinfo={this.state.mapInfo} defTransitCoords={this.state.defTransitCoords} />
                                :""}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												{/* <div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div> */}
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
            </div>
        )
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}  