import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class RailAnaytics extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"Rail",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            open:false,
            financialyear:''
        }

        this.monthoptions = this.monthoptions.bind(this);
        this.onLoadShowData = this.onLoadShowData.bind(this);
        this.updateSelectedItem = this.updateSelectedItem.bind(this);
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var cy = moment.parseZone().format("YYYY");
        var strt = moment.parseZone(new Date(y+"-01-01")).add(1, 'years').format("YYYY");
        //console.log("strt ", strt)
        var first = "Apr-"+cy
        var end = "Mar-"+(parseInt(cy)+1)
        var fy = first+" - "+end;
        console.log("strt ", cy)
        console.log("fy ", fy)
        this.setState({
            // month:{"value":cy,"label":fy}
        });
        var mm = moment.parseZone().subtract(1, 'months').format("MM")
        var yyyy = moment.parseZone().add(1, 'year').format("YYYY")
        // console.log("yyyy ", yyyy)
        var q1 = moment().quarter(moment().quarter()).startOf('quarter');
        var q2 = moment().quarter(moment().quarter()).endOf('quarter');
        // console.log("Q1 ",q1)
        // console.log("Q2 ",q2)

        const obj = {};

        /* 0-Jan, 1-Feb, 2-Mar */
        if (moment().month() <= 2) { 
            obj.quarter1 = { start: moment().month(3).startOf('month').add('years', -1), end: moment().month(5).endOf('month').add('years', -1) };
            obj.quarter2 = { start: moment().month(6).startOf('month').add('years', -1), end: moment().month(8).endOf('month').add('years', -1) };
            obj.quarter3 = { start: moment().month(9).startOf('month').add('years', -1), end: moment().month(11).endOf('month').add('years', -1) };
            obj.quarter4 = { start: moment().month(0).startOf('month'), end: moment().month(2).endOf('month') };    
        } else {
            obj.quarter1 = { start: moment().month(3).startOf('month'), end: moment().month(5).endOf('month') };
            obj.quarter2 = { start: moment().month(6).startOf('month'), end: moment().month(8).endOf('month') };
            obj.quarter3 = { start: moment().month(9).startOf('month'), end: moment().month(11).endOf('month') };
            obj.quarter4 = { start: moment().month(0).startOf('month').add('years', 1), end: moment().month(2).endOf('month').add('years', 1) };    
        }
        var start = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("MMM-YYYY")
        var end = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("MMM-YYYY")
         var startyy = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("YYYY")
        var endyy = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("YYYY")
        // console.log("start ",start);
        // console.log("end ",end);
        var financialyear = start+" - "+end;
        console.log("financialyear ", financialyear)
        var monthstr = start.split("-");
        var cym = monthstr[1];
        this.setState({
            financialyear : financialyear,
            month:{"value":cym,"label":financialyear}
        });
       
        var rows = []
        for(var y = startyy; y<=endyy; y++)
        {
            var Q1start = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("MM-YYYY")
            var Q1end = moment.parseZone(new Date(obj.quarter1.end._d)).utcOffset("+05:30").format("MM-YYYY")
            
            var Q2start = moment.parseZone(new Date(obj.quarter2.start._d)).utcOffset("+05:30").format("MM-YYYY")
            var Q2end = moment.parseZone(new Date(obj.quarter2.end._d)).utcOffset("+05:30").format("MM-YYYY")
            
            var Q3start = moment.parseZone(new Date(obj.quarter3.start._d)).utcOffset("+05:30").format("MM-YYYY")
            var Q3end = moment.parseZone(new Date(obj.quarter3.end._d)).utcOffset("+05:30").format("MM-YYYY")
            
            var Q4start = moment.parseZone(new Date(obj.quarter4.start._d)).utcOffset("+05:30").format("MM-YYYY")
            var Q4end = moment.parseZone(new Date(obj.quarter1.end._d)).utcOffset("+05:30").format("MM-YYYY")
            
            var startQ1 = moment.parseZone(new Date(obj.quarter1.start._d)).utcOffset("+05:30").format("MM")
            var endQ1 = moment.parseZone(new Date(obj.quarter1.end._d)).utcOffset("+05:30").format("MM")
       
            var startQ2 = moment.parseZone(new Date(obj.quarter2.start._d)).utcOffset("+05:30").format("MM")
            var endQ2 = moment.parseZone(new Date(obj.quarter2.end._d)).utcOffset("+05:30").format("MM")
       
            var startQ3 = moment.parseZone(new Date(obj.quarter3.start._d)).utcOffset("+05:30").format("MM")
            var endQ3 = moment.parseZone(new Date(obj.quarter3.end._d)).utcOffset("+05:30").format("MM")
       
            var startQ4 = moment.parseZone(new Date(obj.quarter4.start._d)).utcOffset("+05:30").format("MM")
            var endQ4 = moment.parseZone(new Date(obj.quarter4.end._d)).utcOffset("+05:30").format("MM")
            var syQ1 = Q1start.split("-");
            if(syQ1[1] == y)
            {
                for(var m = parseInt(startQ1); m<=parseInt(endQ1);m++)
                {
                    if(m<10)
                    {
                        var mm = "0"+parseInt(m);
                    }
                    else
                    {
                        var mm = parseInt(m);
                    }
                    var mnth = moment.parseZone(mm).format("MMM")
                    var rowdat = mnth+"-"+y
                    rows.push(rowdat)
                }
            }

            var syQ2 = Q2start.split("-");
            if(syQ2[1] == y)
            {
                for(var m = parseInt(startQ2); m<=parseInt(endQ2);m++)
                {
                    if(m<10)
                    {
                        var mm = "0"+parseInt(m);
                    }
                    else
                    {
                        var mm = m;
                    }
                    var mnth = moment.parseZone(mm).format("MMM")
                    var rowdat = mnth+"-"+y
                    rows.push(rowdat)
                }
            }
            //console.log("Q3start ", Q3start)
            var syQ3 = Q3start.split("-");
           // console.log("syQ3 ", syQ3)
           // console.log(" == y ", y)
            if(syQ3[1] == y)
            {
                for(var m3 = parseInt(startQ3); m3<=parseInt(endQ3);m3++)
                {
                   
                   // console.log("MM ", m3.toString())
                    var mnth = moment([y, m3-1, "01"]).format("MMM");
                    //var mnth = moment.parseZone(new Date(m3+"-"+y)).format("MMM-YYYY")
                    //console.log("mnth ", startDate)
                    var rowdat = mnth+"-"+y
                    rows.push(rowdat)
                }
            
            }
            var syQ4 = Q4start.split("-");
            if(syQ4[1] == y)
            {
                for(var m = parseInt(startQ4); m<=parseInt(endQ4);m++)
                {
                    if(m<10)
                    {
                        var mm = "0"+parseInt(m);
                    }
                    else
                    {
                        var mm = m;
                    }
                    var mnth = moment.parseZone(mm).format("MMM")
                    var rowdat = mnth+"-"+y
                    rows.push(rowdat)
                }
            }
            
        }
        //console.log("rows ", rows)
        var parameters = {
            months:rows
        }
        this.onLoadShowData(parameters)
        
       // const daysInMonth = moment(startDate).daysInMonth();
    }

    onLoadShowData(parameters){
        redirectURL.post("/consignments/financialloadinvoicesdata", parameters)
        .then((response) => {
            console.log("list ",response.data)
            var records = response.data
            records.sort(GetSortOrder("rowid"));
            this.setState({
                rowData:records
            })
        })
    }
    
    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
    };
    
	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    changeArrayHandler = month => {
        this.setState(
            { month }
        );
    }

    monthoptions(){
        var months = []
        var curenty = moment.parseZone().format("YYYY")
        for(var y = 2015; y<=curenty; y++)
        {
            //console.log("y ", y)
            var strt = moment.parseZone(new Date(y+"-01-01")).add(1, 'years').format("YYYY");
            //console.log("strt ", strt)
            var first = "Apr-"+y
            var end = "Mar-"+strt
            var fy = first+" - "+end;
            months.push({
                value:y,
                label:fy
            })
        }
       // console.log("months ", months)
        return months;
    }
    onClickShowFilterData(){
        var selected = this.state.month
        this.setState({
            financialyear : "Apr-"+selected.value+" - "+"Mar-"+(parseInt(selected.value)+1)
        });
       var st = selected.value;
       //console.log("st ",st)
        var rows = [
            'Apr-'+st,
            'May-'+st,
            'Jun-'+st,
            'Jul-'+st,
            'Aug-'+st,
            'Sep-'+st,
            'Oct-'+st,
            'Nov-'+st,
            'Dec-'+st,
            'Jan-'+(st+1),
            'Feb-'+(st+1),
            'Mar-'+(st+1)
        ]
        
       // console.log("rows ", rows)
        var parameters = {
            months:rows
        }
        this.onLoadShowData(parameters)
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
    }
    
    updateSelectedItem()
    {
        var rowCount = this.gridApi.getSelectedNodes();
        console.log("rowCount ", rowCount)
        var selected = []
        rowCount.map((item) => {
            selected.push(item.data)
        })
        var financialyear = this.state.financialyear;
        var parameters = {
            rowdata:selected,
            financialyear:financialyear,
            email:localStorage.getItem("email")
        }
        redirectURL.post("/consignments/saveFinancialData", parameters)
        .then((response) => {
            console.log("response.data ",response.data)
            var res = response.data;
            if(res.message == 'success')
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully updated",
                    basicType:"success"
                })
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Please select checkbox",
                    basicType1:"danger"
                })

            }
        })
    }
    onRowSelected(event) {

    }
    closeAlert = async() => {
        await this.setState({
            show: false
		});
		await window.location.reload()
    }
    closeAlert1 = async() => {
        this.setState({
            show1: false
		});
		//await window.location.reload()
    }
    render(){
       var columnwithDefs = [
            {
                headerName:"",
                field:"rowid",
                width:50,
                resizable:true,
                headerCheckboxSelection: true,
                checkboxSelection: function(params){
                    return true;
                },
                editable:false
            },
            {
                headerName:"Month",
                field:"month",
                width:150,
                editable:false
            },
            {
                headerName:"Planned",
                field:"planned",
                width:150,
                editable:true
            },
            // {
            //     headerName:"Actual",
            //     field:"actual",
            //     width:150,
            //     editable:true
            // },
            {
                headerName: "Actual",
				children: [				
                    {
                        headerName:"Export",
                        field:"export",
                        width:150,
                        editable:true
                    },
                    {
                        headerName:"Domestic",
                        field:"domestic",
                        width:150,
                        editable:true
                    }
                ]
            }
       ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>

	            {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rail Monthly Invoice Data</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <div className="row">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Financial: </label>
                                        <Select 
                                            placeholder={"All"} 
                                          //  isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.month} 
                                            className="border-radius-0" 
                                            onChange={this.changeArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.monthoptions()} required
                                        />
                                    </div>
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.onClickShowFilterData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={false}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            components={this.state.components}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onRowSelected={this.onRowSelected.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            //floatingFilter={true}
                                            //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            //onCellClicked={this.popmarker}
                                        />
                                    </div>  
                                    <div className="form-group pull-right col-xl-9 col-lg-9"></div>
                                    <div className="form-group float-right col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.updateSelectedItem.bind(this)} type="button" className="btn btn-warning">Update Now</button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
    )}       
} 



function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 