import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import CountUp from 'react-countup';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ViewPODFile from "./viewpodfileaction";
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class IntransitDelayReport extends Component{

	constructor(props){
		super(props);
		this.state={
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                ViewPODFile:ViewPODFile
			},
            animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				
            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			totalpod:[],
            pendingpod:[],
            completedpod:[],
            defaultDate:""
		}
		
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
	}
	componentDidMount = async () => {
        var from_date = moment.parseZone().subtract(3,'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#ostoDate").val(to_date);
        var parameters = {
            
        }
        this.onLoadGetShipments(parameters);
        loadDateTimeScript();
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				parameters.plant_code = localStorage.getItem("pc");
			}
		}catch(e){
			
		}
		
		
       
        var Default_date = moment.parseZone().format('YYYY-MM-DD');
         console.log(parameters,"172")
        redirectURL.post("/amns/intransitdelay",parameters).then(async (response) =>{
            console.log("response ", response.data);
            var records = response.data;
            
            this.setState({
                rowData:records,
                loadshow:"show-n",
                
            })
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
    onClickFilterPodData(){
        var from_date =$("#osfromDate").val();
        var startdate = this.state.defaultDate 
        var diff = moment.parseZone(startdate).diff(from_date)
        var duration = moment.duration(diff)
        var days = Math.floor(duration.asDays())
        console.log(days,"258")

		// var to_date = $("#ostoDate").val();
            if(from_date != '')
            {
                if (days<30){
                    
                
                console.log(this.state.defaultDate,"260")
                
                    if(from_date != undefined && from_date != "")
                    {
                        from_date = moment.parseZone(new Date(from_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                        // to_date = moment.parseZone(new Date(to_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                    }
                    if(from_date != undefined && from_date != "")
                    {
                        var reqparams = {		
                            // transporter_code:transporter_code,	
                            date : from_date,
                            
                        }
                        // if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
                        // {
                        //     reqparams.plant_code = localStorage.getItem("pc");
                        // }
                        // if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
                        // {
                        //     reqparams.transporter_code = localStorage.getItem("transportercode")
                        // }
                        // if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
                        // {
                        //     reqparams.customer_code = localStorage.getItem("customer_code")
                        // }
                        // console.log(reqparams,"275")
                        this.setState({
                            loadshow:'show-m',
                            defaultDate:from_date
                            
                        })
                        if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			            {
                            reqparams.plant_code = localStorage.getItem("pc");
			            }
                        console.log(reqparams,"285")
                        redirectURL.post("/amns/reachedcustomer",reqparams).then(async (response) =>{
                            console.log("response ", response.data);
                            var shipments = response.data.shipments;
                            console.log(shipments,"289")
                            var totalpod=[];
                            var pendingpod=[];
                            var completedpod=[];
                            var shipmentarr = []
                            
                            // if(shipments.length > 0)
                            // {
                            //     shipments.map((item) => {
                            //         if(item.is_pod_submit == "undefined" || item.is_pod_submit == undefined)
                            //         {
                            //             item.pod_status = "Pending";
                            //             pendingpod.push(item)
                            //         }
                            //         else{
                            //             if(item.is_pod_submit == 1)
                            //             {
                            //                 item.pod_status = "Completed";
                            //                 completedpod.push(item)
                            //             }
                            //         }
                                    
                            //         totalpod.push(item);
                            //         shipmentarr.push(item);
                            //     })
                            // }
                            this.setState({
                                rowData:shipments,
                                loadshow:"show-n",
                                // showDiv: 'show-m',
                                // overly:'show-m',
                                totalpod:totalpod,
                                pendingpod:pendingpod,
                                completedpod:completedpod
                            })
                        })
                        // redirectURL.post("/amns/getFilteredPodData",reqparams)    
                        // .then((response) => {
                        //     var shipments = response.data.shipments;
                        //     var totalpod=[];
                        //     var pendingpod=[];
                        //     var completedpod=[];
                        //     var shipmentarr = []
                        //     if(shipments.length > 0)
                        //     {
                        //         shipments.map((item) => {
                        //             if(item.is_pod_submit == "undefined" || item.is_pod_submit == undefined)
                        //             {
                        //                 item.pod_status = "Pending";
                        //                 pendingpod.push(item)
                        //             }
                        //             else{
                        //                 if(item.is_pod_submit == 1)
                        //                 {
                        //                     item.pod_status = "Completed";
                        //                     completedpod.push(item)
                        //                 }
                        //             }
                                    
                        //             totalpod.push(item);
                        //             shipmentarr.push(item);
                        //         })
                        //     }
                        //     this.setState({
                        //         rowData:shipmentarr,
                        //         loadshow:"show-n",
                        //         overly:'show-n',
                        //         totalpod:totalpod,
                        //         pendingpod:pendingpod,
                        //         completedpod:completedpod
                        //     })
                            
                        // })
                        .catch(function(e){
                            console.log("Error ",e)
                        })
                    }    
                // }
                    else
                    {
                        this.setState({
                            show:true,
                            basicTitle:'From Date and To Date are required',
                            basicType:"danger",
                            loadshow:'show-n',
                        })
                    }
                }
                else {
                    this.setState({
                        show:true,
                        basicTitle:'Select Date should be in last 30 days',
                        basicType:"danger",
                        loadshow:'show-n',
                    })
                }
            }
    }
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
	onCellUpdateData = (params) => {
        // console.log("params ", params)
	}
    onClickUpdateItem(params){
        // console.log("params ", params)
        redirectURL.post("/amns/updatecustomerpod",params.data)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger"
                })
            }
        })
    }
    onClickCounter(parm){
        this.setState({
            loadshow:"show-m"
        })
        if(parm == "0"){
            this.setState({
                rowData:this.state.totalpod,
                loadshow:"show-n"
            })
        }
        
        if(parm == "1"){
            this.setState({
                rowData:this.state.pendingpod,
                loadshow:"show-n"
            })
        } 
        if(parm == "2"){
            this.setState({
                rowData:this.state.completedpod,
                loadshow:"show-n"
            })
        }
    }
	render(){
		var columnwithDefs = [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:120,
            },
            // {
            //     headerName:Constant.COL_GSTNO,
            //     field:"customer_pod_gst_no",
            //     width:150,
            // },
            {
                headerName:"Batch No",
                field:"consignment_code",
                width:150,
            },
            // {
            //     headerName:"Consignment Name",
            //     field:"consignment_code",
            //     width:120,
            // },
            {
                headerName:"Invoice No",
                field:"sales_order",
                width:120,
            },
            
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:120,
            },
            {
                headerName:"Customer Name",
                field:"consignee_name",
                width:200,
            },
            {
                headerName:"Customer Code",
                field:"consignee_code",
                width:120,
            },
            
            {
                headerName:"Customer City",
                field:"transporter_zone",
                width:120,
            },
            {
                headerName:"Transit Time (Days)",
                field:"transit_time",
                width:120,
                
            },
           
            {
                headerName:"Material Description",
                field:"material_desc",
                width:120,
            },
            
            

            {
                headerName:"Loading Yard Exit Time",
                field:"loading_yard_exit_time",
                width:150,
                
            },
            {
                headerName:"Expected Trip End",
                field:"expected_trip_end",
                width:120,
            },
            {
                headerName:"Revised Trip End",
                field:"revised_trip_end",
                width:120,
            },
            {
				headerName: "Transit Delay (In Days)",
				field: "transit_delay",
				colId: "transit_delay",					
				width: 120,filter: true,
				cellRenderer:'',
				resizable: true,
				// hide:hideForCustomer,
				// suppressColumnsToolPanel:hideForCustomer,
				valueGetter:function(params){
					var expected = params.data.expected_trip_end;
					if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
					  {
						  var revised = params.data.expected_trip_end;
					  }
					  else{
						var revised = params.data.revised_trip_end;
					  }
					  //console.log("Exp ", expected)
					  //console.log("revised ", revised)
					  var s = moment.parseZone(expected).format("x");
					  var ed = moment.parseZone(revised).format("x");
					  //console.log("Exp ", s)
					  //console.log("revised ", ed)
					  var diff = (ed-s)
					 // console.log("Diff",diff/1000)
					  var days = secondsToDays(diff)
					  //console.log("Days ", Math.round(days/1000))
					  var totaldays = Math.round(days/1000);
					  if(totaldays > 0)
					  {
							return totaldays;							  
					  }
					  else{
						  return 0;
					  }
					  //return Math.round(days/1000);

				}
			},
            {
                headerName:"Current Location Of Truck",
                field:"current_location_of_truck",
                width:140,
            },

           
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">

                    
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                        <div className="card-header">
                            <h5>
							    <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	    Iod Delay Report
                            </h5>
                        </div>
                            
                            <div class="row" style={{marginTop:"17px",marginLeft:"29px"}}>
                                {/* <div class="col-xl-2 col-lg-2 form-group">
                                    <label> Date</label>
                                    <input type="text" value={this.state.defaultDate} name="defaultDate" id="osfromDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/>
                                </div> */}
{/*                                 
                                <div class="col-xl-2 col-lg-2 form-group">
                                    <button type="submit" onClick={this.onClickFilterPodData.bind(this)} class="btn btn-success mt-30p">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </div> */}
						    </div>			   			
		            		<div className="card-body">
                                {/* <div className="row col-xl-12 col-lg-12">
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                   
                                </div> */}
                                <div className="row col-xl-12 col-lg-12">
								    
                                    <div className="col-xl-12 col-lg-12">
                                        

                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                components={this.state.components}
                                                frameworkComponents={this.state.frameworkComponents}
                                                
                                                //floatingFilter={true}
                                                onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                                //onCellClicked={this.popmarker}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                            />
                                        </div>
                                        {/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
                                    </div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
                 <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}