
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const DeliverRail = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowDeliveredLegs(props);
    };
    
    let revisedDateOnlyMoment = 0
    let expectedDateOnlyMoment  = 0
    if (props.data.last_mile_dispatch_date != undefined && props.data.eta_rail_last_mile != undefined) {
        revisedDateOnlyMoment  = moment.parseZone(props.data.last_mile_dispatch_date).format("x");
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_rail_last_mile).format("x");
       
    } else {
        var today = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        
        revisedDateOnlyMoment  = moment.parseZone(today).format("x");
        //console.log("today ", revisedDateOnlyMoment)
        expectedDateOnlyMoment  = moment.parseZone(props.data.eta_rail_last_mile).format("x");
        //console.log("expectedDateOnlyMoment ", expectedDateOnlyMoment)
    }

        if(props.data.status == 8)
        {

            if(revisedDateOnlyMoment > expectedDateOnlyMoment)
            {
                var colr = 'redfont'
            }

            else if(revisedDateOnlyMoment < expectedDateOnlyMoment)
            {
                var colr = 'greenfont'
            }
            else{
                var colr = 'greenfont'
            }
        }
        else{
            var colr = 'grayfont'
        }
        //console.log("delsta ", props.data.status)
   // console.log("Deliv ", colr)

    return (
        <div>
            {(props.data.status == 8)?
			<button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="Delivered" style={{color:"#ff0000"}}><i className="icofont icofont-truck-alt"></i> &nbsp;</button>
            :
            <button onClick={handleOverspeedClick} className={"custom-btn f22 label label-success "+(colr)} title="Delivered" style={{color:"#ccc"}}><i className="icofont icofont-truck-alt"></i> &nbsp;</button>
            
            }
        </div>
    );
};

export default DeliverRail;
