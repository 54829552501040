import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Grid from './gridComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import '../../assets/css/customdate.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS } from '../common/utils'; 
var redirectURL = require('../redirectURL');


export default class TruckswithgpsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
				inlineFilters:'',
				filter_from_date:'',
				filter_to_date:'',
				alldepts:[],
				showdata : this.displayData,
				sliderTranslate:'',
            	deptvalue:'all',
            	truckscnt:0,
            	trucklocations:0,
            	notrans:0,
            	trucktoday:0,
            	trucknogps:0,
                loadsData:[], 
                transportschart:[],
                transaxis:[],
                transyaxis:[],
                deptaxis:[],
                deptyaxis:[],
                lspaxis:[],
                lspyaxis:[],
                modules: AllModules,
      	      open: false,
      	      filterstatess:{},
      	      columnsdatas:{},
      	      rowgroupdcols:[],
      	      valuecolumns:[],
      	      pivotmodeis:false,
      	      pivotcols:[],
      	      columnwithDefs: [
				{
				  headerName: "Truck No",
				  field: "truck_no",
				  width: 200,
				  
				  
				},
				{
				headerName: "LSP Name",
				field: "transporter_name",
				width: 200,
					
			  },
				{
				headerName: "Department Code",
				field: "dept_code",
				width: 200,
					
			  },
				{
					headerName: "GPS Provider (as per master)",
					field: "lsp_name",
					width: 200,
						
			  },
				{
				headerName: "Actual GPS Provider",
				field: "actual_lspuser",
				width: 200,
					
			  },
				{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 200,
				valueGetter:function(params){
					return getDDMMYYYYHHMMSS(params.data.timestamp)
				},
					
			  },
			  {
				headerName: "Data Received On",
				field: "last_gps_date",
				width: 200,
				valueGetter:function(params){
					return getDDMMYYYYHHMMSS(params.data.last_gps_date)
				},
			  }
				 
				 
			  ],
      	      defaultColDef: {
      	        sortable: true,
      	        filter: true,

      	      },
      	      rowData: null,
      	      
      	      rowModelhighchartoptionsType: 'enterprise',
      	      rowGroupPanelShow: 'always',
      	      animateRows: true,
      	      debug: true,
      	      showToolPanel: true,
      	      suppressAggFuncInHeader: true,
        	      
            };
        }
	
	componentDidMount(){
		//console.log("child  ", this.props.stateKey +"=="+ this.props.key);
		const layoutdata={
            token:localStorage.getItem('token'),
            
        }
		  /*Truck with gpsdata*/	
		  redirectURL.post('/overview/truckWithGPSData',layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		.then((response) => {
		
		var records = JSON.parse(JSON.stringify(response)).data;
		//console.log(records);
		this.setState({
			rowData:records
		});
		
		})
		.catch(function (error) {
		console.log(error);
		});
	}
	componentWillReceiveProps(newProps) {
		this.setState({
			filter_from_date: newProps.fromDate,
			filter_to_date: newProps.toDate,
			filter_dept_code:newProps.deptcode
		});
		var setter ='no';
		if(this.state.filter_from_date != newProps.fromDate)
		{
			setter = 'yes';
		}
		
		if(this.state.filter_to_date != newProps.toDate)
		{
			setter = 'yes';
		}
		
		if(this.state.filter_dept_code != newProps.deptcode)
		{
			setter = 'yes';
		}
		
		//if(setter == 'yes')
		//{
			const layoutdata={
				token:localStorage.getItem('token'),
				fromDate: newProps.fromDate,
				toDate: newProps.toDate,
				deptcode:newProps.deptcode
			}
			  /*Truck with gpsdata*/	
			  redirectURL.post('/overview/truckWithGPSData',layoutdata,{
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then((response) => {
			
			var records = JSON.parse(JSON.stringify(response)).data;
			//console.log(records);
			this.setState({
				rowData:records
			});
			
			})
			.catch(function (error) {
			console.log(error);
			});
		//}
		
	}
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
	  onShowSidebar(){
		this.setState({
			showdata : this.displayData,
			sliderTranslate:"slider-translate"
		});
	  }
	  onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
		});
	 }
	 onShowInlineFilter = (i) => {
		//console.log("i " ,i)
		this.setState({
			inlineFilters:"fslider-translate-40p",
		});	
	}
	onCloseiInlineFilter(){
		this.setState({
			inlineFilters:""
		});	
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		if((d.getMonth() +1) < 10)
		{
			var mm ="0"+(d.getMonth() +1);
		}
		else{
			var mm =d.getMonth() +1;
		}
		
		if(d.getDate() < 10)
		{
			var dd = "0"+d.getDate();
		}
		else{
			var dd=d.getDate();
		}

		if(d.getHours() < 10)
		{
			var hh = "0"+d.getHours();
		}
		else{
			var hh = d.getHours();
		}
    	var startdate = d.getFullYear()+"-"+mm+"-"+dd+" "+hh+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	
    	this.setState({
    		filter_from_date:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	if((d.getMonth() +1) < 10)
		{
			var mm ="0"+(d.getMonth() +1);
		}
		else{
			var mm =d.getMonth() +1;
		}
		
		if(d.getDate() < 10)
		{
			var dd = "0"+d.getDate();
		}
		else{
			var dd=d.getDate();
		}

		if(d.getHours() < 10)
		{
			var hh = "0"+d.getHours();
		}
		else{
			var hh = d.getHours();
		}
    	var edate = d.getFullYear()+"-"+mm+"-"+dd+" "+hh+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	this.setState({
    		filter_to_date:edate
    	});
    	
    	
    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}

	formHandler = (event) => {
    	event.preventDefault();
		const layoutdata={
			token:localStorage.getItem('token'),
			fromDate: this.state.filter_from_date,
			toDate: this.state.filter_to_date,
			deptcode:'all'
		}
		console.log("layoutdata ",layoutdata);
		  /*Truck with gpsdata*/	
		  redirectURL.post('/overview/truckWithGPSData',layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		.then((response) => {
		
		var records = JSON.parse(JSON.stringify(response)).data;
		//console.log(records);
		this.setState({
			rowData:records
		});
		
		})
		.catch(function (error) {
		console.log(error);
		});
    	
	}
	onPopItem(e){
		console.log(e);
		this.props.context.onPopItem(e);
	}
    render(){
        return ( 
			<div className={"card"} >
			<div className="card-header">
				<h5 className="grid-h5">{this.props.componentTitle}
				{(this.props.popnow == '0')?
						<span className="pull-right">
							<ul className="actions-list">
								{/*<li>
									<a href="#" onClick={this.onShowInlineFilter.bind(this)} title="Filter">
										<i class="fa fa-filter"></i>
									</a>
								</li>
								*/}
								<li>
									<a className="grid-mx" href="#" onClick={this.onPopItem.bind(this,this.props.info)} title="Maximize">
										<i class="fa fa-external-link"></i>
									</a>
								</li>
								
							</ul>
						</span>
						:""}
				</h5>
			</div> 
			<div className="card-body" style={{padding:"0px"}}>
				<div className="dashboard-chart-container sales-overview-chart mb30np">
							  
					<Grid
						columnaxis={this.state.columnwithoutDefs}
						columnyaxis={this.state.rowData}
						context={this}
						filtershow={this.props.filtershow}
						stateKey={this.props.stateKey}
					/>
					
					<div className={"fslide-r "+(this.state.inlineFilters)}>
							<div className="fslide-r-title">
								<h4>
									<i class="icofont icofont-filter"></i> Filter
									<span className="float-right fclose" onClick={this.onCloseiInlineFilter.bind(this)}>x</span>
								</h4>
							</div>
							<div className="fcslide-r-body">
								<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
									<div className="form-group col-xl-12 col-lg-12">
										<label for="formDate">From Date</label>
										<Datetime inputProps={{ placeholder: 'yyyy-mm-dd HH:mm:ss',  autoComplete:'off', class:'fc-input', required:true }} value={this.state.filter_from_date} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="fromDate" onChange={this.handlerStartDateTime.bind(this)} />
									</div>
									<div className="form-group col-xl-12 col-lg-12">
										<label for="formDate">To Date</label>
										<Datetime inputProps={{ placeholder: 'yyyy-mm-dd HH:mm:ss', autoComplete:'off', class:'fc-input', required:true }} value={this.state.filter_to_date} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="toDate" onChange={this.handlerEndDateTime.bind(this)} />
									</div>
									<div className="form-group col-xl-12 col-lg-12">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div> 
				</div>
			</div>
			
		</div>  
			);
		}
}












