
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const Editrailsummary = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props.data);
        props.context.componentParent.onClickUpdateRailSummary(props);
    };


    return (
        <div>
			<button onClick={handleClick} className="custom-btn f12 btn btn-warning" title="Edit" >Edit</button>

        </div>
    );
};

export default Editrailsummary;
