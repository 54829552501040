import React, { Component } from 'react';
import Consignments from '../manage/consignments';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentsComponent extends Component {
    constructor(props){
    	super(props);
    	this.state={
            data:'',
        }
    }

    render(){
        return (
            <Consignments />
        );
    }
}