import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Pie from './pieComponent';
var redirectURL = require('../redirectURL');

export default class DepartmentWiseTrucksWithGPSComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
			transportschart:'',
			deptaxis:'',
			deptyaxis:'',
			inlineFilters:'',
			filter_from_date:'',
			filter_to_date:'',
		}
    } 
    componentDidMount(){
        const layoutdata={
            token:localStorage.getItem('token'),
            dept_code:this.props.deptcode
        }
        redirectURL.post('/overview/departmentWiseTrucksChart', layoutdata,{
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
        .then((response) => {
            
        var records = JSON.parse(JSON.stringify(response)).data;
        // console.log(records);
        var dxaxis=[];
        var dseriesdata=[];
        for(var rec in records)
        {
            //   console.log(records[rec]);
            dxaxis.push(records[rec]._id);
            dseriesdata.push(parseInt(records[rec].count));
        }
        this.setState({
            deptaxis:dxaxis,
            deptyaxis:dseriesdata
        });
            
        })
        .catch(function (error) {
            console.log(error);
        });
	}
	
	onShowInlineFilter = (i) => {
		//console.log("i " ,i)
		this.setState({
			inlineFilters:"fcslide-translate-40p",
		});	
	}
	onCloseiInlineFilter(){
		this.setState({
			inlineFilters:""
		});	
	}
    render(){
    	
        const dStyles={
				width:'100%',
				height:'100%'
		}
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.y:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {
			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        
        return ( 
			<div className={"card"} >
				<div className="card-header">
					<h5 className="grid-h5">{this.props.componentTitle}
						<span className="pull-right">
							<ul className="actions-list">
								{/*<li>
									<a href="#" onClick={this.onShowInlineFilter.bind(this)} title="Filter">
										<i class="fa fa-filter"></i>
									</a>
								</li>
								*/}
								<li>
									<a className="grid-mx" href="" title="Maximize">
										<i class="fa fa-external-link"></i>
									</a>
								</li>
								
							</ul>
						</span>
					</h5>
				</div> 
				<div className="card-body" style={{padding:"0px"}}>
					<div className="media posrel">
						<Pie columnaxis={this.state.deptaxis} columnyaxis={this.state.deptyaxis}  />
						<div className={"fcslide-r "+(this.state.inlineFilters)}>
							<div className="fcslide-r-title">
								<h4>
									<i class="fa fa-external-link"></i> Filter
									<span className="float-right fclose" onClick={this.onCloseiInlineFilter.bind(this)}>x</span>
								</h4>
							</div>
						</div> 
					</div>
					
				</div>
				
			</div> 
			);
		}
}












