import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import DateFormater from '../layouts/dateComponent';


export default class GridComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            	alldepts:[],
            	deptvalue:'all',
            	truckscnt:0,
            	trucklocations:0,
            	notrans:0,
            	trucktoday:0,
            	trucknogps:0,
                loadsData:[], 
                transportschart:[],
                transaxis:[],
                transyaxis:[],
                deptaxis:[],
                deptyaxis:[],
                lspaxis:[],
                lspyaxis:[],
                modules: AllModules,
      	      open: false,
      	      filterstatess:{},
      	      columnsdatas:{},
      	      rowgroupdcols:[],
      	      valuecolumns:[],
      	      pivotmodeis:false,
      	      pivotcols:[],
      	      
      	      defaultColDef: {
      	        sortable: true,
				  filter: true,
				  resizable: true

      	      },
      	      rowData: null,
      	      
      	      rowModelhighchartoptionsType: 'enterprise',
      	      rowGroupPanelShow: 'always',
      	      animateRows: true,
      	      debug: true,
      	      showToolPanel: false,
				suppressAggFuncInHeader: true,
				frameworkComponents: {
					dateFormater:DateFormater,
					customLoadingCellRenderer: CustomLoadingCellRenderer
					//statusChange:StatusChange
				},
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
	      	    statusBar: {
	      	        statusPanels: [
	      	          {
	      	            statusPanel: "agTotalAndFilteredRowCountComponent",
	      	            align: "left"
	      	          },
	      	          {
	      	            statusPanel: "agTotalRowCountComponent",
	      	            align: "center"
	      	          },
	      	          { statusPanel: "agFilteredRowCountComponent" },
	      	          { statusPanel: "agSelectedRowCountComponent" },
	      	          { statusPanel: "agAggregationComponent" }
	      	        ]
	      	      },
	      	      sideBar: {
	      	        toolPanels: [
	      	          {
	      	            id: "columns",
	      	            labelDefault: "Columns",
	      	            labelKey: "columns",
	      	            iconKey: "columns",
	      	            toolPanel: "agColumnsToolPanel"
	      	          },
	      	          {
	      	            id: "filters",
	      	            labelDefault: "Filters",
	      	            labelKey: "filters",
	      	            iconKey: "filter",
	      	            toolPanel: "agFiltersToolPanel"
	      	          }
	      	        ]
	      	      }
        	      
            };
        }
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	 }
	  
    render(){
        return ( 
    		 <div className="col-xl-12 col-lg-12 beffect n-p-0">
                 <div className="card height-equal equal-height-lg">
                    
                     <div className="card-body" style={{padding:'10px'}}>
                         <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={this.props.columnaxis}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.props.columnyaxis}
		          		            enableCharts={false}
		          		            enableRangeSelection={false}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
		          	                frameworkComponents={this.state.frameworkComponents}
	      	                		paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									enableRangeSelection={true}
									//floatingFilter={true}
									
		          		          />
		          		        </div>
                     </div>
                     
                 </div>
                 
             </div>
			);
		}
}
