import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class ColumnChartDailyPrt extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng"
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()

	}
	componentWillReceiveProps (newprops){
        if(newprops.title != this.props.dept_code)
        {
            //console.log("newprops ",newprops);
        }
	}
	
    render(){
        //console.log("this.props.code) ", this.props.categories)
    
  
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
			chart: {
                type: 'column'
            },
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            xAxis: {
                categories:this.props.categories,
                //crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yaxistitle
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} '+this.props.prefex
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                
                    colorByPoint: true
                },
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y} KM/Day'
                    }
                }
            },
            series: this.props.series,
            colors:[ '#f35e82','#7f84e9','#f3d800', '#00f0b5', '#52f300', '#00ddf3','#f30000','#00f0b5','#3100f3', '#fff493', '#f88fa2', '#f15c80', '#92A8CD', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
            credits:false,
            legend:false
        };
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}