import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
import { getDDMMMYYYYHHMMDefault } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CountUp from 'react-countup';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var infoBox = require('../common/google-infowindow');
var aggriddata = [];
var originaldata;

$.datetimepicker.setLocale('en');
var marker;
var allcords=[];
var map;
var doOnce = true;

var rad = function(x) {
	return x * Math.PI / 180;
  };
export default class RoutePlanning extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
            showToolPanel: false,
            griddata:[],
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
               
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Route Planning",
            originaldata:"",
            orignalresponse:"",
            mapinfo:[],
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
            destinationarr:[],
            selectedorigin:'',
            selecteddestination:'',
            checkedinputs:[],
            uncheckedinputs:[],
            noofroutes:3,
            tolls:[],
            tollshow:false,
            plantcoords:"",
            plantname:"",
            sliderTranslate:"",
            viewtolls:[],
            tollrowid:"",
            routeid:"",
            routeapprovestatus:"",
            open:false,
            route_reason:"",
            gridapi: [],
            viewroutedata:[],
            sliderRouteTranslate:"",
            sliderChokeTranslate:"",
            chokepoints:[],
            chokepointdata:[],
            startlat:"",
            endlng:"",
            mapcolors:[],
            trip_start:"",
            trip_end:"",
            total_trip_distance:"",
            total_trip_duration:"",
            baserouteid:'',
            baseroutecheck:false,
            trucktype:"",
            preference:""
        };
        this.origins = this.origins.bind(this);
        this.destinations = this.destinations.bind(this);
        this.onLoadFormRequest = this.onLoadFormRequest.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.popmarker=this.popmarker.bind(this);
        this.timings = this.timings.bind(this);
        this.preferences = this.preferences.bind(this);
        this.trucktypes = this.trucktypes.bind(this);
        
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
        loadDateTimeScript()
       // console.log("this.macth ", this.props.location.search)
        var s = this.props.location.search;
        var dept_code=''
        if(this.props.match.path == "/sndrouteplanning")
        {
            dept_code = "SNDG"
        }
        if(this.props.match.path == "/prtrouteplanning")
        {
            dept_code = "LOG-PRT"
        }
        if(this.props.match.path == "/tnprouteplanning")
        {
            dept_code = "LOG-TNP"
        }
        this.setState({
            dept_code:dept_code
        })
        
        //this.onClickShowRoute()
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        redirectURL.post("/consignments/deviationconsignees")
        .then((response) => {
            //console.log("Consignees ", response.data)
           var records = response.data;
            var destinationarr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item != "")
                    {
                        destinationarr.push(item)
                    }
                })
            }
            this.setState({
                destinationarr:destinationarr
            })
        })
        
        
        
        
	}
	renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
    }
    

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }

	changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }
    changeDestinationHandler = destinationoption => {
        this.setState(
            { destinationoption }
        );
    }
   
    changeTruckTypeHandler = trucktype => {
        this.setState(
            { trucktype }
        );
    }
   
    changePreferenceHandler = preference => {
        this.setState(
            { preference }
        );
    }
   
    changeDispatchHandler = dispatchtime => {
        this.setState(
            { dispatchtime }
        );
    }
   
	formHandler = (event) =>{
        event.preventDefault();
       // loadchecked()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m',            
            tolllist:[]
        });
        if(this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {

            var dispatchtime = document.getElementById("dispatchtime").value;
            //var dispatchtime = this.state.dispatchtime.value;
            if(this.state.dept_code != "LOG-TNP")
            {
                var trucktype=this.state.trucktype.value;
            }else
            {
                var trucktype="";
            }
            var preference = this.state.preference.value;
            var formdata = {
                origin:this.state.originoption.value,
                destination:this.state.destinationoption.value,
                dept_code:this.state.dept_code,
                dispatchtime:dispatchtime,
                // trucktype:trucktype,
                // preference:preference
            }
            var p = {
                plant:this.state.originoption.value
            }
            redirectURL.post("/consignments/plantcoordinates", p)
            .then((response) => {
                //console.log("Ress ", response.data)
                var plantdata = response.data
                //console.log("plantcoords ", JSON.parse(plantdata[0].plant_coordinates))
                var plantcoords = JSON.parse(plantdata[0].plant_coordinates)
                this.setState({
                    plantcoords:{coord:plantcoords,name:this.state.originoption.label},
                    plantname:this.state.originoption.label,
                })
            })
            this.onLoadFormRequest(formdata)
            
             
        }
		
		
    }
    
    onLoadFormRequest = async(formdata) => { 
        //console.log("formdata ", formdata)
        await this.setState({
            mapinfo:[]
        })
        await redirectURL.post("/consignments/routeplanningdata", formdata)
        .then(async (response) => {
            // console.log("Response ",response.data)
            // console.log("JSON.parse(response.data.route_coords) " , JSON.parse(response.data.route_coords))
            var coords = JSON.parse(response.data.route_coords)
            //var mapdata = coords.sort(GetSortOrder("leg_no"));
            var trips = JSON.parse(response.data.trip_schedule);
            trips =  trips.sort(GetSortOrder("leg_no"));
            var startlat = "";
            var endlng = "";
            if(coords.length > 0)
            {
                startlat = coords[0].lat
                endlng = coords[coords.length - 1].lng
            }

            try{
                var baserouteid = response.data.base_route_id
            }
            catch(e){
                var baserouteid = ""
            }
            var legsarr= []
            var recordsarr = []
            if(coords.length > 0)
            {
                coords.map((item) => {
                    legsarr.push(item.leg_no)
                })
            }
            var unique = legsarr.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
            var colors = []
            if(unique.length > 0)
            {
                //colors.push("#157254")
                await unique.map((item, index) => {
                    var scolor = setBg()
                    if(scolor.length == 6)
                    {
                        scolor = scolor+"0"
                    }
                    
                    colors[index]=scolor
                })
            }
            
            if(coords.length > 0)
            {
                await coords.map((item) => {
                    recordsarr.push(item)
                    recordsarr.linecolor = colors[parseInt(item.leg_no)-1]
                })
            }
           // console.log("colors ", colors)
            await this.setState({
                loadshow:"show-n",
                overly:"show-n",
                rowData:trips,
                mapinfo:coords,
                mapcolors:colors,
                trip_start:response.data.trip_start,
                trip_end:response.data.trip_end,
                total_trip_distance:response.data.total_trip_distance,
                total_trip_duration:response.data.total_trip_duration,
                baserouteid:baserouteid
            })
            await this.renderMap();
        })
    }
	origins(){
        var items=[]
        if(this.state.dept_code == "SNDG")
        {
            items.push(
                {value:"F", label:"Haryana"},
                {value:"G", label:"Gujarat"},
                {value:"N", label:"Nagpur"},
                {value:"I", label:"Silguri"},
                {value:"B", label:"Banglore"}
                
            );
        }
        if(this.state.dept_code == "LOG-PRT")
        {
            items.push(
                {value:"MSILDM", label:"MSIL Domestic"},
                {value:"MSILG", label:"Gurgaon"},
                {value:"MSILM", label:"Manesar"},
                {value:"RPDC-BAN", label:"RPDC Bangalore"},
                {value:"RPDC-GUJ", label:"RPDC Gujarat"},
                {value:"RPDC-NAG", label:"RPDC Nagpur"},
                {value:"RPDC-SIL", label:"RPDC Siliguri"}
                
            );
        }

        if(this.state.dept_code == "LOG-TNP")
        {
            items.push(
                {value:"MSILME", label:"Haryana"},
                {value:"MSILMC", label:"Casting"},
                {value:"SMG", label:"SMG Gujarat"}
            )
        }
        return items;
    }

    destinations(){
        var items=[]
        if(this.state.dept_code == "LOG-TNP")
        {
            items.push(
                {value:"SMG", label:"SMG Gujarat"}
            )
        }
        else{
            if(this.state.destinationarr.length > 0)
            {
                var destinationarr = this.state.destinationarr;
                destinationarr.map((item) =>{
                    items.push({
                    value:item,label:item 
                    })
                })
            }
        }
        return items;
    }

    trucktypes(){
        var items=[]
        if(this.state.dept_code == "SNDG")
        {
            items.push(
                {value:"Truck", label:"Truck"},
                {value:"Trailer", label:"Trailer"}
                
            );
        }
        if(this.state.dept_code == "LOG-PRT")
        {
            items.push(
                {value:"32 ft", label:"32 ft"},
                {value:"20 ft", label:"20 ft"}
            );
        }
       
        return items;
    }

    preferences(){
        var items=[]
      
        items.push(
            {value:"transit time", label:"Transit Time"},
            {value:"distance", label:"Distance"},
            {value:"cost", label:"Cost"}
            
        );
        
        return items;
    }
    onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
            sliderRouteTranslate:'',
            sliderChokeTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
    }
    

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderTranslate:'',
            sliderRouteTranslate:'',
            sliderChokeTranslate:""
		});
		
    }
    
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
     };
     
    
     initMap = () => {
		try{
			var currentwindow;
			var lt=28.4519751;
		    var ln=77.0310713;
            var jsondata = this.state.mapinfo;
            var tolls = this.state.tolls;
            var arr = jsondata;

            var mapOptions = {
                zoom: 8,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels:true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(lt,ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
			var bounds = new window.google.maps.LatLngBounds();
			
			if(doOnce){
				initOMS();
				doOnce = false;
			}

            if(arr.length > 0)
            {	
               var markersArray = arr;
                
                var arr2 = arr;
                var routeinfo = jsondata;
                //this.plotMultiColorPolyline(arr,map,bounds)
               // console.log("arr2 ", arr2)
               
                for(var i=0;i< arr2.length-1;i++)
                {
                    //console.log("Color ", this.state.mapcolors[parseInt(arr2[i].leg_no)-1])
                    var locationLatLng = [];
                    locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
                    locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
                    
                    var consignments_missing_route_line = new window.google.maps.Polyline({
                        map:map,
                        path : locationLatLng,
                        strokeColor: '#157254',
                        strokeOpacity: 1.0,
                        strokeWeight: 2.5,
                        
                    });
                    // console.log("leg == ", arr2[i].leg_no);
                    //  console.log("this.state.mapcolors[parseInt(arr2[i].leg_no)-1] ", this.state.mapcolors[parseInt(arr2[i].leg_no)-1])
                    consignments_missing_route_line.setOptions({
                        strokeColor : this.state.mapcolors[parseInt(arr2[i].leg_no)-1],
                        strokeWeight: 5.5,
                    })
                   
                    
                    bounds.extend(new window.google.maps.LatLng(parseFloat(arr2[i].lat),parseFloat(arr2[i].lng)));
                }
            
            }

			
          map.fitBounds(bounds)
          try{
                if(tolls.length > 0)
                {
                    var infowindow = new window.google.maps.InfoWindow();
                    var l;
                    tolls.map(function(e,index){
                        var tollMarker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(e.lat, e.lon),
                            icon:require('../../assets/icons/barrier.png'),
                            map: map,
                        });
                        window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
                            return function() 
                            {
                                var contentarr = []
                                var header = "Toll - "+e.name
                                contentarr.push({"key":"Address", "value":e.location})
                                var contentString = infoBox(tollMarker.icon, header, contentarr)
                                infowindow.setContent(contentString);
                                var currentwindow = infowindow;
                                infowindow.open(map, tollMarker);
                            }
                        })(tollMarker, index));
                        window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
                            infowindow.close();
                            });
                    })  
                    
                }
                map.fitBounds(bounds)
            }
            catch(e){}

		}
		catch(e)
		{
			console.log(e);
		}
		
		 
		  
    }
    popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.start_lat);
		var lng =parseFloat(e.data.start_lng);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
        map.setZoom(9);
        
		// var markerLatlng = new window.google.maps.LatLng(lat, lng);
        // var marker = new window.google.maps.Marker({
		// 	position: markerLatlng,
		// 	map: map
		// });
		
		// marker.setMap(map);
		
	}
    onClickShowTolls(event)
    {
        var inputid =event.target.id;
        var checking = document.getElementById(inputid).checked
        // console.log("checking ", checking)
        // console.log("baserouteid ", this.state.baserouteid)
        this.setState({
            baseroutecheck:checking
        })
        if(checking == true)
        {
            var params = {
                base_route_id:this.state.baserouteid
            }
            redirectURL.post("/consignments/routeplanningtolls", params)
            .then((response) => {
                var records = response.data
                //console.log("resords ", records)
                if(records.length > 0)
                {
                    this.setState({
                        tolls:records[0].tolls
                    })
                    this.renderMap()
                }
            })
        }
        else
        {
            this.setState({
                tolls:[]
            })
            this.renderMap()
        }
        
    }

    timings(){
        var items=[]
        var start=0;
        var end = 24;
        for(var t=start;t<end;t++)
        {
            if(t<10)
            {
                var tt="0"+t;
            }
            else{
                var tt=t;
            }
            items.push(
                {value:tt+":00", label:tt+":00"},
                {value:tt+":30", label:tt+":30"},
            )
        }
        
        return items;
    }

    render(){
        const modalStyles  = {
			width:'500px !important',
            }
        const { open } = this.state;
        var gridcolors = this.state.mapcolors
		const dStyles={
			width:'100%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
        }
        const columnDefs = [
            
            {
                headerName:"ETD",
                field:"start_eta",
                width:160,
                cellStyle:function(params){
                    return {backgroundColor:gridcolors[parseInt(params.data.leg_no)-1]}
                }
            },
            {
                headerName:"Start",
                field:"start_address",
                width:160
            },
            {
                headerName:"End ETA",
                field:"end_eta",
                width:160
            },
            {
                headerName:"End",
                field:"end_address",
                width:160
            },
            
            {
                headerName:"Exp. Dist.",
                field:"leg_distance_kms",
                width:100
            },
            
            {
                headerName:"Exp. Time.",
                field:"leg_duration_hrs_text",
                width:160
            },
            {
                headerName:"Break Duration",
                field:"break_duration_in_leg",
                width:160
            },

        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Route Planning  </span>
				       			</h5>
				   			</div>
				   			<div className="card-body row">
							  
								<div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Origin: </label>
                                        <Select 
                                            name="originoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.originoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeOriginHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.origins()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Destination: </label>
                                        <Select 
                                            name="destinationoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.destinationoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeDestinationHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.destinations()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Time Of Dispatch: </label>
                                        <input
                                            type="text"
                                            className="dispatchtime"
                                            name="dispatchtime"
                                            style={{width:"100%"}}
                                            value={this.state.dispatchtime}
                                            id="dispatchtime"
                                        />
                                        {/* <Select 
                                            name="dispatchtime"
                                            id="dispatchtime"
                                            closeMenuOnSelect={true} 
                                            value={this.state.dispatchtime} 
                                            className="border-radius-0" 
                                            onChange={this.changeDispatchHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.timings()} required
                                        /> */}
                                    </div>
                                    {(this.state.dept_code != "LOG-TNP")?
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Truck Type: </label>
                                        <Select 
                                            name="trucktype"
                                            closeMenuOnSelect={true} 
                                            value={this.state.trucktype} 
                                            className="border-radius-0" 
                                            onChange={this.changeTruckTypeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.trucktypes()} required
                                        />
                                    </div>
                                :""}
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Preferences: </label>
                                        <Select 
                                            name="preference"
                                            closeMenuOnSelect={true} 
                                            value={this.state.preference} 
                                            className="border-radius-0" 
                                            onChange={this.changePreferenceHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.preferences()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <button style={{marginTop:"30px"}} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                   
                                </div>

                                {(this.state.mapinfo != "")?
								<div className="row col-xl-12 col-lg-12">                              
                                    <div className="col-xl-3 col-lg-3 mt-20p mb-30p textAlignCenter">
                                        <span className="counter f16 txt-info" style={{fontWeight:"bold"}}>Start:<br /> {(this.state.trip_start != '')?this.state.trip_start:""}</span>
                                        
                                    </div>                              
                                    <div className="col-xl-3 col-lg-3 mt-20p mb-30p textAlignCenter">
                                        <span className="counter f16 txt-danger" style={{fontWeight:"bold"}}>End:<br /> {(this.state.trip_end != '')?this.state.trip_end:0}</span>
                                        
                                    </div>                             
                                    <div className="col-xl-3 col-lg-3 mt-20p mb-30p textAlignCenter">
                                        <span className="counter f16 txt-primary" style={{fontWeight:"bold"}}>Total Distance:<br />{(this.state.total_trip_distance != '')?this.state.total_trip_distance:0}</span>
                                        
                                    </div>                             
                                    <div className="col-xl-3 col-lg-3 mt-20p mb-30p textAlignCenter">
                                        <span className="counter f16 txt-success" style={{fontWeight:"bold"}}>Total Duration:<br />{(this.state.total_trip_duration != '')?this.state.total_trip_duration:0}</span>
                                        
                                    </div>

                                    <div className="col-xl-6 col-lg-6" style={{height:"500px"}}>
                                      
                                        {/* <div className="col-xl-12 col-lg-12">
                                            <input type="checkbox" className="tollcheck" id="tollcheck" onClick={this.onClickShowTolls.bind(this)} /> Show Tolls
                                        </div> */}
                                        <div id="myGrid" style={{ height: "560px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                onCellClicked={this.popmarker}
                                                headerHeight={this.state.headerHeight}
                                                rowHeight={this.state.rowHeight}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                            {/* <DrawMap 
                                                context={this}
                                                mapFor={"routeplan"}
                                                mapinfo={this.state.mapinfo}
                                                startlat={this.state.startlat}
                                                endlng={this.state.endlng}
                                                selectedorigin={this.state.selectedorigin}
                                                selecteddestination={this.state.selecteddestination}
                                            /> */}
                                        <div className="col-xl-12 col-lg-12">
                                            <input 
                                                type="checkbox" 
                                                id="baseroute" 
                                                checked={this.state.baseroutecheck}
                                                onClick={this.onClickShowTolls.bind(this)} /> Show Tolls
                                        </div>
                                        <div id="map" style={{width:'100%',height:"70vh"}}></div>
                                        
                                    </div>
									
								</div>:""}
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dispatchtime').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false,
    });
    
    
	$(".styles_modal__gNwvD").css({width:"450px"})
};

document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
   
	$('#dispatchtime').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false,
	});
	
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}

function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function setBg(){
	const randomColor = Math.floor(Math.random()*16777215).toString(16);
	return "#"+randomColor
  }
  function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }