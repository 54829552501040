import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class RakePlanCounter extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
            planned:0,
            invoiced:0,
            exposedinvoicedcnt:0,
            totalinvoice:0,
            revisedCounter : 0,
            tobeapprovedcounter: 0
    	}
    
    }
    
    componentWillReceiveProps(newprops){
        console.log("Newporps ", newprops)
       this.setState({
            planned:parseInt(newprops.planned),
            invoiced:parseInt(newprops.sndloadinvoiceCounts)-parseInt(newprops.exportsCounts),
            exposedinvoicedcnt:newprops.exportsCounts,
            totalinvoice:parseInt(newprops.sndloadinvoiceCounts),
            revisedCounter : newprops.revisedCounter,
            tobeapprovedcounter : newprops.tobeapprovedcounter
       })

    }
    onClickCounter(data){
        console.log(this.props)
        this.props.onClickCounterShowData(data)
    }
    render(){
       
        return (
            <div className="row">

                 <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-info"></i><br /> Cars Planned</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.planned != '')?this.state.planned:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-secondary"></i><br /> Revised Car Plan</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.revisedCounter != '')?this.state.revisedCounter:0}/></span></h4>
                                    </div>
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-success"></i><br /> Domestic Invoiced</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.invoiced != '')?this.state.invoiced:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-danger"></i><br /> Exports Invoiced</span>
                                        <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.exposedinvoicedcnt != '')?this.state.exposedinvoicedcnt:0}/></span></h4>
                                        
                                    </div>
                                    <div className="col cirlce-d cursorPointer">
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-info"></i><br /> Total Invoiced</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.totalinvoice != '')?this.state.totalinvoice:0}/></span></h4>
                                        
                                    </div>
                                    {/* <div className="col cirlce-d cursorPointer" >
                                        <span className="f13"><i className="icofont icofont-car-alt-1 f24 txt-info"></i><br />To be Approved</span>
                                        <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.tobeapprovedcounter != '')?this.state.tobeapprovedcounter:0}/></span></h4>
                                    </div> */}
                                    
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                
           
            </div>
        );
    }
}






