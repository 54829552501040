
import React from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');


const RoutemapTransitAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props ", props);
        props.agGridReact.props.gridOptions.context.componentParent.onShowDayWiseRouteMap(props.data);
    };

    return (
        <div>
            <button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-map-pins f20"></i> </button>

        </div>
    );
};

export default RoutemapTransitAction;
