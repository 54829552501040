
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');



export default class Showtolls extends Component {
    constructor(props) {
      super(props);
      this.state ={
          value:false
      }
      this.checkedHandler = this.checkedHandler.bind(this);
    }
  
    checkedHandler(event){
        var inputid =event.target.id;
        var checking = document.getElementById(inputid).checked
        // console.log("checking ",checking)
        // console.log("props ",this.props)
        var checkedlst = []
        var uncheckedlst = []
        if(checking == true)
        {
            uncheckedlst.push(inputid)
        }
        if(checking == false)
        {
            checkedlst.push(inputid)
        }
       // console.log("checkedlst ", checkedlst)
        event.stopPropagation();
        if(checking == true)
        {
            this.props.context.componentParent.onClickShowRouteWsie(this.props);
        }
        else
        {
            this.props.context.componentParent.onClickHideRouteWise(this.props);
        }

        this.setState({
            value:!this.state.value
        })
    }

    
    render() {
        //console.log("this.Defs ", this.props)
      return (
        <div>
            <span style={{fontSize:"12px"}}>
                <input 
                type="checkbox" 
                id = {"c_"+this.props.colDef.colId}
                className={"c_"+this.props.colDef.colId}
                onClick={this.checkedHandler}
                checked={this.state.value}
                />&nbsp;Show Tolls
           
            </span>
        </div>
      )
    }
  }
