
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Consignmenttickets = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onShowConsignmentTickets(props);
    };
    
    //}
    return (
        <div>
            <button onClick={handleEventClick} className="custom-btn f22 label label-success" 
            title="Ticket" ><i className={"icofont icofont-ticket "}></i> &nbsp;</button>
            
        </div>
    );
};

export default Consignmenttickets;
