
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const LoadVinLegs = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onShowVinLegs(props);
    };

    return (
        <div className="map-icon-component">
			<button onClick={handleRouteClick} className="custom-btn label label-success" title="View VIN List"><i className="icofont icofont-car-alt-3 f25"></i> </button>
       
        </div>
    );
};

export default LoadVinLegs;
