import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import CommonMapView from './commonmapview';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
export default class TrackConsignmentByInvoice extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  rownode:[], 
		  googelRoutes:[], 
		  maptruckno:'',
          maplastlocation:'',
          mapeta:'',
		  dealer:'',
		  mapinfo:[],
		  defTransitCoords:'',
		  showdetails:'show-n',
		  showsecond:'show-n'
		};
		
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
	}
	componentDidMount = async() =>{
		this.setState({
			loadshow:'show-m'
		});
		var invoice_no = this.props.match.params.invoice_no;
        try{
            var reqdata = {
                invoice_no:invoice_no,
                status:8
            }
            
            // await redirectURL.post('/amns/sendShortURL',reqdata)	
            // .then( async (response) => {
            //     console.log("response ", response);
            // });
            await redirectURL.post('/amns/truckshipment',reqdata)	
            .then( async (response) => {
                if(response.data.length > 0)
                {
                    var e = response.data[0];
                    if(e.parking_in_time != "" && e.parking_in_time != undefined)
                    {
                        var ptime = moment.parseZone(e.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
                    }
                    else{
                        var ptime = moment.parseZone().format('YYYY-MM-DD')+" 00:00:00";
                    }

                    if(e.status == "9" || e.status == "10" || e.status == 9 || e.status == 10)
                    {
                        var end_date = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
                    }
                    if(e.status == "11" || e.status == 11)
                    {
                        var end_date = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss");
                    }
                    else{
                        if(e.status == 6 || e.status == "6")
                        {
                            var end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss');
                        }
                        else{
                            var end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
                        }                        
                    }

                    var reqData = {
                        truck_no:e.truck_no,
                        shipment_id:e.shipment_id,
                        consignment_codes:[e.consignment_code],
                        consignee_code:e.consignee_code,
                        fromdate:ptime,
                        todate:end_date,
                        screen:"consignment"
                    }
                    //console.log("reqData: ", reqData);
                    await redirectURL.post('/trucks/truckmap',reqData, {
                        headers:{
                            'content-type':'application/json'
                        }
                    })
                    .then((response) => {
                        var records = response.data;
                        // console.log("Props data ", records)

                        this.setState({
                            loadshow:'show-m'
                        });
                        if(records.coords.length == 0)
                        {
                            //console.log("records", records.coords);
                            this.setState({
                                show: true,
                                basicTitle:'Tracking is not available',
                                basicType:"danger",
                                loadshow:'show-n',overly:'show-n',
                            });
                        }
                        else{
                            if(records != '')
                            {
                                var sdate = e.gate_out_time;
                                if(e.invoice_time)
                                {
                                    //console.log("came into invoice time", e.invoice_time)
                                    //console.log("Step1", e.invoice_time)
                                    var sdate = e.invoice_time;
                                }
                                else{
                                    //console.log("Step2")
                                    if(e.gate_in_time)
                                    {
                                        //console.log("came into gate in time", e.gate_in_time)
                                        //console.log("Step3")
                                        var sdate = e.gate_in_time;
                                    }
                                    else{
                                        //console.log("Step4")
                                        if(e.gate_out_time){
                                            //console.log("Step5")
                                            //console.log("came into gate out time", e.gate_out_time)
                                            var sdate = e.gate_out_time;
                                        }
                                    }
                                }


                                var edate ='';
                                if(e.status <= 4)
                                {
                                    //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
                                    //var edatesecond = new Date(ms);
                                    end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                                    edate = getDDMMYYYYHHMMSS(end_date);
                                }
                                else if(e.status == 5)
                                {
                                    if (e.left_dealer_marked)
                                    {
                                        edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
                                    }
                                    else{
                                        end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
                                        edate = getDDMMYYYYHHMMSS(end_date);
                                    }
                                }
                                //console.log("End Date ", edate)
                                this.setState({
                                    sliderRouteTranslate:"slider-translate-60p",
                                    showDiv:'show-m',
                                    mapinfo:records,
                                    dealer:e.consignee_code,
                                    consignment_code:"Consignment : "+e.consignment_code,
                                    maptruckno:e.truck_no,
                                    maplastlocation:e.last_known_address,
                                    mapeta:e.revised_trip_end,
                                    routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
                                    loadshow:'show-n',
                                    sidebarSubHeader:"Tracking Information For Invoice No - "+this.props.match.params.invoice_no,
                                    overly:'show-n',
                                    rownode:e,
                                    leg_no:0
                                });
                                this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
                                //this.renderMap();

                            }
                        }
                        
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
                else
                {
                    this.setState({
                        show: true,
                        basicTitle:'Tracking is not available',
                        basicType:"danger",
                        loadshow:'show-n',overly:'show-n',
                    });
                }
            })
            .catch(function(e){
                console.log(e)
            })
                
        
        }
        catch(e){
            
        }
        
    
		
	}
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}


	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			showsecond:'show-n'
		});
		
	}
	onClickShowDetails(){
		this.setState({
			//loadshow:'show-m',
			overly:'show-m',
			showdetails:'show-m',
			inboxSlider:'slider-translatey',
			showsecond:'show-m'
		});
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    render () {
        const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	  
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
					{this.state.alert}
					{(this.state.mapinfo != '')?
						<div className="col-xl-12 col-md-12 n-p-0">
							<CommonMapView 
								context={this} 
								rownode={this.state.rownode} 
								mapFor={"consignment"} 
								//googleroutes={this.state.googelRoutes} 
								truckno={this.state.maptruckno} 
								dealer={""} 
								mapinfo={this.state.mapinfo} 
								defTransitCoords={this.state.defTransitCoords} 
							/>
                            <div className="col-xl-12 col-lg-12 n-p-0">
                                <div className="crm-numbers pb-0">
                                    <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                    <div className="col-xl-12 col-lg-12 row">
                                        <div className="col route-block">
                                            <label className="sidebar-label">Truck No</label>
                                            <div>{this.state.routeTruck.truck_no}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">Start Time</label>
                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">End Time</label>
                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">GPS Distance</label>
                                            <div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
                                        </div>                                        
                                        <div className="col route-block">
                                            <label className="sidebar-label">Last Location</label>
                                            <div>{this.state.maplastlocation}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">ETA</label>
                                            <div>{(!isEmpty(this.state.mapeta))?getDDMMYYYYHHMMSS(this.state.mapeta):"-"}</div>
                                        </div>                           
                                    </div>
                                </div>
                            </div>
							{/* <div className="andriomap-pos" onClick={this.onClickShowDetails.bind(this)}>
								<i className="icofont icofont-dotted-up ico-color"></i>
							</div>
							
							<div className={"divpos zindex999 "+(this.state.inboxSlider)}>
								<div className={"andriomap-pos2 pt-10px "+(this.state.showsecond)} onClick={this.onClickHideAll.bind(this)}>
									<i className="icofont icofont-dotted-down ico-color"></i>
								</div>
								<div className="crm-numbers mt-20p prel pb-0">
									<div className="col-xl-12 col-lg-12 textAlignCenter row">
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Truck No</label>
											<div>{this.state.routeTruck.truck_no}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Start Time</label>
											<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
										</div>
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">End Time</label>
											<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">GPS Distance</label>
											<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
										</div>
										{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
										:
										<div className="col-xl-12 col-lg-12 textAlignCenter route-block">
											<label className="sidebar-label">Google Distance</label>
											<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
										</div>
										}
										
									</div>
								</div>
							</div> */}
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
							</div>
							<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
					
					 </div>
					
					:
                    // <h4 style={{display: "flex",justifyContent: "center",alignItems: "center",textAlign: "center",minHeight: "90vh",color:"red"}}>Tracking is not available</h4>
                    ""
                    }
							
			</div>
        )
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
