import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getHyphenYYYYMMDD } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Editusdrate from "./editusdrate";
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class USDRates extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:moment.parseZone().subtract(30, 'days').format("YYYY-MM-DD"),
            endDate:moment.parseZone().format("YYYY-MM-DD"),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true
            },
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
             overly : "show-n",
            loadshow : "show-n",
            rowData : [],
            frameworkComponents : {
                Editusdrate:Editusdrate
            },
            conversion_rate:'',
            conversion_date:'',
            selectrowid:'',
            show:false,
            basicTitle:"",
            basicType:""
        }
        this.onLoadUSDRates = this.onLoadUSDRates.bind(this); 
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickEditData = this.onClickEditData.bind(this);
    }
    componentDidMount(){
        
        
        this.onLoadUSDRates();
    }

    onLoadUSDRates(){
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/consignments/usdrates")
        .then((response) => {
           // console.log("Response ", response.data)
            this.setState({
                rowData:response.data,
                loadshow : "show-n",
                overly : "show-n"
            })
        })
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
    }
    handlerDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		conversion_date:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    changeHandler = (event) => {
    	console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    onClickStoreData(){
        var conversion_rate = this.state.conversion_rate
        var conversion_date = this.state.conversion_date
        if(conversion_date != "NaN-NaN-NaN")
        {
            var conversiondate = conversion_date
        }
        else
        {
            var conversiondate = ""
        }
        if(conversiondate != "" && conversion_rate != "")
        {
            
            //console.log("Parameters ", parameters);
            if(this.state.selectrowid == "")
            {
                var parameters = {
                    conversion_date:conversiondate,
                    conversion_rate:conversion_rate
                }
                redirectURL.post("/consignments/storeusdrates", parameters)
                .then((response) => {
                    //console.log("Response ", response.data)
                    if(response.data.message == "success")
                    {
                        this.setState({
                            conversion_date:"",
                            conversion_rate:"",
                            show:true,
                            basicTitle:"Successfully added/update",
                            basicType:"success"
                        })
                    }
                    if(response.data.message == "exists")
                    {
                        this.setState({
                            conversion_date:"",
                            conversion_rate:"",
                            show:true,
                            basicTitle:"Already data exists for the selected date",
                            basicType:"info"
                        })
                    }
                    this.onLoadUSDRates();
                })
            }
            else
            {
                var parameters = {
                    conversion_date:conversiondate,
                    conversion_rate:conversion_rate,
                    selectrowid:this.state.selectrowid
                }
                redirectURL.post("/consignments/updateusdrates", parameters)
                .then((response) => {
                    //console.log("Response ", response.data)
                    if(response.data.message == "success")
                    {
                        this.setState({
                            conversion_date:"",
                            conversion_rate:"",
                            show:true,
                            basicTitle:"Successfully added/update",
                            basicType:"success"
                        })
                    }
                    if(response.data.message == "exists")
                    {
                        this.setState({
                            conversion_date:"",
                            conversion_rate:"",
                            show:true,
                            basicTitle:"Already data exists for the selected date",
                            basicType:"info"
                        })
                    }
                    this.onLoadUSDRates();
                })
            }
        }
        else
        {
            this.setState({
                conversion_date:"",
                conversion_rate:"",
                show:true,
                basicTitle:"Fields can not be empty",
                basicType:"danger"
            })
        }
    }
    
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n'
		});
		
    }
    onClickEditData(params){
        //console.log("params ", params.data)
        var data = params.data;
        var conversedate = getHyphenYYYYMMDD(data.usd_date);
        this.setState({
            conversion_date:conversedate,
            conversion_rate:data.rate,
            selectrowid:params.data._id
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    render(){
       var columnwithDefs = [
            {
                headerName:"",
                field:"_id",
                width:80,
                cellRendererSelector:function(params){
                    
                    var rendComponent = {
                        component: 'Editusdrate'
                    };
                    return rendComponent
                
                },
            },
            {
               headerName:"Date",
               field:"usd_date",
               width:180,
               valueGetter:function(params){
                   try{
                        if(params.data.usd_date != "")
                        {
                            return getHyphenYYYYMMDD(params.data.usd_date)
                        }
                        else
                        {
                            return "";
                        }
                   }
                   catch(e){
                       return "";
                   }
               }
           },
           {
                headerName:"Conversion Rate",
                field:"rate",
                width:180
            }
       ]
        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow"}>		
                        <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>USD Rates</span>
                            </h5>                    
                        </div>
                       
                        <div className=" col-xl-12 col-lg-12 mt-35p">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Choose Date: </label>
                                    <Datetime value={this.state.conversion_date} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Date', name: 'conversion_date', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="conversion_date" 
                                    onChange={this.handlerDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Conversion Rate: </label>
                                    <input
                                        value={this.state.conversion_rate}
                                        type="text"
                                        name="conversion_rate"
                                        onChange={this.changeHandler} 
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickStoreData.bind(this)}>Submit</button>
                                </div>
                            </div> 
                        </div>
                        
                            
                        
                        <div className={"row mt-40p "}>
                            <div className="col-xl-12">
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">  
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        frameworkComponents={this.state.frameworkComponents}
                                        suppressRowClickSelection={true}
                                    />

                                </div>
                            </div>
                        </div>
                                            
	                </div> 

                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	           </div>
			);
		}
}
