import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import Select from 'react-select';

var redirectURL = require('../redirectURL');

export default class AssignMenusToRolesCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes:this.props.nodes,
            checked: this.props.sendchecked,
            expanded: this.props.sendexpanded
        };
    }
    componentDidMount(){
        this.setState({
            
        })
    }
    // componentWillReceiveProps(newprops){
    //     if(this.props.rolename != newprops.rolename)
    //     {
    //         console.log("newprops.rolename ", newprops.rolename)

    //         console.log("newprops.rolename ", newprops.rolename)
    //         this.setState({
    //             nodes:newprops.nodes,
    //             checked: newprops.checked,
    //             expanded: newprops  .expanded
    //         });  
    //     }
    // }
    render() {
        console.log("Props Nodes " ,this.state.nodes)
        console.log("Props checked ",this.state.checked)
        console.log("Props expanded ",this.state.expanded)
        return (
            <CheckboxTree
                nodes={this.state.nodes}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={checked => this.setState({ checked })}
                onExpand={expanded => this.setState({ expanded })}
            />
        );
    }
}