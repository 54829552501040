

import React, { Component } from 'react';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class DashboardSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderRouteTranslate:'',
      serires:[],
      categories:[],
      activeserires:[],
      activecategories:[],
			loadshow:'show-n',
      overly:'show-n',
      leftTitle:'',
      leftSubTitle:'',
      activeleftTitle:'',
      activeleftSubTitle:'',
      sidebartitle:"",
      summarydata:[],
      originaldata:[],
      criticaldata:[],
      showdashboard:'show-m',
      loaddashboard:'show-n',
      bila:'show-m',
      bidrd:'show-n',
      bitransit:'show-n',
      usergridstate:[],
      screenurl:"",
      screentitle:"",
      pagetitle:"Dashboard Summary",
      eventGridAction: "gridAction",
      screenView: ""
    };
  }

	componentDidMount(){
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
    $(document).ready(function(){
      $(".loadclickm").on("mouseover",function(){
        var id = $(this).attr("id");
        //$(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide()
        if(id == "load")
        {
          $("#"+id).addClass("active");
          $(".dashboard-sub").show()
        }

      })

      $(".loadclickm").on("mouseout",function(){
        var id = $(this).attr("id");
        $(".dashboard-sub").hide()
        
      })
    })
    var screenView = "";
    if(this.props.match.path == "/mgmtdashboardsummary")
    {
      screenView = "Mobile"
    }
    var defaultdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
    this.setState({
      startDate:defaultdate,
      endDate:defaultdate,
      loadshow:'show-m',
      overly:'show-m',
      screenView: screenView
    });
   
        redirectURL.post("/consignments/dashboardsummary")
        .then((response) => {
          var recordsarr = response.data.message;
          console.log("Respons ", recordsarr)
          var summaryarr=[]
          var ytitle='';
          recordsarr.map((item) => {
            console.log("item.dept_code ", item.dept_code)
            if(item.dept_code == 'SNDG')
            {
              ytitle="Trucks"
            }
            if(item.dept_code == 'LOG-PRT')
            {
              ytitle="Consignments"
            }
            if(item.dept_code == "LOG-PRT (Courier)")
            {
              ytitle="Consignments"
            }
            if(item.dept_code == 'LOG-SC')
            {
              ytitle="Containers"
            }
            if(item.dept_code == 'LOG-TNP')
            {
              ytitle="Trucks"
            }
            if(item.dept_code == 'LOG-SC (Exports)')
            {
              ytitle="Containers"
            }
            summaryarr.push({
              categories:['Total','On Time','Delayed','Critical'],
              series:[{
                  name:'Total',
                  data:[item.total_active_count],
                  percent:0,
                  code:item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g,'_'),
                  dept_name:item.dept_name,
                  critical_list:item.critical_tpt_list,
                  critical_data:item.critical_data,
                  dept_code:item.dept_code
                },{
                  name:'On Time',
                  data:[item.delay_counts.OnTime],
                  percent:Math.round((item.delay_counts.OnTime/item.total_active_count)*100),
                  code:item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g,'_'),
                  dept_name:item.dept_name,
                  critical_list:item.critical_tpt_list,
                  critical_data:item.critical_data,
                  dept_code:item.dept_code
                },{
                  name:'Delayed',
                  data:[item.delay_counts.Delayed],
                  percent:Math.round((item.delay_counts.Delayed/item.total_active_count)*100),
                  code:item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g,'_'),
                  dept_name:item.dept_name,
                  critical_list:item.critical_tpt_list,
                  critical_data:item.critical_data,
                  dept_code:item.dept_code
                },{
                  name:'Critical',
                  data:[item.delay_counts.Critical],
                  percent:Math.round((item.delay_counts.Critical/item.total_active_count)*100),
                  code:item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g,'_'),
                  dept_name:item.dept_name,
                  critical_list:item.critical_tpt_list,
                  critical_data:item.critical_data,
                  dept_code:item.dept_code
                }
              ],
              total_active_count:item.total_active_count,
              critical:Math.round((item.delay_counts.Critical/item.total_active_count)*100),
              onTime:Math.round((item.delay_counts.OnTime/item.total_active_count)*100),
              delayed:Math.round((item.delay_counts.Delayed/item.total_active_count)*100),
              title:item.dept_name,
              yaxistitle:ytitle,
              dept_code:item.dept_code,
              code:item.dept_name.replace(/[&\/\\#,+()$~%.'" :*?<>{}]/g,'_'),
            })
          });

          this.setState({
            summarydata:summaryarr,
            originaldata:recordsarr,
            loadshow:'show-n',
            overly:'show-n',
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.params = params;
    this.gridColumnApi = params.columnApi;
    

  };

  onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
      sliderRouteTranslate:'',
      sliderTicketTranslate:'',
      tabsliderTranslate:'',
			inboxSlider:''
		});
		
	}
  onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
  onClickShowCritical(params){
    
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        "category": pageTitle,
        "action": this.state.eventGridAction,
        "label": "Filters",
      }
      googleAnalytics.logEvent(eventOptions);
    }
    var data = this.state.originaldata;
    var relateddata = []
    if(data.length > 0)
    {
      data.map((item) => {
        if(item.dept_code == params)
        {
          relateddata.push(item.critical_tpt_list)
        }
      })
      //console.log("relateddata ",relateddata)
      if(relateddata.length > 0)
      {
        if(relateddata[0].length > 0)
        {
            var convertdata = []
            relateddata[0].map((itm) => {
              convertdata.push({
                delay_type:itm.delay_type,
                len:parseInt(itm.len),
                max:parseInt(itm.max),
                min:parseInt(itm.min),
                transporters_name:itm.transporters_name
              })
            })
            convertdata.sort(GetSortOrder("len"));

            console.log("After Sort convertdata ",convertdata)
            this.setState({
              criticaldata:convertdata,
              sliderRouteTranslate:"slider-translate-100p"
            })
        }
      }
    }
   
  }

  onClickLoadDept(params){
    if(params == 'dashboard')
    {
        this.setState({
            showdashboard:'show-m',
            loaddashboard:'show-n'
        })
    }
    if(params == 'load')
    {
        this.setState({
            showdashboard:'show-n',
            loaddashboard:'show-m'
        })
    } 
  }

  onClickBIItem(params){
    if(params == 'loaddashboard')
    {
        this.setState({
          bidrd:'show-n',
          bila:'show-m',
          bitransit:'show-n'
        })
    }
    if(params == 'dailydashboard')
    {
        this.setState({
          bila:'show-n',
          bidrd:'show-m',
          bitransit:'show-n'
        })
    } 
    if(params == 'transitanalytics')
    {
      this.setState({
        bila:'show-n',
        bidrd:'show-n',
        bitransit:'show-m'
      })
    }
  }
  logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
  // redirectNavPage(pageurl){
  //   return <Redirect to={pageurl} />
  // }

  render() {
 //console.log("SND93221ROls" , localStorage.getItem("roles"))
     
    return (
      <div className="col-xl-12 col-lg-12">
        
         <div className={(this.state.screenView == "Mobile")?"show-n":"row col-xl-12 col-lg-12 "}>
           <ul className="dashboard-page">
               <li className="active">
                    <a href={"/dashboardsummary"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                    </a>
                
                </li>
                {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
                {(this.state.dept_code != 'LOG-SC')?
                  <li className="btn-group">
                    <a href="javascript:;"  className="loadclickm" id="load">
                      <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />
                       
                      <ul className="dashboard-sub" style={{"display":"none"}}>
                       
                             <li>
                                  <a href="/dailyrunningmanagement">
                                      Daily Running
                                  </a>
                              </li>
                              
                              <li>
                                  <a href="/loadingdashboard">
                                      Loading Dashboard
                                  </a>
                              </li>


                              <li>
                                  <a href="/livetrucksinfo">
                                  Truck Location (Map)
                                  </a>
                              </li>

                              <li>
                                  <a href="/msilvesselschedule">
                                  MSIL Vessel Schedule
                                  </a>
                              </li>

                              {/* <li>
                                  
                                  <Route render={({ history}) => (
                                      <a
                                      href="javascript:;"
                                        onClick={() => { history.push('/dailyrunningmanagement') }}
                                      >
                                        Daily Running
                                      </a>
                                    )} />
                              </li>
                              
                              <li>
                                

                                  <Route render={({ history}) => (
                                      <a
                                      href="javascript:;"
                                        onClick={() => { history.push('/loadingdashboard') }}
                                      >
                                       Loading Dashboard
                                      </a>
                                    )} />

                              </li> */}

                          </ul>
                    </a>
                  </li>
                  :""}
                {/* :""} */}
            </ul>	
        </div>
        <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"}>		
              <div className="col-xl-12 col-lg-12">		            	
                  <h5>
                        <i className="icofont icofont-dashboard-web cus-i"></i> <span>Performance Metrics</span>
                        <span className={(this.state.screenView == "Mobile")?"show-n":"float-right f12 "}> 
                              <a className="btn btn-danger" style={{float:"left",marginLeft:"10%"}} href="/managementdashboard">Back</a>
                        </span>
                  </h5>                    
              </div>
                    
          {/* <div className="col-xl-12 col-lg-12">		            	
                <h5>
                  <i className="icofont icofont-dashboard-web cus-i"></i> <span>Dashboard </span>
                </h5>                    
            </div> */}
        
          <div className="row mt-20p"> 
          
          {/* <ul className="fixtb">
                <li>
                      <a href="javascript:;" >Dashboard</a>
                  </li>
                <li>
                      <a href="javascript:;" >Dashboard</a>
                  </li>
                <li>
                      <a href="javascript:;" >Dashboard</a>
                  </li>
                <li>
                      <a href="javascript:;" >Dashboard</a>
                  </li>
                
              </ul>	 */}
            {
              this.state.summarydata.map(item=>
          
              (localStorage.getItem("roles") == 'PRT DDVM,TNP DDVM,SC DDVM')?
                (item.dept_code == 'LOG-PRT' || item.dept_code == 'LOG-PRT (Courier)' || item.dept_code == 'LOG-TNP' || item.dept_code == 'LOG-SC')?
                <div className="col-xl-6 col-lg-6 col-sm-6 beffect mb-20p">
              
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <ColumnGroup 
                    context={this}
                    series={item.series}
                    categories={item.categories}
                    title={item.title}
                    subtitle={"On time performance of the on going trips"}
                    yaxistitle={item.yaxistitle}
                    code={item.code}
                    critical_data={item.critical_data}
                    dept_code={item.dept_code}
                  />
                  </div>
                </div>
                :""
              :""
              )}
              {
              this.state.summarydata.map(item=>
                
                (localStorage.getItem("roles") == 'SND DDVM')?
                (item.dept_code == 'SNDG')?
                <div className="col-xl-6 col-lg-6 col-sm-6 beffect mb-20p">
              
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <ColumnGroup 
                  context={this}
                  series={item.series}
                  categories={item.categories}
                  title={item.title}
                  subtitle={"On time performance of the on going trips"}
                  yaxistitle={item.yaxistitle}
                  code={item.code}
                  critical_data={item.critical_data}
                  dept_code={item.dept_code}
                />
                </div></div>
                :""
              :""
              )}
              {
              this.state.summarydata.map(item=>
                <div className="col-xl-6 col-lg-6 col-sm-6 beffect mb-20p">
              
                  <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
              {(localStorage.getItem("roles") != 'SND DDVM' && localStorage.getItem("roles") != 'PRT DDVM,TNP DDVM,SC DDVM')?
                
                <ColumnGroup 
                  context={this}
                  series={item.series}
                  categories={item.categories}
                  title={item.title}
                  subtitle={"On time performance of the on going trips"}
                  yaxistitle={item.yaxistitle}
                  code={item.code}
                  critical_data={item.critical_data}
                  dept_code={item.dept_code}
                />
                
              :""}
                </div>
              
                </div>
            
              )}

            
          </div>
          <div className={"dataLoadpage " +(this.state.loadshow)}></div>
          <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
          </div>
          <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

        {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
        {/* <div className={"col-xl-12 col-lg-12 whiteRow n-p-0 "+(this.state.loaddashboard)}>
          <ul className="tabc-sbm col-xl-12 col-lg-12 row n-p-0" style={{marginTop:"-22px"}}>
            <li>
              <a href="javascript:;" className="biclickm active" id="loaddashboard" onClick={this.onClickBIItem.bind(this,'loaddashboard')}>
                 <span>Loading Analytics </span>
              </a>
            </li>
            <li>
              <a href="javascript:;" className="biclickm" id="dailydashboard" onClick={this.onClickBIItem.bind(this,'dailydashboard')}>
                  <span>Daily Running Summary </span>
              </a>
            </li>

            
            
          </ul>
            <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bila)}>
                  <LoadingDashboard  />
              </div>
              <div className={"col-xl-12 col-lg-12 n-p-0 mt-35p "+(this.state.bidrd)}>
                <DailyDashboard showexclude={0} />
            </div>
            
          </div>  */}
      </div>
    );
  }
}

function GetSortOrder(prop) {    
  return function(a, b) {    
      if (a[prop] < b[prop]) {    
          return 1;    
      } else if (a[prop] > b[prop]) {    
          return -1;    
      }    
      return 0;    
  }    
} 


// $(document).ready(function(){
    
//   $(".loadclickm").click(function(){
//       var id = $(this).attr("id");
//       $(".loadclickm").removeClass("active");
//       $("#"+id).addClass("active");
//   })
//   $(".biclickm").click(function(){
//       var id = $(this).attr("id");
//       $(".biclickm").removeClass("active");
//       $("#"+id).addClass("active");
//   })
// })


$(document).ready(function(){
  $(".loadclickm").on("mouseover",function(){
    var id = $(this).attr("id");
  //  $(".loadclickm").removeClass("active");
    $(".dashboard-sub").hide()
    if(id == "load")
    {
      $("#"+id).addClass("active");
      $(".dashboard-sub").show()
    }

  })

  $(".loadclickm").on("mouseout",function(){
    var id = $(this).attr("id");
    //$(".loadclickm").removeClass("active");
    $(".dashboard-sub").hide()
    // $("#"+id).addClass("active");
    // $("#"+id+">dashboard-sub").show()

  })
  // $(".loadclickm").click(function(){
  //     var id = $(this).attr("id");
  //     $(".loadclickm").removeClass("active");
  //     $("#"+id).addClass("active");
  // })
  // $(".biclickm").click(function(){
  //     var id = $(this).attr("id");
  //     $(".biclickm").removeClass("active");
  //     $("#"+id).addClass("active");
  // })
})