import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            deptcode:this.props.deptcode,
            triptype:this.props.triptype,
            active_trucks:0,
            loading_delay:0,
            unloading_delay:0,
            enroute_stoppage:0
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{
        if(newprops.deptcode != this.state.deptcode)
        {
        //console.log("Conters ",newprops.deptcode)
            if(newprops.triptype && newprops.triptype != 0)
            {
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode,
                    trip_type:newprops.triptype
                }
            }
            else{
                var layoutdata={
                    token:localStorage.getItem('token'),
                    dept_code:newprops.deptcode
                }   
            }
            /*redirectURL.post('/consignments/getConsignmentCounters', layoutdata,{
                headers:{
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                }
            })
            .then((response) => {
                //console.log("totalTrucksCount",response);
                var counterdata = JSON.parse(response.data.body);
                
               //console.log("counterdata ",counterdata);
               if(counterdata != null)
               {
                this.setState({
                    active_consignments:counterdata.active_consignments,
                    overspeeding:counterdata.overspeeding,
                    night_driving:counterdata.night_driving,
                    transit_delay:counterdata.transit_delay,
                    unloading_dealer:counterdata.unloading_dealer,
                    left_dealer:counterdata.left_dealer,
                    gps_data_available:counterdata.no_gps_data,
                    active_trucks:counterdata.active_trucks,
                    loading_delay:counterdata.loading_delay,
                    unloading_delay:counterdata.unloading_delay,
                    enroute_stoppage:counterdata.enroute_stoppage,
                });
               }
                
            });
            */
            /*Venkatesh Code*/
            var transitdelaysarr=[];
            var gpsdatanaarr=[];
            var overspeedtrucksarr=[];
            var nightdrivearr=[];
            var enroutearr=[];
            var loadingarr=[];
            var unloadingarr=[];
            //console.log("newprops.gridData ", newprops.gridData)
            if(newprops.gridData.length > 0)
            {
                transitdelaysarr = await newprops.gridData.filter(rec => {
                    if (rec["transit_delay"]) {
                        return rec["transit_delay"] == 1;
                    }
                });
                gpsdatanaarr = await newprops.gridData.filter(rec => {
                    if (rec["no_gps_data"]) {
                        return rec["no_gps_data"] == 1;
                    }
                });
                overspeedtrucksarr = await newprops.gridData.filter(rec => {
                    if (rec["overspeeding_exception"]) {
                        return rec["overspeeding_exception"] == 1;
                    }
                });
                nightdrivearr = await newprops.gridData.filter(rec => {
                    if (rec["nightdriving_exception"]) {
                        return rec["nightdriving_exception"] == 1;
                    }
                });
                unloadingarr = await newprops.gridData.filter(rec => {
                    if (rec["unloading_delay_exception"]) {
                        return rec["unloading_delay_exception"] == 1;
                    }
                });
                loadingarr = await newprops.gridData.filter(rec => {
                    if (rec["loading_delay_exception"]) {
                        return rec["loading_delay_exception"] == 1;
                    }
                });
                enroutearr = await newprops.gridData.filter(rec => {
                    if (rec["enroute_exception"]) {
                        return rec["enroute_exception"] == 1;
                    }
                });
               // console.log("transitdelaysarr ", transitdelaysarr)
               await this.setState({
                    active_consignments:newprops.gridData.length,
                    overspeeding:overspeedtrucksarr.length,
                    night_driving:nightdrivearr.length,
                    transit_delay:transitdelaysarr.length,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    gps_data_available:gpsdatanaarr.length,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay:loadingarr.length,
                    unloading_delay:unloadingarr.length,
                    enroute_stoppage:enroutearr.length,
                });

            }
            else{
                await this.setState({
                    active_consignments:0,
                    overspeeding:0,
                    night_driving:0,
                    transit_delay:0,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    gps_data_available:0,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay:0,
                    unloading_delay:0,
                    enroute_stoppage:0,
                }); 
            }

        }
        

    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '')?this.state.active_consignments:0}/></span></h4>
                                    
                                </div>
                                {/* <div className="col">
                                    <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Active Trucks</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>
                                    
                                </div> */}
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                    <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                   
                                </div>
                                {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT')?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.loading_delay != '')?this.state.loading_delay:0}/></span></h4>
                                </div>
                                }
                                 {(this.props.deptcode == 'LOG-PRT' || (this.props.unloadshow == true))?"":
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                    <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                </div>
                                }
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '')?this.state.enroute_stoppage:0}/></span></h4>                                   
                                </div>
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            {/*
            <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                <div className="card">
                    <div className="card-body">
                        
                        <div className="brd brd-txt1"></div>
                        <div className="stat-widget-dashboard">
                            <div className="media">
                                <div className="media-body text-left">
                                    <h4 className="mt-0 fnt3"><CountUp className="font-primary" end={(this.state.active_consignments != '')?this.state.active_consignments:0} /></h4>
                                    
                                </div>
                                
                            </div>
                                <div className="col-lg-12 col-sm-12 n-p-0 f15">Active Consignments</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
	                        <div className="brd brd-txt2"></div>
	                        <div className="stat-widget-dashboard">
                                <div className="media">
                                	<div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-secondary" end={(this.state.transit_delay != '')?this.state.transit_delay:0} /></h4>
                               
                                    </div>
                                      
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Transit Delays</div>
                            </div>
                        </div>
                    </div>
                </div>
	            
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
                			<div className="brd brd-txt3"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-primary" end={(this.state.overspeeding != '')?this.state.overspeeding:0} /></h4>
                                        
                                    </div>
                                     
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Overspeeding Trucks</div>
                            </div>
                        </div>
                    </div>
                </div>
               
	            
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
                        	<div className="brd brd-txt4"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-info" end={(this.state.night_driving != '')?this.state.night_driving:0} /></h4>
                            
                                    </div>
                                  
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Night Driving Trucks</div>
                            </div>
                        </div>
                    </div>
                </div>
                */}




               {/* <div className="col-xl-2 col-lg-6 col-sm-6 beffect">
	                <div className="card">
	                    <div className="card-body">
	                    	<div className="brd brd-txt3"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt3 counter font-success"><CountUp className="font-success" end={this.state.notrans} /></h4>
	                                    
	                                </div>
	                               
	                            </div>
	
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">Current Enroute Stoppages</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                */}
            </div>
        );
    }
}






