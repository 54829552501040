import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Updatemsilvesselreport from './updatemsilvesselreport';
import UpdateButton from "./updatelmstates";
import ApproveMSILItem from './approvemsilvesselitem';
import {getHyphenDDMMMYYYYHHMM} from "../common/utils";
import CSVFileValidator from 'csv-file-validator';

//import * as XLSX from 'xlsx';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
 
export default class MSILVesselSchedule extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "PPC Schedule",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			mapinfo:'',
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",

			alert1:null,
            show1: false,
			basicTitle1:'',
			basicType1:"default",

			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
              },
            columndefs:[],
      	    rowData: [],
            maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                Updatemsilvesselreport:Updatemsilvesselreport,
                ApproveMSILItem:ApproveMSILItem,
                UpdateButton:UpdateButton,
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			screenpage:'',
			pagetitle:'PPC Schedule',
			usergridstate:[],
			screenurl:"",
			screentitle:"",
            eventGridAction: "gridAction",
            ismgnt:"",
            sliderbulkupload: "",
            sliderbulkppcupload: "",
            csvcontent: [],
            csvppccontent: [],
            vesselData: "show-n",
            ppcData: "show-m",
            ppcRowData: [],
            vessel:"",
            ppc:"activet",
        }
        
        this.onLoadShowData = this.onLoadShowData.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        this.updateLmStates = this.updateLmStates.bind(this);
        this.onClickApproveItem = this.onClickApproveItem.bind(this);
        this.onLoadShowManagementData = this.onLoadShowManagementData.bind(this);
        this.onClickAddRow = this.onClickAddRow.bind(this);
		this.onClickAddPPCRow = this.onClickAddPPCRow.bind(this);
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
        console.log("this.props.match.path ", this.props.match.path)
        if(this.props.match.path == "/msilvesselschedule" || this.props.match.path == "/msilvesselstatus")
        {
            this.setState({
                ismgnt:1,                
                overlayNoRowsTemplate: "Pending approval from supply chain department",
                //pagetitle:"MSIL Vessel Schedule",
                pagetitle:"PPC Schedule"
            })
            this.onLoadShowManagementData()
        }
        if(this.props.match.path == "/managemsilvesselschedule" || this.props.match.path == "/managemsilvesselstatus")
        {
            this.setState({
                ismgnt:0,
                //pagetitle:"Manage MSIL Vessel Schedule",
                pagetitle:"PPC Schedule"
            })
            this.onLoadShowData()
        }
	};

    onLoadShowData(){
        redirectURL.post("/consignments/supplychainonereport")
        .then((response) => {
            var records = response.data.records;
            console.log(records,"records")
            this.setState({
                rowData:records,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
        redirectURL.post("/consignments/ppcschedule")
        .then((response1) => {
            var result = response1.data;
            console.log(result,"result")
            this.setState({
                ppcRowData:result,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
        
    }
	
    onLoadShowManagementData(){
        redirectURL.post("/consignments/approvedsupplychainonereport")
        .then((response) => {
            //console.log("response ", response)
            var records = response.data.records;
            var totalrecords = response.data.totalrecords;
            // if(parseInt(totalrecords) == records.length)
            // {
                this.setState({
                    rowData:records,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            // }
            // else{
            //     this.setState({
            //         rowData:[]
            //     })
            // }
        })
        redirectURL.post("/consignments/approvedppcschedule")
        .then((response1) => {
            var result = response1.data;
            console.log(result,"result")
            this.setState({
                ppcRowData:result,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };

    onAgGridReady = params => {
		this.gridApi1 = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi1 = params.columnApi;
    };


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            sliderbulkupload: "",
            sliderbulkppcupload: "",
		});
		
	}

	closeAlert = async() => {
        await this.setState({
            show: false
        });
        await window.location.reload();
	}

	closeAlert1 = async() => {
        this.setState({
            show1: false
        });
        
	}
	
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';

		if(this.props.match.path == "/railconsignments")
		{
			screenpage='rail consignments';
		}
		else if(this.props.match.path == "/deliveredrailconsignments")
		{
			screenpage='rail delivered consignments';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    changeFileHandler = async (e) => {
        var fileheaders = [
            "loading_vessel_name",
            "loading_schedule_etd_smz",
            "loading_schedule_eta_sin",
            "loading_revised_eta_smz",
            "loading_revised_eta_sin",
            "transshipment_vessel",
            "transshipment_schedule_eta_sin",
            "transshipment_schedule_etd_sin",
            "transshipment_revised_eta_sin",
            "transshipment_revised_etd_sin",
            "scheduled_pkg_eta",
            "revised_pkg_etd",
            "schedule_eta_ppv_ppc_schedule",
            "schedule_eta_ppv" ,
            "revised_eta_ppv",
            "delay_from_schedule",
            "transit_time_days",
            "delay_from_ppc_schedule",
            "remarks",
        ]
        var headersList= [];
        fileheaders.map(function(e){
            headersList.push({ 
                name: e,
                inputName: e,
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            })
        })
        const config = {
            headers: headersList
        }
        console.log(headersList)
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
            this.setState({
                csvcontent: csvData.data
            });
        })
        .catch(err => {})
    
        this.setState({
            file: e.target.files[0]
        });
    
        
    }
    uploadBulkForceFormHandler(e){
        e.preventDefault();
        // var flag = 0;
        // var headerKeys = Object.keys(this.state.csvcontent[0])
        // this.state.csvcontent.map(function(k){
        //     headerKeys.map(function(j){
        //         if(k[j] == "" || k[j] == undefined)
        //         {
        //             flag = 1;
        //         }
        //     })
        // })
        // if(flag == 0)
        // {
            var check  = this.state.csvcontent.map(function(k){
                var parameters = {
                    rowitem: k,
                    userid:localStorage.getItem("userid"),
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/updatemsilvesselitem",parameters)
                .then((response) => {
    
                })
            }) 
            Promise.all([check]).then(()=>{
                this.setState({
                    basicType: "success",
                    basicTitle: "success",
                    show: true
                })
            })
            
        // }
        // else
        // {
        //     this.setState({
        //         basicType: "warning",
        //         basicTitle: "Values Should Not be Empty",
        //         show: true
        //     })
        // }
    }
    changePPCFileHandler = async (e) => {
        var fileheaders = [
            "order_by",
            "loading_vessel_name",
            "order_no",
            "ppc_plan",
            "revised_atd_smz",
            "revised_eta_sin",
            "transshipment_vessel",
            "revised_etd_sin",
            "revised_ppv_nsa",
            "current_eta_msil",
            "days",
        ]
        var headersList= [];
        fileheaders.map(function(e){
            headersList.push({ 
                name: e,
                inputName: e,
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            })
        })
        const config = {
            headers: headersList
        }
        console.log(headersList)
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
            this.setState({
                csvppccontent: csvData.data
            });
        })
        .catch(err => {})
    
        this.setState({
            file: e.target.files[0]
        });
    
        
    }
    uploadBulkForcePPCFormHandler(e){
        e.preventDefault();
        if(this.state.csvppccontent.length > 0)
        {
            console.log(this.state.csvppccontent,"csvcontent")
            var check  = this.state.csvppccontent.map(function(k){
                var parameters = {
                    rowitem: k,
                    userid:localStorage.getItem("userid"),
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/updateppcschedule",parameters)
                .then((response) => {

                })
            }) 
            Promise.all([check]).then(()=>{
                $("#ppcbulkUploadID").val("");
                this.setState({
                    show1:true,
                    basicTitle1:"success",
                    basicType1:"success",
                    sliderbulkppcupload:"",
                    overly:"show-n"
                })
                if(this.state.ismgnt == 1)
                {
                    this.onLoadShowManagementData()
                }
                else
                {
                    this.onLoadShowData()
                }
            })
        }
        else
        {
            $("#ppcbulkUploadID").val("");
            this.setState({
                show1:true,
                basicTitle1:"Invalid File",
                basicType1:"warning",
            })
        }
    }
	onClickApproveAll(){
        let records = [];
        this.gridApi.forEachNode(node => records.push(node.data));
        // console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username")
        }
        redirectURL.post("/consignments/approveallmsilvesselitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Successfully update item",
                    basicType1:"success"
                })
                
                this.onLoadShowData()
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Something went wrong",
                    basicType1:"danger"
                })
            }
        })
    }
    onClickApprovePPCAll(){
        let records = [];
        this.gridApi1.forEachNode(node => records.push(node.data));
        console.log("records ", records)
        var parameters = {
            rowitems:records,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username")
        }
        redirectURL.post("/consignments/approveallppcvesselitems",parameters)
        .then((response) => {
            //console.log("ApproveAll res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Approved Successfully",
                    basicType1:"success"
                })
                if(this.state.ismgnt == 1)
                {
                    this.onLoadShowManagementData()
                }
                else
                {
                    this.onLoadShowData()
                }
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Something went wrong",
                    basicType1:"danger"
                })
            }
        })
    }
    onClickUpdateItem(params){
        //console.log("update ", params.data)
        var parameters = {
            rowitem:params.data,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username")
        }
        redirectURL.post("/consignments/updatemsilvesselitem",parameters)
        .then((response) => {
        //    console.log("Update res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Successfully update item",
                    basicType1:"success"
                })
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Something went wrong",
                    basicType1:"danger"
                })
            }
        })
    }

    onClickApproveItem(params){
        //console.log("Approve ", params)
        var parameters = {
            rowitem:params.data
        }
        redirectURL.post("/consignments/approvemsilvesselitem",parameters)
        .then((response) => {
            //console.log("Approve res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Successfully approved item",
                    basicType1:"success"
                })
                this.onLoadShowData()
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Something went wrong",
                    basicType1:"danger"
                })
            }
        })
    }
	
    onClickLoadDept(params){
        if(params == 'dashboard')
        {
            this.setState({
                showdashboard:'show-m',
                loaddashboard:'show-n'
            })
        }
        if(params == 'load')
        {
            this.setState({
                showdashboard:'show-n',
                loaddashboard:'show-m'
            })
        } 
    }

    onBtnExportDataAsExcel = () => {
        var api = this.gridApi,
          params = getParams();
        // if (validateSelection(params, api)) {
        //   return;
        // }
        api.exportDataAsExcel(params);
    };

    onBtnExportDataAsPPCExcel = () => {
        var api = this.gridApi1,
          params = getParams();
        // if (validateSelection(params, api)) {
        //   return;
        // }
        api.exportDataAsExcel(params);
    };

    onBtnExportDataAsCsv = () => {
        var api = this.gridApi,
          params = getParams();
        // if (validateSelection(params, api)) {
        //   return;
        // }
        api.exportDataAsCsv(params);
    };
    onBtnExportDataAsPPCCsv = () => {
        var api = this.gridApi1,
          params = getParams();
        // if (validateSelection(params, api)) {
        //   return;
        // }
        api.exportDataAsCsv(params);
    };
    onClickAddRow(){
        console.log("check")
        const rows = {
            "loading_vessel_name" : "",
            "loading_schedule_etd_smz" : "",
            "loading_schedule_eta_sin" : "",
            "loading_revised_eta_smz" : "",
            "loading_revised_eta_sin" : "",
            "transshipment_vessel" : "",
            "transshipment_schedule_eta_sin" : "",
            "transshipment_schedule_etd_sin" : "",
            "transshipment_revised_eta_sin" : "",
            "transshipment_revised_etd_sin" : "",
            "scheduled_pkg_eta" : "",
            "revised_pkg_etd" : "",
            "schedule_eta_ppv_ppc_schedule" : "",
            "schedule_eta_ppv" : "",
            "revised_eta_ppv" : "",
            "delay_from_schedule" : "",
            "transit_time_days" : "",
            "delay_from_ppc_schedule" : "",
            "remarks" : "",
        }
        console.log(rows)
        
        this.gridApi.insertItemsAtIndex(0,[rows])
    }
    onClickAddPPCRow()
    {
        // console.log(this.gridApi);
        this.gridApi1.insertItemsAtIndex(0,[{loading_vessel_name:"",order_no:"",ppc_plan:"",revised_atd_smz:"",revised_eta_sin:"",transshipment_vessel:"",revised_etd_sin:"",revised_ppv_nsa:"",current_eta_msil:"",days:""}])
        // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    }
    onClickShowBulkUpload()
    {
        this.setState({
            sliderbulkupload : "slider-translate-40p",
            csvcontent: [],
            overly : "show-m"
        })
    }
    onClickShowBulkPPCUpload()
    {
        this.setState({
            sliderbulkppcupload : "slider-translate-40p",
            csvppccontent: [],
            overly : "show-m"
        })
    }
    onCellClicked(cell)
    {
        if(cell.colDef.field == "delete")
        {
            redirectURL.post("/consignments/deleteMsilScheduleRow", {"_id": cell.data._id}).then(()=>{
                this.setState({
                    loadshow:"show-m",
                    overly:"show-m"
                })
                if(this.state.ismgnt == 1)
                {
                    this.onLoadShowManagementData()
                }
                else
                {
                    this.onLoadShowData()
                }
            })
        }
    }
    onPPCCellClicked(params)
    {
        if(params.colDef.field == "delete")
        {
            redirectURL.post("/consignments/deletePPCScheduleRow", {"_id": params.data._id}).then(()=>{
                this.setState({
                    loadshow:"show-m",
                    overly:"show-m"
                })
                if(this.state.ismgnt == 1)
                {
                    this.onLoadShowManagementData()
                }
                else
                {
                    this.onLoadShowData()
                }
            })
        }
    }
    onClickTab(pageType){
        if(pageType == "vesselData")
        {
            var vessel = "activet";
            var ppc = "";
            var vesselData = "";
            var ppcData = "show-n";
            if(this.state.ismgnt == 1)
            {
                var pagetitle = "MSIL Vessel Schedule";
                this.onLoadShowManagementData()
            }
            else
            {
                var pagetitle = "Manage MSIL Vessel Schedule";
                this.onLoadShowData()
            }
        }
        if(pageType == "ppcData")
        {
            var vessel = "";
            var ppc = "activet";
            var vesselData = "show-n";
            var ppcData = "";
            if(this.state.ismgnt == 1)
            {
                var pagetitle = "PPC Schedule";
                this.onLoadShowManagementData()
            }
            else
            {
                var pagetitle = "Manage PPC Schedule";
                this.onLoadShowData()
            }
        }
        this.setState({
            pagetitle: pagetitle,
            vessel: vessel,
            ppc: ppc,
            vesselData: vesselData,
            ppcData: ppcData,
        })
    }
    updateLmStates(params){
        //console.log("update ", params.data)
        var parameters = {
            rowitem:params,
            userid:localStorage.getItem("userid"),
            email:localStorage.getItem("email"),
            username:localStorage.getItem("username")
        }
        redirectURL.post("/consignments/updateppcschedule",parameters)
        .then((response) => {
        //    console.log("Update res ", response.data)
            if(response.data.message == "Success")
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Successfully update item",
                    basicType1:"success"
                })
            }
            else
            {
                this.setState({
                    show1:true,
                    basicTitle1:"Something went wrong",
                    basicType1:"danger"
                })
            }
        })
    }
	render(){
        console.log("this.state.ismgnt ",this.state.ismgnt)
        var hideicons = "";
        if(this.props.match.path == "/msilvesselstatus" || this.props.match.path == "/managemsilvesselstatus")
        {
            hideicons = "show-n"
        }
        if(this.state.ismgnt == 0)
        {
            var isedit=true;
            var hidemgnt = false;
            var mgntstyle = "";
            var showmgnt = true;
        }
        if(this.state.ismgnt == 1)
        {
            var isedit=false;
            var hidemgnt = true;
            var mgntstyle = " tab- pt-20px whiteRow ml-18p"
            var showmgnt = false;
        }
        if(this.state.rowData.length > 0)
        {
            var lastapprove = getHyphenDDMMMYYYYHHMM(this.state.rowData[0].approval_done_at);
        }
        else{
            var lastapprove = "";
		}
        if(this.state.ppcRowData.length > 0)
        {
            var lastppcapprove = getHyphenDDMMMYYYYHHMM(this.state.ppcRowData[0].approval_done_at);
        }
        else{
            var lastppcapprove = "";
		}
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                width: 80,
                pinned:'left',
                filter: true,
                hide:hidemgnt,
                resizable: true,
                editable:false,
                cellRendererSelector:function(params){
                    return {component:"Updatemsilvesselreport"}
                }                
            },
            {
                headerName: "",
                field: "delete",
                width: 80,
                pinned:'left',
                filter: true,
                hide:hidemgnt,
                resizable: true,
                editable:false,
                cellRenderer: function(params){
                    return  "<button class='btn btn-danger' style='padding: 0px 6px 0px 6px'>Delete</button>"
                }                
            },		
            {
                headerName: "Loading Vessel",
                headerClass:["cellstylegridG"],
                cellClass:["cellstylegridG"],
                field: "loading_vessel_name",
                width: 180,
                filter: true,
                resizable: true,
                editable:isedit,

            },
            {
                headerName: "Schedule",
                //headerClass:["cellstylegridG"],
                children:[
                    {
                        headerName:"ETD SMZ",
                        headerClass:["cellstylegridG"],
                        field:"loading_schedule_etd_smz",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    },
                    {
                        headerName:"ETA SIN",
                        headerClass:["cellstylegridG"],
                        field:"loading_schedule_eta_sin",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    },
                    
                ]        
            },
            {
                headerName: "Revised",
                //headerClass:["cellstylegridG"],
                children:[
                    {
                        headerName:"ETD SMZ",
                        headerClass:["cellstylegridG"],
                        field:"loading_revised_eta_smz",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    },
                    {
                        headerName:"ETA SIN",
                        headerClass:["cellstylegridG"],
                        field:"loading_revised_eta_sin",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    }
                    
                    
                ]        
            },
            {
                headerName: "Transshipment Vessel",
                headerClass:["cellstylegridMG"],
                cellClass:["cellstylegridMG"],
                field: "transshipment_vessel",
                width: 180,
                filter: true,
                resizable: true,
                editable:isedit              
            },
            
            {
                headerName: "Schedule",
                //headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"ETA SIN",
                        headerClass:["cellstylegridMG"],
                        field:"transshipment_schedule_eta_sin",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit
                    },
                    {
                        headerName:"ETD SIN",
                        headerClass:["cellstylegridMG"],
                        field:"transshipment_schedule_etd_sin",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit
                    },
                    
                ]        
            },
            {
                headerName: "Revised",
                // headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"ETA SIN",
                        headerClass:["cellstylegridMG"],
                        field:"transshipment_revised_eta_sin",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit
                    },
                    {
                        headerName:"ETD SIN",
                        headerClass:["cellstylegridMG"],
                        field:"transshipment_revised_etd_sin",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit
                    },
                    
                ]        
            },
            {
                headerName: "Schedule-PKG",
               // headerClass:["cellstylegridG"],
                children:[
                    {
                        headerName:"ETA",
                        headerClass:["cellstylegridG"],
                        field:"scheduled_pkg_eta",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    },
                    {
                        headerName:"ETD",
                        headerClass:["cellstylegridG"],
                        field:"revised_pkg_etd",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    }
                ]        
            },
            {
                headerName: "Schedule",
                //headerClass:["cellstylegridLG"],
                children:[
                    {
                        headerName:"ETA PPV",
                        headerClass:["cellstylegridLG"],
                        field:"schedule_eta_ppv",
                        width:100,
                        cellClass:["cellstylegridLG"],
                        editable:isedit
                    }
                ]        
            },
            
            {
                headerName: "ETA PPV (PPC Schedule)",
                headerClass:["cellstylegridLG"],
                cellClass:["cellstylegridLG"],
                field: "schedule_eta_ppv_ppc_schedule",
                width: 140,
                filter: true,
                resizable: true,
                editable:isedit                
            },
            {
                headerName: "Revised",
               // headerClass:["cellstylegridLG"],
                children:[
                    {
                        headerName:"ETA PPV",
                        headerClass:["cellstylegridLG"],
                        field:"revised_eta_ppv",
                        width:100,
                        cellClass:["cellstylegridLG"],
                        editable:isedit
                    }
                ]        
            },
            {
                headerName: "Delay From Schedule (Days)",
                headerClass:["cellstylegridBLG"],
                cellClass:["cellstylegridBLG"],
                field: "delay_from_schedule",
                width: 140,
                filter: true,
                resizable: true,
                editable:isedit               
            },
            {
                headerName: "Transit Time (Days)",
                headerClass:["cellstylegridCLG"],
                cellClass:["cellstylegridCLG"],
                field: "transit_time_days",
                width: 140,
                filter: true,
                resizable: true,
                editable:isedit               
            },
            {
                headerName: "Delay From PPC Schedule (Days)",
                headerClass:["cellstylegridGLG"],
                field: "delay_from_ppc_schedule",
                width: 140,
                filter: true,
                resizable: true,
                editable:isedit,
                cellClass:function(params){
                    try{
                        if(params.data.delay_from_ppc_schedule != "" && params.data.delay_from_ppc_schedule != undefined)
                        {
                            if(parseInt(params.data.delay_from_ppc_schedule) <= 7 )
                            {
                                return " yellow";    
                            }
                            if(parseInt(params.data.delay_from_ppc_schedule) > 7 && parseInt(params.data.delay_from_ppc_schedule) <= 15 )
                            {
                                return " cellstylegridORG";    
                            }
                            if(parseInt(params.data.delay_from_ppc_schedule) > 15 )
                            {
                                return " red";    
                            }
                        }
                        else
                        {
                            return "cellstylegridGLG";    
                        }
                    }
                    catch(e){
                        return "cellstylegridGLG";
                    }
                }         
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width: 200,
                filter: false,
                resizable: true,
                editable:isedit  
            }
            // ,
            // {
            //     headerName: "",
            //     field: "_id",
            //     width: 100,
            //     filter: false,
            //     resizable: true,
            //     hide:hidemgnt,
            //     cellRendererSelector:function(params){
            //         return {component:"ApproveMSILItem"}
            //     }                
            // }

        ];

        const columnwithDefsForPPC = [
            {
                headerName: "",
                field: "",
                width: 120,
                pinned:'left',
                filter: true,
                hide:hidemgnt,
                resizable: true,
                editable:false,
                cellRendererSelector:function(params){
                    return {component:"UpdateButton"}
                }                
            },
            {
                headerName: "",
                field: "delete",
                width: 120,
                pinned:'left',
                filter: true,
                hide:hidemgnt,
                resizable: true,
                editable:false,
                cellRenderer: function(params){
                    return  "<button class='btn btn-danger' style='padding: 0px 0px 9px 0px;width:92px'>Delete</button>"
                }                
            },
            {
                headerName: "S.No",
                field: "order_by",
                width: 100,
                filter: true,
                resizable: true,
                pinned:'left',
            },				
            {
                headerName: "Loading Vessel Name",
                headerClass:["cellstylegridG"],
                cellClass:["cellstylegridG"],
                field: "loading_vessel_name",
                width: 180,
                filter: true,
                resizable: true,
                editable:isedit,

            },
            {
                headerName: "PPC",
                //headerClass:["cellstylegridG"],
                children:[
                    {
                        headerName:"Order No",
                        headerClass:["cellstylegridG"],
                        field:"order_no",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit
                    },
                    {
                        headerName:"PPC Plan",
                        headerClass:["cellstylegridG"],
                        field:"ppc_plan",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.ppc_plan != null)
                            {
                                if(moment(params.data.ppc_plan,'DD-MM-YYYY hh:mm',true).isValid() == false)
                                {
                                    return moment.parseZone(params.data.ppc_plan).format('DD-MMM')
                                }
                                else{
                                    return "";
                                }
                                
                            }
                            else
                            {
                                return ""
                            }
                        }
                    },
                    
                ]        
            },
            {
                headerName: "Revised",
                //headerClass:["cellstylegridG"],
                children:[
                    {
                        headerName:"ATD/ETD SMZ",
                        headerClass:["cellstylegridG"],
                        field:"revised_atd_smz",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.revised_atd_smz != null)
                            {
                                if(moment(params.data.revised_atd_smz,'DD-MM-YYYY hh:mm',true).isValid() == false)
                                {
                                    return moment.parseZone(params.data.revised_atd_smz).format('DD-MMM')
                                }
                                else{
                                    return "";
                                }
                                
                            }
                            else
                            {
                                return ""
                            }
                        }
                    },
                    {
                        headerName:"ETA SIN",
                        headerClass:["cellstylegridG"],
                        field:"revised_eta_sin",
                        width:100,
                        cellClass:["cellstylegridG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.revised_eta_sin != null)
                            {
                                if(moment(params.data.revised_eta_sin,'DD-MM-YYYY hh:mm',true).isValid() == false)
                                {
                                    return moment.parseZone(params.data.revised_eta_sin).format('DD-MMM')
                                }
                                else{
                                    return "";
                                }
                                
                            }
                            else
                            {
                                return ""
                            }
                        }
                    }
                    
                    
                ]        
            },
            {
                headerName: "Transshipment Vessel",
                headerClass:["cellstylegridMG"],
                cellClass:["cellstylegridMG"],
                field: "transshipment_vessel",
                width: 180,
                filter: true,
                resizable: true,
                editable:isedit              
            },
            
            {
                headerName: "Revised",
                //headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"ETD SIN",
                        headerClass:["cellstylegridMG"],
                        field:"revised_etd_sin",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.revised_etd_sin != null)
                            {
                                if(moment(params.data.revised_etd_sin,'DD-MM-YYYY hh:mm',true).isValid() == false)
                                {
                                    return moment.parseZone(params.data.revised_etd_sin).format('DD-MMM')
                                }
                                else{
                                    return "";
                                }
                                
                            }
                            else
                            {
                                return ""
                            }
                        }
                    },
                    
                ]        
            },
            {
                headerName: "Revised",
                // headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"ETA PPV/NSA",
                        headerClass:["cellstylegridMG"],
                        field:"revised_ppv_nsa",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.revised_ppv_nsa != null)
                            {
                                if(moment(params.data.revised_ppv_nsa,'DD-MM-YYYY hh:mm',true).isValid() == false)
                                {
                                    return moment.parseZone(params.data.revised_ppv_nsa).format('DD-MMM')
                                }
                                else{
                                    return "";
                                }
                                
                            }
                            else
                            {
                                return ""
                            }
                        }
                    },
                    
                ]        
            },
            {
                headerName: "Current",
                // headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"ETA MSIL",
                        headerClass:["cellstylegridMG"],
                        field:"current_eta_msil",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit,
                        cellEditor: DateEditor,
                        valueGetter:function(params){
                            //console.log("params ", params);
                            if (params.data.current_eta_msil != null && params.data.current_eta_msil !=undefined)
                            {
                                return moment.parseZone(params.data.current_eta_msil).format('DD-MMM')                               
                            }
                            else
                            {
                                return ""
                            }
                        }
                    },
                    
                ]        
            },
            {
                headerName: "Transit Time From PPC Plan To MSIL",
                // headerClass:["cellstylegridMG"],
                children:[
                    {
                        headerName:"Days",
                        headerClass:["cellstylegridMG"],
                        field:"days",
                        width:100,
                        cellClass:["cellstylegridMG"],
                        editable:isedit
                    },
                    
                ]        
            },
        ];
        
		return(
			<div className={(hideicons == "")?"container-fluid": ""} style={(hideicons!="")?{marginTop:"2em"}:{}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
			<SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert1}
				
				 <div className={(hideicons == "")?"col-xl-12 col-lg-12":""}>
                     {(this.state.ismgnt == 1)?
                     
                        <div className={"row col-xl-12 col-lg-12 "+hideicons}>
                        
                            <ul className="dashboard-page">
                                <li className="active">
                                        <a href={"/dashboardsummary"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                            <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                        </a>
                                    
                                    </li>
                                    <li className="btn-group">
                                        <a href="javascript:;"  className="loadclickm" id="load">
                                        <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />
                                        
                                        <ul className="dashboard-sub" style={{"display":"none"}}>
                                        
                                                <li>
                                                    <a href="/dailyrunningmanagement">
                                                        Daily Running
                                                    </a>
                                                </li>
                                                
                                                <li>
                                                    <a href="/loadingdashboard">
                                                        Loading Dashboard
                                                    </a>
                                                </li>


                                                <li>
                                                    <a href="/livetrucksinfo">
                                                    Truck Location (Map)
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="/msilvesselschedule">
                                                    MSIL Vessel Schedule
                                                    </a>
                                                </li>

                                            </ul>
                                        </a>
                                </li>
                            </ul>	
                        </div>
                    :""}
                    <div className={(hideicons == "")?"col-xl-12 col-lg-12 "+(mgntstyle):""}>    
                        <div className="col-xl-12 col-lg-12">
                            {/* <div className="f16" style={{marginBottom:"10px"}}>
                                <ul className="d-tabs">

                                    <li onClick={this.onClickTab.bind(this,"vesselData")} className={"lttabs "+(this.state.vessel)}>
                                        <button type="button" className="btn" onClick={this.onClickTab.bind(this,"vesselData")}>MSIL Vessel Schedule</button>
                                    </li>

                                    <li onClick={this.onClickTab.bind(this,"ppcData")} className={"lttabs "+(this.state.ppc)}>
                                        <button type="button" className="btn" onClick={this.onClickTab.bind(this,"ppcData")}>PPC Schedule</button>
                                    </li>
                                </ul>
                            </div> */}
                            <div className={"card "+this.state.vesselData}>
                                <div className="card-header">
                                    {(this.state.ismgnt == 1)?
                                        <a className={(hideicons == "")?"btn btn-danger float-right f12": hideicons} href="/managementdashboard">Back</a>
                                    :""}
                                    <button className={(hideicons == "")?"btn btn-info float-right f12": hideicons} onClick={() => this.onBtnExportDataAsCsv()}>
                                        Export CSV
                                    </button>
                                    <button  className={(hideicons == "")?"btn btn-warning float-right f12": hideicons} onClick={() => this.onBtnExportDataAsExcel()}>
                                        Export Excel
                                    </button>
                                    {(this.state.ismgnt != 1)?
                                    <span>
                                        <button className="btn btn-info float-right f12" onClick={this.onClickShowBulkUpload.bind(this)}>Bulk Upload</button>
                                        <button  className={(hideicons == "")?"btn btn-success float-right f12": hideicons} onClick={() => this.onClickAddRow()}>
                                            Add Row
                                        </button>
                                    </span>
                                    :""}
                                    <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                    
                                   
                                    </h5>
                                </div>
                                                            
                                <div className="card-body pt-15px" >
                                     {/* {(this.state.ismgnt == 1)? */}
                                        <span className="f12" style={{float:"right",textAlign:"right", fontWeight:"normal"}}>Last approved by LOG-SC dept on : {lastapprove}</span>
                                    {/* :""} */}
                                    <div className="row col-xl-12 col-lg-12" style={{padding:"0px"}}>
                                   
                                        <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                //floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                // suppressCellFlash={true}
                                                rowClassRules={this.state.rowClassRules}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                masterDetail={true}
                                                                
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            />

                                        </div>
                                    </div>
                                    {(this.state.ismgnt == 0)?
                                    <button type="button" onClick={this.onClickApproveAll.bind(this)} className="btn btn-success">Approve All</button>
                                    :""}

                                    <div>
                                        Delay From PPC Schedule (Days): 
                                        <span style={{backgroundColor:"#f1ffb4", padding:"0px 10px", width:"32px", height:"32px"}}>&nbsp;</span> 
                                        <span>&lt; 7 Days&nbsp;&nbsp;</span>
                                        <span style={{backgroundColor:"#ffb657", padding:"0px 10px", width:"32px", height:"32px"}}>&nbsp;</span>
                                        <span>7 - 15 Days&nbsp;&nbsp;</span>
                                        <span style={{backgroundColor:"#ff0000", padding:"0px 10px", width:"32px", height:"32px"}}>&nbsp;</span>
                                        <span>&gt; 15 Days&nbsp;&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"card "+this.state.ppcData}>
                                <div className="card-header">
                                    {(this.state.ismgnt == 1)?
                                        <a className={(hideicons == "")?"btn btn-danger float-right f12": hideicons} href="/managementdashboard">Back</a>
                                    :""}
                                    <button className={(hideicons == "")?"btn btn-info float-right f12": hideicons} style={{marginRight:"10px"}} onClick={() => this.onBtnExportDataAsPPCCsv()}>
                                        Export CSV
                                    </button>
                                    <button  className={(hideicons == "")?"btn btn-warning float-right f12": hideicons} style={{marginRight:"10px"}} onClick={() => this.onBtnExportDataAsPPCExcel()}>
                                        Export Excel
                                    </button>
                                    {(this.state.ismgnt != 1)?
                                    <span style={{marginTop:"0px"}}>
                                        <button className="btn btn-info float-right f12" style={{marginRight:"10px"}} onClick={this.onClickShowBulkPPCUpload.bind(this)}>Bulk Upload</button>

                                        <button  className={(hideicons == "")?"btn btn-success float-right f12": hideicons} style={{marginRight:"10px"}} onClick={() => this.onClickAddPPCRow()}>
                                            Add Row
                                        </button>
                                    </span>
                                    :""}
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    
                                   
                                    </h5>
                                </div>
                                                            
                                <div className="card-body pt-15px" >
                                     {/* {(this.state.ismgnt == 1)? */}
                                        <span className="f12" style={{float:"right",textAlign:"right", fontWeight:"normal"}}>Last approved by LOG-SC dept on : {lastppcapprove}</span>
                                    {/* :""} */}
                                    <div className="row col-xl-12 col-lg-12" style={{padding:"0px"}}>
                                   
                                        <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefsForPPC}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.ppcRowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onAgGridReady}
                                                onGridState={this.onAgGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                frameworkComponents={this.state.frameworkComponents}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                //floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                // suppressCellFlash={true}
                                                rowClassRules={this.state.rowClassRules}
                                                onCellClicked={this.onPPCCellClicked.bind(this)}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                masterDetail={true}
                                                                
                                                rowSelection={this.state.rowSelection}
                                                suppressRowClickSelection={true}
                                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            />

                                        </div>
                                    </div>
                                    {(this.state.ismgnt == 0)?
                                    <button type="button" onClick={this.onClickApprovePPCAll.bind(this)} className="btn btn-success">Approve All</button>
                                    :""}
                                </div>
                            </div>	
                        </div>
					</div>
				 </div>
                 <div className={"slide-r "+(this.state.sliderbulkupload)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Upload Batch File</h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <form method="POST" id="upform" style={{marginBottom: "4em"}} className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>
                                <div className="form-group mt-20p">
                                    <label className="">Upload File *</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                
                                <button type="submit" className="btn btn-info">Submit</button>
                            </form>
                            {/* <div className="form-group">
                                <p style={{color:"#ff0000"}}>* Note : All fields related to the form are mandatory and cannot be empty</p>
                            </div> */}
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_msil_vessel_sample.csv')} target="_blank">Sample Template</a>
                            </div>
                            <div className="form-group">
                                {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                            
                            </div>		
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.sliderbulkppcupload)} style={{overflow:"hidden"}}>
                    <h3 className="subH">Upload PPC Schedule File</h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            <form method="POST" id="upform" style={{marginBottom: "4em"}} className="theme-form" onSubmit={this.uploadBulkForcePPCFormHandler.bind(this)}>
                                <div className="form-group mt-20p">
                                    <label className="">Upload File *</label> 
                                    <input type="file" name="uploadFile" id="ppcbulkUploadID" onChange={this.changePPCFileHandler}  className="form-control" required  />
                                </div>
                                
                                <button type="submit" className="btn btn-info">Submit</button>
                            </form>
                            {/* <div className="form-group">
                                <p style={{color:"#ff0000"}}>* Note : All fields related to the form are mandatory and cannot be empty</p>
                            </div> */}
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/bulk_ppc_sample.csv')} target="_blank">Sample Template</a>
                            </div>
                            <div className="form-group">
                                {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                            
                            </div>		
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
			</div>
              	
		);
	}
}


  function getParams() {
    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    
    };
  }

  function makeCustomContent() {
    return [
      [],
      [
        {
          data: {
            type: 'String',
            value: 'Summary',
          },
        },
      ],
      
      [],
    ];
  }

  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "dd-mm",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};