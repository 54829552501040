
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
const ViewPrimaryStockDownloadPayments = (props) => {
    //console.log("props ", props)
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
       // props.context.componentParent.onShowRakeLegs(props);
    };
    var donwloads = []
    try{
        //console.log("props.data.prim_uploadfiles ", props.data.prim_uploadfiles)
        if(props.data.prim_stock_uploadfiles != undefined)
        {
            if(props.data.prim_stock_uploadfiles.length > 0)
            {
                props.data.prim_stock_uploadfiles.map((item)=> {
                    donwloads.push(item)
                })
            }
        }
        else{
            donwloads=[];
        }
    
    }
    catch(e){
        donwloads=[];
    }
    
    const download = (filepath, filename) => {
        var element = document.createElement("a");
        var file = new Blob(
          [
            filepath
          ],
          
        );
        element.href = URL.createObjectURL(filepath);
        element.download = filename;
        element.click();
      };

    //console.log(" donwloads ", donwloads)
    return (
        <div>
           {(donwloads.length > 0)?
            (donwloads.map(file =>
                <a
                    href={require("../../assets/video/"+(file.name))}
                    target="_blank"  
                    //onClick={e => download(file.path, file.name)} 
                >{file.name} &nbsp;
                </a>
            ))
            :""}
            
           
        </div>
    );
};

export default ViewPrimaryStockDownloadPayments;
