import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

export default class StatusUpdateAction extends Component {
	statusChange = (e, l) => {
		console.log(e.data);
		console.log(l)
		console.log(this.props.actionCall+" , "+this.props.actionMethod);
		var actionCall=this.props.actionCall;
		var actionMethod=this.props.actionMethod;
		var fdata = {
	    		rownode:e.data,
	    		changestatus:l
	    }
		
	    redirectURL.post("/"+actionCall+"/"+actionMethod, fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log(response.data);
				var gridColumnApi = e.columnApi;
				var gridCols = gridColumnApi.getAllGridColumns();
				//console.log("gridCols",gridCols);
				
				var allcols=[]
				gridCols.map(cl => {
					//console.log(cl.colId);
					allcols.push(cl.colId);
				});
				console.log("allcols",allcols);
				var api = e.gridApi;
				allcols.forEach(function(col, index) {
			      var millis = index * 100;
			      var params = {
			        force: true,
			        columns: [col]
			      };
			      callRefreshAfterMillis(params, millis, api);
			    });
				
				var api = e.gridApi;
				 var millis = 100;
			      var rowNodes = [e];
			     // console.log(rowNodes);
			      var params = {
			    	force: true,	  
			        rowNodes: rowNodes
			      };
			      callRefreshAfterMillis(params, millis, api);
			      var rowData = JSON.parse(JSON.stringify(e.data));
			      
			      let rowNode = e.gridApi.getRowNode(e.id);
			     // console.log("Here rowNode ", rowNode);
			      if(l == 0)
		    	  {
			    	  e.setData(e.data);
		    	  }
			      if(l == 1)
		    	  {
			    	  e.setData(e.data);
		    	  }
			      this.scrambleAndRefreshTopToBottom()
			       let itemsToUpdate = [];
			      const selected = e.data;
			       // selected._id_1 = value;
			        if(selected._id_1 == 1)
		        	{
			        	itemsToUpdate.push(<button onClick={() => this.statusChange(this.props.node,0)} className="custom-btn label label-success">
		            	<i className="icofont icofont-save"></i> Active 
    		            </button>);
		        	}
			        else
			        {
			        	itemsToUpdate.push(<button onClick={() => this.statusChange(this.props.node,1)} className="custom-btn label label-success">
		            	<i className="icofont icofont-save"></i> Inactive 
    		            </button>);
			        }
			        
			        api.updateRowData({update: itemsToUpdate});    
			}
		)
		.catch(function(error){
			console.log(error);
		});
		
		
	}
	
	scrambleAndRefreshTopToBottom() {
	    scramble();
	    var frame = 0;
	    var i;
	    var rowNode;
	    var api = this.props.node.gridApi;
	    for (i = 0; i < api.getPinnedTopRowCount(); i++) {
	      rowNode = api.getPinnedTopRow(i);
	      refreshRow(rowNode, api);
	    }
	    for (i = 0; i < this.props.node.gridApi.getDisplayedRowCount(); i++) {
	      rowNode = this.props.node.gridApi.getDisplayedRowAtIndex(i);
	      refreshRow(rowNode, api);
	    }
	    for (i = 0; i < this.props.node.gridApi.getPinnedBottomRowCount(); i++) {
	      rowNode = this.props.node.gridApi.getPinnedBottomRow(i);
	      refreshRow(rowNode, api);
	    }
	    function refreshRow(rowNode, api) {
	        var millis = frame++ * 100;
	        var rowNodes = [rowNode];
	        var params = {
	          force: true,
	          rowNodes: rowNodes
	        };
	        callRefreshAfterMillis(params, millis, api);
	      }
    }
	    
    render() {
        return (
        	<span>
        		{
        			(this.props.node.data.is_active == 1) ?

        		            <button onClick={() => this.statusChange(this.props.node,0)} className="custom-btn label label-success">
        		            	<i className="icofont icofont-save"></i> Active 
        		            </button>
        		            	:

		    	            <button onClick={() => this.statusChange(this.props.node,1)} className="custom-btn label label-success">
		    	            	<i className="icofont icofont-save"></i> Inactive 
		    	            </button> 		
        		}
            </span>
        );
    }
}

function isForceRefreshSelected() {
	  return true;
	}
function callRefreshAfterMillis(params, millis, gridApi) {
	  setTimeout(function() {
	    gridApi.refreshCells(params);
	  }, millis);
	}
var data=[];
var topRowData = [];
var bottomRowData = [];
function scramble() {
	  data.forEach(scrambleItem);
	  topRowData.forEach(scrambleItem);
	  bottomRowData.forEach(scrambleItem);
	}
	function scrambleItem(item) {
		var gridColumnApi = this.props.node.columnApi;
		var gridCols = gridColumnApi.getAllGridColumns();
		//console.log("gridCols",gridCols);
		
		var allcols=[]
		gridCols.map(cl => {
			//console.log(cl.colId);
			allcols.push(cl.colId);
		});
		
		allcols.forEach(function(colId) {
	    if (Math.random() > 0.5) {
	      return;
	    }
	    item[colId] = Math.floor(Math.random() * 100);
	  });
	}
