import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';

class BoxRowOne extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		truckscnt:0,
    		trucklocations:0,
    		notrans:0
    	}
    
    }
    componentDidMount(){
    	//console.log("Box ",localStorage.getItem('token'));
    	//console.log(this.props);
    		const layoutdata={
    				token:localStorage.getItem('token'),
			}
    		//console.log("Trucks", this.props.tottrucks);
    	//	//console.log("trucklocations", this.props.truckgps);
    		//console.log("notrans", this.props.notruckgps);
		 this.setState({
  			   truckscnt:this.props.tottrucks,
  			   trucklocations:this.props.truckgps,
  			   notrans:this.props.notruckgps,
  			   
  		   });
    		
		 /* axios.post('http://autometrics.in/overview/trucks', layoutdata)
		  .then((response) => {
			 
		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
		   //console.log(trucks);
		   this.setState({
			   truckscnt:trucks
		   });
		   
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
    	  
    		
    		axios.post('http://autometrics.in/overview/trucklocations', layoutdata)
  		  .then((response) => {
  			 
  		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
  		   //console.log(trucks);
  		   this.setState({
  			 trucklocations:trucks
  		   });
  		   
  		    
  		  })
  		  .catch(function (error) {
  		    console.log(error);
  		  });
    	

    		
    		axios.post('http://autometrics.in/overview/notransporters', layoutdata)
  		  .then((response) => {
  			 
  		   var trucks = JSON.parse(JSON.stringify(response)).data.count;
  		   //console.log(trucks);
  		   this.setState({
  			 notrans:trucks
  		   });
  		   
  		    
  		  })
  		  .catch(function (error) {
  		    console.log(error);
  		  });	
    		*/
    }
    render(){
        return (
            <div className="row">
                <div className="col-xl-3 col-lg-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                			<div className="brd brd-txt1"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-primary" end={this.props.tottrucks} /></h4>
                                        
                                    </div>
                                    <img className="mr-3" src={require('../../../assets/images/dashboard-icons/document.png')} alt="Generic placeholder image" />
                                	   
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Total Trucks</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
	                        <div className="brd brd-txt2"></div>
	                        <div className="stat-widget-dashboard">
                                <div className="media">
                                	<div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-secondary" end={this.props.truckgps} /></h4>
                               
                                    </div>
                                    <img className="mr-3" src={require('../../../assets/images/dashboard-icons/operator.png')} alt="Generic placeholder image" />
                                        
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with GPS Data</div>
                            </div>
                        </div>
                    </div>
                </div>
	            {/* <div className="col-xl-3 col-lg-6 col-sm-6">
	                <div className="card">
	                    <div className="card-body">
	                    	<div className="brd brd-txt4"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt3 counter font-success"><CountUp className="font-success" end={this.props.trucktoday} /></h4>
	                                    
	                                </div>
	                                <img className="mr-3" src={require('../../../assets/images/dashboard-icons/chat.png')} alt="Generic placeholder image"/>
	
	                            </div>
	
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with GPS Data (Today)</div>
	                        </div>
	                    </div>
	                </div>
	            </div> */}
	            
                <div className="col-xl-3 col-lg-6 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                        	<div className="brd brd-txt4"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-info" end={this.props.tottrucks-this.props.truckgps} /></h4>
                            
                                    </div>
                                    <img className="mr-3" src={require('../../../assets/images/dashboard-icons/like.png')} alt="Generic placeholder image"/>
  
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">GPS Data Not Reported Today</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-sm-6">
	                <div className="card">
	                    <div className="card-body">
	                    	<div className="brd brd-txt3"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt3 counter font-success"><CountUp className="font-success" end={this.props.notruckgps} /></h4>
	                                    
	                                </div>
	                                <img className="mr-3" src={require('../../../assets/images/dashboard-icons/chat.png')} alt="Generic placeholder image"/>
	
	                            </div>
	
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with No Transporters</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                
            </div>
        );
    }
}

export default BoxRowOne