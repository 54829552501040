import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import ShowTruckConsignments from "./showtruckconsignments.js"
import RevokSaveBilling from "./checkboxtruckconsignments.js"
import Modal from 'react-responsive-modal';
import BillingLogs from './billingsclogsComponent';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class BillingSC extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                showtruckconsignments:ShowTruckConsignments,
                RevokSaveBilling:RevokSaveBilling
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            billingtrucks:[],
            pagerest:0,
            approveby:"",
            approvedon:"",
            screen:'',
            hidebuttons:0,
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false,
            reason:'',
            approveenreason:'',
            revertreason:'',
            approveitemreason:'',
            disapprovereason:'',
            dispproveitem:"",
            approveitem:"",
            billingRowData:"",
            acbtntrucks:"btn-danger",
            acbtntrips:"btn-default",
            columnstrucks:1,
            columnstrips:0,
            truckslist:[]
        }
        this.onLoadBillingData = this.onLoadBillingData.bind(this);
        this.onClickShowTruckLegs = this.onClickShowTruckLegs.bind(this);
        this.onClickCheckboxItem = this.onClickCheckboxItem.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.resetPaginationSelection = this.resetPaginationSelection.bind(this);
        this.revertSavedItem = this.revertSavedItem.bind(this);
        this.approveSavedItem = this.approveSavedItem.bind(this);
        this.onClickShowGridData = this.onClickShowGridData.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var mm = moment.parseZone().subtract(1, 'months').format("MM")
        var mmm = moment.parseZone().subtract(1, 'months').format("MMM")
        var month = [{value:mm,label:mmm}]
        var yy = moment.parseZone().subtract(1, 'months').format("YYYY")
        var year = [{value:yy, label:yy}]
        
        // months start at index 0 in momentjs, so we subtract 1
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");

        if(this.props.match.path == "/scimportsbilling")
		{
            var deptcode='LOG-SC';
            var pageTitle="Supply Chain - Imports";
            var screen = "imports";
        }
        if(this.props.match.path == "/scexportsbilling")
		{
            var deptcode='LOG-SC';
            var pageTitle="Supply Chain - Exports";
            var screen = "exports";
        }
        if(this.props.match.path == "/sccoilsbilling")
		{
            var deptcode='LOG-SC';
            var pageTitle="Supply Chain - Coils";
            var screen = "coils";
        }
       
        this.setState({
            dept_code:deptcode,
            pageTitle:pageTitle,
            month:month,
            year:year,
            startDate:startDate,
            endDate:endDate,
            screen:screen
        })
        var parameters = {
            dept_code:deptcode,
            startDate:startDate,
            endDate:endDate,
            screen:screen
        }
        var params = {
            dept_code:deptcode
        }
        // redirectURL.post("/consignments/billinglogs",params)
        // .then((response) => {
        //     this.setState({
        //         billingRowData:response.data
        //     })
        // })
        this.onLoadBillingData(parameters)
    }

    onLoadBillingData(parameters){
        this.setState({
            loadshow:'show-m',
            //overly:'show-m'
        })
        redirectURL.post("/consignments/billingsctrailers", parameters)
        .then((response) => {
            //console.log("response.data ", response.data)
            var approveby="";
            var approvedon="";
            var truckslist = [];
            if(response.data.length > 0)
            {
                response.data.map((item) => {
                    if(item.confirm_status != undefined)
                    {
                        if(item.confirm_status == 1)
                        {
                            approveby=item.approvedName;
                            approvedon=item.approved_on;   
                        }
                    }

                    truckslist.push(item.truck_no);
                })
            }
            this.setState({
                rowData:response.data,
                loadshow:'show-n',
                approvedon:approvedon,
                approveby:approveby,
                truckslist:truckslist
               //    overly:'show-n'
            })
        })
        .catch(function(e){

        })
    }
    onClickShowFilterData(){
        // console.log("this.state.month ", this.state.month)
        // console.log("this.state.year ", this.state.year)
        if(Array.isArray(this.state.year))
        {
            var yy = this.state.year[0].value;
        }
        else{
            var yy = this.state.year.value;
        }
        if(Array.isArray(this.state.month))
        {
            var mm = this.state.month[0].value;
        }
        else{
            var mm = this.state.month.value;
        }
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
        this.setState({
            startDate:startDate,
            endDate:endDate
        });
        var parameters = {
            dept_code:this.state.dept_code,
            startDate:startDate,
            endDate:endDate,
            screen:this.state.screen
        }
        this.onLoadBillingData(parameters)
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
    
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n'
        });
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    changeArrayHandler = month => {
        this.setState(
            { month }
        );
        
    }
    monthoptions(){
        var months = [
           // {value:'all',label:'All'},
            {value:'01',label:'Jan'},
            {value:'02',label:'Feb'},
            {value:'03',label:'Mar'},
            {value:'04',label:'Apr'},
            {value:'05',label:'May'},
            {value:'06',label:'Jun'},
            {value:'07',label:'Jul'},
            {value:'08',label:'Aug'},
            {value:'09',label:'Sep'},
            {value:'10',label:'Oct'},
            {value:'11',label:'Nov'},
            {value:'12',label:'Dec'}
        ]

        return months;
    }
    changeYearArrayHandler = year => {
        this.setState(
            { year }
        );
        
    }
    yearoptions(){
        var years = []
        var cyear = moment.parseZone().format("YYYY")
        var defaultyear = 2020
        //console.log("cyear ", cyear)
        for(var i=defaultyear;i<=cyear;i++)
        {
            //console.log(i)
            years.push({value:i,label:i})
        }    
       // console.log("years ", years)
        return years;
    }

    // saveSelectedTrucks(){
    //     var rowCount = this.gridApi.getSelectedNodes();
    //    // console.log("rowCount ", rowCount)
    //     var selecteditems = []
    //     if(rowCount.length > 0)
    //     {
    //         rowCount.map((item) => {
    //             selecteditems.push(item.data)
    //         })
    //     }
    //     if(selecteditems.length > 0)
    //     {   
    //         var params = {
    //             selecteditems:selecteditems,
    //             dept_code:this.state.dept_code,
    //             email:localStorage.getItem("email"),
    //             username:localStorage.getItem("username")
    //         }
    //         redirectURL.post("/consignments/saveBillingTrucks", params)
    //         .then(async (response) => {
    //             //console.log(response.data)
    //             if(response.data.status == "success")
    //             {
    //                 await this.onClickShowFilterData();
    //                 await this.setState({
    //                     show:true,
    //                     basicType:"success",
    //                     basicTitle:"Successfully approved"
    //                 })
    //             }
    //             else
    //             {
    //                 await this.onClickShowFilterData();
    //                 await this.setState({
    //                     show:true,
    //                     basicTitle:"Please choose atleast one item",
    //                     basicType:'info'
    //                 })
    //             }
    //         })
    //     }
    //     else
    //     {
    //         this.setState({
    //             show:true,
    //             basicTitle:"Please choose atleast one item",
    //             basicType:'info'
    //         })
    //     }
    // }

    // approveSelectedTrucks(){      

    //     var rowCount = this.gridApi.getSelectedNodes();
    //     //console.log("rowCount ", rowCount)
    //     var selecteditems = []
    //     if(rowCount.length > 0)
    //     {
    //         rowCount.map((item) => {
    //             selecteditems.push(item.data)
    //         })
    //     }
    //     if(selecteditems.length > 0)
    //     {   
    //         var cnf = window.confirm("Are you sure you want to approve ?")
    //        // console.log("cnf ", cnf)
    //         if(cnf == true)
    //         {
    //             var params = {
    //                 selecteditems:selecteditems,
    //                 dept_code:this.state.dept_code,
    //                 email:localStorage.getItem("email"),
    //                 username:localStorage.getItem("username")
    //             }
    //             redirectURL.post("/consignments/approveBillingTrucks", params)
    //             .then(async (response) => {
                    
    //                 if(response.data.status == "success")
    //                 {
    //                     await this.onClickShowFilterData();
    //                     await this.setState({
    //                         show:true,
    //                         basicType:"success",
    //                         basicTitle:"Successfully approved"
    //                     })
    //                 }
    //                 else
    //                 {
    //                     await this.onClickShowFilterData();
    //                     await this.setState({
    //                         show:true,
    //                         basicTitle:"Please choose atleast one item",
    //                         basicType:'info'
    //                     })
    //                 }
    //             })
    //         }
    //     }
    //     else
    //     {
    //         this.setState({
    //             show:true,
    //             basicTitle:"Please choose atleast one item",
    //             basicType:'info'
    //         })
    //     }
        
        
    // }

    approveAllTrucks(){
        this.setState({
            open:true
        })
    }
    approveAllTrucksAction(){
        var selecteditems = [];

        console.log("this.gridApi ", this.gridApi)
        this.gridApi.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        console.log("selecteditems ", selecteditems)
        var cnf = window.confirm("Are you sure you want to approve? Data cannot be modified once approved.")
        //console.log("cnf ", cnf)
        if(cnf == true)
        {
            if(Array.isArray(this.state.year))
            {
                var yy = this.state.year[0].value;
            }
            else{
                var yy = this.state.year.value;
            }
            if(Array.isArray(this.state.month))
            {
                var mm = this.state.month[0].value;
            }
            else{
                var mm = this.state.month.value;
            }
            var params = {
                selecteditems:selecteditems,
                screen:this.state.screen,
                month:mm,
                year:yy,
                reason:this.state.reason,
                dept_code:this.state.dept_code,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username")
            }
            redirectURL.post("/consignments/approveConfirmBillingTrucks", params)
            .then(async (response) => {
                
                if(response.data.status == "success")
                {
                    await  this.onClickShowFilterData();
                    await  this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully approved",
                        open:false,
                        reason:""
                    })
                }
                else
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicTitle:"Please choose atleast one item",
                        basicType:'info',
                        open:false,
                        reason:""
                    })
                    
                }
            })
        }
    }

    revertSavedItem(item)
    {
        this.setState({
            disapproveopen:true,
            dispproveitem:item
        })
    }
    revertSavedItemAction(){
        var item = this.state.dispproveitem
        var cnf = window.confirm("Are you sure you want to disapprove item ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            if(Array.isArray(this.state.year))
            {
                var yy = this.state.year[0].value;
            }
            else{
                var yy = this.state.year.value;
            }
            if(Array.isArray(this.state.month))
            {
                var mm = this.state.month[0].value;
            }
            else{
                var mm = this.state.month.value;
            }
            var params = {
                revertdata:item.data,
                screen:this.state.screen,
                month:mm,
                year:yy,
                reason:this.state.disapprovereason,
                dept_code:this.state.dept_code,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username")
            }
            redirectURL.post("/consignments/screvertSaveItem", params)
            .then(async (response) => {
                //console.log(response.data)
                if(response.data.status == "success")
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully revert back item",
                        disapprovereason:"",
                        disapproveopen:false
                    })
                   
                }
                else
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicTitle:"Please choose atleast one item",
                        basicType:'info',
                        disapprovereason:"",
                        disapproveopen:false
                    })
                }
            })
        }
    }
    onClickShowTruckLegs = async (props) => {
        //console.log("Props ",props)
        if(Array.isArray(this.state.year))
        {
            var yy = this.state.year[0].value;
        }
        else{
            var yy = this.state.year.value;
        }
        if(Array.isArray(this.state.month))
        {
            var mm = this.state.month[0].value;
        }
        else{
            var mm = this.state.month.value;
        }
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");


        if(this.state.screen == "imports")
        {
            var importslegscols = [
                { 
                    headerName: "Container No",
                    field: "container_no", 
                    width:150,
                    resizable: true,
                },
                { 
                    headerName: "BL No",
                    field: "bill_of_lading", 
                    width:150
                },
                
                { 
                    headerName: "Shipping Line",
                    field: "shipping_line", 
                    width:240
                },
                
                { 
                    headerName: "Vessel Arrived On",
                    field: "vessel_arrived_on", 
                    width:160,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.vessel_arrived_on);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "SMTP Handover Date",
                    field: "smtp_handover_date", 
                    width:160,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.smtp_handover_date);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "Departure GRFL Date",
                    field: "departure_to_grfl_date", 
                    width:150,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.departure_to_grfl_date);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "GRFL Arrival Date",
                    field: "grfl_arrival_date", 
                    width:150,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.grfl_arrival_date);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "Dispatch Date",
                    field: "dispatch_date", 
                    width:150,
                    filter:true,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.dispatch_date);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                }                                
            ]
        }
        else
        {
            var importslegscols = [
                { 
                    headerName: "Container No",
                    field: "container_no", 
                    width:150,
                    resizable: true,
                },
                
                
                { 
                    headerName: "Shipping Line",
                    field: "shipping_line", 
                    width:240
                },
                
                { 
                    headerName: "MSIL Gate In",
                    field: "msil_gate_in", 
                    width:150,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_in);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "MSIL Gate Out",
                    field: "msil_gate_out", 
                    width:150,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_out);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },

                { 
                    headerName: "Handover Date",
                    field: "handover_date", 
                    width:160,
                    valueGetter:function(params){
                        try{
                            return getHyphenDDMMMYYYYHHMM(params.data.handover_date);
                        }
                        catch(e){
                            return "";
                        }
                        
                    }
                },
                
                { 
                    headerName: "POD",
                    field: "searates_pod", 
                    width:240,
                    filter:true
                }                                
            ]
        }
        var screen = this.state.screen;
        //console.log("importslegscols ", importslegscols)
        await this.setState({
				
			detailCellRendererParams:{
				suppressRefresh: true,
				detailGridOptions: {
					columnDefs: importslegscols,
					overlayNoRowsTemplate: 'No trailers available',
											
				},
				getDetailRowData: function(params) {
					//console.log("Step 4 ",transitedelaydata)
					//console.log("Step 4 ",params)
					var qu = {
                        trailer_no:[params.data.truck_no],
                        startDate:startDate,
                        endDate:endDate
                    }
                    //console.log("qu ", qu)
                    if(screen == "imports")
                    {
                        var urlpath = '/consignments/billingimportstrailer';
                    }
                    else
                    {
                        var urlpath = '/consignments/billingexportstrailer';
                    }
					redirectURL.post(urlpath, qu)
					.then(async (response) =>{
						params.successCallback(response.data);
						//console.log("Step 2 ",response.data)
						
					});
					
				}
			}
		});
		
		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if(props.column.colDef.field == '_id')
		{
			props.node.setExpanded(!props.node.expanded);
			//console.log("expanded ",params.node.expanded)
			// if(props.node.expanded == false)
			// {
			// 	transitedelaydata=[]
			// }
		}
		else{

			props.node.setExpanded(false);
        }
        
    }

    onClickCheckboxItem = async (props) =>{
        console.log(props)
    }
    onPaginationChanged = (param) => {
        //console.log("param ", param)
        this.resetPaginationSelection();
    }
    resetPaginationSelection = (e) =>
    {
        //e.api.deselectAll();

        //Initialize pagination data
        let paginationSize = e.api.paginationGetPageSize();
        let currentPageNum = e.api.paginationGetCurrentPage();
        let totalRowsCount = e.api.getDisplayedRowCount();

        //Calculate current page row indexes
        let currentPageRowStartIndex = (currentPageNum * paginationSize);
        let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
        if(currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

        for(let i = 0; i < totalRowsCount; i++)
        {
            //Set isRowSelectable=true attribute for current page rows, and false for other page rows
            let isWithinCurrentPage = (i >= currentPageRowStartIndex && i < currentPageRowLastIndex);
            e.api.getDisplayedRowAtIndex(i).setRowSelectable(isWithinCurrentPage);
        }

    };
    approveSavedItem(item)
    {
        this.setState({
            apprvopen:true,
            approveitem:item
        })
    }
    approveSavedItemAction(){      
        var item = this.state.approveitem
        var cnf = window.confirm("Are you sure you want to approve item ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            if(Array.isArray(this.state.year))
            {
                var yy = this.state.year[0].value;
            }
            else{
                var yy = this.state.year.value;
            }
            if(Array.isArray(this.state.month))
            {
                var mm = this.state.month[0].value;
            }
            else{
                var mm = this.state.month.value;
            }
            var params = {
                selecteditems:[item.data],
                screen:this.state.screen,
                reason:this.state.approveitemreason,
                month:mm,
                year:yy,
                dept_code:this.state.dept_code,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username")
            }
            redirectURL.post("/consignments/approvescBillingTrucks", params)
            .then(async (response) => {
                //console.log(response.data)
                if(response.data.status == "success")
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully revert back item",
                        approveitemreason:"",
                        apprvopen:false
                    })
                   
                }
                else
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicTitle:"Please choose atleast one item",
                        basicType:'info',
                        approveitemreason:"",
                        apprvopen:false
                    })
                }
            })
        }
    }

    revertMsilApproval(){
        this.setState({
            revertopen:true
        })
    }
    revertMsilApprovalAction(){
        var cnf = window.confirm("Are you sure you want to revert items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            if(Array.isArray(this.state.year))
            {
                var yy = this.state.year[0].value;
            }
            else{
                var yy = this.state.year.value;
            }
            if(Array.isArray(this.state.month))
            {
                var mm = this.state.month[0].value;
            }
            else{
                var mm = this.state.month.value;
            }

            var params = {
                dept_code:this.state.dept_code,
                screen:this.state.screen,
                reason:this.state.revertreason,
                month:mm,
                year:yy,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username")
            }
            redirectURL.post("/consignments/revertmsilapprovals", params)
            .then(async (response) =>{
                if(response.data.status == "success")
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully revert items",
                        revertreason:"",
                        revertopen:false
                    })
                    
                }
                else
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicTitle:"Please choose atleast one item",
                        basicType:'info',
                        revertreason:"",
                        revertopen:false
                    })
                }
            })
            .catch(function(e){

            })
        }
    }

    approvalEnmovil(){
        this.setState({
            approveenopen:true
        })
    }
    approvalEnmovilAction(){
        var cnf = window.confirm("Are you sure you want to approve items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            
            if(Array.isArray(this.state.year))
            {
                var yy = this.state.year[0].value;
            }
            else{
                var yy = this.state.year.value;
            }
            if(Array.isArray(this.state.month))
            {
                var mm = this.state.month[0].value;
            }
            else{
                var mm = this.state.month.value;
            }

            var params = {
                dept_code:this.state.dept_code,
                screen:this.state.screen,
                reason:this.state.approveenreason,
                month:mm,
                year:yy,
                email:localStorage.getItem("email"),
                username:localStorage.getItem("username")
            }
            redirectURL.post("/consignments/confirmenmovilapproval", params)
            .then(async (response) =>{
                if(response.data.status == "success")
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully approved",
                        approveenreason:"",
                        approveenopen:false
                    })
                    
                }
                else
                {
                    await this.onClickShowFilterData();
                    await this.setState({
                        show:true,
                        basicTitle:"Please choose atleast one item",
                        basicType:'info',
                        approveenreason:"",
                        approveenopen:false
                    })
                }
            })
            .catch(function(e){

            })
        }
    }


    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	    this.setState({ 
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false, 
        });
     };
    

	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    
    
    onClickShowGridData(params){
        this.setState({
            loadshow:"show-m"
        })
        if(params === "trucks"){
            var parameters = {
                dept_code:this.state.dept_code,
                startDate:this.state.startDate,
                endDate:this.state.endDate,
                screen:this.state.screen
            }
            this.onLoadBillingData(parameters)
            this.setState({
                acbtntrucks:"btn-danger",
                acbtntrips:"btn-default",
                columnstrucks:1,
                columnstrips:0
            });
        }
        else{
            var qu = {
                trailer_no:this.state.truckslist,
                startDate:this.state.startDate,
                endDate:this.state.endDate
            }
            //console.log("qu ", qu)
            if(this.state.screen == "imports")
            {
                var urlpath = '/consignments/billingimportstrailer';
            }
            else
            {
                var urlpath = '/consignments/billingexportstrailer';
            }
            redirectURL.post(urlpath, qu)
            .then((resp) => {                
                this.setState({
                    acbtntrucks:"btn-default",
                    acbtntrips:"btn-danger",
                    columnstrucks:0,
                    columnstrips:1,
                    rowData:resp.data,
                    loadshow:"show-n"
                    
                });
            })
        }
    }
    
    render(){

        const { open } = this.state;
        const { revertopen } = this.state;
        const { approveenopen } = this.state;
        const { apprvopen } = this.state;
        const { disapproveopen } = this.state;
        if(this.state.columnstrucks == 1)
        {
            var columnwithDefs = [            
                // {
                //     headerName:"",
                //     field:"",
                //     width:50,
                //     headerCheckboxSelection: true,
                //     //headerCheckboxSelectionFilteredOnly: true,
                // 	checkboxSelection: function(params){
                //         try{
                //             if(params.data.billing_status == undefined)
                //             {
                //                 return true;
                //             }
                //             else
                //             {
                //                 //console.log("params.data.billing_status ", params)
                            
                //                 if(params.data.billing_status == 0)
                //                 {
                //                     //params.node.setSelected(true);
                //                     return true;
                //                 }
                //                 if(params.data.billing_status == 1)
                //                 {
                //                     //params.node.setSelected(true);
                //                     return false;
                //                 }
                                
                //             }
                //         }
                //         catch(e){
                //             return true;
                //         }
                //     }
                // },
                {
                    headerName:"",
                    field:"_id",
                    width:50,
                    cellRendererSelector:function(params){
                        var rendComponent = {
                            component: 'showtruckconsignments'
                        };
                        return rendComponent
                    }
                },
                {
                    headerName:"Truck No",
                    field:"truck_no",
                    width:120
                },
                {
                    headerName:"Source",
                    field:"source",
                    width:140
                },

                // {
                //     headerName:"Approved By",
                //     field:"approvedName",
                //     width:150
                // },
                // {
                //     headerName:"Approved On",
                //     field:"approved_on",
                //     width:160,
                //     valueGetter:function(params){
                //         try{
                //             return getHyphenDDMMMYYYYHHMM(params.data.approved_on);
                //         }
                //         catch(e){
                //             return "";
                //         }
                //     }
                // },
                {
                    headerName:"Status",
                    field:"billing_status",
                    width:100,
                    cellRendererSelector:function(params){
                        
                        var rendComponent = {
                            component: 'RevokSaveBilling'
                        };
                        return rendComponent
                    
                        
                    }
                },

            ]
        }
        else{
            if(this.state.screen == "imports")
            {
                var columnwithDefs = [
                    { 
                        headerName: "Container No",
                        field: "container_no", 
                        width:150,
                        resizable: true,
                    },
                    { 
                        headerName: "BL No",
                        field: "bill_of_lading", 
                        width:150
                    },
                    
                    { 
                        headerName: "Shipping Line",
                        field: "shipping_line", 
                        width:240
                    },
                    
                    { 
                        headerName: "Vessel Arrived On",
                        field: "vessel_arrived_on", 
                        width:160,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.vessel_arrived_on);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "SMTP Handover Date",
                        field: "smtp_handover_date", 
                        width:160,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.smtp_handover_date);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "Departure GRFL Date",
                        field: "departure_to_grfl_date", 
                        width:150,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.departure_to_grfl_date);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "GRFL Arrival Date",
                        field: "grfl_arrival_date", 
                        width:150,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.grfl_arrival_date);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "Dispatch Date",
                        field: "dispatch_date", 
                        width:150,
                        filter:true,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.dispatch_date);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    }                                
                ]
            }
            else
            {
                var columnwithDefs = [
                    { 
                        headerName: "Container No",
                        field: "container_no", 
                        width:150,
                        resizable: true,
                    },
                    
                    
                    { 
                        headerName: "Shipping Line",
                        field: "shipping_line", 
                        width:240
                    },
                    
                    { 
                        headerName: "MSIL Gate In",
                        field: "msil_gate_in", 
                        width:150,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_in);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "MSIL Gate Out",
                        field: "msil_gate_out", 
                        width:150,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.msil_gate_out);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
    
                    { 
                        headerName: "Handover Date",
                        field: "handover_date", 
                        width:160,
                        valueGetter:function(params){
                            try{
                                return getHyphenDDMMMYYYYHHMM(params.data.handover_date);
                            }
                            catch(e){
                                return "";
                            }
                            
                        }
                    },
                    
                    { 
                        headerName: "POD",
                        field: "searates_pod", 
                        width:240,
                        filter:true
                    }                                
                ]
            }
        }
        

        var columnbilling = [
            {
                headerName:"Department",
                field:"dept_code",
                width:120
            },
            {
                headerName:"Reason",
                field:"reason",
                width:250
            },
            {
                headerName:"Month",
                field:"billing_month",
                width:100,
                valueGetter:function(params){
                    var arr = [
                        "Jan", 
                        "Feb", 
                        "Mar", 
                        "Apr", 
                        "May", 
                        "Jun", 
                        "Jul", 
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ]
                    var month = params.data.billing_month;
                    return arr[month-1];
                }
            },
            {
                headerName:"Year",
                field:"billing_year",
                width:100
            },
            {
                headerName:"Performed By",
                field:"username",
                width:200
            },
            {
                headerName:"Performed Email",
                field:"email",
                width:200
            },
            {
                headerName:"Created On",
                field:"created_on",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.created_on)
                }
            }
        ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Billing ( {this.state.pageTitle} )</span>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                <div className="row">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Month: </label>
                                        <Select 
                                            placeholder={"All"} 
                                          //  isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.month} 
                                            className="border-radius-0" 
                                            onChange={this.changeArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.monthoptions()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Year: </label>
                                        <Select 
                                            placeholder={"All"}  
                                            //isMulti={true}
                                            closeMenuOnSelect={true} 
                                            value={this.state.year} 
                                            className="border-radius-0" 
                                            onChange={this.changeYearArrayHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.yearoptions()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button style={{marginTop:"30px"}} onClick={this.onClickShowFilterData.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                </div>

                                <div className="row">
                                    <button type="button" className={"btn "+(this.state.acbtntrucks)} onClick={this.onClickShowGridData.bind(this,'trucks')}>Unique Trucks</button>
                                    <button type="button" className={"btn "+(this.state.acbtntrips)} onClick={this.onClickShowGridData.bind(this,'trips')}>Trips</button>
                                </div>
                                <div className="row">
                                    <div id="myGrid1" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}
                                        
                                    />
                                        
                                       
                                    </div> 
                                    <div className="col-xl-4 col-lg-4">
                                        {/* {(this.state.approveby != "" || this.state.approvedon != "")?
                                            <span>
                                                Approved By: <span style={{fontWeight:"600"}}>{this.state.approveby}</span>
                                                <br />Approved On: <span style={{fontWeight:"600"}}>{getHyphenDDMMMYYYYHHMM(this.state.approvedon)}</span>
                                            </span>
                                        :""} */}
                                    </div>
                                    {(this.state.hidebuttons == 0 || this.state.hidebuttons == "")?
                                   
                                    <div className="col-xl-8 col-lg-8" style={{textAlign:"right"}}>
                                        {(localStorage.getItem("email") == "nanda@enmovil.in" || localStorage.getItem("email") == "ravi@enmovil.in")?
                                            <span>
                                                <button type="button" className="btn btn-danger" onClick={this.approvalEnmovil.bind(this)}>Approve (Enmovil)</button>
                                                <button type="button" className="btn btn-warning" onClick={this.revertMsilApproval.bind(this)}>Revert MSIL Approval</button>
                                            </span>
                                        :""    
                                        }
                                        <button type="button" className="btn btn-success" onClick={this.approveAllTrucks.bind(this)}>Approve MSIL</button>
                                    </div>
                                    
                                    :""}
                                       
                                </div>


                                <div className="row mt-20p">
                                    <h5>Billing Log Report:</h5>
                                    <BillingLogs 
                                        dept_code={this.state.dept_code}
                                        screen={this.state.screen}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            	<Modal open={open} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <textarea className="col-xl-12 col-lg-12" name="reason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveAllTrucksAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={revertopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <textarea className="col-xl-12 col-lg-12" name="revertreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertMsilApprovalAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={approveenopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <textarea className="col-xl-12 col-lg-12" name="approveenreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approvalEnmovilAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={apprvopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <textarea className="col-xl-12 col-lg-12" name="approveitemreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={disapproveopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <textarea className="col-xl-12 col-lg-12" name="disapprovereason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>

            </div>
    )}       
} 