import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM , getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import DrawMap from "../common/drawmap";
import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ArrivalMap from "./arrivalMap";
import LoadArrivalCounters from "./loadArrivalCounter";
import RailShareChart from './railPlanLineChart';
import { set } from 'lodash';


var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var map;
var dept_code;
var infoBox = require('../common/google-infowindow');

export default class RailLoadPlanAnalysis extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly : "show-n",
            detailCellRendererParams:{},
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            lspRowData: [],
            destRowData: [],
			animateRows: true,
            showTabOne: true,
            showTabTwo: false,
            showTabThree: false,
            showTabFour: false,
            activeTabOne: "activet",
            activeTabTwo: "",
            activeTabThree: "",
            activeTabFour: "",
            lspChartData: [],
            plantChartData: [],
            clusterChartData: [],
            totalChartData: [],
            total_shares_list: [],
            plant_shares_list: [],
            cluster_shares_list: [],
            lsp_shares_list: [],
            loadedChart: false,
            errorLoadingChart: false,
            monthWiseRailShareData: [],
            monthWiseRailChartData: []
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        })
        redirectURL.post("/consignments/getRailLoadPlanAnalysisData").then((response)=>{
            this.setState({
                loadshow : "show-n",
                overly : "show-n",
                rowData: response.data
            })
            redirectURL.post("/consignments/getLSPRailLoadPlanAnalysisData").then((response1)=>{
                this.setState({
                    lspRowData: response1.data
                })
                redirectURL.post("/consignments/getDestRailLoadPlanAnalysisData").then((response2)=>{
                    this.setState({
                        destRowData: response2.data
                    })
                    redirectURL.post("/consignments/getRailShareChartData").then((response3)=>{
                        try{
                            var total_shares_list = JSON.parse(response3.data.total_shares_list);
                            var plant_shares_list = JSON.parse(response3.data.plant_shares_list);
                            var cluster_shares_list = JSON.parse(response3.data.cluster_shares_list);
                            var lsp_shares_list = JSON.parse(response3.data.lsp_shares_list);
    
                            this.setState({
                                total_shares_list: total_shares_list,
                                plant_shares_list: plant_shares_list,
                                cluster_shares_list: cluster_shares_list,
                                lsp_shares_list: lsp_shares_list
                            })
                            this.totalSharesListChart();
                            this.plantSharesListChart();
                            this.clusterSharesListChart();
                            this.lspSharesListChart();

                            redirectURL.post("/consignments/getClusterWiseRailRoadData").then((response4)=>{
                                this.setState({
                                    monthWiseRailShareData: response4.data
                                })
                                this.monthShareListData();
                            })
                        }
                        catch(e){
                            this.setState({errorLoadingChart: true})
                        }
                        
                    })
                })
            })
        })
    }
    totalSharesListChart(){
        var categories = [];
        var seriesData = [{"name": "Road Share", "data": []},{"name": "Rail Share", "data": []}];
        this.state.total_shares_list.map((e)=>{
            categories.push(e.month);
            seriesData[0]['data'].push({"name": e.month,"y": e.road_share});
            seriesData[1]['data'].push({"name": e.month,"y": e.rail_share});
        })
        console.log(seriesData, "total chart")
        this.setState({
            totalChartData: [{"seriesData": seriesData, "categories": categories}],
            loadedChart: true,
            errorLoadingChart: false
        })

    }

    plantSharesListChart(){
        var categories = [];
        var seriesData = [];
        var plants = [];
        this.state.plant_shares_list.map((e)=>{
            categories.push(e.month);
            plants = Object.keys(e)
            const index = plants.indexOf("month");
            if (index > -1) {
                plants.splice(index, 1);
            }
        })
        plants.map((e)=>{
            var data = [];
            this.state.plant_shares_list.map((k)=>{
                data.push({"y": k[e]})
            })
            seriesData.push({"name": e, "data": data})
        })
        console.log(seriesData, "plants")
        this.setState({
            plantChartData: [{"seriesData": seriesData, "categories": categories}]
        })
    }

    clusterSharesListChart(){
        var categories = [];
        var seriesData = [];
        var clusters = [];
        this.state.cluster_shares_list.map((e)=>{
            categories.push(e.month);
            clusters = Object.keys(e)
            const index = clusters.indexOf("month");
            if (index > -1) {
                clusters.splice(index, 1);
            }
        })
        clusters.map((e)=>{
            var data = [];
            this.state.cluster_shares_list.map((k)=>{
                data.push({"y": k[e]})
            })
            seriesData.push({"name": e, "data": data})
        })
        console.log(seriesData, "clusters")
        this.setState({
            clusterChartData: [{"seriesData": seriesData, "categories": categories}]
        })
    }

    lspSharesListChart(){
        var categories = [];
        var seriesData = [];
        var lsps = [];
        this.state.lsp_shares_list.map((e)=>{
            categories.push(e.month);
            lsps = Object.keys(e)
            const index = lsps.indexOf("month");
            if (index > -1) {
                lsps.splice(index, 1);
            }
        })
        lsps.map((e)=>{
            var data = [];
            this.state.lsp_shares_list.map((k)=>{
                data.push({"y": k[e]})
            })
            seriesData.push({"name": e, "data": data})
        })
        console.log(seriesData, "lsp")
        this.setState({
            lspChartData: [{"seriesData": seriesData, "categories": categories}]
        })
    }

    monthShareListData()
    {
        var categories = [];
        var clusters = [];
        this.state.monthWiseRailShareData.map((e)=>{
            if(categories.indexOf(e.month+"-"+e.year) == -1)
            {
                categories.push(e.month+"-"+e.year)
            }
            if(clusters.indexOf(e.cluster) == -1)
            {
                clusters.push(e.cluster)
            }
        });
        console.log(categories, "categories")
        var roadShareArray = [];
        var railShareArray = [];
        var drilldown = [];
        categories.map((e)=>{
            var filtered = this.state.monthWiseRailShareData.filter((k) => {return k.month+"-"+k.year == e})
            var roadshare = 0;
            var railshare = 0;
            var defaultRoadShare = 0;
            filtered.map((l)=>{ roadshare = roadshare + l.road_share; railshare = railshare + l.rake_share })
            if(filtered.length)
            {
                defaultRoadShare = roadshare;
                roadshare = Math.round((roadshare / (roadshare + railshare)) * 100, 2);
                railshare = Math.round((railshare / (defaultRoadShare + railshare)) * 100, 2);
            }
            railShareArray.push({
                "name": e,
                "y": railshare,
                "drilldown": e
            })
            roadShareArray.push({
                "name": e,
                "y": roadshare,
                "drilldown": e
            })
            
            // cluster & lsp drilldown
            var clusterDrilldownData = []
            clusters.map((x)=>{
                var filtered_clusters = filtered.filter((h) => h.cluster == x);
                var cluster_share = 0;
                var cluster_road_share = 0;
                var lsp_drilldown = [];
                filtered_clusters.map((z) => {
                    cluster_share = cluster_share + z.rake_share;
                    cluster_road_share = cluster_road_share + z.road_share;
                    if(z.rake_share != 0)
                    {

                        lsp_drilldown.push({"name": z.transporter_name,"y": Math.round((z.rake_share / (z.road_share + z.rake_share)) * 100, 2)});
                    }
                });
                clusterDrilldownData.push({name: x, "y": Math.round((cluster_share/(cluster_road_share + cluster_share)) * 100, 2),
                                         "drilldown": x+" "+e})

                drilldown.push({
                    "name": x+" "+e,
                    "id": x+" "+e,
                    "data": lsp_drilldown,
                    "type": "column"
                })
            })
            drilldown.push({
                "name": e,
                "id": e,
                "data": clusterDrilldownData,
            })
            
        })
        console.log(railShareArray)
        var monthWiseRailChartData = [{
            seriesData: [
            {
                "name": "Road Share",
                "data": roadShareArray,
                "type": "spline",
                // "yAxis": 0
            },
            {
                "name": "Rail Share",
                "data": railShareArray,
                "type": "spline",
                // "yAxis": 1
            }],
            categories: categories,
            drilldown: drilldown
        }];
        console.log(monthWiseRailChartData, "monthWiseRailChartData")

        this.setState({
            monthWiseRailChartData: monthWiseRailChartData
        })
    }

    onClickHideAll(){
		this.setState({
            sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n',
		});
		
    }
    
    onGridReady = params => {
		this.gridApi = params.api;		
        this.gridColumnApi = params.columnApi;
	};

	onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);   
    }

    onGridLSPReady = params => {
		this.gridApi = params.api;		
        this.gridColumnApi = params.columnApi;
	};

	onGridLSPState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);   
    }
    
    onGridDestReady = params => {
		this.gridApi = params.api;		
        this.gridColumnApi = params.columnApi;
	};

	onGridDestState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);   
	}
    
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickSubmit(e){
        e.preventDefault();
    }
    render(){
        const railcolumns = [
            {
                headerName: "Month",
                field: "month_name",
                width: 90
            },
            {
                headerName: "Source",
                field: "source",
                width: 130
            },
            {
                headerName: "Destination",
                field: "desitnation",
                width: 130
            },
            {
                headerName: "No. of NMG",
                field: "no_of_nmg",
                width: 130
            },
            {
                headerName: "No. of TPT Rakes",
                field: "no_of_tpt_rakes",
                width: 130
            },
            {
                headerName: "Total Cars",
                field: "total_cars",
                width: 100
            },
            {
                headerName: "Week 1",
                field: "week_1",
                width: 110
            },
            {
                headerName: "Week 2",
                field: "week_2",
                width: 110
            },
            {
                headerName: "Week 3",
                field: "week_3",
                width: 110
            },
            {
                headerName: "Week 4",
                field: "week_4",
                width: 110
            },
            {
                headerName: "Week 5",
                field: "week_5",
                width: 110
            }
        ]
        const lspcolumns = [
            {
                headerName: "Month",
                field: "month_name",
                width: 90
            },
            {
                headerName: "Transporter",
                field: "transporter",
                width: 130
            },
            {
                headerName: "Source",
                field: "source",
                width: 130
            },
            {
                headerName: "Destination",
                field: "desitnation",
                width: 130
            },
            {
                headerName: "No. of NMG",
                field: "no_of_nmg",
                width: 120
            },
            {
                headerName: "No. of TPT Rakes",
                field: "no_of_tpt_rakes",
                width: 100
            },
            {
                headerName: "Total Cars",
                field: "total_cars",
                width: 100
            },
            {
                headerName: "Week 1",
                field: "week_1",
                width: 90
            },
            {
                headerName: "Week 2",
                field: "week_2",
                width: 90
            },
            {
                headerName: "Week 3",
                field: "week_3",
                width: 90
            },
            {
                headerName: "Week 4",
                field: "week_4",
                width: 90
            },
            {
                headerName: "Week 5",
                field: "week_5",
                width: 90
            }
        ]
        const destcolumns = [
            {
                headerName: "Month",
                field: "month_name",
                width: 90
            },
            {
                headerName: "Destination",
                field: "desitnation",
                width: 130
            },
            {
                headerName: "Total Cars",
                field: "total_cars",
                width: 100
            },
            {
                headerName: "Week 1",
                field: "week_1",
                width: 110
            },
            {
                headerName: "Week 2",
                field: "week_2",
                width: 110
            },
            {
                headerName: "Week 3",
                field: "week_3",
                width: 110
            },
            {
                headerName: "Week 4",
                field: "week_4",
                width: 110
            },
            {
                headerName: "Week 5",
                field: "week_5",
                width: 110
            },
            {
                headerName: "Avg. Rail Arrival frequency (Days)",
                field: "avg_rake_arrival_frequency_days",
                width: 180
            }

        ]

        return(
            <div class="container-fluid" style={{paddingBottom:"3em"}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <button className={"btn btn-default "+this.state.activeTabOne} style={{marginLeft: "1em"}} onClick={()=>{this.setState({showTabOne: true,showTabTwo: false, showTabThree: false,showTabFour: false,
                                                                                                            activeTabOne: "activet", activeTabTwo: "", activeTabThree: "",activeTabFour: ""})}}>Rail Load Analysis</button>
                <button className={"btn btn-default "+this.state.activeTabTwo} onClick={()=>{this.setState({showTabOne: false,showTabTwo: true, showTabThree: false,showTabFour: false,
                                                                            activeTabOne: "", activeTabTwo: "activet", activeTabThree: "", activeTabFour: ""})}}>LSP Rail Load Analysis</button>
                <button className={"btn btn-default "+this.state.activeTabThree} onClick={()=>{this.setState({showTabOne: false,showTabTwo: false, showTabThree: true,showTabFour: false,
                                                                                activeTabOne: "", activeTabTwo: "", activeTabThree: "activet", activeTabFour: ""})}}>Destination Rail Load Analysis</button>
                 <button className={"btn btn-default "+this.state.activeTabFour} onClick={()=>{this.setState({showTabOne: false,showTabTwo: false, showTabThree: false, showTabFour: true,
                                                                                activeTabOne: "", activeTabTwo: "", activeTabThree: "", activeTabFour: "activet"})}}>Rail Share Analysis</button>
                 {(this.state.showTabOne)?
                    <div className={"col-xl-12 col-lg-12 "}>
                        <div className="card" >
                            <div className="card-header">
                                <h5><i className="icofont icofont-train-steam f25"></i> Rail Planning Analysis</h5>
                            </div>
                            <div className="card-body">
                                <div id="myGrid" style={{width:"100%",height:"480px"}} className="ag-theme-balham">    
                                    <AgGridReact
                                    masterDetail={true}
                                        modules={this.state.modules}
                                        columnDefs={railcolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        suppressRowTransform={false}
                                        paginationPageSize={50}
                                        pagination={true}
                                        rowSelection={"multiple"}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        required />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                 :""}
                
                {(this.state.showTabTwo)?
                    <div className={"col-xl-12 col-lg-12 "}>
                        <div className="card" >
                            <div className="card-header">
                                <h5><i className="icofont icofont-train-steam f25"></i> LSP Rail Planning Analysis</h5>
                            </div>
                            <div className="card-body">
                                <div id="myGrid" style={{width:"100%",height:"480px"}} className="ag-theme-balham">    
                                    <AgGridReact
                                    masterDetail={true}
                                        modules={this.state.modules}
                                        columnDefs={lspcolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.lspRowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        onGridReady={this.onGridLSPReady}
                                        onGridState={this.onGridLSPState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        suppressRowTransform={false}
                                        paginationPageSize={50}
                                        pagination={true}
                                        rowSelection={"multiple"}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        required />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                :""}
                {(this.state.showTabThree)?
                    <div className={"col-xl-12 col-lg-12 "}>
                        <div className="card" >
                            <div className="card-header">
                                <h5><i className="icofont icofont-train-steam f25"></i> Destination wise Rail Planning Analysis</h5>
                            </div>
                            <div className="card-body">
                                <div id="myGrid" style={{width:"100%",height:"480px"}} className="ag-theme-balham">    
                                    <AgGridReact
                                    masterDetail={true}
                                        modules={this.state.modules}
                                        columnDefs={destcolumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.destRowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        onGridReady={this.onGridDestReady}
                                        onGridState={this.onGridDestState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        suppressRowTransform={false}
                                        paginationPageSize={50}
                                        pagination={true}
                                        rowSelection={"multiple"}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        required />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                :""}
                {(this.state.showTabFour)?
                    
                    <div className={"col-xl-12 col-lg-12 "}>
                        <div className="card" >
                            <div className="card-header">
                                <h5><i className="icofont icofont-train-steam f25"></i> Rail Share Analysis</h5>
                            </div>
                            
                            {(this.state.loadedChart & this.state.errorLoadingChart == false)?
                                <div className="card-body row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        {this.state.totalChartData.length > 0?
                        
                                            this.state.totalChartData.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row"}>
                                                    
                                                    <RailShareChart
                                                        title={"Road vs Rail Share"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.seriesData}
                                                        categories={item.categories}
                                                        xaxistitle={"Months"}
                                                        yaxistitle={"Road vs Rail Share"}
                                                    />
                                                </div>
                                            )
                                        :
                                            ""
                                        }
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        {this.state.plantChartData.length > 0?
                        
                                            this.state.plantChartData.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row"}>
                                                    
                                                    <RailShareChart
                                                        title={"Plant Wise Share"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.seriesData}
                                                        categories={item.categories}
                                                        xaxistitle={"Months"}
                                                        yaxistitle={"Plant Wise Share"}
                                                    />
                                                </div>
                                            )
                                        :""}
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        {this.state.clusterChartData.length > 0?
                        
                                            this.state.clusterChartData.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row"}>
                                                    
                                                    <RailShareChart
                                                        title={"Cluster Share"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.seriesData}
                                                        categories={item.categories}
                                                        xaxistitle={"Road vs Rail"}
                                                        yaxistitle={"Months"}
                                                    />
                                                </div>
                                            )
                                        :""}
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        {this.state.lspChartData.length > 0?
                        
                                            this.state.lspChartData.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row"}>
                                                    
                                                    <RailShareChart
                                                        title={"LSP Wise Share"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.seriesData}
                                                        categories={item.categories}
                                                        xaxistitle={"Road vs Rail"}
                                                        yaxistitle={"Months"}
                                                    />
                                                </div>
                                            )
                                        :""}
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        {this.state.monthWiseRailChartData.length > 0?
                        
                                            this.state.monthWiseRailChartData.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row"}>
                                                    
                                                    <RailShareChart
                                                        title={"Monthly Road vs Rail Share"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.seriesData}
                                                        categories={item.categories}
                                                        drilldown={item.drilldown}
                                                        xaxistitle={"Months"}
                                                        yaxistitle={"Road vs Rail Share"}
                                                    />
                                                </div>
                                            )
                                        :
                                            ""
                                        }
                                    </div>
                                </div>
                            :
                                (this.state.errorLoadingChart == false)?
                                    <div className="card-body" style={{textAlign: "center"}}>
                                        <h5 className="text-center f16">Loading...</h5>
                                    </div>
                                :
                                <div className="card-body" style={{textAlign: "center"}}>
                                    <h5 className="text-center f16"> No Data Found.</h5>
                                </div>
                                
                            }
                        </div>

                    </div>
                   
                    
                :""}
                
                
                <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }
        
function loadDateTimeScript(){
	// alert("timeDate");
	$("#monthYearPicker").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("monthYearPicker");
        }
    });
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function groupBy(list, keyGetter) {
    const maps = new Map();
    list.forEach((item) => {
         const keys = keyGetter(item);
         const collection = maps.get(keys);
         if (!collection) {
            maps.set(keys, [item]);
         } else {
             collection.push(item);
         }
    });
    return maps;
}