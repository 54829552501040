import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from './gridReportComponent';
var redirectURL = require('../redirectURL');
 


export default class ReportEnrouteStoppages extends Component {

	constructor(props){
		super(props);
		this.state={
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			truck_no:'',
			transporter_code:'',
			dept_code:'',
			file:'',
			alltransporters:[],
			alltransporter_codes:[],
			allDepts:[],
			csvmessage:'',
			csverrmessage:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			loadshow:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default"
			
		}
	
	}
	componentDidMount(){

		this.setState({
			loadshow:'show-m'
		});
		 /*Truck with gpsdata*/	
	    redirectURL.post('/reports/overspeed')
		  .then((response) => {
			 
		   //var records = JSON.parse(JSON.stringify(response)).data;
		   //console.log(response.data);
		   this.setState({
			   rowData:response.data,
			   loadshow:'show-n'
		   });
		     
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	    
	};
	
	
	onShowSidebarDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseSidebarDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
		document.getElementById("upform").reset();
	}
	 
	render(){
		const columnwithDefs = [
	  	        {
	  	          headerName: "Truck No",
	  	          field: "truck_no",
	  	          width: 150,
	    	    
		        },
		        {
					headerName: "Transporter Code",
					field: "transporter_code",
					width: 200
			  },
			  {
		  	        headerName: "Transporter Name",
		  	        field: "transporter_name",
					width: 300,
					
					  
		        },
	  	        {
		          headerName: "Speed",
		          field: "speed",
		          width: 120,
	  	          
		        },
	  	        {
			          headerName: "Reported at",
			          field: "first_instance",
			          width: 200,
	    	          filter: "agTextColumnFilter"
		    	          
		        },
	  	       
		      
	    	       
	    ];
		return(
			<div className="container-fluid">
		
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Reports - Enroute Stoppages </span>
                                </h5>
				   			</div>
				   			<div className="card-body">
				   			
								   <Grid 
								  	rowData={this.state.rowData} 
                                    columnwithDefs={columnwithDefs}  
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
				 	<div className="slide-r-body" style={{position:"relative"}}>
						<div className={"dataLoad " +(this.state.loadshow)}>
							<img src={require("../../assets/icons/loader.gif")} />
						</div>
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 			
				 		</div>
				 	</div>
				 </div>
            </div>
              	
		);
	}
}