import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./editrailsconsignment";
import CountUp from 'react-countup';

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MLLConsignmentsChildGrid extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                editMLLConsignment:EditMLLConsignment
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
			sourceopt:'',
			fnr_no:"",
			gpsdevice:"",
			sources:[],
			totalvinsarr:[],
			totalvins:0,
			atplantarr:[],
			atplant:0,
			intransitloadarr:[],
			intransitload:0,
			atloadarr:[],
			atload:0,
			rakeassignarr:[],
			rakeassign:0,
			intransitdestarr:[],
			intransitdest:0,
			atdestinationarr:[],
			atdestination:0,
			intransitdealerarr:[],
			intransitdealer:0,
			intransitrsoarr:[],
			intransitrso:0,
			atrsoarr:[],
			atrso:0,
			neardealerarr:[],
			neardealer:0,
			podreceiptarr:[],
			podreceipt:0,
			lastmiledestination:"",
			desttype:"",
			destypeopt:"",
			consignees:[],
			rsos:[],
			destopts:[],
			sliderForceLastMile:"",
			sliderRso:"",
			modetype:"",
			dropdownOpen: false,
			sliderLoadingYardTranslate: ""
			
        };

		this.resetState = this.resetState.bind(this);
        
	}
	
    componentDidMount(){
        loadDateTimeScript();
        
	}
    
    componentWillReceiveProps()
    {
        console.log("legs..!!")
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
        var parameter={
            master_ref_no: this.props.refno
        }
        redirectURL.post('/consignments/mllbatchesbyrefno', parameter)
        .then(async (response) =>{
            var records = response.data.records;
			var batchdata = [];
            var rows = []
			if(records !=undefined)
			{
				if(records.length > 0)
				{
					var batchdata = groupBy(records, rdata => rdata.batchno);
					batchdata.forEach((item,key) => {
						if(key != "undefined" && key != undefined)
						{
							item[0].totalvins = item.length
							rows.push(item[0])
						}
					})
				}
			}
            // console.log("batchdata ", batchdata)
            this.setState({
                rowData: rows,
                refno: this.props.refno
            })
            console.log(rows)
        });
    }
    
    
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='MLL Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
	toggle() {

        this.setState(prevState => ({

            dropdownOpen: !prevState.dropdownOpen

        }));

    }
    render(){
        const columnwithDefs = [
            {
                headerName:"Batch No",
                field:"batchno",
                width:150,
                resizable:true
            },
            {
                headerName:"Total Vins",
                field:"totalvins",
                width:150,
                resizable:true
            },
            { 
                headerName: "",
                field: "batchno", 
                cellRenderer:function(params){
                    var batchno = params.data.batchno;
                    return '<a href="/mllmarkarrivalloadingyard/'+batchno+'" style="color:#333" class="label label-warning" target="_blank">Mark Arrival At Loading Yard</a>'
                },
                width:180,
                resizable:true
            },
                                    
        ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
						
            	<div className="row">
                    
            		<div className="col-xl-12 col-lg-12">      
		            	<div className="card">
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        />

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}