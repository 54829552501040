import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridUpdateComponent';
import Select from 'react-select';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM } from '../common/utils'; 
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageTrucks extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Manage Trucks",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData:[],
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderAddTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			truck_no:'',
			transporter_code:'',
			dept_code:'',
			file:'',
			alltransporters:[],
			alltransporter_codes:[],
			allDepts:[],
			csvmessage:'',
			csverrmessage:'',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			loadshow:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			usermanualmodal:false,
			truck_no:"",
			transporter:{"value":"","label":"Select"},
			overly:"show-n"
			
		}
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.uploadFormHandler = this.uploadFormHandler.bind(this);
		this.changeSelectHandler = this.changeSelectHandler.bind(this);
	}
	
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/manage/sndtrucks":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/manage/prttrucks":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptrucks":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctrucks":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

		this.setState({
			loadshow:'show-m'
		});
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});

		var plant_code =""
		if(localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined" && localStorage.getItem("pc") != null && localStorage.getItem("pc") !=""){
			plant_code = localStorage.getItem("pc")
		}
		var params ={
			plant_code : plant_code,
		}
		 /*Truck with gpsdata*/	
	    redirectURL.post('/masters/trucks',params)
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		//    console.log(records);
		   if (this.state.departmentCode) {
			 records = records.filter(rec => rec.dept_code === this.state.departmentCode);
		   }
		   this.setState({
			   rowData:records,
			   loadshow:'show-n'
		   });
		     
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	    /*Get All Transporters from Trucks table */
	    redirectURL.post('/masters/getAllTransporters')
		  .then((response) => {
			//console.log("/masters/getAllTransporters = ",response.data);
			 var all_transporters = [];
			 var transporter_codes=[]
			  for(var rec in response.data)
			  {
				  all_transporters.push({"transporter_code":response.data[rec].transporter_code,"transporter_name":response.data[rec].transporter_name})
				  transporter_codes.push(response.data[rec].transporter_code+"_"+response.data[rec].transporter_name);
			  }
			  //console.log("/masters/getAllTransporters, all_transporters, transporter_codes = ", all_transporters, transporter_codes);
			this.setState({
				alltransporters: all_transporters,
				alltransporter_codes : transporter_codes
				});
		  });
			    		  
	    /*Get All Departments Distinct from Trucks table*/
	    redirectURL.post('/masters/departments')
		  .then((response) => {
			  //console.log("/masters/departments = ",response.data);
			this.setState({allDepts:response.data});
		  });
	    
	};
	
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	changeHandler(event){
		var name= event.target.name;
		var value = event.target.value;
		this.setState({
			[name]:value
		})
	}
    buttonExample = (type) => {
        switch (type) {
            case 'custom':
                this.setState({
                    alert: (
                        <SweetAlert
                            custom
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnText="No"
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="default"
                            customIcon="thumbs-up.jpg"
                            title="Do you like thumbs?"
                            onConfirm={this.hideAlert}
                            onCancel={this.hideAlert}
                        >
                            You will find they are up!
                        </SweetAlert>
                    )
                });
                break;
            case 'input':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            showCancel
                            cancelBtnBsStyle="default"
                            title="An input!"
                            placeHolder="Write something"
                            onConfirm={this.onRecieveInput}
                            onCancel={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            case 'password':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            required
                            inputType="password"
                            title="Enter Password"
                            validationMsg="You must enter your password!"
                            onConfirm={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            default:
                this.setState({
                    alert: (
                        <SweetAlert
                            showCancel
                            confirmBtnText="Continue"
                            confirmBtnBsStyle={type}
                            type={type}
                            title="Are you sure?"
                            onCancel={this.hideAlert}
                            onConfirm={this.hideAlert}
                        >
                            You will not be able to recover this imaginary file!
                        </SweetAlert>
                    )
                });
                break;
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

	/*End*/
	
	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
		document.getElementById("upform").reset();
	}
	resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			transporter :{"value":"","label":"Select"}
		});
		document.getElementById("upform").reset();
	}
	
	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
	
	changeSelectHandler(){
		console.log("test");
	}
	
	uploadFormHandler(event){
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			if (this.state.currentDepartmentTitle) {
				pageTitle = pageTitle + " " + this.state.currentDepartmentTitle;

			}
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.fileUploaded,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		var formData = new FormData(event.target);
		formData.append('uploadFile',this.state.file);
		var plant_code =""
		if(localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined" && localStorage.getItem("pc") != null && localStorage.getItem("pc") !=""){
			plant_code = localStorage.getItem("pc")
		}
		var params ={
			plant_code : plant_code,
		}
		console.log('file',formData);
		this.setState({
			loadshow:'show-m'
		}); 
		redirectURL.post("/manage/uploadMasterTruckCsv", formData,{
			headers:{
				'content-type': 'multipart/form-data'
			}
		}).
		then(
			(response)=>{
				console.log(response);
				//console.log(response.data.status);
				document.getElementById("upform").reset();
				if(response.data.status == 'failure')
				{
					this.setState({
						//csverrmessage:response.data.status,
						show: true, basicType:'danger', basicTitle:response.data.message,
						uploadDivWidth:'0%',
						sliderTranslate:'',
						showDiv:'show-n',
						loadshow:'show-n',
						uploadFile:'',
						file:''
					});
					
				}
				else
				{
					this.setState({
						show: true, basicType:'success', basicTitle:response.data.message,
						uploadDivWidth:'0%',
						sliderTranslate:'',
						showDiv:'show-n',
						uploadFile:'',
						loadshow:'show-n',
						file:''
					});
					 redirectURL.post('/masters/trucks',params)
					  .then((response) => {
						 
					   var records = JSON.parse(JSON.stringify(response)).data;
					   //console.log(records);
					   this.setState({
						   rowData:records,
							loadshow:'show-n'
					   });
					    
					  })
					  .catch(function (error) {
					    console.log(error);
					  });
				}
				
				 
			}
		)
		.catch(function(error){
			console.log(error);
		});
	}
	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate:'',
			sliderAddTranslate:'',
			transporter :{"value":"","label":"Select"}
		});
		document.getElementById("upform").reset();
	}
	addTruck(){
		this.setState({
			sliderAddTranslate:"slider-translate",
			overly:'show-m',
		})
	}
	transporters(){
		var items= [];
		var alltransporters = this.state.alltransporters;
		if(alltransporters.length > 0){
			alltransporters.map((item) => {
				items.push({
					value:item.transporter_code,
					label:item.transporter_name,
				})
			})
		}
		return items;
	}
	onChangeTransporter(transporter){
		this.setState({transporter} ,() =>{
			// console.log("transporter ",this.state.transporter);
		})
	}
	addTruckFormHandler(event){
		event.preventDefault();
		var truck_no = this.state.truck_no;
		if(this.state.transporter.value != "")
		{
			var transporter_code = this.state.transporter.value;
			var transporter_name = this.state.transporter.label;
		}
		else{
			var transporter_code = "";
			var transporter_name = "";
		}
		var params = {
			truck_no:truck_no,
			transporter_code:transporter_code,
			transporter_name:transporter_name
		}
		redirectURL.post("amns/addtruck",params)
		.then((response) => {
			if(response.data.status == "success"){
				this.setState({
					show:true,
					basicTitle:"Successfully added truck",
					basicType:"success",
					truck_no:"",
					transporter:{"value":"","label":"Select"},
					sliderAddTranslate:"",
					overly:'show-n'
				})
			}
			else{
				this.setState({
					show:true,
					basicTitle:"Failed to add truck",
					basicType:"danger"
				})
			}
		})
	}
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const hideprtcolumns = (this.state.departmentCode == "LOG-PRT")?false:true
		const {usermanualmodal} = this.state;
		const columnwithDefs = [
	  	        {
	  	          headerName: "Truck No",
	  	          field: "truck_no",
	  	          width: 150,
	    	    	editable:false,
	    	        filter: "agSetColumnFilter",
		        },
		        {
					headerName: "LSP Code",
					field: "transporter_code",
					width: 200,
					cellEditor: "agRichSelectCellEditor",
				    cellEditorParams: {
					  values: this.state.alltransporter_codes,
					  cellRenderer: function(params) {
							return params.value.split("_")[0]
						}
					},
					valueSetter:function(params){
						//params.setValue('transporter_code') = params.newValue.split('_')[1]
						//console.log(params.newValue)
						params.data.transporter_name=params.newValue.split('_')[1];
						params.data.transporter_code=params.newValue.split('_')[0];
						
						return params.newValue.split('_')[0]
					},
					filter: "agSetColumnFilter"
			  },
			  {
		  	        headerName: "LSP Name",
		  	        field: "transporter_name",
					  width: 200,
					  editable:false,
					  filter: "agSetColumnFilter",
					  
		        },
	  	        // {
		        //   headerName: "Department Code",
		        //   field: "dept_code",
		        //   width: 120,
	  	        //   cellEditor: "agRichSelectCellEditor",
	            //   cellEditorParams: {
	            // 	  values: this.state.allDepts
	            //   },
    	        //   filter: "agSetColumnFilter"
	    	          
		        // },
	  	        // {
		        //   headerName: "Actual GPS Provider",
		        //   field: "actual_lspuser",
		        //   width: 150,
		        //   editable:false,
    	        //   filter: "agSetColumnFilter",
	    	          
		        // },
	  	        {
					headerName: "Last Sample Time",
					field: "timestamp",
					width: 200,
					editable:false,
					valueGetter:function(params){
								if(params.data.timestamp != undefined){
									// console.log(new Date(params.data.timestamp));
								return getHyphenDDMMMYYYYHHMM(params.data.timestamp);	
								}	
								else{
									return "";
								}
								 
							},
				//   cellRenderer:'dateFormater',
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				      	          
				},
				{
					headerName: "Last Packet Time",
					field: "modified_date",
					width: 200,
					editable:false,
					/*valueGetter:function(params){
						var dsplit = params.data.last_gps_date.split("T");
						var SSplit = dsplit[1].split(".");
						var dateFormatIs = dsplit[0]+" "+SSplit[0]
						return (dateFormatIs)
					},*/
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date);	 
					},
					// cellRenderer:'dateFormater',
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue;
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },   	          
				},
				{
					headerName: "Axle Type",
					field: "axle_type",
					width: 130,
					editable: false,
					filter: true,
					hide: hideprtcolumns
				},
				{
					headerName: "Truck Type",
					field: "truck_type",
					width: 130,
					editable: false,
					filter: true,
					hide: hideprtcolumns
				},
				{
					headerName: "Report Truck Type",
					field: "report_truck_type",
					width: 130,
					editable: false,
					filter: true,
					hide: hideprtcolumns
				},				
		        {
		            headerName: "Status",
		            field: "is_active",
		            width: 90,
					filter: false,
		            //singleClickEdit:true,
		            editable: true,
		            cellRenderer: function(params){
		            	if(params.value == 1 || params.value == 'Active')
	            		{
		            		return 'Active';
	            		}
		            	else
	            		{
		            		return 'Inactive';
	            		}
		            },
		            cellEditor: 'agRichSelectCellEditor',
		            cellEditorParams: {
		                cellRenderer: 'genderCellRenderer',
		                values: ['Active', 'Inactive']
		            }
		        },
		        {
		          headerName: "Action",
		          field: "_id",
		          width: 100,
				  filter: false,
		          cellRenderer:'updateItem',
		          cellRendererParams: {
			            actionCall: "manage",
			            actionMethod:"updateTruckRow"
			        },
			        editable:false
		        }
		        
		      /*  {
			          headerName: "",
			          field: "_id",
			          width: 100,
			          cellRenderer:'statusChange',
			          cellRendererParams: {
				            actionCall: "manage",
				            actionMethod:"statusUpdate"
				        },
				        editable:false,
				          suppressCellFlash: true
			        }*/
	    	       
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> 
									   <span>Manage Trucks</span>
				       				{/* <button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Upload CSV</button> */}
									<button type="button" onClick={this.addTruck.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-plus"></i> Add Truck</button>
				       			</h5>
				   			</div>
				   			<div className="card-body">
				   				{/*<div className={"dataLoad " +(this.state.loadshow)}>
				   					<img src={require("../../assets/icons/loader.gif")} />
				   				</div>
				   					*/}
				   					
								   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}  
									table={"trucklocation"} 
									rowData={this.state.rowData} 
									columnwithDefs={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 
				 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				 <div className={"slide-r "+(this.state.sliderAddTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
				 			Add Truck
							 <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
				 			{/*<span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span>*/}
				 		</h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						{/* <div className={"dataLoad " +(this.state.loadshow)}>
							<div style={{position:"absolute", left:"38%"}}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div> */}
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 			<form id="upform" className="theme-form" onSubmit={this.addTruckFormHandler.bind(this)}>

				 				<div className="form-group mt-20p">
				 					<label className="">Truck No</label> 
				 					<input type="text" name="truck_no" onChange={this.changeHandler.bind(this)}  className="form-control" required  />
				 				</div>
								 
				 				<div className="form-group mt-20p">
				 					<label className="">Transporter</label> 
									 <Select 
									 	value={this.state.transporter}
										className="border-radius-0"
										isMulti={false}
										id="transporter"
										name="transporter"
										style={{borderRadius:"0px"}} 
										options={this.transporters()}
										onChange={this.onChangeTransporter.bind(this)}
										  />
				 				</div>
			 					<div className="form-group">
				 					<button type="submit" className="btn btn-success">Submit</button>
				 					<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
			 					</div>
		 					</form>
		 					{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a> 
								</div> */ }
				 		</div>
				 	</div>
				 </div>
				 
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
				 			Upload File
				 			{/*<span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span>*/}
				 		</h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						<div className={"dataLoad " +(this.state.loadshow)}>
							<div style={{position:"absolute", left:"38%"}}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div>
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 			<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

				 				<div className="form-group mt-20p">
				 					<label className="">Upload File</label> 
				 					<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
				 				</div>
			 					<div className="form-group">
				 					<button type="submit" className="btn btn-success">Submit</button>
				 					<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
			 					</div>
		 					</form>
		 					{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a> 
								</div> */ }
				 		</div>
				 	</div>
				 </div>

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Trucks</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of master file of the entire fleet list which serves a particular department for carrying the material along with its transporter and GPS service provider details. Each column is having filter option through which one can find particular details, make particular changes and update the master file. Various grid columns are described below:
							</div>
							
							<div className="col-xl-12 col-lg-12">
								
								<table className="table table-border">
									<tr>
										<th>Column</th>
										<th>
											Remarks
										</th>
									</tr>
									<tr>
										<td>
										Truck No
										</td>
										<td>
										Truck detail
										</td>
									</tr>
									<tr>
										<td>
										LSP Code
										</td>
										<td>
										MSIL code provided for transporter
										</td>
									</tr>
									<tr>
										<td>
										LSP Name	
										</td>
										<td>
										Transporter Name
										</td>
									</tr>
									<tr>
										<td>
										Department Code
										</td>
										<td>
										Name of Department
										</td>
									</tr>
									{/* <tr>
										<td>
										Actual GPS Provider
										</td>
										<td>
										GPS Service provider Name
										</td>
									</tr> */}
									<tr>
										<td>
										Last Sample Time
										</td>
										<td>
										Sample GPS data details
										</td>
									</tr>
									<tr>
										<td>
										Last Packet Time
										</td>
										<td>
										Last packet time details by service provider
										</td>
									</tr>
									<tr>
										<td>
										Status
										</td>
										<td>
										Current status of vehicle wheter actively used by dept or not
										</td>
									</tr>
									<tr>
										<td>
										Action
										</td>
										<td>
										update status of any changes made in master
										</td>
									</tr>
									<tr>
										<td>

										</td>
										<td>
											
										</td>
									</tr>
									<tr>
										<td>

										</td>
										<td>
											
										</td>
									</tr>
								</table>
					
							</div>
							<div className="col-xl-12 col-lg-12">
								If user wants to update multiple vehicles, this can be done through bulk option available as shown in figure below:
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>
            </div>
              	
		);
	}
}

function alltransporters(){

	 /*Truck Transporters*/	
	var arr=[];
   redirectURL.post('/masters/truckTransporters')
	  .then((response) => {
		 // console.log(response.data)
		return response.data;
	  });

}

// function dateComparator(date1, date2) {
// 	// console.log("dateComparator");
// 	var date1Number = monthToComparableNumber(date1);
// 	var date2Number = monthToComparableNumber(date2);
// 	console.log(date1,date2);
// 	console.log(date1Number,date2Number);
// 	if (date1Number === null && date2Number === null) {
// 	  return 0;
// 	}
// 	if (date1Number === null) {
// 	  return -1;
// 	}
// 	if (date2Number === null) {
// 	  return 1;
// 	}
// 	return date1Number - date2Number;
//   }
// function monthToComparableNumber(date) {
// 	console.log(date);

// 	if (date === undefined || date === null) {
// 		return null;
// 	}
// 	if(date.search("T") == -1 )
// 	{
// 		if(date.length !== 19)
// 		{
// 			return null;
// 		}
		
// 	}
	
// 	var yearNumber = date.substring(0, 4);
// 	var monthNumber = date.substring(5,7);
// 	var dayNumber = date.substring(8,10);
// 	// console.log(yearNumber,monthNumber,dayNumber);
// 	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
// 	return result;
// }
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}