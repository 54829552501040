
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Ticketloadingaction = (props) => {
    //console.log("LoadingDelay ",props);
    const handleLoadingDelayClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowLoadingDelayKpi(props);
    };
   
    return (
        <div>
            <button onClick={handleLoadingDelayClick} className="custom-btn f22 label label-success" title="Loading Delay"><i className="icofont icofont-truck-loaded darkorange"></i> &nbsp;</button>
            
           
        </div>
    );
};

export default Ticketloadingaction;
