/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import CountUp from 'react-countup';
// import axios from 'axios';
// var redirectURL = require('../redirectURL');

export default class D22ConsignmentCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		active_consignments:0,
    		overspeeding:0,
            night_driving:0,
            transit_delay:0,
            unloading_dealer:0,
            left_dealer:0,
            gps_data_available:0,
            nogpslifetime:0,
            is_critical:0,
            is_priority: 0,
            // deptcode:this.props.deptcode,
            active_trucks:0,
            loading_delay:0,
            unloading_delay:0,
            enroute_stoppage:0,
            probableAccident : 0,
            hidecol:true,
            probableAccidentconfirm:0,
            cancelledCount : 0,
            pod_count: 0,
            trip_closed_by_pod_count: 0,
            returnConsignments: 0,            
            counttype:"all",
            digi_lock:0,
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{      
        //console.log("Conters ",newprops.deptcode)            
            
               
        /*New Code for Counters*/
        var counter = newprops.countersjson;
        console.log("counter: => ",counter)
        var overspdcnt = 0;
        var nightdrvcnt = 0;
        var transdly = 0;
        var npgs = 0;
        var nogpslifetime=0;
        var lodelay= 0;
        var unlodelay = 0;
        var enrote=0;
        var critical=0;
        var priority=0;
        var probableAccident =0;
        var probableAccidentConfirmCount=0;
        var cancelledCount = 0;
        var pod_count = counter.pod_received_data;
        var trip_closed_by_pod_count = counter.trip_closed_by_pod;
       
        overspdcnt = counter.overspeeding;
        nightdrvcnt = counter.night_drive;
        transdly = counter.transit_delay;
        npgs = counter.no_gps;
        nogpslifetime = counter.nogpslifetime;
        lodelay = counter.loading_delay;
        enrote = counter.enroute_stoppage;           
        
        console.log("nogpslifetime ",nogpslifetime)
        await this.setState({
            active_consignments:newprops.gridData.length,
            overspeeding:overspdcnt,
            is_critical:critical,
            is_priority: priority,
            night_driving:nightdrvcnt,
            transit_delay:transdly,
            gps_data_available:npgs,
            nogpslifetime:nogpslifetime,
            loading_delay:lodelay,
            unloading_delay:unlodelay,
            enroute_stoppage:enrote,
            probableAccident : probableAccident,
            probableAccidentconfirm:probableAccidentConfirmCount,
            cancelledCount: cancelledCount,
            pod_count: pod_count,
            trip_closed_by_pod_count: trip_closed_by_pod_count,
            returnConsignments: newprops.returnConsignments,    
            counttype:newprops.counttype,
            digi_lock:counter.digi_lock
        });
           
        this.setState({
            hidecol:newprops.hide,
        });
    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '')?this.state.active_consignments:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '')?this.state.transit_delay:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                    <h4 className="txt-secondary f40">
                                        
                                        <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                            
                                            <CountUp className="txt-danger" end={(this.state.gps_data_available != '')?this.state.gps_data_available:0}/> 
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                        <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                            <CountUp  className="txt-secondary" end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                        </span>  

                                    </h4>
                                    
                                </div>
                                
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '')?this.state.overspeeding:0}/></span></h4>
                                
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '')?this.state.night_driving:0}/></span></h4>
                                    
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"loading_delay_exception")} >
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.loading_delay != '')?this.state.loading_delay:0}/></span></h4>
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '')?this.state.enroute_stoppage:0}/></span></h4>                                   
                                </div>
                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"digi_lock")}>
                                    <span className="f13"><i className="icofont icofont-lock f22 maroon"></i><br /> DIGI Lock Alert</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.digi_lock != '')?this.state.digi_lock:0}/></span></h4>                                   
                                </div>
                                {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_critical")}>
                                    <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Critical</span>
                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.is_critical != '')?this.state.is_critical:0}/></span></h4>                                   
                                </div> */}
                                {/* <div className="col cursorPointer">
                                    <span className="f13"><i className="icofont icofont-barricade f22 txt-danger"></i><br />Probable Accident</span>
                                    <h4 className="txt-secondary f40">                                            
                                        <span className="counter" onClick={this.onClickCounter.bind(this,"probable_accident_confirm")}>
                                            <CountUp  className="txt-danger" end={(this.state.probableAccidentconfirm != '')?this.state.probableAccidentconfirm:0}/>
                                        </span>
                                        <span className="f24">&nbsp;/&nbsp;</span>
                                            <span className="counter"  onClick={this.onClickCounter.bind(this,"probable_accident")}>
                                                
                                                <CountUp className="txt-danger" end={(this.state.probableAccident != '')?this.state.probableAccident:0}/> 
                                            </span>
                                    </h4>
                                </div>                                 */}
                            </div>                          
                            
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}