import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import Select from 'react-select';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import OnTripMapAction from './ontripmapaction';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import Modal from 'react-responsive-modal';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class EmptyReturnedTrucks extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Empty Return Trucks",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				OnTripMapAction:OnTripMapAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

			},
			fencelist:[],
			startDate:'',
            endDate:'',
            consignee_code:'',
            consigneeCoordinate:[],
            consignments:[],
            mwidth:"col-xl-12 col-lg-12",
			plantgeofences:[],
			tolls : [],
			usermanualmodal:false

		}
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.onChangeHandle = this.onChangeHandle.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		this.logPageView(); // Log page view to GA
		//console.log("Props ", this.props.match)
		var urlpath;
        var dpt;
        this.setState({
            loadshow:'show-m'
        });
		if(this.props.match.path == "/sndemptyreturntrucks")
		{
			urlpath = '/consignments/emptyreturnsgeofencetrucks';
			dpt='SNDG';
		}
		else if(this.props.match.path == "/prtemptyreturntrucks")
		{
			urlpath = '/consignments/emptyreturnsgeofencetrucks';
			dpt='LOG-PRT';
		}
		else if(this.props.match.path == "/tnpemptyreturntrucks")
		{
			urlpath = '/consignments/emptyreturnsgeofencetrucks';
			dpt='LOG-TNP';
		}
		else{
			urlpath = '/consignments';
			dpt='all';
		}
		var rad = '';
		if(this.props.match.path == "/sndactivetruckswithinrange" 
		|| this.props.match.path == "/prtactivetruckswithinrange"
		|| this.props.match.path == "/tnpactivetruckswithinrange")
		{
			rad = 60;
		}
		else
		{
			rad = '';
		}
		//console.log(dpt);
		this.setState({
			deptcode:dpt,
			radius:rad
        });
        
        var reqparams = {
			dept_code:dpt
		}
		//console.log(reqparams)
		redirectURL.post(urlpath,reqparams)
		  .then((response) => {
            var records = response.data;
			console.log("records", records);
			var listarr=[];
			records.map((item) => {
				listarr.push(item);
			})
			//console.log("listarr ",listarr);
			var uniquefencelist = listarr.filter(function (value, index, self) {
				return self.indexOf(value) === index;
			} );
			//console.log("uniquefencelist " , uniquefencelist)
            this.setState({
                rowData:response.data,
				loadshow:'show-n',
				fencelist:uniquefencelist
            });
            this.renderMap()
		  })
		.catch(function (error) {
		    console.log(error);
		});
	
		
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			sliderRouteTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
   
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
   
	selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.fencelist.forEach(item => {
        	if(item != '' && item != null)
    		{
				//items.push(<option key={item} value={item}>{item}</option>); 
				items.push({"value":item,"label":item});
    		}
        	
        });
        return items;
	}

	handlerForm = plant_code => {
		//console.log("Select", event.target.value);
		this.setState(
			{ plant_code },
			() => console.log(`Option selected:`, this.state.plant_code)
		  );

	}

	onChangeHandle(event){
		console.log("event.target.value ",event.target.value)
		let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}

	onShowRouteDiv = (e) => {
		//console.log("Rute params ", e)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		var firstdate = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss');
		var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		

		var reqData = {
            truck_no:e.truck_no,
			gate_out_time:firstdate,
			seconddate:enddate,
			screen:"geofence"
        }
        console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("Props data ", e)

			this.setState({
				loadshow:'show-m'
			});
			if(records.coords.length == 0)
			{
				//console.log("records", records.coords);
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				if(records != '')
				{
					var sdate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
					var edate = getDDMMYYYYHHMMSS(enddate);
					
					//console.log("End Date ", edate)
					this.setState({
						sliderRouteTranslate:"slider-translate-60p",
						showDiv:'show-m',
						mapinfo:records,
						dealer:e.consignee_code,
						consignment_code:"Consignment : "+e.consignment_code,
						maptruckno:e.truck_no,
						routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
						loadshow:'show-n',
						sidebarSubHeader:"Consignment Information",
						overly:'show-m',
						rownode:e,
						leg_no:0,
						tolls : records.tolls
					});
					this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
					//this.renderMap();

				}
			}
			
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}	
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;	
		var maptransporter='';
		console.log("this.state.rownode ", this.state.rownode)
		// if(this.state.rownode.transporter_name)
		// {
		// 	///console.log("map rownode ",this.state.rownode.transporter[0])
		// 	maptransporter=this.state.rownode.transporter_name;
		// }
		console.log("Rendere ",this.state.deptcode)
		let isHiddenSNDG = true;
		let isHiddenTNP = true;
		let isHiddenPRT = true;
		if (this.state.deptcode == "SNDG") {
			// to display specific/distance comumns for SNDG only
			//[ "distance_from_b", "distance_from_f", "distance_from_g", "distance_from_i", "distance_from_m", "distance_from_n" ]
			isHiddenSNDG = false;
		}
		if (this.state.deptcode == "LOG-TNP") {
			// to display specific/distance comumns for TNP only
			//["distance_from_msilg", "distance_from_msilm"]
			isHiddenTNP = false;
		}
		if (this.state.deptcode == "LOG-PRT") {
			// to display specific/distance comumns for PRT only
			// MSILG - Gurgaon, MSILM - Manesar, RPDC-BAN, RPDC-GUJ,	RPDC-NAG, RPDC-SIL
			isHiddenPRT = false;
		}

		const columnwithDefs = [
					
			{
				headerName: "Actions",
				field: "_id",
				colId: "_id",
				width: 80,
				pinned: 'left',
				cellRenderer:'OnTripMapAction',
				filter: false,resizable: true
				
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 150,
				filter: true,
				resizable: true
			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 150,
				filter: true,
				resizable: true
			},

			
			{
				headerName: "Consignee Code",
				field: "consignee_code",
				width: 150,
				filter: true,
				resizable: true
			},

			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 150,
				filter: true,
				resizable: true
			},

			{
				headerName: "Consignee City",
				field: "consignee_city",
				width: 150,
				filter: true,
				resizable: true
			},
			{
				headerName: "Last Known City/Town",
				field: "area",
				width: 200,
			},
	        {
	          headerName: "Last Known State",
	          field: "state",
	          width: 200,
	        },
			{
				headerName: "Left Destination at",
				field: "left_dealer_marked",
				width: 150,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue;
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
			},
			{
				headerName: "Distance from TVP Bangalore (KM)",
				field: "distance_from_b",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from Gurgaon (KM)",
				field: "distance_from_f",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from Gujarat (KM)",
				field: "distance_from_g",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from Siliguri (KM)",
				field: "distance_from_i",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from Manesar (KM)",
				field: "distance_from_m",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from TVP Nagpur (KM)",
				field: "distance_from_n",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenSNDG
			},
			{
				headerName: "Distance from MSIL-G (KM)",
				field: "distance_from_msil-g",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenTNP
			},
			{
				headerName: "Distance from MSIL-M (KM)",
				field: "distance_from_msil-m",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenTNP
			},
			{
				headerName: "Distance from MSIL Gurgaon (KM)",
				field: "distance_from_msilg",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
			{
				headerName: "Distance from MSIL Manesar (KM)",
				field: "distance_from_msilm",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
			{
				headerName: "Distance from RPDC Bangalore (KM)",
				field: "distance_from_rpdc-ban",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
			{
				headerName: "Distance from RPDC Gujarat (KM)",
				field: "distance_from_rpdc-guj",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
			{
				headerName: "Distance from RPDC Nagpur (KM)",
				field: "distance_from_rpdc-nag",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
			{
				headerName: "Distance from RPDC Siliguri (KM)",
				field: "distance_from_rpdc-sil",
				width: 150,
				filter: true,
				resizable: true,
				hide: isHiddenPRT
			},
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:'0px !important'

		}
	var hText = 'All';
	if(this.state.deptcode == 'SNDG')
	{
		hText = 'Sales and Dispatch'
	}

	if(this.state.deptcode == 'LOG-PRT')
	{
		hText = 'Spare Parts'
	}
	if(this.state.deptcode == 'LOG-TNP')
	{
		hText = 'Train and Production'
	}
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                               
                                <div className="row col-xl-12 col-lg-12">
								    <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
										<AgGridReact
											modules={this.state.modules}
											columnDefs={columnwithDefs}
											defaultColDef={this.state.defaultColDef}
											rowData={this.state.rowData}
											enableCharts={false}
											enableRangeSelection={false}
											autoGroupColumnDef={this.state.autoGroupColumnDef}
											onGridReady={this.onGridReady}
											onGridState={this.onGridState}
											statusBar={this.state.statusBar}
											sideBar={this.state.sideBar}
											paginationPageSize={this.state.paginationPageSize}
											pagination={true}
											enableRangeSelection={true}
											frameworkComponents={this.state.frameworkComponents}
											onCellClicked={this.popmarker}
											gridOptions={{
												context: { componentParent: this }
											}}
										/>
									</div>
										
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} tolls={this.state.tolls} rownode={this.state.rownode} mapFor={"consignment"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.rownode.left_dealer_marked != '')?getDDMMYYYYHHMMSS(this.state.rownode.left_dealer_marked):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
					<div className={"dataLoadpage " +(this.state.loadshow)}>
					</div>
					<div className={"dataLoadpageimg " +(this.state.loadshow)}>
						<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
					</div>
					<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Empty Returned Trucks</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility provides details about those vehicles which have delivered their consignments and are now returning back to its original destination without any MSIL load. One can find details about the empty trips based on the source of origin and the time at which the vehicle had left the original destination.
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>
			</div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}