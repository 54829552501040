import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
// import ShowTruckConsignments from "./showtruckconsignments.js"
import RevokSaveBilling from "./checkboxtruckconsignments.js";
import Showuptimelegs from "./showgpsbillinguptimelegs";
import Modal from 'react-responsive-modal';
import BillingLogs from './gpsbillinglogsComponent';
import CSVFileValidator from 'csv-file-validator';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class GPSBilling extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            showreload:false,
            basicTitleReload:"",
            basicTypeReload:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                // showtruckconsignments:ShowTruckConsignments,
                RevokSaveBilling:RevokSaveBilling,
                Showuptimelegs:Showuptimelegs
			},
            headerHeight:60,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            billingtrucks:[],
            pagerest:0,
            approveby:"",
            approvedon:"",
            hidebuttons:0,
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false,
            reason:'',
            approveenreason:'',
            revertreason:'',
            approveitemreason:'',
            disapprovereason:'',
            dispproveitem:"",
            approveitem:"",
            billingRowData:"",
            screen:'',
            msilapprover:'',
            revertapprover:'',
            acbtntrucks:"btn-danger",
            acbtntrips:"btn-default",
            columnstrucks:1,
            columnstrips:0,
            truckslist:[],
            rowTripData:[],
            rowTruckData:[],
            sliderBulk:"",
            selectmonth:{"value":"",label:"Month"},
            transporter:{"value":"",label:"All"},
            transporters:[],
            smonth:{"value":"",label:"Month"},
            showbulk:"show-m",
            tottalDistance:0,
            billmonthtext:"",
            sliderUptime:"",
            uptimeRowData:[],
            uptimetruckno:"",
            billstart:"",
            billend:""
        }
        this.onLoadBillingData = this.onLoadBillingData.bind(this);
        this.onClickCheckboxItem = this.onClickCheckboxItem.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.resetPaginationSelection = this.resetPaginationSelection.bind(this);
        this.revertSavedItem = this.revertSavedItem.bind(this);
        this.approveSavedItem = this.approveSavedItem.bind(this);
        this.onClickShowUpTimeLegs = this.onClickShowUpTimeLegs.bind(this);
        this.onClickDonwloadUpTimeLegs = this.onClickDonwloadUpTimeLegs.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var prevmonth = moment.parseZone().subtract(1, 'months').format("YYYY-MM")
        var currentmonth = moment.parseZone().format("YYYY-MM")
        var prevmm = moment.parseZone().subtract(1, 'months').format("MM")
        var monthc = moment.parseZone().format("MM")
        // var currentyy = moment.parseZone().format("YYYY")

        var mnth = parseInt(this.props.match.params.month)
        if(mnth < 10)
        {
            monthc = "0"+mnth;
        }
        else
        {
            monthc = mnth;
        }
        var currentmm = monthc
        var currentyy = this.props.match.params.year


    //    console.log("prevmonth ",prevmonth)
       console.log("currentmonth ",currentmm)
    //    var startDate = prevmonth+"-"+16
    //    var endDate = currentmonth+"-"+15
       
       var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
        'September', 'October', 'November', 'December'] 
        console.log("mopts ", mopts[parseInt(currentmm)-1])
       this.setState({
            // startDate:startDate,
            // endDate:endDate,
            year:currentyy,
            month:currentmm,
            smonth:{"value":currentmm, "label":mopts[parseInt(currentmm)-1]},
            billmonthtext:mopts[parseInt(currentmm)-1]+" - "+currentyy
        })
        redirectURL.post("/consignments/transporters")
        .then((response) => {
            console.log("response ", response.data)
            var transporters = response.data
            // console.log("devices ", devices)
            this.setState({
                transporters:transporters
            })
            
        })
        var parameters = {
            // startDate:startDate,
            // endDate:endDate,
            year:currentyy,
            month:currentmm.toString()
        }
        // redirectURL.post("/consignments/apigpsbillingtrucks",parameters)
        // .then((response) => {
        //     // console.log("response ", response.data.devices)
        //     var devices = response.data.devices
        //     // console.log("devices ", devices)
        //     this.setState({
        //         // rowData:devices
        //     })
            
        // })
        this.onLoadBillingData(parameters)
    }

    onLoadBillingData(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/consignments/gpsbillingtrucks",parameters)
        .then((response) => {
            // console.log("response ", response.data.devices)
            var records = response.data.devices
            console.log("records ", records)
            var flag = 0
            var tper = 0
            var avguptime = 0
            var billstart="";
            var billend="";
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.confirm_status == 1)
                    {
                        flag = 1
                    }
                    tper = parseFloat(tper)+parseFloat(item.uptime_percentage)
                })
                avguptime = tper/(records.length)
                billstart = records[0].billing_start
                billend = records[0].billing_end
            }
            if(flag == 1)
            {
               var showbulk = "show-n"
            }
            else
            {
               var showbulk = "show-m"
            }

            var pinnedBottomRowData = {}
            pinnedBottomRowData['truck_no'] = "";
            pinnedBottomRowData['transporter_code'] = "";
            pinnedBottomRowData['transporter_name'] = "";
            pinnedBottomRowData['device_registered_on'] = "";
            pinnedBottomRowData['billing_start'] = "";
            pinnedBottomRowData['billing_end'] = "";
            pinnedBottomRowData['billing_days'] = "";
            pinnedBottomRowData['uptime_days'] = "Total (%)";
            pinnedBottomRowData['uptime_percentage'] = avguptime;
            pinnedBottomRowData['is_part_msil_fleet'] = "total";
            pinnedBottomRowData['billing_status'] = "total";
            // console.log("avguptime " , avguptime)
            this.setState({
                rowData:records,
                tottalDistance:[pinnedBottomRowData],
                loadshow:"show-n",
                showbulk:showbulk,
                billstart:billstart,
                billend:billend
            })
            
        })
    }
    
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
    
    
    onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
        
        this.gridApi1.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi1.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState1 = () =>{
		 console.log(this.gridApi1);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi1.getFilterModel();
           this.gridApi1.setFilterModel(window.filterState);
           

           
        
        //
    }
    
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderBulk:"",
            sliderUptime:""
        });
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    
    closeAlertReload = async () => {
        await this.setState({
            showreload: false
        });
        await window.location.reload()
    }
    changeArrayHandler = month => {
        this.setState(
            { month }
        );
        
    }
    monthoptions(){
        var months = [
           // {value:'all',label:'All'},
            {value:'01',label:'Jan'},
            {value:'02',label:'Feb'},
            {value:'03',label:'Mar'},
            {value:'04',label:'Apr'},
            {value:'05',label:'May'},
            {value:'06',label:'Jun'},
            {value:'07',label:'Jul'},
            {value:'08',label:'Aug'},
            {value:'09',label:'Sep'},
            {value:'10',label:'Oct'},
            {value:'11',label:'Nov'},
            {value:'12',label:'Dec'}
        ]

        return months;
    }
    
    changeYearArrayHandler = year => {
        this.setState(
            { year }
        );
        
    }
    yearoptions(){
        var years = []
        var cyear = moment.parseZone().format("YYYY")
        var defaultyear = 2020
        //console.log("cyear ", cyear)
        for(var i=defaultyear;i<=cyear;i++)
        {
            //console.log(i)
            years.push({value:i,label:i})
        }    
       // console.log("years ", years)
        return years;
    }

    approveAllTrucks(){
        this.setState({
            open:true
        })
    }
    approveAllTrucksAction(){
        this.setState({
            loadshow:"show-m"
        })
        var selecteditems = []
        this.gridApi.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })
        // console.log("selecteditems ", selecteditems)
        var cnf = window.confirm("Are you sure you want to approve? Data cannot be modified once approved.")
        //console.log("cnf ", cnf)
        if(cnf == true)
        {
            if(this.state.reason != "" && this.state.reason.trim() != "")
            {
                var params = {
                    selecteditems:selecteditems,
                    month:this.state.month,
                    year:this.state.year,
                    reason:this.state.reason,
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                // console.log("params ", params)
                redirectURL.post("/consignments/approveConfirmGPSBillingTrucks", params)
                .then(async (response) => {
                    
                    if(response.data.status == "success")
                    {
                        await  this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully approved",
                            open:false,
                            reason:"",
                            loadshow:"show-n"
                        })
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year
                        }
                        this.onLoadBillingData(parameters)
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            open:false,
                            reason:"",
                            loadshow:"show-n"
                        })
                        
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:'danger',
                    loadshow:"show-n"
                })
            }
            
        }
    }

    revertSavedItem(item)
    {
        this.setState({
            disapproveopen:true,
            dispproveitem:item
        })
    }
    revertSavedItemAction(){
        var item = this.state.dispproveitem
        var cnf = window.confirm("Are you sure you want to disapprove item ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            if(this.state.disapprovereason != "" && this.state.disapprovereason.trim() != "")
            {
                var params = {
                    revertdata:item.data,
                    reason:this.state.disapprovereason,
                    month:this.state.month,
                    year:this.state.year,
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/gpsrevertSaveItem", params)
                .then(async (response) => {
                    //console.log(response.data)
                    if(response.data.status == "success")
                    {
                        await this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully disapproved item",
                            disapprovereason:"",
                            disapproveopen:false
                        })
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year
                        }
                        this.onLoadBillingData(parameters)
                       
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            disapprovereason:"",
                            disapproveopen:false
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:"danger"
                })
            }
            
            
        }
    }
   

    onClickCheckboxItem = async (props) =>{
        console.log(props)
    }
    onPaginationChanged = (param) => {
        //console.log("param ", param)
        this.resetPaginationSelection();
    }
    resetPaginationSelection = (e) =>
    {
        //e.api.deselectAll();

        //Initialize pagination data
        let paginationSize = e.api.paginationGetPageSize();
        let currentPageNum = e.api.paginationGetCurrentPage();
        let totalRowsCount = e.api.getDisplayedRowCount();

        //Calculate current page row indexes
        let currentPageRowStartIndex = (currentPageNum * paginationSize);
        let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
        if(currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

        for(let i = 0; i < totalRowsCount; i++)
        {
            //Set isRowSelectable=true attribute for current page rows, and false for other page rows
            let isWithinCurrentPage = (i >= currentPageRowStartIndex && i < currentPageRowLastIndex);
            e.api.getDisplayedRowAtIndex(i).setRowSelectable(isWithinCurrentPage);
        }

    };

    approveSavedItem(item)
    {
        this.setState({
            apprvopen:true,
            approveitem:item
        })
    }
    approveSavedItemAction(){
        var item = this.state.approveitem      
        var cnf = window.confirm("Are you sure you want to approve item ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            if(this.state.approveitemreason != "" && this.state.approveitemreason.trim() != "")
            {
                var params = {
                    selecteditems:[item.data],
                    reason:this.state.approveitemreason,
                    month:this.state.month,
                    year:this.state.year,
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/approveGPSBillingTrucks", params)
                .then(async (response) => {
                    //console.log(response.data)
                    if(response.data.status == "success")
                    {
                        await this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully approved items",
                            approveitemreason:"",
                            apprvopen:false
                        })
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year
                        }
                        this.onLoadBillingData(parameters)
                       
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            approveitemreason:"",
                            apprvopen:false
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:'infdanger'
                })
            }
            
        }
    }
    revertMsilApproval(){
        this.setState({
            revertopen:true
        })
    }
    revertMsilApprovalAction(){
        var cnf = window.confirm("Are you sure you want to revert items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            console.log("Success revert")
        }
    }

    approvalEnmovil(){
        this.setState({
            approveenopen:true
        })
    }
    approvalEnmovilAction(){
        var cnf = window.confirm("Are you sure you want to approve items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            console.log("Success enmovil")
        }
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	    this.setState({ 
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false, 
        });
     };
    

	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    
    onClickBulkUpdate(){
        this.setState({
            sliderBulk:'slider-translate',
            overly:"show-m"
        })
    }

    
	changeFileHandler = async (e) => {
		var dt = '';
		
		const config = {
			headers: [
				{ 
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                }
                
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
            var trukss = csvData.data;
            console.log("trukss ",trukss)
            var truckarr = []
            if(trukss.length > 0)
            {
                trukss.map((item) => {
                    var truckno = item.truck_no.trim();
                    truckarr.push({"truck_no":truckno})
                })
            }
			this.setState({
				// csvcontent:csvData.data
				csvcontent:truckarr
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
    }
    
    formBulkUpload(event){
        event.preventDefault();
        var records = this.state.csvcontent
        
        var month = this.state.month
        // if(month.value == "")
        // {
        //     this.setState({
        //         show:true,
        //         basicType:"danger",
        //         basicTitle:"Please select month"
        //     })
        // }
        // else
        // {
            var params = {
                rowitems:records,
                month:month,
                // year:this.state.year
            }
            // console.log("params here ",params)
            redirectURL.post("/consignments/uploadgpsmsilfleet",params)
            .then((response) => {
                // console.log("resov ", response.data)
                if(response.data.status == "success")
                {
                    this.setState({
                        show:true,
                        basicType:"success",
                        basicTitle:"Successfully updated",
                        sliderBulk:"",
                        csvcontent:[],
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                    var parameters = {
                        // startDate:this.state.startDate,
                        // endDate:this.state.endDate,
                        year:this.state.year,
                        month:this.state.month
                    }
                    this.onLoadBillingData(parameters)
                }
                else if(response.data.status == "notexists")
                {
                    this.setState({
                        show:true,
                        basicType:"danger",
                        basicTitle:"Some of truck numbers are not valid."
                    })
                }
                else if(response.data.status == "nodata")
                {
                    this.setState({
                        show:true,
                        basicType:"danger",
                        basicTitle:"No data found."
                    })
                }
                else{
    
                    this.setState({
                        show:true,
                        basicType:"danger",
                        basicTitle:"Failed to updated"
                    })
                }
            })
        // }
        
    }

    
	changeMonthHandler = selectmonth =>{
        this.setState(
	      { selectmonth },
	      () => console.log(`Month Option selected:`, this.state.selectmonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectMonths(){
        var items = []
        items.push({value:'',label:'Month'});
        items.push({value:'01',label:'Jan'});
        items.push({value:'02',label:'Feb'});
        items.push({value:'03',label:'Mar'});
        items.push({value:'04',label:'Apr'});
        items.push({value:'05',label:'May'});
        items.push({value:'06',label:'Jun'});
        items.push({value:'07',label:'Jul'});
        items.push({value:'08',label:'Aug'});
        items.push({value:'09',label:'Sep'});
        items.push({value:'10',label:'Oct'});
        items.push({value:'11',label:'Nov'});
        items.push({value:'12',label:'Dec'}); 
		return items
    }
    
	changeMonthBulkHandler = smonth =>{
        this.setState(
	      { smonth },
	      () => console.log(`Month Option selected:`, this.state.smonth)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
	changeTransporterHandler = transporter =>{
        this.setState(
	      { transporter },
	      () => console.log(`Month Option selected:`, this.state.transporter)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    selectTransporters(){
        var items = []
        items.push({value:'',label:'All'});
        var transporters = this.state.transporters
        if(transporters.length > 0)
        {
            transporters.map((item) => {
                items.push({
                    value:item.transporter_code,
                    label:item.transporter_name
                })
            })
        }
		return items
    }
    onClickFilterData(){
        this.setState({
            loadshow:'show-m',
            
        })
        var month = this.state.month
        var transporter = this.state.transporter
        // if(month.value == "")
        // {
        //     this.setState({
        //         show:true,
        //         basicTitle:"Please select month",
        //         basicType:"danger",
        //         loadshow:'show-n',
        //     })
        // }
        // else{
            if(transporter.value == "")
            {
                var transporter_code = ""
            }
            else{
                var transporter_code = transporter.value
            }
            var parameters = {
                month:month,
                transporter_code:transporter_code
            }
            redirectURL.post("/consignments/gpstransportersdata",parameters)
            .then((response) => {
                var records = response.data.records;
                
                this.setState({
                    rowData:records,
                    loadshow:'show-n',

                })
            })
        // s}
    }

    onClickShowUpTimeLegs = async (rownode) =>{
        // console.log("rownde ", rownode)
       
        await this.setState({
                
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName:"Total Packet Sent",
                            field:"count",
                            width:160,
                        },
                        {
                            headerName:"Report Date",
                            field:"report_date",
                            width:160,
                            valueGetter:function(params){
                                try{
                                    if(params.data.report_date != "" && params.data.report_date != undefined){
                                        return getHyphenDDMMMYYYYHHMM(params.data.report_date);
                                    }
                                    else
                                    {
                                        return "";
                                    }
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        
                    ]							
                },
                getDetailRowData: async function(params) {
                //    console.log("params ", params)
                   var sdate = getHyphenYYYYMMDDHHMMSS(params.data.billing_start);
                   var edate = getHyphenYYYYMMDDHHMMSS(params.data.billing_end);
                   var truck_no = params.data.truck_no;
                  
                    var paras = {
                        start_date:sdate,
                        end_date:edate,
                        truck_no:truck_no
                    }
                    redirectURL.post("/consignments/gpsbillinguptimelegs",paras)
                    .then((response) => {
                        console.log("response ", response.data)
                        var records = response.data.records;
                        params.successCallback(records)
                    })
                    .catch(function(e){
            
                    })
                    
                }
            }
        });
        
        if(rownode.column.colDef.field == 'uptime_days')
        {
            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    
    }
    onClickDonwloadUpTimeLegs= async (rownode) =>{
        this.setState({
            loadshow:"show-m"
        })
        // console.log("rownde ", rownode)
        var sdate = getHyphenYYYYMMDDHHMMSS(rownode.data.billing_start);
        var edate = getHyphenYYYYMMDDHHMMSS(rownode.data.billing_end);
        var truck_no = rownode.data.truck_no;
        
        var paras = {
            start_date:sdate,
            end_date:edate,
            truck_no:truck_no
        }
        redirectURL.post("/consignments/gpsbillinguptimelegs",paras)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                sliderUptime:"slider-translate-65p",
                uptimeRowData:records,
                overly:"show-m",
                loadshow:"show-n",
                uptimetruckno:truck_no
            })
        })
        .catch(function(e){

        })
                  
    
    }
    onClickUpTimeReport= async (rownode) =>{
        this.setState({
            loadshow:"show-m"
        })
        // console.log("rownde ", rownode)
        var sdate = getHyphenYYYYMMDDHHMMSS(this.state.billstart);
        var edate = getHyphenYYYYMMDDHHMMSS(this.state.billend);
        
        var paras = {
            start_date:sdate,
            end_date:edate
        }
        redirectURL.post("/consignments/gpsbillinguptimereport",paras)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                sliderUptime:"slider-translate-65p",
                uptimeRowData:records,
                overly:"show-m",
                loadshow:"show-n"
            })
        })
        .catch(function(e){

        })
                  
    
    }
    render(){
        const { open } = this.state;
        const { revertopen } = this.state;
        const { approveenopen } = this.state;
        const { apprvopen } = this.state;
        const { disapproveopen } = this.state;
       

        var columnwithDefs = [
            { 
                headerName: "Truck No",
                field: "truck_no", 
                width:120,
                pinned:"left",
                resizable: true,
            },
            { 
                headerName: "Transporter Code",
                field: "transporter_code", 
                width:100,
                pinned:"left",
                resizable: true,
            },
            { 
                headerName: "Transporter Name",
                field: "transporter_name", 
                width:150,
                resizable: true,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.registereddevices.length > 0 )
                //         {
                //             return params.data.registereddevices[0].transporter_name;
                //         }
                //         else
                //         {
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            { 
                headerName: "Date Of Installation",
                field: "device_registered_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.device_registered_on != "" && params.data.device_registered_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_registered_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Voice Box Installed On",
                field: "voicebox_installed_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.voicebox_installed_on != "" && params.data.voicebox_installed_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.voicebox_installed_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Freewheeling Installed On",
                field: "device_registered_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.device_registered_on != "" && params.data.device_registered_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_registered_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            { 
                headerName: "Billing Start Date",
                field: "billing_start", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_start != "" && params.data.billing_start != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.billing_start);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            { 
                headerName: "Billing End Date",
                field: "billing_end", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_end != "" && params.data.billing_end != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.billing_end);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Total Billing Days",
                field: "billing_days", 
                width:90,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_days != "" && params.data.billing_days != undefined )
                        {
                            return params.data.billing_days;
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Uptime Days",
                field: "uptime_days", 
                width:90,
                resizable: true,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.uptime_days != "" && params.data.uptime_days != undefined )
                //         {
                //             return params.data.uptime_days;
                //         }
                //         else
                //         {
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // },
                cellRendererSelector:function(params){
                    if(params.data.uptime_days != "" && params.data.uptime_days != undefined )
                    {
                        if(params.data.uptime_days != 'Total (%)')
                        {
                            var rendComponent = {
                                component: 'Showuptimelegs'
                            };
                            return rendComponent;
                        }
                        else{
                            return "";
                        }
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            { 
                headerName: "Uptime (%)",
                field: "uptime_percentage", 
                width:100,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.uptime_percentage != "" && params.data.uptime_percentage != undefined )
                        {
                            return params.data.uptime_percentage.toFixed(2);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Part Of MSIL Billing",
                field: "is_part_msil_fleet", 
                width:90,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.is_part_msil_fleet != "" && params.data.is_part_msil_fleet != undefined )
                        {
                            if(params.data.is_part_msil_fleet != 'total')
                            {
                                return params.data.is_part_msil_fleet;
                            }
                            else
                            {
                                return "";
                            }
                                                        
                        }
                        else
                        {
                            return "No";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            // { 
            //     headerName: "Eligible for Billing",
            //     field: "eligible_for_billing", 
            //     width:90,
            //     resizable: true,
                
            // },
            {
                headerName:"Status",
                field:"billing_status",
                width:100,
                pinned:"right",
                cellRendererSelector:function(params){
                    if(params.data.billing_status != "total")
                    {
                        var rendComponent = {
                            component: 'RevokSaveBilling'
                        };
                        return rendComponent;
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            // {
            //     headerName:"Transporter Status",
            //     field:"tpt_confirm_status",
            //     width:100,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.tpt_confirm_status != "" && params.data.tpt_confirm_status != undefined)
            //             {
            //                 if(params.data.tpt_confirm_status == 1)
            //                 {
            //                     return "Confirmed";
            //                 }
            //                 else{
            //                     return "Pending";
            //                 }
            //             }
            //             else{
            //                 return "";
            //             }
            //         }
            //         catch(e)
            //         {
            //             return "";
            //         }
            //     }
            // },
                                
        ]
    
        var uptimecolumnsDefs = [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140,
            },
            {
                headerName:"Transporter Code",
                field:"transporer_code",
                width:180,
            },
            
            {
                headerName:"Total Packet Sent",
                field:"count",
                width:160,
            },
            {
                headerName:"Report Date",
                field:"report_date",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.report_date != "" && params.data.report_date != undefined){
                            return getHyphenDDMMMYYYYHHMM(params.data.report_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            {
                headerName:"Manual Insert",
                field:"manual_insert",
                width:120,
                valueGetter:function(params){
                    try{
                        if(params.data.manual_insert == 1)
                        {
                            return "Yes";
                        }
                        else
                        {
                            return "No";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Follow Up Status",
                field:"manual_entry_reason",
                width:160,
            },
            
            {
                headerName:"Manual Entry Reason",
                field:"follow_up_status",
                width:250,
            },
        ]
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.showreload}
                    type={this.state.basicTypeReload}
                    title={this.state.basicTitleReload}
                    onConfirm={this.closeAlertReload}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-body pt-15px">
                                <form method="POST" className="row col-xl-12 col-lg-12">
                                    
                                    {/* <div className="form-group col-xl-4 col-lg-4">
                                    
                                        <lable>Select Month:</lable>
                                        <Select placeholder={"Month"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.selectmonth} 
                                        className="border-radius-0" 
                                        onChange={this.changeMonthHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectMonths()} 
                                        required />
									
                                    </div> */}
                                    <div className="form-group col-xl-4 col-lg-4">
                                    
                                        <lable>Select Transporter:</lable>
                                        <Select placeholder={"All"} 
                                        // closeMenuOnSelect={false} 
                                        value={this.state.transporter} 
                                        className="border-radius-0" 
                                        onChange={this.changeTransporterHandler.bind(this)} 
                                        style={{borderRadius:"0px"}} 
                                        options={this.selectTransporters()} 
                                        required />
									
                                    </div>
                                    <div className="form-group col-xl-4 col-lg-4">
                                    
                                       <button 
                                        type="button" 
                                        className="btn btn-warning mt-22p"
                                        onClick={this.onClickFilterData.bind(this)}
                                        >Submit</button>
									
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>GPS Billing - {this.state.billmonthtext}</span>
                                
                                    <a href="/gpsbillingdashboard" className={"btn btn-primary float-right f12 "}> Back</a>
                                    <button type="button" onClick={this.onClickBulkUpdate.bind(this)} className={"btn btn-danger float-right f12 "+(this.state.showbulk)}> Upload MSIL Billing List</button>
                                    
                                    <button type="button" onClick={this.onClickUpTimeReport.bind(this)} className={"btn btn-warning float-right f12 "}> Download Uptime Report</button>
                                    
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                                
                                <div className="row">
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}   
                                            headerHeight={this.state.headerHeight}                                         
                                        />
                                    </div> 
                                    <div className="col-xl-4 col-lg-4">
                                        {/* {(this.state.approveby != "" || this.state.approvedon != "")?
                                            <span>
                                                Approved By: <span style={{fontWeight:"600"}}>{this.state.approveby}</span>
                                                <br />Approved On: <span style={{fontWeight:"600"}}>{getHyphenDDMMMYYYYHHMM(this.state.approvedon)}</span>
                                            </span>
                                        :""} */}
                                        
                                    </div>

                                    <div className="col-xl-8 col-lg-8" style={{textAlign:"right"}}>
                                        {/* <span>
                                            <button type="button" className="btn btn-danger" onClick={this.approvalEnmovil.bind(this)}>Approve (Enmovil)</button>
                                            <button type="button" className="btn btn-warning" onClick={this.revertMsilApproval.bind(this)}>Revert MSIL Approval</button>
                                        </span> */}
                                    
                                        <button type="button" className="btn btn-success" onClick={this.approveAllTrucks.bind(this)}>Approve (MSIL)</button>
                                        
                                    </div>
                                    
                                       
                                </div>

                                <div className="row mt-20p">
                                    <h5>Billing Log Report:</h5>
                                   <BillingLogs 
                                        dept_code={this.state.dept_code}
                                        screen={this.state.screen}
                                        month={this.state.month}
                                        year={this.state.year}
                                   />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<Modal open={open} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="reason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveAllTrucksAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={revertopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="revertreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertMsilApprovalAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={approveenopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="approveenreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approvalEnmovilAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={apprvopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="approveitemreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={disapproveopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="disapprovereason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>

                
                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkUpload.bind(this)}>
                            {/* <div className="form-group mt-20p">
								<label className="">Select Month</label> 
                                <Select 
                                name="smonth" 
                                onChange={this.changeMonthBulkHandler.bind(this)}  
                                className="" 
                                value={this.state.smonth}
                                options={this.selectMonths()}
                                />
							</div> */}
                            <div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_bulk_trucks.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUptime)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Uptime  {this.state.uptimetruckno}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid1" style={{ height: "90vh",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={uptimecolumnsDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptimeRowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}   
                                        headerHeight={this.state.headerHeight}                                         
                                    />
                                </div> 
                            </div>
                        </div>
					</div>
				</div>


            </div>
    )}       
} 