import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import ActionItem from './actionItemComponent';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";

var redirectURL = require('../redirectURL');
var selectRowItemFromAgGrid='';
var url=redirectURL;
export default class GridReportComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				 // editable: true,
				  resizable: true
      		},
			rowData: null,

			//editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			frameworkComponents: {
				actionItem:ActionItem,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2
		};
        
	}
      
    onCellClicked(rownode){
		console.log("rownode", rownode);
		this.props.gridOptions.context.componentParent.state.loadshow='show-m';
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.first_instance);
    	var first_instance = d.getFullYear()+"-"+d.getMonth() +1+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    	var recent_instance = r.getFullYear()+"-"+r.getMonth() +1+"-"+r.getDate()+" "+r.getHours()+":"+((r.getMinutes() < 10) ? "0"+r.getMinutes():r.getMinutes())+":"+((r.getSeconds() < 10) ? "0"+r.getSeconds():r.getSeconds());
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var rintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:rintance,
		}
		
		this.props.gridOptions.context.componentParent.onCloseSidebarDiv();
		this.props.gridOptions.context.componentParent.showLoader();
        redirectURL.post('/reports/overspeedgpsroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    //console.log(response.data);
		   this.setState({
			   rowData:response.data,
			   loadshow:'show-n'
		   });

			this.props.gridOptions.context.componentParent.hideLoader();
		   this.props.gridOptions.context.componentParent.state.overspeedmapdata=rownode;
		   this.props.gridOptions.context.componentParent.state.overspeermapRoutes=response.data
		   	this.props.gridOptions.context.componentParent.onShowSidebarDiv();
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
    }
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
       
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
           
		  
		   
	 }
	 

    render(){
    	
        
        return ( 
        		<div className="row">
			
	                 <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
	      		          <AgGridReact
	      		            modules={this.state.modules}
	      		            columnDefs={this.props.columnwithDefs}
	      		            defaultColDef={this.state.defaultColDef}
	      		            rowData={this.props.rowData}
	      	                rowSelection={this.state.rowSelection}
							enableCharts={false}
							//  paginationAutoPageSize={true}
							frameworkComponents={this.state.frameworkComponents}
							paginationPageSize={this.state.paginationPageSize}
	      		            pagination={true}
	      		            enableRangeSelection={false}
	      		            onGridReady={this.onGridReady}
	      		            onGridState={this.onGridState}
	      		           	floatingFilter={true}
	      		            gridOptions={{
								context: { componentParent: this }
							}}
	      		            //onCellClicked={this.onCellClicked.bind(this)}
	      		          />
	  		        </div>
      		    </div>
                    
			);
		}
}


const CustomBtn = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.handleStateChange(props.data);
    };

    return (
        <div>
            <button onClick={handleClick}>
               Change State
            </button>
        </div>
    );
};








