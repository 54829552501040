import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY, 
    getHyphenDDMMMYYYYHHMM} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnChartLoadingDashboard from './columnchartloadingdashboard';
import ScatterChartLoadingDashboard from './scatterchartloadingdashboard';
import ColumnDrilldownLoadingDashboard from './columndrilldownloading';
import LineLoadingDashboard from './lineloading';
import LineLoadingTimeSlot from './lineloadingtimeslot';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

export default class Columnchart extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                enablePivot: true,
                pivot : true,
                enableValue: true,
                enableRowGroup: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            gpsrowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'',
            departmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-m',
            showgpschart:'show-n',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            timeslotdata:[],
            timeslotgpsdata:[],
            monthlydata:[],
            monthlygpsdata:[],
            timeslotgateout:[],
            timeslotgpsgateout:[],
            defaultsdate:'',
            defaultedate:'',
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"Load Dashboard",
            eventGridAction: "gridAction",
            loadingData : [],
            items_options_list : [],
            selected_items_list : {"label":"ALL","value":"ALL"},
            monthly_transporter_loading_data:[],
            monthly_transporter_gps_loading_data:[],
            transporterLoadingData : []
        }
        this.formatMonthlyTransLdData = this.formatMonthlyTransLdData.bind(this);
    }

    componentWillReceiveProps(newprops){
        if(newprops.dept_code != this.props.dept_code)
        {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({eventAction: eventAction});

            this.setState({
                loadshow:'show-m',
                overly:'show-m',
                transporter:{"value": "all", "label":'All'},
                location:{"value": "all", "label":'All'}
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            var dept_code=newprops.dept_code;
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            var preqparams = {
                dept_code:dept_code
            }
            this.setState({
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                defaultsdate:sdate,
                defaultedate:edate,
                locations:[],
                transporters:[]
            })
            var preqparams = {
                dept_code:dept_code
            }
            if(dept_code == "LOG-TNP")
            {
                redirectURL.post("/dashboard/getDistinctTNPItems",preqparams) 
                .then((response) => {
                    console.log(response.data)
                    var items_options_list = [];
                    items_options_list.push({"label":"ALL","value":"ALL"})
                    response.data.map(function(e){
                        if(e != "")
                        {
                            items_options_list.push({"label":e,"value":e})
                        }
                        
                    });
                    this.setState({
                        items_options_list : items_options_list
                    })
                }).catch((e)=>{})
            }
            
            redirectURL.post("/consignments/plants",preqparams)    
            .then((response) => {
              var records = response.data;
              //console.log("records ", records)
              var locations = [];
              if(records.length >0)
              {
                  records.map((item) => {
                    locations.push({
                        code:item.plant_code,
                        label:item.plant_name,
                    })
                  })
              }
              this.setState({
                  locations:locations
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
            redirectURL.post("/masters/transportersbydept",{
                deptcode:dept_code
            })    
            .then((response) => {
              var records = response.data;
              var transporters = [];
              if(records.length >0)
              {
                  records.map((item) => {
                    transporters.push({
                        code:item.transporter_code,
                        label:item.transporter_name,
                    })
                  })
              }
              this.setState({
                transporters:transporters
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            });
    
            //getPlantWiseLoadingDelayAnalysis
            //console.log(" this.state.transporter ",this.state.transporter)
            redirectURL.post("/dashboard/getLoadingData",{
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                transporter:[{"value": "all", "label":'All'}],
                location:[{"value": "all", "label":'All'}]
            })    
            .then((response) => {
                var records = response.data;
                console.log(records,"loading data");
                this.setState({
                    loadingData : records
                    })
            }).catch(function(e){
                console.log("Error ",e)
            })

            redirectURL.post("/dashboard/plantwiseloading",{
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                transporter:[{"value": "all", "label":'All'}],
                location:[{"value": "all", "label":'All'}]
            })
            .then((response) => {
              var records = JSON.parse(response.data.plant_wise_ld_data);
             // console.log("records ",records)
              //var scaterrecords = JSON.parse(response.data.complete_data);
              var planttrancsrecords = JSON.parse(response.data.plant_trans_wise_ld_data);
              var transrecords = JSON.parse(response.data.trans_wise_ld_data);
              //console.log("records ", records);
              var deptcodedata = groupBy(records, rdata => rdata.dept_code);
              var ldcounts = response.data.ld_counts;
              var top10data = JSON.parse(response.data.top_10_ld_data);
              var top10gpsdata = JSON.parse(response.data.top_10_gps_ld_data);
              var timeslotdata = JSON.parse(response.data.time_slot_ld_data); 
              var monthlydata = JSON.parse(response.data.monthly_ld_data)
              var timeslot_plant_gate_out = JSON.parse(response.data.time_slot_plant_gate_out_counts);
              var time_slot_plant_outside_fence_counts = JSON.parse(response.data.time_slot_plant_outside_fence_counts);

              if(this.state.dept_code == "LOG-TNP")
              {
                var transporterLoadingData = JSON.parse(response.data.tnp_custom_report); 
                var monthly_trans_ld_data = JSON.parse(response.data.monthly_trans_ld_data);
                var monthlytransportdata = this.formatMonthlyTransLdData(monthly_trans_ld_data);
              }
              
              //console.log("timeslotdata ", timeslotdata)
                
                var departmentarray=[];
                deptcodedata.forEach((item, key) =>{
                    //console.log("item ", key)
                    if(key == dept_code)
                    {
                        var serr = [];
                        var categories = [];
                        var loadingarr = [];
                        var gpsloadingarr = [];
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                if(itm.loading_delay_in_hrs > 0 || itm.gps_loading_delay_time_in_hrs > 0)
                                {
                                    categories.push(itm.consigner_code)
                                }
                                if(itm.loading_delay_in_hrs > 0)
                                {
                                    loadingarr.push(itm.loading_delay_in_hrs);
                                }
                                if(itm.gps_loading_delay_time_in_hrs > 0)
                                {
                                    gpsloadingarr.push(itm.gps_loading_delay_time_in_hrs);
                                }
                            })
                        }
                        departmentarray.push({
                            key:key,
                            series:[{
                                name: 'MSIL Data',
                                data: loadingarr
                        
                            }, {
                                name: 'GPS Data',
                                data: gpsloadingarr
                        
                            }],
                            categories:categories,
                            yaxistitle:"Avg. Loading Time (Hrs)"
                        })
                    }
                })
                //console.log("departmentarray ", departmentarray)
                var transporterdataarr=[];
                var transportergpsdataarr=[];
                var transdrildownarr = [];
                var transgpsdrildownarr = [];
                //console.log("this.state.loadcheck ", this.state.loadcheck)
                var transscatterdeptcodes = transrecords.sort(GetSortDescOrder("weights"));
                
                    transscatterdeptcodes = groupBy(transscatterdeptcodes, rdata => rdata.dept_code);
                //console.log("transrecordsdata ",transrecordsdata)
                try{
                    transscatterdeptcodes.forEach((item, key) =>{
                        //console.log("item ", item)
                        if(key == dept_code)
                        {
                            
                            var transeries = [];
                            var trangpsseries = [];
                            var transcategories = [];
                            var transloadingarr = [];
                            var transgpsloadingarr = [];
                            if(item.length > 0)
                            {
                                
                                item.map((itm) => {
                                    //console.log("itm ",itm)
                                    if(itm.transporter_name != "NA")
                                    {
                                        if(itm.loading_delay_in_hrs > 0)
                                        {
                                            transeries.push({
                                                name:itm.transporter_name,
                                                y: itm.loading_delay_in_hrs,
                                                drilldown:itm.transporter_name,
                                                weights:itm.weights
                                            })
        
                                            
                                            //if()
                                            transcategories.push(itm.consigner_code)
                                        
                                            transloadingarr.push(itm.loading_delay_in_hrs);
                                        }
                                    }
                                    
                                })
                            }
                            transporterdataarr.push({
                                key:key,                                
                                transseries:transeries,
                                categories:transcategories,
                                yaxistitle:"Avg. Loading Time (Hrs)"
                            })
                        }
                    })


                    /*GPS*/
                    var transgpsscatterdeptcodedata = transrecords.sort(GetSortDescOrder("weights"));
                    transgpsscatterdeptcodedata = groupBy(transgpsscatterdeptcodedata, rdata => rdata.dept_code);
                
                    transgpsscatterdeptcodedata.forEach((item, key) =>{
                        //console.log("item ", item)
                        if(key == dept_code)
                        {
                            
                            var transeries = [];
                            var trangpsseries = [];
                            var transcategories = [];
                            var transloadingarr = [];
                            var transgpsloadingarr = [];
                            if(item.length > 0)
                            {
                                
                                item.map((itm) => {
                                    //console.log("itm ",itm)
                                    if(itm.transporter_name != "NA")
                                    {
                                        if(itm.gps_loading_delay_time_in_hrs > 0)
                                        {
                                            trangpsseries.push({
                                                name:itm.transporter_name,
                                                y: itm.gps_loading_delay_time_in_hrs,
                                                drilldown:itm.transporter_name,
                                                weights:itm.weights
                                            })
                                            
                                            //if()
                                            transcategories.push(itm.consigner_code)
                                       
                                        
                                            transgpsloadingarr.push(itm.gps_loading_delay_time_in_hrs);
                                        }
                                    }
                                    
                                })
                            }
                            transportergpsdataarr.push({
                                key:key,                                
                                transseries:trangpsseries,
                                categories:transcategories,
                                yaxistitle:"Avg. Loading Time (Hrs)"
                            })
                        }
                    })


                    //DrilldownData
                    var transscatterdeptcodedata = planttrancsrecords.sort(GetSortDescOrder("weights"));
                    var transscatterdeptcodedata = groupBy(transscatterdeptcodedata, rdata => rdata.transporter_name);
                    //console.log("transscatterdeptcodedata ", transscatterdeptcodedata)
                   
                    transscatterdeptcodedata.forEach((item, key) =>{
                       // console.log("item ", item)
                    
                        
                        var trandrilseries = [];
                        var trangpsdrilseries = [];
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                trandrilseries.push([itm.consigner_code,itm.loading_delay_in_hrs])
                            
                                trangpsdrilseries.push([itm.consigner_code,itm.gps_loading_delay_time_in_hrs])
                            })
                        }
                        transdrildownarr.push({
                            name:key,
                            id:key,
                            data:trandrilseries
                        })
                        transgpsdrildownarr.push({
                            name:key,
                            id:key,
                            data:trangpsdrilseries
                        })
                        
                    })
                }
                catch(e){
                    transporterdataarr=[]
                    transdrildownarr=[]
                }
                var scatterdepartmentarray=[];
                var scatterloading = [ldcounts.loading_time_range_counts];
                var scattergpsloading = [ldcounts.gps_loading_time_range_counts];
                var scatterloadingarr=[];
                var scattergpsloadingarr=[];
                
                for(var obj in scatterloading){
                    if(scatterloading.hasOwnProperty(obj)){
                       var total = 0;
                        for(var prop in scatterloading[obj]){
                            if(scatterloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                total = total+scatterloading[obj][prop];
                            }
                        }
                       // console.log("total ", total)
                        for(var prop in scatterloading[obj]){
                            if(scatterloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                if(scatterloading[obj][prop] > 0)
                                {
                                    scatterloadingarr.push({
                                        name:  prop,
                                        value: ((scatterloading[obj][prop]/total)*100)
                                    })
                                }
                                
                            }
                        }
                    }
                }

                for(var obj in scattergpsloading){
                    if(scattergpsloading.hasOwnProperty(obj)){
                       var gtotal = 0;
                        for(var prop in scattergpsloading[obj]){
                            if(scattergpsloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                gtotal = gtotal+scattergpsloading[obj][prop];
                            }
                        }
                       // console.log("total ", gtotal)
                        for(var prop in scattergpsloading[obj]){
                            if(scattergpsloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scattergpsloading[obj][prop]);
                                if(scattergpsloading[obj][prop] > 0)
                                {
                                    scattergpsloadingarr.push({
                                        name:  prop,
                                        value: ((scattergpsloading[obj][prop]/gtotal)*100)
                                    })
                                }
                                
                            }
                        }
                    }
                }
                

                scatterdepartmentarray.push({
                        color: "rgba(243,94,130,1)",
                        marker: {
                            fillColor: "rgba(243,94,130,1)",
                            fillOpacity:1
                        },
                        name: 'MSIL Data',
                        data: scatterloadingarr
                    },{
                        color: "rgba(127,132,233,1)",
                        marker: {
                            fillColor: "rgba(127,132,233,1)",
                            fillOpacity:1
                        },
                        name: 'GPS Data',
                        data: scattergpsloadingarr
                    })
                
                //TimeSlot Chart
               
                var timecategories = [];
                var timeslotarrdata = [];
                var timeslotarrgpsdata = [];
                var timeslotdatagroup = groupBy(timeslotdata, rdata => rdata.consigner_code);
                var colgroups = groupBy(timeslotdata, rdata => rdata.gate_in_hour);
               // console.log("colgroups ", colgroups)
                //console.log("timeslotdatagroup  ", timeslotdatagroup)

                colgroups.forEach((it,ky) =>{
                    timecategories.push(ky)
                })

                var timeslotgrparr=[]
                var timeslotgrpgpsarr=[]
                //console.log("timecategories ",timecategories.length)
                var slttime = [];
                // timeslotdatagroup.forEach((item, key) =>{
                //     item.map((mt) => {
                //         slttime.push({"hr":mt .gate_in_hour})
                //     })
                // })
                // let uniqueObject = {}; 

                // var objTitle;
                // for (let i in slttime) { 
        
                // 	// Extract the title 
                // 	objTitle = slttime[i]['hr']; 
        
                // 	// Use the title as the index 
                // 	uniqueObject[objTitle] = slttime[i]; 
                // } 
                
                // // Loop to push unique object into array 
                // for (var a in uniqueObject) { 
                // 	newArray.push(uniqueObject[a].hr); 
                // } 

                timeslotdatagroup.forEach((item, key) =>{
                    var timeloadarr = [];
                    var timegpsloadarr = [];

                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.gate_in_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == timecategories.length)
                    {
                        item.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.gate_in_hour;
                            
                            if(key == val.consigner_code)
                            {
                                timeloadarr.push(val.loading_delay_in_hrs)
                                timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                            }
                            // })
                            
                        });
                        timeslotgrparr.push({
                            name:key,
                            data:timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name:key,
                            data:timegpsloadarr
                        })
                    
                    }
                    else{
                        timecategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.gate_in_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            timeloadarr.push(val.loading_delay_in_hrs)
                                            timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                                        }
                                        // else{
                                        //     var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                        //     console.log("Elskey ", key)
                                        //     console.log("newArray ", newArray)    
                                        //     if(newArray.indexOf(mn) >= 0)
                                        //     {
                                        //         console.log("Here ", mn)
                                        //     }
                                        //     else{
                                        //         console.log("Else ",tt)
                                        //         if(key == val.consigner_code)
                                        //         {
                                        //             timeloadarr.push(0)
                                        //             timegpsloadarr.push(0)
                                        //         }
                                        //     }
                                        // }
                                    }
                                    
                                })
                            }
                            else{
                                timeloadarr.push(0)
                                timegpsloadarr.push(0)
                            }
                        })
                    
                        timeslotgrparr.push({
                            name:key,
                            data:timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name:key,
                            data:timegpsloadarr
                        })
                    
                    }


                    // item.map((val) => {                        
                    //     //colgroups.forEach((ci,ky) => {
                    //      //   console.log("CI ",ky)
                    //         if(key == val.consigner_code)
                    //         {
                    //             timeloadarr.push(val.loading_delay_in_hrs)
                    //             timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                    //         }
                    //    // })
                        
                    // });
                    // timeslotgrparr.push({
                    //     name:key,
                    //     data:timeloadarr
                    // })
                    // timeslotgrpgpsarr.push({
                    //     name:key,
                    //     data:timegpsloadarr
                    // })
                })
                
                //console.log("timeslotgrparr ", timeslotgrparr)
               // console.log("colgroups ", colgroups)
                timeslotarrdata.push({
                    categories:timecategories,
                    lineseries:timeslotgrparr
                })
                timeslotarrgpsdata.push({
                    categories:timecategories,
                    lineseries:timeslotgrpgpsarr
                })
                //console.log("timeslotarrdata ", timeslotarrdata)

                //Monthly Line Chart
                var monthcategories = [];
                var monthlydataarr = [];
                var monthlydatagroup = groupBy(monthlydata, rdata => rdata.consigner_code);
                var monthlydatasort = monthlydata.sort(GetSortOrder("month_order"));
                var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.gate_in_month+" "+rdata.gate_in_year));
               

                var monthgrparr=[];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr=[]
                monthlydatagroup.forEach((item, key) =>{
                    var monthloadarr = [];
                    var monthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("month_order"));
                    itemdata.map((val) => {                        
                        //colgroups.forEach((ci,ky) => {
                         //   console.log("CI ",ky)
                            if(key == val.consigner_code)
                            {
                                monthloadarr.push(val.loading_delay_in_hrs)
                                monthgpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                            }
                       // })
                        
                    });
                    //console.log(key);
                    //console.log(item);
                    //console.log("timeloadarr ", timeloadarr)
                    monthgrparr.push({
                        name:key,
                        data:monthloadarr
                    })
                    monthgrpgpsarr.push({
                        name:key,
                        data:monthgpsloadarr
                    })
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
                mncolgroups.forEach((it,ky) =>{
                    monthcategories.push(ky)
                })
                monthlydataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrparr
                })
                monthlygpsdataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpgpsarr
                })



                 //timeslot_plant_gate_out_counts
                var tsgateoutcategories = [];

              
                var tsgateoutarr = [];
                var tsgateoutgroup = groupBy(timeslot_plant_gate_out, rdata => rdata.consigner_code);
                var tsgateoutsort = timeslot_plant_gate_out.sort(GetSortOrder("time_slot_order"));
                var tsgateoutgroups = groupBy(tsgateoutsort, rdata => (rdata.gate_out_hour));
               //console.log("tsgateoutgroup ",tsgateoutgroup)
                tsgateoutgroups.forEach((it,ky) =>{
                    tsgateoutcategories.push(ky)
                })
                
                var tsgateoutarrgrparr=[];
                // tsgateoutgroup.forEach((item, key) =>{
                //     var tsgateoutloadarr = [];
                //     var itemdata = item.sort(GetSortOrder("time_slot_order"));
                //     itemdata.map((val) => {                        
                //         //colgroups.forEach((ci,ky) => {
                //          //   console.log("CI ",ky)
                //             if(key == val.consigner_code)
                //             {
                //                 tsgateoutloadarr.push(val.counts)
                //             }
                //        // })
                        
                //     });
                    
                //     tsgateoutarrgrparr.push({
                //         name:key,
                //         data:tsgateoutloadarr
                //     })
                // })
                


                tsgateoutgroup.forEach((item, key) =>{
                    var tsgateoutloadarr = [];
                    var itemdata = item.sort(GetSortOrder("time_slot_order"));
                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.gate_out_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == tsgateoutcategories.length)
                    {
                        itemdata.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.gate_out_hour;
                            
                            if(key == val.consigner_code)
                            {
                                tsgateoutloadarr.push(val.counts)
                            }
                            // })
                            
                        });
                        tsgateoutarrgrparr.push({
                            name:key,
                            data:tsgateoutloadarr
                        })
                    }
                    else{
                        tsgateoutcategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            //console.log("newArray ", newArray)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.gate_out_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            tsgateoutloadarr.push(val.counts)
                                        }
                                       
                                    }
                                    
                                })
                            }
                            else{
                                tsgateoutloadarr.push(0)
                            }
                        })
                    
                        tsgateoutarrgrparr.push({
                            name:key,
                            data:tsgateoutloadarr
                        })
                    
                    }

                })



                tsgateoutarr.push({
                    categories:tsgateoutcategories,
                    lineseries:tsgateoutarrgrparr
                })
                //console.log("tsgateoutarr ", tsgateoutarr)


                 //time_slot_plant_outside_fence_counts
                 var tsgpsgateoutcategories = [];
                 var tsgpsgateoutarr = [];
                 var tsgpsgateoutgroup = groupBy(time_slot_plant_outside_fence_counts, rdata => rdata.consigner_code);
                 var tsgpsgateoutsort = time_slot_plant_outside_fence_counts.sort(GetSortOrder("time_slot_order"));
                 var tsgpsgateoutgroups = groupBy(tsgpsgateoutsort, rdata => (rdata.outside_fence_hour));
                 tsgpsgateoutgroups.forEach((it,ky) =>{
                    tsgpsgateoutcategories.push(ky)
                })
 
                 var tsgpsgateoutarrgrparr=[];
                //  tsgpsgateoutgroup.forEach((item, key) =>{
                //      var tsgpsgateoutloadarr = [];
                //      var itemdata = item.sort(GetSortOrder("time_slot_order"));
                //      itemdata.map((val) => {                        
                //          //colgroups.forEach((ci,ky) => {
                //           //   console.log("CI ",ky)
                //              if(key == val.consigner_code)
                //              {
                //                  tsgpsgateoutloadarr.push(val.counts)
                //              }
                //         // })
                         
                //      });
                     
                //      tsgpsgateoutarrgrparr.push({
                //          name:key,
                //          data:tsgpsgateoutloadarr
                //      })
                //  })


                tsgpsgateoutgroup.forEach((item, key) =>{
                    var tsgpsgateoutloadarr = [];
                    var itemdata = item.sort(GetSortOrder("time_slot_order"));
                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.outside_fence_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == tsgpsgateoutcategories.length)
                    {
                        itemdata.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.outside_fence_hour;
                            
                            if(key == val.consigner_code)
                            {
                                tsgpsgateoutloadarr.push(val.counts)
                            }
                            // })
                            
                        });
                        tsgpsgateoutarrgrparr.push({
                            name:key,
                            data:tsgpsgateoutloadarr
                        })
                    }
                    else{
                        tsgpsgateoutcategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            //console.log("newArray ", newArray)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.outside_fence_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            tsgpsgateoutloadarr.push(val.counts)
                                        }
                                       
                                    }
                                    
                                })
                            }
                            else{
                                tsgpsgateoutloadarr.push(0)
                            }
                        })
                    
                        tsgpsgateoutarrgrparr.push({
                            name:key,
                            data:tsgpsgateoutloadarr
                        })
                    
                    }

                })
                 
                
                 tsgpsgateoutarr.push({
                     categories:tsgpsgateoutcategories,
                     lineseries:tsgpsgateoutarrgrparr
                 })
                //con
               // console.log("tsgateoutarr ", tsgateoutarr)
                
                // Promise.all(monthlytransportdata).then(()=>{
                    this.setState({
                        departmentarray:departmentarray,
                        scatterdepartmentarray:scatterdepartmentarray,
                        transporterdata:transporterdataarr,
                        transdrilldown:transdrildownarr,
                        transportergpsdata:transportergpsdataarr,
                        transgpsdrilldown:transgpsdrildownarr,
                        timeslotgateout:tsgateoutarr,
                        timeslotgpsgateout:tsgpsgateoutarr,
                        loadshow:'show-n',
                        overly:'show-n',
                        rowData:top10data,
                        gpsrowData:top10gpsdata,
                        timeslotdata:timeslotarrdata,
                        timeslotgpsdata:timeslotarrgpsdata,
                        monthlydata:monthlydataarr,
                        monthlygpsdata:monthlygpsdataarr,
                        transporterLoadingData : transporterLoadingData
                    });
                // })
                
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
        }
        
    }

    formatMonthlyTransLdData(monthly_trans_ld_data){
        var monthlydata = monthly_trans_ld_data;
        console.log(monthlydata);
        //Monthly Line Chart
        var monthcategories = [];
        var monthlydataarr = [];
        var monthlydatagroup = groupBy(monthlydata, rdata => rdata.transporter_name);
        var monthlydatasort = monthlydata.sort(GetSortOrder("month_order"));
        var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.gate_in_month+" "+rdata.gate_in_year));

        var monthgrparr=[];
        var monthgrpgpsarr = [];
        var monthlygpsdataarr=[]
        monthlydatagroup.forEach((item, key) =>{
            var monthloadarr = [];
            var monthgpsloadarr = [];
            var itemdata = item.sort(GetSortOrder("month_order"));
            itemdata.map((val) => {                        
                    if(key == val.transporter_name)
                    {
                        monthloadarr.push(val.loading_delay_in_hrs)
                        monthgpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                    }
            });

            monthgrparr.push({
                name:key,
                data:monthloadarr
            })
            monthgrpgpsarr.push({
                name:key,
                data:monthgpsloadarr
            })
        })
       // mncolgroups.sort(GetSortOrder("month_order"));
       //console.log("mncolgroups ",mncolgroups)
        mncolgroups.forEach((it,ky) =>{
            monthcategories.push(ky)
        })
        monthlydataarr.push({
            categories:monthcategories,
            lineseries:monthgrparr
        })
        monthlygpsdataarr.push({
            categories:monthcategories,
            lineseries:monthgrpgpsarr
        })
        console.log(monthlydataarr,"trns")
        this.setState({
            monthly_transporter_loading_data : monthlydataarr,
            monthly_transporter_gps_loading_data : monthlygpsdataarr
        })
    }

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Location Option selected:`, this.state.location)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onSelectTransporter= transporter =>{
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if(locations.length > 0){
            items.push({value:'all',label:'All'}); 
            locations.forEach(item =>{
                items.push({value:item.code,label:item.label}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item.code,label:item.label}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onSelectItems = items =>{
        
        try{
            
            this.setState({
                selected_items_list : items,
            });
        }catch(e){} 
    }
    onClickGetLoadingData(event){
        event.preventDefault();

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m',
            overly:"show-m",
            transporterdata:[]
        })
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            var startDate = this.state.defaultsdate;
        }
        else{
            var startDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
        {
            var endDate = this.state.defaultedate;
        }
        else{
            var endDate = this.state.endDate;
        } 
        if(this.state.location == '' || this.state.location == null)
        {
            var location = [{"value": "all", "label": "All"}];
        }
        else
        {
            var location = this.state.location;
        } 
        if(this.state.transporter == '' || this.state.transporter == null)
        {
            var transporter = [{"value": "all", "label": "All"}];
        }
        else
        {
            var transporter = this.state.transporter;
        }
        console.log(this.state.transporter,"transporter");
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        if(this.state.dept_code == "LOG-TNP")
        {
            var items_list = [];
            try{
                this.state.selected_items_list.map(function(e){
                    items_list.push(e.value)
                })
            }catch(e){}
            var parms = {
                startDate:startDate,
                endDate:endDate,
                location:locations,
                transporter:transporters,
                dept_code:this.state.dept_code,
                items : items_list
            }
        }
        else
        {
            var parms = {
                startDate:startDate,
                endDate:endDate,
                location:locations,
                transporter:transporters,
                dept_code:this.state.dept_code,
            }
        }
        
        redirectURL.post("/dashboard/getLoadingData",parms).then((response)=>{
            this.setState({
                loadingData : response.data
            })
        }).catch((e)=>{
            console.log(e);
        })
        //getPlantWiseLoadingDelayAnalysis
        redirectURL.post("/dashboard/plantwiseloading",parms)
        .then((response) => {

            var records = JSON.parse(response.data.plant_wise_ld_data);
              //var scaterrecords = JSON.parse(response.data.complete_data);
              var planttrancsrecords = JSON.parse(response.data.plant_trans_wise_ld_data);
              var transrecords = JSON.parse(response.data.trans_wise_ld_data);
              //console.log("records ", records);
              var deptcodedata = groupBy(records, rdata => rdata.dept_code);
              var ldcounts = response.data.ld_counts;
              var top10data = JSON.parse(response.data.top_10_ld_data);
              var top10gpsdata = JSON.parse(response.data.top_10_gps_ld_data);
              var timeslotdata = JSON.parse(response.data.time_slot_ld_data); 
              var monthlydata = JSON.parse(response.data.monthly_ld_data)
              var timeslot_plant_gate_out = JSON.parse(response.data.time_slot_plant_gate_out_counts);
              var time_slot_plant_outside_fence_counts = JSON.parse(response.data.time_slot_plant_outside_fence_counts);
              if(this.state.dept_code == "LOG-TNP")
              {
                var monthly_trans_ld_data = JSON.parse(response.data.monthly_trans_ld_data);
                var transporterLoadingData = JSON.parse(response.data.tnp_custom_report); 
                this.formatMonthlyTransLdData(monthly_trans_ld_data)
              }
              
              //console.log("timeslotdata ", timeslotdata)
             
                var departmentarray=[];
                deptcodedata.forEach((item, key) =>{
                    //console.log("item ", key)
                    if(key == this.state.dept_code)
                    {
                        
                        var serr = [];
                        var categories = [];
                        var loadingarr = [];
                        var gpsloadingarr = [];
                        if(item.length > 0)
                        {
                            item.map((itm) => {

                                categories.push(itm.consigner_code)
                                if(itm.loading_delay_in_hrs > 0)
                                {
                                    loadingarr.push(itm.loading_delay_in_hrs);
                                }
                                if(itm.gps_loading_delay_time_in_hrs > 0)
                                {
                                    gpsloadingarr.push(itm.gps_loading_delay_time_in_hrs);
                                }
                            })
                        }
                        departmentarray.push({
                            key:key,
                            series:[{
                                name: 'MSIL Data',
                                data: loadingarr
                        
                            }, {
                                name: 'GPS Data',
                                data: gpsloadingarr
                        
                            }],
                            categories:categories,
                            yaxistitle:"Avg. Loading Time (Hrs)"
                        })
                    }
                })

                var transporterdataarr=[];
                var transportergpsdataarr=[];
                var transdrildownarr = [];
                var transgpsdrildownarr = [];
                //console.log("this.state.loadcheck ", this.state.loadcheck)
                var transscatterdeptcodes = transrecords.sort(GetSortDescOrder("weights"));
                
                    transscatterdeptcodes = groupBy(transscatterdeptcodes, rdata => rdata.dept_code);
                //console.log("transrecordsdata ",transrecordsdata)
                try{
                    transscatterdeptcodes.forEach((item, key) =>{
                        //console.log("item ", item)
                        if(key == this.state.dept_code)
                        {
                            
                            var transeries = [];
                            var trangpsseries = [];
                            var transcategories = [];
                            var transloadingarr = [];
                            var transgpsloadingarr = [];
                            if(item.length > 0)
                            {
                                
                                item.map((itm) => {
                                    //console.log("itm ",itm)
                                    if(itm.transporter_name != "NA")
                                    {
                                        if(itm.loading_delay_in_hrs > 0)
                                        {
                                            transeries.push({
                                                name:itm.transporter_name,
                                                y: itm.loading_delay_in_hrs,
                                                drilldown:itm.transporter_name,
                                                weights:itm.weights
                                            })
        
                                            
                                            //if()
                                            transcategories.push(itm.consigner_code)
                                        
                                            transloadingarr.push(itm.loading_delay_in_hrs);
                                        }
                                    }
                                    
                                })
                            }
                            transporterdataarr.push({
                                key:key,                                
                                transseries:transeries,
                                categories:transcategories,
                                yaxistitle:"Avg. Loading Time (Hrs)"
                            })
                        }
                    })


                    /*GPS*/
                    var transgpsscatterdeptcodedata = transrecords.sort(GetSortDescOrder("weights"));
                    transgpsscatterdeptcodedata = groupBy(transgpsscatterdeptcodedata, rdata => rdata.dept_code);
                
                    transgpsscatterdeptcodedata.forEach((item, key) =>{
                        //console.log("item ", item)
                        if(key == this.state.dept_code)
                        {
                            
                            var transeries = [];
                            var trangpsseries = [];
                            var transcategories = [];
                            var transloadingarr = [];
                            var transgpsloadingarr = [];
                            if(item.length > 0)
                            {
                                
                                item.map((itm) => {
                                    //console.log("itm ",itm)
                                    if(itm.transporter_name != "NA")
                                    {
                                        if(itm.gps_loading_delay_time_in_hrs > 0)
                                        {
                                            trangpsseries.push({
                                                name:itm.transporter_name,
                                                y: itm.gps_loading_delay_time_in_hrs,
                                                drilldown:itm.transporter_name,
                                                weights:itm.weights
                                            })
                                            
                                            //if()
                                            transcategories.push(itm.consigner_code)
                                       
                                        
                                            transgpsloadingarr.push(itm.gps_loading_delay_time_in_hrs);
                                        }
                                    }
                                    
                                })
                            }
                            transportergpsdataarr.push({
                                key:key,                                
                                transseries:trangpsseries,
                                categories:transcategories,
                                yaxistitle:"Avg. Loading Time (Hrs)"
                            })
                        }
                    })


                    //DrilldownData
                    var transscatterdeptcodedata = planttrancsrecords.sort(GetSortDescOrder("weights"));
                    var transscatterdeptcodedata = groupBy(transscatterdeptcodedata, rdata => rdata.transporter_name);
                    //console.log("transscatterdeptcodedata ", transscatterdeptcodedata)
                   
                    transscatterdeptcodedata.forEach((item, key) =>{
                       // console.log("item ", item)
                    
                        
                        var trandrilseries = [];
                        var trangpsdrilseries = [];
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                trandrilseries.push([itm.consigner_code,itm.loading_delay_in_hrs])
                            
                                trangpsdrilseries.push([itm.consigner_code,itm.gps_loading_delay_time_in_hrs])
                            })
                        }
                        transdrildownarr.push({
                            name:key,
                            id:key,
                            data:trandrilseries
                        })
                        transgpsdrildownarr.push({
                            name:key,
                            id:key,
                            data:trangpsdrilseries
                        })
                        
                    })
                }
                catch(e){
                    transporterdataarr=[]
                    transdrildownarr=[]
                }
                var scatterdepartmentarray=[];
                var scatterloading = [ldcounts.loading_time_range_counts];
                var scattergpsloading = [ldcounts.gps_loading_time_range_counts];
                var scatterloadingarr=[];
                var scattergpsloadingarr=[];
                
                for(var obj in scatterloading){
                    if(scatterloading.hasOwnProperty(obj)){
                       var total = 0;
                        for(var prop in scatterloading[obj]){
                            if(scatterloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                total = total+scatterloading[obj][prop];
                            }
                        }
                       // console.log("total ", total)
                        for(var prop in scatterloading[obj]){
                            if(scatterloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                if(scatterloading[obj][prop] > 0)
                                {
                                    scatterloadingarr.push({
                                        name:  prop,
                                        value: ((scatterloading[obj][prop]/total)*100)
                                    })
                                }
                                
                            }
                        }
                    }
                }

                for(var obj in scattergpsloading){
                    if(scattergpsloading.hasOwnProperty(obj)){
                       var gtotal = 0;
                        for(var prop in scattergpsloading[obj]){
                            if(scattergpsloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scatterloading[obj][prop]);
                                gtotal = gtotal+scattergpsloading[obj][prop];
                            }
                        }
                       // console.log("total ", gtotal)
                        for(var prop in scattergpsloading[obj]){
                            if(scattergpsloading[obj].hasOwnProperty(prop)){
                            //console.log(prop + ':' + scattergpsloading[obj][prop]);
                                if(scattergpsloading[obj][prop] > 0)
                                {
                                    scattergpsloadingarr.push({
                                        name:  prop,
                                        value: ((scattergpsloading[obj][prop]/gtotal)*100)
                                    })
                                }
                                
                            }
                        }
                    }
                }
                

                scatterdepartmentarray.push({
                        color: "rgba(243,94,130,1)",
                        marker: {
                            fillColor: "rgba(243,94,130,1)",
                            fillOpacity:1
                        },
                        name: 'MSIL Data',
                        data: scatterloadingarr
                    },{
                        color: "rgba(127,132,233,1)",
                        marker: {
                            fillColor: "rgba(127,132,233,1)",
                            fillOpacity:1
                        },
                        name: 'GPS Data',
                        data: scattergpsloadingarr
                    })
                
                //TimeSlot Chart
               
                var timecategories = [];
                var timeslotarrdata = [];
                var timeslotarrgpsdata = [];
                var timeslotdatagroup = groupBy(timeslotdata, rdata => rdata.consigner_code);
                var colgroups = groupBy(timeslotdata, rdata => rdata.gate_in_hour);
               // console.log("colgroups ", colgroups)
                //console.log("timeslotdatagroup  ", timeslotdatagroup)

                colgroups.forEach((it,ky) =>{
                    timecategories.push(ky)
                })

                var timeslotgrparr=[]
                var timeslotgrpgpsarr=[]
                //console.log("timecategories ",timecategories.length)
                var slttime = [];
                // timeslotdatagroup.forEach((item, key) =>{
                //     item.map((mt) => {
                //         slttime.push({"hr":mt .gate_in_hour})
                //     })
                // })
                // let uniqueObject = {}; 

                // var objTitle;
                // for (let i in slttime) { 
        
                // 	// Extract the title 
                // 	objTitle = slttime[i]['hr']; 
        
                // 	// Use the title as the index 
                // 	uniqueObject[objTitle] = slttime[i]; 
                // } 
                
                // // Loop to push unique object into array 
                // for (var a in uniqueObject) { 
                // 	newArray.push(uniqueObject[a].hr); 
                // } 

                timeslotdatagroup.forEach((item, key) =>{
                    var timeloadarr = [];
                    var timegpsloadarr = [];

                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.gate_in_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == timecategories.length)
                    {
                        item.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.gate_in_hour;
                            
                            if(key == val.consigner_code)
                            {
                                timeloadarr.push(val.loading_delay_in_hrs)
                                timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                            }
                            // })
                            
                        });
                        timeslotgrparr.push({
                            name:key,
                            data:timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name:key,
                            data:timegpsloadarr
                        })
                    
                    }
                    else{
                        timecategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.gate_in_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            timeloadarr.push(val.loading_delay_in_hrs)
                                            timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                                        }
                                        // else{
                                        //     var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                        //     console.log("Elskey ", key)
                                        //     console.log("newArray ", newArray)    
                                        //     if(newArray.indexOf(mn) >= 0)
                                        //     {
                                        //         console.log("Here ", mn)
                                        //     }
                                        //     else{
                                        //         console.log("Else ",tt)
                                        //         if(key == val.consigner_code)
                                        //         {
                                        //             timeloadarr.push(0)
                                        //             timegpsloadarr.push(0)
                                        //         }
                                        //     }
                                        // }
                                    }
                                    
                                })
                            }
                            else{
                                timeloadarr.push(0)
                                timegpsloadarr.push(0)
                            }
                        })
                    
                        timeslotgrparr.push({
                            name:key,
                            data:timeloadarr
                        })
                        timeslotgrpgpsarr.push({
                            name:key,
                            data:timegpsloadarr
                        })
                    
                    }


                    // item.map((val) => {                        
                    //     //colgroups.forEach((ci,ky) => {
                    //      //   console.log("CI ",ky)
                    //         if(key == val.consigner_code)
                    //         {
                    //             timeloadarr.push(val.loading_delay_in_hrs)
                    //             timegpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                    //         }
                    //    // })
                        
                    // });
                    // timeslotgrparr.push({
                    //     name:key,
                    //     data:timeloadarr
                    // })
                    // timeslotgrpgpsarr.push({
                    //     name:key,
                    //     data:timegpsloadarr
                    // })
                })
                
                //console.log("timeslotgrparr ", timeslotgrparr)
               // console.log("colgroups ", colgroups)
                timeslotarrdata.push({
                    categories:timecategories,
                    lineseries:timeslotgrparr
                })
                timeslotarrgpsdata.push({
                    categories:timecategories,
                    lineseries:timeslotgrpgpsarr
                })
                //console.log("timeslotarrdata ", timeslotarrdata)

                //Monthly Line Chart
                 var monthcategories = [];
                var monthlydataarr = [];
                var monthlydatagroup = groupBy(monthlydata, rdata => rdata.consigner_code);
                var monthlydatasort = monthlydata.sort(GetSortOrder("month_order"));
                var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.gate_in_month+" "+rdata.gate_in_year));
               

                var monthgrparr=[];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr=[]
                monthlydatagroup.forEach((item, key) =>{
                    var monthloadarr = [];
                    var monthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("month_order"));
                    itemdata.map((val) => {                        
                        //colgroups.forEach((ci,ky) => {
                         //   console.log("CI ",ky)
                            if(key == val.consigner_code)
                            {
                                monthloadarr.push(val.loading_delay_in_hrs)
                                monthgpsloadarr.push(val.gps_loading_delay_time_in_hrs)
                            }
                       // })
                        
                    });
                    //console.log(key);
                    //console.log(item);
                    //console.log("timeloadarr ", timeloadarr)
                    monthgrparr.push({
                        name:key,
                        data:monthloadarr
                    })
                    monthgrpgpsarr.push({
                        name:key,
                        data:monthgpsloadarr
                    })
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
                mncolgroups.forEach((it,ky) =>{
                    monthcategories.push(ky)
                })
                monthlydataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrparr
                })
                monthlygpsdataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpgpsarr
                })



                 //timeslot_plant_gate_out_counts
                var tsgateoutcategories = [];

              
                var tsgateoutarr = [];
                var tsgateoutgroup = groupBy(timeslot_plant_gate_out, rdata => rdata.consigner_code);
                var tsgateoutsort = timeslot_plant_gate_out.sort(GetSortOrder("time_slot_order"));
                var tsgateoutgroups = groupBy(tsgateoutsort, rdata => (rdata.gate_out_hour));
               //console.log("tsgateoutgroup ",tsgateoutgroup)
                tsgateoutgroups.forEach((it,ky) =>{
                    tsgateoutcategories.push(ky)
                })
                
                var tsgateoutarrgrparr=[];
                // tsgateoutgroup.forEach((item, key) =>{
                //     var tsgateoutloadarr = [];
                //     var itemdata = item.sort(GetSortOrder("time_slot_order"));
                //     itemdata.map((val) => {                        
                //         //colgroups.forEach((ci,ky) => {
                //          //   console.log("CI ",ky)
                //             if(key == val.consigner_code)
                //             {
                //                 tsgateoutloadarr.push(val.counts)
                //             }
                //        // })
                        
                //     });
                    
                //     tsgateoutarrgrparr.push({
                //         name:key,
                //         data:tsgateoutloadarr
                //     })
                // })
                


                tsgateoutgroup.forEach((item, key) =>{
                    var tsgateoutloadarr = [];
                    var itemdata = item.sort(GetSortOrder("time_slot_order"));
                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.gate_out_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == tsgateoutcategories.length)
                    {
                        itemdata.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.gate_out_hour;
                            
                            if(key == val.consigner_code)
                            {
                                tsgateoutloadarr.push(val.counts)
                            }
                            // })
                            
                        });
                        tsgateoutarrgrparr.push({
                            name:key,
                            data:tsgateoutloadarr
                        })
                    }
                    else{
                        tsgateoutcategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            //console.log("newArray ", newArray)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.gate_out_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            tsgateoutloadarr.push(val.counts)
                                        }
                                       
                                    }
                                    
                                })
                            }
                            else{
                                tsgateoutloadarr.push(0)
                            }
                        })
                    
                        tsgateoutarrgrparr.push({
                            name:key,
                            data:tsgateoutloadarr
                        })
                    
                    }

                })



                tsgateoutarr.push({
                    categories:tsgateoutcategories,
                    lineseries:tsgateoutarrgrparr
                })
                //console.log("tsgateoutarr ", tsgateoutarr)


                 //time_slot_plant_outside_fence_counts
                 var tsgpsgateoutcategories = [];
                 var tsgpsgateoutarr = [];
                 var tsgpsgateoutgroup = groupBy(time_slot_plant_outside_fence_counts, rdata => rdata.consigner_code);
                 var tsgpsgateoutsort = time_slot_plant_outside_fence_counts.sort(GetSortOrder("time_slot_order"));
                 var tsgpsgateoutgroups = groupBy(tsgpsgateoutsort, rdata => (rdata.outside_fence_hour));
                 tsgpsgateoutgroups.forEach((it,ky) =>{
                    tsgpsgateoutcategories.push(ky)
                })
 
                 var tsgpsgateoutarrgrparr=[];
                //  tsgpsgateoutgroup.forEach((item, key) =>{
                //      var tsgpsgateoutloadarr = [];
                //      var itemdata = item.sort(GetSortOrder("time_slot_order"));
                //      itemdata.map((val) => {                        
                //          //colgroups.forEach((ci,ky) => {
                //           //   console.log("CI ",ky)
                //              if(key == val.consigner_code)
                //              {
                //                  tsgpsgateoutloadarr.push(val.counts)
                //              }
                //         // })
                         
                //      });
                     
                //      tsgpsgateoutarrgrparr.push({
                //          name:key,
                //          data:tsgpsgateoutloadarr
                //      })
                //  })


                tsgpsgateoutgroup.forEach((item, key) =>{
                    var tsgpsgateoutloadarr = [];
                    var itemdata = item.sort(GetSortOrder("time_slot_order"));
                    let newArray = []; 
                    item.map((mt) => {
                        newArray.push(mt.outside_fence_hour)
                    })
                    //console.log("item.length " , item.length)
                    if(item.length == tsgpsgateoutcategories.length)
                    {
                        itemdata.map((val) => {
                            //console.log("VAL ",val); 
                            var yr = val.outside_fence_hour;
                            
                            if(key == val.consigner_code)
                            {
                                tsgpsgateoutloadarr.push(val.counts)
                            }
                            // })
                            
                        });
                        tsgpsgateoutarrgrparr.push({
                            name:key,
                            data:tsgpsgateoutloadarr
                        })
                    }
                    else{
                        tsgpsgateoutcategories.forEach((mn, ky)=>{
                            //console.log("MN ", mn)
                            //console.log("newArray ", newArray)
                            if(newArray.indexOf(mn) >= 0)
                            {
                                item.map((val) => { 
                                    if(key == val.consigner_code)
                                    {
                                        var yr = val.outside_fence_hour;
                                            
                                        if(mn == yr)
                                        {
                                            var tt = mn+"=="+yr+": "+key +" == "+val.consigner_code
                                            //console.log(tt)
                                            tsgpsgateoutloadarr.push(val.counts)
                                        }
                                       
                                    }
                                    
                                })
                            }
                            else{
                                tsgpsgateoutloadarr.push(0)
                            }
                        })
                    
                        tsgpsgateoutarrgrparr.push({
                            name:key,
                            data:tsgpsgateoutloadarr
                        })
                    
                    }

                })
                 
                
                 tsgpsgateoutarr.push({
                     categories:tsgpsgateoutcategories,
                     lineseries:tsgpsgateoutarrgrparr
                 })
                //con
               // console.log("tsgateoutarr ", tsgateoutarr)
               
                this.setState({
                    departmentarray:departmentarray,
                    scatterdepartmentarray:scatterdepartmentarray,
                    transporterdata:transporterdataarr,
                    transdrilldown:transdrildownarr,
                    transportergpsdata:transportergpsdataarr,
                    transgpsdrilldown:transgpsdrildownarr,
                    timeslotgateout:tsgateoutarr,
                    timeslotgpsgateout:tsgpsgateoutarr,
                    loadshow:'show-n',
                    overly:'show-n',
                    rowData:top10data,
                    gpsrowData:top10gpsdata,
                    timeslotdata:timeslotarrdata,
                    timeslotgpsdata:timeslotarrgpsdata,
                    monthlydata:monthlydataarr,
                    monthlygpsdata:monthlygpsdataarr,
                    transporterLoadingData : transporterLoadingData
                });
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    onChangeChartView(params){

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Change Chart View",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if(params == 'gpsloading')
        {
            this.setState({
                gpsloadcheck:true,
                loadcheck:false,
                showgpschart:'show-m',
                showtranschart:'show-n'
            })
        }
        if(params == 'loading')
        {
            this.setState({
                gpsloadcheck:false,
                loadcheck:true,
                showgpschart:'show-n',
                showtranschart:'show-m'
            })
        }
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
           
		});
		
	}
    render(){
        var hideinvoice = (this.state.dept_code == 'LOG-PRT')?true:false;
        var hidemsilcol = (this.state.showtranschart == 'show-n')?true:false 
        var hidegpscol = (this.state.showtranschart == 'show-n')?false:true;
        var hideItems = (this.state.dept_code == "LOG-TNP")?false:true;
        var hideGatein = (this.state.dept_code == "LOG-TNP")?true:false;
        var columnwithDefs= [
            {
                headerName:"Plant Name",
                field:"consigner_code",
                width:150
            },
            {
                headerName:"Truck No",
                field:"truck_no",
                width:120
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:200,
                valueGetter : function(params)
                {
                    if(params.data.transporter_name != "NA")
                    {
                        return params.data.transporter_name;
                    }
                }
            },
            {
                headerName : "Items",
                field :"items",
                width : 150,
                hide : hideItems
            },
            {
                headerName:"Invoice Time",
                field:"invoice_time",
                width:150,
                hide:hideItems,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                },
                //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            
            {
                headerName:"Gate In ",
                field:"gate_in_time",
                width:150,
                hide : !hideItems,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                },
                //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
						// comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            {
                headerName:"Gate Out ",
                field:"gate_out_time",
                width:150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
                },
                //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            {
                headerName:"GPS Gate In ",
                field:"inside_fence",
                width:150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.inside_fence)
                },
                //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            {
                headerName:"GPS Gate Out",
                field:"outside_fence",
                width:150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.outside_fence)
                },
                //filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
            },
            {
                headerName:"Loading Time (Hrs)",
                field:"loading_delay_in_hrs",
                width:120,
                hide:hidemsilcol
            },
            {
                headerName:"GPS Loading Time (Hrs)",
                field:"gps_loading_delay_time_in_hrs",
                width:120,
                hide:hidegpscol
            }
        ]
        const transLoadingColumns = [
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:150,
            },
            {
                headerName:"Source Location",
                field:"consignee_code",
                width:150,
            },
            {
                headerName:"Dealer Location",
                field:"consigner_code",
                width:150,
            },
            {
                headerName:"Total No. of Trips",
                field:"total_trips",
                width:150,
            },

            // {
            //     headerName:"Trips Loaded On Time (MSIL)",
            //     field:"msil_loaded_on_time",
            //     width:150,
            // },
            {
                headerName:"Trips Loaded On Time (GPS)",
                field:"gps_loaded_on_time",
                width:150,
            },
            // {
            //     headerName:"Average Loading Time (MSIL)",
            //     field:"msil_avg_loading_time_text",
            //     width:150,
            //     valueGetter : function(params)
            //     {
            //         if(params.data.msil_avg_loading_time_text != "" && params.data.msil_avg_loading_time_text != undefined)
            //         {
            //             var splittext = params.data.msil_avg_loading_time_text.split(":");
            //             return splittext[0]+" Hours "+splittext[1]+" Minutes" ;
            //         }
            //         else
            //         {
            //             return ""
            //         }
            //     }
            // },
            {
                headerName:"Average Loading Time (GPS)",
                field:"gps_avg_loading_time_text",
                width:150,
                valueGetter : function(params)
                {
                    if(params.data.gps_avg_loading_time_text != "" && params.data.gps_avg_loading_time_text != undefined)
                    {
                        var splittext = params.data.gps_avg_loading_time_text.split(":");
                        return splittext[0]+" Hours "+splittext[1]+" Minutes" ;
                    }
                    else
                    {
                        return ""
                    }
                }
            },
        ]
        return ( 
        		<div className="row pt-20px tab-brd">
                    <div className="row col-xl-12 col-lg-12">                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Start Date: </label>
                            <Datetime value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>End Date: </label>
                            <Datetime value={this.state.endDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="endDate" 
                            onChange={this.handlerEndDateTime.bind(this)} />   
                        </div>
                        <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                            <label>Location: </label>
                                <Select placeholder={"Select "} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation.bind(this)} style={{borderRadius:"0px"}} options={this.locationoptions()} required />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Transporter: </label>
                                <Select placeholder={"Select"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{borderRadius:"0px"}} options={this.transporteroptions()} required />
                        </div>
                        {(this.state.dept_code == "LOG-TNP")?
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Select Items : </label>
                                <Select placeholder={"ALL"} 
                                    isMulti={true} 
                                    className="border-radius-0" 
                                    value={this.state.selected_items_list}
                                    onChange={this.onSelectItems.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.items_options_list} 
                                    required />
                            </div>
                        :""}
                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                        </div>
                    </div>
                    <div className="row col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                            {this.state.departmentarray.length > 0?
                            
                                this.state.departmentarray.map(item => 
                                    <div className="col-xl-6 col-lg-6">
                                        <ColumnChartLoadingDashboard
                                            title={"Avg. Loading Time / plant"}
                                            subtitle={"Avg. Time taken inside the plant from Gate in to Gate out"}
                                            context={this}
                                            series={item.series}
                                            categories={item.categories}
                                            yaxistitle={item.yaxistitle}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                            {this.state.scatterdepartmentarray.length > 0?
                            
                            //this.state.scatterdepartmentarray.map(item => 
                                <div className="col-xl-6 col-lg-6"> 
                                    <ScatterChartLoadingDashboard
                                        title={""}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.scatterdepartmentarray}
                                        //categories={item.categories}
                                        //yaxistitle={item.yaxistitle}
                                        //dept_code={this.state.dept_code}
                                    /> 
                                </div>
                            //)
                        :""}

                        </div>

                        
                        <div className={"col-xl-12 col-lg-12 mt-40p optchrt"}>
                            <input type="radio" value="loading" checked={this.state.loadcheck} onChange={this.onChangeChartView.bind(this, "loading")} /> MSIL Data
                            &nbsp;&nbsp;<input type="radio" value="gpsloading" checked={this.state.gpsloadcheck} onChange={this.onChangeChartView.bind(this, "gpsloading")} /> GPS Data
                        </div>

                        <div className={"row col-xl-12 col-lg-12 mb-40p "}>
                            <div className={"row col-xl-12 col-lg-12"}>
                        
                            {this.state.timeslotdata.length > 0?
                            
                                this.state.timeslotdata.map(item => 
                                    <div className={"col-xl-6 col-lg-6 "+(this.state.showtranschart)}>
                                        <LineLoadingDashboard
                                            title={'Hourly Loading Avg. Time (MSIL Data)'}
                                            subtitle={"Avg time taken for loading based on Gate In"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={"Gate In Hour"}
                                            yaxistitle={"Avg. Loading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                             {this.state.timeslotgpsdata.length > 0?
                            
                            this.state.timeslotgpsdata.map(item => 
                                <div className={"col-xl-6 col-lg-6 "+(this.state.showgpschart)}>
                                    <LineLoadingDashboard
                                        title={'Hourly Loading Avg. Time (GPS Data)'}
                                        subtitle={"Avg time taken for loading based on Gate In"}
                                        context={this}
                                        series={item.lineseries}
                                        categories={item.categories}
                                        xaxistitle={"Gate In Hour"}
                                        yaxistitle={"Avg. Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>
                                
                            )
                        :""}
                        {this.state.monthlydata.length > 0?
                            
                            this.state.monthlydata.map(item => 
                                <div className={"col-xl-6 col-lg-6 "+(this.state.showtranschart)}>
                                    <LineLoadingDashboard
                                        title={'Monthly Avg. Loading Time (MSIL Data)'}
                                        subtitle={"Monthly Avg. time taken inside the plant from Gate in to Gate out"}
                                        context={this}
                                        series={item.lineseries}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Avg. Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>
                                
                            )
                        :""} 
                         {this.state.monthlygpsdata.length > 0?
                            
                            this.state.monthlygpsdata.map(item => 
                                <div className={"col-xl-6 col-lg-6 "+(this.state.showgpschart)}>
                                    <LineLoadingDashboard
                                        title={'Monthly Avg. Loading Time (GPS Data)'}
                                        subtitle={"Monthly Avg. time taken inside the plant from Gate in to Gate out"}
                                        context={this}
                                        series={item.lineseries}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Avg Loading Time (Hrs)"}
                                        pointpostfix={"Hrs"}
                                        dept_code={this.state.dept_code}
                                    />
                                </div>
                                
                            )
                        :""}
                            </div>
                        </div>
                       
                        <div className={"row col-xl-12 col-lg-12 mb-40p "}>
                            <div className={" col-xl-12 col-lg-12"}>
                            

                            {this.state.timeslotgateout.length > 0?
                                
                                this.state.timeslotgpsgateout.map(item => 
                                    <div className={"row col-xl-12 col-lg-12 "+(this.state.showtranschart)}>
                                        <LineLoadingTimeSlot
                                            title={'Hourly Gate Out (MSIL Data)'}
                                            subtitle={"Avg. number of carrriers exiting the plant in that hour"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"No Of Trucks"}
                                            pointpostfix={""}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}

                            {this.state.timeslotgpsgateout.length > 0?
                                
                                this.state.timeslotgpsgateout.map(item => 
                                    <div className={"col-xl-12 col-lg-12 "+(this.state.showgpschart)}>
                                        <LineLoadingTimeSlot
                                            title={'Hourly Gate Out (GPS Data)'}
                                            subtitle={"Avg. number of carrriers exiting the plant in that hour"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"No Of Trucks"}
                                            pointpostfix={""}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                            </div>
                        </div>
                        <div className={"row col-xl-12 col-lg-12 mb-40p "+(this.state.showtranschart)}>
                            {this.state.transporterdata.length > 0?
                            
                                this.state.transporterdata.map(item => 
                                    <div className="col-xl-12 col-lg-12">
                                        <ColumnDrilldownLoadingDashboard
                                            title={'Avg. Loading Time / Transporter (MSIL Data)'}
                                            subtitle={"Avg. Time taken inside the plant from Gate in to Gate out per transporter"}
                                            context={this}
                                            series={item.transseries}
                                            categories={item.categories}
                                            yaxistitle={"Wt. Avg Loading Time"}
                                            drilldownseries={this.state.transdrilldown}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                        </div>


                        <div className={"row col-xl-12 col-lg-12 mb-40p "+(this.state.showgpschart)}>
                            {this.state.transportergpsdata.length > 0?
                            
                                this.state.transportergpsdata.map(item => 
                                    <div className="col-xl-12 col-lg-12">
                                        <ColumnDrilldownLoadingDashboard
                                            title={'Avg. Loading Time / Transporter (GPS Data)'}
                                            subtitle={"Avg. Time taken inside the plant from Gate in to Gate out per transporter"}
                                            context={this}
                                            series={item.transseries}
                                            categories={item.categories}
                                            yaxistitle={"Wt. Avg Loading Time"}
                                            drilldownseries={this.state.transgpsdrilldown}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                        </div>







                        <div className={"row col-xl-12 col-lg-12 mb-40p "+(this.state.showgpschart)}>
                            {((this.state.monthly_transporter_gps_loading_data.length > 0)&&(this.state.dept_code=="LOG-TNP"))?
                            
                                this.state.monthly_transporter_gps_loading_data.map(item => 
                                    <div className={"col-xl-12 col-lg-12 "+(this.state.showgpschart)}>
                                        <LineLoadingDashboard
                                            title={'Monthly Transporter Avg. Loading Time (GPS Data)'}
                                            subtitle={"Monthly Avg. time taken inside the plant from Gate in to Gate out"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. Loading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                        </div>
                        <div className={"row col-xl-12 col-lg-12 mb-40p "+(this.state.showtranschart)}>
                            {((this.state.monthly_transporter_loading_data.length > 0)&&(this.state.dept_code=="LOG-TNP"))?
                            
                                this.state.monthly_transporter_loading_data.map(item => 
                                    <div className={"col-xl-12 col-lg-12 "+(this.state.showtranschart)}>
                                        <LineLoadingDashboard
                                            title={'Monthly Transporter Avg. Loading Time (MSIL Data)'}
                                            subtitle={"Monthly Avg. time taken inside the plant from Gate in to Gate out"}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. Loading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                        </div>


                        <div className={"row col-xl-12 col-lg-12 "+(this.state.showtranschart)}>
                            <h5>Top 10 longest wait (MSIL Data)</h5>
                            <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                />
                                
                            </div>
                        </div>

                        <div className={"row col-xl-12 col-lg-12 "+(this.state.showgpschart)}>
                            <h5>Top 10 longest wait (GPS Data)</h5>
                            <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.gpsrowData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                />
                                
                            </div>
                        </div>
                        {(this.state.dept_code == "LOG-TNP")?
                            <div className={"row col-xl-12 col-lg-12"} style={{marginTop:"2em"}}>
                                <h5>Transporter Loading Report</h5>
                                <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={transLoadingColumns}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.transporterLoadingData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    />
                                    
                                </div>
                            </div>
                        :""}
                        
                        <div className={"row col-xl-12 col-lg-12"} style={{marginTop:"2em"}}>
                            <h5>Loading Data</h5>
                            <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.loadingData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                />
                                
                            </div>
                        </div>
                        
                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    
                    </div>
                   
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}







