import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import CustomDateComponent from "../common/customdatepicker";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class CHANhavaSheva extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Customs Entry (NS)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				customDateComponent:CustomDateComponent
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			
		}
		
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.selectContainersOptionsItems = this.selectContainersOptionsItems.bind(this);
	}
	componentDidMount = async () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		var tpar = {
			deptcode:"LOG-SC"
		}
		await redirectURL.post("/masters/transportersbydept",tpar)
		.then((resp) =>{
			var rec = resp.data;
			if(rec.length > 0)
			{
				var depts = [];
				rec.map((item) => {
					depts.push(item.transporter_name)
				})
				this.setState({
					alltransporters:depts
				});
			}
		})
		var containerslst = []
		var containersimportlst = []
		await redirectURL.post("/imports/chanhavaimportsconsignments")
		.then(async (response) => {
			console.log("Import container Response ", response.data)
			if(response.data.length > 0)
			{
				response.data.map((item) => {
					containerslst.push(item.container_no)
					containersimportlst.push(item)
				})
			}
		
			await redirectURL.post("/imports/chanhavaimports",{
				containers:containerslst
			})
			.then(async (response) =>{
				var records = response.data;
				console.log("records ",records)



				for (let i = 0; i < containersimportlst.length; i++) {
					for (let j = 0; j < records.length; j++) {
						if ((containersimportlst[i].container_no === records[j].container_no) && 
						(containersimportlst[i].hbl_no === records[j].hbl_no)) 
						{
							containersimportlst[i] = Object.assign(containersimportlst[i], records[j]);
						} else {
							// assign null values for no match
							containersimportlst[i] = Object.assign(containersimportlst[i], records);
						}
					}
				}

				var dataArray = [];
				try{

					if(containersimportlst.length > 0)
					{
						await containersimportlst.map((item) => {
							//console.log("Items ", item.nhavasheva.length)
							//containerslst.map((itm) => {
								
								dataArray.push({
									"hbl_no" : item.hbl_no,
									"container_no":item.container_no,
									"document_received_date":item.document_received_date,
									"original_fta_received_date":item.original_fta_received_date,
									"msil_job_no":item.msil_job_no,
									"type_of_container":item.type_of_container,
									"inward_date":item.inward_date,
									"port_cfs_movement_date":item.port_cfs_movement_date,
									"destuffing_date":item.destuffing_date,
									"suppliers":item.suppliers,
									"description":item.description,
									"invoice_no":item.invoice_no,
									"invoice_value_currency":item.invoice_value_currency,
									"invoice_value":item.invoice_value,
									"ex_rate":item.ex_rate,
									"freight_amount_currency":item.freight_amount_currency,
									"freight_amount":item.freight_amount,
									"no_of_cas":item.no_of_cas,
									"gwt_kgs_per_bl":item.gwt_kgs_per_bl,
									"assesable_value_av":item.assesable_value_av,
									"be_no":item.be_no,
									"be_date":item.be_date,
									"rms_exam":item.rms_exam,
									"ooc_date":item.ooc_date,
									"handover_to_transporter":item.handover_to_transporter,
									"handover_date":item.handover_date,
									"delivery_location":item.delivery_location,
									"remarks_for_delay":item.remarks_for_delay,
									"truck_no":item.trailer_no,
									"transporter_name":item.transporter_name,
									"dispatch_date":item.dispatch_from_port,
									"dispatch_from_port":item.dispatch_from_port
								})
								
								
							//})
							
						});
						//console.log("dataArray ", dataArray)
						await this.setState({
							rowData:dataArray,
							loadshow:'show-n'
						})
					}
					
				}
				catch(e){
					console.log("Response Error ", e)
				}
			})
			.catch(e => {
				console.log("Error ",e);
			})
			
		})
		.catch(function(e){
			console.log("Error ", e)
		})
		
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
   
	selectOptionsItems() {
        let items = [];     
        //console.log("transporterslist ",this.state.transporterslist);
        this.state.transporterslist.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push({value:item.transporter_code,label:item.transporter_name}); 
    		}
        	
        });
        return items;
	}

	handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeArrayHandler = async searates => {
    	
    	await this.setState(
	      { searates },
	      () => console.log(`Option selected:`, this.state.searates)
	    );
    	
		//await console.log("Selecton ",this.state.searates.value)
		
    }

    changeContainerArrayHandler = container_no =>{
        this.setState(
	      { container_no },
	      () => console.log(`Container Option selected:`, this.state.container_no)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    changeTransporterHandler = transporter_code => {
        this.setState(
            { transporter_code },
            () => console.log(`Container Option selected:`, this.state.transporter_code)
          );
    }

    selectContainersOptionsItems(){
        let items = [];     
        //console.log(this.state.trucks);
        if(this.state.containerslist.length > 0)
        {
            this.state.containerslist.forEach(item => {
                if(item != '' && item != null)
                {
                    items.push({value:item,label:item}); 
                }
                
            });
        }
        
        return items;
    }
    
   

    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
		});
		var assignarr=[];
		
		itemsToUpdate.map((item) => {
			var cdate =new Date();
			var currentDate = getDDMMYYYYHHMMSS(cdate);
			console.log("document_received_date ", item.document_received_date+"aa")
			if((item.document_received_date == undefined || item.document_received_date.trim() == "") &&
			(item.original_fta_received_date == undefined || item.original_fta_received_date.trim() == "") ||
			(item.msil_job_no == undefined || item.msil_job_no.trim() == "") &&
			(item.type_of_container == undefined || item.type_of_container.trim() == "") &&
			(item.inward_date == undefined || item.inward_date.trim() == "") &&
			(item.port_cfs_movement_date == undefined || item.port_cfs_movement_date.trim() == "") &&
			(item.destuffing_date == undefined || item.destuffing_date.trim() == "") &&
			(item.suppliers == undefined || item.suppliers.trim() == "") &&
			(item.description == undefined || item.description.trim() == "") &&
			(item.invoice_no == undefined || item.invoice_no.trim() == "") &&
			(item.invoice_value_currency == undefined || item.invoice_value_currency.trim() == "") &&
			(item.invoice_value == undefined || item.invoice_value.trim() == "") &&
			(item.ex_rate == undefined || item.ex_rate.trim() == "") &&
			(item.freight_amount_currency == undefined || item.freight_amount_currency.trim() == "") &&
			(item.freight_amount == undefined || item.freight_amount.trim() == "") &&
			(item.no_of_cas == undefined || item.no_of_cas.trim() == "") &&
			(item.gwt_kgs_per_bl == undefined || item.gwt_kgs_per_bl.trim() == "") &&
			(item.assesable_value_av == undefined || item.assesable_value_av.trim() == "") &&
			(item.be_no == undefined || item.be_no.trim() == "") &&
			(item.be_date ==undefined || item.be_date.trim() == "") &&
			(item.rms_exam == undefined || item.rms_exam.trim() == "") &&
			(item.ooc_date == undefined || item.ooc_date.trim() == "") &&
			(item.handover_to_transporter == undefined || item.handover_to_transporter.trim() == "") &&
			(item.delivery_location == undefined || item.delivery_location.trim() == "") &&
			(item.remarks_for_delay == undefined || item.remarks_for_delay.trim() == "") &&
			(item.truck_no == undefined || item.truck_no.trim() == "") &&
			(item.transporter_name == undefined || item.transporter_name.trim() == "")
			(item.dispatch_from_port == undefined || item.dispatch_from_port.trim() == ""))
			{
				console.log("Yes ",item)
			}	
			else{
				assignarr.push({
					"hbl_no" : item.hbl_no,
					"container_no":item.container_no,
					"document_received_date":item.document_received_date,
					"original_fta_received_date":item.original_fta_received_date,
					"msil_job_no":item.msil_job_no,
					"type_of_container":item.type_of_container,
					"inward_date":item.inward_date,
					"port_cfs_movement_date":item.port_cfs_movement_date,
					"destuffing_date":item.destuffing_date,
					"suppliers":item.suppliers,
					"description":item.description,
					"invoice_no":item.invoice_no,
					"invoice_value_currency":item.invoice_value_currency,
					"invoice_value":item.invoice_value,
					"ex_rate":item.ex_rate,
					"freight_amount_currency":item.freight_amount_currency,
					"freight_amount":item.freight_amount,
					"no_of_cas":item.no_of_cas,
					"gwt_kgs_per_bl":item.gwt_kgs_per_bl,
					"assesable_value_av":item.assesable_value_av,
					"be_no":item.be_no,
					"be_date":item.be_date,
					"rms_exam":item.rms_exam,
					"ooc_date":item.ooc_date,
					"handover_to_transporter":item.handover_to_transporter,
					"delivery_location":item.delivery_location,
					"remarks_for_delay":item.remarks_for_delay,
					"truck_no":item.truck_no,
					"transporter_name":item.transporter_name,
					"dispatch_date":item.dispatch_from_port,
					"vessel_arrived_on":item.searates_ata_pod
				});
			}
			
		})
		
	   // var res = this.gridApi.updateRowData({ update: itemsToUpdate });
	   //console.log("itemsToUpdate " , itemsToUpdate)
	   //console.log("assignarr " , assignarr)

	   var urlpath = '/imports/storeNhavaShevaData';
	   var reqparams = {
		   updateData:assignarr
	   } 
		redirectURL.post(urlpath,reqparams)
		.then((response) => {
			console.log("Response ", response)
			//if(response.data.status == 'Success')
			//{

				redirectURL.post("/imports/chanhavaimports",)
				.then((response) =>{
					var records = response.data;
					var dataArray = [];
					try{
		
						if(records.length > 0)
						{
							records.map((item) => {
								//console.log("Items ", item)
								dataArray.push({
									"hbl_no" : item.hbl_no,
									"container_no":item.container_no,
									"document_received_date":item.document_received_date,
									"original_fta_received_date":item.original_fta_received_date,
									"msil_job_no":item.msil_job_no,
									"type_of_container":item.type_of_container,
									"inward_date":item.inward_date,
									"port_cfs_movement_date":item.port_cfs_movement_date,
									"destuffing_date":item.destuffing_date,
									"suppliers":item.suppliers,
									"description":item.description,
									"invoice_no":item.invoice_no,
									"invoice_value_currency":item.invoice_value_currency,
									"invoice_value":item.invoice_value,
									"ex_rate":item.ex_rate,
									"freight_amount_currency":item.freight_amount_currency,
									"freight_amount":item.freight_amount,
									"no_of_cas":item.no_of_cas,
									"gwt_kgs_per_bl":item.gwt_kgs_per_bl,
									"assesable_value_av":item.assesable_value_av,
									"be_no":item.be_no,
									"be_date":item.be_date,
									"rms_exam":item.rms_exam,
									"ooc_date":item.ooc_date,
									"handover_to_transporter":item.handover_to_transporter,
									"handover_date":item.handover_date,
									"delivery_location":item.delivery_location,
									"remarks_for_delay":item.remarks_for_delay,
									"truck_no":item.trailer_no,
									"transporter_name":item.transporter_name,
									"dispatch_from_port":item.dispatch_from_port
								})
							});
							this.setState({
								rowData:dataArray,
								loadshow:'show-n',
								successMessage:"Successfully updated"
							})
						}
					}
					catch(e){
						console.log("Response Error ", e)
					}
				})
				.catch(e => {
					console.log("Error ",e);
				});

			//}
		})
		.catch(function (error) {
			console.log(error);
		});
		
	}

	onCellUpdateData = (params) => {
		if(params.colDef.field == 'dispatch_from_port')
		{
			console.log("params ", params)
			console.log("params field", params.colDef.field);
			var dispatchUpdate = 0;
			if(params.value != '')
			{
				dispatchUpdate = 1;
			}
			else{
				dispatchUpdate = 2;
			}
			var upddata = {
				updateData:params.data,
				isDispatch:dispatchUpdate
			}
			redirectURL.post("/imports/storeElementNhavaShevaData", upddata)
			.then((response) => {
				console.log("Resp ",response.data)
			});
		}
		else{
			if(params.value != '')
			{
				console.log("params ", params)
				console.log("params field", params.colDef.field);
				var dispatchUpdate = 0;
				if(params.colDef.field == 'dispatch_from_port')
				{
					dispatchUpdate = 1;
				}
				else{
					dispatchUpdate = 0;
				}
				var upddata = {
					updateData:params.data,
					isDispatch:dispatchUpdate
				}
				redirectURL.post("/imports/storeElementNhavaShevaData", upddata)
				.then((response) => {
					console.log("Resp ",response.data)
				});
			}
		}
		
	}
	
	applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
				
				{
					headerName: "BL No",
					field: "hbl_no",
					width: 200,
					filter: true,
					pinned: 'left',
                    resizable: true
                },
                {
					headerName: "Container No",
					field: "container_no",
					width: 200,
					filter: true,
					pinned: 'left',
                    resizable: true
				},
				{
					headerName: "Document Received Date",
					field: "document_received_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.document_received_date != null)
						{
							if(moment(params.data.document_received_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.document_received_date);
							}
							else{
								var rd = params.data.document_received_date;
								rd = rd.split("-")[1]+"-"+rd.split("-")[0]+"-"+rd.split("-")[2]
								// return getHyphenDDMMMYYYY(rd);
								return params.data.document_received_date;
							}
							// return getDDMMYYYY(params.data.document_received_date);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },

				},
				{
					headerName: "Original FTA received",
					field: "original_fta_received_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						try{
							if (params.data.original_fta_received_date != null)
							{
								// if(moment(params.data.original_fta_received_date,'DD-MM-YYYY',true).isValid() == false)
								// {
								// 	return getHyphenDDMMMYYYY(params.data.original_fta_received_date);
								// }
								// else{
								// 	return params.data.original_fta_received_date;
								// }
								return getHyphenDDMMMYYYY(params.data.original_fta_received_date);
								// return getDDMMYYYY(params.data.original_fta_received_date);
								//return params.data.msil_initial_transit_eta;
							}
							else
							{
								return ""
							}
						}
						catch(e){
							return ""
						}
						

					},
					comparator: dateComparator1,
					// change the date format to DD-MM-YYYY to sort and filter the column
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },

				},
				{
					headerName: "MSIL JOB NO",
					field: "msil_job_no",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Type of container",
					field: "type_of_container",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Inward Date",
					field: "inward_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.inward_date != null)
						{
							if(moment(params.data.inward_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.inward_date);
							}
							else{
								return params.data.inward_date;
							}
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },

				},
				{
					headerName: "Port to CFS movement date",
					field: "port_cfs_movement_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.port_cfs_movement_date != null)
						{
							if(moment(params.data.port_cfs_movement_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.port_cfs_movement_date);
							}
							else{
								return params.data.port_cfs_movement_date;
							}
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "De-stuffing date",
					field: "destuffing_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.destuffing_date != null)
						{
							if(moment(params.data.destuffing_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.destuffing_date);
							}
							else{
								return params.data.destuffing_date;
							}
							// return getDDMMYYYY(params.data.destuffing_date);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Suppliers",
					field: "suppliers",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Description",
					field: "description",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Invoice no.",
					field: "invoice_no",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Invoice value Currency",
					field: "invoice_value_currency",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Invoice value",
					field: "invoice_value",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Ex. Rate",
					field: "ex_rate",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Freight Amount Currency",
					field: "freight_amount_currency",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Freight Amount",
					field: "freight_amount",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "No.of CAS",
					field: "no_of_cas",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "GWT kgs as per BL",
					field: "gwt_kgs_per_bl",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Assesable Value AV",
					field: "assesable_value_av",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "B/E No.",
					field: "be_no",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "B/E Dt.",
					field: "be_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.be_date != null)
						{
							if(moment(params.data.be_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.be_date);
							}
							else{
								return params.data.be_date;
							}
							// return getDDMMYYYY(params.data.be_date);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "RMS/EXAM",
					field: "rms_exam",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "OOC date",
					field: "ooc_date",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.ooc_date != null)
						{
							if(moment(params.data.ooc_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.ooc_date);
							}
							else{
								return params.data.ooc_date;
							}
							// return getDDMMYYYY(params.data.ooc_date);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Handover to Transporter",
					field: "handover_to_transporter",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.handover_to_transporter != null)
						{
							if(moment(params.data.handover_to_transporter,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.handover_to_transporter);
							}
							else{
								return params.data.handover_to_transporter;
							}
							// return getDDMMYYYY(params.data.handover_to_transporter);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				
				{
					headerName: "Dispatch From Port",
					field: "dispatch_from_port",
					width: 200,
					filter: true,
					resizable: true,
					editable:true, 
					cellEditor: DateEditor,
					//filter: "agDateColumnFilter",
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.dispatch_from_port != null)
						{
							if(moment(params.data.dispatch_from_port,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.dispatch_from_port);
							}
							else{
								return params.data.dispatch_from_port;
							}
							// return getDDMMYYYY(params.data.dispatch_from_port);
							//return params.data.msil_initial_transit_eta;
						}
						else
						{
							return ""
						}

					},
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Delivery Location",
					field: "delivery_location",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Transporter Name",
					field: "transporter_name",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					cellEditor: "agRichSelectCellEditor",
				    cellEditorParams: {
					  values: this.state.alltransporters,
					  cellRenderer: function(params) {
						  console.log("tras",params)
							return params.value
						}
					},
					//cellEditor: "datePicker"
				},
				{
					headerName: "Trailer No",
					field: "truck_no",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "Remarks for delay",
					field: "remarks_for_delay",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				}
				
			];
		const dStyles={
			width:this.props.width,
            height:'500px',	
            marginLeft:'0px !important'

	    }
        
        const searatesList=[
            {value:"Nhava Sheva", label:"Nhava Sheva"},
            {value:"Pipavav", label:"Pipava"},
            {value:"Mundra", label:"Mundra"},
            {value:"Garhi", label:"Garhi"}
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ship cus-i"></i> <span>  {this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                   
                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   {
									(this.state.rowData.length > 0)?
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													components={this.state.components}
													frameworkComponents={this.state.frameworkComponents}
									
													//floatingFilter={true}
													onCellEditingStopped={this.onCellUpdateData.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                           {/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
										</div>
										: ""
									}
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}