import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import ShowTruckConsignments from "./showtruckconsignments.js"
import RevokSaveBilling from "./checkboxtruckconsignments.js"
import Modal from 'react-responsive-modal';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class BillingLogs extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                showtruckconsignments:ShowTruckConsignments,
                RevokSaveBilling:RevokSaveBilling
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            billingtrucks:[],
            pagerest:0,
            approveby:"",
            approvedon:"",
            screen:'',
            hidebuttons:0,
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false,
            reason:'',
            approveenreason:'',
            revertreason:'',
            approveitemreason:'',
            disapprovereason:'',
            dispproveitem:"",
            approveitem:"",
            billingRowData:"",
        }
        
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var mm = moment.parseZone().format("MM")
        var mmm = moment.parseZone().format("MMM")
        var month = [{value:mm,label:mmm}]
        var yy = moment.parseZone().format("YYYY")
        var year = [{value:yy, label:yy}]
        
        // months start at index 0 in momentjs, so we subtract 1
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
        var screen = ''
        var logurlpath = "/consignments/billinglogs";
       
        if(this.props.dept_code == "SNDG" && this.props.screen == "primary")
		{
            var deptcode='SNDG';
            screen="primary";
            var logurlpath = "/consignments/sndbillinglogs";
        }

        if(this.props.dept_code == "SNDG" && this.props.screen == "secondary")
		{
            var deptcode='SNDG';
            screen="secondary";
            var logurlpath = "/consignments/sndbillinglogs";
        }

        if(this.props.dept_code == "LOG-PRT")
		{
            var deptcode='LOG-PRT';
            var logurlpath = "/consignments/prtbillinglogs";
        }
        if(this.props.dept_code == "LOG-TNP")
		{
            var deptcode='LOG-TNP';
            var logurlpath = "/consignments/tnpbillinglogs";
        }
        var params = {
            dept_code:deptcode,
            screen:screen
        }
        redirectURL.post(logurlpath,params)
        .then((response) => {
            this.setState({
                billingRowData:response.data
            })
        })
    }
    componentWillReceiveProps(newprops){
        var mm = moment.parseZone().format("MM")
        var mmm = moment.parseZone().format("MMM")
        var month = [{value:mm,label:mmm}]
        var yy = moment.parseZone().format("YYYY")
        var year = [{value:yy, label:yy}]
        
        // months start at index 0 in momentjs, so we subtract 1
        var startDate = moment([yy, mm-1, "01"]).format("YYYY-MM-DD");

        // get the number of days for this month
        const daysInMonth = moment(startDate).daysInMonth();

        // we are adding the days in this month to the start date (minus the first day)
        var endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");
        var screen = ''
        var logurlpath = "/consignments/billinglogs";
       
        if(newprops.dept_code == "SNDG" && newprops.screen == "primary")
		{
            var deptcode='SNDG';
            screen="primary";
            var logurlpath = "/consignments/sndbillinglogs";
        }

        if(newprops.dept_code == "SNDG" && newprops.screen == "secondary")
		{
            var deptcode='SNDG';
            screen="secondary";
            var logurlpath = "/consignments/sndbillinglogs";
        }

        if(newprops.dept_code == "LOG-PRT")
		{
            var deptcode='LOG-PRT';
            var logurlpath = "/consignments/prtbillinglogs";
        }
        if(newprops.dept_code == "LOG-TNP")
		{
            var deptcode='LOG-TNP';
            var logurlpath = "/consignments/tnpbillinglogs";
        }
        var params = {
            dept_code:deptcode,
            screen:screen
        }
        redirectURL.post(logurlpath,params)
        .then((response) => {
            this.setState({
                billingRowData:response.data
            })
        })
    }

    render(){


        var columnbilling = [
            {
                headerName:"Department",
                field:"dept_code",
                width:120
            },
            {
                headerName:"Reason",
                field:"reason",
                width:250
            },
            {
                headerName:"Performed Action",
                field:"actionmessage",
                width:180
            },
            {
                headerName:"Month",
                field:"billing_month",
                width:100,
                valueGetter:function(params){
                    var arr = [
                        "Jan", 
                        "Feb", 
                        "Mar", 
                        "Apr", 
                        "May", 
                        "Jun", 
                        "Jul", 
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ]
                    var month = params.data.billing_month;
                    return arr[month-1];
                }
            },
            {
                headerName:"Year",
                field:"billing_year",
                width:100
            },

            {
                headerName:"Truck No",
                field:"truck_no",
                width:150
            },
            {
                headerName:"Performed By",
                field:"username",
                width:200
            },
            // {
            //     headerName:"Performed Email",
            //     field:"email",
            //     width:200
            // },
            {
                headerName:"Created On",
                field:"created_on",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.created_on)
                }
            }
        ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
               
                <div className="row mt-20p">
                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnbilling}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.billingRowData}
                        enableCharts={true}
                        enableRangeSelection={true}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        frameworkComponents={this.state.frameworkComponents}
                        detailCellRendererParams={this.state.detailCellRendererParams}
                        stopEditingWhenGridLosesFocus={true}
                        enableCellChangeFlash={false}
                        rowClassRules={this.state.rowClassRules}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        masterDetail={true}
                        rowSelection={this.state.rowSelection}
                        suppressRowClickSelection={true}
                        onPaginationChanged={this.resetPaginationSelection}
                        
                    />
                        
                        
                    </div> 
                </div>
                            
            </div>
    )}       
} 