import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class PipavaMundraRoadPlanComponent extends Component {

	constructor(props){
		super(props);
		this.state={			
			pageTitle: "Pipava Mundra Road Pickup",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            transporterdropshow:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:''
			
		}
		
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.selectContainersOptionsItems = this.selectContainersOptionsItems.bind(this);
	}
	componentDidMount(){
		//console.log("Props ", this.props.match)
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		var urlpath;
        var dpt='';
        this.setState({
            loadshow:'show-m'
        });
		if(this.props.match.path == "/pm-road-plan")
		{
			dpt='LOG-SC';
		}
		
        urlpath = '/masters/transportersbydept';
		
        var reqparams = {
			deptcode:dpt
		}
		//console.log(reqparams)
		redirectURL.post(urlpath,reqparams)
		  .then((response) => {
            var records = response.data;
			//console.log("records", records);
			var listarr=[];
			records.map((item) => {
				listarr.push({"transporter_code":item.transporter_code, "transporter_name":item.transporter_name});
			})
			//console.log("listarr ",listarr);
			var uniquefencelist = listarr.filter(function (value, index, self) {
				return self.indexOf(value) === index;
			} );
			//console.log("uniquefencelist " , uniquefencelist)
            this.setState({
                //rowData:rowarray,
				loadshow:'show-n',
				transporterslist:uniquefencelist
            });
		  })
		.catch(function (error) {
		    console.log(error);
		});
		
        /*All Containers List */
        redirectURL.post("/imports/pmconsignments")
		  .then((response) => {
            var records = response.data;
			//console.log("records", records);
            var pipavalistarr=[];
            var mundralistarr=[];
			records.map((item) => {
                var searates_pod = item.searates_pod;
                if(searates_pod == 'Pipavav')
                {
                    pipavalistarr.push(item.container_no);
                }
				
                if(searates_pod == 'Mundra')
                {
                    mundralistarr.push(item.container_no);
                }
			})
			//console.log("mundralistarr ",mundralistarr);
			//console.log("pipavalistarr ",pipavalistarr);
			var pipavauniquefencelist = pipavalistarr.filter(function (value, index, self) {
				return self.indexOf(value) === index;
            } );
            
            var mundrauniquefencelist = mundralistarr.filter(function (value, index, self) {
				return self.indexOf(value) === index;
			} );
			//console.log("uniquefencelist " , uniquefencelist)
            this.setState({
                //rowData:rowarray,
				loadshow:'show-n',
				pipavaContainers:pipavauniquefencelist,
				mundraContainers:mundrauniquefencelist
            });
		  })
		.catch(function (error) {
		    console.log(error);
		});
		
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
   
	selectOptionsItems() {
        let items = [];     
        //console.log("transporterslist ",this.state.transporterslist);
        this.state.transporterslist.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push({value:item.transporter_code,label:item.transporter_name}); 
    		}
        	
        });
        return items;
	}

	handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeArrayHandler = async searates => {
    	
    	await this.setState(
	      { searates },
	      () => console.log(`Option selected:`, this.state.searates)
	    );
    	
        //await console.log("Selecton ",this.state.searates.value)
        if(this.state.searates.value == 'Pipavav')
        {
            await this.setState({
                containerslist:this.state.pipavaContainers,
                container_no:'',
				errorMessage:'',
				successMessage:''
            })
        }
        if(this.state.searates.value == 'Mundra')
        {
            await this.setState({
                containerslist:this.state.mundraContainers,
				container_no:'',
				errorMessage:'',
				successMessage:''
            })
        }

        //await console.log("containerslist ", this.state.containerslist)
    }

    changeContainerArrayHandler = container_no =>{
        this.setState(
	      { container_no },
	      () => console.log(`Container Option selected:`, this.state.container_no)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    changeTransporterHandler = transporter_code => {
        this.setState(
            { transporter_code },
            () => console.log(`Container Option selected:`, this.state.transporter_code)
          );
    }

    selectContainersOptionsItems(){
        let items = [];     
        //console.log(this.state.trucks);
        if(this.state.containerslist.length > 0)
        {
            this.state.containerslist.forEach(item => {
                if(item != '' && item != null)
                {
                    items.push({value:item,label:item}); 
                }
                
            });
        }
        
        return items;
    }
    
    formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGetData,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		console.log("IN Form Handler event = ", event);
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
        //console.log("this.state.container_no.value ", this.state.container_no.length)
        if(this.state.container_no.length > 0)
        {
			var containerarr=this.state.container_no;
			var containerlist=[];
			containerarr.map((mp) => {
				containerlist.push(mp.value)
			})
			//console.log("containerlist ", containerlist.toString())
            var reqparams= {
                container_no:containerlist.toString()
            }
            //console.log("formdata ", formdata)
            var urlpath = '/container/pipavamundraContainerRoadPickup';
        
            redirectURL.post(urlpath,reqparams)
            .then((response) => {
                var records = JSON.parse(JSON.stringify(response.data));
                // console.log("records", records);
                var items = []
                records.map((item) => {
                    items.push(item.container_no)
                })
                //console.log("Container no ",items);
                this.setState({
                    transporterdropshow:'show-m',
                    rowData:records,
                    loadshow:'show-n'			
                })
                
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else{
            this.setState({
				errorMessage:"Fields are missing",
				loadshow:'show-n'
            });
        }
    }


    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedAssignTransporter,
			}
			googleAnalytics.logEvent(eventOptions);
		}

        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
        console.log("this.state.transporter_code.value ", this.state.transporter_code.value)
        if(this.state.transporter_code.value != '' && this.state.transporter_code.value != undefined)
        {
            var itemsToUpdate = [];
            this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
                var data = rowNode.data;
                itemsToUpdate.push(data);
            });
            var assignarr=[];
            itemsToUpdate.map((item) => {
                var cdate =new Date();
                var currentDate = getDDMMYYYYHHMMSS(cdate);
                assignarr.push({
                    "transporter_code":this.state.transporter_code.value,
                    "container_no": item.container_no, 
					"bl_no":item.bl_no,
					"container_size" : item.container_size,
                    "eta_pod":item.eta_pod,
                    "ata_pod":item.ata_pod,
                    "pod":this.state.searates.value,
                    "assigned_date":currentDate,
                    "modified_date":currentDate
                });
            })

           // var res = this.gridApi.updateRowData({ update: itemsToUpdate });
           //console.log("itemsToUpdate " , itemsToUpdate)
           //console.log("assignarr " , assignarr)

           var urlpath = '/container/storepipavamundraContainers';
           var reqparams = {
               assignlist:assignarr
		   } 
		   console.log(reqparams)
            redirectURL.post(urlpath,reqparams)
            .then((response) => {
				if(response.data.status == 'Success')
				{
					this.setState({
						loadshow:'show-m'
					});
					redirectURL.post("/imports/pmconsignments")
					.then((response) => {
						var records = response.data;
						//console.log("records", records);
						var pipavalistarr=[];
						var mundralistarr=[];
						records.map((item) => {
							var searates_pod = item.searates_pod;
							if(searates_pod == 'Pipavav')
							{
								pipavalistarr.push(item.container_no);
							}
							
							if(searates_pod == 'Mundra')
							{
								mundralistarr.push(item.container_no);
							}
						})
						//console.log("mundralistarr ",mundralistarr);
						//console.log("pipavalistarr ",pipavalistarr);
						var pipavauniquefencelist = pipavalistarr.filter(function (value, index, self) {
							return self.indexOf(value) === index;
						} );
						
						var mundrauniquefencelist = mundralistarr.filter(function (value, index, self) {
							return self.indexOf(value) === index;
						} );
						//console.log("uniquefencelist " , uniquefencelist)
						this.setState({
							//rowData:rowarray,
							loadshow:'show-n',
							pipavaContainers:pipavauniquefencelist,
							mundraContainers:mundrauniquefencelist,
							transporter_code:'',
							searates:'',
							container_no:'',
							rowData:[],
							transporterdropshow:'show-n',
							successMessage:"Successfully Assigned"
						});
					})
					.catch(function (error) {
						console.log(error);
					});
				}
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else{
            this.setState({
                errorMessage:"Select Transporter"
            });
        }
    }
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
				
				{
					headerName: "Container no",
					field: "container_no",
					width: 180,
					filter: true,
                    resizable: true
				},
				{
					headerName: "BL No",
					field: "bl_no",
					width: 200,
					filter: true,
                    resizable: true
				},
				{
					headerName: "Container Size",
					field: "container_size",
					width: 200,
					filter: true,
                    resizable: true
				},
				{
					headerName: "ETA POD",
					field: "eta_pod",
					width: 200,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){
                        if(params.data.eta_pod != 'NaT')
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.eta_pod)
                        }
                        else{
                            return ''
                        }
					},
					//filter: "agDateColumnFilter",
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
				},
				
				// {
				// 	headerName: "ETD POL",
				// 	field: "etd_pol",
				// 	width: 150,
				// 	filter: true,
                //     Routeresizable: true,
                //     valueGetter:function(params){
                //         return getDDMMYYYYHHMMSS(params.data.etd_pol)
                //     }
				// },
				
				{
					headerName: "ATA POD",
					field: "ata_pod",
					width: 200,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){
                        if(params.data.ata_pod != 'NaT')
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.ata_pod)
                        }
                        else{
                            return ''
                        }
					},
					//filter: "agDateColumnFilter",
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 
				}
			];
		const dStyles={
			width:this.props.width,
            height:'500px',
            marginLeft:'0px !important'

	    }
        
        const searatesList=[
            {value:"Pipavav", label:"Pipava"},
            {value:"Mundra", label:"Mundra"}
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> 
									   <span> {this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                    <form className="row theme-form col-xl-9 col-lg-9" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>Choose Port</label>
                                            <Select value={this.state.searates} className="border-radius-0" onChange={this.changeArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={searatesList} required />
					               			
										</div>
                                        <div className="col-xl-5 col-lg-5 form-group">
                                            <label>Choose Container No</label>
                                            <Select value={this.state.container_no} isMulti="true" className="border-radius-0" onChange={this.changeContainerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectContainersOptionsItems()} required />
                                        </div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label></label><br />
                                            <button type="submit" className="btn btn-success mt-9p">Get Data</button>
                                        </div>
                                    </form>
                                    <form className={"row theme-form col-xl-3 col-lg-3 "+(this.state.transporterdropshow) } onSubmit={this.formAssignHandler}>
                                        <div className="col-xl-8 col-lg-8 form-group float-left">
                                            <label>Choose Transporter</label>
                                            <Select value={this.state.transporter_code} className="border-radius-0" onChange={this.changeTransporterHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectOptionsItems()} required />
                                        </div>
                                        <div className={"col-xl-4 col-lg-4 form-group float-left"}>
											<label></label><br />
											<button type="submit" className="btn btn-info mt-9p">Assign</button>
                                        </div>
                                    </form>

                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   {
									(this.state.rowData.length > 0)?
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													enableRangeSelection={false}
													floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    onCellClicked={this.popmarker}
												/>
											</div>
                                           
										</div>
										: ""
									}
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}