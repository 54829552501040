import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import { transform } from 'lodash';

var Constant = require('../common/Constant');
$.datetimepicker.setLocale('en');

var redirectURL = require('../redirectURL');
var moment = require('moment');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");


class DispachSummaryDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadshow: "show-n",
			overly: "show-n",
			pagetitle: "All Trips",
			lastYearData: [],
			lastMonthData: [],
			yesterdayData: [],
			lastYearIntData: [],
			lastMonthIntData: [],
			yesterdayIntData: [],
			startDate: '',
			endDate: '',
			defaultsdate: '',
			defaultedate: '',
			transporterOptions: [],
			selectedTransporter: { value: "all", label: "ALL", },
			vehicleInsidePlant: [],
		};
	}

	componentDidMount() {
		this.fetchVehicleInsidePlantData();
		this.fetchData();
	}

	fetchData = async (transportercode) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m",
		})
		try {
			const edate = moment.parseZone().format('YYYY-MM-DD');
			const sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');

			this.setState({
				startDate: sdate,
				endDate: edate,
				defaultsdate: sdate,
				defaultedate: edate,
			});

			const parameters = {
			};

			if (transportercode) {
				parameters.transporter_code = transportercode;
			}
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
            {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }

			const response = await redirectURL.post("/consignments/alldashboarddata", parameters);
			const interplantData = response.data.interplantData;
			var lastYearData = interplantData.lastYearData;
			console.log(interplantData, "interPlant70")
			// lastYearData.map((lyd)=>{
			// 	lyd.line_average = (lyd['0_8_hrs']+lyd['0_8_hrs']+lyd['0_8_hrs']+lyd['0_8_hrs'])/4
			// });
			var lastMonthData = interplantData.lastMonthData;
			// lastMonthData.map((lmd)=>{
			// 	lmd.line_average = (lmd['0_8_hrs']+lmd['0_8_hrs']+lmd['0_8_hrs']+lmd['0_8_hrs'])/4
			// });
			var yesterdayData = interplantData.yesterdayData;
			// yesterdayData.map((yd)=>{
			// 	yd.line_average = (yd['0_8_hrs']+yd['0_8_hrs']+yd['0_8_hrs']+yd['0_8_hrs'])/4
			// });
			const intransitData = response.data.intransitData;
			var lastYearIntData = intransitData.lastYearIntData;
			var lastMonthIntData = intransitData.lastMonthIntData;
			var yesterdayIntData = intransitData.yesterdayIntData;
			var transporterList = response.data.transporterList
			var transporterOptions = [{ value: "all", label: "ALL" }]
			transporterList.length > 0 && transporterList.map(e => transporterOptions.push({ label: e.transporter_name + "-" + e.transporter_code, value: e.transporter_code }))
			this.setState({
				lastYearData: lastYearData,
				lastMonthData: lastMonthData,
				yesterdayData: yesterdayData,
				lastYearIntData: lastYearIntData,
				lastMonthIntData: lastMonthIntData,
				yesterdayIntData: yesterdayIntData,
				transporterOptions: transporterOptions,
				selectedTransporter: this.state.selectedTransporter,
				// loadshow: "show-n",
				// overly: "show-n",
			});
		} catch (error) {
			console.error("Error fetching data:", error);
			// Handle error, e.g., update state with an error message
		}
	};

	fetchVehicleInsidePlantData = async (transportercode) => {
		try {
			// this.setState({ loadshow: "show-m",
			// overly: "show-m",})
			var edate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD 23:59:59');
			var sdate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD 00:00:00');
			var parameters = {
				start_date: sdate,
				end_date: edate
			};
			if (transportercode) {
				parameters.transporter_code = transportercode;
			}
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined")
            {
                parameters.plant_code = encode(localStorage.getItem("pc"));
            }
			var response2 = await redirectURL.post("/consignments/vehicleinsideplant", parameters);
			console.log(response2.data,"response2")
			var vehicleInsidePlantData = response2.data.vehicles_inside_plant_count || []
			this.setState({ vehicleInsidePlant: vehicleInsidePlantData, loadshow: "show-n", overly: "show-n", })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	onchangeTransporterType = (event) => {
		// console.log(event.value)
		this.setState({
			selectedTransporter: event
		})
	}

	onSubmitTransporter = async (event) => {
		event.preventDefault()
		if (this.state.selectedTransporter.value != "all") {
			this.fetchData(this.state.selectedTransporter.value)
			this.fetchVehicleInsidePlantData(this.state.selectedTransporter.value)

		} else {
			this.fetchData()
			this.fetchVehicleInsidePlantData()

		}
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		console.log(startdate, "958");
		this.setState({
			startDate: startdate,
		});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		this.setState({
			endDate: edate,
		});
	}

	formatDataForTable = (lastYearData, lastMonthData, yesterdayData) => {
		const formattedData = [];

		if (lastYearData && lastMonthData && yesterdayData) {
			// Total row logic for lastYearData
			var total_lastYear_0_8_hrs = 0;
			var total_lastYear_8_16_hrs = 0;
			var total_lastYear_16_24_hrs = 0;
			var total_lastYear_gt_24_hrs = 0;
			lastYearData.forEach((row) => {
				total_lastYear_0_8_hrs += row['0_8_hrs'] || 0;
				total_lastYear_8_16_hrs += row['8_16_hrs'] || 0;
				total_lastYear_16_24_hrs += row['16_24_hrs'] || 0;
				total_lastYear_gt_24_hrs += row['gt_24_hrs'] || 0;
				const formattedRow = {
					fence_type: row.fence_type || '',
					lastYear_0_8_hrs: row['0_8_hrs'] || 0,
					lastYear_8_16_hrs: row['8_16_hrs'] || 0,
					lastYear_16_24_hrs: row['16_24_hrs'] || 0,
					lastYear_gt_24_hrs: row['gt_24_hrs'] || 0,
					// lastYear_average: row['line_average'] || 0,
				};
				formattedData.push(formattedRow);
			});
			var total_lastYear = {
				fence_type: 'Total',
				lastYear_0_8_hrs: total_lastYear_0_8_hrs,
				lastYear_8_16_hrs: total_lastYear_8_16_hrs,
				lastYear_16_24_hrs: total_lastYear_16_24_hrs,
				lastYear_gt_24_hrs: total_lastYear_gt_24_hrs,
			}
			if (lastYearData.length > 0) {
				formattedData.push(total_lastYear);
			}
			// Total row logic for lastMonthData
			var total_lastMonth_0_8_hrs = 0;
			var total_lastMonth_8_16_hrs = 0;
			var total_lastMonth_16_24_hrs = 0;
			var total_lastMonth_gt_24_hrs = 0;
			lastMonthData.forEach((row) => {
				total_lastMonth_0_8_hrs += row['0_8_hrs'] || 0;
				total_lastMonth_8_16_hrs += row['8_16_hrs'] || 0;
				total_lastMonth_16_24_hrs += row['16_24_hrs'] || 0;
				total_lastMonth_gt_24_hrs += row['gt_24_hrs'] || 0;
				const formattedRow = {
					fence_type: row.fence_type || '',
					lastMonth_0_8_hrs: row['0_8_hrs'] || 0,
					lastMonth_8_16_hrs: row['8_16_hrs'] || 0,
					lastMonth_16_24_hrs: row['16_24_hrs'] || 0,
					lastMonth_gt_24_hrs: row['gt_24_hrs'] || 0,
					// lastMonth_average: row['line_average'] || 0,
				};
				const existingRow = formattedData.find((item) => item.fence_type === row.fence_type || '');
				if (existingRow) {
					Object.assign(existingRow, formattedRow);
				} else {
					formattedData.push(formattedRow);
				}
			});
			var total_lastMonth = {
				lastMonth_0_8_hrs: total_lastMonth_0_8_hrs,
				lastMonth_8_16_hrs: total_lastMonth_8_16_hrs,
				lastMonth_16_24_hrs: total_lastMonth_16_24_hrs,
				lastMonth_gt_24_hrs: total_lastMonth_gt_24_hrs,
			}
			var existingRow = formattedData.find((item) => item.fence_type === "Total");
			if (existingRow) {
				Object.assign(existingRow, total_lastMonth);
			}
			// Total row logic for yesterdayData
			var total_yesterday_0_8_hrs = 0;
			var total_yesterday_8_16_hrs = 0;
			var total_yesterday_16_24_hrs = 0;
			var total_yesterday_gt_24_hrs = 0;
			yesterdayData.forEach((row) => {
				total_yesterday_0_8_hrs += row['0_8_hrs'] || 0;
				total_yesterday_8_16_hrs += row['8_16_hrs'] || 0;
				total_yesterday_16_24_hrs += row['16_24_hrs'] || 0;
				total_yesterday_gt_24_hrs += row['gt_24_hrs'] || 0;
				const formattedRow = {
					fence_type: row.fence_type || '',
					yesterday_0_8_hrs: row['0_8_hrs'] || 0,
					yesterday_8_16_hrs: row['8_16_hrs'] || 0,
					yesterday_16_24_hrs: row['16_24_hrs'] || 0,
					yesterday_gt_24_hrs: row['gt_24_hrs'] || 0,
					// yesterday_average: row['line_average'] || 0,
				};
				const existingRow = formattedData.find((item) => item.fence_type === row.fence_type);
				if (existingRow) {
					Object.assign(existingRow, formattedRow);
				} else {
					formattedData.push(formattedRow);
				}
			});
			var total_yesterday = {
				yesterday_0_8_hrs: total_yesterday_0_8_hrs,
				yesterday_8_16_hrs: total_yesterday_8_16_hrs,
				yesterday_16_24_hrs: total_yesterday_16_24_hrs,
				yesterday_gt_24_hrs: total_yesterday_gt_24_hrs,
			}
			var existingRow = formattedData.find((item) => item.fence_type === "Total");
			if (existingRow) {
				Object.assign(existingRow, total_yesterday);
			}
		}

		return formattedData;
	};



	formatDataForInsidePlantTable = (vehicleInsidePlantData) => {
		if (!Array.isArray(vehicleInsidePlantData)) {
			console.error("vehicleInsidePlantData is not an array", vehicleInsidePlantData);
			return []; // Return empty array if vehicleInsidePlantData is not an array
		}

		let total_standard_time_in_hours = 0;
		let total_truck_count = 0;
		let total_trucks_gt_5_hours_count = 0;

		const formattedData = vehicleInsidePlantData.map(row => {
			const { fence_group = '', standard_time_in_hours = 0, truck_no = 0, trucks_gt_5_hours_count = 0 } = row;
			total_standard_time_in_hours += standard_time_in_hours;
			total_truck_count += truck_no;
			total_trucks_gt_5_hours_count += trucks_gt_5_hours_count;
			return { fence_group, standard_time_in_hours, truck_no, trucks_gt_5_hours_count };
		});

		if (vehicleInsidePlantData.length > 0) {
			const total_vehicle_inside_plant = {
				fence_group: 'Total',
				standard_time_in_hours: total_standard_time_in_hours.toFixed(2),
				truck_no: total_truck_count,
				trucks_gt_5_hours_count: total_trucks_gt_5_hours_count
			};
			formattedData.push(total_vehicle_inside_plant);
		}

		return formattedData;
	};


	toCamelCase = (str) => {
		return str.replace(/_/g, ' ').replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => letter);
	};

	onClickHideAll = () => {
		this.setState({
			loadshow: "show-n",
			overly: "show-n",
		})
	}

	render() {
		const yesterdayData = this.state.yesterdayData;
		const lastMonthData = this.state.lastMonthData;
		const lastYearData = this.state.lastYearData;

		const yesterdayIntData = this.state.yesterdayIntData;
		const lastMonthIntData = this.state.lastMonthIntData;
		const lastYearIntData = this.state.lastYearIntData;

		var vehicleInsidePlantData = this.formatDataForInsidePlantTable(this.state.vehicleInsidePlant);
		const formattedData = this.formatDataForTable(lastYearData, lastMonthData, yesterdayData);
		console.log(formattedData, "formatteddata328")
		// const formattedIntData = this.formatIntDataForTable(lastYearIntData, lastMonthIntData, yesterdayIntData);

		const tableHeaderStyle = {
			border: '1px solid #000',
			background: 'rgb(36 120 159)',
			color: 'white',
			textAlign: 'center',
			padding: '10px'
		};

		const tableCellStyle = {
			border: '1px solid #000',
			padding: '8px',
			textAlign: 'center',
			textTransform: 'uppercase',
		};

		return (
			<>
				<div className="card">
					<div className="card-header">
						<h5>
							<i className="icofont icofont-building-alt cus-i"></i> <span>TAT Analysis - Inter Plant Movement (No Of Trucks) </span>
						</h5>
					</div>

					<div className="card-body pt-15px">
						<form className="row" onSubmit={this.onSubmitTransporter.bind(this)}>
							<div className="form-group col-xl-4">
								<label>Select Transporter</label>
								<Select
									className="border-radius-0"
									value={this.state.selectedTransporter}
									isMulti={false}
									id="selectedFilter"
									style={{ borderRadius: "0px" }}
									options={this.state.transporterOptions}
									onChange={this.onchangeTransporterType.bind(this)}
									required />
							</div>
							<div className="form-group col-xl-4" style={{ float: 'right', marginTop: "30px" }}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
						<div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
							<div style={{ width: '33%', paddingTop: '10px', paddingRight: '12px', marginRight: '20px' }}>
								<div className="card-header" style={{ textAlign: 'center' }}>
									<h5>
										VEHICLE INSIDE PLANT
									</h5>

								</div>
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', }}>
									<thead>
										<tr>
											<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000' }}>No Of Trucks (Yesterday)</th>
										</tr>
										<tr>
											<th style={tableHeaderStyle}>Loading Point</th>
											<th style={tableHeaderStyle}>Std Time Hrs</th>
											<th style={tableHeaderStyle}>Trucks</th>
											<th style={tableHeaderStyle}> &gt; +5 Hrs Std Time Trucks  </th>

										</tr>
									</thead>
									<tbody>
										{vehicleInsidePlantData.map((row, index) => (
											<tr key={index}>
												<td style={tableCellStyle}>{(row.fence_group)}</td>
												<td style={tableCellStyle}>{row.standard_time_in_hours}</td>
												<td style={tableCellStyle}>{row.truck_no}</td>
												<td style={tableCellStyle}>{row.trucks_gt_5_hours_count}</td>
											</tr>
										))}
									</tbody>
								</table>
								{vehicleInsidePlantData.length == 0 &&
									<div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
								}
							</div>
							<div style={{ width: '67%', paddingTop: '10px', paddingRight: '12px' }}>
								<div className="card-header" style={{ textAlign: 'center' }}>
									<h5>
										TAT Hrs
									</h5>
								</div>
								<table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
									<thead>
										<tr>
											<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000' }}>Year ({moment().subtract(1, 'years').format('YYYY')}) </th>
											<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000' }}>Month ({moment().subtract(1, 'month').format('MMMM')})</th>
											<th colSpan={4} style={{ textAlign: 'center', background: '#ddd', padding: '10px', border: '1px solid #000' }}>Yesterday</th>
										</tr>
										<tr>
											<th style={tableHeaderStyle}>0-8 Hrs</th>
											<th style={tableHeaderStyle}>8-16 Hrs</th>
											<th style={tableHeaderStyle}>16-24 Hrs</th>
											<th style={tableHeaderStyle}>&gt; 24 Hrs</th>
											{/* <th style={tableHeaderStyle}>Average</th> */}
											<th style={tableHeaderStyle}>0-8 Hrs</th>
											<th style={tableHeaderStyle}>8-16 Hrs</th>
											<th style={tableHeaderStyle}>16-24 Hrs</th>
											<th style={tableHeaderStyle}>&gt; 24 Hrs</th>
											{/* <th style={tableHeaderStyle}>Average</th> */}
											<th style={tableHeaderStyle}>0-8 Hrs</th>
											<th style={tableHeaderStyle}>8-16 Hrs</th>
											<th style={tableHeaderStyle}>16-24 Hrs</th>
											<th style={tableHeaderStyle}>&gt; 24 Hrs</th>
											{/* <th style={tableHeaderStyle}>Average</th> */}
										</tr>
									</thead>
									<tbody>
										{formattedData.map((row, index) => (

											<tr key={index}>
												<td style={tableCellStyle}>{row.lastYear_0_8_hrs}</td>
												<td style={tableCellStyle}>{row.lastYear_8_16_hrs}</td>
												<td style={tableCellStyle}>{row.lastYear_16_24_hrs}</td>
												<td style={tableCellStyle}>{row.lastYear_gt_24_hrs}</td>
												{/* <td style={tableCellStyle}>{row.lastYear_average}</td> */}

												<td style={tableCellStyle}>{row.lastMonth_0_8_hrs}</td>
												<td style={tableCellStyle}>{row.lastMonth_8_16_hrs}</td>
												<td style={tableCellStyle}>{row.lastMonth_16_24_hrs}</td>
												<td style={tableCellStyle}>{row.lastMonth_gt_24_hrs}</td>
												{/* <td style={tableCellStyle}>{row.lastMonth_average}</td> */}

												<td style={tableCellStyle}>{row.yesterday_0_8_hrs}</td>
												<td style={tableCellStyle}>{row.yesterday_8_16_hrs}</td>
												<td style={tableCellStyle}>{row.yesterday_16_24_hrs}</td>
												<td style={tableCellStyle}>{row.yesterday_gt_24_hrs}</td>
												{/* <td style={tableCellStyle}>{row.yesterday_average}</td> */}
											</tr>
										))}
									</tbody>
								</table>
								{formattedData.length == 0 &&
									<div style={{ textAlign: 'center', width: '100%', }}> No Data Found </div>
								}
							</div>

						</div>

					</div>
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			</>
		);
	}
}

export default DispachSummaryDashboard;

