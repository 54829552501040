
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');



export default class DateDeviationRoute extends Component {
    constructor(props) {
      super(props);
      this.state ={
          value:false
      }
      this.checkedHandler = this.checkedHandler.bind(this);
    }
    componentDidMount(){
        console.log("Here Date Deviation" , this.props)
        if(this.props.value != "")
        {
            this.checkedHandler();
        }
    }
    checkedHandler(){
        this.props.context.componentParent.onSelectRouteDate(this.props);
    }
    render() {
        //console.log("this.Defs ", this.props)
      return (
        <span style={{fontSize:"12px"}}>
            {this.props.value}
        </span>
      )
    }
  }
