
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Sendemailticket = (props) => {
    const handleEventClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickSendEmail(props);
    };
    const handleEventCallClick = (e) => {
        e.stopPropagation();
        //console.log("Overspeed ",props);
        props.context.componentParent.onClickCallNow(props);
    };
    return (
        <div>
            {/* <button onClick={handleEventClick} className="cpointer custom-btn f22 label label-success" 
            title="Send Email" ><i className={"icon-email "}></i> &nbsp;</button>
             */}
            <button onClick={handleEventCallClick} className="cpointer custom-btn f22 label label-success" 
            title="Contact Details" ><i className={"fa fa-phone"}></i> &nbsp;</button>
        </div>
    );
};

export default Sendemailticket;
