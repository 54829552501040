import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
    getHyphenYYYYMMDD, 
    getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import TransitLineChart from './transitdashboardlinechart';
import ColumnGroupGPS from './columngroupgps';
import LineLoadingDashboard from './linedailyloading';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

var moment = require('moment');
var allmonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
       
export default class GPSDashboard extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot : true,
                // enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            rowDataExcluded:[],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'',
            departmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-m',
            showgpschart:'show-n',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            tripclosureallsummary:[],
            tripclosureprimarysummary:[],
            tripclosuresecondsummary:[],
            tripcategories:[],
            tripfcclosureallsummary:[],
            tripfcclosureprimarysummary:[],
            tripfcclosuresecondsummary:[],
            tripfccategories:[],
            monthlydataaall:[],
            monthlyprimarydata:[],
            monthlysecondarydata:[],
            shownormalprimary:'show-n',
            shownormalall:'show-m',
            shownormalsecond:'show-n',
            showfcprimary:'show-n',
            showfcsecond:'show-n',
            showfcall:'show-m',
            pchecked:false,
            schecked:false,
            achecked:true,
            afcchecked:false,
            sfcchecked:false,
            afcchecked:true,
            monthlyfcprimarydata:[],
            monthlyfcsecondarydata:[],
            monthlyfcalldata:[],
            dept_code:'',
            selectmonth:'',
            monthlywisegpsprovider:[],
            selectgps:'',
            gpsproviderlist:[],
            monthlywisegpspacket:[],
            gpsdata:[],
            transmonthlywisegpsprovider:[],
            gpsprovider:'',
            gpstransportdata:[],
            transmonthlywisegpspacketprovider:[]
            
        }
    }

    componentWillReceiveProps(newprops){
        //console.log("newprops.dept_code ", newprops.dept_code)
        var month = moment.parseZone().format("M Y")
        var onlymonth = moment.parseZone().format("M")
        
        if(newprops.dept_code != this.state.dept_code)
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
                transporter:{"value": "all", "label":'All'},
                location:{"value": "all", "label":'All'}
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            if(newprops.dept_code == 'all')
            {
                var dept_code="SNDG";
            
            }
            else{
                var dept_code=newprops.dept_code;
            
            }
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            var preqparams = {
                dept_code:dept_code
            }
            this.setState({
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                locations:[],
                transporters:[]
            })
            var preqparams = {
                dept_code:dept_code,
                transporter_code:localStorage.getItem("transportercode")
            }
            
            redirectURL.post("/dashboard/gpstransporterdashboard",preqparams)
            .then((response) => {
              console.log("Response ", response.data)
                var records = response.data
                var monthlydatagroup = groupBy(records, rdata => rdata.report_month+" "+rdata.report_year);
               
                var categories = []
                var pcategories = []
                var monthlywisegpsprovider=[]
                var monthlywisegpspacketreceive=[]
                var columndata = []

                var prcolumndata = []
                var gpsarr=[]
                var sgps = ''
                monthlydatagroup.forEach((item,key ) => {
                    
                    if(key == month)
                    {
                        var less3=[];
                        var threefive=[];
                        var fiveten=[];
                        var ten15=[];
                        var fiteen60=[];
                        var greater60=[];

                        var prless3=[];
                        var prthreefive=[];
                        var prfiveten=[];
                        var prten15=[];
                        var prfiteen60=[];
                        var prgreater60=[];
                        item.map((itm, ky) => {
                            //console.log("itm ", itm)
                            if(ky == 0)
                            {
                                sgps = itm.truck_no
                            }
                            
                            //gpsarr.push(itm.gps_provider)   
                            if(this.state.dept_code == 'SNDG')
                            {
                                if(itm.gps_provider == 'TRIMBLE' || itm.gps_provider == 'EFKON')
                                {
                                    if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                        itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                        itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                        itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                        itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                        itm.timestamp_freq_greater_than_60_mins_per > 0)
                                        {
                                            categories.push({
                                                name:itm.truck_no
                                            })
                                        }
                                        
                                    if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                    {
                                        less3.push(itm.timestamp_freq_less_than_3_mins_per*100)
                                    
                                    }
                                      
                                    if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                    {
                                        threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per*100)
                                    }
                                    if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                    {
                                        fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per*100)
                                    }
                                    if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                    {
                                        ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per*100)
                                    }
                                    if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                    {
                                        fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per*100)
                                    }
                                    if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                    {
                                        greater60.push(itm.timestamp_freq_greater_than_60_mins_per*100)
                                    }
                                    

                                    if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                        itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                        itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                        itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                        itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                        itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                    {
                                        pcategories.push({
                                            name:itm.truck_no
                                        })
                                    }
                                    
                                    

                                    if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                    {
                                        prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                                    }
                                    
                                    if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                    {
                                        prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                                    }

                                    if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                    {
                                        prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                                    }
                                    if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                    {
                                        prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                                    }
                                    if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                    {
                                        prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                                    }
                                    
                                    if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                    {
                                        prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                                    }
                                }
                            }      
                            else{
                                if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                    itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                    itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                    itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                    itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                    itm.timestamp_freq_greater_than_60_mins_per > 0)
                                    {
                                        categories.push({
                                            name:itm.truck_no
                                        })
                                    }
                                    
                                if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                {
                                    less3.push(itm.timestamp_freq_less_than_3_mins_per*100)
                                
                                }
                                  
                                if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                {
                                    threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per*100)
                                }
                                if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                {
                                    fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per*100)
                                }
                                if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                {
                                    ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per*100)
                                }
                                if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                {
                                    fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per*100)
                                }
                                if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                {
                                    greater60.push(itm.timestamp_freq_greater_than_60_mins_per*100)
                                }
                                

                                if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                    itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                    itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                {
                                    pcategories.push({
                                        name:itm.truck_no
                                    })
                                }
                                
                                

                                if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                {
                                    prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                                }
                                
                                if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                {
                                    prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                                }

                                if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                {
                                    prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                                }
                                if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                {
                                    prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                                }
                                if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                {
                                    prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                                }
                                
                                if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                {
                                    prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                                }
                            }
                            
                               
                        })
                        columndata.push({
                            name:'< 3 mins',
                            data:less3
                        },{
                            name: '3 - 5 mins',
                            data:threefive
                        },{
                            name: '5 - 10 mins',
                            data: fiveten
                        },{
                            name: '10 - 15 mins',
                            data: ten15
                        },{
                            name: '15 - 60 mins',
                            data: fiteen60
                        },{
                            name: '> 60 mins',
                            data: greater60
                            
                        })


                        prcolumndata.push({
                            name:'< 3 mins',
                            data:prless3
                        },{
                            name: '3 - 5 mins',
                            data:prthreefive
                        },{
                            name: '5 - 10 mins',
                            data: prfiveten
                        },{
                            name: '10 - 15 mins',
                            data: prten15
                        },{
                            name: '15 - 60 mins',
                            data: prfiteen60
                        },{
                            name: '> 60 mins',
                            data: prgreater60
                            
                        })
                    }
                })

                let newArray = []; 
            
                // Declare an empty object 
                let uniqueObject = {}; 
                var objTitle;
                // Loop for the array elements 
                for (let i in categories) { 
        
                    // Extract the title 
                    objTitle = categories[i]['name']; 
        
                    // Use the title as the index 
                    uniqueObject[objTitle] = categories[i]; 
                } 
                
                // Loop to push unique object into array 
                for (var a in uniqueObject) { 
                    newArray.push(uniqueObject[a]); 
                } 
                var ncats = []
                newArray.map((it) => {
                    ncats.push(it.name)
                })

                let packnewArray = []; 
            
                //console.log("pcategories ", pcategories)
                // Declare an empty object 
                let packuniqueObject = {}; 
                var packobjTitle;
                // Loop for the array elements 
                for (let p in pcategories) { 
        
                    // Extract the title 
                    packobjTitle = pcategories[p]['name']; 
                    //console.log("packobjTitle ", packobjTitle)
                    // Use the title as the index 
                    packuniqueObject[packobjTitle] = pcategories[p]; 
                } 
                
                // Loop to push unique object into array 
                for (var a in packuniqueObject) { 
                    packnewArray.push(packuniqueObject[a]); 
                } 
                var paccats = []
                packnewArray.map((it) => {
                    paccats.push(it.name)
                })



               monthlywisegpsprovider.push({
                   series:columndata,
                   categories:ncats
               })
               
               monthlywisegpspacketreceive.push({
                   series:prcolumndata,
                   categories:paccats
               })

                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    selectmonth:{"value":onlymonth,"label":allmonths[onlymonth-1]},
                   // selectgps:sgps,
                    ///gpsproviderlist:gpsarr,
                    monthlywisegpsprovider:monthlywisegpsprovider,
                    monthlywisegpspacket:monthlywisegpspacketreceive,
                    gpsdata:records
                })
            })
            .catch(function(e){
                console.log("Error ",e)
            })

            
    
        }
        
    }
    
    onClickGetLoadingData(event){
        
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    monthoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
       var months = allmonths;
        if(months.length > 0){
            months.forEach((item,key) =>{
                items.push({value:(key+1),label:item}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }

    gpsprovidersoptions(){
        var items=[];
        var gpsproviders = this.state.gpsproviderlist;
        if(gpsproviders.length > 0){
            gpsproviders.forEach((item,key) =>{
                items.push({value:item,label:item}); 
            });
        }
        return items;
    }
    /*Month wise*/
    onSelectMonth = async (selectmonth) => {
        //console.log("Month ", selectmonth)
        await this.setState(
			{ selectmonth },
			() => console.log(`Location Option selected:`, this.state.selectmonth)
		  );
		  
        await this.onShowMonthWiseData();
        await this.onShowTransporterwiseData();
        
    }

    onShowMonthWiseData(){
        this.setState({
            monthlywisegpsprovider:[],
            monthlywisegpspacket:[]
        })
        var records = this.state.gpsdata;
        //console.log("records ", records)
        var monthlydatagroup = groupBy(records, rdata => rdata.report_month+" "+rdata.report_year);
        //console.log("monthlydatagroup ", monthlydatagroup)
        var categories = []
        var pcategories = [];
        var monthlywisegpsprovider=[]
        var monthlywisegpspacketreceive=[]
        var columndata = []

        var prcolumndata = []
       
        monthlydatagroup.forEach((item,key ) => {
            var k = key.split(" ")
            if(parseInt(k[0]) == this.state.selectmonth.value)
            {
                var less3=[];
                var threefive=[];
                var fiveten=[];
                var ten15=[];
                var fiteen60=[];
                var greater60=[];

                var prless3=[];
                var prthreefive=[];
                var prfiveten=[];
                var prten15=[];
                var prfiteen60=[];
                var prgreater60=[];
                item.map((itm, ky) => {
                   // console.log("ITM ",itm)
                   
                    if(this.state.dept_code == 'SNDG')
                    {
                        if(itm.gps_provider == 'TRIMBLE' || itm.gps_provider == 'EFKON')
                        {
                            if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                itm.timestamp_freq_greater_than_60_mins_per > 0)
                                {
                                    categories.push({
                                        name:itm.gps_provider
                                    })
                                }
                                
                            if(itm.timestamp_freq_less_than_3_mins_per > 0)
                            {
                                less3.push(itm.timestamp_freq_less_than_3_mins_per)
                            
                            }
                                
                            if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                            {
                                threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per)
                            }
                            if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                            {
                                fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per)
                            }
                            if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                            {
                                ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per)
                            }
                            if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                            {
                                fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per)
                            }
                            if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                            {
                                greater60.push(itm.timestamp_freq_greater_than_60_mins_per)
                            }
                            

                            if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                itm.packet_receival_freq_greater_than_60_mins_per > 0)
                            {
                                pcategories.push({
                                    name:itm.gps_provider
                                })
                            }
                            
                            

                            if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                            {
                                prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                            }
                            
                            if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                            {
                                prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                            }

                            if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                            {
                                prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                            }
                            if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                            {
                                prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                            }
                            if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                            {
                                prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                            }
                            
                            if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                            {
                                prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                            }
                        }
                    }      
                    else{
                        if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                            itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                            itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                            itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                            itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                            itm.timestamp_freq_greater_than_60_mins_per > 0)
                            {
                                categories.push({
                                    name:itm.gps_provider
                                })
                            }
                            
                        if(itm.timestamp_freq_less_than_3_mins_per > 0)
                        {
                            less3.push(itm.timestamp_freq_less_than_3_mins_per)
                        
                        }
                            
                        if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                        {
                            threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per)
                        }
                        if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                        {
                            fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per)
                        }
                        if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                        {
                            ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per)
                        }
                        if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                        {
                            fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per)
                        }
                        if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                        {
                            greater60.push(itm.timestamp_freq_greater_than_60_mins_per)
                        }
                        

                        if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                            itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                            itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                            itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                            itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                            itm.packet_receival_freq_greater_than_60_mins_per > 0)
                        {
                            pcategories.push({
                                name:itm.gps_provider
                            })
                        }
                        
                        

                        if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                        {
                            prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                        }
                        
                        if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                        {
                            prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                        }

                        if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                        {
                            prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                        }
                        if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                        {
                            prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                        }
                        if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                        {
                            prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                        }
                        
                        if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                        {
                            prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                        }
                    }
                    

                })
                //console.log("less3 ", less3)
                columndata.push({
                    name:'< 3 mins',
                    data:less3
                },{
                    name: '3 - 5 mins',
                    data:threefive
                },{
                    name: '5 - 10 mins',
                    data: fiveten
                },{
                    name: '10 - 15 mins',
                    data: ten15
                },{
                    name: '15 - 60 mins',
                    data: fiteen60
                },{
                    name: '> 60 mins',
                    data: greater60
                    
                })


                prcolumndata.push({
                    name:'< 3 mins',
                    data:prless3
                },{
                    name: '3 - 5 mins',
                    data:prthreefive
                },{
                    name: '5 - 10 mins',
                    data: prfiveten
                },{
                    name: '10 - 15 mins',
                    data: prten15
                },{
                    name: '15 - 60 mins',
                    data: prfiteen60
                },{
                    name: '> 60 mins',
                    data: prgreater60
                    
                })
            }
        })

        let newArray = []; 
            
        // Declare an empty object 
        let uniqueObject = {}; 
        var objTitle;
        // Loop for the array elements 
        for (let i in categories) { 

            // Extract the title 
            objTitle = categories[i]['name']; 

            // Use the title as the index 
            uniqueObject[objTitle] = categories[i]; 
        } 
        
        // Loop to push unique object into array 
        for (var a in uniqueObject) { 
            newArray.push(uniqueObject[a]); 
        } 
        var ncats = []
        newArray.map((it) => {
            ncats.push(it.name)
        })

        let packnewArray = []; 
    
        //console.log("pcategories ", pcategories)
        // Declare an empty object 
        let packuniqueObject = {}; 
        var packobjTitle;
        // Loop for the array elements 
        for (let p in pcategories) { 

            // Extract the title 
            packobjTitle = pcategories[p]['name']; 
            //console.log("packobjTitle ", packobjTitle)
            // Use the title as the index 
            packuniqueObject[packobjTitle] = pcategories[p]; 
        } 
        
        // Loop to push unique object into array 
        for (var a in packuniqueObject) { 
            packnewArray.push(packuniqueObject[a]); 
        } 
        var paccats = []
        packnewArray.map((it) => {
            paccats.push(it.name)
        })



       monthlywisegpsprovider.push({
           series:columndata,
           categories:ncats
       })
       
       monthlywisegpspacketreceive.push({
           series:prcolumndata,
           categories:paccats
       })

        this.setState({
            loadshow:"show-n",
            overly:"show-n",
            monthlywisegpsprovider:monthlywisegpsprovider,
            monthlywisegpspacket:monthlywisegpspacketreceive
        })


    }

    onSelectGPS = async (selectgps) =>{
        await this.setState(
			{ selectgps },
			() => console.log(`Location Option selected:`, this.state.selectgps)
		  );
		  
          await this.onShowTransporterwiseData()
    }

    onShowTransporterwiseData()
    {
        this.setState({
            transmonthlywisegpsprovider:[]
        })
        var records = this.state.gpstransportdata
        //console.log("records ",records)
        try{
            var monthlydatagroup = groupBy(records, rdata => rdata.report_month+" "+rdata.report_year);
            //console.log('monthlydatagroup ', monthlydatagroup)
            var mcategories = []
            var packcategories = [];
            var transmonthlywisegpsprovider=[]
            var transmonthlywisegpspacketprovider = []
            var transcats = []
            
            var columndata = []
    
            var prcolumndata = []
            var gpsarr=[]
            var sgps = ''
            var sn = 0;
           //console.log("this.state.selectgps.value ", this.state.selectgps.value)
            monthlydatagroup.forEach((item,key ) => {
                console.log("item ", key)
                var k = key.split(" ")
                if(parseInt(k[0]) == (this.state.selectmonth.value))
                {
                    var monthlydataarr = groupBy(item, rdata => rdata.transporter_name);
                        //console.log("monthlydataarr ", monthlydataarr)
                        var less3=[];
                        var threefive=[];
                        var fiveten=[];
                        var ten15=[];
                        var fiteen60=[];
                        var greater60=[];
                        

                        var prless3=[];
                        var prthreefive=[];
                        var prfiveten=[];
                        var prten15=[];
                        var prfiteen60=[];
                        var prgreater60=[];

                        monthlydataarr.forEach((m, mk) => {
                           
                            gpsarr.push(mk)
                           // console.log("mk ", mk)
                            if(mk == this.state.selectgps.value)
                            {
                              // console.log("m ", m)
                                
                                m.map((itm, ky) => {
                                    if(this.state.dept_code == 'SNDG')
                                    {
                                        if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                            itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                            itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                            itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                            itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                            itm.timestamp_freq_greater_than_60_mins_per > 0)
                                            {
                                                mcategories.push({
                                                    name:itm.gps_provider
                                                })
                                            }
                                            
                                        if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                        {
                                            less3.push(itm.timestamp_freq_less_than_3_mins_per)
                                        
                                        }
                                        
                                        if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                        {
                                            threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per)
                                        }
                                        if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                        {
                                            fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per)
                                        }
                                        if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                        {
                                            ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per)
                                        }
                                        if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                        {
                                            fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per)
                                        }
                                        if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                        {
                                            greater60.push(itm.timestamp_freq_greater_than_60_mins_per)
                                        }
                                        

                                        if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                            itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                        {
                                            packcategories.push({
                                                name:itm.gps_provider
                                            })
                                        }
                                        
                                        

                                        if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                        {
                                            prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                                        }
                                        
                                        if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                        {
                                            prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                                        }

                                        if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                        {
                                            prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                                        }
                                        if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                        {
                                            prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                                        }
                                        if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                        {
                                            prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                                        }
                                        
                                        if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                        {
                                            prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                                        }
                                    }
                                    else{
                                        if(itm.timestamp_freq_less_than_3_mins_per > 0 || 
                                            itm.timestamp_freq_btw_3_to_5_mins_per > 0 || 
                                            itm.timestamp_freq_btw_5_to_10_mins_per > 0 || 
                                            itm.timestamp_freq_btw_10_to_15_mins_per > 0 || 
                                            itm.timestamp_freq_btw_15_to_60_mins_per > 0 || 
                                            itm.timestamp_freq_greater_than_60_mins_per > 0)
                                            {
                                                mcategories.push({
                                                    name:itm.gps_provider
                                                })
                                            }
                                            
                                        if(itm.timestamp_freq_less_than_3_mins_per > 0)
                                        {
                                            less3.push(itm.timestamp_freq_less_than_3_mins_per)
                                        
                                        }
                                    
                                        if(itm.timestamp_freq_btw_3_to_5_mins_per > 0)
                                        {
                                            threefive.push(itm.timestamp_freq_btw_3_to_5_mins_per)
                                        }
                                    
                                        if(itm.timestamp_freq_btw_5_to_10_mins_per > 0)
                                        {
                                            fiveten.push(itm.timestamp_freq_btw_5_to_10_mins_per)
                                        }
                                    
                                        if(itm.timestamp_freq_btw_10_to_15_mins_per > 0)
                                        {
                                            ten15.push(itm.timestamp_freq_btw_10_to_15_mins_per)
                                        }
                                    
                                        if(itm.timestamp_freq_btw_15_to_60_mins_per > 0)
                                        {
                                            fiteen60.push(itm.timestamp_freq_btw_15_to_60_mins_per)
                                        }
                                    
                                        if(itm.timestamp_freq_greater_than_60_mins_per > 0)
                                        {
                                            greater60.push(itm.timestamp_freq_greater_than_60_mins_per)
                                        }
                                    

                                        if(itm.packet_receival_freq_less_than_3_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_3_to_5_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_5_to_10_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_10_to_15_mins_per > 0 || 
                                            itm.packet_receival_freq_btw_15_to_60_mins_per > 0 || 
                                            itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                        {
                                            packcategories.push({
                                                name:itm.gps_provider
                                            })
                                        }
                                        
                                        

                                        if(itm.packet_receival_freq_less_than_3_mins_per > 0)
                                        {
                                            prless3.push(itm.packet_receival_freq_less_than_3_mins_per*100)
                                        }
                                    
                                        if(itm.packet_receival_freq_btw_3_to_5_mins_per > 0)
                                        {
                                            prthreefive.push(itm.packet_receival_freq_btw_3_to_5_mins_per*100)
                                        }
                                
                                        if(itm.packet_receival_freq_btw_5_to_10_mins_per > 0)
                                        {
                                            prfiveten.push(itm.packet_receival_freq_btw_5_to_10_mins_per*100)
                                        }

                                        if(itm.packet_receival_freq_btw_10_to_15_mins_per > 0)
                                        {
                                            prten15.push(itm.packet_receival_freq_btw_10_to_15_mins_per*100)
                                        }
                                        if(itm.packet_receival_freq_btw_15_to_60_mins_per > 0)
                                        {
                                            prfiteen60.push(itm.packet_receival_freq_btw_15_to_60_mins_per*100)
                                        }
                                        
                                        if(itm.packet_receival_freq_greater_than_60_mins_per > 0)
                                        {
                                            prgreater60.push(itm.packet_receival_freq_greater_than_60_mins_per*100)
                                        }
                                    }
                                    
        
                                })
                            }
                            
                        })
                        

                        
                        columndata.push({
                            name:'< 3 mins',
                            data:less3
                        },{
                            name: '3 - 5 mins',
                            data:threefive
                        },{
                            name: '5 - 10 mins',
                            data: fiveten
                        },{
                            name: '10 - 15 mins',
                            data: ten15
                        },{
                            name: '15 - 60 mins',
                            data: fiteen60
                        },{
                            name: '> 60 mins',
                            data: greater60
                            
                        })



                        prcolumndata.push({
                            name:'< 3 mins',
                            data:prless3
                        },{
                            name: '3 - 5 mins',
                            data:prthreefive
                        },{
                            name: '5 - 10 mins',
                            data: prfiveten
                        },{
                            name: '10 - 15 mins',
                            data: prten15
                        },{
                            name: '15 - 60 mins',
                            data: prfiteen60
                        },{
                            name: '> 60 mins',
                            data: prgreater60
                            
                        })
    
                }
            })
    
            //console.log("mcategories ", mcategories)
            let newArray = []; 
        
            // Declare an empty object 
            let uniqueObject = {}; 
            var objTitle;
            // Loop for the array elements 
            for (let i in mcategories) { 
    
                // Extract the title 
                objTitle = mcategories[i]['name']; 
    
                // Use the title as the index 
                uniqueObject[objTitle] = mcategories[i]; 
            } 
            
            // Loop to push unique object into array 
            for (var a in uniqueObject) { 
                newArray.push(uniqueObject[a]); 
            } 
            var mcats = []
            newArray.map((it) => {
                mcats.push(it.name)
            })


            let packnewArray = []; 
            
                // Declare an empty object 
                let packuniqueObject = {}; 
                var packobjTitle;
                // Loop for the array elements 
                for (let p in packcategories) { 
        
                    // Extract the title 
                    packobjTitle = packcategories[p]['name']; 
                    //console.log("packobjTitle ", packobjTitle)
                    // Use the title as the index 
                    packuniqueObject[packobjTitle] = packcategories[p]; 
                } 
                
                // Loop to push unique object into array 
                for (var a in packuniqueObject) { 
                    packnewArray.push(packuniqueObject[a]); 
                } 
                var packcats = []
                packnewArray.map((it) => {
                    packcats.push(it.name)
                })

            // console.log("mcats ",mcats)
            transmonthlywisegpsprovider.push({
                series:columndata,
                categories:mcats
            })
            
            transmonthlywisegpspacketprovider.push({
                series:prcolumndata,
                categories:packcats
            })

            
            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                transmonthlywisegpsprovider:transmonthlywisegpsprovider,
                transmonthlywisegpspacketprovider:transmonthlywisegpspacketprovider,
                //selectgps:this.state.selectgps
            })
        }
        catch(e){
            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                transmonthlywisegpsprovider:[]
            })
        }
    }
   
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n'
		});
		
	}
    render(){
        return ( 
        		<div className="row pt-20px tab-brd">
                    
                    <div className="row col-xl-12 col-lg-12">
                        <div className="col-xl-2" style={{margin:"0 auto"}}>
                            Choose Month : <Select placeholder={"Month"} value={this.state.selectmonth} className="border-radius-0" onChange={this.onSelectMonth.bind(this)} style={{borderRadius:"0px"}} options={this.monthoptions()} required />
                        </div>
                        
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                            
                            <div className={"col-xl-12 col-lg-12 "+(this.state.shownormalall)}>
                            {this.state.monthlywisegpsprovider.length > 0?
                
                                this.state.monthlywisegpsprovider.map(item => 
                                    <ColumnGroupGPS
                                        title={"GPS Provider Wise Sample Frequency Analysis "}
                                        subtitle={"Time duration between two GPS Samples"}
                                        context={this}
                                        series={item.series}
                                        //drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        //dept_code={this.state.dept_code}
                                    />    
                                    )
                                :
                                <ColumnGroupGPS
                                        title={"GPS Provider Wise Sample Frequency Analysis "}
                                        subtitle={"Time duration between two GPS Samples"}
                                        context={this}
                                        series={[]}
                                        //drildown = {item.chartdrildown}
                                        categories={[]}
                                        xaxistitle={""}
                                        yaxistitle={"Percentages"}
                                        //dept_code={this.state.dept_code}
                                    />
                                    }
                            </div>
                            <div className={"col-xl-12 col-lg-12 "}>
                                {this.state.monthlywisegpspacket.length > 0?
                
                                this.state.monthlywisegpspacket.map(item => 
                                    <ColumnGroupGPS
                                        title={"GPS Provider Wise Data Frequency Analysis"}
                                        subtitle={"Time duration between receipt of two GPS Packets"}
                                        context={this}
                                        series={item.series}
                                        //drildown = {item.chartdrildown}
                                        categories={item.categories}
                                        xaxistitle={""}
                                        yaxistitle={""}
                                        dept_code={this.state.dept_code}
                                    />    
                                    )
                                :""}
                            </div>
                            


                        </div>
                        
                        
                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    
                    </div>
                   
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}





