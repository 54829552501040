import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
var redirectURL = require('../redirectURL');


var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class MapRoutesComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
        	routemap:'',
        	departments:[],
        	coords:[],
        	geo_name:'',
        	geo_type:'',
        	fence_type:'',
        	dept_code:[],
        	notify:'',
        	erroMessage:''
    	      
        };
        this.showMap = this.showMap.bind(this);
       
    }
    componentDidMount(){
		
			this.renderMap();
		
    	//this.showMap();
    }
    componentDidUpdate(){
		
			this.renderMap();
		
    	//this.showMap();
    }
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
    }
    
    initMap = () => {
		var coords=[];
		//console.log("Check Coordinates",this.props.coordinates.length);
		
		if(this.props.coordinates.length > 0)
		{
			lt=this.props.coordinates[0].latitude;
			ln=this.props.coordinates[0].longitude;
		}
		else{
			var lt=17.3580;
			var ln=78.4869;
		}
		var geoFencingObj = [{lat:lt,lng:ln}];
    	var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true,
			fullscreenControl: true
		  });
		var marker = new window.google.maps.Marker({
		position: new window.google.maps.LatLng(lt,ln),
		map: map,
		title: 'Hello World!'
		});
  		geoFencingArea = new window.google.maps.Polygon({
			//map:map,
		  paths: geoFencingObj,
		  editable: true,
		  strokeColor: '#1D9567',
		  strokeOpacity: 0.70,
		  strokeWeight: 0,
		  fillColor: '#1D9567',
		  fillOpacity: 0.35,
		  draggable:true
		  
		});
		geoFencingArea.setMap(map);
		fencingLength=geoFencingObj;
		//console.log("test ",geoFencingArea.getPath());
		//window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);

		window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
		window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);
		
    }
    handleClick = (e) => {
        e.stopPropagation();
        this.props.context.onCloseRouteDiv(e);
    };
    render(){
    	let order_cnt=[];

    	const dStyles={
				width:this.props.width,
                height:'500px',
                marginLeft:this.props.marginLeft,

		}
    
        return (
         
                <div className="col-xl-12 col-lg-12 beffect n-p-0">
                    <div className="slide-r-title">
				 		<h4>
                            Route Map # {this.props.mapdetails.consignment_code}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.handleClick.bind(this)} >X</span>
									
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
                        <div id="map_canvas" className="" style={dStyles}></div>
                    </div>
                </div>
		           		
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}

	function getPolygonCoords()
	{
		
		 var len = geoFencingArea.getPath().getLength();
		 console.log(len);
		 var htmlStr = "";
		 coordinates=[];
		 for (var i = 0; i < len; i++) {
			// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
			// coordinates.push()
			 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
			 var spliting = strlatlng.split(",");
			 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
			 coordinates.push(latlngd);
		  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 }
		// console.log("htmlStr", coordinates);
		 
	}

